/* eslint-disable no-nested-ternary */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { createTranslator } from 'helpers/i18n';
import GlobalModal from 'helpers/GlobalModal';
import { TOGGLE_PASSWORD_CONFIRMATION_MODAL } from 'ducks/ui/types';

import {
  Panel, ButtonBar, Modal,
} from 'components/utils';
import ReturnToLoginButton from 'components/login/ReturnToLoginButton';

import HelpSuggestions from './HelpSuggestions';

const tr = createTranslator({
  title: 'Reset Email Sent',
  description: 'If this email address is associated with an Aidin user account, you should have just received an email with a password reset link.',
  close: 'Return to Log In',
});


const handleClose = () => GlobalModal.close(TOGGLE_PASSWORD_CONFIRMATION_MODAL);

const PasswordConfirmationModal = ({ open }) => (
  <Modal
    title={tr('title')}
    open={open}
    onClose={handleClose}
    width={650}
  >
    <Panel align="center" spacing={10}>
      {tr('description')}
      <HelpSuggestions />
      <ButtonBar align="right">
        <ReturnToLoginButton onClick={handleClose} />
      </ButtonBar>
    </Panel>
  </Modal>
);

PasswordConfirmationModal.propTypes = {
  /** The controlled open state for the wrapped Modal */
  open: PropTypes.bool.isRequired,
};

export default connect(({
  ui: {
    passwordConfirmationModal: {
      open,
    },
  },
}) => ({
  open,
}))(PasswordConfirmationModal);

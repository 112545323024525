/* eslint-disable import/prefer-default-export */
import React from 'react';
import { css } from 'emotion';
import { BLACK } from 'components/utils/Colors';

import {
  FEATURE_PROVIDER_VERIFICATION_WARNING,
  FEATURE_PROVIDER_EXPERIENCE_DEFAULT_SELECT_ALL_INSURANCES,
} from 'ducks/session/types';

export const createProviderSettingsFormLayout = ({
  features = {},
} = {}) => {
  if (features[FEATURE_PROVIDER_VERIFICATION_WARNING]) {
    return {
      hideApplyOtherLink: true,
      hideSidebar: true,
      selectionGroupClassName: css`
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        @supports (display: grid) {
          display: grid;
          grid-template-columns: repeat( auto-fit, 226px );
        }
      `,
      selectionGroupIcon: 'chevron-down',
      selectionGroupIconColor: BLACK,
      selectionGroupSelectMenuAsLabelElement: true,
      showExplainerText: true,
      filterInsurancesByHospitalSystem: true,
      verificationButton: 'provider',
      replaceBadgeWithWarningIcon: true,
      adminTabIncludeWarningIcon: true,
      providerChooserIncludeWarningIcon: true,
      primaryColumnWidth: 345,
      secondaryColumnWidth: 345,
      selectAllDefault: features[FEATURE_PROVIDER_EXPERIENCE_DEFAULT_SELECT_ALL_INSURANCES],
    };
  }
  return {
    /** Hide the small text version of apply to other locations link */
    hideApplyOtherLink: false,
    /** Hide sidebar content */
    hideSidebar: false,
    /** Custom CSS for selction item panel */
    selectionGroupClassName: undefined,
    /** Icon for selection items */
    selectionGroupIcon: undefined,
    /** Position of select all/none */
    selectionGroupSelectMenuAsLabelElement: undefined,
    /** Show explainer text */
    showExplainerText: false,
    /* Variant for verification button display */
    filterInsurancesByHospitalSystem: false,
    /* Variant for verification button display */
    verificationButton: 'default',
    /* Variant for verification warning in lieu of badge display */
    replaceBadgeWithWarningIcon: false,
    /* Variant for verification warning icon in AdminTab */
    adminTabIncludeWarningIcon: false,
    /* Variant for verification warning icon (full width) in ProviderChooser dropdown */
    providerChooserIncludeWarningIcon: false,
    /** PrimarySettingsColumn prop width */
    primaryColumnWidth: undefined,
    /** SecondarySettingsColumn prop width */
    secondaryColumnWidth: undefined,
  };
};

export const createDefaultContext = (options = {}, session = {}) => ({
  layout: createProviderSettingsFormLayout(session),
  readOnly: false,
  ...options,
});


const ViewContext = React.createContext(createDefaultContext());

export default ViewContext;

import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { css, cx } from 'emotion';

import TextField from 'components/utils/TextField';

const editingLabelStyle = css`
  width: 100%;
  padding: 5px 0 4px;
  font-size: 14px !important;
  > div {
    height: 19px;
  }
  input {
    padding: 0px !important;
    font-size: 14px !important;
    border: none !important;
    box-shadow: none !important;
    background-color: transparent;
    line-height: 1 !important;
    height: 100% !important;
  }
`;

/**
 * Given a value, allows for that value to be edited
 * in-line. While the specific use case is for attachments
 * and documents, could be used more generically. The
 * styling of the text field is opinionated to match the
 * requirements of an attachment label.
 */
const EditableAttachmentLabel = forwardRef(({
  children,
  className,
  deferLabelToValue,
  onSubmit,
  value,
}, ref) => {
  const [editing, setEditing] = useState(false);
  const [label, setLabel] = useState(value);
  const toggleEditing = (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    setEditing(!editing);
  };
  const textFieldReference = useRef(null);

  /**
   * Grants referential access to parent components
   * for the declared value, i.e. ref.current.toggleEditing
   */
  useImperativeHandle(ref, () => ({
    field: textFieldReference.current,
    editing,
    toggleEditing,
  }));

  /**
   * Update value in state if the passed-in value changes.
   */
  useEffect(() => {
    if (deferLabelToValue && !editing) {
      setLabel(value);
    }
  }, [value]);

  /**
   * Auto-select the appropriate text when editing starts.
   */
  useEffect(() => {
    if (editing && textFieldReference.current) {
      const { field } = textFieldReference.current;
      field.focus();
      try {
        if (label && label.match(/.*\.[a-zA-Z]{3}$/)) {
          field.input.setSelectionRange(0, label.length - 4);
        } else {
          field.input.setSelectionRange(0, label.length);
        }
      } catch (ex) {
        // Was not meant to be; no big deal...
      }
    }
  }, [editing]);

  const handleSubmit = () => {
    toggleEditing();
    if (onSubmit) {
      onSubmit(label);
    }
  };

  if (editing) {
    return (
      <TextField
        fullWidth
        className={cx(editingLabelStyle, className)}
        onBlur={handleSubmit}
        onSubmit={handleSubmit}
        ref={textFieldReference}
        value={label}
        onChange={(editedLabel) => { setLabel(editedLabel); }}
        onKeyDown={(e) => {
          if (e.keyCode === 27 || e.key === 'Esc' || e.key === 'Escape') {
            setLabel(value); // Reset on edit
            toggleEditing(e);
          }
        }}
      />
    );
  }

  return typeof children === 'function'
    ? children({ editing, label, toggleEditing })
    : (children || label);
});
EditableAttachmentLabel.propTypes = {
  /**
   * What renders by default in non-edit mode. If a function is given,
   * will pass the label (the initial value) along with a toggleEditing
   * function to change into edit mode, and an editing prop to denote
   * if the field is currently in edit mode. If nothing is given, will
   * just display the value.
   */
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  /**
   * Additional class name for the text field.
   */
  className: PropTypes.string,
  /**
   * Set to true if the displayed label should change in the event that
   * the value is updated; false to keep the state and ignore any incoming
   * prop changes. Default false.
   */
  deferLabelToValue: PropTypes.bool,
  /**
   * Callback when the user has submitted changes to the name.
   */
  onSubmit: PropTypes.func,
  /**
   * The initial value to show when starting to edit. This is passed
   * to the child as function if denoted as such.
   */
  value: PropTypes.string,
};
EditableAttachmentLabel.defaultProps = {
  children: undefined,
  className: undefined,
  deferLabelToValue: false,
  onSubmit: undefined,
  value: null,
};

export default EditableAttachmentLabel;

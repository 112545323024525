import initialState from 'store/initialState';
import * as types from './types';

const printing = (state = initialState.printing, { type, ...entity }) => {
  switch (type) {
    case types.CLEAR_PRINT_TRAY:
      return {
        pending: null,
        inline: null,
        document: null,
      };
    case types.REPLACE_PRINT_TRAY:
      return {
        ...state,
        pending: null,
        document: entity.document,
      };
    case types.PENDING_PRINT_TRAY:
      return {
        pending: entity.pending,
        inline: entity.inline,
        document: null,
      };
    default:
      return state;
  }
};

export default {
  printing,
};

import React, {
  useState, useContext, useMemo, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { merge } from 'lodash';
import { ThemeProvider as EmotionThemeProvider } from 'emotion-theming'; // eslint-disable-line

import { FEATURE_UPGRADE_UI } from 'ducks/session/types';

import ThemeControl from 'components/utils/ThemeControl';
import ThemeElement from 'components/utils/ThemeElement';

export const CURRENT_UI_VERSION = 1;

export const ThemeContext = React.createContext({});

export const ThemeConsumer = ({ children }) => (
  <ThemeContext.Consumer>
    {context => (typeof children === 'function' ? children(context) : children)}
  </ThemeContext.Consumer>
);
ThemeConsumer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func, PropTypes.string]),
};
ThemeConsumer.defaultProps = {
  children: undefined,
};

const MyThemeProvider = ({
  availableThemes: componentAvailableThemes,
  children,
  features: {
    [FEATURE_UPGRADE_UI]: isFeatureEnabled,
  } = {},
  componentTheme,
}) => {
  const context = useContext(ThemeContext);
  const {
    version: baseVersion,
    availableThemes: baseAvailableThemes,
  } = context;
  const availableThemes = componentAvailableThemes || baseAvailableThemes || [CURRENT_UI_VERSION];
  let useVersion = isFeatureEnabled ? CURRENT_UI_VERSION + 1 : CURRENT_UI_VERSION;
  if (!availableThemes.some(i => i === +useVersion)) {
    useVersion = CURRENT_UI_VERSION;
  }
  const versionStamp = `v${useVersion}`;
  const nextVersion = componentTheme ? (baseVersion || versionStamp) : versionStamp;
  const [version, setVersion] = useState(nextVersion);

  useEffect(() => { setVersion(nextVersion); }, [nextVersion]);

  const { theme: baseTheme = ThemeControl[version] } = context;

  const currentTheme = useMemo(() => {
    const matchingTheme = baseVersion === version ? baseTheme : ThemeControl[version];
    const currentComponentTheme = ((typeof componentTheme === 'function'
      ? componentTheme({ theme: matchingTheme, version })
      : componentTheme) || {})[version];
    return merge(matchingTheme, currentComponentTheme || {}, { version });
  }, [version]);

  const themeInfo = {
    theme: currentTheme, version, setVersion, availableThemes,
  };

  return (
    <ThemeContext.Provider value={themeInfo}>
      <EmotionThemeProvider theme={currentTheme}>
        <ThemeElement version={version}>
          {children}
        </ThemeElement>
      </EmotionThemeProvider>
    </ThemeContext.Provider>
  );
};
MyThemeProvider.propTypes = {
  availableThemes: PropTypes.arrayOf(PropTypes.number),
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  componentTheme: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      v1: PropTypes.shape({}),
      v2: PropTypes.shape({}),
    }),
  ]),
  features: PropTypes.shape({
    [FEATURE_UPGRADE_UI]: PropTypes.bool,
  }).isRequired,
};
MyThemeProvider.defaultProps = {
  availableThemes: undefined,
  componentTheme: undefined,
};

export const ThemeProvider = connect(
  ({ session: { features } }) => ({ features }),
)(MyThemeProvider);

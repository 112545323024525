import { createTranslator } from 'helpers/i18n';

const tr = createTranslator({
  deidentified: {
    name: 'Deidentified Patient',
  },
});

export const shapePatient = (patient, referral = {}) => {
  const { name, initials, deidentified } = patient || {};
  const { checklist_configuration_id: config } = referral;
  return {
    ...patient,
    name: name || initials || ((deidentified || !config) ? tr('deidentified.name') : ''),
    deidentified: deidentified || !(name || initials),
  };
};

export const shapeMessage = (message) => {
  const { patient } = message.message_content;
  return {
    ...message,
    message_content: {
      ...message.message_content,
      patient: shapePatient(patient),
    },
  };
};

export const shapeMessages = messages => messages.map(shapeMessage);

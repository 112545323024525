/* eslint-disable import/prefer-default-export, camelcase */

import apiDirector from 'helpers/ApiDirector';
import * as actions from '../actions';

export const saveSignature = (signatureData) => dispatch => apiDirector.validateFetch('/api/user_signatures', {
  method: 'POST',
  body: JSON.stringify({ signature: signatureData }),
}).then(signature => {
  dispatch(actions.addSignature({ id: signature.signature_id }));
});

export const deleteSignature = (index) => (dispatch, getState) => {
  const {
    documents: {
      signatures,
    },
  } = getState();

  if (signatures !== undefined && signatures[index] !== undefined) {
    const { id } = signatures[index];
    apiDirector.validateFetch(`/api/user_signatures/${id}`, {
      method: 'DELETE',
    }).then(() => {
      dispatch(actions.removeSignature(index));
    });
  }
};

export const fetchSignatures = (signatures) => dispatch => (
  dispatch(actions.populateSignatures(signatures)));

import { DEFAULT_LOCALE } from 'helpers/i18n';

import en from './en/help';
import es from './es/help';

const translations = { en, es };

export default translations[DEFAULT_LOCALE];

export const translatedHelp = locale => translations[locale] || translations[DEFAULT_LOCALE];

/* eslint-disable import/prefer-default-export */

import apiDirector from 'helpers/ApiDirector';
import * as actions from 'ducks/conversations/actions';

export const requestMessageList = () => dispatch => apiDirector.validateFetch('/api/conversations/inbox')
  .then(json => dispatch(actions.replaceMessages(json)));

export const requestMessageUnreadCount = () => dispatch => apiDirector.validateFetch('/api/conversations/inbox/unread')
  .then(json => dispatch(actions.replaceMessages(json)));

import initialState from 'store/initialState';
import * as types from './types';

const updateRecords = (state, { records }) => [
  ...state,
  ...records,
];

const deleteRecord = (state, { id }) => {
  const copy = state.slice();
  const index = copy.findIndex(i => i.id === id);
  if (index >= 0) {
    copy.splice(index, 1);
  }
  return copy;
};

const replaceRecord = (state, { record }) => {
  const copy = state.slice();
  const index = copy.findIndex(i => i.id === record.id);
  if (index >= 0) {
    copy.splice(index, 1, record);
  } else {
    copy.push(record);
  }
  return copy;
};

const replaceRecords = (state, { records }) => {
  const copy = state.slice();
  records.forEach((record) => {
    const index = copy.findIndex(i => i.id === record.id);
    if (index >= 0) {
      copy.splice(index, 1, record);
    } else {
      copy.push(record);
    }
  });
  return copy;
};

const providerDisclosures = (state = initialState.admin.providerExclusions, action) => {
  const { type, ...entity } = action;

  switch (type) {
    case types.REPLACE_PROVIDER_DISCLOSURE:
      return replaceRecord(state, entity);
    case types.REPLACE_PROVIDER_DISCLOSURES:
      return entity.records;
    case types.UPDATE_PROVIDER_DISCLOSURES: {
      if (entity.method === 'merge') {
        return replaceRecords(state, entity);
      }
      return updateRecords(state, entity);
    }
    case types.DELETE_PROVIDER_DISCLOSURES:
      return deleteRecord(state, entity);
    default:
      return state;
  }
};

export default { providerDisclosures };

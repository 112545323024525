import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'emotion';
import styled from 'react-emotion';
import { createTranslator, numeric } from 'helpers/i18n';
import { Date } from 'helpers/dates';

import TextFieldBase from 'components/utils/TextFieldBase';
import Field, { fieldPropTypes } from 'components/utils/Field';
import CalendarField from 'components/utils/CalendarField';
import Popup from 'components/utils/Popup';
import Icon from 'components/utils/Icon';
import { applyTestAttribute } from 'helpers/development';

const tr = createTranslator({
  today: 'Today',
  tomorrow: 'Tomorrow',
});

const Container = styled.div`
  position: relative;
`;

const iconStyle = css`
  position: absolute;
  top: 5px;
  left: 4px;
`;

const closeIconStyle = css`
  position: absolute;
  top: 6px;
  right: 6px;
  cursor: pointer;
`;

const IGNORED = [
  'Control', 'Alt', 'Meta', 'Tab',
];

/**
 * A text field where, upon clicking the field, a
 * calendar will pop up for date selection.
 */
class DateField extends React.Component {
  static propTypes = {
    ...fieldPropTypes,
    /** Passed to CalendarField to determine allowed ranges */
    isOutsideRange: PropTypes.func,
    /** Name of field */
    name: PropTypes.string,
    /** The position of the calendar popup */
    position: PropTypes.oneOf([
      'top', 'bottom', 'left', 'right',
      'top left',
      'top center',
      'top right',
      'bottom left',
      'bottom center',
      'bottom right',
      'right top',
      'right center',
      'right bottom',
      'left top',
      'left center',
      'left bottom',
    ]),
    /** Receive a moment UTC object when the date is selected */
    onChange: PropTypes.func,
    /** The first selectable year */
    startYear: PropTypes.number,
    /** The last selectable year */
    endYear: PropTypes.number,
    /** The initial date, in Date UTC format */
    value: PropTypes.string,
    /** Custom rendering for the value */
    valueRenderer: PropTypes.func,
    /** A test identifier, for integration testing */
    'data-testid': PropTypes.string,
  }

  static defaultProps = {
    isOutsideRange: undefined,
    onChange: undefined,
    name: undefined,
    position: 'bottom left',
    startYear: undefined,
    endYear: undefined,
    value: '',
    valueRenderer: undefined,
    'data-testid': 'date-picker',
  }

  constructor(props) {
    super(props);
    this.state = { value: props.value };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ value: nextProps.value });
  }

  cleanDate = date => date.set({
    hours: 0, minutes: 0, seconds: 0, milliseconds: 0,
  });

  dayAndDate = () => {
    if (this.state.value) {
      const now = this.cleanDate(Date.now().utc());
      const date = this.cleanDate(Date.parse(this.state.value));
      const diffDays = date.diff(now, 'days');
      const sameYear = now.isSame(date, 'year');
      return numeric(diffDays, {
        0: `${tr('today')}, ${date.format('MMM. D')}`,
        1: `${tr('tomorrow')}, ${date.format('MMM. D')}`,
        other: `${date.format('ddd, MMM. D')}${sameYear ? '' : `, ${date.format('\'YY')}`}`,
      });
    }
    return '';
  }

  cancelKey = (event) => {
    if (IGNORED.indexOf(event.key) < 0) {
      event.preventDefault();
    }
  }

  formatValue = () => (this.props.valueRenderer
    ? this.props.valueRenderer(this.state.value) : this.dayAndDate());

  handleDateChange = (value) => {
    this.setState({
      value: value ? Date.format(value) : null,
    }, () => {
      if (this.props.onChange) {
        this.props.onChange(this.state.value);
      }
    });
  }

  render() {
    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <Container {...applyTestAttribute(this.props)}>
        <Popup
          ref={(ref) => { this.popup = ref; }}
          position={this.props.position}
          triggerOn="focus"
          trigger={(
            <TextFieldBase
              indent
              name={this.props.name}
              onKeyDown={this.cancelKey}
              placeholder={this.props.placeholder}
              value={this.formatValue()}
            />
          )}
        >
          {({ close }) => (
            <CalendarField
              isOutsideRange={this.props.isOutsideRange}
              value={this.state.value ? Date.parse(this.state.value) : null}
              onChange={(value) => {
                close();
                this.handleDateChange(value);
              }}
              startYear={this.props.startYear}
              endYear={this.props.endYear}
            />
          )}
        </Popup>
        <Icon className={iconStyle} name="mp-date" size={30} />
        {this.state.value && (
          <Icon
            className={closeIconStyle}
            name="mp-cancel"
            size={28}
            onClick={() => this.handleDateChange(null)}
          />
        )}
      </Container>
    );
  }
}

export default Field(DateField);

/* eslint-disable camelcase */

import * as types from './types';

export const replaceReferralSearchIndex = results => dispatch => Promise.resolve(dispatch({
  type: types.REPLACE_REFERRALS_SEARCH_INDEX,
  results,
}));

export const updateReferralSearchContent = referrals => dispatch => Promise.resolve(dispatch({
  type: types.APPEND_REFERRALS_SEARCH_CONTENT,
  referrals,
}));

export const replaceReferralsSearchFilters = filters => dispatch => Promise.resolve(dispatch({
  type: types.REPLACE_REFERRALS_SEARCH_FILTERS,
  filters,
}));

import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'emotion';
import styled from 'react-emotion';
import { createTranslator } from 'helpers/i18n';

import {
  Panel, Colors, Styles,
} from 'components/utils';

import AidinLogo from 'components/branding/AidinLogo';

const { Info, Label } = Styles;

const { WHITE } = Colors;

const tr = createTranslator({
  tagline: 'The online marketplace for care transitions',
});

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

const logoStyle = css`
  width: 215px;
  height: 58px;
`;

const logoPanelStyle = css`
  .panel-item-1 {
    margin-bottom: 10px;
  }
  .panel-item-1 ${Label} {
    color: ${WHITE};
  }
`;

const TagLine = styled(Info)`
  text-align: center;
`;

const AidinLogoContainer = ({ hideTagline }) => (
  <LogoContainer>
    <Panel spacing={10} align="center">
      <Panel orientation="horizontal" align="bottom" className={logoPanelStyle}>
        <AidinLogo className={logoStyle} />
      </Panel>
      {!hideTagline && <TagLine>{tr('tagline')}</TagLine>}
    </Panel>
  </LogoContainer>
);
AidinLogoContainer.propTypes = {
  hideTagline: PropTypes.bool,
};
AidinLogoContainer.defaultProps = {
  hideTagline: undefined,
};

export default AidinLogoContainer;

import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { numeric } from 'helpers/i18n';

import { ThemeContext } from 'components/utils/ThemeContext';

require('./icons');

const ProviderProfileIcon = ({
  name,
  color,
  size,
}) => {
  const { theme } = useContext(ThemeContext);
  const iconName = name.startsWith('provider-') ? name : `provider-profile-${name}-icon`;
  const iconColor = numeric(color, {
    primary: theme.colors.RECEIVING_PRIMARY_BUTTON_FILL,
    secondary: theme.colors.SECONDARY_FILL,
  });
  return (
    <svg
      width={size}
      height={size}
      color={iconColor}
    >
      <use xlinkHref={`#${iconName}`} />
    </svg>
  );
};

ProviderProfileIcon.propTypes = {
  /** Icon name; can use the short name here too */
  name: PropTypes.string.isRequired,
  /** Either primary or secondary to use the theme; custom colors can be used too */
  color: PropTypes.oneOfType([
    PropTypes.oneOf(['primary', 'secondary']),
    PropTypes.string,
  ]),
  /** Icon size */
  size: PropTypes.number,
};

ProviderProfileIcon.defaultProps = {
  color: 'primary',
  size: 18,
};

export default ProviderProfileIcon;

/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'emotion';
import styled from 'react-emotion';

import Icon from 'components/utils/Icon';

import * as colors from 'components/utils/Colors';
import * as styles from 'components/utils/Styles';

const { TAG_DEFAULT, BLACK } = colors;
const { Label } = styles;

const Container = styled.div`
  background-color: ${props => props.color || TAG_DEFAULT};
  display: inline-flex;
  align-items: center;
  padding: 4px 5px 3px;
  -ms-user-select: none;
  user-select: none;
  ${Label} {
    ${props => (props.textColor ? `color: ${props.textColor} !important;` : '')}
  }
`;

const iconStyle = css`
  margin-right: 6px;
`;

/**
 * Tag display text in label format with an optional icon
 */
const Tag = React.forwardRef(({
  children,
  color,
  element,
  icon,
  textColor,
  ...passThrough
} = {}, ref) => {
  const Element = element || Label;

  return (
    <Container {...passThrough} color={color} textColor={textColor} innerRef={ref}>
      {icon && <Icon className={iconStyle} color={BLACK} name={icon} />}
      <Element>{children}</Element>
    </Container>
  );
});
Tag.propTypes = {
  /** The text to display. Should be a string, but can be anything. */
  children: PropTypes.node.isRequired,
  /** The background color of the tag. Gray default */
  color: PropTypes.string,
  /** custom wrapper to use instead of Label */
  element: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
  ]),
  /** The name of the icon. */
  icon: PropTypes.string,
  /** The text color */
  textColor: PropTypes.string,
};
Tag.defaultProps = {
  color: TAG_DEFAULT,
  element: undefined,
  icon: undefined,
  textColor: undefined,
};

export default Tag;

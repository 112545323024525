import PropTypes from 'prop-types';
import AidinPropTypes from 'helpers/AidinPropTypes';

const User = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  title: PropTypes.string,
  first: PropTypes.string,
  last: PropTypes.string,
  avatar_url: PropTypes.string,
  created_at: AidinPropTypes.dateTime,
  updated_at: AidinPropTypes.dateTime,
  fax: PropTypes.string,
  sms: PropTypes.string,
  phone: PropTypes.string,
  prefs: PropTypes.shape({}),
  email: PropTypes.string,
  abilities: PropTypes.objectOf(PropTypes.bool),
});

export default User;

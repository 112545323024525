/* eslint-disable react/no-array-index-key, react/no-danger */

import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'emotion';
import styled from 'react-emotion';
import Popup from 'reactjs-popup';

import Panel from 'components/utils/Panel';
import * as styles from 'components/utils/Styles';
import { createTrigger, modalOverrides, baseModalStyleAttributes } from 'components/utils/PopupUtils';
import helpTextParser from 'helpers/helpTextParser';

import { createTranslator } from 'helpers/i18n';
import translations from 'translations/help';

const { Secondary } = styles;

const tr = createTranslator(translations);

const helpTextStyle = css`
  border-bottom: 1px dotted #cccccc;
`;

const modalStyle = {
  ...baseModalStyleAttributes,
  width: '300px',
};

const ModalContent = styled(Secondary)`
  opacity: 0.5;
  padding: 20px;
  ul {
    margin-left: 16px;
  }
`;

const valueRenderer = html => value => (!html ? helpTextParser(tr(value) || value) : (
  <div dangerouslySetInnerHTML={{ __html: tr(value) || value }} />
));

const parseHelpText = (value, asHtml) => {
  const renderer = valueRenderer(asHtml);
  if (!value) { return value; }
  if (typeof value === 'string') { return renderer(value); }
  if (typeof value === 'object' && value.length) {
    return (
      <Panel>
        {value.map((s, idx) => <div key={idx}>{parseHelpText(s, asHtml)}</div>)}
      </Panel>
    );
  }
  return value;
};

/**
 * Display text with a dotted line underneath, revealing contextual help text
 * upon mouseover or click. Currently, custom content is not supported, all
 * text must be provided via the value property. This may change in the future.
 */
const HelpText = (props) => {
  const Content = props.element;
  const component = (
    <span className={props.noUnderline ? undefined : helpTextStyle}>{props.text}</span>
  );
  const trigger = Content
    ? <Content {...props.elementProps /* eslint-disable-line */}>{component}</Content>
    : component;
  const triggerElement = props.element ? null : undefined;
  return (
    <>
      <Popup
        contentStyle={{
          ...modalOverrides,
          ...modalStyle,
        }}
        arrow={false}
        on="hover"
        trigger={createTrigger({ ...props, triggerElement }, trigger)}
        position={`${props.position} center`}
      >
        <ModalContent>
          {parseHelpText(props.value, props.html)}
        </ModalContent>
      </Popup>
    </>
  );
};
HelpText.displayName = 'HelpText';
HelpText.propTypes = {
  /** Element to wrap the help text */
  element: PropTypes.oneOfType([
    PropTypes.object, PropTypes.element, PropTypes.node, PropTypes.func, PropTypes.string,
  ]),
  /** Props to pass to the given element */
  elementProps: PropTypes.shape({}),
  /** True to parse this as HTML, false otherwise */
  html: PropTypes.bool,
  /** True to disable bottom border, false otherwise (default false) */
  noUnderline: PropTypes.bool,
  /** Position of the tooltip */
  position: PropTypes.oneOf(['top', 'bottom', 'left', 'right']),
  /** The text to highlight for help */
  text: PropTypes.string.isRequired,
  /** Perhaps the ID or lookup key of help text, to lookup dynamically. */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]).isRequired,
};
HelpText.defaultProps = {
  element: null,
  elementProps: undefined,
  html: undefined,
  noUnderline: undefined,
  position: 'bottom',
};

export default HelpText;

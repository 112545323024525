/** Default empty app skeleton configration, mainly for styleguide */
const defaultConfig = {
  maps: {
    apiKey: null,
  },
};

/** The global config defined from Rails/Javascript, or our defaults */
const Config = window.AidinNext || defaultConfig;

/** Additional app config */
Config.supportClassicMode = false;

// Import all JSON from this dir and add it to our Config
const context = require.context('./', true, /\.json$/);
context.keys().forEach((file) => {
  Config[file.slice(2, -5)] = context(file);
});

export default Object.freeze(Config);

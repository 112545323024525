import React from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion';
import { connect } from 'react-redux';
import { activateZendeskModal } from 'ducks/session';
import { createTranslator } from 'helpers/i18n';
import markdown from 'helpers/markdown';

import text from './translations';

const tr = createTranslator(text);

const List = styled.ul`
  display: flex;
  flex-direction: column;
`;

const ListItem = styled.li`
  margin: 10px 0;
  word-wrap: break-word;
  max-width: 350px;
`;

const HelpSuggestions = ({
  activateZendeskModal: renderZendeskModal,
}) => {
  const handleAction = (action) => {
    if (action === 'activateZendeskModal') {
      return renderZendeskModal();
    }
    return null;
  };
  return (
    <List>
      {Object.entries(tr('suggestions')).map(([key, txt]) => (
        <ListItem key={key}>
          <div>{markdown(txt, handleAction)}</div>
        </ListItem>
      ))}
    </List>
  );
};

HelpSuggestions.propTypes = {
  activateZendeskModal: PropTypes.func.isRequired,
};

export default connect(null, { activateZendeskModal })(HelpSuggestions);

/* eslint-disable no-nested-ternary */

import React from 'react';
import PropTypes from 'prop-types';
import AidinPropTypes from 'helpers/AidinPropTypes';
import moment from 'moment';
import momentPropTypes from 'react-moment-proptypes';
import { Date, DateTime } from 'helpers/dates';
import {
  createTranslator, numeric, relativeTimeSince, relativeTimeUntil,
} from 'helpers/i18n';
import { relativeDaysAgo, relativeDaysUntil } from 'translations/utils';
import momentIntializer from 'translations/moment';

const MINUTE = 60 * 1000;
const HOUR = 60 * MINUTE;
const DAY = 24 * HOUR;

const tr = createTranslator({
  empty: 'No date set',
  now: 'just now',
  discharge: {
    after: 'Started service',
    other: 'Starting service',
  },
  dischargeFrom: {
    after: 'Started service from ',
    other: 'Starting service from',
  },
});

/**
 * The default datetime formatter. Uses moment formatting.
 * @public
 */
export const defaultFormatter = {
  before: ({ since, now }) => now.to(since),
  now: tr('now'),
  after: ({ since, now }) => since.from(now),
};

export const defaultOptions = {
  before: { prefix: true },
  now: { prefix: true },
  after: { suffix: true },
};

const getFormatPrefix = (era, oracle) => {
  const prefix = oracle && numeric(era, oracle, '');
  return prefix ? `${prefix} ` : '';
};

/**
 * Formatter helper that prefixes relative days with the given text.
 */
export const relativeDaysFormatter = (oracle, optionsOracle = defaultOptions) => ({
  before: ({ since, now, era }) => `${getFormatPrefix(era, oracle)}${relativeDaysUntil(since, numeric(era, optionsOracle), now)}`,
  now: ({ since, now, era }) => `${getFormatPrefix(era, oracle)}${relativeDaysAgo(since, numeric(era, optionsOracle), now)}`,
  after: ({ since, now, era }) => `${getFormatPrefix(era, oracle)}${relativeDaysAgo(since, numeric(era, optionsOracle), now)}`,
});

export const relativeDaysFormatterFrom = (oracle, hospital, optionsOracle = defaultOptions) => ({
  before: ({ since, now, era }) => `${getFormatPrefix(era, oracle)}${hospital} ${relativeDaysUntil(since, numeric(era, optionsOracle), now)}`,
  now: ({ since, now, era }) => `${getFormatPrefix(era, oracle)}${hospital} ${relativeDaysAgo(since, numeric(era, optionsOracle), now)}`,
  after: ({ since, now, era }) => `${getFormatPrefix(era, oracle)}${hospital} ${relativeDaysAgo(since, numeric(era, optionsOracle), now)}`,
});

export const relativeTimeFormatter = (oracle, optionsOracle = defaultOptions) => ({
  before: ({ since, now, era }) => `${getFormatPrefix(era, oracle)}${relativeTimeUntil(since, numeric(era, optionsOracle), now)}`,
  now: ({ since, now, era }) => `${getFormatPrefix(era, oracle)}${relativeTimeSince(since, numeric(era, optionsOracle), now)}`,
  after: ({ since, now, era }) => `${getFormatPrefix(era, oracle)}${relativeTimeSince(since, numeric(era, optionsOracle), now)}`,
});

/**
 * A helper formatter specifically for discharge dates
 * @public
 */

export const dischargeDateFormatter = relativeDaysFormatter(tr('discharge'));

export const dischargeFromDateFormatter = hospital => relativeDaysFormatterFrom(tr('dischargeFrom'), hospital.name);

const shortFormat = (since, now, options = { suffix: true }) => {
  moment.updateLocale(since.locale(), {
    relativeTime: {
      s: 'now',
      ss: '%ds',
      m: '1m',
      mm: '%dm',
      h: '1h',
      hh: '%dh',
      d: '1d',
      dd: '%dd',
      M: '1mo',
      MM: '%dmo',
      y: '1y',
      yy: '%dy',
    },
  });

  const value = relativeTimeSince(since, options, now);

  momentIntializer();

  return value;
};

/**
 * An abbreviated format for date times
 */
export const shortFormatter = {
  before: defaultFormatter.now, /* Should never happen */
  now: defaultFormatter.now,
  after: ({ since, now }) => shortFormat(since, now),
};

/**
 * A VERY abbreviated format for date times
 */
export const tinyFormatter = {
  before: defaultFormatter.now, /* Should never happen */
  now: defaultFormatter.now,
  after: ({ since, now }) => shortFormat(since, now, { suffix: false }),
};

export const dayFormatFormatter = {
  other: ({ since }) => Date.dayFormat(since),
};

/**
 * Determines there refresh rate; the checks should become shorter and
 * longer as the time difference becomes shorter and longer.
 */
export const defaultRefreshRate = (diff) => {
  if (diff < MINUTE) {
    return 10 * 1000; // Update every 10 seconds for 59 seconds.
  }
  if (diff < HOUR) {
    return 30 * 1000; // Update every 30 seconds for up to 59 minutes.
  }
  if (diff < DAY) {
    return HOUR / 2; // Update every half hour up to 23 hours.
  }
  return DAY; // Otherwise, update daily.
};

/**
 * Display relative time for a date with live updating.
 *
 * Updating will occur more frequently for closer times and back off to
 * daily intervals for longer time periods. Custom formatters can be
 * supplied for each time era.
 *
 * Under the hood, this uses the `<time>` tag and supplies a title that
 * displays the actual date and time on mouseover.
 */
export default class RelativeTime extends React.Component {
  static propTypes = {
    /** Custom class name for the element container */
    className: PropTypes.string,
    /**
     * The container element for this object. Default to a div
     */
    element: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    /**
     * Default element displayed when the value is null.
     */
    empty: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.node]),
    /**
     * True to disable auto-refresh, such that the first relative
     * time displayed remains on-screen unchanged. False othweriwse
     * (default false)
     */
    fixed: PropTypes.bool, // eslint-disable-line react/no-unused-prop-types
    /**
     * Formatter to handle date/datetime formats. The object should
     * contain formats for past, present, and future dates, though
     * any missing keys will be overridden by the defaultFormatter.
     *
     * * `before`: Format a date that is in the future.
     * * `now`: Format a date that is right now, or near now (same minute).
     * * `after`: Format a date that is in the past.
     *
     * Each of these formatter options can be either a displayable object,
     * such as a string or node, or a function, which returns a displayable
     * object. In the case of a function, an object will be passed as a
     * parameter containing the following properties:
     *
     * * `since`: the initial date/time value
     * * `now`: the current date/time
     * * `era`: one of `before`, `now`, or `after`.
     */
    formatter: PropTypes.shape({
      /** Format relative times that are in the future (in x hours) */
      before: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
      /** Format relative times that are now (just now) */
      now: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
      /** Format relative times that are in the past (x hours ago) */
      after: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
      /** Catch-all, so you don't have to supply each case */
      other: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    }),
    /**
     * Supply a custom refresh rate. This can either be a fixed number, or
     * a function. In the case of a function, the time difference in ms
     * between the value and the current time will be supplied as an
     * argument.
     *
     * By default, the refresh rate uses `defaultRefreshRate`, which
     * refreshes 10s/min, 30s/hour, 30m/day, and daily after that.
     */
    // eslint-disable-next-line react/no-unused-prop-types
    refreshRate: PropTypes.oneOfType([PropTypes.number, PropTypes.func]),
    /**
     * The current time relative to the value. Replaces `now()` functions.
     */
    since: PropTypes.oneOfType([AidinPropTypes.date, AidinPropTypes.dateTime]),
    /**
     * A templating function that returns a value based on the formatted text.
     * Useful for adding prefix/postfix text, or even adding a wrapping component
     * or icon.
     *
     * The function take a single argument, the formatted text. For era-aware
     * formatting, use the formatter prop instead.
     */
    template: PropTypes.func,
    /**
     * Time unit to which the time is relative. Defaults to minutes.
     */
    // eslint-disable-next-line react/no-unused-prop-types
    unit: PropTypes.oneOf(['days', 'minutes', 'seconds']),
    /**
     * The date or datetime value to which the display should be relative.
     * Can be either a moment object or a date/datetime-compatible string.
     * This value is expected to be a "raw" UTC value from the database.
     * This component will handle time zone display conversion internally.
     */
    value: PropTypes.oneOfType([
      momentPropTypes.momentObj,
      AidinPropTypes.date,
      AidinPropTypes.dateTime,
    ]).isRequired,
  }

  static defaultProps = {
    className: undefined,
    element: 'div',
    empty: tr('empty'),
    fixed: undefined,
    refreshRate: defaultRefreshRate,
    since: undefined,
    template: undefined,
    unit: 'minutes',
    formatter: defaultFormatter,
  }

  constructor(props) {
    super(props);
    this.state = { text: undefined };
    this.interval = null;
    this.intervalFrequency = null;
  }

  UNSAFE_componentWillMount() {
    this.refresh();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.props.value) {
      // New value, reset interval
      this.resetInterval();
    }
    this.refresh(nextProps);
  }

  componentWillUnmount() {
    this.resetInterval();
  }

  /**
   * Use either the actual current time, or the provided time.
   */
  getCurrentTime = () => (
    this.props.since ? this.getDateParser().parse(this.props.since) : this.getDateParser().now()
  );

  getDateParser = () => (['day', 'days'].some(v => v === this.props.unit) ? Date : DateTime);

  /**
   * Calculate the interval delay in ms based on the
   * given refresh rate. If no refresh rate is given,
   * use the defaultRefreshRate instead.
   */
  getIntervalDelay = (refreshRate, diff) => {
    let result = null;
    if (refreshRate) {
      switch (typeof refreshRate) {
        case 'number':
          result = refreshRate;
          break;
        case 'function':
          result = refreshRate(diff);
          break;
        default:
          break;
      }
    }
    return result || defaultRefreshRate(diff);
  }

  /**
   * Re-format the time string, and update the state if necessary.
   * Also, set or reset the refresh interval if necessary.
   */
  refresh = (props) => {
    const {
      fixed, refreshRate, value, unit,
    } = props || this.props;
    const DateParser = this.getDateParser();
    const now = DateParser.clearDateTime(this.getCurrentTime());
    const since = DateParser.clearDateTime(DateParser.parse(value), unit);
    const diff = Math.floor(now.diff(since, unit, true));

    const era = diff === 0 ? 'now' : diff < 0 ? 'before' : 'after';
    const text = this.renderFormattedTime({ since, now, era });

    if (text !== this.state.text || era !== this.state.era) {
      this.setState({ text, era });
    }

    if (!fixed) {
      const intervalFrequency = this.getIntervalDelay(refreshRate, Math.abs(diff));
      const currentFrequency = this.intervalFrequency;
      if (!this.interval || intervalFrequency !== currentFrequency) {
        this.resetInterval();
        this.intervalFrequency = intervalFrequency;
        this.interval = setInterval(this.refresh.bind(this), intervalFrequency);
      }
    }
  }

  resetInterval = () => {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = undefined;
      this.intervalFrequency = undefined;
    }
  }

  /**
   * TODO: Ensure this is a good format. Should be standardized.
   */
  renderDateTimeInfo = () => {
    const { unit, value } = this.props;
    if (!value) { return null; }

    if (unit === 'days') {
      return this.getDateParser().dayFormat(value, { format: 'M/D' });
    }
    return this.getDateParser().dayFormat(value, {
      format: 'M/D h:mma',
      otherFormat: 'M/D/YY h:mma',
    });
  }

  renderFormattedTime = ({ since, now, era }) => {
    if (since) {
      const defaults = this.props.formatter && this.props.formatter.other ? {} : defaultFormatter;
      const formatter = {
        ...defaults,
        ...this.props.formatter,
      };
      const fmt = numeric(era, formatter);
      return typeof fmt === 'function' ? fmt({ since, now, era }) : fmt;
    }
    return '...';
  }

  renderText = (text, era) => (this.props.template ? this.props.template(text, era) : text);

  render() {
    const Element = this.props.element || RelativeTime.defaultProps.element;
    const dateTime = this.renderDateTimeInfo();
    if (!dateTime) {
      return <Element>{this.props.empty}</Element>;
    }
    return (
      <>
        <Element className={this.props.className}>
          <time dateTime={dateTime} title={dateTime}>
            {this.renderText(this.state.text, this.state.era)}
          </time>
        </Element>
      </>
    );
  }
}

export default {
  privacy: `
    Before proceeding, you must confirm that you are authorized to access Protected Health Information (PHI) as defined under the Health Insurance Portability and Accountability Act on behalf of the organization listed. You must also agree to limit your access to the minimum amount of information necessary to perform a permitted treatment or other health care operations activity. In the event you obtain access to information that you are not authorized to view, please notify the referral source. Failure to comply with these terms may result in immediate termination of you and your organization’s access to MRG Inc.’s website. Privacy Breaches: Be very careful to check the ordering physician’s full name, their specialty and the last four digits of their TIN or NPI before selecting them in this system. By sending patients’ Protected Health Information (PHI) to physicians who are not the ordering physicians, you may be in violation of HIPAA Privacy Act.
  `,
  fraud: `
    The Provider/Customer and Authorization Request Submitter certify that all information supplied on the prior authorization form, all attachments, and any other accompanying information constitute true, correct, and complete information. The Provider/Customer and Authorization Request Submitter understand that falsifying entries, concealment of material, or pertinent omissions may constitute fraud and may be prosecuted under applicable federal and/or state laws. Fraud is a felony, which can result in fines or imprisonment. The Provider/Customer and Authorization Request Submitter understand and acknowledge that violation of any provision of this Certificate Statement may subject the Submitter to suspension or permanent revocation of privileges enjoyed under an agreement with MRG Inc.
  `,
  links: {
    backToLogin: 'Return to Log In',
  },
};

/* eslint-disable import/prefer-default-export */
import styled from 'react-emotion';
import { css } from 'emotion';
import { mq } from 'components/utils/Responsive';
import { numeric } from 'helpers/i18n';

import {
  primaryButtonStyle,
  textInputHeightV2,
} from 'components/login/LoginStyles';

const baseButtonBarStyle = css(mq({
  marginTop: '20px',
  '> div': {
    width: [undefined, '100%', '100%'],
  },
}));

const baseSuccessContainer = styled.div({
  padding: '20px',
});

const containerDiv = styled.div();

export const createComponentStyles = ({ version, theme: { colors } }) => numeric(version, {
  other: {
    styles: {
      Container: containerDiv,
      SuccessContainer: baseSuccessContainer,
      buttonDarkerColor: colors.RECEIVING_DARKER_FILL,
      buttonBarStyle: baseButtonBarStyle,
      spinnerColor: colors.RECEIVING_DARKER_FILL,
      errorTipColor: colors.ERROR_LIGHTEST_FILL,
    },
  },
  v2: {
    styles: {
      Container: containerDiv,
      SuccessContainer: baseSuccessContainer,
      loginButtonStyle: primaryButtonStyle,
      buttonDarkerColor: colors.RECEIVING_DARKEST_FILL,
      textInputHeight: textInputHeightV2,
      buttonBarStyle: baseButtonBarStyle,
      spinnerColor: colors.RECEIVING_DARKER_FILL,
      errorTipColor: colors.ERROR_LIGHTEST_FILL,
    },
  },
});

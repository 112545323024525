/* eslint-disable camelcase */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'react-emotion';
import { createTranslator } from 'helpers/i18n';
import md from 'helpers/markdown';


import {
  Panel, Popup, Styles, Icon, Colors, SplitPanel, ZendeskSessionLink,
} from 'components/utils';

const { SECONDARY_FILL } = Colors;

const { LinkText, PopupContent } = Styles;

const tr = createTranslator({
  support: 'Aidin Support',
  appOnboarding: 'Tutorials: How Aidin Works',
  dashboardTooltips: 'View Dashboard Tutorial',
  profileCompletionReceiver: 'Completing your Profile for Receiving Referrals',
  profileCompletionSender: 'Completing your Profile for Sending Referrals',
  receivingReferralDetails: 'View Referral Details Tutorial',
  receivingReferralRespondStep: 'How to Respond to a Referral',
  sendingReferralDetails: 'How to Send a Referral',
  sendingReferralOverview: 'View Referral Overview Tutorial',
  sendingReferralProviderSearch: 'View Provider Search Tutorial',
  accountSettings: '[Getting started in Aidin](https://rebrand.ly/account_settings)',
  sendReferral: '[How to send a referral](https://rebrand.ly/aidin_sending)',
  receiveAndRespondReferral: '[How to receive & respond to a referral](https://rebrand.ly/aidin_responding)',
  learnAidin: '[Learn how to use Aidin](https://rebrand.ly/aidin_webinar)',
  sender_title: 'Tutorials: Senders of Referrals',
  receiver_title: 'Tutorials: Receivers of Referrals',
  webinar_title: 'Webinars with Aidin',
  sender_tutorials: {
    tutorial_one: '[Tutorial 1: Update Account Settings](https://rebrand.ly/sender_account)',
    tutorial_two: '[Tutorial 2: How to Send a Referral](https://rebrand.ly/aidin_sending)',
    tutorial_three: '[Tutorial 3: Resumption of Care](https://rebrand.ly/resuming_care)',
    tutorial_four: '[Tutorial 4: Changing a Level of Care](https://rebrand.ly/level_of_care)',
    tutorial_five: '[Tutorial 5: Reopen a Referral](https://rebrand.ly/reopen_referral)',
    tutorial_six: '[Tutorial 6: Unreserve/Unfollow](https://rebrand.ly/osu-unreserve-unfollow)',
    tutorial_seven: '[Tutorial 7: Tips & Tricks](https://rebrand.ly/tips_tricks)',
  },
  receiver_tutorials: {
    tutorial_one: '[Tutorial 1: Getting Access](https://rebrand.ly/getting-access)',
    tutorial_two: '[Tutorial 2: How Referrals are Sent](https://rebrand.ly/sending-side)',
    tutorial_three: '[Tutorial 3: Dashboard / Reviewing Referrals](https://rebrand.ly/dashboard-reviewing-referrals)',
    tutorial_four: '[Tutorial 4: Responding to Referrals](http://rebrand.ly/aidin_responding)',
    tutorial_five: '[Tutorial 5: Account Settings](http://rebrand.ly/account_settings)',
  },
  webinar_tutorials: {
    sender: '[Senders: Office Hours with Aidin!](https://rebrand.ly/hospital_webinar)',
    receiver_on_demand: '[Receivers: Receiver On Demand!](https://rebrand.ly/aidin_webinar)',
    receiver_live: '[Receivers: Live Webinar with Aidin!](https://rebrand.ly/provider_live_webinar)',
  },
});

const MenuLink = styled(LinkText.withComponent('div'))`
  padding: 5px 0px;
`;

const sections = [
  {
    title: tr('sender_title'),
    items: Object.values(tr('sender_tutorials')),
  },
  {
    title: tr('receiver_title'),
    items: Object.values(tr('receiver_tutorials')),
  },
  {
    title: tr('webinar_title'),
    items: Object.values(tr('webinar_tutorials')),
  },
];

const HelpMenu = ({
  onOpen,
  trigger,
}) => (
  <Popup
    width={265}
    trigger={trigger}
    position="bottom right"
    onOpen={onOpen}
    offsetY={10}
    disableScrolling
  >
    {({ close }) => (
      <PopupContent>
        <Panel spacing={10}>
          {sections.map(({ title, items = [] } = {}) => (
            <Popup
              key={title}
              width={275}
              trigger={(
                <SplitPanel align="center" style={{ justifyContent: 'space-between' }}>
                  <MenuLink>{title}</MenuLink>
                  {items.length && <Icon name="mp-chevron-right" color={SECONDARY_FILL} style={{ marginTop: 5 }} />}
                </SplitPanel>
              )}
              triggerOn={items.length ? 'hover' : 'click'}
              position="bottom left"
              offsetX={40}
              offsetY={8}
            >
              <PopupContent>
                <Panel spacing={10}>
                  {items.map(item => (
                    <MenuLink key={item} onClick={() => close()}>{md(item)}</MenuLink>
                  ))}
                </Panel>
              </PopupContent>
            </Popup>
          ))}
          <ZendeskSessionLink onClick={close} component={MenuLink} />
        </Panel>
      </PopupContent>
    )}
  </Popup>
);

HelpMenu.propTypes = {
  onOpen: PropTypes.func,
  trigger: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
};

HelpMenu.defaultProps = {
  onOpen: undefined,
};

export default connect(({ referral }) => ({ referral }))(HelpMenu);

/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { css } from 'emotion';
import styled from 'react-emotion';
import Link from 'components/utils/Link';
import { compose } from 'redux';
import { connect } from 'react-redux';
import * as notificationActions from 'ducks/notifications';
import * as sessionActions from 'ducks/session';
import * as uiActions from 'ducks/ui';
import { trackEvent } from 'helpers/analytics';
import { applyTestIdentifier } from 'helpers/development';
import { FEATURE_UPGRADE_RELEASE_4 } from 'ducks/session/types';

import Message from 'models/Message';
import Notification from 'models/Notification';
import Session from 'models/Session';

import { createTranslator, numeric } from 'helpers/i18n';
import { DateTime } from 'helpers/dates';
import { Responsive, mq } from 'components/utils/Responsive';
import Panel from 'components/utils/Panel';
import Button from 'components/utils/Button';
import Tag from 'components/utils/Tag';
import Divider from 'components/utils/Divider';
import PageContent from 'components/utils/PageContent';
import withAvailableThemes from 'routes/withAvailableThemes';
import withThemeProvider from 'components/utils/withThemeProvider';

import {
  RECEIVING_DARKER_FILL, NOTIFICATION_FILL, WHITE, DARKER_GRAY_FILL,
} from 'components/utils/Colors';

import withConversationActions, { MessageApi } from 'components/messages/withConversationActions';
import AidinLogoWhite from 'components/branding/AidinLogoWhite';

import ConversationModal from 'components/messages/ConversationModal';
import NewMessagePopupPanel from 'components/messages/NewMessagePopupPanel';
import VerifiedAdminStatusIcon from 'components/admin/providers/VerifiedAdminStatusIcon';

import avatar from 'components/branding/avatar@2x.png';
import VerifiedAdminActions from 'components/admin/providers/VerifiedAdminActions';

import InAppNotifNotificationIcon from './InAppNotifNotificationIcon';
import MessageNotificationIcon from './MessageNotificationIcon';
import HelpMenu from './HelpMenu';
import SettingsMenu, { getSettingsMenuItems, getSettingsMenuSecondaryLinks } from './SettingsMenu';
import SearchBox from './SearchBox';
import ViewOnlyIndicator from './ViewOnlyIndicator';

import ProviderVerificationWarningIcon from './ProviderVerificationWarningIcon';
import ProviderVerificationWarningPanel from './ProviderVerificationWarningPanel';

import {
  Icon,
} from './MenuBarStyles';

const Menu = styled.div(({ hasBanner }) => mq({
  position: ['absolute', 'fixed', 'fixed'],
  top: `${hasBanner ? 41 : 0}px`,
  left: '0px',
  right: '0px',
  color: WHITE,
  backgroundColor: DARKER_GRAY_FILL,
  height: '45px',
  zIndex: 11,
  '-webkit-font-smoothing': 'antialiased',
}));

const AvatarContainer = styled.div(mq({
  display: 'flex',
  marginTop: '9px',
  background: '#cccccc',
  width: '25px',
  height: '25px',
  borderRadius: '100%',
  overflow: 'hidden',
}));

const MenuContentBase = styled.div`
  display: flex;
  justify-content: space-between;
`;

const MenuContent = ({ children } = {}) => ( // eslint-disable-line
  <PageContent element={MenuContentBase}>
    {children}
  </PageContent>
);

const menuLogoStyle = css`
  flex: 1;
  cursor: pointer;
  box-sizing: border-box;
  padding-top: 12px;
  :hover {
    opacity: 0.8;
  }
  -ms-flex-preferred-size: 75px;
`;

const menuIconLinksSection = css`
  display: flex;
  width: 35%;
  justify-content: flex-end;
`;

const menuLinkStyle = css(mq({
  padding: ['14px 1rem 10px', '6px 0', '6px 0'],
  fontFamily: 'futura-pt',
  fontSize: '14px',
  fontWeight: '500',
  textDecoration: 'none',
  color: WHITE,
  display: 'flex',
  cursor: ['pointer', 'default', 'default'],
  textAlign: ['center', 'right', 'right'],
  '&:hover': {
    opacity: '0.8',
  },
}));


const SettingsContainer = styled.div`
  ${menuLinkStyle}
`;

const HelpMenuContainer = styled.div`
  ${menuLinkStyle}
`;

const menuCapsStyle = css`
  ${menuLinkStyle}
  text-transform: uppercase;
`;

const imageResponsive = css`
  max-width: 100%;
  height: auto;
  display: block;
`;

const PaddedMenuLink = styled.div`
  padding: 0 .5rem;
`;

const MenuDrawer = styled.div`
  position: fixed;
  width: 240px;
  top: 44px;
  right: 0px;
  bottom: 0px;
  background-color: #666666;
  padding: 0px 10px;
  display: flex;
  flex-direction: column;
  text-align: right;
  -webkit-font-smoothing: antialiased;
`;

const SearchDrawer = styled.div`
  position: fixed;
  width: 210px;
  height: 50px;
  top: 44px;
  right: 0px;
  background-color: #666666;
  padding: 0px 10px;
  display: flex;
  flex-direction: column;
  text-align: right;
  -webkit-font-smoothing: antialiased;
`;

const InboxIndicatorsContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const InboxIndicatorContainer = styled.div`
  display: flex;
`;

const loggedOutHelpStyle = css`
  flex: 0;
  padding-left: 1rem;
  padding-right: 1rem;
`;

const MenuLink = styled.div`
  ${menuLinkStyle}
  display: inline-block;
  cursor: pointer;
`;

const viewOnlyIconStyle = index => numeric(index, {
  1: css`
    align-self: center;
  `,
  other: undefined,
});

const verifiedAdminIconModifier = css`
  display: inline-block;
  max-width: 12px;
  vertical-align: text-top;
`;

const providerWarningIconMobileStyle = css`
  position: absolute;
  top: 7px;
  right: 10px;
`;

const tr = createTranslator({
  main: {
    menu: {
      dashboard: 'Dashboard',
      help: 'Help',
      settings: 'Settings',
      user: 'User',
      inbox: 'Aidin Inbox',
      logout: 'Log out',
      login: 'Log In to Aidin',
    },
    search: {
      hint: 'Search by Name or ID',
    },
  },
  loggedInAs: 'Logged in as',
});

const ZendeskLink = styled.span`
  ${menuLinkStyle}
  ${loggedOutHelpStyle}
`;

const isReferralCurrent = ({
  created_at: createdAt,
  patient_visit: {
    discharge,
  } = {},
} = {}) => !discharge || DateTime.parse(createdAt) > DateTime.now().subtract(90, 'day');

class MenuBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      drawerOpen: false,
      searchOpen: false,
    };
  }

  isLoggedIn = () => this.props.session.user.id;

  toggleDrawer = () => {
    this.setState(({ drawerOpen }) => ({
      drawerOpen: !drawerOpen,
      searchOpen: false,
    }), () => {
      if (this.state.drawerOpen) {
        trackEvent('Site Header', 'Click', 'Settings (Mobile)');
      }
    });
  };

  toggleSearch = () => {
    this.setState(({ searchOpen }) => ({
      searchOpen: !searchOpen,
      drawerOpen: false,
    }), () => {
      if (this.state.searchOpen) {
        trackEvent('Site Header', 'Click', 'Open Search (Mobile)');
      }
    });
  };

  handleActionClick = (action) => {
    this.reset(() => {
      switch (action) {
        case 'openIdentityVerificationModal': {
          this.props.openIdentityVerificationModal();
          break;
        }
        default:
          break;
      }
    });
  }

  reset = (andThen) => {
    this.setState({
      searchOpen: false,
      drawerOpen: false,
    }, andThen);
  };

  renderMobileUserDisplay = () => this.isLoggedIn() && (
    <>
      <Divider />
      <Panel spacing={-10} align="right">
        <div css={menuLinkStyle}>{tr('loggedInAs')}:</div>
        <div css={menuLinkStyle}>{this.props.session.user.name}</div>
        {!this.props.session.user.previewer
          && this.props.session.user.name !== this.props.session.user.email && (
          <div css={menuLinkStyle}>{this.props.session.user.email}</div>
        )}
      </Panel>
    </>
  );

  renderPreviewUserMenuBar = () => (
    <MenuContent>
      <Link to="/" {...applyTestIdentifier('aidin-logo')} css={menuLogoStyle} style={{ display: 'flex' }}>
        <AidinLogoWhite css="height: 20px; width: 74px;" />
      </Link>
      <Responsive size="large">
        <ZendeskLink onClick={this.props.activateZendeskModal}>{tr('main.menu.help')}</ZendeskLink>
        {this.props.session.user.previewer && (
          <Link style={{ display: 'flex', alignItems: 'center', textDecoration: 'none' }} to="/">
            <Button small color={RECEIVING_DARKER_FILL}>
              {tr('main.menu.login')}
            </Button>
          </Link>
        )}
        <MenuLink onClick={this.props.logoutUser}>{tr('main.menu.logout')}</MenuLink>
      </Responsive>
      <Responsive size={['small', 'medium']}>
        <div css="display: flex">
          <Icon
            className="mp-list"
            onClick={this.toggleDrawer}
          />
          { this.state.drawerOpen
            && (
            <MenuDrawer>
              <Link to="/zendesk_session" target="_blank" css={menuLinkStyle}>{tr('main.menu.help')}</Link>
              <Link css={menuLinkStyle} to="/">{tr('main.menu.login')}</Link>
              <MenuLink onClick={this.props.logoutUser}>{tr('main.menu.logout')}</MenuLink>
              {this.renderMobileUserDisplay()}
            </MenuDrawer>
            )}
          { this.isLoggedIn() && this.state.searchOpen
            && (
            <SearchDrawer>
              <SearchBox
                session={this.props.session}
                onClose={() => { this.setState({ searchOpen: false }); }}
              />
            </SearchDrawer>
            )}
        </div>
      </Responsive>
    </MenuContent>
  )

  renderFullUserMenuBar = () => (
    <MenuContent>
      <Panel orientation="horizontal" spacing={16} itemClassName={viewOnlyIconStyle}>
        <Link to="/" css={menuLogoStyle} style={{ display: 'flex' }}>
          <AidinLogoWhite css="height: 20px; width: 74px;" />
        </Link>
        <ViewOnlyIndicator />
      </Panel>
      <Responsive size="large">
        {this.isLoggedIn() && (
          <SearchBox referralFilter={isReferralCurrent} session={this.props.session} />
        )}
        <div css={menuIconLinksSection}>
          {this.isLoggedIn() && (
            <InboxIndicatorsContainer>
              <InboxIndicatorContainer>
                <MessageNotificationIcon />
              </InboxIndicatorContainer>
              <InboxIndicatorContainer>
                <InAppNotifNotificationIcon
                  messageActions={this.props.messageActions}
                  conversationActions={this.props.conversationActions}
                />
              </InboxIndicatorContainer>
            </InboxIndicatorsContainer>
          )}
          {this.isLoggedIn() && (
            <HelpMenu
              onOpen={() => trackEvent('Site Header', 'Click', 'Help')}
              location={this.props.location}
              trigger={(
                <Panel orientation="horizontal">
                  <HelpMenuContainer>{tr('main.menu.help')}</HelpMenuContainer>
                </Panel>
              )}
            />
          )}
          {!this.isLoggedIn() && <ZendeskLink onClick={this.props.activateZendeskModal}>{tr('main.menu.help')}</ZendeskLink>}
          {this.isLoggedIn() && (
            <SettingsMenu
              onLogout={this.props.logoutUser}
              trigger={(
                <Panel orientation="horizontal">
                  <SettingsContainer onClick={() => trackEvent('Site Header', 'Click', 'Settings')} css={menuLinkStyle}>{tr('main.menu.settings')}
                    <ProviderVerificationWarningIcon align="top" size={25} margin="-2px 0 0 0" />
                  </SettingsContainer>
                  <AvatarContainer onClick={() => trackEvent('Site Header', 'Click', 'Avatar')}>
                    <img
                      alt={tr('main.menu.user')}
                      css={imageResponsive}
                      src={this.props.session.user.avatar_url || avatar}
                    />
                  </AvatarContainer>
                </Panel>
              )}
              session={this.props.session}
            />
          )}
        </div>
      </Responsive>
      <Responsive size={['small', 'medium']}>
        <div css="display: flex">
          {this.isLoggedIn() && (
            <MessageNotificationIcon hideUnreadIndicator />
          )}
          {this.isLoggedIn() && (
            <InAppNotifNotificationIcon
              messageActions={this.props.messageActions}
              conversationActions={this.props.conversationActions}
            />
          )}
          {this.isLoggedIn() && (
            <PaddedMenuLink>
              <Icon
                className="mp-search"
                data-testid="mp-search-mobile"
                css="cursor: pointer;"
                onClick={this.toggleSearch}
              />
            </PaddedMenuLink>
          )}
          <Icon
            className="mp-list"
            onClick={this.toggleDrawer}
          >
            {this.isLoggedIn() && (<ProviderVerificationWarningIcon align="top" size={20} className={providerWarningIconMobileStyle} />)}
          </Icon>
          {this.state.drawerOpen
            && (
            <MenuDrawer>
              <Panel align="right" spacing={2}>
                {this.isLoggedIn() && <Link to="/" css={menuCapsStyle} onClick={() => this.reset()}>{tr('main.menu.dashboard')}</Link>}
                <ZendeskLink onClick={this.props.activateZendeskModal}>{tr('main.menu.help')}</ZendeskLink>
              </Panel>
              <Divider />
              {this.isLoggedIn() && getSettingsMenuItems(this.props.session)
                .filter(({ parent }) => !parent)
                .map(({
                  key, url, label, tag, showVerifiedAdminStatusIcon, className,
                }) => (
                  <Panel align="right">
                    <Panel spacing={4} align="center" orientation="horizontal" className={className}>
                      <Link key={key} to={url} css={menuLinkStyle}>{label}</Link>
                      {tag && <Tag color={NOTIFICATION_FILL} textColor={WHITE}>{tag}</Tag>}
                      {showVerifiedAdminStatusIcon && (
                        <VerifiedAdminStatusIcon
                          className={verifiedAdminIconModifier}
                        />
                      )}
                    </Panel>
                  </Panel>
                ))}
              {this.isLoggedIn()
                && getSettingsMenuSecondaryLinks(this.props.session, this.props.location,
                  this.handleActionClick).filter(({ parent }) => !parent).map(({
                  key, url, label, target, onClick,
                }) => (key === 'becomeProviderAdmin' ? <VerifiedAdminActions url={url} session={this.props.session} target={target} isMobile />
                  : (onClick
                    ? <MenuLink onClick={onClick}>{label}</MenuLink>
                    : <Link key={key} to={url} target={target} css={menuLinkStyle}>{label}</Link>)
                ))}
              {this.isLoggedIn() && <MenuLink onClick={this.props.logoutUser}>{tr('main.menu.logout')}</MenuLink>}
              {this.renderMobileUserDisplay()}
            </MenuDrawer>
            )}
          { this.isLoggedIn() && this.state.searchOpen
            && (
            <SearchDrawer>
              <SearchBox
                referralFilter={isReferralCurrent}
                session={this.props.session}
                onClose={() => { this.setState({ searchOpen: false }); }}
              />
            </SearchDrawer>
            )}
        </div>
      </Responsive>
    </MenuContent>
  )

  renderErrorMenuBar = () => (
    <MenuContent>
      <a href="/" css={menuLogoStyle} style={{ display: 'flex' }}>
        <AidinLogoWhite css="height: 20px; width: 74px;" />
      </a>
    </MenuContent>
  );

  render = () => {
    if (this.props.hasError) {
      return <Menu className="no-print">{this.renderErrorMenuBar()}</Menu>;
    }

    const FEATURE_NAME = 'aidin_inbox';

    const {
      session: {
        organizations = [],
      } = {},
    } = this.props;

    const hasSubscriptions = organizations.some(({ organization_purchases: purchases = [] }) => (
      purchases.some(({
        feature,
        expires_at: expiration,
      }) => (
        feature === FEATURE_NAME
          && (!expiration || DateTime.parse(expiration).isAfter(DateTime.now()))
      ))
    ));

    return (
      <Menu className="no-print" hasBanner={this.props.hasBanner}>
        {this.isLoggedIn() && this.props.session.user.previewer
          ? this.renderPreviewUserMenuBar()
          : this.renderFullUserMenuBar(hasSubscriptions)}
        {this.isLoggedIn() && (
          <ProviderVerificationWarningPanel />
        )}
        {this.isLoggedIn() && (
          <div>
            <ConversationModal
              session={this.props.session}
              sendMessage={this.props.messageActions.sendMessage}
              thread={this.props.messages.current}
              onClose={this.props.messageActions.closeMessageThread}
            />
            <NewMessagePopupPanel
              session={this.props.session}
              composer={this.props.messages.composer}
              sendMessage={this.props.messageActions.sendMessage}
              onClose={this.props.messageActions.requestNewMessageThread}
            />
          </div>
        )}
      </Menu>
    );
  }
}

MenuBar.propTypes = {
  session: Session.isRequired,
  messages: PropTypes.shape({
    initialized: PropTypes.bool,
    list: PropTypes.arrayOf(Message),
    unread: PropTypes.number,
    composer: PropTypes.shape({
      open: PropTypes.bool,
    }),
    current: PropTypes.shape({
      id: PropTypes.number,
      messages: PropTypes.arrayOf(Message),
    }),
  }).isRequired,
  notifications: PropTypes.shape({
    list: PropTypes.arrayOf(Notification),
    unread: PropTypes.number,
  }).isRequired,
  activateZendeskModal: PropTypes.func.isRequired,
  messageActions: MessageApi.isRequired,
  conversationActions: MessageApi.isRequired,
  openIdentityVerificationModal: PropTypes.func.isRequired,
  logoutUser: PropTypes.func.isRequired,
  hasError: PropTypes.bool,
  hasBanner: PropTypes.bool,
  location: ReactRouterPropTypes.location.isRequired,
};

MenuBar.defaultProps = {
  hasError: false,
  hasBanner: false,
};

const connectedState = state => ({
  session: state.session,
  messages: state.messages,
  notifications: state.notifications,
});

const connectedProps = {
  ...notificationActions,
  ...sessionActions,
  ...uiActions,
};

export default compose(
  withAvailableThemes(FEATURE_UPGRADE_RELEASE_4),
  withThemeProvider,
  connect(connectedState, connectedProps),
  withConversationActions,
)(MenuBar);

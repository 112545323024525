import { createTranslator, numeric } from 'helpers/i18n';

const tr = createTranslator({
  noData: (subject, term) => `${subject} ${term} not have enough data to earn this badge.`,
  noDataUser: (subject, term) => `${subject} ${term} does not have enough data to earn this badge.`,
  metricsTitle: 'Aidin uses the following tasks to calculate this badge',
  subject: {
    personal: {
      user: 'You',
      provider: 'You',
    },
    default: {
      user: 'This Aidin user',
      provider: 'This provider',
    },
  },
  terms: {
    personal: {
      is: 'are',
      has: 'have',
      meets: 'meet',
      responds: 'respond',
      does: 'do',
      accepts: 'accept',
      receives: 'receive',
      completes: 'complete',
      chooses: 'choose',
      sends: 'send',
    },
    default: {
      is: 'is',
      has: 'has',
      meets: 'meets',
      responds: 'responds',
      does: 'does',
      accepts: 'accepts',
      receives: 'receives',
      completes: 'completes',
      chooses: 'chooses',
      sends: 'sends',
    },
  },
});

const getSubject = (type, isMine) => (isMine ? tr(`subject.personal.${type}`) : tr(`subject.default.${type}`));

const getTerm = (term, isMine) => (isMine ? tr(`terms.personal.${term}`) : tr(`terms.default.${term}`));

/**
 * Include in your code via:
 *
 *     import badges from 'translations/badges';
 *     const tr = createTranslator({
 *       badges,
 *       ...,
 *     });
 *
 * Then get the name or description with a given AwardedBadge via:
 *
 *     const badge = ...;
 *     const name = tr(`${badge.key}.name`, badge);
 *     const desc = tr(`${badge.key}.description`, badge);
 *
 * The `badge` object must contain two keys:
 * - key: the key for the badge
 * - state, one of:
 *   - active: Badge has been earned.
 *   - pending: Badge has no data
 *   - failed: Badge failed criteria
 *
 * You can also import the badge name and description helpers:
 *
 *     import { trBadgeName, trBadgeDescription } from 'translations/badges';
 *
 *     const name = trBadgeName(badge);
 *
 * Which do the above internally.
 */
const badges = {
  // Provider Search
  network_member: {
    name: 'Network Member',
    description: ({ state }, isMine) => numeric(state, {
      active: `${getSubject('provider', isMine)} ${getTerm('is', isMine)} part of your team's network for referrals like this one.`,
      other: `${getSubject('provider', isMine)} ${getTerm('is', isMine)} not part of your team's network for referrals like this one.`,
    }),
  },
  authorized_for_care: {
    name: 'Authorized for Patient\'s Care',
    description: ({ state }, isMine) => numeric(state, {
      active: `${getSubject('provider', isMine)} ${getTerm('is', isMine)} authorized by their insurance to cover this referral's needs.`,
      other: `${getSubject('provider', isMine)} ${getTerm('is', isMine)} not authorized by their insurance to cover this referral's needs.`,
    }),
  },
  accepts_similar_insurance: {
    name: ({ state }) => numeric(state, {
      active: 'Similar Insurance Matched',
      other: 'Similar Insurance Unmatched',
    }),
    description: ({ state }, isMine) => numeric(state, {
      active: `${getSubject('provider', isMine)} ${getTerm('has', isMine)} accepted similar insurance in the last 90 days.`,
      pending: tr('noData', getSubject('provider', isMine), getTerm('does', isMine)),
      failed: `${getSubject('provider', isMine)} ${getTerm('has', isMine)} not accepted similar insurance in the last 90 days.`,
    }),
  },
  accepts_similar_care_types: {
    name: ({ state }) => numeric(state, {
      active: 'Similar Clinical Needs Matched',
      other: 'Similar Clinical Needs Unmatched',
    }),
    description: ({ state }, isMine) => numeric(state, {
      active: `${getSubject('provider', isMine)} ${getTerm('has', isMine)} accepted similar clinical needs in the last 90 days.`,
      pending: tr('noData', getSubject('provider', isMine), getTerm('does', isMine)),
      failed: `${getSubject('provider', isMine)} ${getTerm('has', isMine)} not accepted similar clinical needs in the last 90 days.`,
    }),
  },
  accepts_similar_geography: {
    name: ({ state }) => numeric(state, {
      active: 'Similar Geography Matched',
      other: 'Similar Geography Unmatched',
    }),
    description: ({ state }, isMine) => numeric(state, {
      active: `${getSubject('provider', isMine)} ${getTerm('has', isMine)} accepted similar geography needs in the last 90 days.`,
      pending: tr('noData', getSubject('provider', isMine), getTerm('does', isMine)),
      failed: `${getSubject('provider', isMine)} ${getTerm('has', isMine)} not accepted similar geography needs in the last 90 days.`,
    }),
  },
  lowest_readmission_rate: {
    name: 'Lowest 20% Readmission Rate',
    description: ({ state }, isMine) => numeric(state, {
      active: `${getSubject('provider', isMine)} ${getTerm('has', isMine)} a Medicare re-admission rate in the best 20% of filtered providers.`,
      pending: tr('noData', getSubject('provider', isMine), getTerm('does', isMine)),
      failed: `${getSubject('provider', isMine)} ${getTerm('has', isMine)} a higher Medicare re-admission rate than the best 20% of filtered providers.`,
    }),
  },
  highest_patient_satisfaction: {
    name: 'Top 20% Patient Satisfaction',
    description: ({ state }, isMine) => numeric(state, {
      active: `${getSubject('provider', isMine)} ${getTerm('has', isMine)} Medicare satisfaction in the top 20% of the filtered providers.`,
      pending: tr('noData', getSubject('provider', isMine), getTerm('does', isMine)),
      failed: `${getSubject('provider', isMine)} ${getTerm('has', isMine)} Medicare satisfaction lower than the top 20% of the filtered providers.`,
    }),
  },
  meets_goal_los: {
    name: 'Meets Goal Lengths of Stay (LOS)',
    description: ({ state }, isMine) => numeric(state, {
      active: `${getSubject('provider', isMine)} ${getTerm('meets', isMine)} expected lengths of stay for referrals like this one.`,
      pending: tr('noData', getSubject('provider', isMine), getTerm('does', isMine)),
      failed: `${getSubject('provider', isMine)} ${getTerm('has', isMine)} not met expected lengths of stay for referrals like this one.`,
    }),
  },
  responds_similar_care_plans: {
    name: 'Responds to Similar Care Plans',
    description: ({ state }, isMine) => numeric(state, {
      active: `${getSubject('provider', isMine)} ${getTerm('responds', isMine)} to similar care plans, and responds on time.`,
      pending: tr('noData', getSubject('provider', isMine), getTerm('does', isMine)),
      failed: `${getSubject('provider', isMine)} ${getTerm('has', isMine)} not responded to similar care plans.`,
    }),
  },
  active_account: {
    name: ({ state }) => numeric(state, {
      failed: 'Inactive Aidin Account',
      other: 'Active Aidin Account',
    }),
    description: ({ state }, isMine) => numeric(state, {
      active: `${getSubject('provider', isMine)} ${getTerm('has', isMine)} responded to a referral in the last 90 days, and has a bio & photo.`,
      pending: tr('noData', getSubject('provider', isMine), getTerm('does', isMine)),
      failed: `${getSubject('provider', isMine)} ${getTerm('has', isMine)} not responded to a referral in the last 90 days.`,
    }),
  },
  responds_on_time: {
    name: 'Responds to Referrals On-Time',
    description: ({ state }, isMine) => numeric(state, {
      active: `${getSubject('provider', isMine)} ${getTerm('responds', isMine)} on-time for at least 80% of referrals.`,
      pending: tr('noData', getSubject('provider', isMine), getTerm('does', isMine)),
      failed: `${getSubject('provider', isMine)} may respond late to referrals.`,
    }),
  },
  accurate_insurance_filters: {
    name: 'Accurate Insurance Filters',
    description: ({ state }, isMine) => numeric(state, {
      active: `${getSubject('provider', isMine)} ${getTerm('has', isMine)} completed their insurance filters.`,
      pending: tr('noData', getSubject('provider', isMine), getTerm('does', isMine)), // TODO: Can this really be no data? Either you did or didn't??
      failed: `${getSubject('provider', isMine)} ${getTerm('has', isMine)} not completed their insurance filters.`,
    }),
  },
  accurate_care_types_filters: {
    name: 'Accurate Clinical Need Filters',
    description: ({ state }, isMine) => numeric(state, {
      active: `${getSubject('provider', isMine)} ${getTerm('has', isMine)} completed their clinical needs filters.`,
      pending: tr('noData', getSubject('provider', isMine), getTerm('does', isMine)), // TODO: Can this really be no data? Either you did or didn't??
      failed: `${getSubject('provider', isMine)} ${getTerm('has', isMine)} not completed their clinical needs filters.`,
    }),
  },
  accurate_geography_filters: {
    name: 'Accurate Geography Filters',
    description: ({ state }, isMine) => numeric(state, {
      active: `${getSubject('provider', isMine)} ${getTerm('has', isMine)} completed their location filters.`,
      pending: tr('noData', getSubject('provider', isMine), getTerm('does', isMine)), // TODO: Can this really be no data? Either you did or didn't??
      failed: `${getSubject('provider', isMine)} ${getTerm('has', isMine)} not completed their location filters.`,
    }),
  },
  high_medicare_score: {
    name: '4+ Medicare Score Rating',
    description: ({ state }, isMine) => numeric(state, {
      active: `${getSubject('provider', isMine)} ${getTerm('has', isMine)} a 4- or 5-star Medicare rating.`,
      pending: `${getSubject('provider', isMine)} ${getTerm('does', isMine)} not have a Medicare rating.`,
      failed: `${getSubject('provider', isMine)} ${getTerm('has', isMine)} a Medicare rating of less than 4.`,
    }),
  },
  accepts_financially_complex_cases: {
    name: 'Accepts Financially Complex Cases',
    description: ({ state }, isMine) => numeric(state, {
      active: `${getSubject('provider', isMine)} ${getTerm('accepts', isMine)} financially complex cases.`,
      pending: tr('noData', getSubject('provider', isMine), getTerm('does', isMine)), // TODO: Can this really be no data? Either you do or you don't??
      failed: `${getSubject('provider', isMine)} ${getTerm('does', isMine)} not accept financially complex cases.`,
    }),
  },
  accepts_clinically_complex_cases: {
    name: 'Accepts Clinically Complex Cases',
    description: ({ state }, isMine) => numeric(state, {
      active: `${getSubject('provider', isMine)} ${getTerm('accepts', isMine)} clinically complex cases, like difficult patients.`,
      pending: tr('noData', getSubject('provider', isMine), getTerm('does', isMine)), // TODO: Can this really be no data? Either you do or you don't??
      failed: `${getSubject('provider', isMine)} ${getTerm('does', isMine)} not accept clinically complex cases, like difficult patients.`,
    }),
  },
  electronic_notifications: {
    name: 'Uses Emails',
    description: ({ state }, isMine) => numeric(state, {
      active: `${getSubject('provider', isMine)} ${getTerm('receives', isMine)} emails, which tend to be faster than faxing.`,
      pending: tr('noData', getSubject('provider', isMine), getTerm('does', isMine)), // TODO: Can this really be no data? Either you do or you don't??
      failed: `${getSubject('provider', isMine)} ${getTerm('does', isMine)} not receive emails.`,
    }),
  },
  open_competition: {
    name: 'Open Competition',
    description: ({ state }, isMine) => numeric(state, {
      active: `${getSubject('user', isMine)} ${getTerm('sends', isMine)} to multiple providers, and ${getTerm('sends', isMine)} to the highest-quality providers available over 90% of the time.`,
      pending: tr('noDataUser', getSubject('user', isMine), getTerm('does', isMine)),
      failed: `${getSubject('user', isMine)} ${getTerm('does', isMine)} not always send to multiple providers or send to the highest-quality providers available.`,
    }),
    metrics: {
      title: tr('metricsTitle'),
      items: {
        competition: 'Sending to 3 or more providers on non-resuming care referrals',
        deadline: 'Allowing the deadline on a referral to close before reserving',
        patientChoice: 'Showing the patient their Patient Choice List and not skipping ahead to reserve',
      },
    },
  },
  quality_first: {
    name: 'Quality First',
    description: ({ state }, isMine) => numeric(state, {
      active: `${getSubject('user', isMine)} ${getTerm('chooses', isMine)} the highest-quality available provider over 90% of the time.`,
      pending: tr('noDataUser', getSubject('user', isMine), getTerm('does', isMine)),
      failed: `${getSubject('user', isMine)} ${getTerm('does', isMine)} not always choose the highest-quality available provider.`,
    }),
    metrics: {
      title: tr('metricsTitle'),
      items: {
        quality: 'Reserving the highest-quality available provider on referrals',
      },
    },
  },
  accountability_transparency: {
    name: 'Accountability & Transparency',
    description: ({ state }, isMine) => numeric(state, {
      active: `${getSubject('user', isMine)} ${getTerm('responds', isMine)} to and ${getTerm('completes', isMine)} referrals on-time over 90% of the time.`,
      pending: tr('noDataUser', getSubject('user', isMine), getTerm('does', isMine)),
      failed: `${getSubject('user', isMine)} ${getTerm('responds', isMine)} to and ${getTerm('completes', isMine)} referrals on-time less than 90% of the time.`,
    }),
    metrics: {
      title: 'Aidin uses the following tasks\' on-time completion to calculate this badge',
      items: {
        submit: 'Creating and sending referrals',
        reopen: 'Reopening referrals',
        share: 'Sharing the Patient Choice List',
        reserve: 'Reserving the provider',
      },
    },
  },
  practices_receiving_referrals: {
    name: ({ state }) => numeric(state, {
      active: 'Practices Receiving Referrals',
      other: 'Has Not Practiced Receiving Referrals',
    }),
    description: ({ state }, isMine) => numeric(state, {
      active: `${getSubject('user', isMine)} ${getTerm('has', isMine)} received & completed 10 practice referrals in the last year.`,
      other: `${getSubject('user', isMine)} ${getTerm('has', isMine)} not received & completed 10 practice referrals in the last year.`,
    }),
  },
  practices_sending_referrals: {
    name: ({ state }) => numeric(state, {
      active: 'Practices Sending Referrals',
      other: 'Has Not Practiced Sending Referrals',
    }),
    description: ({ state }, isMine) => numeric(state, {
      active: `${getSubject('user', isMine)} ${getTerm('has', isMine)} sent & completed 10 practice referrals in the last year.`,
      other: `${getSubject('user', isMine)} ${getTerm('has', isMine)} not sent & completed 10 practice referrals in the last year.`,
    }),
  },
};

export default badges;

import initialState from 'store/initialState';
import * as types from './types';

const dashboardMetadata = (state = initialState.dashboardMetadata, { type } = {}) => {
  switch (type) {
    case types.INCREMENT_DASHBOARD_VERSION:
      return {
        ...state,
        version: state.version + 1,
      };
    default:
      return state;
  }
};

const dashboard = (state = initialState.dashboard, { type, ...entity } = {}) => {
  switch (type) {
    case types.INITIALIZE_DASHBOARD: {
      const { filters, ...index } = entity;
      const {
        content: {
          insights,
        } = {},
      } = state;
      return {
        filters,
        index,
        content: {
          ...initialState.dashboard.content,
          insights,
        },
      };
    }
    case types.REPLACE_DASHBOARD_DATA:
      return {
        ...state,
        content: {
          ...state.content,
          ...entity,
        },
      };
    case types.REPLACE_DASHBOARD_INDEX: {
      const { filters, ...index } = entity;
      return {
        ...state,
        filters: filters || state.filters,
        index: {
          ...state.index,
          ...index,
        },
      };
    }
    case types.MERGE_DASHBOARD_MESSAGES: {
      const { section } = entity;
      const data = entity[section];
      return {
        ...state,
        content: {
          ...state.content,
          [section]: {
            ...state.content[section],
            ...data,
          },
        },
      };
    }
    case types.MERGE_DASHBOARD_REFERRAL_EVENTS: {
      const { content } = entity;
      return {
        ...state,
        content: {
          ...state.content,
          referral_events: {
            ...state.content.referral_events,
            ...content,
          },
        },
      };
    }
    case types.REMOVE_DASHBOARD_REFERRAL: {
      const { referral } = entity;
      const categories = [
        'active_referrals', 'expired_active_referrals',
        'in_progress_referrals', 'expired_in_progress_referrals',
        'completed_referrals',
        'opportunities', 'expired_opportunities', 'hidden_opportunities',
        'incoming', 'expired_incoming', 'hidden_incoming',
        'closed',
      ];
      const removeReferral = (value) => {
        const update = value.slice();
        const index = update.findIndex(({ id }) => id === referral.id);
        if (index >= 0) {
          update.splice(index, 1);
        }
        return update;
      };
      return {
        ...state,
        index: Object.entries(state.index).reduce((set, [key, value]) => ({
          ...set,
          [key]: categories.some(c => c === key) ? removeReferral(value) : value,
        }), {}),
      };
    }
    case types.REMOVE_PATIENT_REFERRAL: {
      const { referral: referralId } = entity;
      const categories = [
        'command_center_trns', 'command_center_acute',
      ];

      const removeReferral = (column, patients) => patients.map(({ referrals, ...patient }) => ({
        ...patient,
        referrals: referrals.map(r => ({
          ...r,
          hidden: r.hidden || r.id === referralId,
        })),
      }));

      return {
        ...state,
        content: Object.entries(state.content).reduce((set, [key, value]) => ({
          ...set,
          [key]: categories.some(c => c === key) ? removeReferral(key, value) : value,
        }), {}),
      };
    }
    case types.REMOVE_DASHBOARD_TASK: {
      const { task } = entity;
      const hidden = {
        hidden_opportunities: state.index.hidden_opportunities || [],
        hidden_incoming: state.index.hidden_incoming || [],
      };
      const categories = [
        'active_referrals', 'expired_active_referrals',
        'in_progress_referrals', 'expired_in_progress_referrals',
        'completed_referrals',
        'opportunities', 'expired_opportunities',
        'incoming', 'expired_incoming',
        'closed',
      ];
      const removeTask = (column, value) => {
        const update = value.slice();
        const index = update.findIndex(({ task_id: id }) => id === task);
        if (index >= 0) {
          let name = column;
          if (column.startsWith('expired_')) {
            name = name.substring('expired_'.length);
          }
          name = `hidden_${name}`;
          if (hidden[name]) {
            hidden[name].unshift(update[index]);
          }
          update.splice(index, 1);
        }
        return update;
      };
      return {
        ...state,
        index: Object.entries(state.index).reduce((set, [key, value]) => ({
          ...set,
          [key]: categories.some(c => c === key) ? removeTask(key, value) : value,
        }), hidden),
      };
    }
    case types.REPLACE_DASHBOARD_REFERRAL: {
      const { referral, category } = entity; // eslint-disable-line no-unused-vars

      // Short-circuit if updating in the same category
      /* const sameCategoryIndex = state.content[category].findIndex(r => r.id === referral.id);
      if (sameCategoryIndex >= 0) {
        const updated = state.content[category].slice();
        updated[sameCategoryIndex] = referral;
        return {
          ...state,
          content: {
            ...state.content,
            [category]: updated,
          },
        };
      } */
      // Otherwise, must update all categories since the category has changed!
      const referralContent = {
        active_referrals: [],
        in_progress_referrals: [],
        completed_referrals: [],
        opportunities: [],
        incoming: [],
        closed: [],
      };
      Object.keys(referralContent).forEach((group) => {
        const idx = state.content[group].findIndex(r => r.id === referral.id);
        if (idx >= 0) {
          referralContent[group] = state.content[group].slice();
          referralContent[group].splice(idx, 1, referral);
        } else {
          referralContent[group] = state.content[group].slice().concat(referral);
        }
      });

      return {
        ...state,
        content: {
          ...state.content,
          ...referralContent,
        },
      };
    }
    case types.REMOVE_PATIENT_FROM_DASHBOARD: {
      const { patient } = entity;
      const patientColumns = [
        'in_house',
        'discharged',
        'command_center_acute',
        'command_center_trns',
      ];

      const filter = data => Object.entries(data).reduce((sum, [key, value]) => ({
        ...sum,
        [key]: patientColumns.some(i => i === key)
          ? value.filter(({ id }) => id !== patient)
          : value,
      }), {});

      return {
        ...state,
        index: filter(state.index),
        content: filter(state.content),
      };
    }
    default:
      return state;
  }
};

export default {
  dashboard,
  dashboardMetadata,
};

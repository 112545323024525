import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

const withAvailableThemes = flagName => WrappedComponent => ({
  features: {
    [flagName]: isFeatureEnabled,
  },
  ...passThrough
}) => (
  <WrappedComponent
    availableThemes={isFeatureEnabled ? [1, 2] : [1]}
    {...passThrough /* eslint-disable-line react/jsx-props-no-spreading */}
  />
);

export default (flagName) => compose(
  connect(({ session: { features = {} } = {} }) => ({ features })),
  withAvailableThemes(flagName),
);

import React from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import { withRouter } from 'react-router';
import qs from 'query-string';

import apiDirector from 'helpers/ApiDirector';

import {
  QueryContext,
} from 'components/utils';

import ProviderLookupContainer from 'components/providers/ProviderLookupContainer';

const SUPPORT_KEY = 'providers';


const checkQueryKeys = (query) => {
  const keys = ['user[first]', 'user[last]', 'user[email]'];
  let count = 0;
  for (let i = 0; i < keys.length; i += 1) {
    if (keys[i] in query) count += 1;
  }
  if (query['user[invitation_token]'] && count === 0) return true;
  if (query['user[invitation_token]'] && count > 0 && count < 3) return true;
  return count === 3;
};

export const getNextEndpoint = (props) => {
  const query = qs.parse(props.location.search);
  if (query.support === SUPPORT_KEY && !checkQueryKeys(query)) {
    return '/devise_users/sign_in?support=activate';
  } if (query.support === 'activate' && !checkQueryKeys(query)) {
    return '/devise_users/sign_in?support=activate';
  }
  return '/devise_users/sign_in';
};

/**
 * Implementation of ProviderLookupContainer that displays for
 * logged-out users using QueryContext to determine when to show.
 */
class NewUserProviderLookupModal extends React.Component {
  static propTypes = {
    history: ReactRouterPropTypes.history.isRequired,
    location: ReactRouterPropTypes.location.isRequired,
  }

  componentDidMount() {
    const query = qs.parse(this.props.location.search);
    if (query.support === SUPPORT_KEY) {
      if ((query['user[email]'] && query['user[first]'] && query['user[last]'])) {
        if (this.panel) { this.panel.focus(); }
      } else if (query['user[invitation_token]']) {
        if (this.panel) { this.panel.focus(); }
      } else {
        this.handleClose();
      }
    }
  }

  handleClose = () => {
    const next = getNextEndpoint(this.props);
    if (this.props.location.pathname !== next) {
      this.props.history.push(next);
    }
  }

  handleClaimProvidersResend = (user, providers) => apiDirector.validateFetch('/api/providers/request-claim', {
    method: 'POST',
    body: JSON.stringify({
      user,
      providers,
      bypass_invitation_check: true,
    }),
  })

  handleClaimProviders = (user, providers) => apiDirector.validateFetch('/api/providers/request-claim', {
    method: 'POST',
    body: JSON.stringify({
      user,
      providers,
    }),
  })
    .catch((e) => {
      const { code } = e.response;
      const person = user;
      if (person.invitation_token && code === 409) {
        this.props.history.push('/devise_users/sign_in?support=activate');
        return Promise.reject(e);
      } if (code === 409) {
        return this.handleClaimProvidersResend(person, providers);
      }
      throw e;
    });

  handleCreateProvider = (user, provider) => apiDirector.validateFetch('/api/providers/request-create', {
    method: 'POST',
    body: JSON.stringify({
      user,
      provider,
    }),
  })

  render() {
    return (
      <QueryContext.Consumer>
        {({ support, user }) => (
          <ProviderLookupContainer
            ref={(ref) => { this.panel = ref; }}
            open={support === SUPPORT_KEY}
            user={user ? ['first', 'last', 'email', 'invitation_token'].reduce((s, k) => ({ ...s, [k]: user[k] }), {}) : undefined}
            onClose={this.handleClose}
            onClaimProviders={this.handleClaimProviders}
            onCreateProvider={this.handleCreateProvider}
          />
        )}
      </QueryContext.Consumer>
    );
  }
}

const connectedModal = withRouter(NewUserProviderLookupModal);
connectedModal.SUPPORT_KEY = SUPPORT_KEY;

export default connectedModal;

/* eslint-disable camelcase */

import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'emotion';
import styled from 'react-emotion';
import Conversation from 'models/InboxConversation';
import { Organization } from 'models/Session';
import { messageDateFormat, createTranslator } from 'helpers/i18n';
import { DateTime } from 'helpers/dates';
import withTimeStampPopup from 'components/messages/MessageUtils';

import {
  Panel, Styles, Icon, CheckboxField,
} from 'components/utils';

import ReferralDetailRow from './ReferralDetailRow';

const { Secondary } = Styles;

const tr = createTranslator({
  unknownOrganization: 'Unknown Organization',
  sender: (user, org) => `${user ? `${user.name} at ` : ''}${org}`,
  label: (message_content) => (message_content.user ? `${message_content.user.name} at ${message_content.organization.name}` : `${message_content.organization.name}`),
});

const Container = styled.div`
  padding: 10px;
  cursor: pointer;
  ${props => props.unread && 'background-color: rgba(67,121,255,0.1);'}
  &:hover {
    background-color: ${props => (props.unread ? 'rgba(67,121,255,0.16)' : '#f9f9f9')};
  };
`;

const PreviewAndTime = styled.div`
  display: flex;
`;

const TextPreview = styled.div`
  color: #000;
  opacity: 0.3;
  font-size: 15px;
  line-height: 21px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-grow: 1;
  margin-left: ${({ checkbox }) => checkbox && '30px'};
  width: 95%;
`;

const Sender = styled(Secondary)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const selfSentIconStyle = css`
  position: relative;
  top: 1px;
  margin-right: 4px;
`;

const TimeSent = styled.div`
  color: #666666;
  font-size: 12px;
  line-height: 15px;
  text-align: right;
  white-space: nowrap;
  padding-top: 2px;
  min-width: 50px;
  float: right;
  margin-right: 5px;
`;

const isUnread = (sender, sentAt, organizations, participants, membership) => {
  // If I'm the sender, return false
  if (organizations.some(({ id, organization_type: type }) => (
    id === sender.organization_id && type === sender.organization_type
  ))) { return false; }

  const sentDate = DateTime.parse(sentAt);
  if (membership && membership.length > 0) {
    return membership
      .map(({ last_read_at, user_read: isRead }) => (
        isRead ? DateTime.parse(last_read_at) : null
      ))
      .reduce((unread, date) => unread && (!date || sentDate.isAfter(date, 'millisecond')), true);
  }
  const recipients = participants
    .map(({ last_read_at, organization }) => ({ ...organization, last_read_at }))
    .filter(({ id, type }) => (
      organizations.some(o => o.id === id && o.organization_type === type)
    ))
    .filter(({ id, type }) => (
      !(sender.organization_id === id && sender.organization_type === type)
    ));

  if (recipients.length === 0) { return false; }

  return recipients
    .map(({ last_read_at }) => DateTime.parse(last_read_at))
    .reduce((unread, date) => unread && (!date || sentDate.isAfter(date, 'millisecond')), true);
};

const getOrganizationName = (organization, options) => {
  const found = options.find(({
    organization_id, organization_type,
  }) => (
    organization_id === organization.organization_id
      && organization_type === organization.organization_type
  ));
  return found ? found.organization.name : tr('unknownOrganization');
};

const MessageListItem = ({
  checkbox,
  handleCheck,
  isChecked,
  lastMessageReceived,
  onClick,
  message,
  message: {
    topic_type,
    topic_info: referral,
    last_conversation_item: {
      text,
      user,
      organization_id,
      organization_type,
      created_at,
    } = {},
    conversation_members = [],
    conversation_organizations,
  },
  organizations,
}) => {
  const Component = () => <TimeSent>{messageDateFormat(created_at)}</TimeSent>;
  const EnhancedTimeAgo = withTimeStampPopup(Component);

  return (
    <Container
      onClick={onClick}
      unread={isUnread(
        { organization_id, organization_type },
        created_at,
        organizations,
        conversation_organizations,
        conversation_members,
      )}
    >
      <Panel spacing={3}>
        <PreviewAndTime>
          {checkbox && lastMessageReceived && (
            <CheckboxField
              onChange={checked => handleCheck(checked, message.id)}
              value={isChecked}
              noClick // TODO: this can be removed when noClick becomes the CheckboxField default
            />
          )}
          <TextPreview checkbox={checkbox && lastMessageReceived}>{text}</TextPreview>
          <EnhancedTimeAgo item={message} position="bottom right" />
        </PreviewAndTime>
        <Sender>
          {organizations.some(({ id: myOrgId, organization_type: myOrgType }) => (
            myOrgId === organization_id && myOrgType === organization_type
          ))
            && <Icon className={selfSentIconStyle} color="#000" size={20} name="mp-arrow-left" />}
          {tr('sender', user, getOrganizationName({ organization_id, organization_type }, conversation_organizations))}
        </Sender>
        {topic_type === 'Referral' && referral && (
          <ReferralDetailRow
            referral={referral}
          />
        )}
      </Panel>
    </Container>
  );
};
MessageListItem.propTypes = {
  onClick: PropTypes.func.isRequired,
  organizations: PropTypes.arrayOf(Organization).isRequired,
  message: Conversation.isRequired,
  checkbox: PropTypes.bool,
  isChecked: PropTypes.bool,
  handleCheck: PropTypes.func,
  lastMessageReceived: PropTypes.bool,
};

MessageListItem.defaultProps = {
  checkbox: false,
  isChecked: undefined,
  handleCheck: undefined,
  lastMessageReceived: undefined,
};

export default MessageListItem;

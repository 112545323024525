/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

/**
 * Does not bubble up clicks.
 */
const withNoClick = WrappedComponent => ({
  clickElement: Element = 'div',
  clickElementClassName,
  ...props
}) => (
  <Element
    className={clickElementClassName}
    onClick={(e) => {
      e.preventDefault();
      e.stopPropagation();
    }}
  >
    <WrappedComponent {...props} />
  </Element>
);

export default withNoClick;

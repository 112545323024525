/* eslint-disable camelcase */
import { providerTypes } from 'translations/providers';

export const MAP_TYPE_HOME = 'Home';
export const MAP_TYPE_PROVIDERS = 'Providers';

export const SEARCH_AREA_PATIENT = 'Patient';
export const SEARCH_AREA_HOSPITAL = 'Hospital';

export const PROVIDER_GROUP_TRANSACTIONAL = 'transactional';
export const PROVIDER_GROUP_HOME_SERVICE = 'home_service';
export const PROVIDER_GROUP_INPATIENT = 'inpatient';
export const PROVIDER_GROUP_OUTPATIENT = 'outpatient';

export const LENGTH_OF_STAY_MODE_RECURRING = 'recurring';
export const LENGTH_OF_STAY_MODE_APPOINTMENT = 'individual';

/**
 * Houses default settings for all provider types, and provider groups.
 */
const common = {
  all: {
    enableResumingCare: true,
    startOfCare: false,
    searchByZip: true,
    searchArea: SEARCH_AREA_PATIENT,
    searchByLocation: false,
    enableSearchRadius: true,
    lengthOfStay: true,
    lengthOfStayMode: null,
    patientSurveyAllowed: true,
    patientSurveyDefault: true,
    mapType: MAP_TYPE_HOME,
    defaultDeadlineHours: 4,
    idealProviderCount: 5,
    lateDeadlineHour: 13,
    medicareDischargeToCommunityRate: false,
  },
  [PROVIDER_GROUP_TRANSACTIONAL]: {
    searchByLocation: true,
    enableResumingCare: false,
    enableSearchRadius: false,
    lengthOfStay: false,
    patientSurveyAllowed: false,
    defaultDeadlineHours: 2,
    lateDeadlineHour: 16,
  },
  [PROVIDER_GROUP_HOME_SERVICE]: {
    searchByLocation: true,
    enableSearchRadius: false,
    startOfCare: true,
    defaultDeadlineHours: 2,
    lateDeadlineHour: 15,
  },
  [PROVIDER_GROUP_INPATIENT]: {
    mapType: MAP_TYPE_PROVIDERS,
    defaultDeadlineHours: 4,
  },
  [PROVIDER_GROUP_OUTPATIENT]: {
    mapType: MAP_TYPE_PROVIDERS,
    lengthOfStay: false,
    lengthOfStayMode: LENGTH_OF_STAY_MODE_APPOINTMENT,
    defaultDeadlineHours: 4,
  },
};

/**
 * Combines the common defaults and the group defaults, along
 * with any individual settings, to build a settings hash for
 * each provider type.
 */
const metadata = [
  { providerType: 'DME', category: PROVIDER_GROUP_TRANSACTIONAL },
  { providerType: 'INF', category: PROVIDER_GROUP_TRANSACTIONAL },
  {
    providerType: 'TRNS',
    category: PROVIDER_GROUP_TRANSACTIONAL,
    searchArea: SEARCH_AREA_HOSPITAL,
  },
  {
    providerType: 'NEMT',
    category: PROVIDER_GROUP_TRANSACTIONAL,
    searchArea: SEARCH_AREA_PATIENT,
    patientSurveyAllowed: true,
  },
  { providerType: 'GENERAL', category: PROVIDER_GROUP_TRANSACTIONAL, incomplete: true },
  {
    providerType: 'AUTH',
    category: PROVIDER_GROUP_TRANSACTIONAL,
    idealProviderCount: 1,
    defaultDeadlineHours: 48,
  },
  {
    providerType: 'HHA',
    category: PROVIDER_GROUP_HOME_SERVICE,
    idealProviderCount: 6,
    medicareDischargeToCommunityRate: true,
  },
  { providerType: 'PAL', category: PROVIDER_GROUP_HOME_SERVICE },
  { providerType: 'PD', category: PROVIDER_GROUP_HOME_SERVICE },
  { providerType: 'HOSP', category: PROVIDER_GROUP_HOME_SERVICE },
  {
    providerType: 'SNF',
    category: PROVIDER_GROUP_INPATIENT,
    idealProviderCount: 6,
    medicareDischargeToCommunityRate: true,
  },
  { providerType: 'LTAC', category: PROVIDER_GROUP_INPATIENT, medicareDischargeToCommunityRate: true },
  { providerType: 'IRF', category: PROVIDER_GROUP_INPATIENT, medicareDischargeToCommunityRate: true },
  { providerType: 'PSYC', category: PROVIDER_GROUP_INPATIENT, patientSurveyDefault: false },
  { providerType: 'ALF', category: PROVIDER_GROUP_INPATIENT },
  {
    providerType: 'DIAL',
    category: PROVIDER_GROUP_OUTPATIENT,
    lengthOfStayMode: LENGTH_OF_STAY_MODE_RECURRING,
  },
  { providerType: 'DAR', category: PROVIDER_GROUP_OUTPATIENT },
  { providerType: 'OUTPSYC', category: PROVIDER_GROUP_OUTPATIENT },
  { providerType: 'PCP', category: PROVIDER_GROUP_OUTPATIENT },
  { providerType: 'PT', category: PROVIDER_GROUP_OUTPATIENT },
  { providerType: 'PTSERV', category: PROVIDER_GROUP_OUTPATIENT },
  { providerType: 'ACUTE', category: PROVIDER_GROUP_INPATIENT },
  {
    providerType: 'RECUPE',
    category: PROVIDER_GROUP_INPATIENT,
    idealProviderCount: 12,
  },
  { providerType: 'INHOSP', category: PROVIDER_GROUP_INPATIENT },
  { providerType: 'PATIENT', category: PROVIDER_GROUP_TRANSACTIONAL },
].map(settings => ({
  ...common.all,
  ...common[settings.category],
  ...settings,
  name: providerTypes[settings.providerType],
}));

export const getProviderTypesByCategory = category => (
  metadata.filter(i => i.category === category)
);

/**
 * Utility method to determine if a level of care is transportation
 */
export const isTransportation = (level_of_care = []) => (
  metadata.filter(i => ['NEMT', 'TRNS'].includes(i.providerType))
    .some(i => level_of_care.some(value => value === i.providerType))
);

/**
 * Utility method to determine if a level of care is NEMT
 */
export const isNEMT = (level_of_care) => level_of_care.includes('NEMT');

/**
 * Utility method to determine if a level of care is transactional
 */
export const isTransactional = (level_of_care = []) => (
  getProviderTypesByCategory(PROVIDER_GROUP_TRANSACTIONAL)
    .some(i => level_of_care.some(value => value === i.providerType))
);

/**
 * Utility method to determine if a level of care is outpatient
 */
export const isOutpatient = (level_of_care = []) => (
  getProviderTypesByCategory(PROVIDER_GROUP_OUTPATIENT)
    .some(i => level_of_care.some(value => value === i.providerType))
);

/**
 * Utility method to determine if a level of care is inpatient
 */
export const isInpatient = (level_of_care = []) => (
  getProviderTypesByCategory(PROVIDER_GROUP_INPATIENT)
    .some(i => level_of_care.some(value => value === i.providerType))
);

/**
 * Utility method to determine if a level of care is home_service
 */
export const isHomeService = (level_of_care = []) => (
  getProviderTypesByCategory(PROVIDER_GROUP_HOME_SERVICE)
    .some(i => level_of_care.some(value => value === i.providerType))
);

/**
 * Utility method to determine if a level of care is authorization
 */
export const isAuthorization = (level_of_care = []) => (
  level_of_care.some(value => value === 'AUTH')
);

export const usesTransactionalWorkflow = (level_of_care = []) => (
  isTransactional(level_of_care) || isOutpatient(level_of_care)
);

/**
 * Get a ProviderType model for a given provider type.
 */
export const getProviderTypeModel = (providerType) => {
  const model = metadata.find(i => i.providerType === providerType);
  return model ? { ...model } : null;
};

/**
 * Given a list of provider types (probably from a referral),
 * retrieve settings
 */
export const getProviderTypeModels = (level_of_care = []) => (
  level_of_care.map(getProviderTypeModel)
).filter(result => !!result);

/**
 * Get all applicable settings for this referral. Usually only one.
 */
export const getReferralProviderTypeModels = ({ level_of_care = [] }) => (
  getProviderTypeModels(level_of_care)
);

const mapping = metadata.reduce((map, value) => ({ ...map, [value.providerType]: value }), {});
mapping.defaults = common.all;

/**
 * Usage example:
 *
 * import ProviderType from 'models/ProviderType'
 *
 * const settings = ProviderType['SNF']
 *
 * Good for when you are working with a single provider type, but for
 * referrals you probably want `getReferralProviderTypeModels` instead.
 */
export default mapping;

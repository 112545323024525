import React, { useReducer, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import qs from 'query-string';
import markdown from 'helpers/markdown';

import { createTranslator } from 'helpers/i18n';
import { applyTestIdentifier } from 'helpers/development';
import withThemedComponents, { ThemeConfig } from 'components/utils/withThemedComponents';
import {
  Panel, Button, TextField, Styles, ButtonBar, Spinner, Tip,
} from 'components/utils';

import { handleSubmit, reducer, makeOnChangeHandler } from './functions';
import initialState from './fixtures/initialState';
import Success from './screens/success';
import { createComponentStyles } from './styles';
import text from './translations';

const { Secondary } = Styles;

const tr = createTranslator(text);

export const SUPPORT_KEY = 'activate';

const TextFieldWrapper = ({
  fieldKey, state, dispatch, onSubmit, textInputHeight,
}) => (
  <TextField
    required
    autoFocus={fieldKey === 'firstName'}
    type={fieldKey === 'email' ? 'email' : 'text'}
    label={tr(fieldKey)}
    value={state[fieldKey]}
    name={fieldKey}
    errors={state.errors[fieldKey] && [tr(`errors.${fieldKey}`)]}
    onChange={makeOnChangeHandler({ fieldKey, dispatch })}
    height={textInputHeight}
    onSubmit={onSubmit}
  />
);

const RegisterAccountForm = ({
  theme: {
    styles: {
      Container, loginButtonStyle, textInputHeight, buttonBarStyle, spinnerColor, errorTipColor, buttonDarkerColor,
    },
  },
  location: { search },
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const query = qs.parse(search);
    if (query.token === 'invalid') {
      dispatch({ type: 'errors', value: { token: true } });
    }
  }, [search]);

  if (state.loading) return (<Spinner color={spinnerColor} />);
  if (state.success) return (<Success />);
  return (
    <Container>
      <h3>{tr('title')}</h3>
      <Panel>
        {state.errors.token && (
          <Tip color={errorTipColor}>
            {markdown(tr('errors.tokenInvalid'))}
          </Tip>
        )}
        <Secondary>{tr('prompt')}</Secondary>
        <form onSubmit={(e) => { e.preventDefault(); }} {...applyTestIdentifier('emailPasswordRegisterForm')}>
          <Panel spacing={10}>
            <TextFieldWrapper fieldKey="firstName" state={state} dispatch={dispatch} textInputHeight={textInputHeight} />
            <TextFieldWrapper fieldKey="lastName" state={state} dispatch={dispatch} textInputHeight={textInputHeight} />
            <TextFieldWrapper fieldKey="email" state={state} dispatch={dispatch} textInputHeight={textInputHeight} onSubmit={() => handleSubmit({ state, dispatch })} />
          </Panel>
          <ButtonBar align="right" className={buttonBarStyle}>
            <Button
              small
              onClick={() => handleSubmit({ state, dispatch })}
              color={buttonDarkerColor}
              className={loginButtonStyle}
            >
              {tr('submit')}
            </Button>
          </ButtonBar>
        </form>
      </Panel>
    </Container>
  );
};

RegisterAccountForm.propTypes = {
  theme: ThemeConfig.isRequired,
};

export default withRouter(withThemedComponents(createComponentStyles)(RegisterAccountForm));

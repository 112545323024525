/**
 * Get initial set of system-wide features as made available
 * in the page's head tag. If no document exists (testing mode),
 * then default to empty set.
 *
 * This will eventually get overridden when the user logs in,
 * but this initial set ensure feature flags for things like
 * quick drag are immediately available.
 */
const getInitialFeatures = () => {
  if (!document || !document.querySelectorAll) {
    return {};
  }

  return Array
    .from(document.querySelectorAll('head meta[name="aidin:system_feature_flag"]'))
    .reduce((set, element) => ({
      ...set,
      [element.getAttribute('content')]: true,
    }), {});
};

/*
  The initial shape of the redux store.

  note that the initial shape is currently stuffed with placeholder
  data that will eventually be replaced with live data from APIs.
*/
const initialState = {
  admin: {
    team: [],
    providers: {
      current: {},
      administrators: [],
    },
    providerNetworks: [],
    providerExclusions: [],
    providerDisclosures: [],
  },
  session: {
    user: {
      organizations: [],
    },
    organizations: [],
    features: getInitialFeatures(),
    system_alerts: [],
  },
  system: {
    care_types: [],
    languages: [],
    checklists: [],
  },
  loadingQueue: {
  },
  printing: {
    pending: null,
    inline: null,
    document: null,
  },
  referralsDashboard: {
    index: [],
    content: {},
    filters: {},
    metadata: {},
  },
  dashboardMetadata: {
    version: 1,
  },
  dashboard: {
    index: {
      active_referrals: [],
      in_progress_referrals: [],
      completed_referrals: [],
      in_house: [],
      discharged: [],
      opportunities: [],
      incoming: [],
      closed: [],
      patients: [],
      incoming_patients: [],
      command_center_trns: [],
      command_center_acute: [],
      activities: { unread: 0 },
    },
    content: {
      active_referrals: [],
      in_progress_referrals: [],
      completed_referrals: [],
      in_house: [],
      discharged: [],
      opportunities: [],
      incoming: [],
      closed: [],
      patients: [],
      incoming_patients: [],
      command_center_trns: [],
      command_center_acute: [],
      activities: [],
      messages: {},
      referral_events: {},
      conversations: {},
      zip_code_eligible: [],
    },
    filters: {},
  },
  referral: null,
  providerSearch: {
    clinicalNeeds: [],
    results: [],
    sortOrder: null,
    warnings: {},
  },
  conversations: [],
  activity: [],
  followers: {
    abilities: {},
    all: [],
  },
  watchers: [],
  purchases: {
    features: [],
    trials: [],
  },
  conversationThread: {
    current: null,
    composer: {
      open: false,
    },
  },
  conversationsInbox: {
    list: [],
    unread: 0,
  },
  conversationsNotification: {
    list: [],
    unread: 0,
    initialized: false,
  },
  messages: {
    list: [],
    unread: 0,
    initialized: false,
    current: null,
    composer: {
      open: false,
    },
  },
  inbox: {
    list: [],
    unread: 0,
  },
  notifications: {
    list: [],
    unread: 0,
  },
  patientSearch: {
    results: [],
  },
  documents: {
    library: {
      size: 0,
      content: [],
    },
    outbound: {
      size: 0,
      content: [],
    },
    inbound: {
      size: 0,
      content: [],
      stage: [],
    },
  },
  ui: {
    conversationModal: {
      open: false,
    },
    providerSearchContactInfoModal: {
      open: false,
    },
    providerPreferencesModal: {
      referralId: null,
      preferences: null,
      open: false,
    },
    providerMissingBadgeModal: {
      referralId: null,
      hospitalId: null,
      preferences: null,
      open: false,
    },
    passwordChangeModal: {
      open: false,
    },
    fedrampModal: {
      open: false,
    },
    badgesCheckupModal: {
      providers: null,
      open: false,
    },
    identityVerificationModal: {
      open: false,
      refresh: null,
    },
    yearEndReportModal: {
      open: false,
    },
    newProviderModal: {
      preferences: null,
      open: false,
    },
    newOutboundDocumentModal: {
      organization: null,
      open: false,
    },
    purchaseModal: {
      organization: null,
    },
    softwareUpdate: {
      open: false,
    },
    attentionAlert: {
      open: false,
    },
    createReferralConflictModal: {
      open: false,
    },
    updateReferralConflictModal: {
      open: false,
    },
    sendToProviders: {
      open: false,
    },
    receiverPurchaseableModal: {
      open: false,
    },
    advancedMessageSearchModal: {
      open: false,
    },
    attachmentViewer: {
      open: false,
    },
    messageMultipleProvidersModal: {
      open: false,
    },
    editAttachmentModal: {
      open: false,
    },
    newHospitalModal: {
      open: false,
    },
    newReferralModal: {
      open: false,
    },
    documentWarningModal: {
      open: false,
    },
    passwordConfirmationModal: {
      open: false,
    },
    editProviderSettings: {
      open: false,
    },
    documentResponseModal: {
      open: false,
    },
    confirmSignatureModal: {
      open: false,
    },
    systemAlert: {},
    requestAlreadyExists: {
      open: false,
    },
    enterPinModal: {
      open: false,
    },
    scrapeableDetails: {
      open: false,
      referral: null,
    },
    fullFaxDisabledConflictModal: {
      open: false,
    },
    joinOrganizationModal: {
      open: false,
    },
    receivingResponseModal: {
      open: false,
      referral: null,
    },
    receivingAuthResponseModal: {
      open: false,
      referral: null,
    },
    receivingOutpatientResponseModal: {
      open: false,
      referral: null,
    },
    sendToEpicModal: {
      open: false,
    },
    denyReferralModal: {
      open: false,
      referral: null,
    },
    declineReasonModal: {
      open: false,
      referral: null,
    },
    generateFormInviteModal: {
      open: false,
    },
    payorConflictModal: {
      open: false,
    },
    responseReviewProcessingModal: {
      open: false,
    },
    responseReviewListModal: {
      open: false,
    },
    attachUnassignedDocumentsToPatientModal: {
      open: false,
    },
    completeNonEmergencyMedicalTransportModal: {
      open: false,
    },
    currentViewerConfirmModal: {
      open: false,
    },
    turnOffFaxesModal: {
      open: false,
    },
    followingPreferencesMarketingModal: {
      open: false,
    },
    requestDuplicationWarningModal: {
      open: false,
    },
    attachmentDownloadModal: {
      open: false,
    },
  },
};

export default initialState;

import styled from 'react-emotion';
import { css } from 'emotion';
import { mq } from 'components/utils/Responsive';

export const PrimaryColumn = styled.div(mq({
  width: ['620px', '100%', '100%'],
  margin: [undefined, '5px 5px', '5px 5px'],
}));

export const SecondaryColumn = styled.div(props => mq({
  width: ['300px', '100%', '100%'],
  backgroundColor: props.backgroundColor || undefined,
  margin: [undefined, '5px 5px', '5px 5px'],
  boxShadow: '0 2px 3px 0 rgba(153, 153, 153, 0.3)',
}));

export const ResponsiveGrid = styled.div(props => mq({
  display: 'flex',
  flexDirection: ['row', 'column', 'column'],
  justifyContent: [props.justifyContent || 'space-between', 'center', 'center'],
  alignItems: 'flex-start',
  '> div': {
    margin: [undefined, '10px 0px', '10px 0px'],
  },
}));

export const primaryButtonStyle = css(mq({
  width: [undefined, '100%', '100%'],
  display: 'flex',
  justifyContent: 'center',
}));

export const ContentPanelPageTitle = styled.h3({
  padding: '0px',
});

export const pageContainerStyleV2 = css(mq({
  paddingLeft: [0, '20px', '20px'],
  paddingRight: [0, '20px', '20px'],
  width: ['100%', 'auto', 'auto'],
}));

export const textInputHeightV2 = '44px';

import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/utils/Button';
import { createTranslator } from 'helpers/i18n';
import text from './translations';

const tr = createTranslator(text);

const ClearButton = ({
  small,
  className,
  children,
  onClick,
  disabled,
  color,
}) => (
  <Button small={small} className={className} onClick={onClick} disabled={disabled} color={color}>
    {children || tr('clear')}
  </Button>
);

ClearButton.propTypes = {
  /** {Boolean} small - Show small version or not */
  small: PropTypes.bool,
  /** {String} className - className values */
  className: PropTypes.string,
  /** {node} children - Child nodes */
  children: PropTypes.node,
  /** {function} onClick - Callback function to be triggered on click */
  onClick: PropTypes.func,
  /** {Boolean} disabled - Whether or not button is disabled */
  disabled: PropTypes.bool,
  /** {String} color - Color of button */
  color: PropTypes.string,
};

ClearButton.defaultProps = {
  small: true,
  className: undefined,
  children: null,
  onClick: null,
  disabled: false,
  color: undefined,
};

export default ClearButton;

/* eslint-disable camelcase */

import React from 'react';
import { connect } from 'react-redux';

import { User } from 'models/Session';
import { DateTime } from 'helpers/dates';
import { hidePasswordWarningToast } from 'helpers/ui';

import PasswordTimer from './PasswordTimer';

const WARN_AT_DAYS_REMAINING = 10;

export const isPasswordExpiring = (user) => {
  if (!user) { return false; }

  const { days_before_password_expiration = null } = user;

  if (days_before_password_expiration === null) { return false; }

  return days_before_password_expiration <= WARN_AT_DAYS_REMAINING;
};

/**
 * Connected Toast that opens when a user's password is old, and
 * dismisses when the user has been updated in Redux to show that
 * the password has been updated.
 */
class PasswordChangeReminderToast extends React.Component {
  static propTypes = {
    /** The logged-in user */
    user: User.isRequired,
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { password_last_update_at: password_last_update_at_was } = this.props.user;
    const { password_last_update_at } = nextProps.user;

    if (password_last_update_at !== password_last_update_at_was) {
      if (!isPasswordExpiring(nextProps.user)) {
        hidePasswordWarningToast();
      }
    }
  }

  render() {
    const { user } = this.props;
    const { password_last_update_at } = user;

    const passwordUpdatedAt = DateTime.parse(password_last_update_at);
    const passwordExpiresAt = DateTime.parse(password_last_update_at).add(90, 'days');
    const warnForExpirationAt = passwordUpdatedAt.add(90 - WARN_AT_DAYS_REMAINING, 'days');

    return (
      <PasswordTimer
        breakpoint={warnForExpirationAt}
        value={passwordExpiresAt}
      />
    );
  }
}

export default connect(({ session }) => ({ user: session.user }))(PasswordChangeReminderToast);

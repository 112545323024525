/* eslint-disable prefer-destructuring, camelcase, no-nested-ternary */
import apiDirector from 'helpers/ApiDirector';
import { DateTime } from 'helpers/dates';
import { reportError } from 'helpers/ErrorHandler';
import { shapePatient, shapeMessages } from './filters';
import * as actions from './actions';

const markLatestAsRead = () => (dispatch, getState) => {
  const organizations = getState().session.organizations || [];
  const belongsTo = ({ organization: { id }, organization_type }) => (
    organizations.some(organization => (
      organization_type === organization.organization_type && id === organization.id
    ))
  );
  const thread = getState().messages.current;
  if (!thread || thread.messages.length === 0) { return; }
  const latest = thread.messages
    .filter(m => !m.read_flag && belongsTo(m))
    .map(m => ({ id: m.id, date: DateTime.parse(m.created_at) }))
    .sort((a, b) => b.date.valueOf() - a.date.valueOf());

  if (latest.length > 0) {
    apiDirector.validateFetch('/api/messages/all/thread/read', {
      method: 'PATCH',
      body: JSON.stringify({
        organizations: thread.organizations,
        referral_id: thread.id,
        read_id: latest[0].id,
      }),
    });
  }
};

export const refreshInboxList = (count, filter = 'all') => dispatch => apiDirector.validateFetch(`/api/messages/all?page=0&limit=${count}&stats=true&filter=${filter}`)
  .then(json => dispatch(actions.replaceInbox(json)));

export const requestInboxList = (count, filter = 'all', offset, withStats) => dispatch => apiDirector.validateFetch(`/api/messages/all?page=${offset}&limit=${count}&filter=${filter}${withStats ? '&stats=true' : ''}`)
  .then(json => dispatch(actions.appendToInbox(json)));

export const requestMessageUnreadCount = () => dispatch => apiDirector.validateFetch('/api/messages/unread')
  .then(json => dispatch(actions.replaceMessages(json)));

export const requestMessageList = () => dispatch => apiDirector.validateFetch('/api/messages/all')
  .then(json => dispatch(actions.replaceMessages(json)));

export const requestMessageThreadById = id => dispatch => apiDirector.validateFetch(`/api/messages/all/thread/${id}`)
  /* .then(json => ({
    organizations: [
      {
        ...json[0].message_content.organization,
        type: json[0].message_content.organization_type,
      },
      {
        ...json[0].organization,
        type: json[0].organization_type,
      },
    ],
    patient: shapePatient(json[0].message_content.patient),
    referral: json[0].message_content.referral,
    messages: shapeMessages(json),
  })) */
  .then(json => ({
    ...json,
    patient: {
      ...shapePatient(json.patient, json.referral),
      hospital: json.referral.hospital,
    },
    messages: shapeMessages(json.messages),
  }))
  .then(json => dispatch(actions.replaceMessageThread(json.referral.id, json)))
  .then(() => dispatch(markLatestAsRead()));

export const requestMessageThread = (
  referral_id, organizations, iReferral = null,
) => (dispatch, getState) => {
  let referral = iReferral;
  if (!referral && getState().referral && getState().referral.id === referral_id) {
    referral = getState().referral;
  }
  return apiDirector.validateFetch('/api/messages/all/thread', {
    method: 'POST',
    body: JSON.stringify({
      referral_id,
      organizations,
    }),
  })
    /* .then(json => ({
      organizations,
      patient: json[0] ? json[0].message_content.patient : referral ? referral.patient : {},
      referral: json[0] ? json[0].message_content.referral : referral,
      messages: shapeMessages(json),
    }))
    .then(json => ({
      ...json,
      messages: shapeMessages(json.messages),
    })) */
    .then(json => ({
      ...json,
      patient: {
        ...shapePatient(json.patient, json.referral),
        hospital: json.referral.hospital,
      },
      messages: shapeMessages(json.messages),
    }))
    .then(json => dispatch(actions.replaceMessageThread(referral_id, json)))
    .then(() => dispatch(markLatestAsRead()))
    .catch((e) => {
      reportError(e, 'Failed to load message in requestMessageThread', {
        referral_id,
        organizations,
        hasReferralParam: !!iReferral,
        hasActualReferral: !!referral,
      });
      throw e;
    });
};

export const refreshMessageThread = () => (dispatch, getState) => {
  const thread = getState().messages.current;
  if (thread) {
    dispatch(requestMessageThread(thread.id, thread.organizations, thread.referral));
  }
};

/**
 * Utility method that takes a referral and optional sentReferral
 * and opens the thread for the associated organizations
 */
export const requestMessageThreadForReferral = (referral, provider) => (
  provider || referral.referralResponse.provider) && requestMessageThread(referral.id, [
  {
    id: provider ? provider.id : referral.referralResponse.provider.id,
    name: provider ? provider.name : referral.referralResponse.provider.name,
    type: (provider ? (provider.organization_type || provider.type)
      : (referral.referralResponse.provider.organization_type)) || 'Provider',
  },
  {
    id: referral.hospital.id,
    name: referral.hospital.name,
    type: referral.hospital.type || referral.hospital.organization_type,
  },
], referral);

// eslint-disable-next-line no-unused-vars
export const refreshMessages = ({ referral, time } = {}, json) => (dispatch, getState) => (
  dispatch(json ? actions.replaceMessages(json) : requestMessageUnreadCount())
    .then((result) => {
      const thread = getState().messages.current;
      if (thread && thread.id === referral) {
        return dispatch(requestMessageThread(referral, thread.organizations));
      }
      return result;
    })
);

export const requestNewMessageThread = (open = true) => dispatch => (
  dispatch(actions.toggleNewMessageThread(open))
);

export const sendAvailableNotes = (text, provider, hospital, referral) => () => apiDirector.validateFetch(`/api/messages/${provider.id}`, {
  method: 'POST',
  body: JSON.stringify({
    message_content: {
      referral_id: referral.id,
      organization_id: provider.id,
      organization_type: provider.organization_type || provider.type || 'Provider',
      patient_id: referral.patient.id,
      text,
      message_attributes: {
        organization_id: hospital.id,
        organization_type: hospital.organization_type || hospital.type || 'Hospital',
      },
    },
  }),
});

export const sendMessage = message => (dispatch, getState) => {
  const organization = message.message_content.organization;
  const thread = getState().messages.current;
  if (!thread) { return Promise.resolve(true); }
  return apiDirector.validateFetch(`/api/messages/${organization.id}`, {
    method: 'POST',
    body: JSON.stringify({
      message_content: {
        referral_id: thread.id,
        organization_id: organization.id,
        organization_type: organization.type,
        patient_id: thread.patient && thread.patient.id,
        text: message.message_content.text,
        message_attributes: {
          organization_id: message.organization.id,
          organization_type: message.organization.type,
        },
      },
    }),
  })
    .then(json => ({
      ...thread,
      messages: thread.messages.concat(json),
    }))
    .then(json => dispatch(actions.replaceMessageThread(thread.id, json)));
};

export const batchUpdateMessages = (messages, operation) => dispatch => (
  apiDirector.validateFetch('/api/messages/batch', {
    method: 'POST',
    body: JSON.stringify({
      messages,
      operation,
    }),
  }).then(({ stats, ...json }) => {
    dispatch(actions.replaceMessages(stats));
    return json;
  })
);

export const sendInBulk = (sender, recipients, topic, text) => () => apiDirector.validateFetch('/api/messages/bulk', {
  method: 'POST',
  body: JSON.stringify({
    bulk_message_request: {
      text,
      referral_id: topic.id,
      sender: {
        organization_id: sender.organization_id || sender.id,
        organization_type: sender.organization_type || sender.type,
      },
      recipients: recipients.map((organization) => ({
        organization_id: organization.organization_id || organization.id,
        organization_type: organization.organization_type || organization.type,
      })),
    },
  }),
});

export const advancedMessageSearch = query => dispatch => apiDirector.validateFetch('/api/messages/search', {
  body: JSON.stringify(query),
  method: 'POST',
}).then(json => dispatch(actions[query.offset ? 'appendToInbox' : 'replaceInbox'](json)));

/* eslint-disable camelcase */
import apiDirector from 'helpers/ApiDirector';
import { numeric } from 'helpers/i18n';
import { DateTime } from 'helpers/dates';

import * as actions from 'ducks/conversations/actions';
import { createShapedReferral } from 'ducks/referrals';

const markLatestAsRead = () => (dispatch, getState) => {
  const thread = getState().conversationThread.current;
  if (!thread || !thread.id || thread.messages.length === 0) { return; }

  const organizations = getState().session.organizations || [];
  const sentToMe = ({ organization_id: id, organization_type: type }) => (
    !organizations.some(organization => (
      type === organization.organization_type && id === organization.id
    ))
  );

  const items = thread.messages.filter(sentToMe);
  if (items.length > 0 && !items[items.length - 1].read_flag) {
    apiDirector.validateFetch(`/api/conversations/${thread.id}/read`, {
      method: 'PATCH',
      body: JSON.stringify({
        organizations: thread.organizations,
      }),
    });
  }
};

const shapeConversation = (json, mapTopicByType) => {
  const {
    topic_id,
    topic_type,
    topic_info,
    conversation_items,
    conversation_organizations,
  } = json;
  const organizations = conversation_organizations.map(({ organization }) => organization);
  return {
    id: json.id,
    organizations,
    topic: {
      id: topic_id,
      type: topic_type,
      info: mapTopicByType(topic_type, topic_info),
    },
    messages: conversation_items.map((item) => {
      const author = conversation_organizations.find(r => (
        r.organization_id === item.organization_id
        && r.organization_type === item.organization_type
      )) || item.organization;

      const notAuthor = conversation_organizations.find(r => !(
        r.organization_id === item.organization_id
        && r.organization_type === item.organization_type
      ));

      let readAt = null;
      if (notAuthor && notAuthor.last_read_at) {
        const sentAt = DateTime.parse(item.created_at);
        if (sentAt.isSameOrBefore(DateTime.parse(notAuthor.last_read_at))) {
          readAt = notAuthor.last_read_at;
        }
      }

      return {
        ...item,
        read_at: readAt,
        read_flag: !!readAt,
        organization: author.organization,
      };
    }),
  };
};

const createConversationShaper = (dispatch, getState) => {
  const mapTopicByType = (type, info) => numeric(type, {
    Referral: createShapedReferral(info)(dispatch, getState),
    other: info,
  });

  return json => shapeConversation(json, mapTopicByType);
};

export const requestMessageThreadByParams = params => (dispatch, getState) => apiDirector.validateFetch('/api/conversations/search', {
  method: 'POST',
  body: JSON.stringify({
    conversation: params,
  }),
})
  .then(createConversationShaper(dispatch, getState))
  .then(json => dispatch(actions.replaceMessageThread(json.id, json)))
  .then(() => dispatch(markLatestAsRead()));

export const requestMessageThreadById = (id, skipMarkRead) => (dispatch, getState) => apiDirector.validateFetch(`/api/conversations/${id}?time=${new Date().getTime()}`)
  .then(createConversationShaper(dispatch, getState))
  .then(json => dispatch(actions.replaceMessageThread(id, json)))
  .then(() => {
    if (skipMarkRead) { return Promise.resolve(true); }
    return dispatch(markLatestAsRead());
  });

export const refreshMessageThread = () => (dispatch, getState) => {
  const thread = getState().conversationThread.current;
  if (thread) {
    dispatch(requestMessageThreadById(thread.id));
  }
};

/**
 * Utility method that takes a referral and optional sentReferral
 * and opens the thread for the associated organizations
 */
export const requestMessageThreadForReferral = (referral, provider, hospital) => (dispatch) => {
  const sender = hospital || referral.hospital;
  const recipient = provider || referral.referralResponse.provider;
  const params = {
    topic_id: referral.id,
    topic_type: 'Referral',
    conversation_organizations_attributes: [
      {
        organization_id: recipient.id,
        organization_type: recipient.organization_type || recipient.type || 'Provider',
      },
      {
        organization_id: sender.id,
        organization_type: sender.organization_type || sender.type || 'Hospital',
      },
    ],
  };
  return dispatch(requestMessageThreadByParams(params));
};

export const requestNewMessageThread = (open = true) => dispatch => (
  dispatch(actions.toggleNewMessageThread(open))
);

export const sendAvailableNotes = (text, provider, hospital, referral) => (dispatch, getState) => apiDirector.validateFetch('/api/conversations', {
  method: 'POST',
  body: JSON.stringify({
    conversation: {
      topic_id: referral.id,
      topic_type: referral.type,
      conversation_organizations_attributes: [provider, hospital].map(org => ({
        organization_id: org.id,
        organization_type: org.type || org.organization_type,
      })),
      conversation_items_attributes: [
        {
          organization_id: provider.id,
          organization_type: provider.type || provider.organization_type,
          user_id: getState().session.user.id,
          text,
        },
      ],
    },
  }),
});

export const sendMessage = ({ message_content: item }) => (dispatch, getState) => {
  const thread = getState().conversationThread.current;
  if (!thread) { return Promise.resolve(true); }
  const {
    text,
    organization: { id: organization_id, type: organization_type },
    user: { id: user_id },
  } = item;
  let saveMethod;
  if (!thread.id) {
    // New conversation!
    saveMethod = apiDirector.validateFetch('/api/conversations', {
      method: 'POST',
      body: JSON.stringify({
        conversation: {
          topic_id: thread.topic.id,
          topic_type: thread.topic.type,
          conversation_organizations_attributes: thread.organizations.map(org => ({
            organization_id: org.id,
            organization_type: org.type || org.organization_type,
          })),
          conversation_items_attributes: [
            {
              organization_id,
              organization_type,
              user_id,
              text,
            },
          ],
        },
      }),
    });
  } else {
    saveMethod = apiDirector.validateFetch(`/api/conversations/${thread.id}`, {
      method: 'POST',
      body: JSON.stringify({
        conversation: {
          id: thread.id,
          conversation_items_attributes: [
            {
              organization_id,
              organization_type,
              user_id,
              text,
            },
          ],
        },
      }),
    });
  }

  return saveMethod
    .then(createConversationShaper(dispatch, getState))
    .then(json => dispatch(actions.replaceMessageThread(thread.id, json)));
};

import React from 'react';
import PropTypes from 'prop-types';
import { css, cx } from 'emotion';
import { createTranslator } from 'helpers/i18n';
import { errorToast } from 'helpers/ui';

import ViewContext from 'components/utils/ViewContext';

import Dropzone from 'react-dropzone';

const MAX_FILE_SIZE_BYTES = undefined; // TODO: Define...

const tr = createTranslator({
  error: {
    invalid: 'This file type or size is not allowed to be uploaded.',
  },
});

const dropzoneStyle = css`
  border: none;
  display: inline-block;
`;

export default class FileDropZone extends React.Component {
  static propTypes = {
    /** What file type to accept? All by defualt. Use mime-type strings */
    accept: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    /** What should display in the droppable zone */
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
    /** CSS class name */
    className: PropTypes.string,
    /** True to disable file upload */
    disabled: PropTypes.bool,
    /** True to allow for multiple files to be uploaded at once. */
    multiple: PropTypes.bool,
    /** Name of file input field */
    name: PropTypes.string,
    /** The max file size (bytes) */
    maxSize: PropTypes.number,
    /** Callback for when a file has been uploaded */
    onFileUploaded: PropTypes.func,
  }

  static defaultProps = {
    accept: undefined,
    className: undefined,
    disabled: undefined,
    maxSize: undefined,
    multiple: undefined,
    name: undefined,
    onFileUploaded: undefined,
  }

  open = () => {
    if (this.input) { this.input.open(); }
  }

  handleFileRejected = () => {
    errorToast(tr('error.invalid'));
  }

  handleFileUpload = (files) => {
    if (this.props.onFileUploaded) {
      this.props.onFileUploaded(files);
    }
  }

  render() {
    return (
      <ViewContext.Consumer>
        {({ readOnly }) => (
          <Dropzone
            ref={(ref) => { this.input = ref; }}
            className={cx(dropzoneStyle, this.props.className)}
            accept={this.props.accept}
            disabled={readOnly || this.props.disabled}
            disableClick
            disablePreview
            multiple={this.props.multiple}
            name={this.props.name || `file-${new Date().getTime()}`}
            maxSize={this.props.maxSize || MAX_FILE_SIZE_BYTES}
            onDropAccepted={this.handleFileUpload}
            onDropRejected={this.handleFileRejected}
          >
            {typeof this.props.children === 'function'
              ? this.props.children({ open: this.open })
              : this.props.children}
          </Dropzone>
        )}
      </ViewContext.Consumer>
    );
  }
}

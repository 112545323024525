/* eslint-disable no-nested-ternary */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// eslint-disable-next-line import/no-named-default
import { default as ReactJSPopup } from 'reactjs-popup';

import { baseModalStyleAttributes, baseModalStyleAttributesV2 } from 'components/utils/Styles';
import { createTrigger, modalOverrides } from 'components/utils/PopupUtils';
import withComponentTheme from 'components/utils/withComponentTheme';
import { ThemeContext } from 'components/utils/ThemeContext';

export const PopupPosition = PropTypes.oneOf([
  'top', 'bottom', 'left', 'right',
  'top left',
  'top center',
  'top right',
  'bottom left',
  'bottom center',
  'bottom right',
  'right top',
  'right center',
  'right bottom',
  'left top',
  'left center',
  'left bottom',
]);

const DEFAULT_DIMENSIONS = { width: 400, height: 'auto' };

const createComponentStyles = () => ({
  v1: {
    styles: {
      BASE_MODAL_STYLE: baseModalStyleAttributes,
    },
  },
  v2: {
    styles: {
      BASE_MODAL_STYLE: baseModalStyleAttributesV2,
    },
  },
});

const overlayStyle = {
  backgroundColor: 'rgba(255,255,255,0.8)',
  overflowY: 'scroll',
};

const hiddenOverlayStyle = {
  display: 'none',
};

/**
 * Display a Popup window with content from the
 * child component of this container. Popup windows
 * have no padding by default, so your provided
 * container should give padding where necessary
 */
class Popup extends React.Component {
  static contextType = ThemeContext;

  findPosition = (modal) => {
    const { position } = this.props;
    if (typeof position !== 'string') {
      return modal ? 'center center' : 'bottom center';
    }
    if (position.indexOf(' ')) {
      return position;
    }
    return `${position} center`;
  }

  /**
   * Toggle the popup visibility state
   * @public
   */
  toggle = () => { if (this.instance) { this.instance.togglePopup(); } }

  /**
   * Close the popup
   * @public
   */
  closePopup = () => { if (this.instance) { this.instance.closePopup(); } }

  handleClose = () => {
    const that = this;

    Promise.resolve(true)
      .then(() => { if (that.instance) { that.instance.closePopup(); } });

    return true;
  }

  render() {
    const modal = this.props.modalMediaTypes.includes(this.props.mediaType);
    const triggerOn = modal && this.props.triggerOn === 'hover' ? 'click' : this.props.triggerOn;
    const popupOverlayStyle = this.props.hideOverlay ? hiddenOverlayStyle : undefined;
    const { theme: { styles: { BASE_MODAL_STYLE } } } = this.context;
    const contentStyle = {
      ...BASE_MODAL_STYLE,
      width: this.props.autoWidth ? 'auto' : modal ? 'auto' : `${(this.props.width || DEFAULT_DIMENSIONS.width)}px`,
      height: `${this.props.autoHeight ? 'auto' : (this.props.height ? `${this.props.height}px` : DEFAULT_DIMENSIONS.height)}`,
      maxHeight: this.props.maxHeight ? `${this.props.maxHeight}px` : undefined,
      minHeight: this.props.minHeight ? `${this.props.minHeight}px` : undefined,
    };
    const emptyContentStyle = {
      display: 'none',
    };
    const content = typeof this.props.children === 'function' ? this.props.children({ close: this.handleClose }) : this.props.children;
    return (
      <ReactJSPopup
        ref={(r) => { this.instance = r; }}
        closeOnDocumentClick={!this.props.hideOverlay}
        contentStyle={!content ? emptyContentStyle : {
          ...modalOverrides,
          ...contentStyle,
          margin: modal ? '14vh auto auto' : undefined,
          overflowY: this.props.disableScrolling ? 'visible' : 'auto',
        }}
        overlayStyle={modal ? overlayStyle : popupOverlayStyle}
        arrow={false}
        on={triggerOn === 'none' ? [] : triggerOn}
        open={this.props.open}
        onClose={this.props.onClose}
        onOpen={this.props.onOpen}
        trigger={createTrigger(this.props)}
        position={this.findPosition(modal)}
        offsetX={!modal ? this.props.offsetX : undefined}
        offsetY={!modal ? this.props.offsetY : undefined}
        modal={modal}
      >
        {content}
      </ReactJSPopup>
    );
  }
}

Popup.propTypes = {
  /** Set the height to auto (overrides height prop) */
  autoHeight: PropTypes.bool,
  /** Set the width to auto (overrides width prop) */
  autoWidth: PropTypes.bool,
  /** The popup content */
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired,
  /** Disable scrolling in popup */
  disableScrolling: PropTypes.bool,
  /** The height of the popup */
  height: PropTypes.number,
  /**
   * True to hide the Popup overlay, false otherwise. If true, the
   * popup can not be closed by clicking the document, you'll need
   * to have a close button or some other means.
   */
  hideOverlay: PropTypes.bool,
  /** Max height of the popup */
  maxHeight: PropTypes.number,
  /** Min height of the popup */
  minHeight: PropTypes.number,
  /** The window size */
  mediaType: PropTypes.oneOf(['small', 'medium', 'large']).isRequired,
  /** The window size that changes the popup to a modal */
  modalMediaTypes: PropTypes.arrayOf(PropTypes.oneOf(['small', 'medium', 'large'])),
  /** Set horizontal offset for popup */
  offsetX: PropTypes.number,
  /** Set vertical offset for popup */
  offsetY: PropTypes.number,
  /**
   * Control open/close state. You may (or may not)
   * also want to set triggerOn="none" when using this.
   */
  open: PropTypes.bool,
  /** Listener for when popup is closed */
  onClose: PropTypes.func,
  /** Listener for when popup is closed */
  onOpen: PropTypes.func,
  /** Position relative to the popup trigger */
  position: PopupPosition,
  /** The node that will trigger the popup on click */
  trigger: PropTypes.node.isRequired, /* eslint-disable-line react/no-unused-prop-types */
  /** Element to wrap the trigger */
  triggerElement: PropTypes.element, /* eslint-disable-line react/no-unused-prop-types */
  /** Event to trigger popup on. Hover trigger will automatically convert to click on mobile. */
  triggerOn: PropTypes.oneOf(['click', 'focus', 'hover', 'none']),
  /** The width of the popup */
  width: PropTypes.number,
};

Popup.defaultProps = {
  autoHeight: false,
  autoWidth: false,
  disableScrolling: undefined,
  height: null,
  hideOverlay: undefined,
  maxHeight: undefined,
  minHeight: undefined,
  modalMediaTypes: ['small'],
  offsetX: undefined,
  offsetY: undefined,
  onClose: undefined,
  onOpen: undefined,
  open: undefined,
  position: 'bottom center',
  triggerElement: undefined,
  triggerOn: 'click',
  width: DEFAULT_DIMENSIONS.width,
};

export default connect(state => ({ mediaType: state.browser.mediaType }),
  null, null, { forwardRef: true })(withComponentTheme(createComponentStyles)(Popup));

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactRouterPropTypes from 'react-router-prop-types';
import { withRouter } from 'react-router';
import styled from 'react-emotion';

import { FEATURE_AIDIN_PRINTER, FEATURE_INBOUND_FAX, FEATURE_EVENT_SUBSCRIPTIONS } from 'ducks/session/types';

import Session from 'models/Session';

import { createTranslator } from 'helpers/i18n';

import NotFound from 'pages/NotFound';

import {
  Panel,
  SplitPanel,
  Responsive,
  Tabs,
} from 'components/utils';
import { LargeHeaderContainerBase as LargeHeaderContainer } from 'components/admin/shared/AdminShared';
import { mq } from 'components/utils/Responsive';

export const INTEGRATION_FEATURES = [
  FEATURE_AIDIN_PRINTER,
  FEATURE_INBOUND_FAX,
  FEATURE_EVENT_SUBSCRIPTIONS,
];

const tr = createTranslator({
  title: 'Aidin Integrations',
  tabs: {
    [FEATURE_AIDIN_PRINTER]: {
      text: 'My Printers',
      path: '/integrations/printers',
    },
    [FEATURE_INBOUND_FAX]: {
      text: 'My Fax Lines',
      path: '/integrations/fax-lines',
    },
    [FEATURE_EVENT_SUBSCRIPTIONS]: {
      text: 'My API Subscriptions',
      path: '/integrations/event-subscriptions',
    },
  },
});

const Container = styled.div`
  margin-top: 20px;
`;

const Divider = styled.div(mq({
  width: ['1060px', '100%', '100%'],
  marginLeft: ['-60px', '0px', '0px'],
  height: '0px',
  borderBottom: 'solid 3px #666666',
}));

const AidinIntegrationsLayout = ({
  location: {
    pathname,
  },
  session: {
    features = {},
  } = {},
  actions,
  children,
}) => {
  const availableFeatures = INTEGRATION_FEATURES.filter(feature => !!features[feature]);

  if (availableFeatures.length === 0) {
    return <NotFound />;
  }

  const links = availableFeatures.map(feature => tr(`tabs.${feature}`));

  return (
    <div>
      <Panel>
        <SplitPanel align="bottom">
          <Panel spacing={4}>
            <h1 css={{ marginBottom: '0px' }}>{tr('title')}</h1>
          </Panel>
          <Responsive size="large">
            {actions}
          </Responsive>
        </SplitPanel>
        <LargeHeaderContainer>
          <Tabs
            active={pathname}
            links={links}
          />
        </LargeHeaderContainer>
      </Panel>
      <Divider />
      <Responsive size={['medium', 'small']}>
        <Container>
          {actions}
        </Container>
      </Responsive>
      <Container>
        {children}
      </Container>
    </div>
  );
};

const Renderable = PropTypes.oneOfType([PropTypes.node, PropTypes.func]);

AidinIntegrationsLayout.propTypes = {
  /** Location property from parent Route */
  location: ReactRouterPropTypes.location.isRequired,
  /** Session Details */
  session: Session.isRequired,
  actions: Renderable,
  children: Renderable.isRequired,
};
AidinIntegrationsLayout.defaultProps = {
  actions: undefined,
};

export default connect(({ session }) => ({ session }))(withRouter(AidinIntegrationsLayout));

import initialState from 'store/initialState';
import * as types from './types';

const replaceProviderNetworks = (_, { providerNetworks }) => ([
  ...providerNetworks,
]);

const addProviderNetwork = (networks, { providerNetwork }) => ([
  ...networks,
  providerNetwork,
]);

const updateProviderNetwork = (networks, { providerNetwork }) => {
  const ids = networks.map(n => n.id);
  const index = ids.indexOf(providerNetwork.id);
  if (index < 0) {
    // somehow the provider network wasn't in state already
    return addProviderNetwork(networks, { providerNetwork });
  }
  const copy = networks.splice();
  copy.splice(index, 1, providerNetwork);
  return copy;
};

const removeProviderNetwork = (networks, { providerNetworkId }) => {
  const ids = networks.map(n => n.id);
  const index = ids.indexOf(providerNetworkId);
  if (index < 0) {
    // somehow the provider network wasn't in state already
    // noop
    return networks;
  }
  const copy = networks.splice();
  copy.splice(index, 1);
  return copy;
};

const providerNetworks = (state = initialState.admin.providerNetworks, action) => {
  const { type, ...entity } = action;

  switch (type) {
    case types.REPLACE_PROVIDER_NETWORKS:
      return replaceProviderNetworks(state, entity);
    case types.ADD_PROVIDER_NETWORK:
      return addProviderNetwork(state, entity);
    case types.UPDATE_PROVIDER_NETWORK:
      return updateProviderNetwork(state, entity);
    case types.REMOVE_PROVIDER_NETWORK:
      return removeProviderNetwork(state, entity);
    default:
      return state;
  }
};

export default { providerNetworks };

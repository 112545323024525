/* eslint-disable no-nested-ternary */

import React from 'react';
import PropTypes from 'prop-types';
import { createTranslator } from 'helpers/i18n';

import Panel from 'components/utils/Panel';
import Popup from 'components/utils/Popup';
import Button from 'components/utils/Button';
import ButtonBar from 'components/utils/ButtonBar';
import Icon from 'components/utils/Icon';
import { PopupContent, Secondary } from 'components/utils/Styles';
import { SENDING_DARKER_FILL, WHITE } from 'components/utils/Colors';

const tr = createTranslator({
  button: {
    cancel: 'Cancel',
    confirm: 'Confirm',
  },
});

const closeHandler = (...handlers) => () => {
  if (handlers) {
    handlers.filter(f => f).forEach((func) => { func(); });
  }
};

const ConfirmationPopup = ({
  onCancel,
  onClose,
  onConfirm,
  color = SENDING_DARKER_FILL,
  confirmButton,
  confirmText = tr('button.confirm'),
  cancelText = tr('button.cancel'),
  children,
  disabled,
  prompt,
  cancelButtonAsLink,
  cancelButtonLeftIcon,
  containerElement: ContentContainer,
  tips,
  disableCloseOnSubmit,
  hideCancelButtonOnSubmit,
  ...popupProps
}) => (
  <Popup
    onClose={closeHandler(onClose)}
    {...popupProps}
  >
    {({ close }) => (
      <ContentContainer tips={tips}>
        <Panel>
          {prompt
            ? <Secondary>{prompt}</Secondary>
            : (children && typeof children === 'function')
              ? children({ close })
              : children}
          <ButtonBar align="right">
            {!hideCancelButtonOnSubmit && (
              <Button
                small
                link={cancelButtonAsLink}
                onClick={closeHandler(close, onCancel)}
                leftIcon={cancelButtonLeftIcon ? (
                  <Icon name={cancelButtonLeftIcon} color={WHITE} />
                ) : undefined}
              >
                {cancelText}
              </Button>
            )}
            {confirmButton || (
              <Button
                small
                color={color}
                disabled={disabled}
                onClick={closeHandler(!disableCloseOnSubmit && close, onConfirm)}
              >
                {confirmText}
              </Button>
            )}
          </ButtonBar>
        </Panel>
      </ContentContainer>
    )}
  </Popup>
);
ConfirmationPopup.propTypes = {
  /** Callback when cancel is clicked */
  onCancel: PropTypes.func,
  /** Callback when the popup is closed. */
  onClose: PropTypes.func,
  /** Callback when confirmation button is clicked */
  onConfirm: PropTypes.func,
  /** The confirmation button color. Defaults to SENDING_DARKER_FILL */
  color: PropTypes.string,
  /** Override confirmation button */
  confirmButton: PropTypes.node,
  /** Text for confirmation button. Defaults to "Confirm" */
  confirmText: PropTypes.string,
  /** Left icon for cancel button. Defaults to undefined */
  cancelButtonLeftIcon: PropTypes.string,
  /** True to render cancel button as a link button, false otherwise (default false) */
  cancelButtonAsLink: PropTypes.bool,
  /** Text for cancellation button. Defaults to "Cancel" */
  cancelText: PropTypes.string,
  /**
   * Show children in place of prompt for custom interface. If a function is
   * given, it will be passed an object with a `close` attribute, which is a
   * function that can be called to programmatically close the popup.
   */
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.func]),
  /** True to disable the confirm button, false otherwise */
  disabled: PropTypes.bool,
  /** Prompt text for confirmation. Overridden if children are present */
  prompt: PropTypes.string,
  /** Optionally override the container element used */
  containerElement: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.func]),
  /** Tips to show in footer (used in conjunction with containerElement) */
  tips: PropTypes.arrayOf(PropTypes.shape({
    tip: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['info', 'warning', 'error']),
  })),
  /** Disable closing the modal when clicking the Add button */
  disableCloseOnSubmit: PropTypes.bool,
  /** Hide cancel button when submitting */
  hideCancelButtonOnSubmit: PropTypes.bool,
};
ConfirmationPopup.defaultProps = {
  onCancel: undefined,
  onClose: undefined,
  onConfirm: undefined,
  color: undefined,
  confirmButton: undefined,
  confirmText: undefined,
  cancelButtonAsLink: undefined,
  cancelButtonLeftIcon: undefined,
  cancelText: undefined,
  children: undefined,
  disabled: undefined,
  prompt: undefined,
  containerElement: PopupContent,
  tips: undefined,
  disableCloseOnSubmit: false,
  hideCancelButtonOnSubmit: false,
};

export default ConfirmationPopup;

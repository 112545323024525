import styled from 'react-emotion';

import {
  getAttribute,
} from './functions';

export default styled.div(({
  height = '100%',
  width,
  margin,
  color,
  backgroundColor,
  selected,
  border,
  customStyles,
  options: {
    selectedBackgroundColor,
    selectedBorderStyle,
    selectedCellColor,
  } = {},
}) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height,
  width,
  margin,
  color: getAttribute(color, selected, selectedCellColor),
  backgroundColor: getAttribute(backgroundColor, selected, selectedBackgroundColor),
  border: getAttribute(border, selected, selectedBorderStyle),
  ...customStyles,
}));

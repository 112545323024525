import GlobalModal, { modals } from 'helpers/GlobalModal';

import * as types from './types';

export const replaceMessages = messages => dispatch => Promise.resolve(dispatch({
  type: types.REPLACE_MESSAGE_LIST,
  ...messages,
  initialized: true,
}));

export const replaceMessageThread = (id, thread) => dispatch => Promise.resolve(dispatch({
  type: types.REPLACE_CURRENT_THREAD,
  id,
  thread,
})).then((result) => {
  GlobalModal.open(modals.TOGGLE_CONVERSATION_MODAL);
  return result;
});

const handleClearThreadData = () => dispatch => Promise.resolve(dispatch({
  type: types.CLOSE_CURRENT_THREAD,
}));

export const closeMessageThread = () => dispatch => new Promise((resolve, reject) => {
  GlobalModal.close(modals.TOGGLE_CONVERSATION_MODAL)
    .then(() => dispatch(handleClearThreadData()))
    .then(resolve)
    .catch(reject);
});

export const toggleNewMessageThread = (open = true) => dispatch => Promise.resolve(dispatch({
  type: types.TOGGLE_NEW_MESSAGE_THREAD,
  open,
}));

export const appendToInbox = messages => dispatch => Promise.resolve(dispatch({
  type: types.APPEND_INBOX_LIST,
  ...messages,
}));

export const replaceInbox = messages => dispatch => Promise.resolve(dispatch({
  type: types.REPLACE_INBOX_LIST,
  ...messages,
}));

export const mergeInbox = (status, conversations) => dispatch => Promise.resolve(dispatch({
  type: types.MERGE_INBOX_LIST,
  status,
  conversations,
}));

export const replaceConversations = conversations => dispatch => Promise.resolve(dispatch({
  type: types.REPLACE_CONVERSATIONS,
  conversations,
}));

export const clearConversations = () => dispatch => Promise.resolve(dispatch({
  type: types.REPLACE_CONVERSATIONS,
  conversations: [],
}));

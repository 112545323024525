import PropTypes from 'prop-types';
import AidinPropTypes from 'helpers/AidinPropTypes';

const Attachment = PropTypes.shape({
  /** The ID of this attachment, or something like it */
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Name of the attachment */
  name: PropTypes.string,
  /** Help text for attachment. */
  help: PropTypes.string,
  /** These attachments can not be deleted */
  protected: PropTypes.bool,
  /** Source name */
  source: PropTypes.string,
  /** The data source for the attachment */
  dataSource: PropTypes.oneOf(['attachments', 'patient_reports']),
  /** Denotes that this attachment is generated, not uploaded by user */
  generated: PropTypes.bool,
  /** Custom URL */
  url: PropTypes.string,
  /** Transitory status of the attachment, if any */
  pendingState: PropTypes.oneOf(['add', 'remove', 'update', 'fax']),
  /** Content type of attached file */
  file_content_type: PropTypes.string,
  /** date added */
  dateAdded: AidinPropTypes.dateTime,
  /** The role of the user who uploaded. */
  referralRole: PropTypes.oneOf(['sending', 'receiving']),
});

export default Attachment;

import React from 'react';
import text from 'components/login/HelpSuggestions/translations';
import { createTranslator } from 'helpers/i18n';
import withThemedComponents, { ThemeConfig } from 'components/utils/withThemedComponents';
import {
  Panel, ButtonBar,
} from 'components/utils';
import HelpSuggestions from 'components/login/HelpSuggestions';
import ReturnToLoginButton from 'components/login/ReturnToLoginButton';
import baseText from '../translations';
import { createComponentStyles } from '../styles';

const tr = createTranslator({ ...baseText, ...text });

const Success = ({
  theme: {
    styles: {
      SuccessContainer, buttonBarStyle,
    },
  },
}) => (
  <SuccessContainer>
    <Panel spacing={20}>
      <h3>{tr('success.thanks')}</h3>
      <h4>{tr('success.check')}</h4>
      <Panel spacing={0}>
        <h3>{tr('success.help')}</h3>
        <HelpSuggestions />
      </Panel>
      <ButtonBar align="center" className={buttonBarStyle}>
        <ReturnToLoginButton />
      </ButtonBar>
    </Panel>
  </SuccessContainer>
);

Success.propTypes = {
  theme: ThemeConfig.isRequired,
};

export default withThemedComponents(createComponentStyles)(Success);

import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'emotion';
import styled from 'react-emotion';
import SignatureCanvas from 'react-signature-canvas';

import ButtonBar from 'components/utils/ButtonBar';
import Field from 'components/utils/Field';
import Icon from 'components/utils/Icon';
import * as Colors from 'components/utils/Colors';

const { TEXT_ERROR } = Colors;

const Container = styled.div`
  position: relative;
  border: 1px solid ${props => (props.errors ? TEXT_ERROR : 'transparent')};
  border-radius: 2px;
  background-color: #ffffff;
  box-shadow: inset 0 0.5px 3px 0 rgba(153, 153, 153, 0.57);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  height: 200px;
  padding: 4px;
  &:focus {
    outline: none;
    border: 1px solid #61cee1;
  }
`;

const sigCanvasStyle = (hidden, full) => css`
  background: transparent;
  width: ${full ? '100%' : '400px'};
  height: ${full ? '100%' : '200px'};
  ${hidden && 'visibility: hidden;'}
`;

const toolBarStyle = css`
  position: absolute;
  top: 8px;
  right: 8px;
`;

/**
 * Draw a signature using finger or mouse and save it as a
 * base64-encoded string. This is an uncontrolled component,
 * so only defaultValue can be set.
 */
class SignatureField extends React.Component {
  static propTypes = {
    /** True to allow undo of line strokes, false otherwise (default false) */
    allowUndo: PropTypes.bool,
    /** The dataUrl for this signature image */
    defaultValue: PropTypes.string,
    /** True to disable this field (view-only), false otherwise */
    disabled: PropTypes.bool,
    /** The data input/output format. Defaults to json. */
    format: PropTypes.oneOf(['json', 'url']),
    /** True for full-width, full-height size, false for default 400x200 */
    full: PropTypes.bool,
    /** Class name to attach to the view-only image */
    imageClassName: PropTypes.string,
    /** Handle change event; returns the encoded image string, or null on clear */
    onChange: PropTypes.func,
  }

  static defaultProps = {
    allowUndo: false,
    defaultValue: undefined,
    disabled: false,
    format: 'json',
    full: false,
    imageClassName: undefined,
    onChange: undefined,
  }

  constructor(props) {
    super(props);
    this.state = { versions: [props.defaultValue], image: null };
  }

  componentDidMount() {
    this.handleRedraw(this.props.defaultValue);
    if (this.props.disabled) {
      this.input.off();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.defaultValue !== nextProps.defaultValue) {
      this.setState({ image: null, versions: [nextProps.defaultValue] }, this.handleRedraw);
    }
    if (this.props.disabled !== nextProps.disabled) {
      if (nextProps.disabled) {
        this.input.off();
      } else {
        this.input.on();
      }
    }
  }

  handleRedraw = () => {
    const value = this.state.versions[0];
    if (value) {
      let inputFormatData = false;
      if (value.indexOf('data:') === 0) {
        inputFormatData = true;
        this.input.fromDataURL(value);
      } else {
        const json = JSON.parse(value);
        const lines = json.lines || [];

        const pointGroups = lines.map(line => line.map(([x, y]) => ({ x, y })));

        this.input.fromData(pointGroups);
      }
      if (this.props.disabled) {
        this.setState({
          image: inputFormatData ? value : this.input.getTrimmedCanvas().toDataURL(),
        });
      }
    } else {
      this.input.clear();
    }
  }

  handleChange = () => {
    let value = null;
    if (!this.input.isEmpty()) {
      if (this.props.format === 'url') {
        value = this.input.getCanvas().toDataURL();
      } else {
        const json = { lines: this.input.toData().map(line => line.map(({ x, y }) => [x, y])) };
        value = JSON.stringify(json);
      }
    }
    this.setState(({ versions }) => ({ versions: [value, ...versions] }), () => {
      if (this.props.onChange) {
        this.props.onChange(value);
      }
    });
  }

  handleReset = () => {
    this.setState({ versions: [] }, () => {
      this.input.clear();

      if (this.props.onChange) {
        this.props.onChange(null);
      }
    });
  }

  handleUndo = () => {
    this.setState(({ versions }) => ({ versions: versions.slice(1) }), () => {
      this.handleRedraw();
      if (this.props.onChange) {
        this.props.onChange(this.state.versions[0]);
      }
    });
  }

  render() {
    if (this.state.image) {
      return <img data-printable="pdf" className={this.props.imageClassName} alt="signature" src={this.state.image} />;
    }
    return (
      <Container>
        <SignatureCanvas
          ref={(ref) => { this.input = ref; }}
          penColor="#333333"
          canvasProps={{ className: sigCanvasStyle(this.props.disabled, this.props.full) }}
          onEnd={this.handleChange}
        />
        {!this.props.disabled && (
          <ButtonBar spacing={8} className={toolBarStyle}>
            {this.props.allowUndo && this.state.versions.length > 1 && (
              <Icon
                name="mp-history"
                color="#c8c8c8"
                onClick={this.handleUndo}
              />
            )}
            <Icon
              name="mp-cancel"
              color="#c8c8c8"
              onClick={this.handleReset}
            />
          </ButtonBar>
        )}
      </Container>
    );
  }
}

export default Field(SignatureField);

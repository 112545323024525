import React from 'react';
import { css } from 'emotion';
import PropTypes from 'prop-types';
import Help from './Help';
import { ERROR_DARKER_FILL } from './Colors';
import Panel from './Panel';
import { Label } from './Styles';

const iconStyle = css`
  color: ${ERROR_DARKER_FILL};
`;

/**
  PDFStatus is used to inform the user that a PDF is unreadable. It is based off
  of the Help component. The default render is a red warning icon.
  onHover, there is a popup message displayed that delivers information to the user
  about the unreadable PDF.
*/

const PDFStatus = (props) => (
  <Help
    icon="mp-warning"
    iconStyle={iconStyle}
    triggerOn="hover"
    position={props.position || 'bottom left'}
  >
    <Panel>
      {props.title && <Label>{props.title}</Label>}
      {props.message}
    </Panel>
  </Help>
);

PDFStatus.propTypes = {
  /** The title of the popup if a pdf is unreadable exists */
  title: PropTypes.string,
  /** The main message body in the popup if a pdf is unreadable exists */
  message: PropTypes.string.isRequired,
  /** Sets the position of the popup in relation to the trigger */
  position: PropTypes.string,
};
PDFStatus.defaultProps = {
  title: undefined,
  position: undefined,
};

export default PDFStatus;

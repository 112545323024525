import React, { useState } from 'react';
import PropTypes from 'prop-types';

import styled from 'react-emotion';

import apiDirector from 'helpers/ApiDirector';
import { baseModalStyleAttributes } from 'components/utils/Styles';

const HoverInspector = ({
  endpoint,
  display,
  children,
  enterTimeout,
  exitTimeout,
  hoverContainerStyles,
}) => {
  const [fetching, setFetching] = useState(false);
  const [reply, setReply] = useState({ entity: null, error: null });
  const [show, setShow] = useState(false);

  let enterTimer;
  let exitTimer;
  let mouse = false;

  const clearTimers = () => {
    if (exitTimer) {
      clearTimeout(exitTimer);
    }
    if (enterTimer) {
      clearTimeout(exitTimer);
    }
  };

  const handleMouseOut = () => {
    clearTimers();
    mouse = false;
    exitTimer = setTimeout(() => { setShow(false); }, exitTimeout);
  };

  const handleMouseOver = () => {
    clearTimers();
    mouse = true;
    enterTimer = setTimeout(() => {
      if (mouse) {
        if (!fetching) {
          setFetching(true);
          apiDirector.validateFetch(endpoint).then((json) => {
            setReply({ entity: json, error: null });
          })
            .catch((error) => { setReply({ entity: null, error }); });
        }
        setShow(true);
      }
    }, enterTimeout);
  };

  return (
    <>
      <div
        css={{ cursor: 'help' }}
        onBlur={handleMouseOut}
        onFocus={handleMouseOver}
        onMouseOut={handleMouseOut}
        onMouseOver={handleMouseOver}
      >
        {children}
      </div>
      { show && (
        <HoverContainer
          css={hoverContainerStyles}
          onBlur={handleMouseOut}
          onFocus={handleMouseOver}
          onMouseOut={handleMouseOut}
          onMouseOver={handleMouseOver}
        >
          {display(reply)}
        </HoverContainer>
      )}
    </>
  );
};

/* eslint-disable react/forbid-prop-types */
// we don't want to enumerate all of CSS for the hoverContainerStyles shape
HoverInspector.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  display: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
  endpoint: PropTypes.string.isRequired,
  hoverContainerStyles: PropTypes.object,
  enterTimeout: PropTypes.number,
  exitTimeout: PropTypes.number,
};
/* eslint-enable react/forbid-prop-types */

HoverInspector.defaultProps = {
  hoverContainerStyles: {},
  enterTimeout: 500,
  exitTimeout: 300,
};

export const HoverContainer = styled.div({
  ...baseModalStyleAttributes,
  display: undefined,
  height: undefined,
  overflow: 'scroll',
  position: 'absolute',
  zIndex: '100',
});

export default HoverInspector;

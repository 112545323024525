import React from 'react';
import { debounce } from 'lodash';

const getDisplayName = WrappedComponent => WrappedComponent.displayName || WrappedComponent.name || 'Component';

/**
 * Sends a hovering prop to your component when it is being
 * focused on moused over.
 *
 * Also takes an optional options object that supports:
 * - containerClassName, which applies a className to the containing component
 * - direct, which skips the containing component altogether, passinng events to
 *   the WrappedComponent directly. It must accept props for onBlur, onFocus,
 *   onMouseOut and onMouseOver.
 */
const withHoverState = (WrappedComponent, {
  containerClassName,
  direct = false,
} = {}) => class extends React.Component {
  static displayName = `withHoverState(${getDisplayName(WrappedComponent)})`;

  constructor(props) {
    super(props);
    this.state = { hovering: false };
    this.toggleActive = debounce(this.handleToggleActive, 50);
  }

  handleToggleActive = (hovering) => {
    this.setState({ hovering });
  }

  render() {
    const show = () => { this.toggleActive(true); };
    const hide = () => { this.toggleActive(false); };

    const hoverProps = {
      onFocus: show,
      onMouseOver: show,
      onBlur: hide,
      onMouseOut: hide,
    };

    if (direct) {
      return (
        <WrappedComponent
          {...hoverProps}
          hovering={this.state.hovering}
          {...this.props}
        />
      );
    }

    return (
      <div className={containerClassName} {...hoverProps}>
        <WrappedComponent
          hovering={this.state.hovering}
          {...this.props}
        />
      </div>
    );
  }
};

export default withHoverState;

import React from 'react';
import PropTypes from 'prop-types';
import { flatten } from 'lodash';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { css } from 'emotion';
import styled from 'react-emotion';
import { createTranslator, numeric } from 'helpers/i18n';
import helpTextParser from 'helpers/helpTextParser';
import AidinPropTypes from 'helpers/AidinPropTypes';
import {
  ContentPanel,
  Divider,
  Fragment,
  Panel,
  Icon,
  Styles,
} from 'components/utils';
import {
  PrimaryColumn,
} from 'components/login/LoginStyles';
import { Row, Column, mq } from 'components/utils/Responsive';
import withThemedComponents, { ThemeConfig } from 'components/utils/withThemedComponents';

import faqTranslations from 'translations/faq';

const { Secondary } = Styles;

const tr = createTranslator({
  title: 'Frequently Asked Questions',
  questions: faqTranslations,
});

const IconContainer = styled.div`
  width: 40px;
`;

const AccountContainer = styled.div`
  padding: 10px 0px;
  .faq-item {
    margin: 0px 20px;
  }
  ${IconContainer} {
    display: flex;
    justify-content: center;
  }
`;

const fullWidthStyle = css`
  &.panel-item-1 {
    flex-basis: 100%;
  }
`;

const renderItem = (key, item, {
  styles: {
    QuestionContainer,
    QuestionIcon,
    QuestionTitle,
    QuestionText,
    iconColor,
    contentClassName,
  },
}) => (
  <QuestionContainer key={key}>
    <Panel className={contentClassName} orientation="horizontal" spacing={40} itemClassName={fullWidthStyle}>
      <QuestionIcon>
        <Icon size={50} name={item.icon} color={iconColor} />
      </QuestionIcon>
      <Panel spacing={2}>
        <QuestionTitle>{item.title}</QuestionTitle>
        <QuestionText>{helpTextParser(item.description)}</QuestionText>
      </Panel>
    </Panel>
  </QuestionContainer>
);

const MainContentPanelV2 = ({ title, children }) => (
  <Panel spacing={0}>
    <h3>{title}</h3>
    <div>
      {children}
    </div>
  </Panel>
);
MainContentPanelV2.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

const defaultQuestionOrder = Object.keys(tr('questions'));

const createComponentStyles = ({ version, theme: { colors } }) => numeric(version, {
  other: {
    styles: {
      ContentContainer: PrimaryColumn,
      MainContentPanel: ContentPanel,
      ContentRow: AccountContainer,
      QuestionContainer: Fragment,
      QuestionIcon: IconContainer,
      QuestionTitle: 'h4',
      QuestionText: 'div',
      iconColor: colors.GRAY_FILL,
      contentClassName: 'faq-item',
    },
    options: {
      showDivider: true,
      getQuestionOrder: () => defaultQuestionOrder,
    },
  },
  v2: {
    styles: {
      ContentContainer: Fragment,
      MainContentPanel: MainContentPanelV2,
      ContentRow: Row,
      QuestionContainer: styled(Column)(mq({
        marginTop: '40px',
        '> div': {
          width: ['80%', '100%', '100%'],
        },
      })),
      QuestionIcon: IconContainer,
      QuestionTitle: styled('h4')({
        marginTop: '6px',
      }),
      QuestionText: Secondary,
      iconColor: colors.SENDING_PRIMARY_BUTTON_FILL,
    },
    options: {
      columns: [2, 1, 1],
      showDivider: false,
      getQuestionOrder: size => numeric(size, {
        // ordering is top-down in v2 instead of left-to-right
        large: defaultQuestionOrder.filter((_, idx) => !(idx % 2))
          .concat(defaultQuestionOrder.filter((_, idx) => idx % 2)),
        other: defaultQuestionOrder,
      }),
    },
  },
});

const getSortedQuestions = (ordering) => flatten(defaultQuestionOrder)
  .sort((a, b) => {
    const aIdx = ordering.findIndex(item => item === a);
    const bIdx = ordering.findIndex(item => item === b);
    return aIdx - bIdx;
  });

const AccountFAQPanel = ({
  size,
  theme,
  theme: {
    styles: {
      ContentContainer,
      MainContentPanel,
      ContentRow,
    },
    options: {
      columns,
      showDivider,
      getQuestionOrder,
    },
  },
}) => (
  <ContentContainer>
    <MainContentPanel title={tr('title')}>
      <ContentRow columns={columns}>
        {getSortedQuestions(getQuestionOrder(size)).map((i, index, { length }) => {
          const item = [
            renderItem(i, tr(`questions.${i}`), theme),
          ];
          if (showDivider && index < length - 1) {
            item.push(<Divider key={`divider-${i}`} margin={25} />);
          }
          return item;
        })}
      </ContentRow>
    </MainContentPanel>
  </ContentContainer>
);
AccountFAQPanel.propTypes = {
  size: AidinPropTypes.mediaType.isRequired,
  theme: ThemeConfig.isRequired,
};

export default compose(
  withThemedComponents(createComponentStyles),
  connect(({ browser }) => ({ size: browser.mediaType })),
)(AccountFAQPanel);

import * as types from './types';

export const pushToLoadingQueue = (key, timeoutId, metadata = {}) => dispatch => (
  Promise.resolve(dispatch({
    type: types.LOADING_QUEUE_PUSH_KEY,
    key,
    timeoutId,
    metadata,
  }))
);

export const popFromLoadingQueue = key => (dispatch, getState) => (
  Promise.resolve(getState().loadingQueue[key])
    .then((data) => ({ key, ...data }))
    .then((data) => {
      dispatch({
        type: types.LOADING_QUEUE_POP_KEY,
        key,
      });
      return data;
    })
);

export const updateLoadingQueueMetadata = (key, metadata) => dispatch => Promise.resolve(dispatch({
  type: types.LOADING_QUEUE_UPDATE_KEY,
  key,
  metadata,
}));

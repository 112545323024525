/* eslint-disable jsx-a11y/tabindex-no-positive */

import React from 'react';
import PropTypes from 'prop-types';
import { createTranslator, pluralize } from 'helpers/i18n';
import md from 'helpers/markdown';
import apiDirector from 'helpers/ApiDirector';
import { confirmationToast } from 'helpers/ui';
import Provider from 'models/Provider';

import {
  Panel,
  Divider,
  ButtonBar,
  Button,
  Icon,
  Spinner,
  TextField,
  Colors,
} from 'components/utils';

import ProviderLookupSearchPanel from './ProviderLookupSearchPanel';

const { RECEIVING_DARKER_FILL, WHITE } = Colors;

export const tr = createTranslator({
  title: 'Trying to get access to Aidin? Let\'s find the organization where you work.',
  label: {
    name: 'Your Organization\'s Name',
    zip: 'Zip Code',
  },
  placeholder: {
    name: 'e.g. All About Health Care',
  },
  help: {
    name: md('Consider legal names and DBA (Doing Business As) names when making your search. If this provider is Medicare-certified, you may be able to use [their website search](https://www.medicare.gov/forms-help-resources/find-compare-doctors-hospitals-other-providers) to find how their organization is listed.'),
    zip: 'If you don’t know the zip code, please use a nearby zip code and we\'ll find organizations in the area.',
  },
  button: {
    search: 'Search Providers',
    updateSearch: 'Update Search',
    submit: count => `Claim ${count} ${pluralize(count, 'Provider')}`,
  },
  confirm: {
    contacted: name => `We've sent an email to the Aidin members at ${name}. Please get in touch with them to get activated!`,
    contactedUrgently: name => `We've sent an email to the Aidin members at ${name}. Please get in touch with them to get activated! This provider has an active patient referral ready for your review. For immediate access, please refer to your referral notification for an invitation code to access your organization.`,
  },
});

class ProviderLookupPanel extends React.Component {
  static propTypes = {
    claims: PropTypes.arrayOf(Provider),
    user: PropTypes.shape({
      first: PropTypes.string,
      last: PropTypes.string,
      email: PropTypes.string,
      invitation_token: PropTypes.string,
    }),
    onRemoveOrganizationFromClaim: PropTypes.func.isRequired,
    onSubmitClaims: PropTypes.func.isRequired,
    onRequestCreateProvider: PropTypes.func,
  }

  static defaultProps = {
    claims: [],
    user: undefined,
    onRequestCreateProvider: undefined,
  }

  constructor(props) {
    super(props);
    this.state = {
      form: {},
      providers: this.getInitialProvidersState(),
    };
  }

  /**
   * Reset the form
   * @public
   */
  reset = () => {
    this.setState({
      form: {},
      providers: this.getInitialProvidersState(),
    });
  }

  /**
   * Focus the main input
   * @public
   */
  focus = () => {
    if (this.focusInput) {
      this.focusInput.field.focus();
    }
  }

  getInitialProvidersState = () => ({
    loading: false,
    results: {
      index: [],
      content: {},
    },
    contacted: [],
    sequence: 0,
    visible: false,
  })

  handleFormChange = name => (value) => {
    this.setState(({ form }) => ({
      form: {
        ...form,
        [name]: value,
      },
    }));
  }

  handleClaimProviders = () => {
    if (this.props.onSubmitClaims) {
      this.props.onSubmitClaims();
    }
  }

  handleSubmit = () => {
    // TODO: implement.
    if (this.props.onSubmit) {
      this.props.onSubmit();
    }
  }

  handleRequestJoinOrganizationResend = provider => apiDirector.validateFetch(`/api/providers/${provider.id}/request-invitation`, {
    method: 'POST',
    body: JSON.stringify({
      user: ['first', 'last', 'email'].reduce((user, key) => ({ ...user, [key]: this.props.user[key] }), {}),
      bypass_invitation_check: true,
    }),
  })
    .then(({ urgent }) => {
      if (urgent) {
        confirmationToast(tr('confirm.contactedUrgently', provider.name));
      } else {
        confirmationToast(tr('confirm.contacted', provider.name));
      }
    })
    .then(() => {
      this.setState(({ providers }) => ({
        providers: {
          ...providers,
          contacted: providers.contacted.slice().concat(provider),
        },
      }));
    })

  handleRequestJoinOrganization = provider => apiDirector.validateFetch(`/api/providers/${provider.id}/request-invitation`, {
    method: 'POST',
    body: JSON.stringify({
      user: ['first', 'last', 'email', 'invitation_token', 'locked_at'].reduce((user, key) => ({ ...user, [key]: this.props.user[key] }), {}),
    }),
  })
    .then(({ urgent }) => {
      if (urgent) {
        confirmationToast(tr('confirm.contactedUrgently', provider.name));
      } else {
        confirmationToast(tr('confirm.contacted', provider.name));
      }
    })
    .then(() => {
      this.setState(({ providers }) => ({
        providers: {
          ...providers,
          contacted: providers.contacted.slice().concat(provider),
        },
      }));
    })
    .catch((e) => {
      const { code } = e.response;
      const person = this.props.user;
      if (person.invitation_token && code === 409) {
        this.props.history.push('/devise_users/sign_in?support=activate');
        return Promise.reject(e);
      } if (code === 409) {
        return this.handleRequestJoinOrganizationResend(provider);
      }
      throw e;
    })

  handleSearchForProviders = () => {
    const { form: { name, zip } = {} } = this.state;
    if (name && zip) {
      const handleResults = (results) => {
        this.setState(({ providers }) => ({
          providers: {
            ...providers,
            results,
            loading: false,
            visible: true,
          },
        }));
      };

      this.setState(({ providers }) => ({
        providers: {
          ...providers,
          loading: true,
        },
      }), () => {
        if (this.props.requestSimilarProviderSearch) {
          setTimeout(() => {
            this.props.requestSimilarProviderSearch({
              indexContent: true,
              query: name,
              zip,
            }).then(handleResults);
          }, 1500);
        } else {
          apiDirector.validateFetch('/api/search/providers', {
            method: 'POST',
            body: JSON.stringify({
              excludeUserOrganizations: this.props.user ? this.props.user.id : undefined,
              indexContent: true,
              query: { name },
              zip,
            }),
          })
            .then(handleResults)
            .catch(() => {
              this.setState(({ providers }) => ({
                providers: {
                  ...providers,
                  loading: false,
                },
              }));
            });
        }
      });
    }
  }

  isSubmitDisabled = () => ['name', 'zip'].some(key => (
    !this.state.form[key]
  ))

  createTextField = (name, props) => (
    <TextField
      required
      name={name}
      label={tr(`label.${name}`)}
      placeholder={tr(`placeholder.${name}`)}
      value={this.state.form[name]}
      onChange={this.handleFormChange(name)}
      help={tr(`help.${name}`)}
      {...props}
    />
  )

  renderResults = () => {
    const {
      contacted, results, visible, loading,
    } = this.state.providers;

    const {
      claims,
      user,
    } = this.props;

    if (loading) {
      return (
        <Panel spacing={10} align="center">
          <Spinner />
        </Panel>
      );
    }

    if (!visible) { return null; }

    return (
      <Panel spacing={10}>
        <ProviderLookupSearchPanel
          claims={claims}
          contacted={contacted}
          results={results}
          user={user}
          onAddOrganizationToClaim={this.props.onAddOrganizationToClaim}
          onRemoveOrganizationFromClaim={this.props.onRemoveOrganizationFromClaim}
          onRequestJoinOrganization={this.handleRequestJoinOrganization}
          onClickNoResults={this.props.onRequestCreateProvider}
        />
      </Panel>
    );
  }

  render() {
    const {
      providers: {
        loading,
        visible: resultsVisible,
      } = {},
    } = this.state;
    const { claims = [] } = this.props;
    return (
      <Panel spacing={40}>
        <Panel spacing={0}>
          <h3>{tr('title')}</h3>
          <Panel>
            <Divider margin={0} />
            {this.createTextField('name', {
              ref: (ref) => { this.focusInput = ref; },
              tabIndex: 1,
              onSubmit: this.isSubmitDisabled() ? undefined : this.handleSearchForProviders,
            })}
            {this.createTextField('zip', {
              tabIndex: 2,
              onSubmit: this.isSubmitDisabled() ? undefined : this.handleSearchForProviders,
            })}
          </Panel>
        </Panel>
        {resultsVisible && (
          <ButtonBar align="center">
            <Button
              small
              tabIndex={3}
              disabled={loading || this.isSubmitDisabled()}
              color={RECEIVING_DARKER_FILL}
              onClick={this.handleSearchForProviders}
            >
              {tr('button.updateSearch')}
            </Button>
          </ButtonBar>
        )}
        {this.renderResults()}
        {resultsVisible && claims.length > 0 && (
          <ButtonBar align="right">
            <Button
              color={RECEIVING_DARKER_FILL}
              rightIcon={<Icon name="chevron-right" color={WHITE} size={30} />}
              onClick={this.handleClaimProviders}
            >
              {tr('button.submit', claims.length)}
            </Button>
          </ButtonBar>
        )}
        {!loading && !resultsVisible && (
          <ButtonBar align="right">
            <Button
              tabIndex={3}
              disabled={loading || this.isSubmitDisabled()}
              color={RECEIVING_DARKER_FILL}
              rightIcon={<Icon name="chevron-right" color={WHITE} size={30} />}
              onClick={this.handleSearchForProviders}
            >
              {tr('button.search')}
            </Button>
          </ButtonBar>
        )}
      </Panel>
    );
  }
}

export default ProviderLookupPanel;

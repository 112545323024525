/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */

import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion';
import { css, cx } from 'emotion';
import { numeric } from 'helpers/i18n';
import { applyTestAttribute } from 'helpers/development';

import * as Colors from 'components/utils/Colors';
import { ThemeContext } from 'components/utils/ThemeContext';
import withComponentTheme from 'components/utils/withComponentTheme';
import ThemeControl from 'components/utils/ThemeControl';

const {
  GRAY_FILL, MEDIUM_LIGHT_GRAY_FILL,
} = Colors;

const createComponentStyles = ({ theme: { colors } }) => ({
  v1: {
    styles: {
      Label: {
        lineHeight: '1.21',
      },
      SquareButtonContainer: {
        padding: '0',
        borderColor: colors.GRAY_FILL,
        filter: ({ disabled }) => (disabled ? 'none' : 'brightness(1.1)'),
      },
    },
  },
  v2: {
    styles: {
      Label: {
        lineHeight: '1.5',
      },
      SquareButtonContainer: {
        padding: '3px 0 4px',
        borderColor: colors.PRACTICE_FILL,
        filter: 'none',
      },
      buttonStyle: css({
        borderRadius: '5px',
      }),
    },
  },
});

/**
 * Automatically swap a v1 button color with the equivalent v2 color
 */
export const getTranslatedColor = (color, version, { colors }) => {
  if (!color) { return color; }
  const {
    v1: { colors: originalColors },
  } = ThemeControl;
  return numeric(version, {
    other: color,
    v2: numeric(color, {
      [originalColors.SENDING_PRIMARY_BUTTON_FILL]: colors.SENDING_PRIMARY_BUTTON_FILL,
      [originalColors.RECEIVING_PRIMARY_BUTTON_FILL]: colors.RECEIVING_PRIMARY_BUTTON_FILL,
      other: color,
    }),
  });
};

const getButtonSize = size => numeric(size, {
  sm: '6px .9rem 5px',
  lg: '16px 1.55rem 15.5px',
  other: '12px 1.1rem 11px',
});

const getFontSize = size => numeric(size, {
  lg: 22,
  other: 19,
});

const getRoundedRadius = size => numeric(size, {
  sm: '13.5px',
  lg: '33px',
  other: '22px',
});

const sizeToString = (props) => {
  const { small, large, size } = props;
  const allowed = ['sm', 'lg'];
  if (allowed.some(i => i === size)) { return size; }
  if (small) { return 'sm'; }
  if (large) { return 'lg'; }
  return 'other';
};

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${({ justify = 'space-between' }) => justify};
  margin: ${({ size }) => getButtonSize(size)};
  color: ${({ color, theme }) => (color === theme.colors.WHITE ? theme.colors.GRAY_FILL : color === theme.colors.TRANSPARENT ? 'inherit' : theme.colors.WHITE)};
`;

const Label = styled.div`
  font-family: ${({ theme }) => theme.fonts.LABEL_TEXT};
  font-weight: 900;
  line-height: ${({ theme }) => theme.styles.Label.lineHeight};
  text-align: center;
  -webkit-font-smoothing: ${({ color, theme }) => (color === theme.colors.WHITE ? 'inherit' : 'antialiased')};
  text-decoration: ${({ link }) => (link ? 'underline' : 'inherit')};
`;

const SquareButtonContainer = styled.button`
  position: relative;
  background-color: ${({
    link, color, theme, disabled, disabledColor,
  }) => ((link || color === theme.colors.TRANSPARENT) ? theme.colors.TRANSPARENT : disabled ? disabledColor : color)};
  border: ${({ link, color, theme }) => (link || color !== theme.colors.WHITE ? '0px' : color === theme.colors.TRANSPARENT ? '0px' : `1px solid ${theme.styles.SquareButtonContainer.borderColor}`)};
  padding: ${({ theme }) => theme.styles.SquareButtonContainer.padding};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  display: inline-block;
  font-size: 15px;
  transition: filter .2s ease;
  &:hover, &:focus {
    filter: ${({ theme }) => theme.styles.SquareButtonContainer.filter};
  }
  &:focus {
    outline: none;
    top: 1px;
  }
`;

const RoundedButtonContainer = styled(SquareButtonContainer)`
  border-radius: ${({ size }) => getRoundedRadius(size)};
  font-size: ${({ size }) => getFontSize(size)}px;
`;

const ButtonIconBase = styled.div`
  line-height: 1.21;
  ${({ size }) => size === 'lg' && `> div { font-size: ${getFontSize(size) + 3}px; }`}
`;

const LeftIcon = styled(ButtonIconBase)`
  margin-right: 8px;
`;

const RightIcon = styled(ButtonIconBase)`
  margin-left: 8px;
`;

const getButtonContainer = size => numeric(size, {
  sm: SquareButtonContainer,
  other: RoundedButtonContainer,
});

const fullWidthStyle = css`
  width: 100%;
`;

/**
 * A clickable button. Can be rendered as a small square button or a
 * large rounded button. The color can be set to one of a few predefined
 * colors, and there is support for left and right icons.
 *
 * This component replaces RoundedButton.
 */
const Button = React.forwardRef((props, ref) => {
  const { version: themeVersion, theme } = useContext(ThemeContext);
  const size = sizeToString(props);
  const ButtonType = getButtonContainer(size);
  const color = props.link
    ? theme.colors.WHITE
    : props.forceColor
      ? props.color
      : getTranslatedColor(props.color, themeVersion, theme) || Button.defaultProps.color;
  const Element = props.element || Label;
  return (
    <ButtonType
      innerRef={ref}
      className={cx(
        theme.styles.buttonStyle,
        props.fullWidth ? fullWidthStyle : undefined,
        props.className,
      )}
      color={color}
      disabled={props.disabled}
      disabledColor={props.disabledColor}
      link={props.link}
      onClick={props.disabled ? null : props.onClick}
      type={props.type}
      tabIndex={props.tabIndex}
      size={size}
      {...applyTestAttribute(props)}
    >
      <Content data-testid="buttonContent" color={color} size={size} justify={props.justifyContent}>
        {props.leftIcon && <LeftIcon size={size}>{props.leftIcon}</LeftIcon>}
        <Element color={color} link={props.link}>
          {props.children}
        </Element>
        {props.rightIcon && <RightIcon size={size}>{props.rightIcon}</RightIcon>}
      </Content>
    </ButtonType>
  );
});
Button.propTypes = {
  /** Text to be used for this button */
  children: PropTypes.node.isRequired,
  /** Additional class names */
  className: PropTypes.string,
  /** Button color. Defaults to GRAY_FILL. Use Colors as options */
  color: PropTypes.string,
  /** True to disable this button, false otherwise (default false) */
  disabled: PropTypes.bool,
  /** Disabled button color. Defaults to MEDIUM_LIGHT_GRAY_FILL. Use Colors as options */
  disabledColor: PropTypes.string,
  /** A color to use for disabled, instead of standard #
  /** Override the default text element. Passes a color and link prop. */
  element: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.func]),
  /** True to ignore color translations, false otherwise (default false) */
  forceColor: PropTypes.bool,
  /** Take up the max width of the container if true; false otherwise. */
  fullWidth: PropTypes.bool,
  /**
   * How content should be justified, especially when icons are being used.
   * Defaults to space-between
   */
  justifyContent: PropTypes.string,
  /** Display an icon to the left of the button text */
  leftIcon: PropTypes.node,
  /** Click handler */
  onClick: PropTypes.func,
  /** Display an icon to the right of the butotn text */
  rightIcon: PropTypes.node,
  /** True to display a smaller button, false otherwise */
  small: PropTypes.bool, /* eslint-disable-line react/no-unused-prop-types */
  /** True to display a larger button, false otherwise */
  large: PropTypes.bool, /* eslint-disable-line react/no-unused-prop-types */
  /** Size of the button */
  size: PropTypes.oneOf(['sm', 'lg']), /* eslint-disable-line react/no-unused-prop-types */
  /** True to display button as a link (underline text, no border) */
  link: PropTypes.bool,
  /** The type of button. Defaults to "button" */
  type: PropTypes.string,
  /** A customizable tabIndex. */
  tabIndex: PropTypes.number,
  'data-testid': PropTypes.string,
};
Button.defaultProps = {
  className: undefined,
  color: GRAY_FILL,
  disabled: false,
  disabledColor: MEDIUM_LIGHT_GRAY_FILL,
  element: undefined,
  forceColor: false,
  fullWidth: false,
  justifyContent: 'space-between',
  leftIcon: null,
  onClick: undefined,
  rightIcon: null,
  small: false,
  large: false,
  size: undefined,
  link: false,
  type: 'button',
  tabIndex: undefined,
  'data-testid': undefined,
};

export default withComponentTheme(createComponentStyles)(Button);

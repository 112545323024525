import { numeric } from 'helpers/i18n';

export const confirmMessages = {
  sending: {
    accept: ({ provider_type: type, provider: { name = 'the provider' } = {}, checklist_configuration: { category } = {} }) => numeric(category, {
      doc: `You have sent a response from ${name} for this document`,
      other: numeric(type, {
        auth: `You have marked that ${name} has approved this authorization.`,
        other: `You have marked that ${name} can accept this patient.`,
      }),
    }),
    decline: ({ provider_type: type, provider: { name = 'the provider' } = {} }) => numeric(type, {
      auth: `You have marked that ${name} has denied this authorization.`,
      other: `You have marked that ${name} cannot accept this patient.`,
    }),
    mark_as_under_review: 'You have marked this referral as under review.',
    change_response_for_provider: (_, { name = 'the provider' } = {}) => `You have removed the response for ${name}`,
    cancel: 'Referral successfully cancelled.',
    revert_to_initial_sent_status: 'You have marked this referral as viewed.',
    send_to_providers: 'Referral successfully sent to providers.',
    resend_to_providers: 'Referral successfully sent to providers.',
    send_manual: 'Referral sent again to provider.',
    extend_deadline: 'Deadline successfully extended.',
    change_deadline: 'Deadline successfully changed.',
    print_patient_choice_pdf: 'Success! Your choice list has been printed.',
    forward_patient_choice_pdf: 'Success! Your choice list has been emailed.',
    forward_attachment: 'Your attachments are being sent.',
    create_outbound_document_referral: 'Success! Your attachments have been sent.',
    remove_provider: 'Provider removed from referral.',
    reserve_provider: ({ provider: { name = 'the provider' } = {} }) => `You have successfully reserved ${name}.`,
    reserve_provider_again: ({ provider: { name = 'the provider' } = {} }) => `You have successfully reserved ${name}.`,
    complete_response: 'You have marked this request as complete.',
    reprint_patient_choice_pdf: 'Success! Your choice list has been printed again.',
    discharge_now: ({ patient: { first } }) => `You have confirmed the requested start of service date for ${first}.`,
    already_discharged: ({ patient: { first } }) => `You have confirmed the requested start of service date for ${first}.`,
    discharge_later: 'Requested start of service date updated.',
    change_discharge_date: 'Requested start of service date updated.',
    update_adt_discharge: 'Discharge date updated.',
    not_discharged_yet: ({ patient: { first } }) => `${first} marked as still in your care.`,
    skip_to_drafted: 'All providers removed from referral.',
    skip_to_sending: 'Provider added back to referral',
    skip_to_sent: 'Deadline successfully extended.',
    skip_to_reviewing: 'Referral successfully closed to new responses.',
    skip_to_reserving: 'Referral successfully closed to new responses.',
    skip_to_reserved: 'Previously reserved provider has been unreserved.',
    skip_to_discharging: ({ patient: { first } }) => `You have confirmed the requested start of service date for ${first}.`,
    snooze: 'Referral has been removed from your dashboard',
    unfollow_patient: 'Patient has been unfollowed and removed from your dashboard',
    blind_referral: ({ provider_type: type } = {}) => numeric(type, {
      auth: 'Patient demographics have been hidden from payors',
      other: 'Patient demographics have been hidden from providers',
    }),
    unblind_referral: ({ provider_type: type } = {}) => numeric(type, {
      auth: 'Patient demographics have been made available to payors',
      other: 'Patient demographics have been made available to providers',
    }),
    update_patient_visit_id: 'Patient visit has been changed.',
    reopen_auth_referral: 'Referral successfully reopened',
    send_to_epic: 'Your referral details have been sent.',
    mark_contact_log_failed: 'This delivery has been cancelled.',
  },
  receiving: {
    cancel: 'Referral successfully cancelled.',
    accept: ({ provider_type: type, checklist_configuration: { category } = {} } = {}) => numeric(category, {
      doc: 'You have sent a response for this document',
      other: numeric(type, {
        auth: 'You have marked that you have approved this authorization.',
        other: 'You have marked that you have pre-approved this patient.',
      }),
    }),
    decline: ({ provider_type: type } = {}) => numeric(type, {
      auth: 'You have marked that you have denied this authorization.',
      other: 'You have marked that you cannot accept this patient.',
    }),
    mark_as_under_review: 'You have marked this referral as under review.',
    revert_to_initial_sent_status: 'You have marked this referral as viewed.',
    respond: 'Your responses have been sent.',
    skip_to_responded: 'You have removed your response.',
    change_response: 'You have removed your response.',
    patient_arriving_later: 'Intake date updated.',
    patient_never_arrived: ({ hospital: { name = 'the sender' } }) => `Your message has been sent to ${name}.`,
    patient_receive_later: 'Date for care updated.',
    patient_receive_now: ({ patient: { first } }) => `${first} received into your care.`,
    change_discharge_date: 'Discharge date updated.',
    intake: ({ patient: { first } }) => `${first} received into your care.`,
    intake_not_complete: 'Intake date updated.',
    accept_later: 'Intake date updated.',
    complete_care: ({ patient: { first } }, details) => (details ? `${first} received your care.` : undefined),
    care_not_complete: ({ patient: { first } }) => `${first} received back into your care.`,

    provider_discharge_now: ({ patient: { first } }) => `Success! ${first} marked as discharged, and referral is complete.`,
    provider_already_discharged: ({ patient: { first } }) => `Success! ${first} marked as discharged, and referral is complete.`,
    provider_discharge_later: 'Discharge date updated.',

    patient_not_coming: ({ hospital: { name = 'the sender' } }) => `Your message has been sent to ${name}.`,
    patient_not_receiving_care: ({ hospital: { name = 'the sender' } }) => `Your message has been sent to ${name}.`,
    patient_already_received_care: ({ patient: { first } }) => `${first} received into your care.`,
    patient_already_in_care: ({ patient: { first } }) => `${first} received into your care.`,
    provider_declined_services: ({ patient: { first = 'patient' } = {} } = {}) => `Services have been declined for ${first}`,
    services_starting_on_different_date: 'Date for care updated.',
    snooze: 'Referral has been removed from your dashboard',

    early_discharge: ({ patient: { first } }) => `Success! ${first} marked as discharged, and referral is complete.`,
    still_in_care: 'Discharge date updated.',
    skip_to_accepted: ({ patient: { first } }) => `${first} received into your care.`,
    skip_to_provider_discharging: ({ patient: { first } }) => `Success! ${first} marked as discharged, and referral is complete.`,
  },
};

export const undoMessages = {
  sending: {
    cancel: 'Undo cancel',
    send_to_providers: 'Undo send',
    extend_deadline: 'skip_to_reviewing',
    reserve_provider: 'skip_to_reserving',
    discharge_now: 'TODO: skip_to_reserved',
    already_discharged: 'TODO: discharge_now',
    discharge_later: 'Set to previous discharge date',
    not_discharged_yet: 'TODO: discharge_now',
    skip_to_drafted: 'Add providers back',
    skip_to_sent: 'skip_to_reviewing',
    skip_to_reviewing: 'skip_to_sent',
    skip_to_reserving: 'skip_to_sent',
    skip_to_reserved: 'Keep provider reserved',
    skip_to_discharging: 'Change discharge date back to previous one',
    skip_to_discharged: 'Change discharge date back to previous one',
  },
  receiving: {
    accept: 'Change Response',
    decline: 'Change Response',
    cancel: 'Undo cancel',
    discharge_later: 'Set to previous intake date',
    complete_care: 'Undo complete_care',
    patient_never_arrived: 'Undo cancel',
    patient_not_coming: 'Undo cancel',
    patient_not_receiving_care: 'Undo cancel',
    patient_receive_later: 'Set to previous intake date',
    patient_already_received_care: 'Set to previous intake date',
    patient_receive_now: 'Set to previous intake date',
    intake: 'Set to not yet intaken',
    provider_discharge_now: 'Reopen & mark as not yet discharged',
    provider_discharge_later: 'Set to previous discharge date',
    early_discharge: 'Reopen & mark as not yet discharged',
    still_in_care: 'Set to previous discharge date',
    skip_to_accepted: 'Set to previous intake date',
    skip_to_provider_discharging: 'Reopen & set to previous discharge date',
  },
};

export default { confirmMessages };

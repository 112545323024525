import React from 'react';

export const PAGE_CONTENT_WIDTH_V1 = 940;
export const PAGE_CONTENT_WIDTH_V1_PX = `${PAGE_CONTENT_WIDTH_V1}px`;
export const PAGE_CONTENT_WIDTH_V2 = 1240;
export const PAGE_CONTENT_WIDTH_V2_PX = `${PAGE_CONTENT_WIDTH_V2}px`;

/**
 * Context to track page sizing and other things that
 * occur in PageContent. This is mainly to get the content
 * width for the V2 UI in order to ensure the menu bar is
 * sized correctly when the screen uses a full-width container.
 */
const PageContext = React.createContext({
  contentWidth: null,
});

export default PageContext;

import React from 'react';
import PropTypes from 'prop-types';
import { getDisplayName, getContactInformation } from 'models/Provider';
import Panel from 'components/utils/Panel';
import * as styles from 'components/utils/Styles';
import { css } from 'emotion';

const { Secondary, DropDownItem } = styles;

/**
 * Need to unset height to align LOC correctly
 * Does not have impact in other locations
 * where previously implemented
*/
const unsetStyle = css`
  line-height: unset;
`;

export const renderProviderFaxOrPhone = (provider) => {
  const value = getContactInformation(provider);
  if (value) {
    return <Secondary>{value}</Secondary>;
  }
  return null;
};

export const providerListItemFullWidthLocation = (provider) => `${provider.address}, ${provider.location} ${provider.zip}`;
export const providerListItemFullWidthLabel = (provider) => `${provider.name} ${provider.provider_type.toUpperCase()} ${provider.address}, ${provider.location} ${provider.zip}`;

/* bold option does not call ProviderListItem to prevent level of care in Secondary format */
export const renderProviderItemWithAddons = (
  provider,
  options = { location: false, phone: false, bold: false },
) => (
  <Panel orientation="vertical" spacing={1}>
    {options.bold
      ? (
        <DropDownItem>
          <div><h4>{getDisplayName(provider)}</h4></div>
          <div><h4>{provider.provider_type && provider.provider_type.toUpperCase()}</h4></div>
        </DropDownItem>
      ) : (
        <ProviderListItem
          key={provider.id}
          name={getDisplayName(provider)}
          levelOfCare={provider.provider_type && provider.provider_type.toUpperCase()}
        />
      )}
    {options.location && provider.location && <Secondary>{provider.location}</Secondary>}
    {options.phone && renderProviderFaxOrPhone(provider)}
  </Panel>
);

/**
 * Display a provider's name and level of care in
 * a SelectField or AutocompleteField, with the
 * level of care to the right in Secondary format.
 */
const ProviderListItem = ({
  name,
  levelOfCare,
  unset,
}) => (
  <DropDownItem>
    <div>{name}</div>
    <Secondary className={unset && unsetStyle}>
      {levelOfCare}
    </Secondary>
  </DropDownItem>
);

ProviderListItem.propTypes = {
  /** The provider name */
  name: PropTypes.string.isRequired,
  /** The level of care */
  levelOfCare: PropTypes.string.isRequired,
  /** Unset Line Height (helps with verital alignment) */
  unset: PropTypes.bool,
};

ProviderListItem.defaultProps = {
  unset: false,
};

export default ProviderListItem;

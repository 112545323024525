import initialState from 'store/initialState';
import * as types from './types';

const ui = (state = initialState.ui, { type, ...entity }) => {
  switch (type) {
    /**
     * A merge will be performed when the current open state matches
     * the requested open state. A replace will happen otherwise.
     */
    case types.TOGGLE_GENERIC_MODAL: {
      const { open, globalModalReduxKey, ...params } = entity;
      const currentOpenState = state[globalModalReduxKey]?.open;
      return {
        ...state,
        [globalModalReduxKey]: currentOpenState === open
          ? { ...state[globalModalReduxKey], ...params }
          : { open, ...params },
      };
    }
    case types.TOGGLE_PROVIDER_SEARCH_CONTACT_INFO_MODAL:
      return {
        ...state,
        providerSearchContactInfoModal: {
          open: entity.open,
          ...entity.props,
        },
      };
    case types.TOGGLE_PROVIDER_PREFERENCES_MODAL:
      return {
        ...state,
        providerPreferencesModal: {
          open: entity.open,
          preferences: entity.preferences,
          referralId: entity.referralId,
        },
      };
    case types.TOGGLE_PROVIDER_MISSING_BADGE_MODAL:
      return {
        ...state,
        providerMissingBadgeModal: {
          open: entity.open,
          preferences: entity.preferences,
          referralId: entity.referralId,
          hospitalId: entity.hospitalId,
        },
      };
    case types.TOGGLE_NEW_PROVIDER_MODAL:
      return {
        ...state,
        newProviderModal: {
          open: entity.open,
          preferences: entity.preferences,
        },
      };
    case types.TOGGLE_PASSWORD_CHANGE_MODAL:
      return {
        ...state,
        passwordChangeModal: {
          open: entity.open,
        },
      };
    case types.TOGGLE_FEDRAMP_MODAL:
      return {
        ...state,
        fedrampModal: {
          open: entity.open,
        },
      };
    case types.TOGGLE_BADGES_CHECKUP_MODAL:
      return {
        ...state,
        badgesCheckupModal: {
          open: entity.open,
          providers: entity.providers,
        },
      };
    case types.TOGGLE_IDENTITY_VERIFICATION_MODAL:
      return {
        ...state,
        identityVerificationModal: {
          open: entity.open,
          refresh: entity.refresh,
          options: entity.open ? {
            showWelcome: true, // Default to showing welcome
            ...entity.options,
          } : undefined,
        },
      };
    case types.TOGGLE_YEAR_END_REPORT_MODAL:
      return {
        ...state,
        yearEndReportModal: {
          open: entity.open,
        },
      };
    case types.UPDATE_PURCHASE_MODAL: {
      return {
        ...state,
        purchaseModal: {
          organization: entity.organization,
        },
      };
    }
    case types.TOGGLE_PDPMCALCULATOR_MODAL: {
      return {
        ...state,
        pdpmCalculatorModal: {
          open: entity.open,
        },
      };
    }
    case types.TOGGLE_NEW_DOCUMENT_MODAL: {
      return {
        ...state,
        newOutboundDocumentModal: {
          open: entity.open,
          organization: entity.open ? entity.organization : null,
          document: entity.document ? entity.document : null,
        },
      };
    }
    case types.TOGGLE_CREATE_REFERRAL_CONFLICT_MODAL: {
      const props = entity.open ? entity : {};
      return {
        ...state,
        createReferralConflictModal: {
          open: entity.open,
          ...props,
        },
      };
    }
    case types.TOGGLE_UPDATE_REFERRAL_CONFLICT_MODAL: {
      const props = entity.open ? entity : {};
      return {
        ...state,
        updateReferralConflictModal: {
          open: entity.open,
          ...props,
        },
      };
    }
    case types.TOGGLE_SOFTWARE_UPDATE_MODAL: {
      return {
        ...state,
        softwareUpdate: {
          open: entity.open,
          permanent: entity.permanent,
          required: entity.required,
          version: entity.version,
        },
      };
    }
    case types.TOGGLE_SEND_TO_PROVIDERS_MODAL: {
      const props = entity.open ? entity : { open: false };
      return {
        ...state,
        sendToProviders: {
          open: entity.open,
          ...props,
        },
      };
    }
    case types.TOGGLE_RECEIVER_PURCHASEABLE_MODAL:
      return {
        ...state,
        receiverPurchaseableModal: {
          open: entity.open,
        },
      };
    case types.TOGGLE_ADVANCED_MESSAGE_SEARCH_MODAL:
      return {
        ...state,
        advancedMessageSearchModal: {
          onSubmit: entity.onSubmit,
          open: entity.open,
        },
      };
    case types.TOGGLE_ATTACHMENT_VIEWER: {
      const props = entity.open ? entity : { open: false };
      return {
        ...state,
        attachmentViewer: {
          open: entity.open,
          ...props,
        },
      };
    }
    case types.TOGGLE_MESSAGE_MULTIPLE_PROVIDERS_MODAL: {
      const props = entity.open ? entity : { open: false };
      return {
        ...state,
        messageMultipleProvidersModal: {
          open: entity.open,
          ...props,
        },
      };
    }
    case types.TOGGLE_DOCUMENT_WARNING_MODAL: {
      const props = entity.open ? entity : { open: false };
      return {
        ...state,
        documentWarningModal: {
          open: entity.open,
          ...props,
        },
      };
    }
    case types.TOGGLE_ATTACH_UNASSIGNED_DOCUMENTS_TO_PATIENT_MODAL: {
      const props = entity.open ? entity : { open: false };
      return {
        ...state,
        attachUnassignedDocumentsToPatientModal: {
          open: entity.open,
          ...props,
        },
      };
    }
    default:
      return state;
  }
};

export default {
  ui,
};

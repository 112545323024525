import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'react-emotion';
import Session from 'models/Session';

import VerifiedAdminStatusIconComponent from 'components/admin/VerifiedAdminStatusIconComponent';

const IconWrapper = styled.div`
  display: inline-block;
`;

const VerifiedAdminStatusIcon = (props) => {
  const { user } = props.session;
  return (
    <IconWrapper className={props.className}>
      <VerifiedAdminStatusIconComponent user={user} />
    </IconWrapper>
  );
};

VerifiedAdminStatusIcon.propTypes = {
  session: Session.isRequired,
  className: PropTypes.string,
};

VerifiedAdminStatusIcon.defaultProps = {
  className: undefined,
};

const connectedState = state => ({
  session: state.session,
});

export default connect(connectedState)(VerifiedAdminStatusIcon);

/* eslint-disable camelcase */

import { replacePreferences } from 'ducks/session/actions';
import apiDirector from 'helpers/ApiDirector';
import * as actions from '../actions';

const parseDocumentStatus = () => 'unsent';

const shapeDocument = document => ({
  ...document,
  name: document.name || document.full_name || document.file_file_name,
  archived: !!(document.meta || {}).archived_at,
  status: parseDocumentStatus(document),
});

const shapeDocuments = documents => documents.map(shapeDocument);

export const requestDocuments = params => dispatch => apiDirector.validateFetch(`/api/inbox/library${params ? `?${params}` : ''}`)
  .then(documentsInfo => ({
    ...documentsInfo,
    content: shapeDocuments(documentsInfo.content),
  }))
  .then(documentsInfo => dispatch(actions.replaceDocuments(documentsInfo, 'library')))
  .then(({ documentsInfo: { aidin_inbox } }) => {
    dispatch(replacePreferences({ aidin_inbox }, true));

    return aidin_inbox;
  });

// eslint-disable-next-line camelcase
export const archiveDocument = (documentId, archived_at, source = 'library') => dispatch => apiDirector.validateFetch(`/api/inbox/${source}/${documentId}`, {
  method: 'PATCH',
  body: JSON.stringify({
    document: {
      meta: {
        archived_at,
      },
    },
  }),
})
  .then(shapeDocument)
  .then(document => dispatch(actions.removeDocument(document, source)));

export const assignAttachableToDocument = (documentId, attachable, source = 'library') => dispatch => apiDirector.validateFetch(`/api/inbox/library/${documentId}`, {
  method: 'PATCH',
  body: JSON.stringify({
    document: {
      ...attachable,
    },
  }),
})
  .then(shapeDocument)
  .then(document => dispatch(actions.replaceDocument(document, source)));

export const renameDocument = (documentId, name, source = 'library') => dispatch => apiDirector.validateFetch(`/api/inbox/library/${documentId}`, {
  method: 'PATCH',
  body: JSON.stringify({
    document: {
      name,
    },
  }),
})
  .then(shapeDocument)
  .then(document => dispatch(actions.replaceDocument(document, source)));


/**
 * Immediately send an uploaded file via fax to someone.
 *
 * The meta prop is expected to contain (optionally):
 *  - to_name
 *  - to_number
 *  - from_name
 *  - from_number
 *  - notes
 *
 * The options can include:
 *  - attachable (id/type), such as a patient or referral. Default to organization
 *  - saveProvider - true if updating the provider fax number, or adding a new provider
 *
 * Note that if a to_number is specified in meta, this will trigger the send fax behavior.
 */
export const uploadDocument = (organization, files, meta, opts = {}) => (dispatch, getState) => {
  if (!files || files.length === 0) {
    return Promise.resolve(true);
  }

  const {
    session: {
      user: {
        name,
      },
    },
  } = getState();

  const options = {
    attachable_id: organization.id,
    attachable_type: organization.organization_type,
    ...opts,
  };

  const form = new FormData();
  files.forEach((file) => { form.append('files[]', file); });
  if (options.fileNames) {
    form.append('fileNames', JSON.stringify(options.fileNames));
  }
  if (options.saveProvider && options.provider) {
    form.append('saveProvider', options.saveProvider);
    Object.entries(options.provider).forEach(([key, value]) => {
      form.append(`provider[${key}]`, value);
    });
  }
  form.append('saveMode', 'chunked');
  form.append('attachable_id', options.attachable_id);
  form.append('attachable_type', options.attachable_type);
  form.append('organization_id', organization.id);
  form.append('organization_type', organization.organization_type);
  form.append('attachment[meta]', JSON.stringify({
    source: 'aidin_inbox',
    from_name: name,
    ...meta,
  }));

  return apiDirector.validateFetch('/api/attachments', {
    method: 'POST',
    body: form,
  }, {
    Accept: 'application/json',
  }).then(() => dispatch(requestDocuments()));
};

export const updateCurrentViewer = (documentId, userId) => () => apiDirector.validateFetch(`/api/inbox/inbound/${documentId}/assign_viewer`, {
  method: 'PATCH',
  body: JSON.stringify({
    user_id: userId,
  }),
});

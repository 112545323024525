/* eslint-disable react/jsx-props-no-spreading, react/prop-types */

import React from 'react';
import { css, cx } from 'emotion';
import { MEDIUM_DARK_GRAY_FILL } from 'components/utils/Colors';
import { clickableStyle } from 'components/utils/Styles';
import Icon from 'components/utils/Icon';

/** Standard name/style for a context menu icon */
export const ContextMenuIconStyle = 'mp-menu';

/** Standard color for this context menu icon */
export const ContextMenuIconColor = MEDIUM_DARK_GRAY_FILL;

/** Appropriate size for this icon, slightly bigger than the standard */
export const ContextMenuIconSize = 25;

const lightStyle = css`
  opacity: 0.5;
`;

/**
 * A referenceable icon used for a context menu. All props
 * given will pass through except the name. If you need a
 * different icon type, use a custom Icon!
 */
const ContextMenuIcon = React.forwardRef(({ className, light, ...passThrough }, ref) => (
  <Icon
    ref={ref}
    data-testid="context-menu"
    name={ContextMenuIconStyle}
    {...passThrough}
    className={cx(clickableStyle, light && lightStyle, className)}
    size={ContextMenuIconSize}
    color={ContextMenuIconColor}
  />
));

export default ContextMenuIcon;

/* eslint-disable react/no-array-index-key */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion';
import { cx } from 'emotion';

import { detectIE } from 'components/utils/IE';
import { applyTestAttribute } from 'helpers/development';

const flexTranslation = {
  top: 'flex-start',
  left: 'flex-start',
  center: 'center',
  right: 'flex-end',
  bottom: 'flex-end',
};

const spaceTranslation = {
  evenly: `space-${detectIE() ? 'around' : 'evenly'}`,
  around: 'space-around',
  between: 'space-between',
};

const Container = styled.div`
  width: ${props => (props.fullWidth ? '100%' : 'auto')};
  display: flex;
  justify-content: ${props => spaceTranslation[props.spacing] || props.spacing || 'space-between'};
  ${props => props.align && `align-items: ${flexTranslation[props.align] || props.align};`}
  ${props => props.wrap === 'wrap' && 'flex-wrap: wrap;'}
`;

const Item = styled.div`
  ${props => props.basis && `flex-basis: ${props.basis};`}
  ${props => props.fill && 'flex: 1;'}
  ${props => props.itemWidth && `width:${props.itemWidth}%;`};
`;

/**
 * Render children in a justified panel with equivalent spacing between them.
 *
 * Defaults to being a horizontal panel with items rendered with space between them.
 */
const SplitPanel = props => (!props.children ? null : (
  <Container
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...applyTestAttribute(props)}
    id={props.id}
    align={props.align}
    spacing={props.spacing}
    className={props.className}
    onClick={props.onClick}
    fullWidth={props.fullWidth}
    wrap={props.wrap ? 'wrap' : null}
  >
    {React.Children
      .map(props.children, child => child)
      .filter(child => !!child)
      .map((child, index, list) => (
        <Item
          itemWidth={props.itemWidth}
          key={index}
          basis={props.basis}
          fill={props.fillColumnIndex === index || list.length === 1}
          className={cx(
            typeof props.itemClassName === 'function' ? props.itemClassName(index) : props.itemClassName,
            `split-panel-item-${index}`,
          )}
        >
          {child}
        </Item>
      ))}
  </Container>
));
SplitPanel.propTypes = {
  align: PropTypes.oneOf(['top', 'left', 'center', 'right', 'bottom', 'baseline']),
  /** Apply consistent flex-basis sizing to each item in the panel */
  basis: PropTypes.string,
  /** Panels in the container */
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.element, PropTypes.node]),
  /** Optional class name for container */
  className: PropTypes.string,
  /** Fill this column via flex: 1 */
  fillColumnIndex: PropTypes.number,
  /** True to stretch container full width, false otherwise (default false) */
  fullWidth: PropTypes.bool,
  /** HTML id */
  id: PropTypes.string,
  /**
   * Optional class name for each item in container.
   * If given a function, index is passed, and a string is expected
   */
  itemClassName: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  /** Click handler for entire panel */
  onClick: PropTypes.func,
  /** Spacing type between each item. Use a keyword or any proper flex justfy-content value */
  spacing: PropTypes.oneOfType([
    PropTypes.oneOf(['evenly', 'around', 'between']),
    PropTypes.string,
  ]),
  /** Wrap items */
  wrap: PropTypes.bool,
  /** Item Width */
  itemWidth: PropTypes.number,
  /**
   * Integration Testing Id
   */
  'data-testid': PropTypes.string,
};
SplitPanel.defaultProps = {
  align: undefined,
  basis: undefined,
  children: undefined,
  className: undefined,
  fillColumnIndex: undefined,
  fullWidth: undefined,
  itemClassName: undefined,
  onClick: undefined,
  spacing: 'space-between',
  id: undefined,
  wrap: undefined,
  itemWidth: undefined,
  'data-testid': undefined,
};

export default SplitPanel;

import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'emotion';
import styled from 'react-emotion';

const clickableStyle = css`
  cursor: pointer;
`;

const iconDefaults = {
  color: '#c8c8c8',
  size: 20,
};

const IconContainer = styled.div`
  display: inline-block;
  font-size: ${props => props.size || iconDefaults.size}px;
  line-height: 0.7em;
  color: ${props => props.color || iconDefaults.color};
`;

/**
 * Renders an icon given the name of the icon as described
 * on the icon library page at /docs/icons.
 */
const Icon = React.forwardRef((props, ref) => {
  const {
    className, clickable, name, size, color, onClick, ...other
  } = props;
  const composedClassName = [
    (onClick || clickable) ? clickableStyle : null,
    className || null,
    name.indexOf('mp-') === 0 ? name : `mp-${name}`,
  ].filter(item => !!item).join(' ');
  return (
    <IconContainer
      role={clickable ? 'button' : undefined}
      innerRef={ref}
      className={composedClassName}
      size={size}
      color={color}
      onClick={onClick}
      {...other}
    />
  );
});
Icon.propTypes = {
  /** Optional class name for additional custom styling */
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /** The color of the icon */
  color: PropTypes.string,
  /** If the icon should show a pointer */
  clickable: PropTypes.bool,
  /** The icon name, i.e. "mp-gear". */
  name: PropTypes.string.isRequired,
  /** Optional click handler */
  onClick: PropTypes.func,
  /** The font size for the icon. Defaults to standard size for app. */
  size: PropTypes.number,
};
Icon.defaultProps = {
  ...iconDefaults,
  className: null,
  clickable: undefined,
  onClick: undefined,
};

export default Icon;

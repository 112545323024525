import initialState from 'store/initialState';
import { findIndex, some } from 'lodash';
import * as types from './types';

const removeTeamMember = (state, { memberId }) => {
  const index = findIndex(state, ['id', memberId]);
  const copy = state.slice();
  copy.splice(index, 1);
  return copy;
};

const checkPrefsForType = (prefs, type) => (
  some(prefs, notificationActions => notificationActions[type] && notificationActions[type] !== 'false')
);

export const teamMemberMapper = member => ({
  ...member,
  locations: (member.user_organizations || [])
    .filter(({ authorized }) => authorized)
    .map(({ id: membershipId, organization, roles }) => ({
      ...organization,
      // If a user has a location, then we can assume checked is true
      // This prevents the TeamLocations from having to propograte this mutation
      // back up to ManageTeam.
      checked: true,
      membershipId,
      roles,
    })),
  // Todo: retrieve correct locations count from backend (filter based on above criteria)
  // counts are different on hover
  locationsCount: member.user_organizations_count,
  locationsRoles: member.user_organizations_roles,
  notify_by_text: checkPrefsForType(member.prefs?.notify || {}, 'sms'),
  notify_by_email: checkPrefsForType(member.prefs?.notify || {}, 'email'),
  notify_by_fax: checkPrefsForType(member.prefs?.notify || {}, 'fax'),
});

const replaceTeam = (_, { team }) => {
  const mapped = (team || []).map(teamMemberMapper);
  return [
    ...mapped,
  ];
};

const addTeamMember = (state, { teamMember }) => ([
  ...state,
  teamMemberMapper(teamMember),
]);

const updateTeamMember = (state, { teamMember }) => {
  const index = findIndex(state, ['id', teamMember.id]);
  // Index wasn't found. This happens when we invite an existing user.
  if (index < 0) {
    return addTeamMember(state, { teamMember });
  }
  const copy = state.slice();
  copy.splice(index, 1, teamMemberMapper(teamMember));
  return copy;
};

const updateTeamMemberContent = (state, { memberId, content }) => {
  const index = findIndex(state, ['id', memberId]);
  const teamMember = {
    ...(state[index]),
    user_organizations: content.user_organizations,
  };
  const copy = state.slice();
  copy.splice(index, 1, teamMemberMapper(teamMember));
  return copy;
};

const team = (state = initialState.admin.team, action) => {
  const { type, ...entity } = action;

  switch (type) {
    case types.REPLACE_TEAM:
      return replaceTeam(state, entity);
    case types.ADD_TEAM_MEMBER:
      return addTeamMember(state, entity);
    case types.REMOVE_TEAM_MEMBER:
      return removeTeamMember(state, entity);
    case types.UPDATE_TEAM_MEMBER:
      return updateTeamMember(state, entity);
    case types.UPDATE_TEAM_MEMBER_CONTENT:
      return updateTeamMemberContent(state, entity);
    default:
      return state;
  }
};

export default { team };

import * as types from './types';

export const replaceMessages = messages => dispatch => Promise.resolve(dispatch({
  type: types.REPLACE_MESSAGE_LIST,
  ...messages,
  initialized: true,
}));

export const replaceMessageThread = (id, thread) => dispatch => Promise.resolve(dispatch({
  type: types.REPLACE_CURRENT_THREAD,
  id,
  thread,
}));

export const closeMessageThread = () => dispatch => Promise.resolve(dispatch({
  type: types.CLOSE_CURRENT_THREAD,
}));

export const toggleNewMessageThread = (open = true) => dispatch => Promise.resolve(dispatch({
  type: types.TOGGLE_NEW_MESSAGE_THREAD,
  open,
}));

export const appendToInbox = messages => dispatch => Promise.resolve(dispatch({
  type: types.APPEND_INBOX_LIST,
  ...messages,
}));

export const replaceInbox = messages => dispatch => Promise.resolve(dispatch({
  type: types.REPLACE_INBOX_LIST,
  ...messages,
}));

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion';

import { errorToast } from 'helpers/ui';
import { createTranslator, pluralize } from 'helpers/i18n';
import { providerTypes } from 'translations/providers';
import Provider from 'models/Provider';
import { applyTestIdentifier } from 'helpers/development';

import Button from 'components/utils/Button';
import Spinner from 'components/utils/Spinner';
import Icon from 'components/utils/Icon';
import Panel from 'components/utils/Panel';
import SplitPanel from 'components/utils/SplitPanel';
import * as Styles from 'components/utils/Styles';
import * as Colors from 'components/utils/Colors';

import OrganizationUserListPopup from 'components/referrals/search/OrganizationUserListPopup';

const { CONFIRMATION_FILL, LIGHTEST_GRAY_FILL, GRAY_FILL } = Colors;

const { LinkText, Secondary } = Styles;

const SecondaryBody = styled(Secondary)`
  font-size: inherit;
`;

const tr = createTranslator({
  distance: miles => `${parseInt(miles.toFixed(), 10).toLocaleString()}mi away`,
  users: count => `${count} Aidin ${pluralize(count, 'user')}`,
  providerType: type => `${providerTypes[type.toUpperCase()]} (${type.toUpperCase()})`,
});

const Container = styled.div`
  background-color: ${LIGHTEST_GRAY_FILL};
  padding: 10px;
`;

const Confirmation = styled.div`
  font-weight: bold;
  color: ${CONFIRMATION_FILL};
`;

/**
 * Display for a generic provider search result.
 */
class GenericProviderSearchResult extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      /** Control the loading spinner */
      saving: false,
      /** Show this record as saved if the server confirms. Do this to prevent update flash */
      saved: false,
    };
  }

  handleActionClick = (provider) => {
    const { onActionClick, actionErrorText } = this.props;
    if (onActionClick) {
      this.setState({ saving: true }, () => {
        onActionClick(provider.id, provider.name, provider)
          .then(({ update = true } = {}) => {
            if (update) {
              this.setState({ saved: true, saving: false });
            }
          }).catch(() => {
            this.setState({ saving: false }, () => {
              if (actionErrorText) {
                errorToast(actionErrorText);
              }
            });
          });
      });
    }
  }

  canShowAction = () => {
    const { showAction } = this.props;

    if (showAction) {
      if (typeof showAction === 'function') {
        return showAction();
      }
      return showAction;
    }

    return false;
  }

  render() {
    const {
      actionButtonText,
      actionButtonColor,
      actionCompletedText,
      actionComplete,
      disableMemberSearch,
      provider,
      showProviderType,
    } = this.props;
    const { saved, saving } = this.state;
    const canShowAction = this.canShowAction();
    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <Container {...applyTestIdentifier(`generic-provider-search-result-${provider.id}`)}>
        <Panel spacing={8}>
          <Panel spacing={0}>
            <h5>{provider.name}</h5>
            {showProviderType && provider.provider_type && <SecondaryBody>{tr('providerType', provider.provider_type)}</SecondaryBody>}
            {provider.address}
            {provider.city_state_zip}
          </Panel>
          <SplitPanel>
            <Secondary>
              { (provider.distance !== undefined && provider.distance !== null)
                && tr('distance', provider.distance)}
            </Secondary>
            {(provider.member_count === 0 || disableMemberSearch)
              ? <Secondary>{tr('users', provider.member_count)}</Secondary>
              : (
                <OrganizationUserListPopup
                  organization={provider}
                  offsetX={16}
                  offsetY={16}
                  position="bottom left"
                  trigger={(
                    <LinkText>
                      {tr('users', provider.member_count)}
                    </LinkText>
                  )}
                />
              )}
          </SplitPanel>
          {saving && <Spinner color={GRAY_FILL} />}
          {(saved || (!saving && actionComplete)) && (
            <Panel orientation="horizontal" align="center" spacing={8}>
              <Icon name="mp-check" color={CONFIRMATION_FILL} />
              <Confirmation>{actionCompletedText}</Confirmation>
            </Panel>
          )}
          {!saved && !saving && canShowAction && !actionComplete && (
            <Button
              small
              color={actionButtonColor}
              onClick={() => this.handleActionClick(provider)}
            >
              {actionButtonText}
            </Button>
          )}
        </Panel>
      </Container>
    );
  }
}

GenericProviderSearchResult.propTypes = {
  /** Text for the action button */
  actionButtonText: PropTypes.string,
  /** Color for the action button */
  actionButtonColor: PropTypes.string,
  /** True if action is complete, false otherwise */
  actionComplete: PropTypes.bool,
  /** Confirmation text */
  actionCompletedText: PropTypes.string,
  /** Error text */
  actionErrorText: PropTypes.string,
  /** True to disable member search, false otherwise */
  disableMemberSearch: PropTypes.bool,
  /** Handle action button click */
  onActionClick: PropTypes.func,
  /** The provider */
  provider: Provider.isRequired,
  /** True to show the action buttons (or a func that return true), false otherwise */
  showAction: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  /** True to show the provider type, false otherwise */
  showProviderType: PropTypes.bool,
};

GenericProviderSearchResult.defaultProps = {
  actionComplete: undefined,
  actionButtonText: undefined,
  actionButtonColor: undefined,
  actionCompletedText: undefined,
  actionErrorText: undefined,
  disableMemberSearch: false,
  showAction: undefined,
  showProviderType: true,
  onActionClick: undefined,
};

export default GenericProviderSearchResult;

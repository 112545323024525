/* eslint-disable camelcase */

import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'emotion';
import styled from 'react-emotion';
import { createTranslator } from 'helpers/i18n';

import avatar from 'components/branding/avatar@2x.png';

import Button from 'components/utils/Button';
import Icon from 'components/utils/Icon';
import FileDropZone from 'components/utils/FileDropZone';
import Field from 'components/utils/Field';
import Panel from 'components/utils/Panel';
import * as colors from 'components/utils/Colors';
import ViewContext from 'components/utils/ViewContext';

const { MEDIUM_GRAY_FILL, TRANSPARENT, NOTIFICATION_FILL } = colors;

const tr = createTranslator({
  alt: 'Avatar',
  upload: 'Change Avatar',
});

const AvatarIcon = styled.div`
  margin-top: 9px;
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  border-radius: 100%;
  overflow: hidden;
  background-color: ${props => (props.exists ? TRANSPARENT : MEDIUM_GRAY_FILL)};
  background-image: url("${props => props.src}");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;

const removeIconStyle = css`
  position: absolute;
  top: 0px;
  right: 0px;
`;

const relativeStyle = css`
  position: relative;
`;

/**
 * Display and allow uploading of user and organization avatars.
 *
 * You can lazily pass your user or organization as long as they have a
 * name and avatar_url:
 *
 * `<Avatar source={user} />`
 *
 * Or specify details:
 *
 * `<Avatar source={{ avatar_url: '', name: '' }} />`
 */
class Avatar extends React.Component {
  static propTypes = {
    /** True to allow deleting the avatar, false otherwise */
    allowRemove: PropTypes.bool,
    /** True to allow upload, false for display-only */
    allowUpload: PropTypes.bool,
    /** Callback for when an avatar should be deleted */
    onRemove: PropTypes.func,
    /** Callback for when a file should be uploaded */
    onUpload: PropTypes.func,
    /** The size of the avatar, in pixels. Defaults to 120 */
    size: PropTypes.number,
    /** Data source for the avatar, like a user or organization. */
    source: PropTypes.shape({
      /** URL for the avatar */
      avatar_url: PropTypes.string,
      /** Source name, used for alt text on the image */
      name: PropTypes.string,
    }),
    /** Set the upload button style */
    uploadButtonColor: PropTypes.oneOf([NOTIFICATION_FILL]),
    /** Set the avatar and upload button position inside avatar container */
    align: PropTypes.string,
  }

  static defaultProps = {
    allowRemove: false,
    allowUpload: false,
    onRemove: undefined,
    onUpload: undefined,
    size: 120,
    source: undefined,
    uploadButtonColor: undefined,
    align: 'center',
  }

  handleRequestUpload = () => {
    if (this.fileUpload) {
      this.fileUpload.open();
    }
  }

  handleRemove = () => {
    if (this.props.onRemove) {
      this.props.onRemove(this.props.source);
    }
  }

  render() {
    const {
      allowRemove,
      allowUpload,
      onUpload,
      size,
      source: {
        avatar_url,
        name = tr('alt'),
      } = {},
      uploadButtonColor,
    } = this.props;

    return (
      <ViewContext.Consumer>
        {({ readOnly }) => (
          <FileDropZone
            className={relativeStyle}
            ref={(ref) => { this.fileUpload = ref; }}
            disabled={readOnly || !allowUpload}
            onFileUploaded={onUpload}
          >
            <Panel align={this.props.align}>
              <AvatarIcon
                title={name}
                size={size}
                exists={!!(avatar_url || avatar)}
                src={avatar_url || avatar}
              />
              {allowUpload && !readOnly && (
                <Button color={uploadButtonColor} small onClick={this.handleRequestUpload}>{tr('upload')}</Button>
              )}
              {allowRemove && !readOnly && avatar_url && (
                <Icon className={removeIconStyle} name="cancel" onClick={this.handleRemove} />
              )}
            </Panel>
          </FileDropZone>
        )}
      </ViewContext.Consumer>
    );
  }
}

export default Field(Avatar);

import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'emotion';
import styled from 'react-emotion';

import HOC from 'components/utils/Field';

const RadioLabel = styled.label`
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

const RadioContent = styled.div`
  margin-top: 10px;
  margin-left: 27px;
`;

const radioStyle = disabled => css`
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: ${disabled ? 'not-allowed' : 'pointer'};
`;

const baseRadioButtonStyle = css`
  height: 17px;
  width: 17px;
  background-color: #ffffff;
  border-radius: 9.5px;
  box-shadow: inset 0 0.5px 3px 0 rgba(102, 102, 102, 0.57);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const RadioCheck = styled.div`
  height: 7px;
  width: 7px;
  border-radius: 8.5px;
  background-color: #57b56c;
`;

const disabledStyle = css`
  background-color: #dddddd !important;
  cursor: not-allowed !important;
`;

const LabelText = styled.span`
  min-height: 21px;
  line-height: 21px;
  color: #333333;
  font-size: 15px;
`;

const RadioContainer = styled.div`
  display: flex;
  flex-direction: ${props => (props.orientation === 'horizontal' ? 'row' : 'column')};
  flex-wrap: wrap;
`;

const RadioItem = styled.div`
  margin-bottom: ${props => (props.isLastItem ? 0 : 10)}px;
  ${props => props.orientation === 'horizontal' && 'margin-right: 20px;'}
`;

const RadioButton = styled.div`
  margin-right: 10px;
`;

/**
 * A group of radio buttons
 */
class RadioField extends React.Component {
  static propTypes = {
    /** Optional classname for container */
    className: PropTypes.string, // eslint-disable-line react/no-unused-prop-types
    /** Render content under the radio item label. */
    contentRenderer: PropTypes.func,
    /** True to disable, false otherwise (default false) */
    disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    /** Optional array of error messages */
    errors: PropTypes.arrayOf(PropTypes.node), // eslint-disable-line react/no-unused-prop-types
    /** Optional function for custom item rendering */
    itemRenderer: PropTypes.func,
    /** Optional classname for radio item container */
    itemClassName: PropTypes.string,
    items: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.func]),
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      })),
    ]).isRequired,
    /** Optional label to display over the field. Can be a string or node */
    label: PropTypes.node, // eslint-disable-line react/no-unused-prop-types
    /** Optional class name to add to the label text */
    labelClassName: PropTypes.string, // eslint-disable-line react/no-unused-prop-types
    /** Radio group name */
    name: PropTypes.string,
    /** Callback when an item is selected */
    onSelect: PropTypes.func.isRequired,
    /** Vertical or horizontal orientation */
    orientation: PropTypes.oneOf(['vertical', 'horizontal']),
    /** True if required field, false otherwise */
    required: PropTypes.bool, // eslint-disable-line react/no-unused-prop-types
    /** The pre-selected value */
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }

  static defaultProps = {
    className: undefined,
    contentRenderer: undefined,
    disabled: undefined,
    errors: undefined,
    itemClassName: undefined,
    itemRenderer: undefined,
    label: undefined,
    labelClassName: undefined,
    name: undefined,
    orientation: 'vertical',
    required: false,
    value: null,
  }

  handleChange = (e) => {
    const selected = this.props.items.find(item => e.target.value === `${(item.value || item)}`);
    this.props.onSelect(selected);
  }

  /* eslint-disable-next-line no-nested-ternary */
  isItemDisabled = item => (this.props.disabled
    ? typeof this.props.disabled === 'function'
      ? this.props.disabled(item)
      : this.props.disabled
    : false);

  renderItem = item => (this.props.itemRenderer ? this.props.itemRenderer(item) : (
    <LabelText>{item.label || item}</LabelText>
  ));

  renderContent = (item) => {
    const content = this.props.contentRenderer && this.props.contentRenderer(item);
    if (content) {
      return <RadioContent>{content}</RadioContent>;
    }
    return null;
  }

  renderRadioButton = (item, groupName, isLastItem) => {
    const checked = `${this.props.value}` === `${(item.value || item)}`;
    const buttonClasses = [baseRadioButtonStyle];
    const disabled = this.isItemDisabled(item);
    if (disabled) { buttonClasses.push(disabledStyle); }
    return (
      <RadioItem
        className={this.props.itemClassName}
        key={item.value || item}
        orientation={this.props.orientation}
        isLastItem={isLastItem}
      >
        <RadioLabel>
          <RadioButton>
            <input
              className={radioStyle(disabled)}
              disabled={disabled}
              type="radio"
              name={groupName}
              value={item.value || item}
              onChange={disabled ? undefined : this.handleChange}
              checked={checked}
            />
            <div className={buttonClasses.join(' ')}>
              {checked && <RadioCheck />}
            </div>
          </RadioButton>
          {this.renderItem(item)}
        </RadioLabel>
        {this.renderContent(item)}
      </RadioItem>
    );
  }

  render() {
    const groupName = this.props.name || `radioGroup-${new Date().getTime()}`;
    return (
      <RadioContainer orientation={this.props.orientation}>
        {this.props.items.map((item, index) => (
          this.renderRadioButton(item, groupName, index + 1 === this.props.items.length)))}
      </RadioContainer>
    );
  }
}

export default HOC(RadioField);

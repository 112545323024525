import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import styled from 'react-emotion';
import { css } from 'emotion';
import ReactMarkdown from 'react-markdown';
import qs from 'query-string';

import Link from 'components/utils/Link';
import { LinkText } from 'components/utils/Styles';
import Badge from 'components/utils/Badge';
import Help from 'components/utils/Help';
import Icon from 'components/utils/Icon';
import Panel from 'components/utils/Panel';
import ZendeskSessionLink from 'components/utils/ZendeskSessionLink';

import ProviderProfileIcon from 'components/admin/providers/ProviderProfileIcon';

import { ThemeContext } from 'components/utils/ThemeContext';

const htmlParser = require('react-markdown/plugins/html-parser');

const LinkLinkText = styled(LinkText.withComponent('span'))`
  font-size: inherit;
`;

const getColor = (color) => {
  const {
    theme: {
      colors: Colors,
    } = {},
  } = useContext(ThemeContext);
  return Colors[color] || color;
};

const getClassName = (node) => {
  const styles = node.attribs.style.split(';').filter(i => i).map(i => i.split(':')).reduce((set, [key, value]) => ({
    ...set,
    [key.trim()]: value.trim(),
  }), {});

  // Now we inject our custom styles
  if (styles.color) {
    styles.color = `${getColor(styles.color)} !important`;
  }

  const attribute = Object.entries(styles).reduce((result, [key, value]) => (
    `${key}: ${value}; ${result}`
  ), '').trim();

  return css`${attribute}`;
};

const createHtmlParser = ({ disableStyles = false } = {}) => htmlParser({
  isValidNode: () => true,
  processingInstructions: [{
    shouldProcessNode: node => node.type === 'tag' && node.attribs.style,
    processNode: (node, children) => React.createElement(node.name || 'span', { className: disableStyles ? undefined : getClassName(node) }, children),
  }],
});

const marker = (mdStr, opts = {}) => {
  const options = typeof opts === 'function' ? { onAction: opts } : (opts || {});
  const {
    onAction = () => {},
    ...parserOptions
  } = options;
  const LinkLink = (props) => {
    const { href } = props;
    // Relative Links
    if (href && href[0] === '/') {
      return (
        <Link to={href}>
          <LinkLinkText>{props.children}</LinkLinkText>
        </Link>
      );
    }
    if (href.indexOf('#action:') === 0) {
      const matches = href.match(/#action:(.+)/);
      if (matches) {
        const [, action] = matches;
        return <LinkLinkText onClick={() => onAction(action)}>{props.children}</LinkLinkText>;
      }
    }
    if (href.indexOf('#badge:') === 0) {
      const matches = href.match(/#badge:(.+)/);
      if (matches) {
        const [, key] = matches;
        return (
          <span>
            <Badge name={key} raised size={14} /> {props.children}
          </span>
        );
      }
    }
    if (href.indexOf('#help:') === 0) {
      const matches = href.match(/#help:(.+)/);
      if (matches) {
        const [, token] = matches;
        const [key, query] = token.split('?');
        let params = {};
        if (query) {
          const allowedOptions = ['position', 'offsetX', 'offsetY', 'on', 'html', 'iconStyle'];
          params = Object.entries(qs.parse(query))
            .filter(([k]) => allowedOptions.some(item => item === k))
            .reduce((set, [k, v]) => ({ ...set, [k]: v }), params);
        }
        return (
          <span>
            <Help value={key} {...params /* eslint-disable-line react/jsx-props-no-spreading */} />
          </span>
        );
      }
    }
    if (href.indexOf('#icon:') === 0) {
      const matches = href.match(/#icon:(.+)/);
      if (matches) {
        const [, config] = matches;
        const [key, color] = config.split(':');
        return (
          <span>
            <Icon name={key} color={getColor(color)} size={18} /> {props.children}
          </span>
        );
      }
    }
    if (href.indexOf('#provider-icon:') === 0) {
      const matches = href.match(/#provider-icon:(.+)/);
      if (matches) {
        const [, config] = matches;
        const [key, color] = config.split(':');
        return (
          <Panel align="center" orientation="horizontal" spacing={5}>
            <ProviderProfileIcon name={key} color={getColor(color)} size={18} /> {props.children}
          </Panel>
        );
      }
    }
    if (href.indexOf('#support') === 0) {
      return (
        <ZendeskSessionLink component={LinkText}>
          {props.children}
        </ZendeskSessionLink>
      );
    }
    return (
      <a target="_blank" rel="noopener noreferrer" href={href}>
        <LinkLinkText>{props.children}</LinkLinkText>
      </a>
    );
  };

  LinkLink.propTypes = {
    /** Link text */
    children: PropTypes.node,
    /** Link href */
    href: PropTypes.string,
  };
  LinkLink.defaultProps = {
    children: null,
    href: null,
  };


  return (
    <ReactMarkdown
      source={mdStr}
      escapeHtml={false}
      renderers={{ link: LinkLink }}
      astPlugins={[createHtmlParser(parserOptions)]}
    />
  );
};

export default marker;

import React from 'react';

import { Popup, Styles } from 'components/utils';
import { createTranslator, messageDateFormat } from 'helpers/i18n';

const { Secondary, PopupContent } = Styles;

const tr = createTranslator({
  time_stamp: {
    sent: time => `Sent: ${time ? `${messageDateFormat(time)}` : 'No Date Set'}`,
    read: time => `Read: ${time ? `${messageDateFormat(time)}` : 'No Date Set'}`,
  },
});

const withTimeStampPopup = WrappedComponent => ({ item, position }) => (
  <Popup
    trigger={<WrappedComponent item={item} />}
    triggerOn="hover"
    width={200}
    autoHeight
    disableScrolling
    position={position || 'bottom'}
  >
    <PopupContent>
      <Secondary>{tr('time_stamp.sent', item.last_sent_date || item.created_at)}</Secondary>
      {(!!item.last_read_at || !!item.read_at || item.read_flag) && (
        <Secondary>{tr('time_stamp.read', item.last_read_at || item.read_at || item.updated_at)}</Secondary>
      )}
    </PopupContent>
  </Popup>
);

export default withTimeStampPopup;

/* eslint-disable camelcase */
import UA from 'ua-parser-js';

import apiDirector from 'helpers/ApiDirector';
import store from 'store';
import * as actions from './actions';
import * as systemOperations from '../system/operations';

export const requestSession = () => dispatch => apiDirector.validateFetch('/api/session')
  .then(json => dispatch(actions.replaceSession(json)));

export const updateUserMetadata = (metadata = {}) => (dispatch, getState) => {
  const { session: { user = {} } } = getState();
  const { id, metadata: existingMetadata = {} } = user;
  return apiDirector.validateFetch(`/api/users/${id}?respondWith=metadata`, {
    method: 'PATCH',
    body: JSON.stringify({
      user: {
        metadata: {
          ...existingMetadata,
          ...metadata,
        },
      },
    }),
  }).then(({ metadata: updates } = {}) => dispatch(actions.replaceMetadata(updates)));
};

export const updateUserPreferences = (prefs = {}) => (dispatch, getState) => {
  const { session: { user = {} } } = getState();
  const { id, prefs: existingPrefs = {} } = user;
  return apiDirector.validateFetch(`/api/users/${id}`, {
    method: 'PATCH',
    body: JSON.stringify({
      user: {
        prefs: {
          ...existingPrefs,
          ...prefs,
        },
      },
    }),
  }).then(({ prefs: updates } = {}) => dispatch(actions.replacePreferences(updates, true)));
};

const configureNetworkPolicy = () => (dispatch, getState) => Promise.resolve(getState())
  .then(({ session: { user: { prefs = {} } } = {} }) => {
    if (prefs.force_http_method_override) {
      apiDirector.setForceHttpMethodOverride(true);
    }
  });

const updateUserAgentIfNecessary = () => (dispatch, getState) => (
  Promise.resolve(UA(window.navigator.userAgent)).then(uaInfo => {
    const { session: { user = {} } } = getState();
    const { browser } = user.metadata || {};
    if (uaInfo) {
      const { browser: { name, version } = {}, os: { name: os } = {} } = uaInfo;
      const agent = [name, version, os].join(' / ');
      if (agent && agent !== browser) {
        return dispatch(updateUserMetadata({ browser: agent }));
      }
    }
    return true;
  }).catch(() => { }) // No worries!
);

export const startInitialLoad = (callback = () => {}) => dispatch => dispatch(requestSession())
  .then(() => dispatch(configureNetworkPolicy()))
  .then(() => dispatch(systemOperations.requestSystem()))
  .then(() => dispatch(updateUserAgentIfNecessary()))
  .then(callback);

export const startMinimalInitialLoad = (callback = () => {}) => dispatch => (
  dispatch(requestSession())
    .then(() => dispatch(systemOperations.requestSystem()))
    .then(callback)
);

// eslint-disable-next-line
const simulateFetch = (username, password) => (username ? Promise.resolve({
  username,
  firstName: username,
  lastName: 'Doe',
}) : Promise.resolve({}));

export const loginUser = (username, password) => () => apiDirector.validateFetchWithoutCatch('/devise_users/sign_in', {
  method: 'POST',
  body: JSON.stringify({
    user: {
      email: username,
      password,
    },
  }),
});

/**
 * Same as logout, but hangs a flash message on the response so that the
 * proper UI is shown on the login screen.
 */
export const timeoutUser = andThen => () => apiDirector.validateFetchWithoutCatch('/api/session/timeout')
  .then(() => {
    if (store && store.enabled) {
      store.set('idleTimerLoggedOut', true);
    }
    if (andThen && typeof andThen === 'function') {
      andThen();
    } else {
      window.location.reload();
    }
  });

export const logoutUser = andThen => () => apiDirector.fetch('/devise_users/sign_out', {
  method: 'DELETE',
}).then(() => {
  if (store && store.enabled) {
    store.set('idleTimerLoggedOut', true);
  }
  if (window.zE && window.zE.logout) {
    try {
      window.zE.logout();
    } catch (whoCares) {
      // Nothing to do...
    }
  }
  if (andThen && typeof andThen === 'function') {
    andThen();
  } else {
    window.location.assign('/devise_users/sign_in');
  }
});

export const updateUser = data => dispatch => apiDirector.validateFetch('/api/session/profile', {
  method: 'PATCH',
  body: JSON.stringify({ user: data }),
})
  .then(json => dispatch(actions.replaceUser(json)));

export const updateUserAvatar = files => (dispatch) => {
  if (!files || files.length === [0]) {
    return Promise.resolve(true);
  }

  const form = new FormData();
  form.append('user[avatar]', files[0]);

  return apiDirector.validateFetch('/api/session/profile', {
    method: 'PATCH',
    body: form,
  }, {
    Accept: 'application/json',
  })
    .then(json => dispatch(actions.replaceUser(json)));
};

/**
 * Reset the user password.
 */
export const resetPassword = email => () => apiDirector.validateFetch('/devise_users/password', {
  method: 'POST',
  body: JSON.stringify({
    user: {
      email,
    },
  }),
});

/**
 * Reset password using token
 */
export const changePasswordWithToken = form => () => apiDirector.validateFetch('/devise_users/password', {
  method: 'PUT',
  body: JSON.stringify({
    user: form,
  }),
});

/**
 * Validate reset password token
 */
export const validateResetPasswordToken = form => () => apiDirector.validateFetch('/devise_users/password/validate_token', {
  method: 'POST',
  body: JSON.stringify(form),
});

/**
 * Resend instructions to the user to confirm their account.
 */
export const resendConfirmationInstructions = email => () => apiDirector.validateFetch('/devise_users/confirmation', {
  method: 'POST',
  body: JSON.stringify({
    user: {
      email,
    },
  }),
});

/**
 * Validate a given invitation code for a given email address.
 * The response here should contain two pieces of information:
 *
 * valid: true if this token is valid, false otherwise.
 * existingUser: true if this user already has an account, false otherwise.
 *
 * This information will help determine how to route the user next.
 */
// eslint-disable-next-line no-unused-vars
export const validateInvitationCode = (token, email) => dispatch => apiDirector.validateFetch('/api/session/validate_token', {
  method: 'POST',
  body: JSON.stringify({
    validate: { token, email },
  }),
});

/**
 * Called once an invited user confirms the HIPAA agreement.
 */
export const inviteUser = (code, email, provider) => dispatch => Promise.resolve({ code, email })
  .then(() => dispatch(actions.setInvitedUser(code, email, provider)));

/**
 * Create new user account with a password
 */
export const signUpUser = (email, password, profile, organization) => () => apiDirector.validateFetch('/devise_users/sign_up', {
  method: 'POST',
  body: JSON.stringify({
    user: {
      email,
      password,
      password_confirmation: password,
      ...profile,
      user_organizations_attributes: [organization],
    },
  }),
})
  .then(() => { window.location.assign('/api/session/welcome'); });

/**
 * FIXME: This should update the organization settings. For
 * testing purposes, it just targets the hospital.
 *
 */
// eslint-disable-next-line camelcase
export const updateOrganizationSettings = ({
  id,
  organization_type,
}, settings) => (dispatch, getState) => {
  const { session: { organizations } } = getState();
  const organization = organizations
    .find(org => org.id === id && org.organization_type === organization_type) || {};
  const { settings: OriginalSettings = {} } = organization;
  return apiDirector.validateFetch(`/api/organizations/${id}?organization_type=${organization_type}`, {
    method: 'POST',
    body: JSON.stringify({
      organization: {
        settings: {
          ...OriginalSettings,
          ...settings,
        },
      },
    }),
  })
    .then(() => dispatch(requestSession()));
};

export const activateZendeskModal = (event, position = 'top') => (_, getState) => {
  const {
    session: {
      user: { id: isLoggedIn } = {},
    } = {},
  } = getState();
  const createHandler = context => (cb) => {
    apiDirector.validateFetch('/zendesk_session/generate', {
      method: 'POST',
      body: JSON.stringify({ context }),
    }).then(({ token } = {}) => {
      cb(token);
    });
  };

  if (window.zE) {
    const settings = {
      webWidget: {
        position: { horizontal: 'right', vertical: position },
      },
    };
    if (isLoggedIn) {
      settings.webWidget.authenticate = {
        chat: {
          jwtFn: createHandler('chat'),
        },
        jwtFn: createHandler('widget'),
      };
    }
    window.zESettings = settings;
    if (isLoggedIn) {
      window.zE('webWidget', 'updateSettings', settings);
      window.zE('webWidget', 'helpCenter:reauthenticate');
      window.zE('webWidget', 'chat:reauthenticate');
    } else {
      window.zE('webWidget', 'updateSettings', settings);
      window.zE('webWidget', 'logout');
    }
    window.zE.activate({ hideOnClose: true });
  }
};

export const verifyIdentity = (id, data = {}) => dispatch => apiDirector.validateFetch(`/api/identity_verifications/${id}`, {
  method: 'PATCH',
  body: JSON.stringify({
    identity_verification: {
      ...data,
      status: 'succeeded',
    },
  }),
}).then(() => dispatch(requestSession()));

export const createOrganizationSendingService = (owner, service) => (dispatch, getState) => apiDirector.validateFetch('/api/hospitals', {
  method: 'POST',
  body: JSON.stringify({
    hospital: {
      hospital_system_id: owner.id,
      hospital_system_type: owner.type || owner.organization_type || 'Provider',
      ...service,
    },
  }),
})
  .then(({ id } = {}) => dispatch(requestSession()).then(() => {
    const { session: { organizations } } = getState();
    return organizations.find(o => o.id === id && o.organization_type === 'Hospital');
  }));

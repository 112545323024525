import React from 'react';

/**
 * This context takes in whatever query parameters are given in the URL
 * and provides them to consumers as an object. Will update as the URL
 * does. This is an alternative to all the boilerplate code required to
 * load and parse this from React Router's location.search prop, but
 * connecting to that would achieve the same result.
 */
const QueryContext = React.createContext({});

export default QueryContext;

import { createContext } from 'react';
import { DateTime } from './dates';
import { reportError } from './ErrorHandler';

/** Default application locale to standard English */
export const DEFAULT_LOCALE = 'en';

/** Support languages in app */

export const SUPPORTED_LANGUAGES = ['en', 'es'];

/**
 * Returns a function bound to a given set of translations, which
 * you can pass a dot-separated key and interpolation values to
 * pass to the translations.
 *
 * The oracle values should be either a constant string or a function
 * that returns a string. These functions may take parameters, and
 * the values parameter will be passed through to that function.
 *
 * Example:
 *
 * import { createTranslator } from 'helpers/i18n';
 *
 * const tr = createTranslator({
 *   hello: 'Hello, world!',
 *   greet: name => `Hello, ${name}.`,
 *   nested: { term: 'Nesting works' },
 * });
 *
 * tr('hello');        # Hello, world!
 * tr('greet', 'Neo'); # Hello, Neo.
 * tr('nested.term');  # Nesting works
 *
 * You can also pass a second parameter containing translations based
 * on the shape of your oracle. Any misses from a translation will fall
 * back to the oracle. Finally, you can specify a third parameter as the
 * default locale to use for translations, which defaults to en.
 *
 * If you want to override the default locale for a single translation,
 * pass an object as the first parameter to the returned function instead
 * of a string, with the `key` being the translation key and `locale`
 * being the locale to use to translate.
 *
 * Example:
 *
 * import { createTranslator } from 'helpers/i18n';
 *
 * const tr = createTranslator({
 *   hello: 'Hello',
 * }, { es: { hello: 'Hola' } }, 'es');
 *
 * tr('hello')                          # Hola
 * tr({ key: 'hello', locale: 'es' });  # Hola
 * tr({ key: 'hello', locale: 'en' });  # Hello
 *
 */
export const createTranslator = (
  oracle,
  translations = {},
  defaultLocale = DEFAULT_LOCALE,
) => (keyOrConfig, ...values) => {
  const config = typeof keyOrConfig === 'string' ? { key: keyOrConfig, locale: defaultLocale } : (keyOrConfig || {});
  const { key, locale: trLocale } = config;
  const locale = trLocale || defaultLocale;
  let path = null;
  try {
    path = key.split('.');
  } catch (e) {
    reportError(e, 'A null or undefined key was sent for translation', { key });
    return null;
  }
  let result = null;
  if (translations[locale]) {
    result = path.reduce((next, token) => next && next[token], translations[locale]);
  }
  if (!result) {
    result = path.reduce((next, token) => next && next[token], oracle);
  }
  if (!result) { return null; }
  if (!values) {
    return result;
  }
  return typeof result === 'function' ? result.apply(this, values) : result;
};

const firstValue = (...values) => values.find(v => typeof v !== 'undefined' && v !== null);

/**
 * Resolve a number to a string value, or the number itself.
 *
 * If no resolution is found, use the given number instead.
 * Use "other" as a catch-all in your resolver object. A function
 * is also acceptable.
 */
export const numeric = (value, resolver, defaultValue) => {
  switch (typeof resolver) {
    case 'object':
      return firstValue(resolver[value], resolver.other, defaultValue, value);
    case 'function':
      return firstValue(resolver(value), defaultValue, value);
    default:
      return firstValue(defaultValue, value);
  }
};

/**
 * Pluralize a term. Returns the term only.
 *
 * The resolver can be one of the following types:
 *
 * string -- the customized pluralization of the term (i.e. "goose" vs "geese")
 * object -- a hash of numbers, i.e:
 *   { 1: "a goose", 2: "a couple geese", other: "traffic jam" }
 * function -- for plurals, return a custom value
 */
export const pluralize = (count, term, iResolver, iLocale = DEFAULT_LOCALE) => {
  if (!term || count === 1) { return term; }
  let resolver = iResolver;
  let locale = iLocale;
  if (SUPPORTED_LANGUAGES.some(i => i === resolver)) {
    // Passed a locale, but not resolver. Allow the optional param.
    resolver = null;
    locale = iResolver;
  }
  if (!resolver) {
    if (locale === 'es') {
      let endsWith = term.slice(-1);
      if (typeof term.normalize === 'function') {
        endsWith = term.normalize('NFD').replace(/[\u0300-\u036f]/g, '').slice(-1);
      }
      return ['a', 'e', 'i', 'o', 'u'].some(v => v === endsWith)
        ? `${term}s`
        : `${term}es`;
    }

    // Dumb English translation. Simple.
    return term.slice(-1) === 'y' ? `${term.slice(0, -1)}ies` : `${term}s`;
  }
  switch (typeof resolver) {
    case 'string': // A term was given to use in case of plurals
      return resolver;
    case 'object': // A hash table will compute this
      return resolver[count] || resolver.other || term;
    case 'function': // Fine, you deal with it
      return resolver(count, term);
    default: // All else fails, return the term
      return term;
  }
};

/**
 * The relative time from now until some time in the future,
 * i.e. "in 3 hours". Supply a custom "now" as the second arg,
 * or use defaults.
 */
export const relativeTimeUntil = (date, { prefix = false } = {}, now = DateTime.now()) => (
  now.to(date, !prefix)
);

/**
 * The relative time from now to some date in the past
 * i.e. "3 hours ago". Supply a custom "now" as the second arg,
 * or use defaults.
 */
export const relativeTimeSince = (date, { suffix = false } = {}, now = DateTime.now()) => (
  date.from(now, !suffix)
);

/** Template constructed for displaying Date and Time using compact format */
export const messageDateFormat = date => `${DateTime.dayFormat(DateTime.parse(date), {
  format: 'M/D | h:mma',
  otherFormat: 'M/D/YY | h:mma',
})}`;

/**
 * Borrowed heavily from https://api.rubyonrails.org/v5.2.4/classes/Array.html#method-i-to_sentence
 * "Converts the array to a comma-separated sentence where the last element is joined by the
 * connector word."
 * TODO: add i18n locales
 */
export const toSentence = (
  arr,
  {
    wordsConnector = ', ',
    lastWordConnector = ' and ',
  } = {},
) => {
  const len = arr.length;

  switch (len) {
    case 0:
      return '';
    case 1:
      return `${arr[0]}`;
    case 2:
      return `${arr[0]}${lastWordConnector}${arr[1]}`;
    default: {
      const lastIdx = len - 1;

      return `${arr.slice(0, lastIdx).join(wordsConnector)}${lastWordConnector}${arr[lastIdx]}`;
    }
  }
};

/**
 * Could be useful for spanish to automatically wrap questions in
 * question marks while keeping the end punctuation for english.
 */
export const punctuate = (sentence, punctuation = '.') => {
  if (typeof sentence !== 'string') { return null; }
  if (sentence.trim().endsWith(punctuation)) { return sentence.trim(); }
  return `${sentence.trim()}${punctuation}`;
};

/**
 * Returns a string translation for the given key, potentially
 * interpolated with any given values, for the given set of
 * translations.
 *
 * See createTranslator for more details, as, under the hood,
 * this function simply calls that function and passes the given
 * key and values for you.
 */
export const translate = (oracle, key, ...values) => createTranslator(oracle)(key, ...values);

export const I18nContext = createContext(DEFAULT_LOCALE);

import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { cx, css } from 'emotion';
import { omit } from 'lodash';
import { numeric } from 'helpers/i18n';
import withThemedComponents, { ThemeConfig } from 'components/utils/withThemedComponents';

require('components/badges');

const raisedBadgeStyle = colors => (size, color) => css`
  background-color: ${typeof color === 'string' ? color : colors.RECEIVING_LIGHT_FILL};
  border-radius: 100%;
  width: ${size}px;
  height: ${size}px;
  text-align: center;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
`;

const createComponentStyles = ({ version, theme: { colors } }) => numeric(version, {
  other: {
    prefix: '',
    styles: {
      raisedBadgeStyle: raisedBadgeStyle(colors),
      getColor: () => undefined,
    },
  },
  v2: {
    prefix: 'v2_',
    styles: {
      raisedBadgeStyle: (size) => css`
        width: ${size}px;
        height: ${size}px;
        text-align: center;
      `,
      getColor: (raisedColor, { category, roles = [] } = {}) => {
        if (typeof raisedColor === 'string') {
          return raisedColor;
        }
        const defaultColor = numeric(category, {
          user: roles.some(r => r === 'receiving')
            ? colors.RECEIVING_BADGE_FILL
            : colors.SENDING_BADGE_FILL,
          other: colors.RECEIVING_BADGE_FILL,
        });
        return raisedColor ? defaultColor : colors.WHITE;
      },
    },
  },
});

/**
 * Render the SVG image of a badge given its key.
 */
const Badge = ({
  badges = [], theme, className, name, raised, size, white, ...svgProps
}) => {
  const {
    prefix,
    styles: {
      raisedBadgeStyle: createRaisedBadgeStyle,
      getColor,
    },
  } = theme;

  const badgeConfig = badges.find(({ key }) => key === name);

  return (
    <>
      <svg
        className={cx(raised && createRaisedBadgeStyle(size, raised), className)}
        width={size}
        height={size}
        color={getColor(raised, badgeConfig)}
        {...omit(svgProps, ['dispatch'])}
      >
        {/* Waiting until we get all the badges before we implement this ternary 'v2_' */}
        <use xlinkHref={`#badge-${prefix}${name}${white || (raised && white === undefined) ? '-white' : ''}`} />
      </svg>
    </>
  );
};
Badge.propTypes = {
  badges: PropTypes.arrayOf(PropTypes.shape({
    category: PropTypes.string,
    key: PropTypes.string,
  })).isRequired,
  /** Custom class name */
  className: PropTypes.string,
  /** Badge key */
  name: PropTypes.string.isRequired,
  /**
   * True for a raised badge with RECEIVING_LIGHT_FILL as
   * the background color. You can also pass a color
   * as a string to raise the badge with the background
   * being the given color.
   */
  raised: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  /** Size of badge (default 100) */
  size: PropTypes.number,
  /** White variant. Raised badges are white by default */
  white: PropTypes.bool,
  /** Theme-specific styles */
  theme: ThemeConfig.isRequired,
};
Badge.defaultProps = {
  className: undefined,
  raised: undefined,
  size: 100,
  white: undefined,
};

export default compose(
  connect(({ system: { badges } }) => ({ badges })),
  withThemedComponents(createComponentStyles),
)(Badge);

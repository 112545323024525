export default {
  title: 'Create or Locate an Account',
  email: 'Email',
  firstName: 'First Name',
  lastName: 'Last Name',
  submit: 'Submit',
  back: 'Back',
  errors: {
    blank: 'This field is required and can not be blank',
    firstName: 'Please enter your first name',
    lastName: 'Please enter your last name',
    email: 'Please enter a valid email address',
    tokenInvalid: 'Your password reset link has expired. Re-enter your email to request a new one. Your link will expire in **24 hours.**<br /><br />Be sure to click the most recently-received link.',
  },
  success: {
    title: 'Welcome to Aidin!',
    thanks: 'Thank You!',
    check: 'Check your email for an activation link.',
    button: 'Close',
    help: 'Didn’t get the email? Try the following:',
  },
};

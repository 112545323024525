import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion';

import { numeric } from 'helpers/i18n';
import { applyTestAttribute } from 'helpers/development';

import { Row, Column, mq } from 'components/utils/Responsive';
import { ThemeContext } from 'components/utils/ThemeContext';

const createComponentStyles = ({ theme: { colors }, version }) => numeric(version, {
  other: {
    styles: {
      ContentPanelTitle: styled.div`
        background-color: ${props => props.backgroundColor};
        padding: 0px 20px;
      `,
      ContentPanelContainer: styled.div`
        background-color: ${colors.WHITE};
        box-shadow: 0 2px 3px 0 rgba(153, 153, 153, 0.3);
        display: flex;
        flex-direction: column;
      `,
      ContentPanelBody: styled.div`
        padding: 17px 20px;
        height: 100%;
      `,
    },
  },
  v2: {
    styles: {
      ContentPanelTitle: styled.div`
        background-color: ${colors.WHITE};
        ${mq({ padding: ['0px 46px', '0px 46px', '0px 20px'] })};
        border-bottom: 1px solid ${props => (props.divider ? colors.NOTIFICATION_FILL : colors.TRANSPARENT)};
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      `,
      ContentPanelContainer: styled.div({
        backgroundColor: colors.WHITE,
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '10px',
        border: `1px solid ${colors.TEXTFIELD_BORDER_COLOR}`,
      }),
      ContentPanelBody: styled.div(mq({ padding: ['17px 46px', '17px 46px', '17px 20px'] })),
    },
  },
});

const ContentTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const ContentTitleIcon = styled.div`
  flex-shrink: 0;
`;

const ContentTitleTag = styled.h3`
  font-size: 14px;
  margin: 20px 0px;
`;

/** Basis for a title of a ContentPanel */
const ContentTitle = ({
  title,
  titleElement,
  icon,
  responsiveIcon,
}) => {
  const themeInfo = useContext(ThemeContext);
  const { version: v } = themeInfo;
  const TitleTag = titleElement || 'h3';
  if (responsiveIcon) {
    return (
      <ContentTitleContainer>
        <Row columns={[2, 2, 1]}>
          <Column>
            {v === 'v1' && <TitleTag>{title}</TitleTag>}
            {v === 'v2' && <ContentTitleTag>{title}</ContentTitleTag>}
          </Column>
          <Column>
            <ContentTitleIcon>{icon}</ContentTitleIcon>
          </Column>
        </Row>
      </ContentTitleContainer>
    );
  }
  return (
    <ContentTitleContainer>
      {v === 'v1' && <TitleTag>{title}</TitleTag>}
      {v === 'v2' && <ContentTitleTag>{title}</ContentTitleTag>}
      <ContentTitleIcon>{icon}</ContentTitleIcon>
    </ContentTitleContainer>
  );
};
ContentTitle.propTypes = {
  title: PropTypes.oneOfType([PropTypes.func, PropTypes.node, PropTypes.string]).isRequired,
  titleElement: PropTypes.oneOfType([PropTypes.func, PropTypes.string, PropTypes.element]),
  icon: PropTypes.node,
  responsiveIcon: PropTypes.bool,
};
ContentTitle.defaultProps = {
  titleElement: 'h3',
  icon: null,
  responsiveIcon: false,
};

/**
 * Use ContentPanel generally to wrap any content to display.
 */
const ContentPanel = (props) => {
  const themeInfo = useContext(ThemeContext);
  const {
    styles: {
      ContentPanelTitle,
      ContentPanelContainer,
      ContentPanelBody,
    },
  } = useMemo(() => createComponentStyles(themeInfo), [themeInfo.version]);
  const titleElement = props.titleElement || (
    <ContentTitle title={props.title} icon={props.icon} responsiveIcon={props.responsiveIcon} />
  );
  return (
    <ContentPanelContainer
      {...applyTestAttribute(props) /* eslint-disable-line react/jsx-props-no-spreading */}
      id={props.id}
    >
      {!props.hideTitleBar && (
        <ContentPanelTitle
          className={props.titleContainerClassName}
          backgroundColor={({ theme }) => (
            props.titleBackgroundColor || theme.colors.CONTENT_PANEL_TITLE
          )}
          divider={props.divider}
        >
          {titleElement}
        </ContentPanelTitle>
      )}
      {props.banner}
      <ContentPanelBody
        className={props.className}
      >
        <>
          {props.children}
        </>
      </ContentPanelBody>
    </ContentPanelContainer>
  );
};
ContentPanel.propTypes = {
  /** The content of the content panel */
  children: PropTypes.node.isRequired,
  /** Optional icon for the panel, displayed to the right. TODO: How do we address icons? */
  icon: PropTypes.node,
  /** Optional ID for this content panel */
  id: PropTypes.string,
  /** Text for panel title, displayed to the left. Ignored when using titleElement */
  title: PropTypes.oneOfType([PropTypes.func, PropTypes.node, PropTypes.string]),
  /** Background color for the title. Default to CONTENT_PANEL_TITLE (#ddd) */
  titleBackgroundColor: PropTypes.string,
  /** Class name for title container */
  titleContainerClassName: PropTypes.string,
  /** Custom title component for this panel. Title and icon are ignored */
  titleElement: PropTypes.node,
  /** Hide the title bar */
  hideTitleBar: PropTypes.bool,
  /** Put the icon component in an equal weight column that stacks at small size */
  responsiveIcon: PropTypes.bool,
  /** Remove border under title */
  divider: PropTypes.bool,
  /** Change Content Style */
  className: PropTypes.string,
  'data-testid': PropTypes.string,
  /** Custom sub-title component */
  banner: PropTypes.oneOfType([PropTypes.func, PropTypes.node, PropTypes.string]),
};
ContentPanel.defaultProps = {
  icon: null,
  id: undefined,
  title: null,
  titleBackgroundColor: undefined,
  titleContainerClassName: undefined,
  titleElement: null,
  hideTitleBar: false,
  responsiveIcon: false,
  divider: true,
  className: undefined,
  'data-testid': null,
  banner: undefined,
};

export default ContentPanel;

export { ContentTitle };

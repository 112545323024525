import React from 'react';
import PropTypes from 'prop-types';
import { createTranslator } from 'helpers/i18n';
import styled from 'react-emotion';
import helpText from 'translations/comingSoon';

import Help from 'components/utils/Help';
import Panel from 'components/utils/Panel';
import { NOTIFICATION_FILL } from 'components/utils/Colors';
import { Label } from 'components/utils/Styles';
import { detectIE } from 'components/utils/IE';

const tr = createTranslator({
  label: 'Coming soon:',
  text: 'Coming soon!',
  help: {
    ...helpText,
  },
});

const Text = styled(Label)`
  color: ${NOTIFICATION_FILL};
`;

/* if an AidinNext variable is being sent, we are running under Rails.
 * Respect its comingSoon flag if any, or default to false (expose all
 * functionality)
 *
 * if it's not sent, (e.g. we are running under styleguidist), default to true
 */
const COMING_SOON = window.AidinNext
  ? (window.AidinNext.comingSoon === 'true') || false
  : true;

const HIDE_COMING_SOON = !COMING_SOON;

const ComingSoon = ({
  align: alignParam,
  asLabel,
  children,
  className,
  help,
  isComingSoon = COMING_SOON,
  orientation,
  position,
  spacing,
  text,
}) => {
  if (!isComingSoon) {
    return children;
  }
  const align = alignParam === 'left' && detectIE() ? 'stretch' : alignParam;
  let component = <Text>{text || (asLabel ? tr('label') : tr('text'))}</Text>;
  if (help) {
    component = (
      <Panel spacing={10} align="center" orientation="horizontal">
        {component}
        <Help html>{tr(`help.${help}`)}</Help>
      </Panel>
    );
  }
  return (
    <Panel align={align} className={className} orientation={orientation} spacing={spacing}>
      {position !== 'after' && component}
      {children}
      {position === 'after' && component}
    </Panel>
  );
};
ComingSoon.propTypes = {
  /** Alignment of the coming soon text and the children. Defaults to center */
  align: PropTypes.oneOf(['top', 'left', 'center', 'right', 'bottom']),
  /**
   * True to display as a label, i.e. "Coming soon:",
   * false as text "Coming soon!" (default false)
   */
  asLabel: PropTypes.bool,
  /** The content */
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.node]).isRequired,
  /** Extra class name if styling is required */
  className: PropTypes.string,
  /** Help text lookup value. Looks in translations/comingSoon.js */
  help: PropTypes.string,
  /** Force setting of coming soon */
  isComingSoon: PropTypes.bool,
  /** One of "horizontal" or "vertical" (default horizontal) */
  orientation: PropTypes.oneOf(['horizontal', 'vertical']),
  /**
   * Position of the coming soon text.
   * - use "before" for left for horizontal orientation, above for vertical
   * - use "after" for right for horizontal orientation, below for vertical
   */
  position: PropTypes.oneOf(['before', 'after']),
  /** How much space, in px, between coming soon and the children. Default 20 */
  spacing: PropTypes.number,
  /** Custom text in place of "Coming soon". Ignores the "asLabel" option */
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.func]),
};
ComingSoon.defaultProps = {
  align: 'center',
  asLabel: false,
  className: undefined,
  help: undefined,
  isComingSoon: undefined,
  orientation: 'horizontal',
  position: 'before',
  spacing: undefined,
  text: undefined,
};

export { ComingSoon as default, COMING_SOON, HIDE_COMING_SOON };

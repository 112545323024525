import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'emotion';

import { createTranslator } from 'helpers/i18n';

import Button from 'components/utils/Button';
import Icon from 'components/utils/Icon';
import FileDropZone from 'components/utils/FileDropZone';
import Panel from 'components/utils/Panel';
import Field from 'components/utils/Field';

const removeIconStyle = css`
  position: absolute;
  top: 0px;
  right: 0px;
`;

const relativeStyle = css`
  position: relative;
`;

const tr = createTranslator({
  addButtonText: 'Upload Image',
  changeButtonText: 'Upload Image',
  altText: 'Uploaded Image',
});

class ImageUploadField extends React.Component {
  static propTypes = {
    /** True to allow deleting the image, false otherwise */
    allowRemove: PropTypes.bool,
    /** True to allow upload, false for display-only */
    allowUpload: PropTypes.bool,
    /** Callback for when an image should be deleted */
    onRemove: PropTypes.func,
    /** Callback for when a file is uploaded and has a handle */
    onUpload: PropTypes.func,
    /** Optional CSS class names for img tag */
    imageClassName: PropTypes.string,
    /** Button text when no image is set */
    addButtonText: PropTypes.string,
    /** Button text when image is set */
    changeButtonText: PropTypes.string,
    /** Default alt text */
    altText: PropTypes.string,
    /** Is dropzone disabled? */
    disabled: PropTypes.bool,
    /** file image accept types */
    accept: PropTypes.arrayOf(PropTypes.string),
    /** The max file size */
    maxSize: PropTypes.number,
    /** True to allow for multiple files to be uploaded at once. */
    multiple: PropTypes.bool,
    /** Name of file input field */
    name: PropTypes.string,
    /** Value (url) of existing form value */
    value: PropTypes.string,
  }

  static defaultProps = {
    allowRemove: false,
    allowUpload: false,
    onRemove: undefined,
    onUpload: undefined,
    imageClassName: null,
    addButtonText: tr('addButtonText'),
    changeButtonText: tr('changeButtonText'),
    altText: tr('altText'),
    disabled: false,
    accept: undefined,
    maxSize: undefined,
    multiple: undefined,
    name: undefined,
    value: undefined,
  }

  constructor(props) {
    super(props);
    this.state = {
      imageFile: undefined,
      imageUrl: this.props.value,
    };
  }

  handleRequestUpload = () => {
    if (this.fileUpload) {
      this.fileUpload.open();
    }
  }

  handleRemove = () => {
    this.setState({
      imageFile: undefined,
      imageUrl: undefined,
    }, () => {
      if (this.props.onRemove) {
        this.props.onRemove();
      }
    });
  }

  onUpload = (files) => {
    const [file] = files;
    this.setState({
      imageFile: file,
      imageUrl: undefined,
    }, () => {
      if (this.props.onUpload) {
        this.props.onUpload(file);
      }
    });
  }

  getImagePreview = () => {
    if (this.state.imageFile && typeof URL !== 'undefined' && URL.createObjectURL) {
      return URL.createObjectURL(this.state.imageFile);
    }
    if (this.state.imageUrl) {
      return this.state.imageUrl;
    }
    return null;
  }

  getAltText = () => {
    if (this.state.imageFile) {
      return this.state.imageFile.name;
    }
    return this.props.altText;
  }

  render() {
    const {
      allowRemove,
      allowUpload,
      disabled,
      imageClassName,
      addButtonText,
      changeButtonText,
      accept,
      maxSize,
      multiple,
      name,
    } = this.props;

    const preview = this.getImagePreview();

    return (
      <FileDropZone
        className={relativeStyle}
        ref={(ref) => { this.fileUpload = ref; }}
        disabled={disabled || !allowUpload}
        onFileUploaded={this.onUpload}
        accept={accept}
        maxSize={maxSize}
        multiple={multiple}
        name={name}
      >
        <Panel>
          {preview && (
            <img
              alt={this.getAltText()}
              className={imageClassName}
              src={preview}
            />
          )}
          {allowUpload && (
            <Button small onClick={this.handleRequestUpload}>
              {preview ? changeButtonText : addButtonText}
            </Button>
          )}
          {allowRemove && preview && (
            <Icon className={removeIconStyle} name="cancel" onClick={this.handleRemove} />
          )}
        </Panel>
      </FileDropZone>
    );
  }
}

export default Field(ImageUploadField);

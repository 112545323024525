import initialState from 'store/initialState';
import { shapeMessage } from './filters';
import * as types from './types';

const inbox = (state = initialState.inbox, action) => {
  const { type, ...entity } = action;
  switch (type) {
    case types.APPEND_INBOX_LIST: {
      return {
        ...state,
        ...entity,
        list: state.list.concat(entity.list).map(shapeMessage),
      };
    }
    case types.REPLACE_INBOX_LIST: {
      return {
        ...state,
        ...entity,
        list: entity.list ? entity.list.map(shapeMessage) : state.list,
      };
    }
    default:
      return state;
  }
};

const messages = (state = initialState.messages, action) => {
  const { type, ...entity } = action;
  switch (type) {
    case types.REPLACE_MESSAGE_LIST:
      return {
        ...state,
        ...entity,
        list: entity.list ? entity.list.map(shapeMessage) : state.list,
      };
    case types.REPLACE_CURRENT_THREAD:
      return {
        ...state,
        current: {
          id: entity.id,
          ...entity.thread,
        },
      };
    case types.CLOSE_CURRENT_THREAD:
      return {
        ...state,
        current: null,
      };
    case types.TOGGLE_NEW_MESSAGE_THREAD:
      return {
        ...state,
        composer: {
          open: entity.open === undefined ? !state.composer.open : entity.open,
        },
      };
    default:
      return state;
  }
};

export default { inbox, messages };

import { combineReducers } from 'redux'; // eslint-disable-line
import { createResponsiveStateReducer } from 'redux-responsive';
import sessionReducer from 'ducks/session';
import systemReducer from 'ducks/system';
import printingReducer from 'ducks/printing';
import referralsReducer from 'ducks/referrals';
import providerSearchReducer from 'ducks/providerSearch';
import messagesReducer from 'ducks/messages';
import conversationsReducer from 'ducks/conversations';
import dashboardReducer from 'ducks/dashboard';
import referralsDashboardReducer from 'ducks/referralsDashboard';
import notificationsReducer from 'ducks/notifications';
import adminReducer from 'ducks/admin';
import uiReducer from 'ducks/ui';
import loadingQueueReducer from 'ducks/loadingQueue';
import documentsReducer from 'ducks/documents';

const reduceReducers = (a, b) => (state, action) => (
  b(a(state, action), action)
);

const mergeDuckReducers = (reducers, duck) => {
  const result = {
    ...reducers,
  };
  Object.entries(duck).forEach((entry) => {
    if (reducers[entry[0]]) {
      result[entry[0]] = reduceReducers(reducers[entry[0]], entry[1]);
    } else {
      result[entry[0]] = entry[1]; //eslint-disable-line
    }
  });
  return result;
};

let appReducerObject = {
  browser: createResponsiveStateReducer({
    small: 600,
    medium: 960,
  }, {
    infinity: 'large',
  }),
};

appReducerObject = mergeDuckReducers(appReducerObject, sessionReducer);
appReducerObject = mergeDuckReducers(appReducerObject, systemReducer);
appReducerObject = mergeDuckReducers(appReducerObject, printingReducer);
appReducerObject = mergeDuckReducers(appReducerObject, referralsReducer);
appReducerObject = mergeDuckReducers(appReducerObject, providerSearchReducer);
appReducerObject = mergeDuckReducers(appReducerObject, messagesReducer);
appReducerObject = mergeDuckReducers(appReducerObject, dashboardReducer);
appReducerObject = mergeDuckReducers(appReducerObject, referralsDashboardReducer);
appReducerObject = mergeDuckReducers(appReducerObject, notificationsReducer);
appReducerObject = mergeDuckReducers(appReducerObject, adminReducer);
appReducerObject = mergeDuckReducers(appReducerObject, uiReducer);
appReducerObject = mergeDuckReducers(appReducerObject, loadingQueueReducer);
appReducerObject = mergeDuckReducers(appReducerObject, documentsReducer);
appReducerObject = mergeDuckReducers(appReducerObject, conversationsReducer);

export default Object.freeze(appReducerObject);

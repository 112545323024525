import initialState from 'store/initialState';
import * as types from './types';

const updateProviderExclusions = (state, { providerExclusions }) => [
  ...state,
  ...providerExclusions,
];

const deleteProviderExclusion = (state, { providerExclusionId }) => {
  const copy = state.slice();
  const index = copy.findIndex(i => i.id === providerExclusionId);
  if (index >= 0) {
    copy.splice(index, 1);
  }
  return copy;
};

const providerExclusions = (state = initialState.admin.providerExclusions, action) => {
  const { type, ...entity } = action;

  switch (type) {
    case types.REPLACE_PROVIDER_EXCLUSIONS:
      return entity.providerExclusions;
    case types.UPDATE_PROVIDER_EXCLUSIONS:
      return updateProviderExclusions(state, entity);
    case types.DELETE_PROVIDER_EXCLUSIONS:
      return deleteProviderExclusion(state, entity);
    default:
      return state;
  }
};

export default { providerExclusions };

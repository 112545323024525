import React from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { cx, css } from 'emotion';
import { getProviderTypeModels } from 'models/ProviderType';
import { DateTime } from 'helpers/dates';

import Icon from 'components/utils/Icon';
import {
  CONFIRMATION_FILL, ERROR_LIGHT_FILL,
  NOTIFICATION_FILL, WHITE,
  TRANSPARENT,
} from 'components/utils/Colors';

import { mq } from 'components/utils/Responsive';
import BadgeToast from 'components/notifications/BadgeToast';
import NotificationToast from 'components/notifications/NotificationToast';
import PasswordChangeReminderToast from 'components/notifications/PasswordChangeReminderToast';

const BADGE_TOAST_VISIBLE_SECONDS = 15;

const PASSWORD_WARNING_TOAST_ID = 'password-warning-toast';

const ERROR_TOAST_ID = 'error-toast';

const PRINT_TOAST_ID = 'print-toast';

const toastContainerStyle = css`
  ${css(mq({ width: ['400px', '320px', '320px'] }))}
  font-family: inherit;
  box-shadow: 0 2px 6px 0 rgba(153, 153, 153, 0.4);
  border: 1px solid #cccccc;
`;

/**
 * Close button for the toast.
 */
const CloseIcon = ({ closeToast, color }) => (
  <Icon name="mp-close" onClick={closeToast} color={color} size={40} />
);
CloseIcon.propTypes = {
  /** This method is supplied by toastify */
  closeToast: PropTypes.func,
  color: PropTypes.string,
};
CloseIcon.defaultProps = {
  closeToast: undefined,
  color: undefined,
};

/**
 * Display a closeable confirmation message with a green background.
 * Returns the ID of the toast.
 */
export const confirmationToast = message => toast(message, {
  className: cx(toastContainerStyle, css({
    background: `${CONFIRMATION_FILL} !important`,
  })),
  bodyClassName: css({
    color: WHITE,
  }),
  closeButton: <CloseIcon color={WHITE} />,
});

/**
 * Display a closeable error message with a red background.
 * Returns the ID of the toast.
 */
export const errorToast = message => message && !toast.isActive(ERROR_TOAST_ID) && toast(message, {
  className: cx(toastContainerStyle, css({
    background: `${ERROR_LIGHT_FILL} !important`,
  })),
  bodyClassName: css({
    color: WHITE,
  }),
  closeButton: <CloseIcon color={WHITE} />,
  toastId: ERROR_TOAST_ID,
});

export const showPasswordWarningToast = () => toast(
  <PasswordChangeReminderToast />,
  {
    autoClose: false,
    className: cx(toastContainerStyle, css({
      background: `${ERROR_LIGHT_FILL} !important`,
    })),
    bodyClassName: css({
      color: WHITE,
    }),
    closeButton: false,
    closeOnClick: false,
    draggable: false,
    toastId: PASSWORD_WARNING_TOAST_ID,
  },
);

export const hidePasswordWarningToast = () => {
  if (toast.isActive(PASSWORD_WARNING_TOAST_ID)) {
    toast.dismiss(PASSWORD_WARNING_TOAST_ID);
  }
};

export const notificationToast = (message, toastOptions = {}) => toast(
  <NotificationToast {...message} />,
  {
    className: cx(toastContainerStyle, css({
      background: `${NOTIFICATION_FILL} !important`,
    })),
    bodyClassName: css({
      color: WHITE,
    }),
    closeButton: <CloseIcon color={WHITE} />,
    ...toastOptions,
  },
);

export const badgeProgressToast = (key, theme, text) => toast(
  ({ closeToast }) => <BadgeToast name={key} theme={theme} text={text} onClose={closeToast} />,
  {
    className: css({
      backgroundColor: TRANSPARENT,
      border: `0px solid ${TRANSPARENT}`,
      boxShadow: 'none',
    }),
    closeButton: false,
    autoClose: BADGE_TOAST_VISIBLE_SECONDS * 1000,
  },
);

export const viewOnlyToast = message => notificationToast(
  { text: message },
  { toastId: ERROR_TOAST_ID },
);

export const printToast = (renderProgressBar) => {
  const render = (renderProps) => (
    <div key={new Date().getTime()}>
      {renderProgressBar(renderProps)}
    </div>
  );
  const options = {
    autoClose: false,
    bodyClassName: css({
      color: WHITE,
    }),
    className: css({
      backgroundColor: TRANSPARENT,
      border: `0px solid ${TRANSPARENT}`,
      boxShadow: 'none',
    }),
    closeButton: false,
    closeOnClick: false,
    draggable: false,
    toastId: PRINT_TOAST_ID,
  };
  if (toast.isActive(PRINT_TOAST_ID)) {
    toast.update(PRINT_TOAST_ID, { render });
    return PRINT_TOAST_ID;
  }
  return toast(render, options);
};

/** The hour at which times start. (TODO: is this configurable, or hard-set?) */
export const STARTING_HOUR = 0;

/** The hour at which times end. (TODO: is this configurable?) */
export const ENDING_HOUR = 24;

/** The default hour for days other than today */
export const DEFAULT_HOUR = 10;

/** Early hour tomorrow for late referrals */
export const EARLY_HOUR = 10;

/** Hours after this are considered "late" */
export const DEFAULT_LATE_TIME = 13;

/** Default hours recommendation. Should not need in practice but... */
export const DEFAULT_RECOMMENDATION = 4;

export const lateThresholdRule = (levelOfCare) => {
  if (levelOfCare.length === 0) { return DEFAULT_LATE_TIME; }
  return getProviderTypeModels(levelOfCare)
    .map(({ lateDeadlineHour }) => lateDeadlineHour)
    .reduce((a, b) => (b < a ? b : a));
};

/** Uses the most impactful rule */
export const defaultDeadlineRule = (levelOfCare) => {
  if (levelOfCare.length === 0) { return DEFAULT_RECOMMENDATION; }
  const rule = getProviderTypeModels(levelOfCare)
    .map(({ defaultDeadlineHours }) => defaultDeadlineHours)
    .reduce((a, b) => (a < b ? b : a));
  return rule || DEFAULT_RECOMMENDATION;
};

/**
 * Zero out time units that match or are more specific than the
 * given precision. Modifies (and returns) the end result.
 */
export const clearDateTime = (date = DateTime.now(), precision = 'second') => {
  const units = ['day', 'hour', 'minute', 'second', 'millisecond'];
  const precisionIndex = units.indexOf(precision);
  if (precisionIndex < 0) { return date; }

  const changeSet = {};
  units.forEach((unit, index) => {
    changeSet[unit] = index >= precisionIndex ? 0 : date.get(unit);
  });

  return date.set(changeSet);
};

const getRoundedTimeInterval = (time, interval, buffer, bufferUnits) => {
  // eslint-disable-next-line no-nested-ternary
  const value = time ? typeof time === 'string'
    ? DateTime.parse(time) : time : DateTime.now();

  const nearestMinutes = (someMoment) => {
    const roundedMinutes = Math.ceil(someMoment.minute() / interval) * interval;
    return someMoment.clone().minute(roundedMinutes).second(0);
  };

  return nearestMinutes(value.add(buffer, bufferUnits));
};

/** Round time up to the nearest 15 minutes, zero hour the rest */
export const getRoundedTime = (time, options) => {
  // eslint-disable-next-line no-nested-ternary
  const value = time ? typeof time === 'string'
    ? DateTime.parse(time) : time : DateTime.now();

  if (options) {
    const {
      interval,
      buffer,
      bufferUnits = 'minutes',
    } = options;
    return getRoundedTimeInterval(value, interval, buffer, bufferUnits);
  }
  if (value.minute() > 0) {
    if (value.minute() <= 15) {
      clearDateTime(value.minute(15));
    } else if (value.minute() <= 30) {
      clearDateTime(value.minute(30));
    } else if (value.minute() <= 45) {
      clearDateTime(value.minute(45));
    } else {
      value.add(1, 'hour');
      clearDateTime(value, 'minute');
    }
  }
  return value;
};

/**
 * Determine a proper rounded default hour for a deadline when
 * one is provided, based on the current level of care.
 *
 * When providers are supplied, we will use the min suggested_deadline
 * instead the default for current level of care.
 *
 * The disableDayRollover option, when set to true, will prevent the
 * default mode of rolling over to the next day when it is late in
 * the day. This is useful when explicitly setting a time.
 *
 * The disableDefaultHour option, when set to true, will prevent the
 * default hour from being set in certain situations. Again, useful
 * for explicitly setting values.
 *
 */
const getMinSuggestedDeadline = (suggestedDeadlines) => (
  suggestedDeadlines.reduce((min, p) => (p.suggested_deadline < min ? p.suggested_deadline : min), suggestedDeadlines[0].suggested_deadline));

export const getDefaultDeadlineValue = (
  date,
  levelOfCare,
  providers = [],
  {
    defaultDeadlineSource = {},
    disableDayRollover = false,
    disableDefaultHour = false,
  } = {},
  tr = () => null,
) => {
  const tips = [];
  const time = getRoundedTime(date);
  const now = getRoundedTime(DateTime.now());
  const suggestedDeadlines = providers?.filter(p => p.suggested_deadline && p.suggested_deadline > 0);
  const minSuggestedDeadline = suggestedDeadlines.length > 0 ? getMinSuggestedDeadline(suggestedDeadlines) : null;
  const recommended = minSuggestedDeadline || defaultDeadlineRule(levelOfCare);

  const {
    referralRoleOrganization: {
      settings: {
        default_deadline: defaultDeadline,
      } = {},
    } = {},
  } = defaultDeadlineSource;

  if (defaultDeadline && !minSuggestedDeadline) {
    time.add(defaultDeadline, 'hours');
  } else if (time.isSame(now, 'day')) {
    if (date === null && !disableDefaultHour) {
      time.add(recommended, 'hours');
      if (minSuggestedDeadline) {
        // No further processing for suggested deadlines.
        return { time, tips };
      }
    }
    if (time.hour() < EARLY_HOUR) {
      if (!disableDefaultHour) {
        time.set({ hour: EARLY_HOUR, minute: 0 });
      }
    // add support for longer lead times that can potentially exceed a day rollover
    } else if (time.hour() > ((defaultDeadlineRule(levelOfCare) % 24) + lateThresholdRule(levelOfCare))) {
      // Too late to book today, try tomorrow, unless this is a time reset.
      if (!disableDayRollover) {
        time.add(1, 'day');
        tips.push({ type: 'warning', tip: tr('tip.late', lateThresholdRule(levelOfCare), EARLY_HOUR) });
      }
      /*
       * Set the default hour, unless the out-of-bounds time is
       * being set by force (i.e. typing)
       */
      if (!disableDefaultHour) {
        time.set({ hour: EARLY_HOUR, minute: 0 });
      }
    }
  } else if (!disableDefaultHour) {
    time.set({ hour: DEFAULT_HOUR, minute: 0 });
  }

  return { time, tips };
};

export const getFontForTheme = fontName => ({
  theme: {
    fonts: {
      [fontName]: font,
    } = {},
  } = {},
} = {}) => font;

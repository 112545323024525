import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { css, cx } from 'emotion';

import { createTranslator, numeric } from 'helpers/i18n';
import { User } from 'models/Session';

import {
  Tag,
  Help,
  Icon,
  Colors,
  Styles,
} from 'components/utils';

const tr = createTranslator({
  label: context => numeric(context, {
    user: 'View-Only Aidin Access',
    other: 'View-Only',
  }),
  help: context => numeric(context, {
    user: 'users.roles.view_only',
    other: 'users.locations.view_only',
  }),
});

const { WHITE, NOTIFICATION_FILL } = Colors;

const { clickableStyle } = Styles;

const tagAsIconStyle = css`
  padding: 2px;
`;

const ViewOnlyIndicator = ({
  className,
  icon,
  size: pageSize,
  user: givenUser,
  defaultUser,
  context,
}) => {
  const { roles = [] } = givenUser || defaultUser;
  const size = icon ? 'small' : pageSize;

  if (context === 'user' && !roles.some(r => r === 'view_only')) { return null; }

  const position = numeric(size, {
    large: 'bottom center',
    other: 'bottom left',
  });

  const triggerContent = numeric(size, {
    large: tr('label', context),
    other: <Icon name="eye" color={WHITE} size={icon ? 14 : undefined} />,
  });

  const trigger = (
    <Tag
      className={cx(icon ? tagAsIconStyle : clickableStyle, className)}
      color={NOTIFICATION_FILL}
      textColor={WHITE}
    >
      {triggerContent}
    </Tag>
  );

  return icon ? trigger : (
    <Help
      offsetY={8}
      position={position}
      trigger={trigger}
      value={tr('help', context)}
    />
  );
};
ViewOnlyIndicator.propTypes = {
  /** Custom class name */
  className: PropTypes.string,
  /** Context which this is displaying */
  context: PropTypes.oneOf(['user', 'referral']),
  /** Logged-in user */
  defaultUser: User.isRequired,
  /** True to always show small view-only icon, false otherwise */
  icon: PropTypes.bool,
  /** Page size */
  size: PropTypes.oneOf(['small', 'medium', 'large']).isRequired,
  /** Overrides the logged-in user */
  user: User,
};
ViewOnlyIndicator.defaultProps = {
  className: undefined,
  icon: undefined,
  user: undefined,
  context: 'user',
};

export default connect(({
  browser: { mediaType: size } = {},
  session: { user: defaultUser } = {},
}) => ({ defaultUser, size }))(ViewOnlyIndicator);

/* eslint-disable react/no-unused-prop-types */

import React from 'react';
import PropTypes from 'prop-types';
import { css, cx } from 'emotion';
import styled from 'react-emotion';
import Select from 'react-select';
import HOC from 'components/utils/Field';
import ViewContext from 'components/utils/ViewContext';
import { createTranslator } from 'helpers/i18n';

import * as colors from 'components/utils/Colors';

import withComponentTheme from 'components/utils/withComponentTheme';
import { ThemeContext } from 'components/utils/ThemeContext';

const { NOTIFICATION_TRANSPARENT_FILL, TEXT_ERROR, WHITE } = colors;

const tr = createTranslator({
  empty: 'No results found',
});

export const getCommonStyles = () => ({
  v1: {
    styles: {
      SHADOW: {
        SELECT_CONTROL: {
          SET: 'inset 0 0.5px 3px 0',
          R: '153',
          G: '153',
          B: '153',
          A: '0.57',
        },
        SELECT_OUTER_MENU: {
          SET: '0 2px 6px 0',
          R: '153',
          G: '153',
          B: '153',
          A: '0.4',
        },
      },
      SELECT_CONTROL_BORDER_RADIUS: '2px',
      SELECT_MENU_OUTER_BORDER_RADIUS: '4px',
      SELECT_MENU_OUTER_BORDER: 'none',
    },
  },
  v2: {
    styles: {
      SHADOW: {
        SELECT_CONTROL: {
          SET: 'inset 0 1px 4px 0',
          R: '125',
          G: '126',
          B: '139',
          A: '0.5',
        },
        SELECT_OUTER_MENU: {
          SET: '0 4px 4px 0',
          R: '125',
          G: '126',
          B: '139',
          A: '0.2',
        },
      },
      SELECT_CONTROL_BORDER_RADIUS: '5px',
      SELECT_MENU_OUTER_BORDER_RADIUS: '5px',
      SELECT_MENU_OUTER_BORDER: 'solid 1px #dfdfe2',
      SELECT_MENU_OUTER_MARGIN: 'margin-top: 7px',
    },
  },
});

const createComponentStyles = ({ theme: { colors: themeColors } }) => {
  const commonStyles = getCommonStyles();
  return {
    ...commonStyles,
    v2: {
      ...commonStyles.v2,
      styles: {
        ...commonStyles.v2.styles,
        versionBaseStyle: css({
          '.Select-control': {
            boxShadow: 'unset',
            borderRadius: '4px',
            border: `1px solid ${themeColors.SELECT_FIELD_BORDER}`,
            '.Select-arrow': {
              borderWidth: '8px 5px 0.5px',
              color: themeColors.BLACK,
              opacity: 1,
              borderColor: `${themeColors.BLACK} transparent transparent`,
            },
            '.Select-arrow-zone': {
              opacity: 1,
            },
          },
        }),
      },
    },
  };
};

export const Sizes = {
  medium: {
    height: 34,
    lineHeight: '30px',
    fontSize: 15,
  },
  large: {
    height: 44,
    lineHeight: '44px',
    fontSize: 20,
  },
};

export const SelectFieldContainer = styled.div`
  .is-focused, .is-open {
    .Select-control {
      border: 1px solid ${({ theme }) => theme.colors.SELECT_FIELD_SELECTED};
    }
  }
`;

export const selectStyle = (size, highlight, styles) => css`
  .Select-control {
    height: ${size.height}px;
    font-size: ${size.fontSize}px;
    border-radius: ${styles.SELECT_CONTROL_BORDER_RADIUS};
    background-color: ${highlight ? NOTIFICATION_TRANSPARENT_FILL : WHITE};
    box-shadow: ${styles.SHADOW.SELECT_CONTROL.SET} rgba(${styles.SHADOW.SELECT_CONTROL.R}, ${styles.SHADOW.SELECT_CONTROL.G}, ${styles.SHADOW.SELECT_CONTROL.B}, ${styles.SHADOW.SELECT_CONTROL.A});
  }
  .Select-control .Select-multi-value-wrapper .Select-placeholder {
    position: absolute;
  }
  .Select-control .Select-multi-value-wrapper .Select-input > input {
    width: 100% !important;
  }
  .Select-control .Select-input {
    width: 100%;
    height: 100%;
    line-height: ${size.lineHeight};
    position: absolute;
  }
  .Select-control .Select-placeholder {
    line-height: ${size.lineHeight};
  }
  .Select-control .Select-value {
    line-height: ${size.lineHeight};
  }
  .Select-control input {
    padding: 0px;
    line-height: ${size.lineHeight};
  }
  .Select-menu-outer {
    border: ${styles.SELECT_MENU_OUTER_BORDER};
    border-radius: ${styles.SELECT_MENU_OUTER_BORDER_RADIUS};
    background-color: #ffffff;
    box-shadow: ${styles.SHADOW.SELECT_OUTER_MENU.SET} rgba(${styles.SHADOW.SELECT_OUTER_MENU.R}, ${styles.SHADOW.SELECT_OUTER_MENU.G}, ${styles.SHADOW.SELECT_OUTER_MENU.B}, ${styles.SHADOW.SELECT_OUTER_MENU.A});
    .Select-menu {
      .is-focused {
        background-color: #eeeeee !important;
      }
      .is-selected {
        background-color: #eeeeee !important;
      }
    }
    ${styles.SELECT_MENU_OUTER_MARGIN}
  }
`;

export const optionStyle = css`
  min-height: 30px;
  padding: 6px 7px;
  &:hover {
    background-color: #eeeeee;
  }
  &:.is-selected {
    background-color: #eeeeee;
  }
`;

/** A select dropdown field */
class SelectField extends React.Component {
  static contextType = ThemeContext;

  static propTypes = {
    /** Automatically focus on load */
    autoFocus: PropTypes.bool,
    /** Optional class name for the field wrapper */
    className: PropTypes.string,
    /** Max height with units, e.g. '5rem', '400px' */
    maxHeight: PropTypes.string,
    /** True to disable this field, false otherwise */
    disabled: PropTypes.bool,
    /** Optional array of error messages */
    errors: PropTypes.arrayOf(PropTypes.node),
    /** True to highlight the control with a green background, false otherwise */
    highlight: PropTypes.bool,
    /** Optional function for custom item rendering */
    itemRenderer: PropTypes.func,
    /** Array of items to display in the drop down */
    items: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.arrayOf(PropTypes.shape({
        /** True to disable this menu item, false otherwise */
        disabled: PropTypes.bool,
        /** Label to display for item by default */
        label: PropTypes.string,
        /** Identifier for this item */
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      })),
    ]).isRequired,
    /** Optional label to display over the field. Can be a string or node */
    label: PropTypes.node,
    /** Optional class name to add to the label text */
    labelClassName: PropTypes.string,
    /** Callback when the menu is closed */
    onClose: PropTypes.func,
    /** Callback when the menu is opened */
    onOpen: PropTypes.func,
    /**
     * Opens select when focused. When used in tandem with autoFocus, will open
     * the select on render.
     */
    openOnFocus: PropTypes.bool,
    /**
     * Placeholder displayed when there are no matching search results
     * or a falsy value to hide it (can also be a react component)
     */
    empty: PropTypes.string,
    /** Input name */
    name: PropTypes.string,
    /** Callback when an item is selected */
    onSelect: PropTypes.func,
    /** Placeholder text to display */
    placeholder: PropTypes.string,
    /** True if required field, false otherwise */
    required: PropTypes.bool,
    /** Input size */
    size: PropTypes.oneOf(['medium', 'large']),
    /** The pre-selected value */
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.shape({})]),
    /** Optional function for custom value rendering */
    valueRenderer: PropTypes.func,
    /** Instructs onChange to save value to internal state */
    changeValueState: PropTypes.bool,
    /** inputProps passthrough */
    inputProps: PropTypes.shape({}),
  };

  static defaultProps = {
    className: undefined,
    maxHeight: '200px',
    autoFocus: undefined,
    disabled: false,
    errors: undefined,
    highlight: false,
    itemRenderer: undefined,
    label: undefined,
    labelClassName: undefined,
    name: undefined,
    onClose: undefined,
    onOpen: undefined,
    openOnFocus: undefined,
    empty: tr('empty'),
    onSelect: undefined,
    placeholder: undefined,
    required: false,
    size: 'medium',
    value: null,
    valueRenderer: undefined,
    changeValueState: true,
    inputProps: undefined,
  };

  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.props.value) {
      this.setState({ value: nextProps.value });
    }
  }

  handleChange = (value) => {
    this.setState(() => {
      if (this.props.changeValueState) {
        return { value: value ? value.value : '' };
      }
      return null;
    }, () => {
      if (this.props.onSelect) {
        this.props.onSelect(value === null ? undefined : value);
      }
    });
  }

  getReadOnlyValue = () => {
    const { empty, items, value } = this.props;
    const item = items.find(i => i.value === value);
    return item ? item.label : empty;
  }

  render() {
    const {
      disabled, highlight, items, itemRenderer,
      name, onOpen, onClose,
      size, valueRenderer, errors, maxHeight,
      placeholder, empty, inputProps,
      autoFocus, openOnFocus,
    } = this.props;

    const { theme: { styles, styles: { versionBaseStyle } } } = this.context;

    return (
      <ViewContext.Consumer>
        {({ readOnly }) => (
          <>
            {readOnly
              ? <div className="Select-read-only-value">{this.getReadOnlyValue()}</div>
              : (
                <Select
                  ref={(ref) => { this.input = ref; }}
                  autoBlur
                  autoFocus={autoFocus}
                  className={cx(css`
                    ${selectStyle(Sizes[size] || Sizes.medium, highlight && this.state.value, styles)}
                    .Select-control {
                      border: 1px solid ${errors ? TEXT_ERROR : 'transparent'};
                    }
                    .Select-menu, .Select-menu-outer {
                      max-height: ${maxHeight};
                    };
                  `, versionBaseStyle)}
                  disabled={disabled}
                  name={name}
                  optionClassName={optionStyle}
                  optionRenderer={itemRenderer}
                  valueRenderer={valueRenderer || itemRenderer}
                  clearable={false}
                  noResultsText={empty}
                  onChange={this.handleChange}
                  onOpen={onOpen}
                  onClose={onClose}
                  openOnFocus={openOnFocus}
                  placeholder={placeholder}
                  options={items}
                  value={this.state.value}
                  inputProps={inputProps}
                />
              )}
          </>
        )}
      </ViewContext.Consumer>
    );
  }
}

export default withComponentTheme(createComponentStyles)(HOC(SelectField, { Container: SelectFieldContainer }));

import React from 'react';
import styled from 'react-emotion';

import { createTranslator } from 'helpers/i18n';

import Referral from 'models/Referral';

import {
  Panel,
  Styles,
} from 'components/utils';

const { Secondary } = Styles;

const tr = createTranslator({
  unknownPatient: '(Patient removed)',
});

const ReferralContent = styled.div`
  opacity: 0.8;
  color: #222;
  font-family: Cabin;
  font-size: 14px;
  line-height: 19px;
  white-space: nowrap;
  max-width: 160px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ReferralDetailRow = ({
  referral,
  referral: {
    patient: { name } = {},
  },
}) => (
  <Panel orientation="horizontal" spacing={10}>
    {name ? <ReferralContent>{name}</ReferralContent> : (
      <ReferralContent><Secondary>{tr('unknownPatient')}</Secondary></ReferralContent>
    )}
    <ReferralContent>|</ReferralContent>
    <ReferralContent>{referral.level_of_care.join(', ')}</ReferralContent>
    <ReferralContent>|</ReferralContent>
    <ReferralContent>#{referral.id}</ReferralContent>
  </Panel>
);
ReferralDetailRow.propTypes = {
  referral: Referral.isRequired,
};

export default ReferralDetailRow;

import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'emotion';
import styled from 'react-emotion';
import translations from 'translations/help';
import { createTranslator, relativeTimeSince } from 'helpers/i18n';
import { DateTime } from 'helpers/dates';
import Referral from 'models/Referral';
import AttachmentModel from 'models/Attachment';

import CheckboxField from 'components/utils/CheckboxField';
import GearMenu from 'components/utils/GearMenu';
import Help from 'components/utils/Help';
import Icon from 'components/utils/Icon';
import PDFStatus from 'components/utils/PDFStatus';
import Link from 'components/utils/Link';
import Tag from 'components/utils/Tag';
import Panel from 'components/utils/Panel';
import { MenuDisabled } from 'components/utils/Menu';

import AttachmentRoleIcon from 'components/referrals/AttachmentRoleIcon';
import ForwardAttachmentPopup from 'components/referrals/ForwardAttachmentPopup';
import EditableAttachmentLabel from 'components/utils/EditableAttachmentLabel';

import {
  MEDIUM_GRAY_FILL, LIGHTEST_GRAY_FILL, BLACK, RECEIVING_DARKER_FILL, WHITE,
} from 'components/utils/Colors';
import { Secondary, LinkText } from 'components/utils/Styles';
import withHoverState from 'components/utils/withHoverState';
import ConfirmationPopup from 'components/utils/ConfirmationPopup';
import ResponseRequestedMenu from 'components/documents/sending/ResponseRequestedMenu';

const tr = createTranslator({
  ...translations,
  removePrompt: 'Remove Attachment?',
  menu: {
    view: 'View Attachment',
    edit: 'Edit Attachment',
    remove: 'Remove from Referral',
    fax: 'Fax Attachment',
    forward: 'Forward Attachment to Email',
    refresh: 'Refresh Report',
    rename: 'Rename file',
  },
  pendingText: name => `Uploading ${name}...`,
  pendingUpdateText: name => `Updating ${name}...`,
  pendingFaxText: id => `(Waiting for Inbound Fax) Cover Sheet for Fax ID ${id}...`,
  attachmentCreatedAgo: time => relativeTimeSince(DateTime.parse(time), { suffix: true }),
  signedTag: 'Signed',
});

const Container = styled.div`
  min-height: 20px;
  border-radius: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid ${MEDIUM_GRAY_FILL};
  padding: 0 8px;
  margin: 4px 0px;
  &:hover {
    background-color: ${LIGHTEST_GRAY_FILL};
  }
`;

const IconContainer = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
`;

const iconStyle = css`
  padding: 0px 4px;
  cursor: pointer;
`;

const FauxIcon = styled.div`
  ${iconStyle}
  font-size: 20px;
  color: ${MEDIUM_GRAY_FILL};
`;

const EmptyIcon = styled(FauxIcon)`
  width: 14px;
  height: 14px;
`;

const SourceIcon = styled.div`
  display: flex;
  align-items: center;
`;

const AttachmentLabel = styled(Secondary.withComponent('span'))();

const AttachmentName = styled.div`
  ${props => props.editable && 'cursor: pointer;'}
  opacity: ${props => (props.pending ? '0.2' : undefined)};
  display: flex;
  &, a {
    width: 100%;
    flex: 1 1 auto;
  }

  a {
    text-decoration: none;
  }

  ${AttachmentLabel} {
    width: ${props => (props.changeFontSize ? '95%' : '100%')};
    ${props => props.changeFontSize && 'font-size: 12px;'}
    word-break: break-all;
    display: inline-block;
    padding: 5px 0 4px;
  }
`;

const checkBoxLabelClassName = css`
  white-space: normal;
  text-overflow: ellipsis;
  margin-left: 25px;
`;

const checkBoxClassName = css`
  width: 100%;
`;

const iconPaddingStyle = css`
  padding: 0px 6px;
`;

const fullWidth = css`
  width: 100%;
`;

/**
 * Display panel for an attachment, with icons for
 * print, menu, and delete.
 */
class Attachment extends React.Component {
  static propTypes = {
    /** Attachment data */
    attachment: AttachmentModel.isRequired,
    /** True if checkable (render a checkbox), false otherwise */
    checkable: PropTypes.bool,
    /** True if checked, false otherwise (requires checkable) */
    checked: PropTypes.bool,
    /** True to disable delete, false otherwise */
    disableDelete: PropTypes.bool,
    /** True to hide icons, false otherwise (default false) */
    disableIcons: PropTypes.bool,
    /** True to disable linking, false otherwise */
    disableLink: PropTypes.bool,
    /** True to edit attachments (rename), false otherwise (default false) */
    editable: PropTypes.bool,
    /** From withHoverState, true when use is hovering/focused */
    hovering: PropTypes.bool.isRequired,
    /** A list of supported menu options */
    menuOptions: PropTypes.arrayOf(PropTypes.oneOf(['view', 'edit', 'remove', 'forward', 'refresh', 'rename'])),
    /** Open attachment in viewer */
    onOpenAttachment: PropTypes.func,
    /** Handle checkbox changes */
    onCheckChange: PropTypes.func,
    /** Handle request to forward via email */
    onForwardRequested: PropTypes.func,
    /** Handle print requests */
    onPrintRequested: PropTypes.func,
    /** Delete an attachment */
    onRemoveAttachment: PropTypes.func,
    /** Rename an attachment */
    onRenameAttachment: PropTypes.func,
    /** Refresh an attachment (for patient reports) */
    onRefreshAttachment: PropTypes.func,
    openEditAttachmentModal: PropTypes.func,
    /** The referral object */
    referral: Referral,
    /** Function that allows sending user to Send a Document / Attachment */
    sendDocuments: PropTypes.func,
    /** Whether or not to display the Warning Icon for Unreadable PDFs on the right */
    showRightIcon: PropTypes.bool,
    /** True to show a column for attachment source, false otherwise */
    showSource: PropTypes.bool,
    confirmOnDelete: PropTypes.bool,
    /** Handle checkbox change for dropdown menu */
    onResponseRequestedCheckChange: PropTypes.func,
    /** True to show dropDown, false otherwise */
    showResponseRequestedMenu: PropTypes.bool,
    /** Selected Response Items for Label Updating */
    selectedResponseRequestedItems: PropTypes.arrayOf(PropTypes.string),
    /** Show Tag For Signed Documents */
    showSignedTag: PropTypes.bool,
  }

  static defaultProps = {
    checkable: false,
    checked: undefined,
    disableDelete: false,
    disableIcons: false,
    disableLink: false,
    editable: false,
    menuOptions: undefined,
    onCheckChange: undefined,
    onForwardRequested: undefined,
    onOpenAttachment: undefined,
    onPrintRequested: undefined,
    onRemoveAttachment: undefined,
    onRenameAttachment: undefined,
    onRefreshAttachment: undefined,
    openEditAttachmentModal: undefined,
    referral: undefined,
    sendDocuments: undefined,
    showRightIcon: undefined,
    showSource: false,
    confirmOnDelete: false,
    onResponseRequestedCheckChange: undefined,
    showResponseRequestedMenu: undefined,
    selectedResponseRequestedItems: undefined,
    showSignedTag: false,
  };

  constructor(props) {
    super(props);
    this.editingTextField = React.createRef();
  }

  /**
   * Turn on or off the attachment name editor.
   */
  toggleEditing = () => {
    if (this.editingTextField.current) {
      this.editingTextField.current.toggleEditing();
    }
  }

  getMenuOptions = (unreadable) => {
    const {
      menuOptions,
      referral,
      referral: {
        hospital: {
          phaxio_enabled: phaxioEnabled,
        } = {},
      } = {},
      attachment: {
        permissions: {
          destroy,
          read = true,
          send_document: forward = true,
          update,
        } = {},
      } = {},
    } = this.props;

    if (menuOptions) { return menuOptions; }
    if (referral) {
      return ['view', 'edit', 'remove', 'fax', 'forward', 'refresh']
        .filter((key) => {
          switch (key) {
            case 'fax':
              return referral.referralRole === 'sending'
                && !unreadable
                && this.props.attachment.file_content_type === 'application/pdf'
                && phaxioEnabled;
            case 'delete':
              return destroy;
            case 'view':
              return read;
            case 'edit':
              return update;
            case 'forward':
              return forward && !!this.props.openEditAttachmentModal;
            case 'refresh':
              return update;
            default:
              return true;
          }
        });
    }
    return ['view'];
  }

  getMenuItems = (unreadable) => this.getMenuOptions(unreadable)
    .filter(key => (!this.props.disableDelete && !this.props.attachment.protected) || key !== 'remove')
    .filter(key => this.props.attachment.dataSource === 'patient_reports' || key !== 'refresh')
    .filter(key => key !== 'edit' || (this.props.referral.referralRole === 'sending' && this.props.attachment.file_content_type === 'application/pdf' && (!this.props.attachment.protected || this.props.attachment.allowEdit)))
    .map(key => ({
      key,
      text: tr(`menu.${key}`),
    }))
    .map((item) => {
      if (item.key === 'view') {
        return {
          ...item,
          renderItem: close => (
            <Link
              to={this.props.attachment.url || `/api/attachments/${this.props.attachment.id}`}
              target="_blank"
              onClick={close}
            >
              <LinkText>{item.text}</LinkText>
            </Link>
          ),
        };
      }
      if (item.key === 'edit') {
        let renderItem;
        if (unreadable) {
          renderItem = () => <MenuDisabled>{item.text}</MenuDisabled>;
        } else {
          renderItem = close => (
            <LinkText
              onClick={() => this.props.openEditAttachmentModal({
                attachment: this.props.attachment,
                onOpen: close,
                organization: this.props.referral.referralRoleOrganization,
              })}
            >
              {item.text}
            </LinkText>
          );
        }
        return { ...item, renderItem };
      }
      if (item.key === 'forward') {
        let renderItem;
        if (unreadable) {
          renderItem = () => <MenuDisabled>{item.text}</MenuDisabled>;
        } else {
          renderItem = close => (
            <ForwardAttachmentPopup
              displayFromField
              referral={this.props.referral}
              onClose={close}
              trigger={<LinkText>{item.text}</LinkText>}
              onSubmit={(emails, from) => {
                this.props.onForwardRequested([this.props.attachment], emails, from);
              }}
            />
          );
        }
        return { ...item, renderItem };
      }

      if (item.key === 'fax') {
        const Component = this.props.sendDocumentPopup;
        const renderItem = close => (
          <Component
            saveOnly={item.value === 'save'}
            defaultValues={{
              name: '',
              fax: '',
              provider_type: '',
              save: item.value === 'save',
            }}
            onClose={close}
            onConfirm={({
              provider, options: { saveProvider },
            }) => this.props.sendDocuments(
              [this.props.attachment],
              provider,
              saveProvider,
              this.props.referral,
            )}
            trigger={<LinkText>{item.text}</LinkText>}
          />
        );

        return { ...item, renderItem };
      }
      return item;
    });

  handleDelete = () => {
    if (this.props.onRemoveAttachment) {
      this.props.onRemoveAttachment(this.props.attachment);
    }
  }

  handleRefresh = () => {
    if (this.props.onRefreshAttachment) {
      this.props.onRefreshAttachment(this.props.attachment);
    }
  }

  handleMenuItemClick = ({ key }) => {
    switch (key) {
      case 'remove':
        this.handleDelete();
        break;
      case 'refresh':
        this.handleRefresh();
        break;
      case 'rename':
        this.toggleEditing();
        break;
      default:
        break;
    }
  }

  handlePrint = () => {
    this.props.onPrintRequested([this.props.attachment]);
  }

  handleEdit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.toggleEditing();
  }

  renderLabel = ({ pending } = {}) => {
    const {
      checked,
      checkable,
      editable,
      hovering,
      disableLink,
      onCheckChange,
      onOpenAttachment,
      referral,
      attachment,
      attachment: {
        id,
        url,
        pendingState,
        code,
        dateAdded,
        meta: {
          unreadable = false,
        } = {},
      },
    } = this.props;
    const savedName = this.props.attachment.name || '';

    const draw = ({ label: name, toggleEditing }) => {
      const dateAddedText = dateAdded ? `  |  ${tr('attachmentCreatedAgo', dateAdded)}` : '';
      const label = (
        <AttachmentLabel>
          {name}{dateAddedText} {editable && hovering
            && <Icon className={iconPaddingStyle} color={BLACK} onClick={toggleEditing} name="mp-pencil-alt" />}
        </AttachmentLabel>
      );
      if (checkable) {
        return (
          <CheckboxField
            className={checkBoxClassName}
            labelClassName={checkBoxLabelClassName}
            label={label}
            onChange={onCheckChange}
            value={checked}
            disabled={!!unreadable}
          />
        );
      }
      if (pendingState === 'add') {
        return <AttachmentLabel pending>{tr('pendingText', name)}</AttachmentLabel>;
      }
      if (pendingState === 'update') {
        return <AttachmentLabel pending>{tr('pendingUpdateText', name)}</AttachmentLabel>;
      }
      if (pendingState === 'fax') {
        return <AttachmentLabel pending>{tr('pendingFaxText', id)}</AttachmentLabel>;
      }
      if (disableLink) {
        return label;
      }
      return (
        <Panel
          align="center"
          orientation="horizontal"
          spacing={2}
          itemClassName={index => (index === 1 ? fullWidth : undefined)}
        >
          <AttachmentRoleIcon
            referral={referral}
            attachment={attachment}
          />
          <Link
            to={url || `/api/attachments/${id}`}
            target="_blank"
            onClick={e => (onOpenAttachment ? onOpenAttachment(e, attachment) : undefined)}
          >
            <Panel orientation="horizontal" spacing={5} align="center">
              {unreadable && !checkable && code !== 'Pending' && (
                <PDFStatus
                  position="bottom"
                  message={tr('documents.pdf_unreadable.attachment_card')}
                />
              )}
              {label}
            </Panel>
          </Link>
        </Panel>
      );
    };

    return (
      <EditableAttachmentLabel
        ref={this.editingTextField}
        attachment={attachment}
        value={savedName}
        key={`${id}-${savedName}`}
        onSubmit={(newName) => {
          if (this.props.onRenameAttachment) {
            this.props.onRenameAttachment(this.props.attachment, newName);
          }
        }}
      >
        {config => (
          <AttachmentName
            pending={pending}
            editable={editable}
            onClick={editable && !config.editing && !id ? config.toggleEditing : undefined}
            changeFontSize={this.props.showResponseRequestedMenu && config.label.length > 30}
          >
            {draw(config)}
          </AttachmentName>
        )}
      </EditableAttachmentLabel>
    );
  }

  renderDeleteIcon() {
    return this.props.confirmOnDelete
      ? (
        <ConfirmationPopup
          trigger={<Icon className={iconStyle} name="mp-cancel" color={MEDIUM_GRAY_FILL} />}
          prompt={tr('removePrompt')}
          onConfirm={this.handleDelete}
        />
      ) : (
        <Icon className={iconStyle} name="mp-cancel" color={MEDIUM_GRAY_FILL} onClick={this.handleDelete} />
      );
  }

  render() {
    const pending = ['add', 'fax', 'update'].indexOf(this.props.attachment.pendingState) >= 0;
    const {
      disableLink,
      showResponseRequestedMenu,
      attachment: {
        meta: {
          unreadable = false,
        } = {},
      },
      showRightIcon,
    } = this.props;
    return (
      <Container data-testid="file-attachment">
        {this.renderLabel({ pending })}
        {this.props.showSource && this.props.attachment.source
          && !pending
          && <SourceIcon><Tag>{this.props.attachment.source}</Tag></SourceIcon>}
        {this.props.disableIcons && this.props.attachment.help && (
          <Help>{this.props.attachment.help}</Help>
        )}
        {showRightIcon && (
          <PDFStatus
            position="bottom"
            message={tr('documents.pdf_unreadable.attachment_card')}
          />
        )}
        {!this.props.disableIcons
          && (
            <IconContainer>
              {showResponseRequestedMenu
                && (
                  <ResponseRequestedMenu
                    selectedItems={this.props.selectedResponseRequestedItems}
                    onChange={this.props.onResponseRequestedCheckChange}
                    attachment={this.props.attachment}
                    iconClassName={iconStyle}
                  />
                )}
              {!pending && this.props.showSignedTag && <Tag color={RECEIVING_DARKER_FILL} textColor={WHITE}> {tr('signedTag')} </Tag>}
              {!pending && !disableLink && !unreadable && <Icon className={iconStyle} name="mp-printer" color={MEDIUM_GRAY_FILL} onClick={this.handlePrint} />}
              {!pending && (
                <GearMenu
                  disableScrolling
                  inline="block"
                  items={this.getMenuItems(unreadable)}
                  onClick={this.handleMenuItemClick}
                />
              )}
              {this.props.disableDelete || this.props.attachment.protected
                ? <EmptyIcon />
                : this.renderDeleteIcon()}
            </IconContainer>
          )}
      </Container>
    );
  }
}

export default withHoverState(Attachment);

import React from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { Helmet } from 'react-helmet';
import { createTranslator } from 'helpers/i18n';
import { withRouter } from 'react-router';

import { STR } from 'translations/menus';

const getTitleText = (text, secondary) => STR(text, 'title', secondary);

/* Maintain this array to connect base routes to generic titles.
   The last matching tuple in the array will be used. */
const routeTitles = [
  ['/', 'dashboard'],
  ['/referrals', 'referrals'],
  ['/referrals/\\d+', 'overview'],
  ['/referrals.*activity', 'activity'],
  ['/referrals.*provider-overview', 'provider_overview'],
  ['/referrals.*details', 'details'],
  ['/referrals.*providers', 'providers'],
  ['/referrals.*pdfs/list', 'patient_choice_pdf'],
  ['/referrals.*pdfs/overview', 'referral_overview_pdf'],
  ['/referrals.*pdfs/cover-sheet', 'fax_cover_sheet_pdf'],
  ['/referrals.*pdfs/dissemination', 'patient_dissemination_pdf'],
  ['/providers', 'providers'],
  ['/users/\\d+', 'profile'],
  ['/users/\\d+-.*', 'profile'],
  ['/providers/\\d+-.*', 'organization'],
  ['/hospitals/\\d+-.*', 'organization'],
  ['/about-badges', 'about.badges'],
  ['/admin', 'admin.index'],
  ['/admin/team', 'admin.team'],
  ['/admin/locations', 'admin.locations'],
  ['/admin/providers', 'admin.providers'],
  ['/admin/providers/.*', 'admin.providers'],
  ['/admin/purchases', 'admin.purchases'],
  ['/reports', 'reports'],
  ['/inbox', 'inbox.outbound'],
  ['/inbox/outbound', 'inbox.outbound'],
  ['/inbox/inbound', 'inbox.inbound'],
  ['/printers', 'printers'],
  ['/integrations', 'integrations'],
];

const tr = createTranslator({
  aidin: getTitleText('aidin'),
  activity: getTitleText('activity'),
  dashboard: getTitleText('dashboard'),
  details: getTitleText('details'),
  overview: getTitleText('overview'),
  provider_overview: getTitleText('provider_overview'),
  providers: getTitleText('providers'),
  profile: getTitleText('profile'),
  referrals: getTitleText('referrals'),
  patient_choice_pdf: getTitleText('patient_choice_pdf'),
  patient_dissemination_pdf: getTitleText('patient_dissemination_pdf'),
  referral_overview_pdf: getTitleText('referral_overview_pdf'),
  fax_cover_sheet_pdf: getTitleText('fax_cover_sheet_pdf'),
  about: {
    badges: getTitleText('badges'),
  },
  admin: {
    index: getTitleText('account'),
    team: getTitleText('team'),
    locations: getTitleText('locations'),
    providers: getTitleText('settings'),
    purchases: getTitleText('purchases'),
  },
  reports: getTitleText('reports'),
  inbox: {
    outbound: getTitleText('inbox', { bound: 'outbound' }),
    inbound: getTitleText('inbox', { bound: 'inbound' }),
  },
  printers: 'Printers',
  integrations: 'Integrations',
});

const Title = ({ title = null, location }) => {
  const titles = title
    ? [title]
    : [];
  titles.push(tr(routeTitles
    .filter(t => location.pathname.match(t[0]))
    .map(t => t[1])
    .pop()));
  titles.push('|');
  titles.push(tr('aidin'));
  return (
    <Helmet>
      <title>{ titles.join(' ') }</title>
    </Helmet>
  );
};

Title.propTypes = {
  /** Supply an explicit page title */
  title: PropTypes.string,
  /** Use location of current route */
  location: ReactRouterPropTypes.location.isRequired,
};

Title.defaultProps = {
  title: null,
};

export default withRouter(Title);

import * as types from './types';

export const setCurrentUser = user => dispatch => Promise.resolve(dispatch({
  type: types.LOGIN_USER,
  current_user: user,
}));

export const clearCurrentUser = () => dispatch => Promise.resolve(dispatch({
  type: types.LOGOUT_USER,
}));

export const setInvitedUser = (code, email, provider) => dispatch => Promise.resolve(dispatch({
  type: types.SET_INVITED_USER,
  code,
  email,
  provider,
}));

export const replaceSession = session => dispatch => Promise.resolve(dispatch({
  type: types.REPLACE_SESSION_DATA,
  session,
}));

export const updateFeatureFlags = featureFlags => dispatch => Promise.resolve(dispatch({
  type: types.UPDATE_FEATURE_FLAGS,
  featureFlags,
}));

export const replaceUser = user => dispatch => Promise.resolve(dispatch({
  type: types.UPDATE_USER,
  user,
}));

export const replacePreferences = (prefs, merge = true) => dispatch => Promise.resolve(dispatch({
  type: types.UPDATE_PREFERENCES,
  prefs,
  merge,
}));

export const replaceMetadata = (metadata, merge = true) => dispatch => Promise.resolve(dispatch({
  type: types.UPDATE_METADATA,
  metadata,
  merge,
}));

export const updateSystemAlerts = systemAlert => dispatch => Promise.resolve(dispatch({
  systemAlert,
  type: types.UPDATE_SYSTEM_ALERTS,
}));

export const removeSystemAlert = systemAlert => dispatch => Promise.resolve(dispatch({
  systemAlert,
  type: types.REMOVE_SYSTEM_ALERT,
}));

import React from 'react';
import PropTypes from 'prop-types';
import momentPropTypes from 'react-moment-proptypes';

import Popup from 'components/utils/Popup';
import withThemedComponents, { ThemeConfig } from 'components/utils/withThemedComponents';

import { PopupContent, Secondary } from 'components/utils/Styles';

import Day from './Day';
import {
  createComponentTheme,
  getPopupPosition,
} from './functions';

const CalendarDay = ({
  date,
  options: {
    color,
    border,
    backgroundColor,
    popup,
    borderRadius,
    width,
    height,
    customStyles,
  } = {},
  selected,
  theme,
}) => {
  const day = (
    <Day
      options={theme.styles}
      selected={selected}
      backgroundColor={backgroundColor}
      color={color}
      border={border}
      borderRadius={borderRadius}
      height={height}
      width={width}
      customStyles={customStyles}
    >
      {date.format('D')}
    </Day>
  );

  if (popup) {
    return (
      <Popup
        width={200}
        position={getPopupPosition(date)}
        triggerOn="click"
        trigger={day}
      >
        {({ close }) => (
          <PopupContent>
            <Secondary>
              {popup(close)}
            </Secondary>
          </PopupContent>
        )}
      </Popup>
    );
  }
  return day;
};

CalendarDay.propTypes = {
  date: momentPropTypes.momentObj.isRequired,
  options: PropTypes.shape({
    color: PropTypes.string,
    backgroundColor: PropTypes.string,
    border: PropTypes.string,
    popup: PropTypes.func,
  }),
  selected: PropTypes.bool,
  theme: ThemeConfig.isRequired,
};

CalendarDay.defaultProps = {
  options: undefined,
  selected: false,
};

export { Day };

export default withThemedComponents(createComponentTheme)(CalendarDay);

import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import { css } from 'emotion';
import styled from 'react-emotion';

import * as sessionActions from 'ducks/session';
import withThemedComponents, { ThemeConfig } from 'components/utils/withThemedComponents';
import { createTranslator, numeric } from 'helpers/i18n';

import { Responsive, mq } from 'components/utils/Responsive';
import {
  Panel, Link, Styles, ZendeskSessionLink,
} from 'components/utils';

import AidinLogo from 'components/branding/AidinLogo';
import AidinLogoWhite from './AidinLogoWhite';
import hipaaPng from './aptible-hipaa-badge-light.png';

const { contentStyle, Label, footerContentStyle } = Styles;

const tr = createTranslator({
  support: 'Aidin Support',
  termsOfService: 'Terms of Service',
  privacyPolicy: 'Privacy Policy',
  aidinInc: 'AIDIN, INC.',
  hipaa: 'HIPAA-Compliant - powered by Aptible',
});

const createComponentStyles = ({ version, theme: { colors } }) => numeric(version, {
  other: {
    styles: {
      FooterContent: styled.div`
        ${contentStyle}
        color: #ffffff;
        -webkit-font-smoothing: antialiased;
        a {
          color: #ffffff;
          -webkit-font-smoothing: antialiased;
        }
      `,
      FooterContainer: styled.div(mq({
        background: '#666666',
        position: 'absolute',
        bottom: '0px',
        width: '100%',
        left: '0px',
        right: '0px',
        height: ['150px', '250px', '250px'],
      })),
      ZendeskLink: styled.span`
        color: #ffffff;
        cursor: pointer;
        text-decoration: underline;
        -webkit-font-smoothing: antialiased;
      `,
      copyrightStyle: css`
        color: ${colors.WHITE};
        font-size: 14px;
      `,
      divStyle: css`
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 20px;
      `,
      ZendeskContainer: React.Fragment,
      AidinLogoContainer: () => (
        <div>
          <Link to="/">
            <AidinLogoWhite
              css="
                width: 150px;
                padding: 30px 0 10px;"
            />
          </Link>
        </div>
      ),
      VerticalBars: () => (<Bar>|</Bar>),
      SmallerFooter: ({
        smallerFooterContainerStyle, AidinLogoContainer, renderLinks, footerLinkStyle, renderCopyright, copyrightStyle, linkProps,
      }) => (
        <div css={smallerFooterContainerStyle}>
          <AidinLogoContainer />
          <div css="font-size: 13px;">
            {renderLinks({ ...linkProps })}
          </div>
          <div css="padding-top: 20px;">
            <HIPAA footerLinkStyle={footerLinkStyle} />
          </div>
          <div css="padding-top: 10px;">
            {renderCopyright({ copyrightStyle })}
          </div>
        </div>
      ),
    },
  },
  v2: {
    styles: {
      FooterContent: styled.div`
        ${footerContentStyle}
        color: ${colors.BLACK},
        -webkit-font-smoothing: antialiased;
        a {
          color: ${colors.BLACK};
          -webkit-font-smoothing: antialiased;
        }
      `,
      FooterContainer: styled.div(mq({
        background: colors.WHITE,
        position: 'relative',
        bottom: '0',
        marginTop: ['50px', '0px', '0px'],
        width: '100%',
        left: '0px',
        right: '0px',
        height: ['150px', '193px', '193px'],
      })),
      ZendeskLink: styled.span`
        color: ${colors.BLACK};
        cursor: pointer;
        text-decoration: underline;
        -webkit-font-smoothing: antialiased;
      `,
      ZendeskContainer: styled.span(mq({
        color: colors.BLACK,
        cursor: 'pointer',
        textDecoration: 'underline',
        '-webkit-font-smoothing': 'antialiased',
        width: ['400px', '100%', '100%'],
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: ['space-between', 'space-around', 'space-around'],
      })),
      copyrightStyle: css(mq({
        paddingTop: ['13px', 0, 0],
        color: colors.BLACK,
        fontSize: '14px',
      })),
      smallerFooterContainerStyle: css`
        width: 100%;
      `,
      footerLinkStyle: css(mq({
        float: ['unset', 'right', 'right'],
      })),
      AidinLogoContainer: () => (
        <div>
          <Link to="/">
            <AidinLogo
              css="
                width: 85px;
                height: 28px;"
            />
          </Link>
        </div>
      ),
      VerticalBars: React.Fragment,
      SmallerFooter: ({
        smallerFooterContainerStyle, AidinLogoContainer, renderLinks, renderCopyright, copyrightStyle, footerLinkStyle, linkProps,
      }) => (
        <Panel align="center" itemClassName={smallerFooterContainerStyle}>
          <AidinLogoContainer />
          <div css="font-size: 13px;">
            {renderLinks({ ...linkProps })}
          </div>
          <Panel orientation="horizontal" fullWidth itemClassName={smallerFooterContainerStyle} align="center">
            {renderCopyright({ copyrightStyle })}
            <HIPAA footerLinkStyle={footerLinkStyle} />
          </Panel>
        </Panel>
      ),
    },
  },
});

const FooterLink = styled.a`
  display: inline-block;
`;

const Bar = styled.span(mq({
  paddingLeft: ['1rem', '1rem', '0.5rem'],
  paddingRight: ['1rem', '1rem', '0.5rem'],
}));

const Links = ({
  user, activateZendeskModal, ZendeskContainer, ZendeskLink, VerticalBars,
}) => (
  <ZendeskContainer>
    {user.id ? <ZendeskSessionLink /> : <ZendeskLink onClick={activateZendeskModal}>{tr('support')}</ZendeskLink>}
    <VerticalBars />
    <FooterLink target="_blank" href="https://myaidin.com/terms.html">{tr('termsOfService')}</FooterLink>
    <VerticalBars />
    <FooterLink target="_blank" href="https://myaidin.com/privacy.html">{tr('privacyPolicy')}</FooterLink>
  </ZendeskContainer>
);

const HIPAA = ({ footerLinkStyle = undefined } = {}) => (
  <FooterLink
    target="_blank"
    href="https://www.aptible.com/secured-by-aptible?referral_id=$%7Bd75f17f0-5052-44a6-8194-2a49de8722cf%7D"
    className={footerLinkStyle}
  >
    <img
      src={hipaaPng}
      alt={tr('hipaa')}
      css="width: 130px;"
    />
  </FooterLink>
);

const renderCopyright = ({ copyrightStyle }) => (
  <Label css={copyrightStyle}>
    &copy; {moment().format('YYYY')} {tr('aidinInc')}
  </Label>
);

const renderLinks = ({
  user, activateZendeskModal, ZendeskContainer, ZendeskLink, VerticalBars,
}) => (
  <Links
    user={user}
    activateZendeskModal={activateZendeskModal}
    ZendeskContainer={ZendeskContainer}
    ZendeskLink={ZendeskLink}
    VerticalBars={VerticalBars}
  />
);

Links.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  activateZendeskModal: PropTypes.func.isRequired,
};

const SiteFooter = ({
  session,
  activateZendeskModal,
  theme: {
    styles: {
      FooterContainer,
      FooterContent,
      copyrightStyle,
      smallerFooterContainerStyle,
      footerLinkStyle,
      ZendeskContainer,
      ZendeskLink,
      AidinLogoContainer,
      VerticalBars,
      SmallerFooter,
    },
  },
}) => {
  const linkProps = {
    user: session.user, activateZendeskModal, ZendeskContainer, ZendeskLink, VerticalBars,
  };

  return (
    <FooterContainer className="no-print">
      <FooterContent>
        <Responsive size="large">
          <div
            css="
            display: flex;
            flex-direction: row;
            justify-content: space-between;
          "
          >
            <div>
              <AidinLogoContainer />
              <div css="padding-bottom: 30px">
                {renderLinks({ ...linkProps })}
              </div>
            </div>
            <div css="
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                padding-top: 30px;
              "
            >
              <div>
                <HIPAA />
              </div>
              {renderCopyright({ copyrightStyle })}
            </div>
          </div>
        </Responsive>
        <Responsive size={['medium', 'small']}>
          <SmallerFooter
            smallerFooterContainerStyle={smallerFooterContainerStyle}
            AidinLogoContainer={AidinLogoContainer}
            renderLinks={renderLinks}
            renderCopyright={renderCopyright}
            copyrightStyle={copyrightStyle}
            footerLinkStyle={footerLinkStyle}
            linkProps={linkProps}
          />
        </Responsive>
      </FooterContent>
    </FooterContainer>
  );
};

SiteFooter.propTypes = {
  session: PropTypes.shape({
    user: PropTypes.shape({
      id: PropTypes.number,
    }),
  }).isRequired,
  activateZendeskModal: PropTypes.func.isRequired,
  theme: ThemeConfig.isRequired,
};

export default compose(
  withThemedComponents(createComponentStyles),
  connect(
    ({ session }) => ({ session }),
    { ...sessionActions },
  ),
)(SiteFooter);

import React from 'react';
import PropTypes from 'prop-types';
import { createTranslator } from 'helpers/i18n';
import { connect } from 'react-redux';
import * as actions from 'ducks/session';

import {
  ContentPanel, Panel, Button, TextField, Colors, Styles,
} from 'components/utils';

const { RECEIVING_LIGHTEST_FILL, RECEIVING_DARKER_FILL } = Colors;
const { Secondary } = Styles;

const tr = createTranslator({
  title: 'Create an Account',
  prompt: 'Create a secure password in order to access your account in the future.',
  email: 'Your Email',
  password: {
    label: 'Create a password',
    help: 'Use at least 8 characters, 1 uppercase letter, 1 lowercase letter, 1 number, and 1 symbol.',
  },
  first: {
    label: 'First name',
  },
  last: {
    label: 'Last name',
  },
  submit: 'Create my Account',
  blankError: 'This field is required and can not be blank',
});

class CreateAccountForm extends React.Component {
  static propTypes = {
    history: PropTypes.func.isRequired,
    session: PropTypes.shape({
      pending_user: PropTypes.shape({
        email: PropTypes.string,
        provider: PropTypes.number,
      }),
    }).isRequired,
    signUpUser: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);
    const { session: { pending_user: { email } = {} } = {} } = props;
    this.state = {
      email, password: '', first: '', last: '', errors: {},
    };
  }

  handleSubmit = () => {
    const { session: { pending_user: { provider } = {} } = {} } = this.props;
    const {
      first, last, email, password,
    } = this.state;
    const errors = ['first', 'last', 'password'].reduce((e, key) => (!this.state[key] ? ({
      ...e,
      [key]: [tr('blankError')],
    }) : e), {});
    if (Object.keys(errors).length > 0) {
      this.setState({ errors });
    } else {
      const roles = provider ? ['provider'] : null;
      const profile = {
        first,
        last,
        roles,
      };
      const organization = {
        organization_id: provider,
        organization_type: provider ? 'Provider' : undefined,
        roles,
      };
      this.props.signUpUser(email, password, profile, organization)
        .catch((e) => {
          if (e.message === 'form_errors') {
            this.setState({ errors: e.response.entity });
          } else {
            this.setState({ errors: [e.message] });
          }
        });
    }
  }

  render() {
    if (!this.state.email) {
      this.props.history.replace('/devise_users/sign_in');
      return null;
    }
    return (
      <ContentPanel
        title={tr('title')}
        titleBackgroundColor={RECEIVING_LIGHTEST_FILL}
      >
        <Panel>
          <Secondary>{tr('prompt')}</Secondary>
          <div>
            <h4>{tr('email')}</h4>
            <div>{this.state.email}</div>
          </div>
          <TextField
            required
            autoFocus
            label={tr('first.label')}
            value={this.state.first}
            errors={this.state.errors.first}
            onChange={(first) => { this.setState({ first }); }}
          />
          <TextField
            required
            label={tr('last.label')}
            value={this.state.last}
            errors={this.state.errors.last}
            onChange={(last) => { this.setState({ last }); }}
          />
          <TextField
            required
            type="password"
            label={tr('password.label')}
            help={tr('password.help')}
            value={this.state.password}
            errors={this.state.errors.password}
            onChange={(password) => { this.setState({ password }); }}
            onSubmit={this.handleSubmit}
          />
          <Button small color={RECEIVING_DARKER_FILL} onClick={this.handleSubmit}>
            {tr('submit')}
          </Button>
        </Panel>
      </ContentPanel>
    );
  }
}

export default connect(state => state, actions)(CreateAccountForm);

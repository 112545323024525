export default {
  referrals: {
    overview: {
      checkInsuranceEligibility: "Aidin can now check online whether your patient's plan will be covered by the care you're seeking.<br/><br/>Checking is instantaneous, and gives you more security that their care is covered.",
      authorizeInsurance: "Aidin can now authorize your patient's insurance by sending this referral info directly to the patient's payors. Aidin will start a process similar to sending to providers, but will wait until your provider is reserved to ask them to approve or decline their coverage.",
      precertAuthorize: "Aidin can submit your authorizaiton request for this patient's care.",
      allowFeedback: 'Aidin follows up with patients 30 days after their treatment ends to learn about their satisfaction with their stay. Aidin uses those reviews to create the Patient Satisfaction badge, improving your provider search quality for future referrals.',
      providerStatus: "Aidin can use the providers' activity to determine their status. The possible options are: <br/><br/>- Unopened<br/>- Undelivered: Aidin had an issue with delivering it to their current contact info<br/>- Reviewing: They've opened the referral, but haven't responded yet<br/>- Available<br/>- Unavailable<br/>- Removed: They've asked to be removed from the referral and all future updates",
    },
    providerSearch: {
      aidinScore: 'Aidin calculates the best provider for your patient based on qualitative categories.<br/><br/>Click a score to compare providers.',
      lastActive: "This is the last time anyone from this provider logged in to Aidin. If they've never logged in, you can invite them to join.",
      removed: 'This provider has been removed from this referral and will no longer receive updates about it.',
      idealProviderCount: "Aidin recommends this number of providers based on what we've observed on successful referrals in the past for the level of care you've chosen. <br/><br/>Generally, too few providers limits the patient's choice, and too many providers makes the choice overwhelming for the patient.",
      preSelectedFeaturedBadge: 'Aidin has pre-selected this provider because it fits your search parameters and is one of the best results.',
      referralBadges: 'These badges are earned based on how the provider compares to other available options on this specific referral.',
      providerBadges: 'These badges are the same for a provider on every referral, and are based on how they are doing as a provider regardless of referral.',
      providerNetworks: 'Your leadership team may have set up provider networks that will override the Aidin filters (area, care types, insurances) and recommend the providers in the network.<br /><br />A "hard network" will default to in-network only providers, where a regular network will always include in-network providers as well as other eligible providers.',
      providerDetailsOverridden: 'The hospital system has overridden all your settings preferences to send you this referral.',
    },
    referralDetails: {
      checkForPatientAuthorization: "Aidin can now authorize your patient's insurance by sending this referral info directly to the patient's payors. Aidin will start a process similar to sending to providers, but will wait until your provider is reserved to ask them to approve or decline their coverage.",
      addPayorsToThisReferral: 'If you have individual insurance authorizors that you would like to add to this referral, you can add them here.',
    },
    receivingReferralsOverview: {
      takeInCare: "Aidin knows that start of service dates tend to change during the patient's stay, but use this date to give your best assessment of whether you could provide the patient's care.",
      clinicallyComplexPatients: 'These patients have been marked clinically complex by their care team, perhaps because their clinical needs require care beyond the standard level—[contact the care team](action:send_message) for more details. <br/><br/> Aidin rewards your team for providing care for these patients by awarding you the Accepts Clinically Complex Cases badge and boosting you in the provider search results.',
      financiallyComplexPatients: 'These patients have been marked financially complex by their care team, perhaps because their insurance is one less often supported—[contact the care team](action:send_message) for more details. <br/><br/> Aidin rewards your team for providing care for these patients by awarding you the Accepts Clinically Complex Cases badge and boosting you in the provider search results.',
      intakeHomeServiceStartOfCare: "This is set based on the date the care team suggested when sending you the referral. Update according to your team's assessment of when the patient will begin their care. <br/><br/>If you don't know the answer, Aidin recommends waiting to complete intake until you can confirm when the patient will begin care.",
      intakeHomeServiceLOS: "Expected Length of Stay is how long (in days) your organization expects to care for this patient. <br/><br/>This is a new requirement for following patients' continuing care. <br/><br/>Take your best guess and Aidin will check in with you when this date arrives. If you don't know the answer, Aidin recommends setting a short LOS that will bring it back to your dashboard when you have more information.",
      aidinScore: 'Aidin uses the Provider Badges to compare possible provider options for a patient to each other.<br/><br/>The higher your Aidin Score the better match this patient is for your services and the better ranking you will have when compared against your peers.',
    },
    intake: {
      startOfCareInfo: 'This is set based on the date the care team suggested when sending you the referral. Update according to your team\'s assessment of when the patient will begin their care.<br/><br/>If you don\'t know the answer, Aidin recommends waiting to complete intake until you can confirm when the patient will begin care.',
      lengthOfStayInfo: 'Expected Length of Stay is how long (in days) your organization expects to care for this patient. <br/><br/>This is a new requirement for following patients\' continuing care.<br/><br/>Take your best guess and Aidin will check in when this date arrives. If you don\'t know the answer, Aidin recommends setting a short LOS that will bring it back to your dashboard when you have more information.',
      intakeDateInfo: 'Aidin is committed to tracking population health, so we ask you to provide which date the patient started care.',
    },
    attachmentRole: {
      receiving: 'This document was uploaded by the reserved provider on this referral.',
      pendingAttachViaFax: 'Please delete and try again if attachment does not appear 1 hour after faxing',
    },
  },
  users: {
    badges: {
      earnSendingBadges: 'You can earn these badges by following best practices when creating and sending referrals.',
      earnReceivingBadges: 'You can earn these badges by following best practices when responding to referrals.',
      suggestCustomBadges: 'Give a provider a high-five! Have an idea for a great badge you\'d like to reward? Let us know!',
    },
    locations: {
      admin: `
The Aidin Admin role allows you to do a few additional tasks:
<br/><br/>
* Manage your provider networks (for sending referrals)
* Manage your team and team memberships
* View your organization's reporting suite
      `,
      view_only: 'View-only access allows users to view patients within Aidin, but they are not allowed to make any patient changes.',
    },
    roles: {
      view_only: 'View-only access allows you to view patients within Aidin, but you are not allowed to make any patient changes.<br/><br/>If you would like to make patient changes, please contact your team admin to gain additional access.',
    },
  },
  providers: {
    badges: {
      earnProviderBadges: 'These badges are earned when the Provider\'s team follows best practices when responding to referrals.',
      suggestCustomBadges: 'Give a provider a high-five! Have an idea for a great badge you\'d like to reward? Let us know!',
    },
    quality: {
      medicare_score: 'Medicare assigns a star rating based on each provider\'s performance across a number of factors: quality, health inspections, staffing, and more. The star rating provides an indicator of a provider\'s performance compared to other providers. More stars indicate better performance.',
      readmission_rate: ({ noData } = {}) => `This is the percentage of patients who received care from this provider and had to return to a hospital within 30 days of their previous hospitalization.<br/><br/>Aidin also tracks readmissions in real-time for patients similar to you.${noData ? '<br/><br/>We are still collecting data on this provider.' : ''}`,
      medicare_outcome_score: 'This is the percentage of patients who received care from this provider and had to return to a hospital within 30 days of their previous hospitalization.<br/><br/>Medicare\'s readmission rate is calculated annually based on the performance of other Medicare patients.',
      patient_rating: ({ noData } = {}) => `This is the percentage of patients who would recommend this provider to friends or family.<br/><br/>Aidin conducts independent surveys of all patients in real-time. We hope you consider providing your voice to the Aidin survey process.${noData ? '<br/><br/>We are still collecting data on this provider.' : ''}`,
      medicare_satisfaction_score: 'This is the percentage of patients who would recommend this provider to friends or family.<br/><br/>Medicare\'s patient satisfaction is calculated annually based on surveys completed from other Medicare patients.',
      medicare_discharge_to_community_rate: 'This is the percentage of patients who received care from this provider and were safely discharged home after their care was completed. Medicare’s DTC rate is calculated annually and is a measure of resource utilization.',
      expected_los_met_rate: 'This conveys how closely the length of stay at this provider matched the provider\'s best guess for how long they expect the patient to stay.',
      response_rate_total: 'This includes referrals that never made it to completed. Aidin wants to give you credit for as many responses as possible, even if the patient didn\'t end up needing care.',
      response_rate: messageVariables => `
This is the percentage of how often this provider has responded to referrals before the deadline set by the sender within the last 90 days. Responding to referrals on time ensures that patients can be discharged on time. Your score is not negatively impacted when a referral is closed early or cancelled.
<br/><br/>
${!messageVariables.no_data
    ? `* Referrals received in the last 90 days: **${messageVariables.total}** [?](#help:providers.quality.response_rate_total)
* Referrals ${messageVariables.subject} responded to before the deadline passed: **${messageVariables.on_time}**
  * Referrals ${messageVariables.subject} didn't respond to: **${messageVariables.unresponded}**
  * Referrals ${messageVariables.subject} responded late to: **${messageVariables.overdue}**
  <br/><br/>

  Percentage: **${messageVariables.on_time}/${messageVariables.total} = ${messageVariables.response_rate}%** Responds on time rate`
    : 'We are still collecting data on this provider.'}`,
    },
    qualitySummary: {
      medicare_score: 'Medicare assigns a star rating based on each provider\'s performance across a number of factors: quality, health inspections, staffing, and more. The star rating provides an indicator of a provider\'s performance compared to other providers. More stars indicate better performance.',
      readmission_rate: 'This is the percentage of patients who received care from this provider and had to return to a hospital within 30 days of their previous hospitalization.<br/><br/>Medicare\'s readmission rate is calculated annually based on the performance of other Medicare patients. Aidin, the service that helps you find providers, also tracks readmissions in real-time for patients similar to you.',
      patient_rating: 'This is the percentage of patients who would recommend this provider to friends or family.<br/><br/>Medicare\'s patient satisfaction is calculated annually based on surveys completed from other Medicare patients. Aidin, the service that helps you find providers, conducts independent surveys of all patients in real-time. We hope you consider providing your voice to the Aidin survey process.',
      discharge_to_community: 'This is the percentage of patients who received care from this provider and were safely discharged home after their care was completed. Medicare’s DTC rate is calculated annually and is a measure of resource utilization.',
    },
    provider_number: {
      info: 'If you do not know your Medicare ID Number (CCN), go to this website [https://data.cms.gov/provider-data/](https://data.cms.gov/provider-data/), select the appropriate organization type, and search for your organization.',
    },
  },
  features: {
    document_reader: {
      about: `
The Aidin Document Scanner will securely scan any incoming documents for:
<br /><br />
* Patients visiting the selected locations
* Connections to your existing referrals
* Action items to take, such as reservations or authorizations.
      `,
    },
    verified_admin: {
      aboutJumio: 'Your identification details are transferred directly and security to Jumio Go and only used for the purposes of verification. Aidin never handles your identity information, and neither Aidin nor Jumio store any of your identity information.',
      aboutPersona: 'Your identification details are transferred directly and security to Persona and only used for the purposes of verification. Aidin never handles your identity information, and neither Aidin nor Persona store any of your identity information.',
    },
  },
  documents: {
    pdf_unreadable: {
      inbox: {
        title: 'Fax Upload Status',
        message: 'Upload successful for online viewing only. This file format will not print or fax',
      },
      attachment_card: 'Upload successful for online viewing only. This file format will not print or fax.',
    },
  },
};

/* eslint-disable camelcase, react/jsx-props-no-spreading */

import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import styled from 'react-emotion';
import { createTranslator } from 'helpers/i18n';
import Message from 'models/Message';
import Referral from 'models/Referral';
import Session from 'models/Session';
import { FEATURE_UPGRADE_RELEASE_2 } from 'ducks/session/types';
import { Modal } from 'components/utils';
import withAvailableThemes from 'routes/withAvailableThemes';
import withThemeProvider from 'components/utils/withThemeProvider';
import ConversationContainer from './ConversationContainer';
import MessageBubble from './MessageBubble';

const tr = createTranslator({
  title: name => `Conversation with ${name}`,
  referral: {
    patient: ({ name }) => name,
    reference_number: id => `Ref #: ${id}`,
  },
  practice: 'Thanks for testing out messaging. On your real referrals, your provider communication will all happen here. Happy practicing!',
});

const MessagesContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const PracticeContainer = styled.div`
  margin: 20px 20px 40px;
`;

class ConversationModal extends React.Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    session: Session.isRequired,
    sendMessage: PropTypes.func.isRequired,
    thread: PropTypes.shape({
      id: PropTypes.number,
      organizations: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        type: PropTypes.oneOf(['Hospital', 'Provider']),
      })),
      patient: PropTypes.shape({
        name: PropTypes.string,
        hospital: PropTypes.shape({
          name: PropTypes.string,
        }),
      }).isRequired,
      referral: Referral,
      messages: PropTypes.arrayOf(Message).isRequired,
    }),
  }

  static defaultProps = {
    thread: undefined,
  }

  componentDidUpdate = (prevProps) => {
    if (!prevProps.thread && this.props.thread) {
      this.scrollThreadToBottom();
    }

    const prevMessages = (prevProps.thread && prevProps.thread.messages) || [];
    const messages = (this.props.thread && this.props.thread.messages) || [];

    if (prevMessages.length !== messages.length) {
      this.scrollThreadToBottom();
    }
  }

  scrollThreadToBottom = () => {
    setTimeout(() => {
      if (this.scrollContainer && this.scrollContainer.scrollToBottom) {
        this.scrollContainer.scrollToBottom();
      }
    }, 100);
  }

  getOrganizations = () => this.props.session.organizations;

  getMyOrganization = () => {
    const myOrgs = this.getOrganizations();
    const orgs = this.props.thread.organizations;
    return orgs.find(org => (
      myOrgs.some(({ id, organization_type }) => org.id === id && org.type === organization_type)
    ));
  }

  getOtherOrganization = () => {
    const myOrgs = this.getOrganizations();
    const sendingOrg = this.getMyOrganization();
    const { type: myOrgType } = sendingOrg || {};
    const orgs = this.props.thread.organizations.sort((a, b) => {
      const { type: aType } = a;
      const { type: bType } = b;
      if (aType === bType) { return 0; }
      if (aType !== myOrgType) { return -1; }
      if (bType !== myOrgType) { return 1; }
      return 0;
    });
    const otherOrg = orgs.find(org => (
      !myOrgs.some(({ id, organization_type }) => org.id === id && org.type === organization_type)
    ));
    return otherOrg || orgs[0];
  }

  getTitle = () => {
    const org = this.getOtherOrganization();
    return org && org.name;
  }

  // TODO: pop confirmation?
  handleMessageSent = () => { }

  renderPractice = () => (
    <PracticeContainer>
      {tr('practice')}
    </PracticeContainer>
  );

  renderBody = (messages, me, isUs) => (
    <MessagesContainer>
      {messages.map(message => (
        <MessageBubble
          key={message.id}
          message={message}
          mine={message.message_content.user && message.message_content.user.id === me}
          ours={isUs(message.message_content)}
        />
      ))}
    </MessagesContainer>
  );

  render() {
    if (this.props.thread === null) { return null; }
    const { referral, messages } = this.props.thread;
    const myOrgs = this.getOrganizations();
    const isUs = ({ organization: { id }, organization_type }) => (
      myOrgs.some(o => o.id === id && o.organization_type === organization_type)
    );
    const me = this.props.session.user.id;

    return (
      <Modal
        title={tr('title', this.getTitle())}
        open={!!this.props.thread}
        onClose={this.props.onClose}
      >
        {({ close }) => (
          <ConversationContainer
            ref={(ref) => { this.scrollContainer = ref; }}
            body={referral.practice === 'sending_alone' ? this.renderPractice() : this.renderBody(messages, me, isUs)}
            onMessageSent={this.handleMessageSent}
            sender={this.getMyOrganization()}
            recipient={this.getOtherOrganization()}
            sendMessage={referral.practice === 'sending_alone' || referral.practice === 'receiving'
              ? () => Promise.resolve(true) : this.props.sendMessage}
            session={this.props.session}
            referral={referral}
            close={close}
            thread={this.props.thread}
          />
        )}
      </Modal>
    );
  }
}

export default compose(
  withAvailableThemes(FEATURE_UPGRADE_RELEASE_2),
  withThemeProvider,
)(ConversationModal);

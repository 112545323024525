class KV {
  constructor({ timeout }) {
    this.timeout = timeout;
    this.data = {};
  }

  set(key, value, timeout) {
    this.data[key] = value;
    if (timeout || this.timeout) {
      setTimeout(() => { delete this.data[key]; }, timeout || this.timeout);
    }
  }

  get(key) {
    return this.data[key];
  }
}

export default KV;

const badges = {};
try {
  const context = require.context('./', true, /\.svg$/);

  context.keys().forEach((file) => {
    const Icon = context(file).default;
    const label = file.slice(2, -4); // strip './badge-' and '.js'
    badges[label] = Icon;
    return badges;
  }, {});
} catch (e) {
  // TODO: Add mocks to support this in a testing environment
}

export default badges;

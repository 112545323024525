import React from 'react';
import PropTypes from 'prop-types';
import { createTranslator } from 'helpers/i18n';
import Message from 'models/Message';
import { connect } from 'react-redux';
import * as actions from 'ducks/messages';
import { confirmationToast, errorToast } from 'helpers/ui';
import Session from 'models/Session';
import {
  GearMenu,
} from 'components/utils';

const tr = createTranslator({
  menu: {
    unread: 'Mark as unread',
  },
  toast: {
    confirm: 'Conversation marked as unread',
    error: 'There was an error while updating your conversation. Please try again later.',
  },
});

const isActionableForMarkAsUnread = ({
  session: { organizations = [] } = {},
  thread: { messages = [] } = {},
}) => {
  if (messages.length === 0) { return false; }
  const message = messages[messages.length - 1];
  const {
    organization: {
      id: recipientId,
    } = {},
    organization_type: recipientType,
  } = message;
  const isMessageToMe = organizations.some(({ id, organization_type: type }) => (
    id === recipientId && type === recipientType
  ));
  return isMessageToMe;
};

const ConversationContextMenu = (props) => {
  const handleMarkAsUnread = () => {
    const {
      session: { user } = {},
      thread: { messages = [] } = {},
    } = props;
    if (!messages.length) return;

    /**
     * Getting messages not sent by me
     */
    const receivedMessages = messages.filter(
      message => message.message_content.user && message.message_content.user.id !== user.id,
    );

    /**
     * Getting messages sent by me
     */
    const sentMessages = messages.filter(
      message => message.message_content.user && message.message_content.user.id === user.id,
    );

    const messageId = receivedMessages.length ? receivedMessages.pop().id : sentMessages[0].id;

    if (messageId) {
      props.batchUpdateMessages([messageId], 'unread').then(json => {
        if (json.status === 200) {
          confirmationToast(tr('toast.confirm'));
          props.requestMessageUnreadCount();
        } else {
          errorToast(tr('toast.error'));
        }
      });
    }
    props.closePopup();
  };

  const menuItems = [
    {
      text: tr('menu.unread'),
      filter: isActionableForMarkAsUnread,
    },
  ].filter(({ filter }) => !filter || filter(props));

  if (menuItems.length === 0) {
    return null;
  }

  return (
    <GearMenu
      menuPosition="bottom right"
      items={menuItems}
      onClick={(item) => { handleMarkAsUnread(item); }}
    />
  );
};

ConversationContextMenu.propTypes = {
  closePopup: PropTypes.func.isRequired,
  batchUpdateMessages: PropTypes.func.isRequired,
  requestMessageUnreadCount: PropTypes.func.isRequired,
  thread: PropTypes.shape({
    id: PropTypes.number,
    messages: PropTypes.arrayOf(Message).isRequired,
  }),
  session: Session,
};

ConversationContextMenu.defaultProps = {
  thread: undefined,
  session: undefined,
};

const connectedState = (state) => ({
  session: state.session,
});

export default connect(connectedState, actions)(ConversationContextMenu);

import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'emotion';

import PrintContext from 'components/utils/PrintContext';
import Panel from 'components/utils/Panel';
import SplitPanel from 'components/utils/SplitPanel';
import { Label } from 'components/utils/Styles';

import DefinitionList from 'components/referrals/details/DefinitionList';

const itemClassName = css`
  flex-shrink: 0;
`;

/**
 * A very opinionated `SplitPanel` that uses text labels for headings and
 * displays each of its children as a child of the `SplitPanel`. For print
 * mode, it changes to a definition list.
 */
const SplitHeaderPanel = ({
  basis,
  children,
  headerElement,
  headers = [],
  pdfTitles,
  spacing,
}) => {
  const Header = headerElement || Label;
  const flexBasis = typeof basis === 'number' ? `${basis}%` : basis;
  const availableChildren = React.Children.toArray(children);
  const terms = pdfTitles || headers;
  return (
    <PrintContext.Consumer>
      {context => (context === 'pdf'
        ? (
          <DefinitionList
            terms={terms}
            labelRenderer={header => header}
            valueRenderer={(_, idx) => (
              <Panel itemClassName="PanelPaddingBottom10">
                {pdfTitles && <strong>{headers[idx]}</strong>}
                {availableChildren[idx]}
              </Panel>
            )}
          />
        )
        : (
          <Panel spacing={spacing}>
            {headers && headers.length > 0 && (
              <SplitPanel itemClassName={itemClassName} basis={flexBasis} align="bottom">
                {headers.filter(h => !!h).map(header => <Header key={header}>{header}</Header>)}
              </SplitPanel>
            )}
            <SplitPanel itemClassName={itemClassName} basis={flexBasis}>
              {availableChildren.filter(child => !!child).map(child => child)}
            </SplitPanel>
          </Panel>
        ))}
    </PrintContext.Consumer>
  );
};

SplitHeaderPanel.propTypes = {
  /**
   * The width of each child. Defaults to 45%. Takes a number or a string.
   * Numbers are interpreted as percentages.
   */
  basis: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /** The children. Generally it is expected there are two children to split. */
  children: PropTypes.node.isRequired,
  /** The wrapper for the header. Defaults to Label */
  headerElement: PropTypes.oneOfType([PropTypes.func, PropTypes.string, PropTypes.element]),
  /** The headers for the panel. */
  headers: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string, PropTypes.node, PropTypes.func,
  ])),
  /**
   * PDF Definition List titles. This will push headers content to
   * the definition section. Uses headers by default
   */
  pdfTitles: PropTypes.arrayOf(PropTypes.string),
  /** Spacing between the headers and the content. Defaults to 4px */
  spacing: PropTypes.number,
};

SplitHeaderPanel.defaultProps = {
  basis: '45%',
  headerElement: Label,
  headers: [],
  pdfTitles: undefined,
  spacing: 4,
};

export default SplitHeaderPanel;

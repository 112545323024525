/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { numeric } from 'helpers/i18n';

import NotFound from 'pages/NotFound';

export const REQUIRE_ANY_FEATURES = 'any';
export const REQUIRE_ALL_FEATURES = 'all';

const DEFAULT_MATCH_MODE = REQUIRE_ALL_FEATURES;

const withFeatureFlagRequired = (config, DisabledComponent = NotFound) => EnabledComponent => ({
  features,
  ...passThrough
}) => {
  const configuration = typeof config === 'string' ? { flags: [config] } : config;
  const { flags = [], match = DEFAULT_MATCH_MODE } = configuration;
  const isFeatureEnabled = numeric(match, {
    [REQUIRE_ALL_FEATURES]: flags.every(item => features[item]),
    [REQUIRE_ANY_FEATURES]: flags.some(item => features[item]),
    other: false,
  });
  const Component = isFeatureEnabled ? EnabledComponent : DisabledComponent;
  return <Component isFeatureEnabled={isFeatureEnabled} {...passThrough} />;
};

const createFeatureFlagComponent = (flag, DisabledComponent = NotFound) => compose(
  connect(({ session: { features } = {} }) => ({ features })),
  withFeatureFlagRequired(flag, DisabledComponent),
);

/**
 * Basic HOC to render a component based on whether a given feature is enabled
 * for the logged-in user. If the feature is not present, the DisabledComponent
 * will render, which default to the NotFound page component. You can pass your
 * own component as a second argument if you wish to override this behavior.
 * Regardless, the child component will receive an `isFeatureEnabled` prop,
 * which can be used to check this state as well.
 *
 * Note that the first argument can be either a string (flag name) or an object
 * containing:
 * - `flags`: an array of feature flag names to check for
 * - `match`: the mode to match; either "any" or "all".
 */
export default createFeatureFlagComponent;

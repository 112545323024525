import React from 'react';
import PropTypes from 'prop-types';

import Field, { fieldPropTypes } from 'components/utils/Field';
import parseRelativeTime from 'components/utils/ParseRelativeTime';
import TextFieldBase from 'components/utils/TextFieldBase';

import { Date } from 'helpers/dates';

import momentPropTypes from 'react-moment-proptypes';

/**
 * Date field that parses text based on absolute and relative times,
 * and human-parsed text, based on the ParseRelativeTime utility.
 */
class SemanticDateTextField extends React.Component {
  static propTypes = {
    ...fieldPropTypes,
    /**
     * The default value to display. This is an uncontrolled field so
     * only default can be specified
     */
    defaultValue: PropTypes.string,
    /** Change event returns a valid date when typed */
    onChange: PropTypes.func,
    /** Input returns any text, and a boolean, true when date is valid, false otherwise. */
    onInput: PropTypes.func,
    /** Time this date is relative to. Defaults to now. */
    relativeTo: momentPropTypes.momentObj,
  };

  static defaultProps = {
    defaultValue: undefined,
    onChange: undefined,
    onInput: undefined,
    relativeTo: undefined,
  };

  constructor(props) {
    super(props);
    this.state = { text: '' };
    this.timer = null;
  }

  checkDate = (text = this.state.text) => {
    const { onChange, onInput } = this.props;
    const relativeTo = this.props.relativeTo || Date.now();
    if (onChange || onInput) {
      const date = parseRelativeTime(relativeTo, text.toLowerCase(), relativeTo);
      if (date.isValid()) {
        if (this.props.onChange) {
          this.props.onChange(date);
        }
      }
      if (this.props.onInput) {
        this.props.onInput(text.toLowerCase(), date.isValid());
      }
    }
    this.timer = null;
  }

  /**
   * Manually focus the field
   * @public
   */
  focus = () => {
    if (this.input) { this.input.focus(); }
  }

  /**
   * Manually select the field
   * @public
   */
  select = () => {
    if (this.input) { this.input.select(); }
  }

  handleInput = (text) => {
    this.setState({ text }, () => {
      this.debounce(this.checkDate);
    });
  }

  handleKeyDown = (original) => (e) => {
    e.persist();
    if (e.keyCode === 13 || e.key === 'Enter') {
      this.checkDate(e.target.value);
    }
    if (original) {
      Promise.resolve(e).then(original); // next tick
    }
  }

  debounce = (func) => {
    if (this.timer) {
      clearTimeout(this.timer);
    }
    this.timer = setTimeout(func.bind(this), 500);
  }

  reset = () => {
    this.setState({ text: '' });
  }

  render() {
    const {
      defaultValue, onKeyDown, onChange, onInput, ...other
    } = this.props;
    return (
      <TextFieldBase
        ref={(ref) => { this.input = ref; }}
        {...other}
        onKeyDown={this.handleKeyDown(onKeyDown)}
        onChange={this.handleInput}
        value={this.state.text || defaultValue}
      />
    );
  }
}

export default Field(SemanticDateTextField);

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion';
import Notification from 'models/Notification';

import { LIGHTEST_GRAY_FILL, UNREAD, UNREAD_HOVER } from 'components/utils/Colors';
import RelativeTime, { shortFormatter } from 'components/utils/RelativeTime';


const Container = styled.div`
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${props => props.unread && `background-color: ${UNREAD};`}
  &:hover {
    background-color: ${props => (props.unread ? UNREAD_HOVER : LIGHTEST_GRAY_FILL)};
  };
`;

const Column = styled.div`
  flex-basis: ${props => props.width}px;
`;

const Text = styled.div`
  color: #333333;
  font-size: 15px;
  line-height: 21px;
  width: 230px;
`;

const TimeSent = styled.div`
  color: #666666;
  font-size: 12px;
  line-height: 15px;
  text-align: right;
  white-space: nowrap;
`;

const NotificationListItem = ({ onClick, notification }) => (
  <Container
    onClick={onClick}
    unread={!notification.read_flag}
  >
    <Column width={230}>
      <Text>{notification.text}</Text>
    </Column>
    <Column width={50}>
      <RelativeTime
        value={notification.created_at}
        formatter={shortFormatter}
        element={TimeSent}
      />
    </Column>
  </Container>
);

NotificationListItem.propTypes = {
  onClick: PropTypes.func.isRequired,
  notification: Notification.isRequired,
};

export default NotificationListItem;

/*
  Ruleset for conditional Rollbar alerts. The idea is that this could be a
  JSON manifest posted externally or shared between client and server.
*/

const rules = [
  {
    key: 'SessionExpired',
    name: 'Limit reports of expired user sessions',
    match: {
      message: '.*session expired*',
    },
    limit: 1,
    after: 10000, // So, hopefully never :)
    every: 10000,
  },
  /* Example usage
  {
    key: 'bazOfUndefined',
    name: 'Limit reports dereferencing baz',
    match: {
      message: 'property.*baz.*undefined', // this will be interpreted as a regex
      // we could add other fields. the mechanism in Rollbar's custom fingerprinter
      // is flexible, but actually pretty slow and hard to get right. just doing
      // regex scans in useful places would be helpful.
    },
    limit: 3, // if defined, max times it will report to Rollbar each session
    after: 2, // if defined, how many times it has to happen before a Rollbar report
    every: 5, // rate limit Rollbar reports to every n occurrences
  },
  */
];

export default rules;

import { createTranslator, DEFAULT_LOCALE } from 'helpers/i18n';

import en from './en/badges';
import es from './es/badges';

const translations = { en, es };

const tr = createTranslator(en, translations);

const helperTr = (key, badgeInfo, { isMine, locale = DEFAULT_LOCALE } = {}) => (
  tr({ key, locale }, badgeInfo, isMine)
);

/**
 * Helper function to retrieve a badge name given an awarded badge.
 * The awarded badge should contain a key and state.
 */
const trBadgeName = ({ key, state }, locale) => helperTr(`${key}.name`, { state }, { locale });

/**
 * Helper function to retrieve a badge description given an awarded badge.
 * The awarded badge should contain a key and state.
 */
const trBadgeDescription = ({ key, state }, isMine, locale) => helperTr(`${key}.description`, { state }, { isMine, locale });

/**
 * Helper function to retrive badge metrics given an awarded badge, if
 * they are available. Will contain the title and items.
 */
const trBadgeMetrics = ({ key }, locale) => helperTr(`${key}.metrics`, {}, { locale });

export {
  trBadgeName,
  trBadgeDescription,
  trBadgeMetrics,
};

export const translateBadges = locale => translations[locale] || translations[DEFAULT_LOCALE];

export default translations[DEFAULT_LOCALE];

/* eslint-disable no-nested-ternary */

import React from 'react';
import { connect } from 'react-redux';
import Session, { getOrganizationUsers } from 'models/Session';

import AutocompleteField, { PropertyFilter } from 'components/utils/AutocompleteField';

const TeamMemberSelectField = (props) => {
  const {
    session: { organizations = [] } = {},
    ...passThrough
  } = props;

  const items = getOrganizationUsers(organizations)
    .filter(u => !u.previewer)
    .map(({ id, name = '', email = '' }) => ({
      label: name,
      value: id,
      compare: (name || '').toLowerCase(),
      email,
    }))
    .sort((a, b) => (a.compare === b.compare ? 0 : a.compare < b.compare ? -1 : 1));

  return (
    <>
      <AutocompleteField
        {...passThrough}
        filter={PropertyFilter('label', 'email')}
        items={items}
      />
    </>
  );
};
TeamMemberSelectField.propTypes = {
  ...AutocompleteField.propTypes,
  /** The session, containing your organization with users */
  session: Session.isRequired,
};

export default connect(({ session }) => ({ session }), null)(TeamMemberSelectField);

import moment from 'moment';
import momentTimeZone from 'moment-timezone';

/**
 * Wrapper for handling client-side date formatting and parsing
 * given a type of date object.
 */
class DateTimeFormatter {
  /**
   * Takes any number of strings representing accepted parsing
   * formats for this date type. The first one will be the
   * default format.
   */
  constructor(...formats) {
    this.formats = formats;
    this.defaultFormat = formats[0]; // eslint-disable-line prefer-destructuring
    this.precision = 'millisecond';
    this.localize = true;
    return this;
  }

  /**
   * Format to display the current day in MMMM Do format. If the
   * year does not match the current year, use M/D/YY format.
   */
  dayFormat = (value, formatOptions = {}) => {
    const options = {
      format: 'MMMM Do',
      otherFormat: 'M/D/YY',
      ...formatOptions,
    };
    let date = typeof value === 'string'
      ? this.parse(value) : moment(value);
    if (this.localize) { date = date.local(); }
    const now = this.now();
    return date.year() === now.year()
      ? date.format(options.format)
      : date.format(options.otherFormat);
  }

  /**
   * Format the given value (a moment-like object) using the given format,
   * or the default format if none given. This should display in the user's
   * current time zone.
   */
  format = (value, fmt) => {
    let date = moment(value);
    if (this.localize) { date = date.local(); }
    return date.format(fmt || this.defaultFormat);
  }

  /**
   * Convert a UTC date to the local time zone.
   */
  local = value => (
    typeof value === 'string' ? this.parse(value).local() : value.local()
  );

  /**
   * Parse the given value using the set of understood formats for this
   * formatter, and return the date as a moment. Supply a defaultValue as
   * the second param if you want to ensure a non-null date.
   *
   * The time zone will be presumed to be UTC (from the server). It will
   * remain that way until format is called; if you use some other formatter,
   * remember to convert it first!
   *
   * Note: the defaultValue should be a local value.
   */
  parse = (value, defaultValue = null) => {
    if (!value) { return defaultValue; }
    const date = this.clearDateTime(moment.utc(value, this.getSupportedFormats()));
    return this.localize ? date.local() : date;
  }

  parseStrict = (value) => {
    if (!value) { return null; }
    const parsedDate = moment.utc(value, this.getSupportedFormats(true), true);
    if (parsedDate.isValid()) {
      return this.parse(value);
    }
    return null;
  }

  /**
   * Use this when writing local times to JSON. It will convert them to UTC
   */
  utc = (value, fmt) => moment(value).utc().format(fmt || this.defaultFormat);

  /**
   * TODO: The concept of now should be based on user preference,
   * etc. For now, use client time, but all references to "now"
   * should flow through this function so things can be converted
   * properly later.
   */
  now = () => {
    const date = this.clearDateTime(this.localize ? moment.utc() : moment());
    return this.localize ? date.local() : date;
  }

  /**
   * Alias for "now"
   */
  current = () => this.now();

  /**
   * Zero out time units that match or are more specific than the
   * given precision. Modifies (and returns) the end result.
   */
  clearDateTime = (value, precision = this.precision) => {
    if (!value || !value.isValid()) { return value; }
    const units = ['day', 'hour', 'minute', 'second', 'millisecond'];
    const precisionIndex = units.indexOf(precision);
    if (precisionIndex < 0) { return value; }

    const changeSet = {};
    units.forEach((unit, index) => {
      changeSet[unit] = index >= precisionIndex ? 0 : value.get(unit);
    });

    return value.set(changeSet);
  };

  /**
   * Updates the date portion of a DateTime with the given date.
   * Can offset the date portion if necessary; for example, with
   * a date range that spans multiple days.
   */
  applyDateToDateTime = (date, dateTime, offset = 0) => {
    if (!date) { return dateTime; }
    const dateValue = new DateTimeFormatter('YYYY-MM-DD', 'MM-DD-YYYY', 'MM/DD/YYYY').setLocalizeFormat(false).parse(date);
    const copyAttributes = ['year', 'month', 'date'];
    return copyAttributes.reduce(
      (current, prop) => current.set({ [prop]: dateValue.get(prop) }),
      this.parse(dateTime),
    ).add(offset, 'days');
  }

  /**
   * Updates the date portion of a range of DateTimes with the given
   * date. Will offset the date portion of the range of times against
   * the first item in the range, if necessary; for example, with
   * a date range that spans multiple days.
   */
  applyDateToDateTimeRange = (date, ...dateTimes) => {
    const [baseDateTime, ...additionalDateTimes] = dateTimes;
    const results = [];
    results.push(this.applyDateToDateTime(date, baseDateTime));

    additionalDateTimes.forEach((time) => {
      const timeValue = this.parse(time);
      const offset = timeValue.diff(this.parse(baseDateTime), 'days');
      results.push(this.applyDateToDateTime(date, timeValue, offset));
    });

    return results;
  }

  getSupportedFormats = (strict = false) => this.formats
    .filter(f => (
      typeof f === 'string' || !strict || f.strict
    ))
    .map(f => (typeof f === 'string' ? f : f.format));

  setPrecision = (precision) => {
    this.precision = precision;
    return this;
  }

  /**
   * Controls whether formatted values should be shown in local
   * time, or kept in raw UTC time. Setting this to false is
   * useful for pure date fields, as otherwise the time
   * adjustment could make the date go to a different day.
   *
   * Defaults to true.
   */
  setLocalizeFormat = (localize) => {
    this.localize = localize;
    return this;
  }
}

/** Represents a date object with no time */
export const Date = new DateTimeFormatter('YYYY-MM-DD', 'MM-DD-YYYY', 'MM/DD/YYYY').setPrecision('hour').setLocalizeFormat(false);

/** Represents a date object with time */
export const DateTime = new DateTimeFormatter('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]', 'YYYY-MM-DD[T]HH:mm:ss[Z]', 'YYYY-MM-DD HH:mm', 'MM-DD-YYYY HH:mm', 'MM/DD/YYYY HH:mm', { format: 'YYYY-MM-DD', strict: false });

/** Represents a time object without a date */
export const Time = new DateTimeFormatter('HH:mm');

/** Get the runtime's default IANA time zone string */
export const TimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const LocalTimeZone = momentTimeZone().tz(TimeZone)
  && momentTimeZone().tz(TimeZone).zoneAbbr();

import initialState from 'store/initialState';
import { omit } from 'lodash';
import * as types from './types';

const isAscending = (sort) => {
  switch (sort) {
    case 'date_received_asc': {
      return true;
    }
    default: {
      return false;
    }
  }
};

const spliceInDocument = (library, documents, ascending) => {
  const newDocuments = documents instanceof Array ? documents : [documents];
  const copy = library.slice();

  const sortedDocuments = ascending ? newDocuments.slice() : newDocuments.slice().reverse();

  sortedDocuments.forEach((document) => {
    const index = copy.findIndex(d => d.id === document.id);
    if (index >= 0) {
      copy.splice(index, 1, document);
    } else if (ascending) {
      copy.push(document);
    } else {
      copy.unshift(document);
    }
  });
  return copy;
};

const spliceOutDocument = (library, document) => {
  const index = library.findIndex(d => d.id === document.id);
  const copy = library.slice();
  if (index >= 0) {
    copy.splice(index, 1);
  }
  return copy;
};

const updateDuplicateDetected = (library, refreshData) => {
  // Iterates over document array and returns updated value if match found in refreshData
  const refreshed = library.map((document) => {
    const match = refreshData.find(d => d.id === document.id);
    if (match) {
      return {
        ...document,
        duplicate_detected: match.duplicate_detected,
      };
    }
    return document;
  });
  return refreshed;
};

const getDocumentFromState = (key, action, state) => (state[action.source][key].find(doc => doc.id === action.document.id));
const removeDocumentFromStateIfFound = (document, key, action, state) => (document ? spliceOutDocument(state[action.source][key], action.document) : state[action.source][key]);

const documents = (state = initialState.documents, action) => {
  switch (action.type) {
    case types.REPLACE_DOCUMENTS: {
      const { source, documentsInfo } = action;
      const { content, page, size } = documentsInfo;
      if (size !== undefined) {
        return {
          ...state,
          [source]: action.documentsInfo,
        };
      }
      return {
        ...state,
        [source]: {
          ...state[source],
          page,
          content: state[source].content.concat(content),
        },
      };
    }
    case types.REPLACE_DOCUMENT: {
      const contentDocument = getDocumentFromState('content', action, state);
      if (contentDocument?.remove === true) return state;

      const before = state[action.source].content;
      const content = spliceInDocument(state[action.source].content, action.document, isAscending(state[action.source].aidin_inbox?.sort_order));
      const size = before.length === content.length
        ? state[action.source].size
        : state[action.source].size + 1;

      const sharedDocumentState = {
        ...state,
        [action.source]: {
          ...state[action.source],
          content,
          size,
        },
      };

      // do not keep a copy in stage if content up to date.
      if (action.source === 'inbound') {
        const stage = spliceOutDocument(state[action.source].stage, action.document);
        return {
          ...sharedDocumentState,
          [action.source]: {
            ...sharedDocumentState[action.source],
            stage,
          },
        };
      }

      return sharedDocumentState;
    }
    case types.STAGE_DOCUMENT: {
      const stageDoc = getDocumentFromState('stage', action, state);
      const contentDoc = getDocumentFromState('content', action, state);
      if (!action.visible) {
        // remove from view if present
        if (contentDoc || stageDoc) {
          const stage = removeDocumentFromStateIfFound(stageDoc, 'stage', action, state);
          const removedDoc = { ...action.document, remove: true };
          const content = contentDoc ? spliceInDocument(state[action.source].content, removedDoc) : state[action.source].content;

          return {
            ...state,
            [action.source]: {
              ...state[action.source],
              stage,
              content,
            },
          };
        }
        return state;
      }

      // don't stage if exists in content exactly
      // version needs to be ignored in comparison
      if (contentDoc && JSON.stringify(omit(contentDoc, 'version')) === JSON.stringify(omit(action.document, 'version'))) {
        return state;
      }

      // Show more is visible
      if (isAscending(state[action.source].aidin_inbox?.sort_order) && ((state.inbound.page * 20) < state.inbound.size)) {
        // TODO add support to update count, front end does not know if the new document is already represented in the count, requires server side instruction (will come with filter support)
        return state;
      }

      const stage = spliceInDocument(state[action.source].stage, action.document, isAscending(state[action.source].aidin_inbox?.sort_order));
      const endState = {
        ...state,
        [action.source]: {
          ...state[action.source],
          stage,
        },
      };

      // don't stage the document if backend requests immediate update
      if (action.immediate) {
        return documents(endState, {
          type: types.LOAD_STAGED_DOCUMENTS,
          source: action.source,
        });
      }

      return endState;
    }
    case types.LOAD_STAGED_DOCUMENTS: {
      const before = state[action.source].content;
      const content = spliceInDocument(state[action.source].content, state[action.source].stage, isAscending(state[action.source].aidin_inbox?.sort_order));
      const size = before.length === content.length
        ? state[action.source].size
        : state[action.source].size + content.length - before.length;
      return {
        ...state,
        [action.source]: {
          ...state[action.source],
          stage: [],
          content,
          size,
        },
      };
    }
    case types.REFRESH_DUPLICATE_DETECTED: {
      const { content, stage } = state[action.source];
      const { refreshData } = action;
      return {
        ...state,
        [action.source]: {
          ...state[action.source],
          content: updateDuplicateDetected(content, refreshData),
          stage: updateDuplicateDetected(stage, refreshData),
        },
      };
    }
    case types.REMOVE_DOCUMENT:
      if (!getDocumentFromState('content', action, state)) {
        return state;
      }
      return {
        ...state,
        [action.source]: {
          ...state[action.source],
          size: state[action.source].size - 1,
          content: spliceOutDocument(state[action.source].content, action.document),
        },
      };

    case types.POPULATE_SIGNATURES:
      return {
        ...state,
        signatures: action.signatures,
      };
    case types.ADD_SIGNATURE:
      return {
        ...state,
        signatures: state.signatures.concat(action.signature),
      };
    case types.DELETE_SIGNATURE: {
      const { signatures } = state;
      const signaturesCopy = signatures.slice();
      signaturesCopy.splice(action.index, 1);

      return {
        ...state,
        signatures: signaturesCopy,
      };
    }
    default:
      return state;
  }
};

export default {
  documents,
};

import React from 'react';
import styled from 'react-emotion';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { FEATURE_PROVIDER_VERIFICATION_WARNING } from 'ducks/session/types';
import Session, { hasSingleProviderCriteriaVerificationWarning, hasSingleProviderVerificationWarning, hasProviderVerificationWarning } from 'models/Session';
import Provider from 'models/Provider';

import { Fragment, Icon } from 'components/utils';
import { CONFIRMATION_FILL, ERROR_LIGHT_FILL } from 'components/utils/Colors';
import withFeatureFlagRequired from 'components/utils/withFeatureFlagRequired';

import Warning from '../../../assets/images/warning_icon.png';

const ProviderVerificationWarningContainer = styled.div(props => ({
  display: props.align ? 'flex' : 'inline-block',
  alignItems: props.align,
  margin: props.margin,
}));

const hasWarning = (session, provider, criteria) => {
  let hasWarningRes = false;
  if (provider && criteria) {
    hasWarningRes = hasSingleProviderCriteriaVerificationWarning(session, provider, criteria);
  } else if (provider) {
    hasWarningRes = hasSingleProviderVerificationWarning(session, provider);
  } else {
    hasWarningRes = hasProviderVerificationWarning(session);
  }
  return hasWarningRes;
};

const generateProviderVerificationWarningContainer = (id, align, margin, name, size, color, className) => (
  <ProviderVerificationWarningContainer id={id} align={align} margin={margin} className={className}>
    {name === 'mp-warning'
      ? <img src={Warning} alt="!" width={`${size}px`} />
      : <Icon name={name} size={size} color={color} />}
  </ProviderVerificationWarningContainer>
);

const ProviderVerificationWarningIcon = ({
  session,
  align,
  size,
  margin,
  provider,
  criteria,
  showCheckOnVerified,
  className,
}) => {
  if (hasWarning(session, provider, criteria)) {
    return generateProviderVerificationWarningContainer('providerVerificationWarningIcon', align, margin, 'mp-warning', size, ERROR_LIGHT_FILL, className);
  }
  if (showCheckOnVerified) {
    return generateProviderVerificationWarningContainer('providerVerificationCheck', align, margin, 'mp-check', size, CONFIRMATION_FILL, className);
  }
  return null;
};

ProviderVerificationWarningIcon.propTypes = {
  session: Session.isRequired,
  align: PropTypes.oneOf(['top', 'left', 'center', 'right', 'bottom', 'baseline']),
  size: PropTypes.number,
  margin: PropTypes.string,
  provider: Provider,
  criteria: PropTypes.string,
  showCheckOnVerified: PropTypes.bool,
  className: PropTypes.string,
};
ProviderVerificationWarningIcon.defaultProps = {
  align: null,
  size: 36,
  margin: null,
  provider: null,
  criteria: null,
  showCheckOnVerified: false,
  className: undefined,
};

export default connect(({ session }) => ({ session }))(
  withFeatureFlagRequired(FEATURE_PROVIDER_VERIFICATION_WARNING, Fragment)(
    ProviderVerificationWarningIcon,
  ),
);

import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'emotion';
import styled from 'react-emotion';
import SpinKitSpinner from 'react-spinkit';

import { GRAY_FILL } from 'components/utils/Colors';

const SpinnerContainer = styled.div`
  width: ${props => props.width};
  height: ${props => props.height};
  display: flex;
  align-items: center;
  justify-content: center;
  .sk-three-bounce > div {
    width: ${props => props.iconSize};
    height: ${props => props.iconSize};
  }
`;

const threeBounceStyle = css`
  width: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

/**
 * CSS-animated spinner.
 */
const Spinner = props => (
  <SpinnerContainer width={props.width} height={props.height} iconSize={props.small ? '9px' : '18px'}>
    <SpinKitSpinner
      className={threeBounceStyle}
      name="three-bounce"
      fadeIn="quarter"
      color={props.color}
    />
  </SpinnerContainer>
);
Spinner.propTypes = {
  /** The color of the spinner */
  color: PropTypes.string,
  /** CSS-based width. Defaults to 100%, but can be 'auto' or some px-based value */
  width: PropTypes.string,
  /** CSS-based height. Defaults to 100%, but can be 'auto' or some px-based value */
  height: PropTypes.string,
  /** True for a small variant (size = 10px), false otherwise (size = 18px) */
  small: PropTypes.bool,
};
Spinner.defaultProps = {
  color: GRAY_FILL,
  width: '100%',
  height: '100%',
  small: undefined,
};

export default Spinner;

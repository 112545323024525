import React from 'react';
import ScrollspyChild from 'react-scrollspy';
import PropTypes from 'prop-types';

const Scrollspy = props => (
  <ScrollspyChild
    componentTag={props.componentTag}
    className={props.className}
    items={props.items}
    currentClassName={props.currentClassName}
    onUpdate={props.onUpdate}
    style={props.style}
  >
    {props.children}
  </ScrollspyChild>
);

Scrollspy.propTypes = {
  /* Allows to override component if you want to use component other than <ul> */
  componentTag: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
  /* Default tab styling */
  className: PropTypes.string.isRequired,
  /* Array of target contents */
  items: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.node, PropTypes.func])).isRequired,
  /* Active tab styling */
  currentClassName: PropTypes.string.isRequired,
  /* Function triggered on change of scroll or onClick */
  onUpdate: PropTypes.func.isRequired,
  /* Links passed through props to search for anchors */
  children: PropTypes.node.isRequired,
  /* Style passed to <ul> element */
  style: PropTypes.shape({}).isRequired,
};

export default Scrollspy;

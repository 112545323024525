/* eslint-disable camelcase */

import apiDirector from 'helpers/ApiDirector';
import { reportError } from 'helpers/ErrorHandler';
import * as referralActions from 'ducks/referrals/operations';
import * as actions from './actions';

const { shapeReferral: createShapedReferral } = referralActions;

const shapeReferral = (referral, getState) => {
  try {
    return createShapedReferral(referral, getState, true);
  } catch (e) {
    reportError(e, 'A referral dashboard referral was parsed without a reservationStatus', {
      status: referral.status,
      discharge_date: referral.discharge_date,
      currentRole: referral.currentRole,
      referralRole: referral.referralRole,
      hospital: referral.hospital,
      provider: referral.provider,
    });
    return {
      id: referral.id,
      referralRole: null,
      reservationStatus: '',
    };
  }
};

const shapeContent = (content, getState) => (content || []).reduce((set, ref) => ({
  ...set,
  [ref.id]: shapeReferral(ref, getState),
}), {});

/**
 * Called when reading search results from the paginated list.
 */
export const requestReferralSearchContent = ids => (dispatch, getState) => {
  if (!ids || ids.length === 0) { return Promise.resolve(ids); }
  return apiDirector.validateFetch('/api/search/referrals/content', {
    method: 'POST',
    body: JSON.stringify({ ids }),
  }).then(results => (
    dispatch(actions.updateReferralSearchContent(shapeContent(results, getState)))
  ));
};

/**
 * Request a brand new search. Retrieves the index and metadata. Will also pre-fetch content
 * if there are only a few search results.
 */
export const requestReferralSearch = filters => (dispatch, getState) => apiDirector.validateFetch('/api/search/referrals', {
  method: 'POST', body: JSON.stringify({ filters }),
})
  .then(results => ({
    ...results,
    content: shapeContent(results.content, getState),
  }))
  .then(results => dispatch(actions.replaceReferralSearchIndex(results)));

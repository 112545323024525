import styled from 'react-emotion';
import { css } from 'emotion';

import { PAGE_CONTENT_WIDTH_V2_PX } from 'components/utils/PageContext';

import mainLoginImage from './images/login_chair_crop.png';
import loginIconOne from './images/login_icon_1.png';
import loginIconTwo from './images/login_icon_2.png';
import loginIconThree from './images/login_icon_3.png';
import loginIconFour from './images/login_icon_4.png';

export { mainLoginImage };

export const partnerImages = [
  loginIconOne,
  loginIconTwo,
  loginIconThree,
  loginIconFour,
];

const baseImageTextStyle = styled.h3(({ theme }) => ({
  fontFamily: theme.fonts.LABEL_TEXT,
  fontSize: '14px',
  fontWeight: 900,
  lineHeight: 1.21,
  textAlign: 'center',
  color: theme.colors.WHITE,
}));

export const styles = {
  ImageBottomText: baseImageTextStyle,
  ImageSubtext: baseImageTextStyle,
  ImageTitleText: styled(baseImageTextStyle)({
    fontSize: '25px',
    marginTop: '300px',
  }),
  partnerImageIconStyle: css`
    width: 125px;
  `,
  imageTextContainerStyle: css`
    position: absolute;
    > div {
      width: 100%;
    }
  `,
  iconRowContainerStyle: css`
    flex-wrap: wrap;
    > div {
      text-align: center;
    }
    @media (min-width:920px) {
      > div {
        flex-basis: 50%;
      }
    }
    @media (min-width:${PAGE_CONTENT_WIDTH_V2_PX}) {
      > div {
        flex-basis: 25%;
      }
    }
  `,
  loginImageContainerStyle: css({
    backgroundImage: `url(${mainLoginImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    height: '700px',
  }),
};

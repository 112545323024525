import React from 'react';
import PropTypes from 'prop-types';
import facepaint from 'facepaint';
import { connect } from 'react-redux';
import styled from 'react-emotion';
import { cx, css } from 'emotion';
import { applyTestAttribute } from 'helpers/development';

export const mq = facepaint([
  '@media(min-width: 600px) and (max-width: 960px)', // "medium", narrow columns
  '@media(max-width: 600px)', // "small", single column
]);

export const media = facepaint([
  '@media print',
]);

const getChildColumnWidth = (child, columns, index) => {
  if (!child) {
    return null;
  }
  const { width } = child.props;
  const type = typeof width;
  if (type !== 'undefined') {
    if (type === 'string') { return width; }
    if (type === 'number') { return `${width}%`; }
    if (type === 'object' && width[index]) { return width[index]; }
  }
  return `${(100 / columns[index])}%`;
};

const RowElement = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  ${props => props.justify && `justify-content: ${props.justify}`};
`;

const ColumnElement = styled.div();

const ResponsiveRowContext = React.createContext({});

export const Row = (props) => {
  const {
    children,
    className,
    columns,
    justify,
  } = props;

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <RowElement className={className} justify={justify} {...applyTestAttribute(props)}>
      {React.Children.map(children, child => {
        if (!child) { return null; }
        const columnClass = css(mq({
          flexBasis: [
            getChildColumnWidth(child, columns, 0),
            getChildColumnWidth(child, columns, 1),
            getChildColumnWidth(child, columns, 2),
          ],
        }));
        return (
          <ResponsiveRowContext.Provider value={{ columnClass }}>
            {child}
          </ResponsiveRowContext.Provider>
        );
      })}
    </RowElement>
  );
};

Row.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  className: PropTypes.string,
  /** An array of the number of columns to render in large, medium, and small */
  columns: PropTypes.arrayOf(PropTypes.number).isRequired,
  /** Justification */
  justify: PropTypes.string,
  /** cypress testing handler */
  'data-testid': PropTypes.string,
};

Row.defaultProps = {
  children: null,
  className: undefined,
  justify: undefined,
  'data-testid': undefined,
};

const mobileMarginStyle = (height) => css(mq({
  marginTop: ['0px', height, height],
}));

export const Column = ({
  id,
  children,
  className,
  mobileMargin,
  onClick,
}) => {
  const { columnClass } = React.useContext(ResponsiveRowContext);
  return (
    <ColumnElement
      id={id}
      className={cx(columnClass, mobileMargin ? mobileMarginStyle(mobileMargin === true ? '20px' : `${mobileMargin}px`) : null, className)}
      onClick={onClick}
    >
      {children}
    </ColumnElement>
  );
};

Column.propTypes = {
  id: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  className: PropTypes.string,
  mobileMargin: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  onClick: PropTypes.func,
  // gets used by Row
  // eslint-disable-next-line react/no-unused-prop-types
  width: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
    PropTypes.number,
  ]),
};

Column.defaultProps = {
  id: undefined,
  children: null,
  className: null,
  mobileMargin: undefined,
  onClick: undefined,
  width: undefined,
};

const ResponsiveListener = (props) => {
  if (
    (props.size === props.mediaType)
    || (props.size.includes && props.size.includes(props.mediaType))
  ) {
    return props.children;
  }
  return null;
};

ResponsiveListener.propTypes = {
  size: PropTypes.oneOfType([
    PropTypes.oneOf(['small', 'medium', 'large']),
    PropTypes.arrayOf(PropTypes.oneOf(['small', 'medium', 'large'])),
  ]).isRequired,
  mediaType: PropTypes.oneOf(['small', 'medium', 'large']).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

ResponsiveListener.defaultProps = {
  children: null,
};

export const Responsive = connect(state => ({
  mediaType: state.browser.mediaType,
}), null)(ResponsiveListener);

import React from 'react';
import PropTypes from 'prop-types';
import Notification from 'models/Notification';

import Popup from 'components/utils/Popup';

import NotificationList from './NotificationList';

const MessageApi = PropTypes.shape({
  requestMessageThreadById: PropTypes.func.isRequired,
  requestNewMessageThread: PropTypes.func.isRequired,
  sendMessage: PropTypes.func.isRequired,
});

/**
 * Popup displaying the notifications
 */
const NotificationsListPopupPanel = ({
  messageActions,
  conversationActions,
  markNotificationAsRead,
  notifications,
  requestNotifications,
  resetUnreadCount,
  trigger,
}) => (
  <Popup
    onOpen={requestNotifications}
    trigger={trigger}
    offsetY={8}
    width={350}
    height={330}
    position="bottom right"
  >
    {({ close }) => (
      <NotificationList
        close={close}
        markNotificationAsRead={markNotificationAsRead}
        notifications={notifications}
        requestNotifications={requestNotifications}
        requestMessageThreadById={messageActions.requestMessageThreadById}
        requestConversationById={conversationActions.requestMessageThreadById}
        resetUnreadCount={resetUnreadCount}
      />
    )}
  </Popup>
);

NotificationsListPopupPanel.propTypes = {
  /** Open message */
  messageActions: MessageApi.isRequired,
  /** Open conversation */
  conversationActions: MessageApi.isRequired,
  /** Mark individual notification as read */
  markNotificationAsRead: PropTypes.func.isRequired,
  /** List of notifications to display */
  notifications: PropTypes.arrayOf(Notification).isRequired,
  /** Request notifications when this component mounts (and sync */
  requestNotifications: PropTypes.func.isRequired,
  /** Reset unread count when open */
  resetUnreadCount: PropTypes.func.isRequired,
  /** Trigger component to open this popup */
  trigger: PropTypes.node.isRequired,
};

export default NotificationsListPopupPanel;

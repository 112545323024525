import React from 'react';
import { compose } from 'redux';
import styled from 'react-emotion';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { css } from 'emotion';

import { Responsive, mq } from 'components/utils/Responsive';
import { detectIE } from 'components/utils/IE';

import { FEATURE_UPGRADE_RELEASE_4 } from 'ducks/session/types';
import withAvailableThemes from 'routes/withAvailableThemes';
import withThemeProvider from 'components/utils/withThemeProvider';

const Container = styled.div`
  .Toastify__toast-container--top-right {
    top: 60px;
  };

  .Toastify__toast {
    padding: 20px 30px;
    border-radius: 5px;
    font-family: inherit;
    color: #333333;
  }
`;

const ToastContainerMobile = styled.div`
  .Toastify__toast-container {
    ${css(mq({ width: ['400px', '340px', '340px'] }))}
    left: auto;
  };
`;

const ToastContainerDesktop = styled.div`
  .Toastify__toast-container {
    width: ${detectIE() ? '420px' : 'auto'};
  };
`;

const ToastContainerCypress = styled.div`
  .Toastify__toast-container {
    width: 0px;
    height: 0px;
  }
`;

/**
 * This only needs to be placed on the page once with the
 * MainContainer.
 *
 * To display a Toast, call one of the helper functions
 * supplied via `helpers/ui`.
 */
const Toast = () => (
  window.Cypress ? (
    <ToastContainerCypress>
      <ToastContainer
        hideProgressBar
      />
    </ToastContainerCypress>
  ) : (
    <Container>
      <Responsive size="large">
        <ToastContainerDesktop>
          <ToastContainer
            position="top-right"
            hideProgressBar
          />
        </ToastContainerDesktop>
      </Responsive>
      <Responsive size={['small', 'medium']}>
        <ToastContainerMobile>
          <ToastContainer
            position="top-right"
            hideProgressBar
          />
        </ToastContainerMobile>
      </Responsive>
    </Container>
  )
);

export default compose(
  withAvailableThemes(FEATURE_UPGRADE_RELEASE_4),
  withThemeProvider,
)(Toast);

import React from 'react';
import PropTypes from 'prop-types';
import Link from 'components/utils/Link';
import { connect } from 'react-redux';
import * as actions from 'ducks/session';
import { createTranslator } from 'helpers/i18n';

import {
  ContentPanel, Panel, Button, ButtonBar, TextField, Tip, Colors,
} from 'components/utils';

const { SENDING_DARKER_FILL } = Colors;

const tr = createTranslator({
  title: 'Resend confirmation instructions',
  email: {
    label: 'Email',
  },
  submit: 'Send me confirmation instructions',
  back: 'Log in',
  success: 'You will receive an email with instructions on how to confirm your email address in a few minutes.',
});

class ResendConfirmationForm extends React.Component {
  static propTypes = {
    resendConfirmationInstructions: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);
    this.state = { email: '', message: null, errors: { email: null } };
  }

  componentDidMount() {
    this.input.getField().focus();
  }

  handleSubmit = () => {
    if (this.state.email) {
      this.setState({ errors: { email: null } }, () => {
        this.props.resendConfirmationInstructions(this.state.email)
          .then(() => {
            this.setState({ message: tr('success') });
          })
          .catch((e) => {
            if (e.response && e.response.errors) {
              this.setState({ errors: e.response.errors });
            }
          });
      });
    } else {
      this.setState({ errors: { email: [] } });
    }
  }

  handleInput = (email) => {
    this.setState({ email });
  }

  render() {
    return (
      <ContentPanel title={tr('title')}>
        <form onSubmit={(e) => { e.preventDefault(); this.handleSubmit(); }}>
          <Panel>
            {this.state.message && (
              <Tip>{this.state.message}</Tip>
            )}
            <TextField
              ref={(ref) => { this.input = ref; }}
              required
              label={tr('email.label')}
              errors={this.state.errors.email}
              value={this.state.email}
              onChange={this.handleInput}
            />
            <ButtonBar>
              <Button type="submit" small color={SENDING_DARKER_FILL}>{tr('submit')}</Button>
              <Link to="/devise_users/sign_in">
                <Button small link>{tr('back')}</Button>
              </Link>
            </ButtonBar>
          </Panel>
        </form>
      </ContentPanel>
    );
  }
}

export default connect(state => state, actions)(ResendConfirmationForm);

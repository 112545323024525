import moment from 'moment';

/*
 This library supplies a function for applying a relative time correction
 to a moment() based on common English relative time shorthands. This entire
 file must be reimplemented to support non-English locales, as regexes and
 order of operations must be tweaked to disambiguate any collisions in common
 shorthands like English "mon" for Monday and Month, and compound expressions
 idiomatically for the language (m/d/y vs d/m/y).

 Examples of English shorthands this library can handle:

 "tomorrow"
 "3pm tomorrow"
 "5pm Monday" or just "5p mon"
 "5:30 PM July 4" or just "5:30p jul 4" or "5:30p 7/4"
*/

const daysOfWeek = ['sun', 'mon$|mon[^t]', 'tue', 'wed', 'thu', 'fri', 'sat'];

const monthsOfYear = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'];

const patterns = [
  /*
    This is a matrix because the order of operations may need to be
    deterministic to handle certain expressions, and also because walking
    object keys is comparatively slow.
  */
  // next d/w/m/y expressions
  ['tomorrow', (m) => { m.add(1, 'd'); }],
  ['next week', (m) => { m.add(1, 'w'); }],
  ['next month', (m) => { m.add(1, 'M'); }],
  ['next year', (m) => { m.add(1, 'y'); }],
  // day-of-week expressions
  [
    '(sun|mon$|mon[^t]|tue|wed|thu|fri|sat)',
    (m, matches, now) => {
      for (let i = 0; i <= 6; i += 1) {
        const rematch = matches[1].match(daysOfWeek[i]);
        if (rematch) {
          if (i <= now.day()) {
            m.add(1, 'w');
          }
          m.day(i);
        }
      }
    },
  ],
  // month expressions
  [
    '(jan|feb|mar|apr|may|jun|jul|aug|sep|oct|nov|dec)\\w*\\s*(\\d+)',
    (m, matches, now) => {
      for (let i = 0; i <= 11; i += 1) {
        const rematch = matches[1].match(monthsOfYear[i]);
        if (rematch) {
          m.month(i);
          if (matches[2]) {
            const d = parseInt(matches[2], 10);
            m.date(d);
          }
        }
      }
      if (m.isBefore(now)) {
        m.add(1, 'y');
      }
    },
  ],
  [
    '(\\d+)/(\\d+)',
    (m, matches, now) => {
      const i = parseInt(matches[1], 10) - 1;
      m.month(i);
      const d = parseInt(matches[2], 10);
      m.date(d);
      if (m.isBefore(now)) {
        m.add(1, 'y');
      }
    },
  ],

  // n m/h/d from now
  ['(\\d+)\\s*m[inutes]*', (m, matches) => { m.add(matches[1], 'm'); }],
  ['(\\d+)\\s*h[ours]*', (m, matches) => { m.add(matches[1], 'h'); }],
  ['(\\d+)\\s*d[ays]*', (m, matches) => { m.add(matches[1], 'd'); }],
  ['(\\d+)\\s*w[eeks]*', (m, matches) => { m.add(matches[1], 'w'); }],
  ['(\\d+)\\s*mont[hs]*', (m, matches) => { m.add(matches[1], 'M'); }],
  // hour set
  ['^(\\d+)\\s*a', (m, matches) => { m.hour(parseInt(matches[1], 10)); m.minute(0); m.second(0); }],
  ['^(\\d+)\\s*p', (m, matches) => { m.hour(parseInt(matches[1], 10) + 12); m.minute(0); m.second(0); }],
  ['\\s(\\d+)\\s*a', (m, matches) => { m.hour(parseInt(matches[1], 10)); m.minute(0); m.second(0); }],
  ['\\s(\\d+)\\s*p', (m, matches) => { m.hour(parseInt(matches[1], 10) + 12); m.minute(0); m.second(0); }],
  ['noon', (m) => { m.hour(12); m.minute(0); m.second(0); }],
  ['midnight', (m) => { m.hour(0); m.minute(0); m.second(0); }],
  // time set
  ['(\\d+):(\\d+)\\s*a', (m, matches) => { m.hour(parseInt(matches[1], 10)); m.minute(parseInt(matches[2], 10)); m.second(0); }],
  ['(\\d+):(\\d+)\\s*p', (m, matches) => { m.hour(parseInt(matches[1], 10) + 12); m.minute(parseInt(matches[2], 10)); m.second(0); }],
];

/**
 m - the moment to be modified
 value - the input text to parse to perform the modification
 now - the value of "now" - mainly for performing deterministic tests
*/
const parseRelativeTime = (m, value, now = moment()) => {
  const mutatedMoment = moment(m);
  const v = value.toLowerCase();
  patterns.forEach((entry) => {
    const matches = v.match(entry[0]);
    if (matches) {
      entry[1](mutatedMoment, matches, now);
    }
  });
  return mutatedMoment;
};

export default parseRelativeTime;

/* eslint-disable camelcase */

import React from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { connect } from 'react-redux';
import { createTranslator } from 'helpers/i18n';
import Session from 'models/Session';

import Modal from 'components/utils/Modal';

import LoginForm from 'components/login/LoginForm';

const tr = createTranslator({
  title: 'Log In to Continue',
});

/**
 * This modal appears when a preview user tries to route to a URL
 * other than a single referral. In this case, they are prompted
 * to login.
 *
 * This does not handle the case of attempt to route to a referral
 * they may not have access to. This should be handled via authz.
 */
const PreviewModal = ({
  hasReferral,
  open,
  onClose,
  location: { pathname },
  session: { user: { preview_email } },
  username,
}) => (
  <Modal
    onClose={onClose}
    title={tr('title')}
    open={open}
  >
    <LoginForm loginOnly hasReferral={hasReferral} justifyContent="center" loginUrl={pathname} username={username || preview_email} />
  </Modal>
);
PreviewModal.propTypes = {
  hasReferral: PropTypes.bool,
  location: ReactRouterPropTypes.location,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  session: Session.isRequired,
  username: PropTypes.string,
};
PreviewModal.defaultProps = {
  hasReferral: true,
  location: {
    pathname: '/', hash: '', search: '',
  },
  onClose: () => { window.history.back(); },
  open: true,
  username: undefined,
};

export default connect(({ session }) => ({ session }))(PreviewModal);

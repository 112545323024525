import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { css, cx } from 'emotion';

import { FEATURE_PROVIDER_VERIFICATION_WARNING } from 'ducks/session/types';
import { hasProviderVerificationWarning } from 'models/Session';
import { createTranslator } from 'helpers/i18n';
import md from 'helpers/markdown';

import { mq } from 'components/utils/Responsive';
import { contentStyle } from 'components/utils/Styles';
import Fragment from 'components/utils/Fragment';
import Link from 'components/utils/Link';
import Panel from 'components/utils/Panel';
import { WHITE, ERROR_LIGHT_FILL, ERROR_DARKER_FILL } from 'components/utils/Colors';
import withFeatureFlagRequired from 'components/utils/withFeatureFlagRequired';

const ProviderVerificationWarningPanelStyle = css({
  display: 'flex',
  background: ERROR_DARKER_FILL,
  textDecoration: 'none',
});

const ProviderVerificationWarningInsetStyle = cx(contentStyle, css(mq({
  padding: ['10px', '15px', '15px'],
})));

const fullWidthStyle = css`
  width: 100%;
  text-decoration: none;
`;

export const providerVerificationsWarningStatusTextStyle = css`
  display: block !important;
  padding-left: 15px;
  font-family: 'futura-pt';
  font-size: 17px;
  font-weight: bold;
  color: ${WHITE};
  text-decoration: none;
  text-decoration-underline: ${ERROR_LIGHT_FILL};
  -webkit-font-smoothing: antialiased;
  `;

export const providerVerificationsWarningBodyTextStyle = cx(
  providerVerificationsWarningStatusTextStyle,
  css`
    font-size: 14px;
    font-weight: normal;
  `,
);
const ProviderVerificationWarningInset = Panel;

const tr = createTranslator({
  warning: {
    provider: {
      status: 'Status: INCOMPLETE',
      body: 'Update your <span style="text-decoration:underline">provider settings</span> to receive referrals',
    },
  },
});

function ProviderVerificationWarningPanel({ session }) {
  const hasWarning = hasProviderVerificationWarning(session);
  if (hasWarning) {
    return (
      <Panel id="providerVerificationWarningPanel" fullWidth align="center" className={ProviderVerificationWarningPanelStyle} itemClassName={fullWidthStyle}>
        <Link key="/admin-providers" to="/admin/providers" className={fullWidthStyle}>
          <ProviderVerificationWarningInset
            className={ProviderVerificationWarningInsetStyle}
            spacing={0}
          >
            <div className={providerVerificationsWarningStatusTextStyle}>{tr('warning.provider.status')}</div>
            <div className={providerVerificationsWarningBodyTextStyle}>
              {md(tr('warning.provider.body'))}
            </div>
          </ProviderVerificationWarningInset>
        </Link>
      </Panel>
    );
  }
  return null;
}

ProviderVerificationWarningPanel.propTypes = {
  session: PropTypes.shape({}).isRequired,
};
ProviderVerificationWarningPanel.defaultProps = {};

export default connect(({ session }) => ({ session }))(
  withFeatureFlagRequired(FEATURE_PROVIDER_VERIFICATION_WARNING, Fragment)(
    ProviderVerificationWarningPanel,
  ),
);

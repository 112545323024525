/* eslint-disable no-nested-ternary */

import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion';
import AidinIcon from 'components/branding/AidinIcon';
import { ThemeContext } from 'components/utils/ThemeContext';

export const scoreNames = {
  best: 'Best',
  great: 'Great',
  good: 'Good',
  ok: 'Okay',
  poor: 'Poor',
  none: 'No Data',
};

export const scoreColors = {
  best: '#57b56c',
  great: '#57b56c',
  good: '#98d480',
  ok: '#bed480',
  poor: '#f4e78a',
  none: '#bbbbbb',
};

const SCORE_ICON_FILL = '#FF0058';

const Score = styled.div`
  display: inline-block;
  font-family: 'futura-pt';
  font-weight: ${({ value, theme }) => (theme.version === 'v2' ? 'normal' : (value === 'none' ? 'normal' : 'bold'))};
  font-size: ${props => (props.value === 'none' ? 14 : props.scoreSize)}px;
  color: ${({ value, theme }) => (theme.version === 'v2' ? theme.colors.BLACK : scoreColors[value])};
  text-transform: ${props => (props.value === 'none' ? 'uppercase' : 'none')};
  white-space: nowrap;
`;

const ScoreIcon = styled.span`
  margin-left: 3.5px;
`;

const IconScore = styled(ScoreIcon)`
  color: ${SCORE_ICON_FILL};
`;

const SVGContainer = styled(ScoreIcon)`
  vertical-align: text-top;
`;
const isInRange = (score, min, max) => score >= min && score <= max;

export const getScoreRange = (score) => {
  if (!score || score === -1) { return 'none'; }
  switch (true) {
    case (isInRange(score, 76, 1000)):
      return 'great';
    case (isInRange(score, 67, 75)):
      return 'good';
    case (isInRange(score, 34, 66)):
      return 'ok';
    case (isInRange(score, 25, 33)):
    default:
      return 'poor';
  }
};

const STATUS_ORDER = [
  'chosen',
  'available',
  'viewed',
  'under_review',
  'pending',
  'unavailable',
  'removed',
];

export const sortByStatus = (a, b) => {
  const ai = STATUS_ORDER.indexOf(a.status);
  const bi = STATUS_ORDER.indexOf(b.status);
  return ai === bi ? 0 : ai < bi ? -1 : 1;
};

const sortByScore = (a, b) => (a.scoreValue === b.scoreValue ? 0
  : a.scoreValue === null ? 1 : b.scoreValue === null ? -1
    : b.scoreValue - a.scoreValue
);

export const getSentReferralStatus = (provider, referralSent = true) => (provider.confirmed
  ? provider.delivered
    ? provider.status
    : referralSent
      ? provider.status // FIXME: Restore // 'undelivered'
      : 'pending'
  : provider.removed_at
    ? 'removed'
    : 'pending');

export const sortAndMapProviders = (
  providers,
  {
    presorted = false,
    referralSent = true,
  } = {},
) => {
  let result = providers.slice().map((provider) => ({
    ...provider,
    status: getSentReferralStatus(provider, referralSent),
    scoreValue: provider.score,
  }));
  if (!presorted) {
    result = result.sort((a, b) => {
      const val = sortByStatus(a, b);
      return val === 0 ? sortByScore(a, b) : val;
    });
  }
  return result.map((provider, idx, results) => {
    const score = getScoreRange(provider.scoreValue);
    let trulyBest = false;
    if (idx === 0 && results.length > 1) {
      const nextProvider = results[idx + 1];
      trulyBest = provider.scoreValue > nextProvider.scoreValue;
      trulyBest = trulyBest && score !== 'none';
    }
    return {
      ...provider,
      score: trulyBest ? 'best' : score,
    };
  });
};

export const translateScore = getScoreRange;

/**
 * Display the Aidin score and badge information. Also has a `sortAndMapProviders` helper
 * function as a named export.
 */
const AidinScore = ({
  score, awardedBadges, formatter, scoreSize, ...rest
}) => {
  const iconSize = `${scoreSize - 4 <= 0 ? 4 : scoreSize - 4}px`;
  const { version: v } = useContext(ThemeContext);

  const IconElement = v === 'v2' ? IconScore : ScoreIcon;
  const IconContainer = v === 'v2' ? SVGContainer : ScoreIcon;
  return (
    <Score value={score} scoreSize={scoreSize} {...rest}>
      <span>
        {score === 'none' ? scoreNames[score]
          : formatter(awardedBadges.filter((s, i, self) => s.state === 'active' && self.findIndex(r => r.key === s.key) === i).length)}
      </span>
      {score === 'none'
        ? null : score === 'best'
          ? <IconElement className="mp-star" />
          : (
            <IconContainer>
              <AidinIcon fill={v === 'v2' ? SCORE_ICON_FILL : scoreColors[score]} css={{ height: iconSize, width: iconSize }} />
            </IconContainer>
          )}
    </Score>
  );
};
AidinScore.propTypes = {
  /** Format the output */
  formatter: PropTypes.func,
  /** The string score. Use a helper function to convert the numeric score to a string */
  score: PropTypes.oneOf(['best', 'great', 'good', 'ok', 'poor', 'none']).isRequired,
  /** The numeric score */
  // eslint-disable-next-line
  scoreValue: PropTypes.number,
  /** Size of scores */
  scoreSize: PropTypes.number,
  /** The number of badges */
  awardedBadges: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    state: PropTypes.string,
  })),
};
AidinScore.defaultProps = {
  formatter: scoreValue => scoreValue,
  scoreSize: 18,
  scoreValue: undefined,
  awardedBadges: [],
};

export default AidinScore;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AidinPropTypes from 'helpers/AidinPropTypes';
import Panel from 'components/utils/Panel';
import DateField from 'components/utils/DateField';
import { createTranslator } from 'helpers/i18n';
import text from './translations';

const tr = createTranslator(text);
/**
 * The `CalendarRangeField` component provides an interface for selecting a date range.
 * It displays two date fields for selecting the start and end dates of the range.
 *
 * @class
 * @param {Object} props - Properties to be passed to the component.
 * @returns {React.Component} - A React component.
 */
const CalendarRangeField = ({ value, onChange, startYear }) => {
  const [focusedInput, setFocusedInput] = useState(null);

  const handleFocusChange = (focused) => {
    if (focused === 'startDate') {
      setFocusedInput('startDate');
    } else if (focused === 'endDate') {
      setFocusedInput('endDate');
    }
  };

  const renderDateField = (id, val, label) => (
    <DateField
      value={val}
      onChange={v => onChange({ [id]: v })}
      isOutsideRange={() => false}
      startYear={startYear}
      focusedInput={focusedInput}
      onFocusChange={handleFocusChange}
      id={id}
      autoFocus={focusedInput === 'startDate'}
      placeholder={label}
      label={label}
    />
  );

  return (
    <Panel className="calendar-range-field">
      {renderDateField('startDate', value.start, tr('start_date'))}
      {renderDateField('endDate', value.end, tr('end_date'))}
    </Panel>
  );
};

CalendarRangeField.propTypes = {
  /** {Object} value - The value object containing start and end dates. */
  value: PropTypes.shape({
    /** {string} or {moment} value.start - The start date in the range. */
    start: AidinPropTypes.date,
    /** {string} or {moment} value.end - The end date in the range. */
    end: AidinPropTypes.date,
  }),
  /** {function} onChange - Callback function to be triggered when date value changes. */
  onChange: PropTypes.func,
  /** {number} startYear - The year to start the calendar from. */
  startYear: PropTypes.number,
};
CalendarRangeField.defaultProps = {
  value: {
    start: null,
    end: null,
  },
  onChange: () => { },
  startYear: new Date().getFullYear() - 10,
};

export default CalendarRangeField;

function ResponseError(response, json) {
  // eslint-disable-next-line no-nested-ternary
  const message = response.status === 500 ? 'An unexpected error occurred.' : !!json && (json.message || json.error) ? (json.message || json.error) : (response.statusText || 'Error during fetch');
  const error = new Error(message);
  error.name = 'ResponseError';
  error.status = response.status;
  error.response = json;
  error.metadata = {
    response: json,
  };
  return error;
}
ResponseError.prototype = Object.create(Error.prototype);

export default ResponseError;

/**
 * The styles here are based on those found in styleguide.scss.
 */
import React from 'react';
import styled from 'react-emotion';
import { css, cx } from 'emotion';
import {
  mq, media,
} from 'components/utils/Responsive';

import ThemeControl from 'components/utils/ThemeControl';

import * as Colors from 'components/utils/Colors';

export const Secondary = styled.div`
  font-family: ${({ theme }) => theme.fonts.BODY_TEXT};
  font-size: 14px;
  line-height: 1.36;
  text-align: left;
  color: #000000;
  opacity: 0.5;
`;

export const SecondaryLight = styled(Secondary)`
  color: #ffffff;
  opacity: 1;
`;

export const H3Light = styled.h3`
  font-family: ${({ theme }) => theme.fonts.LABEL_TEXT};
  font-size: 19px;
  font-weight: 900;
  line-height: 1.21;
  text-align: left;
  color: #ffffff;
`;

export const Label = styled.div`
  font-family: ${({ theme }) => theme.fonts.LABEL_TEXT};
  font-size: 12px;
  line-height: 1.33;
  letter-spacing: 0.2px;
  text-align: left;
  text-transform: uppercase;
  color: ${props => props.color || '#222222'};
  opacity: 0.8;
`;

const InfoShared = styled.span`
  ${mq({ fontSize: ['23px', '20px', '20px'] })}
  font-family: ${({ theme }) => theme.fonts.INFO_TEXT};
  line-height: 1.26;
  text-align: left;
  display: block;
  margin-bottom: 0.5rem;
`;

export const Info = styled(InfoShared)`
  color: #000000;
`;

export const InfoReceiving = styled(Info)`
  color: #573afc;
`;

export const InfoSending = styled(Info)`
  color: #d6a300;
`;

export const InfoWarning = styled(Info)`
  color: ${({ theme }) => theme.colors.TEXT_ERROR};
`;

export const LinkText = styled.span`
  font-family: ${({ theme }) => theme.fonts.BODY_TEXT};
  font-size: 14px;
  line-height: 1.36;
  color: #777777;
  text-decoration: underline;
  cursor: pointer;
`;

export const Hyperlink = styled(LinkText.withComponent('a'))();

export const BodySendingColor = styled.span`
  color: #d6a300;
`;

export const TimeAgo = styled.div`
  font-size: 12px;
  text-align: center;
  color: #666666;
`;

export const NumbersUnreadSmall = styled.div`
  font-family: ${({ theme }) => theme.fonts.LABEL_TEXT};
  font-weight: bold;
  font-size: 14px;
  text-align: right;
  ${props => props.count !== 0 && `color: ${Colors.ERROR_LIGHT_FILL};`}
`;

export const NumbersSmall = styled.div`
  font-family: ${({ theme }) => theme.fonts.LABEL_TEXT};
  font-weight: bold;
  font-size: 14px;
  text-align: right;
  color: #aaaaaa;
`;

export const NumbersLarge = styled.div`
  font-family: ${({ theme }) => theme.fonts.LABEL_TEXT};
  font-size: 22px;
  color: ${props => props.color || '#aaaaaa'};
`;

export const Table = styled.div`
  font-family: ${({ theme }) => theme.fonts.BODY_TEXT};
  font-size: 14px;
  color: #111111;
`;

export const HelpModal = styled.div`
  background-color: #ffffff;
  display: inline-block;
  width: 300px;
  box-shadow: 0 2px 6px 0 rgba(153, 153, 153, 0.4);
  border: 1px solid #cccccc;
  overflow-y: auto;
`;

export const baseModalStyleAttributes = {
  backgroundColor: '#ffffff',
  display: 'inline-block',
  boxShadow: '0 2px 6px 0 rgba(153, 153, 153, 0.4)',
  border: '1px solid #cccccc',
  overflowY: 'auto',
  height: 'auto',
  zIndex: '100',
};

export const baseModalStyle = css(baseModalStyleAttributes);

export const baseModalStyleAttributesV2 = {
  backgroundColor: '#ffffff',
  display: 'inline-block',
  boxShadow: '0 0 6px 0 rgba(125, 126, 139, 0.2)',
  border: 'solid 1px #dfdfe2',
  overflowY: 'auto',
  height: 'auto',
  zIndex: '100',
  borderRadius: '5px',
};

export const contentStyle = css(mq({
  width: ['940px', 'calc(100% - 32px)', 'calc(100% - 32px)'],
  marginLeft: 'auto',
  marginRight: 'auto',
}));

export const footerContentStyle = css(mq({
  width: ['100%', 'calc(100% - 32px)', 'calc(100% - 32px)'],
  marginLeft: 'auto',
  marginRight: 'auto',
}));

export const contentFullStyle = css(mq({
  width: ['inherit', 'calc(100% + 32px)', 'calc(100% + 32px)'],
  marginLeft: ['0px', '-16px', '-16px'],
  marginRight: ['0px', '-16px', '-16px'],
}));

export const ContentFullContainer = styled.div`
  ${contentFullStyle}
`;


function calcPageContentPaddingTop(hasBanner, hasWarning, bannerAdjustmentHeight = 41, headerAdjustmentHeight = 0) {
  let padding = 64;
  if (hasBanner) padding += bannerAdjustmentHeight;
  if (hasWarning) padding += 64;
  return padding - headerAdjustmentHeight;
}

const createFullContainer = backgroundColor => styled.div(({ hasBanner, hasWarning }) => cx(
  css(mq({
    paddingBottom: ['190px', '290px', '290px'],
  })),
  css(media({
    paddingTop: [`${calcPageContentPaddingTop(hasBanner, hasWarning, 21, 19)}px`, '0px'],
  })),
  css({ backgroundColor }),
));

export const FullPageContent = createFullContainer();

export const GrayFullContainer = createFullContainer('#f2f2f3');

export const WhiteFullContainer = createFullContainer(ThemeControl.v2.colors.WHITE);

export const PageContent = styled.div(({ hasBanner, hasWarning }) => cx(
  css(mq({
    paddingBottom: ['190px', '290px', '290px'],
  })),
  css(media({
    paddingTop: [`${calcPageContentPaddingTop(hasBanner, hasWarning)}px`, '0px'],
  })),
));

export const clickableStyle = css`
  cursor: pointer;
`;

export const ClickableTitle = styled.h4(clickableStyle);

export const ignoreOnPDF = css`
  visibility: visible;
`;

export const userSelectNoneStyle = css`
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

export const userSelectAllStyle = css`
  -webkit-user-select: all;
  -moz-user-select: all;
  -ms-user-select: all;
  user-select: all;
`;

export const PopupContent = styled.div`
  padding: 20px;
`;

export const HeaderDivider = styled.div(mq({
  width: ['1060px', '100%', '100%'],
  marginLeft: ['-60px', '0px', '0px'],
  height: '0px',
  borderBottom: 'solid 3px #666666',
}));

export const VerticalTabs = styled.div`
  margin-right: 20px;
`;

export const verticalTabItemStyle = css`
  display: block !important;
  width: 120px;
  padding: 15px;
  background-color: #aaaaaa;
  font-family: 'futura-pt';
  font-size: 17px;
  color: #ffffff;
  text-decoration: none;
  line-height: 1;
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
`;

export const deidentifiedStyle = css`
  opacity: 0.5;
`;

export const createModalContainer = (hMargin = 40) => styled.div(mq({
  paddingTop: '17px',
  paddingBottom: '17px',
  paddingLeft: [`${hMargin}px`, '0px', '0px'],
  paddingRight: [`${hMargin}px`, '0px', '0px'],
}));

/**
 * Use this to make a styled component referenceable. Useful for
 * popup-like components when the styled component is the trigger.
 */
export const withReference = Component => React.forwardRef((props, ref) => (
  <Component
    {...props /* eslint-disable-line react/jsx-props-no-spreading */}
    innerRef={ref}
  />
));

export const DropDownItem = styled.div`
  display: flex;
  min-width: 150px;
  justify-content: space-between;
`;

export const ModalPrompt = styled.div({
  fontSize: '18px',
});

/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-array-index-key, no-nested-ternary */

import React from 'react';
import PropTypes from 'prop-types';
import { css, cx } from 'emotion';
import styled from 'react-emotion';

import Divider from 'components/utils/Divider';
import Panel from 'components/utils/Panel';
import { LinkText, withReference } from 'components/utils/Styles';
import ComingSoon from 'components/utils/ComingSoon';
import { applyTestIdentifier } from 'helpers/development';

const containerStyle = css`
  margin: 20px;
  text-align: left;
`;

export const menuItemPropTypes = PropTypes.arrayOf(PropTypes.oneOfType([
  PropTypes.node,
  PropTypes.func,
  PropTypes.string,
  PropTypes.shape({
    /** True to wrap element in ComingSoon, false otherwise. */
    comingSoon: PropTypes.bool,
    /** The element that will wrap this component. Default to LinkText */
    element: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
    /** Text to display as the menu item link. */
    text: PropTypes.oneOfType([PropTypes.func, PropTypes.node, PropTypes.string]),
    /** True to render this item in full, false otherwise */
    divider: PropTypes.bool,
    /** Custom rendering for this item, most useful for popups. Does not fire onClick. */
    renderItem: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.oneOf(['divider']),
    ]),
  }),
]));

export const MenuItem = withReference(LinkText.withComponent('div'));

export const MenuDivider = styled(MenuItem)`
  text-decoration: none !important;
  cursor: default;
`;

export const MenuDisabled = styled(MenuItem)`
  text-decoration: none !important;
  cursor: default;
  opacity: 0.5;
`;

const getItemClickHandler = (item, index, onClick, onClose) => {
  if (item.divider || item.comingSoon || item.renderItem) {
    return undefined;
  }
  return (e) => {
    e.preventDefault();
    Promise.resolve(true)
      .then(() => { onClose(); })
      .then(() => {
        setTimeout(
          () => { onClick(item, index); },
          100,
        );
      });
  };
};

const Menu = ({
  items, onClick, onClose, spacing, className,
}) => (
  <Panel className={cx(containerStyle, className)} orientation="vertical" spacing={spacing}>
    {items.map((item, index) => {
      const MenuComponent = item.divider
        ? MenuDivider
        : item.comingSoon
          ? MenuDisabled
          : (item.element || MenuItem);
      const component = (
        <MenuComponent
          key={item.key || index}
          {...applyTestIdentifier(['menu', item.key].filter(Boolean).join('_'))}
          onClick={item.comingSoon ? undefined : getItemClickHandler(item, index, onClick, onClose)}
        >
          {item.divider && item.renderItem === 'divider'
            ? <Divider margin={10} />
            : item.renderItem
              ? item.renderItem(onClose)
              : item.text || item}
        </MenuComponent>
      );
      if (item.comingSoon) {
        return (
          <ComingSoon asLabel orientation="vertical" align="left" spacing={2}>
            {component}
          </ComingSoon>
        );
      }
      return component;
    })}
  </Panel>
);
Menu.propTypes = {
  /** Handler for menu item click. Passes the item and item index as params */
  onClick: PropTypes.func,
  /** Handler for when menu is closed */
  onClose: PropTypes.func,
  /**
   * Item to show. These can be a string or node to render for each item,
   * or an object with a text property. The items need not be homogenous.
  */
  items: menuItemPropTypes.isRequired,
  spacing: PropTypes.number,
  className: PropTypes.string,
};
Menu.defaultProps = {
  onClick: () => {},
  onClose: () => {},
  spacing: 10,
  className: undefined,
};

export default Menu;

/* eslint-disable react/no-danger */

import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { css } from 'emotion';
import { createTranslator } from 'helpers/i18n';
import System from 'models/System';
import Provider from 'models/Provider';

import * as actions from 'ducks/system';

import {
  LoadingPanel,
  SplitPanel,
  Panel,
  Icon,
  Button,
  ButtonBar,
  Spinner,
  Divider,
  Colors,
} from 'components/utils';

import ProviderForm from 'components/providers/ProviderForm';

const { RECEIVING_DARKER_FILL, WHITE } = Colors;

const tr = createTranslator({
  instructions: 'Let\'s get started. To create a new organization, please complete the details below. Aidin will then review the details and confirm your access.',
  button: {
    back: 'Back',
    submit: 'Create 1 Organization',
  },
});

const instructionsPanelStyle = css`
  width: 460px;
  margin-bottom: 20px;
`;

const actionBarStyle = css`
  margin: 0px 90px;
`;

const containerStyle = css`
  margin-top: 30px;
`;

class CreateOrganizationStepper extends React.Component {
  static propTypes = {
    /** Go to previous page */
    onNavigatePrevious: PropTypes.func.isRequired,
    /** Save changes to server */
    onSave: PropTypes.func.isRequired,
    /** User information */
    user: PropTypes.shape({
      email: PropTypes.string,
      first: PropTypes.string,
      last: PropTypes.string,
    }),
    /** Provider information */
    provider: Provider,
    /** Load system data */
    requestSystem: PropTypes.func.isRequired,
    /** Changes are being saved */
    saving: PropTypes.bool,
    /** System data */
    system: System,
  }

  static defaultProps = {
    saving: false,
    system: {},
    provider: {},
    user: {},
  }

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  UNSAFE_componentWillMount() {
    const {
      requestSystem,
      system,
    } = this.props;

    const next = () => {
      this.setState({ loading: false });
    };

    if (system.states) {
      next();
    } else {
      requestSystem().then(next);
    }
  }

  renderForm = () => (
    <ProviderForm
      ref={(ref) => { this.form = ref; }}
      provider={{
        ...this.props.user,
        ...this.props.provider,
      }}
    />
  )

  renderInstructions = () => (
    <Panel align="center">
      <Panel className={instructionsPanelStyle}>
        <h4>{tr('instructions')}</h4>
        <Divider margin={0} />
      </Panel>
    </Panel>
  );

  renderIcon = name => (
    <Icon name={name} size={30} color={WHITE} />
  )

  renderActionBar = () => {
    const { saving } = this.props;

    if (saving) {
      return (
        <ButtonBar align="center">
          <Spinner color={RECEIVING_DARKER_FILL} />
        </ButtonBar>
      );
    }

    return (
      <SplitPanel className={actionBarStyle}>
        <Button
          leftIcon={this.renderIcon('chevron-left')}
          onClick={this.props.onNavigatePrevious}
        >
          {tr('button.back')}
        </Button>
        <Button
          rightIcon={this.renderIcon('chevron-right')}
          onClick={this.props.onSave}
          color={RECEIVING_DARKER_FILL}
        >
          {tr('button.submit')}
        </Button>
      </SplitPanel>
    );
  }

  render() {
    if (this.state.loading) {
      return <LoadingPanel height="400px" gray />;
    }

    return (
      <Panel className={containerStyle}>
        {this.renderInstructions()}
        {this.renderForm()}
        {this.renderActionBar()}
      </Panel>
    );
  }
}

export default connect(({ system }) => ({ system }), actions, null, { forwardRef: true })(
  CreateOrganizationStepper,
);

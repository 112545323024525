import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';

import * as messageActionsImports from 'ducks/messages';
import * as conversationActionsImports from 'ducks/conversations';

export const MessageApi = PropTypes.shape({
  closeMessageThread: PropTypes.func.isRequired,
  /** ActionCable handler for MessagesChannel */
  refreshMessages: PropTypes.func.isRequired,
  /** ActionCable handler for a specific thread */
  refreshMessageThread: PropTypes.func.isRequired,
  /** After load, request message count */
  requestMessageUnreadCount: PropTypes.func.isRequired,
  requestMessageThreadById: PropTypes.func.isRequired,
  requestNewMessageThread: PropTypes.func.isRequired,
  sendMessage: PropTypes.func.isRequired,
});

const withConversationActions = WrappedComponent => (props) => {
  const {
    session: { features } = {},
    messageActions: legacy,
    conversationActions: current,
  } = props;

  const getMessagingActions = (feature) => {
    if (features[feature]) {
      return current;
    }
    return legacy;
  };

  return (
    <WrappedComponent
      {...props /* eslint-disable-line */}
      getMessagingActions={getMessagingActions}
    />
  );
};

const connectedState = ({ session }) => ({ session });

const connectedProps = dispatch => ({
  messageActions: bindActionCreators(messageActionsImports, dispatch),
  conversationActions: bindActionCreators(conversationActionsImports, dispatch),
});

export default compose(
  connect(connectedState, connectedProps),
  withConversationActions,
);

/* eslint-disable react/no-array-index-key */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion';

import * as colors from 'components/utils/Colors';

import Tip from 'components/utils/Tip';

const { TIP_DEFAULT, TIP_WARNING, TIP_ERROR } = colors;

const tipColors = {
  error: TIP_ERROR,
  warning: TIP_WARNING,
  info: TIP_DEFAULT,
};

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Main = styled.div`
  padding: 20px;
`;

const TipContainer = styled.div`
  margin-top: 1px;
`;

const renderFooter = ({ footer, tips }) => {
  if (footer) {
    return footer;
  } if (tips) {
    return tips.map((item, idx) => (
      <TipContainer key={idx}>
        <Tip orientation="horizontal" color={tipColors[item.type]}>{item.tip}</Tip>
      </TipContainer>
    ));
  }
  return null;
};

/**
 * Standard container for search filter popups.
 */
const ProviderSearchPopupContent = props => (
  <Container>
    <Main>{props.children}</Main>
    {(props.footer || props.tips) && <div>{renderFooter(props)}</div>}
  </Container>
);
ProviderSearchPopupContent.propTypes = {
  /** Content for the popup. Passes `onChange` data change listener */
  children: PropTypes.node.isRequired,
  /**
   * Optional custom footer content. This will override the
   * standard tips list footer.
   */
  footer: PropTypes.node,
  /**
   * A list of tips with a given type (to determine the color)
   * that will be placed in the footer. Will be ignored if a
   * custom footer is passed.
   */
  tips: PropTypes.arrayOf(PropTypes.shape({
    tip: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['info', 'warning', 'error']),
  })),
};
ProviderSearchPopupContent.defaultProps = {
  footer: undefined,
  tips: undefined,
};

export default ProviderSearchPopupContent;

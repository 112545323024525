import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ThemeContext } from 'components/utils/ThemeContext';

const AidinIcon = (props) => {
  const { fill, ...other } = props;
  const { version: v, theme } = useContext(ThemeContext);

  const Icon = v === 'v2' ? (
    <svg viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
      <path fillRule="evenodd" clipRule="evenodd" d="M6.24749 0H9.49751C11.6699 0 12.4577 0.226191 13.2519 0.650929C14.046 1.07567 14.6693 1.69895 15.0941 2.49315C15.5188 3.28734 15.745 4.0751 15.745 6.24749V11.7468C15.745 13.9192 15.5188 14.7069 15.0941 15.5011C14.6693 16.2953 14.046 16.9186 13.2519 17.3434C12.4577 17.7681 11.6699 17.9943 9.49751 17.9943H6.24749C4.0751 17.9943 3.28734 17.7681 2.49315 17.3434C1.69895 16.9186 1.07567 16.2953 0.650929 15.5011C0.226191 14.7069 0 13.9192 0 11.7468L0 6.24749C0 4.0751 0.226191 3.28734 0.650929 2.49315C1.07567 1.69895 1.69895 1.07567 2.49315 0.650929C3.28734 0.226191 4.0751 0 6.24749 0Z" fill={fill} />
      <path fillRule="evenodd" clipRule="evenodd" d="M8.69553 6.54553C8.80693 6.54553 8.84733 6.55713 8.88806 6.57891C8.92879 6.60069 8.96075 6.63266 8.98253 6.67339C9.00431 6.71411 9.01591 6.75451 9.01591 6.86592V9.54458H11.6946C11.806 9.54458 11.8464 9.55618 11.8871 9.57796C11.9278 9.59974 11.9598 9.63171 11.9816 9.67243C12.0034 9.71316 12.015 9.75356 12.015 9.86496V11.4735C12.015 11.5849 12.0034 11.6253 11.9816 11.666C11.9598 11.7067 11.9278 11.7387 11.8871 11.7605C11.8464 11.7823 11.806 11.7939 11.6946 11.7939H9.01591V14.4725C9.01591 14.5839 9.00431 14.6243 8.98253 14.6651C8.96075 14.7058 8.92879 14.7378 8.88806 14.7595C8.84733 14.7813 8.80693 14.7929 8.69553 14.7929H7.08701C6.97561 14.7929 6.93521 14.7813 6.89448 14.7595C6.85375 14.7378 6.82179 14.7058 6.80001 14.6651C6.77823 14.6243 6.76663 14.5839 6.76663 14.4725V11.7937L4.08796 11.7939C3.97656 11.7939 3.93616 11.7823 3.89543 11.7605C3.8547 11.7387 3.82274 11.7067 3.80096 11.666C3.77918 11.6253 3.76758 11.5849 3.76758 11.4735V9.86496C3.76758 9.75356 3.77918 9.71316 3.80096 9.67243C3.82274 9.63171 3.8547 9.59974 3.89543 9.57796C3.93616 9.55618 3.97656 9.54458 4.08796 9.54458L6.76663 9.54438V6.86592C6.76663 6.75451 6.77823 6.71411 6.80001 6.67339C6.82179 6.63266 6.85375 6.60069 6.89448 6.57891C6.93521 6.55713 6.97561 6.54553 7.08701 6.54553H8.69553Z" fill={theme.colors.WHITE} />
      <path d="M7.93424 5.15705C8.59151 5.15705 9.12434 4.62423 9.12434 3.96695C9.12434 3.30968 8.59151 2.77686 7.93424 2.77686C7.27697 2.77686 6.74414 3.30968 6.74414 3.96695C6.74414 4.62423 7.27697 5.15705 7.93424 5.15705Z" fill={theme.colors.WHITE} />
    </svg>
  ) : (
    <svg viewBox="0 0 81 81" xmlns="http://www.w3.org/2000/svg" {...other}>
      <path d="M40.421 22.285c3.72 0 6.735-3 6.735-6.7s-3.014-6.7-6.735-6.7c-3.719 0-6.733 3-6.733 6.7s3.014 6.7 6.733 6.7zm-6.733 20.048H19.546a1.34 1.34 0 0 0-1.346 1.334v10.731c0 .733.603 1.334 1.346 1.334h14.142v13.402c0 .74.6 1.339 1.34 1.339h10.788c.735 0 1.34-.6 1.34-1.34v-13.4h13.468a1.34 1.34 0 0 0 1.347-1.335V43.667c0-.733-.603-1.334-1.347-1.334H47.156v-14.07c0-.742-.6-1.341-1.34-1.341H35.028c-.736 0-1.34.599-1.34 1.34v14.07zm40.258 37.433H6.224C2.787 79.766 0 76.996 0 73.573V6.193C0 2.772 2.782 0 6.224 0h67.722c3.438 0 6.224 2.768 6.224 6.192v67.38c0 3.42-2.782 6.194-6.224 6.194z" fill={fill} fillRule="evenodd" />
    </svg>
  );
  return Icon;
};
AidinIcon.propTypes = {
  /** The icon color */
  fill: PropTypes.string,
};
AidinIcon.defaultProps = {
  fill: '#FFF',
};

export default AidinIcon;

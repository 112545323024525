import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'react-emotion';
import { css } from 'emotion';
import { createTranslator } from 'helpers/i18n';
import { trBadgeName } from 'translations/badges';
import { BadgeModel } from 'models/System';
import { mq } from 'components/utils/Responsive';

import Badge from 'components/utils/Badge';
import Link from 'components/utils/Link';
import Panel from 'components/utils/Panel';
import TitleBar from 'components/utils/TitleBar';
import * as Colors from 'components/utils/Colors';
import * as Styles from 'components/utils/Styles';

const {
  GRAY_FILL,
  SENDING_BADGE_FILL,
  RECEIVING_BADGE_FILL,
  ERROR_LIGHT_FILL,
} = Colors;

const {
  Label, LinkText, baseModalStyle,
} = Styles;

const tr = createTranslator({
  title: {
    progress: 'Badge Progress!',
    warning: 'Badge Warning',
  },
  learnMore: 'Learn more',
});

const Palette = createTranslator({
  search: () => RECEIVING_BADGE_FILL,
  provider: () => RECEIVING_BADGE_FILL,
  user: ({ roles = [] }) => (roles.some(r => r === 'receiving') ? RECEIVING_BADGE_FILL : SENDING_BADGE_FILL),
});

const Container = styled.div`
  ${css(mq({ width: ['400px', '320px', '320px'] }))}
  height: 300px;
  ${baseModalStyle}
  border: none;
`;

const PopupContent = styled.div`
  padding: 15px;
`;

const Title = styled.span();

const ContentArea = styled.div`
  display: flex;
`;

const BadgeContainer = styled.div`
  flex-basis: 150px;
`;

const TextContent = styled.div`
  flex-basis: 250px;
  ${LinkText} {
    font-size: inherit;
  };
`;

/**
 * General toastable component to display progress information about
 * a badge. Takes care of proper color theme for badges, SVG and naming.
 */
const BadgeToast = ({
  name,
  theme,
  text,
  onClose,
  badges = [],
}) => {
  const badge = badges.find(({ key: id }) => id === name);
  const badgeName = trBadgeName({
    ...badge,
    state: theme === 'progress' ? 'active' : badge.state,
  });
  const color = Palette(badge.category, badge);
  return (
    <Container>
      <TitleBar
        closeable
        large
        color={theme === 'warning' ? ERROR_LIGHT_FILL : color}
        onIconClick={onClose}
      >
        <Title dangerouslySetInnerHTML={{ __html: tr(`title.${theme}`) }} />
      </TitleBar>
      <PopupContent>
        <ContentArea>
          <BadgeContainer>
            <Panel spacing={10}>
              <Badge
                name={name}
                size={50}
                raised={theme === 'progress' ? color : GRAY_FILL}
              />
              <Label>{badgeName}</Label>
            </Panel>
          </BadgeContainer>
          <TextContent>
            {text} <Link to="/about-badges"><LinkText>{tr('learnMore')}</LinkText></Link>.
          </TextContent>
        </ContentArea>
      </PopupContent>
    </Container>
  );
};
BadgeToast.propTypes = {
  /** The key for this badge */
  name: PropTypes.string.isRequired,
  /** The type of toast, either a progress indicator or a warning */
  theme: PropTypes.oneOf(['progress', 'warning']),
  /** The text to use for this toast */
  text: PropTypes.string.isRequired,
  /** Close handler from the toast */
  onClose: PropTypes.func,
  /** From `connect`, a listing of system badges */
  badges: PropTypes.arrayOf(BadgeModel).isRequired,
};
BadgeToast.defaultProps = {
  onClose: undefined,
  theme: 'progress',
};

export default connect(({ system }) => ({ badges: system.badges }))(BadgeToast);

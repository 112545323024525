import React from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion';
import { createTranslator, pluralize } from 'helpers/i18n';
import { errorToast } from 'helpers/ui';
import { applyTestIdentifier } from 'helpers/development';

import {
  ButtonBar,
  Button,
  Panel,
  SplitPanel,
  Divider,
  Styles,
  Colors,
} from 'components/utils';

import GenericProviderSearchResult from 'components/providers/GenericProviderSearchResult';

const { LinkText, Secondary } = Styles;

const { RECEIVING_DARKER_FILL } = Colors;

const LOAD_INCREMENT = 10;
const INITIAL_SIZE = 4;

const tr = createTranslator({
  title: {
    list: count => `${count} Similar ${pluralize(count, 'Provider')}`,
    claims: count => `${count} ${pluralize(count, 'Provider')} to Claim`,
  },
  button: {
    join: 'Join Team',
    claim: 'Claim Provider',
    remove: 'Remove',
    none: 'I Can\'t Find My Organization',
  },
  confirm: {
    join: 'Provider contacted',
  },
  empty: 'No providers found!',
  loadMore: count => `Load ${count} more`,
});

const SectionTitle = styled.h4`
  margin-bottom: 4px;
`;

class ProviderLookupSearchPanel extends React.Component {
  static propTypes = {
    claims: PropTypes.arrayOf(PropTypes.shape({})),
    contacted: PropTypes.arrayOf(PropTypes.shape({})),
    user: PropTypes.shape({}),
    results: PropTypes.shape({
      index: PropTypes.arrayOf(PropTypes.number).isRequired,
      content: PropTypes.shape({}).isRequired,
    }).isRequired,
    onRequestContent: PropTypes.func,
    onRequestJoinOrganization: PropTypes.func,
    onAddOrganizationToClaim: PropTypes.func,
    onRemoveOrganizationFromClaim: PropTypes.func,
    onClickNoResults: PropTypes.func,
  }

  static defaultProps = {
    claims: [],
    contacted: [],
    user: null,
    onRequestContent: null,
    onRequestJoinOrganization: null,
    onAddOrganizationToClaim: null,
    onRemoveOrganizationFromClaim: null,
    onClickNoResults: null,
  }

  constructor(props) {
    super(props);
    this.state = {
      cursor: INITIAL_SIZE,
    };
  }

  getProviders = () => {
    const { claims, results: { index, content } } = this.props;
    const { cursor } = this.state;

    return index
      .slice(0, cursor)
      .filter(id => !claims.some(({ id: savedId }) => id === savedId))
      .map(id => content[id])
      .filter(i => !!i);
  }

  getClaimedProviders = () => {
    const { claims } = this.props;

    return claims;
  }

  handleResultAction = provider => () => {
    if (provider.member_count === 0) {
      if (this.props.onAddOrganizationToClaim) {
        return this.props.onAddOrganizationToClaim(provider);
      }
    } else if (this.props.onRequestJoinOrganization) {
      return this.props.onRequestJoinOrganization(provider);
    }
    return null;
  }

  handleClaimAction = provider => () => this.props.onRemoveOrganizationFromClaim(provider)

  handleClickLoadMore = () => {
    const { onRequestContent, results: { index, content } } = this.props;
    const { cursor } = this.state;

    const itemsToFetch = index.slice(cursor, LOAD_INCREMENT).filter(id => !content[id]);
    if (itemsToFetch.length === 0) {
      this.setState({ cursor: cursor + LOAD_INCREMENT });
    } else {
      onRequestContent(itemsToFetch).then(() => {
        this.setState({ cursor: cursor + LOAD_INCREMENT });
      }).catch(() => {
        errorToast(tr('toast.error'));
      });
    }
  }

  renderLoadMoreLink = () => {
    const { cursor } = this.state;
    const { results: { index } } = this.props;
    if (cursor >= index.length) { return null; }
    const nextIndex = cursor + LOAD_INCREMENT;
    const nextPageCount = nextIndex > index.length ? index.slice(cursor).length : LOAD_INCREMENT;
    return (
      <LinkText css="margin-left: 10px" onClick={this.handleClickLoadMore}>
        {tr('loadMore', nextPageCount)}
      </LinkText>
    );
  }

  renderNotFound = () => (
    <>
      <Divider />
      <ButtonBar align="center">
        <Button small onClick={this.props.onClickNoResults}>
          {tr('button.none')}
        </Button>
      </ButtonBar>
    </>
  )

  render() {
    const {
      user,
      results: { index = [] },
      claims = [],
      contacted = [],
    } = this.props;

    if (index.length === 0 && claims.length === 0) {
      return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Panel {...applyTestIdentifier('provider-lookup-search-container')}>
          <Secondary>{tr('empty')}</Secondary>
          {this.renderNotFound()}
        </Panel>
      );
    }

    const providers = this.getProviders();

    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <Panel {...applyTestIdentifier('provider-lookup-search-container')}>
        <SplitPanel spacing={claims.length === 0 ? 'around' : 'between'} basis="48%">
          <Panel>
            <div>
              <SectionTitle>{tr('title.list', index.length)}:</SectionTitle>
              <Divider margin={0} />
            </div>
            <Panel spacing={10}>
              {providers.length === 0 && (
                <Secondary>{tr('empty')}</Secondary>
              )}
              {providers.map(provider => (
                <GenericProviderSearchResult
                  key={provider.id}
                  disableMemberSearch
                  actionButtonText={provider.member_count === 0
                    ? tr('button.claim') : tr('button.join')}
                  actionCompletedText={tr('confirm.join')}
                  actionComplete={provider.member_count > 0
                    && contacted.some(({ id }) => id === provider.id)}
                  showAction={!!user}
                  showProviderType
                  provider={provider}
                  onActionClick={this.handleResultAction(provider)}
                />
              ))}
              {this.renderLoadMoreLink()}
            </Panel>
          </Panel>
          {claims.length > 0 && (
            <Panel>
              <div>
                <SectionTitle>{tr('title.claims', claims.length)}:</SectionTitle>
                <Divider margin={0} />
              </div>
              <Panel spacing={10}>
                {this.getClaimedProviders().map(provider => (
                  <GenericProviderSearchResult
                    key={provider.id}
                    disableMemberSearch
                    actionButtonText={tr('button.remove')}
                    actionButtonColor={RECEIVING_DARKER_FILL}
                    showAction={!!user}
                    showProviderType
                    provider={provider}
                    onActionClick={this.handleClaimAction(provider)}
                  />
                ))}
              </Panel>
            </Panel>
          )}
        </SplitPanel>
        {claims.length === 0 && this.renderNotFound()}
      </Panel>
    );
  }
}

export default ProviderLookupSearchPanel;

import React from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { withRouter } from 'react-router';

/**
 * Map here any routes that should go against the grain somehow.
 */
const substitutions = [
  /** Treat show page with index route as a show page */
  {
    match: /\/referrals\/[0-9]+$/,
    replace: path => `${path}/overview`,
  },
  /** Treat tabs as anchors so the page doesn't jump while in the same channel */
  {
    match: /\/referrals\/[0-9]+\/details\/.*$/,
    replace: path => `${path.split('/').slice(0, -1).join('/')}#${path.split('/').slice(-1).join('/')}`,
  },
];

/**
 * A naive implementation of scroll to top behavior for react router.
 *
 * The default behavior to trigger a scroll to the top of the page is for
 * a route to be a different "channel" than the previous route. That is,
 * routes like:
 *
 * old: /referrals
 * new: /providers
 *
 * will always jump to the the top. Channels extend recursively, such that
 * the following routes are considered to be in the same channel:
 *
 * old: /referrals/1/details/all
 * new: /referrals/1/details/face-sheet
 *
 * Where "channel" is `/referrals/1/details`.
 *
 * Routes that do not share the same number of tokens inherently are not in
 * the same channel; this represents a parent/child relationship, i.e.:
 *
 * old: /referrals
 * new: /referrals/1
 *
 * As such, these pages will always scroll to top.
 *
 * Exceptions for certain routes have been made in the substititions method.
 */
class ScrollToTop extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    location: ReactRouterPropTypes.location.isRequired,
  }

  static defaultProps = {
    children: undefined,
  }

  componentDidUpdate(prevProps) {
    let reset = false;
    if (this.props.location.hash === '#top') {
      // always reset to top for #top
      reset = true;
    } else if (this.props.location.hash) {
      // any other #hash do not reset
      reset = false;
    } else if (this.props.location !== prevProps.location) {
      // use sibling-detection logic
      const prev = prevProps && this.getPathTokens(prevProps);
      const next = this.getPathTokens(this.props);

      if (prev.length === next.length) {
        reset = prev.slice(0, -1).some((token, index) => next[index] !== token);
      } else {
        reset = true;
      }
    }
    if (reset) {
      // This handles most cases without a visible jump
      window.scrollTo(0, 0);
      // This makes sure the jump happens even if it needs to be visible
      window.setTimeout(() => { window.scrollTo(0, 0); }, 100);
    }
  }

  getPathTokens = (props) => {
    const { location: { pathname } } = props;
    // The root should be treated as an empty array (parent/child), not the channel ""
    if (pathname === '/') return [];
    const substitution = substitutions.find(s => !!pathname.match(s.match));
    const path = substitution ? substitution.replace(pathname) : pathname;
    return path.substring(1).split('/');
  }

  render() {
    return React.cloneElement(this.props.children, { location: this.props.location });
  }
}

export default withRouter(ScrollToTop);

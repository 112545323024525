import React from 'react';
import PropTypes from 'prop-types';
import AidinPropTypes from 'helpers/AidinPropTypes';
import { css } from 'emotion';

import { createTranslator, numeric, pluralize } from 'helpers/i18n';
import markdown from 'helpers/markdown';

import withTimeLimit from 'components/utils/withTimeLimit';
import RelativeTime from 'components/utils/RelativeTime';
import { WHITE } from 'components/utils/Colors';

const tr = createTranslator({
  message: text => markdown(`You have ${text} to change your password until you lose access to Aidin. Change at least 1 character to keep your access. [Click here to update](/admin).`),
  formatter: {
    before: ({ since, now }) => {
      const days = since.diff(now, 'days');
      return numeric(days, {
        0: 'no time remaining',
        other: `${days} ${pluralize(days, 'day', 'days')} left`,
      });
    },
    other: 'no time remaining',
  },
});

const textStyle = css`
  a > span {
    color: ${WHITE} !important;
  }
`;

/**
 * Displays message regarding the days remaining for a user to change their
 * password before they potentially lose access to the system.
 */
const PasswordTimer = ({
  era,
  value,
}) => {
  if (era === 'before') { return null; }
  return (
    <RelativeTime
      className={textStyle}
      unit="days"
      formatter={tr('formatter')}
      template={text => tr('message', text)}
      value={value}
    />
  );
};
PasswordTimer.propTypes = {
  /** The era based on withTimeLimit. Affects how the message is rendered. */
  era: PropTypes.string.isRequired,
  /** The date the password expires, 90 days after the last time it was updated */
  value: AidinPropTypes.dateTime.isRequired,
};

export default withTimeLimit(PasswordTimer);

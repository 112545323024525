/* eslint-disable react/no-typos, react/default-props-match-prop-types */

import React from 'react';
import PropTypes from 'prop-types';

import Popup from 'components/utils/Popup';
import Menu, { menuItemPropTypes } from 'components/utils/Menu';

export const popupMenuProps = {
  /** Handler for menu item click. Passes the item and item index as params */
  onClick: PropTypes.func,
  /**
   * Item to show. These can be a string or node to render for each item,
   * or an object with a text property. The items need not be homogenous.
  */
  items: menuItemPropTypes,
  /** The offset (X-axis) for the menu */
  menuOffsetX: PropTypes.number,
  /** The offset (Y-axis) for the menu */
  menuOffsetY: PropTypes.number,
  /** The position to display the menu when the gear is clicked. */
  menuPosition: PropTypes.string,
  /** Spacing of the menu items, default to 10 */
  menuSpacing: PropTypes.number,
  /** Width of the menu, defaults to 300. Height always auto. */
  menuWidth: PropTypes.number,
};

/**
 * Displays a popup menu when given trigger is clicked. The
 * items can be simple strings, formatted text, or custom nodes.
 */
const PopupMenu = ({
  disableScrolling, menuOffsetX, menuOffsetY, menuPosition, menuSpacing, menuWidth,
  items, onClick, onOpen, trigger,
}) => (
  <Popup
    disableScrolling={disableScrolling}
    trigger={trigger}
    onOpen={onOpen}
    offsetX={menuOffsetX}
    offsetY={menuOffsetY}
    position={menuPosition}
    width={menuWidth || PopupMenu.defaultProps.menuWidth}
  >
    {({ close }) => (
      <Menu
        items={items}
        spacing={menuSpacing}
        onClose={close}
        onClick={onClick}
      />
    )}
  </Popup>
);
PopupMenu.propTypes = {
  ...popupMenuProps,
  disableScrolling: PropTypes.bool,
  /** Listener for when popup is opened */
  onOpen: PropTypes.func,
  /** The node that triggers the display of the popup */
  trigger: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
};
PopupMenu.defaultProps = {
  menuOffsetX: undefined,
  menuOffsetY: undefined,
  menuPosition: 'bottom center',
  disableScrolling: true,
  menuSpacing: undefined,
  menuWidth: 300,
  onClick: undefined,
  onOpen: undefined,
};

export default PopupMenu;

import React from 'react';
import PropTypes from 'prop-types';
import { createTranslator } from 'helpers/i18n';

import Panel from 'components/utils/Panel';
import { LinkText } from 'components/utils/Styles';

const tr = createTranslator({
  showMore: 'Show More',
});

/**
 * ShowMore component abstracts away the state management of truncating
 * text and showing a 'show more' link if necessary.
 */
class ShowMore extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      more: props.text.length > props.truncateAt,
    };
  }

  render() {
    const Component = this.props.element;
    const { more } = this.state;
    const { truncateAt, text } = this.props;
    const t = more ? `${text.substring(0, truncateAt - 1)}...` : text;
    return (
      <Panel>
        <Component>{t}</Component>
        {this.state.more && (
          <LinkText
            onClick={() => {
              this.setState({ more: false });
            }}
          >
            {this.props.linkText}
          </LinkText>
        )}
      </Panel>
    );
  }
}

ShowMore.propTypes = {
  /** Character limit to start truncating at */
  truncateAt: PropTypes.number.isRequired,
  /** Text to be shown and truncating if its length is greater than truncateAt */
  text: PropTypes.string.isRequired,
  /** Customize the 'show more' link text */
  linkText: PropTypes.string,
  /** Defines the element the text is wrapped in.
   * Support simple text, element, or function (styled component) */
  element: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.element]),
};

ShowMore.defaultProps = {
  element: 'div',
  linkText: tr('showMore'),
};

export default ShowMore;

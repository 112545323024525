import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { cx, css } from 'emotion';

/**
 * IE10: Wrap react-router-dom's Link with compatibility hacks. Forcing
 * display: inline-block here allows IE10's flexbox implementation to work on
 * anchors. Because of the nature of this hack, if you want a different
 * display property, override it using inline styles via the style prop
 * on the Link component.
 */
const CompatibleLink = ({
  className,
  inline,
  noUnderline,
  ...passThrough
}) => (
  <Link
    {...passThrough /* eslint-disable-line react/jsx-props-no-spreading */}
    className={cx(
      className,
      css({ display: inline ? 'inline' : 'inline-block' }),
      noUnderline && css({ textDecoration: 'none' }),
    )}
  />
);

CompatibleLink.propTypes = {
  /** Optional class name for container */
  className: PropTypes.string,
  /** Option HTML id */
  id: PropTypes.string,
  inline: PropTypes.bool,
  noUnderline: PropTypes.bool,
};

CompatibleLink.defaultProps = {
  className: '',
  id: undefined,
  inline: undefined,
  noUnderline: undefined,
};

export default CompatibleLink;

/* eslint-disable camelcase */

import initialState from 'store/initialState';
import * as types from './types';

const providerSearch = (state = initialState.providerSearch, payload) => {
  const { type, ...action } = payload;
  switch (type) {
    case types.REPLACE_PROVIDER_SEARCH_RESULTS:
      return {
        ...state,
        ...action,
      };
    case types.RESET_PROVIDER_SEARCH:
      return initialState.providerSearch;
    case types.SYNC_PROVIDER_SEARCH_SELECTION: {
      const { rows } = action;
      const results = state.results.slice();
      rows.forEach(({ provider_id, checked }) => {
        const result = results.find(sr => sr.id === provider_id);
        if (result) { result.checked = checked; }
      });
      return {
        ...state,
        results,
      };
    }
    case types.REPLACE_PROVIDER_FAX: {
      const { providerId, fax } = action;
      const { results } = state;
      const updated = results.slice();
      const index = updated.findIndex(p => p.id === providerId);
      updated[index].fax = fax;

      return { ...state, results: updated };
    }
    case types.REPLACE_PROVIDER_SEARCH_WARNINGS:
      return {
        ...state,
        ...action,
      };
    default: {
      return state;
    }
  }
};

export default { providerSearch };

import React from 'react';
import PropTypes from 'prop-types';
import UA from 'ua-parser-js';

/**
 * detect IE
 * returns version of IE or false, if browser is not Internet Explorer
 */
export const detectIE = () => {
  const ua = UA(window.navigator.userAgent);
  if (ua && ua.browser && ['IE'].some(i => i === ua.browser.name)) {
    return parseInt(ua.browser.major, 10);
  }

  // other browser
  return false;
};

/**
* returns the argument only if rendering on IE. Otherwise,
* returns an empty string if argument is a string, or an empty
* object if the argument is an object.
*/
export const ieOnly = (style) => {
  if (detectIE()) return style;
  if (typeof style === 'string') return '';
  return {};
};

/**
* returns the argument only if not rendering on IE. Otherwise,
* returns an empty string if argument is a string, or an empty
* object if the argument is an object.
*/
export const ieNot = (style) => {
  if (!detectIE()) return style;
  if (typeof style === 'string') return '';
  return {};
};

/**
* returns the argument only if rendering on IE less than the
* specified version. Otherwise, returns an empty string if
* argument is a string, or an empty object if the argument is
* an object.
*/
export const ieLt = (version, style) => {
  const ieVersion = detectIE();
  if (ieVersion && (ieVersion < version)) return style;
  if (typeof style === 'string') return '';
  return {};
};

/**
* returns the argument only if rendering on IE greater than
* the specified version. Otherwise, returns an empty string
* if argument is a string, or an empty object if the argument
* is an object.
*/
export const ieGt = (version, style) => {
  const ieVersion = detectIE();
  if (ieVersion && (ieVersion > version)) return style;
  if (typeof style === 'string') return '';
  return {};
};

/**
* returns the argument only if rendering on the specified IE
* version. Otherwise, returns an empty string if argument is
* a string, or an empty object if the argument is an object.
*/
export const ieEq = (version, style) => {
  if (detectIE() === version) return style;
  if (typeof style === 'string') return '';
  return {};
};

/**
* component for conditional rendering on IE
* <IE only>only renders on IE</IE> or
* <IE lt={12}>only renders on IE11 or less</IE>
* <IE not>renders on non-IE browsers</IE>
*/
const IE = ({
  only,
  lt,
  gt,
  eq,
  not,
  children,
}) => {
  const ieVersion = detectIE();
  if (
    (only && ieVersion)
    || (not && !ieVersion)
    || (lt && ieVersion && (ieVersion < lt))
    || (gt && ieVersion && (ieVersion > gt))
    || (eq && (ieVersion === eq))
  ) return <>{children}</>;
  return null;
};

IE.propTypes = {
  only: PropTypes.bool,
  not: PropTypes.bool,
  lt: PropTypes.number,
  gt: PropTypes.number,
  eq: PropTypes.number,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func, PropTypes.string]).isRequired,
};

IE.defaultProps = {
  only: true,
  not: false,
  lt: null,
  gt: null,
  eq: null,
};

export default IE;

import initialState from 'store/initialState';
import * as types from './types';

const notifications = (state = initialState.notifications, action) => {
  const { type, ...entity } = action;
  switch (type) {
    case types.REPLACE_NOTIFICATION_LIST:
      return {
        ...state,
        ...entity,
      };
    case types.RESET_UNREAD_COUNT:
      return {
        ...state,
        unread: 0,
      };
    case types.INCREMENT_UNREAD_COUNT:
      return {
        ...state,
        unread: state.unread + 1,
      };
    case types.DECREMENT_UNREAD_COUNT:
      return {
        ...state,
        unread: state.unread > 0 ? state.unread - 1 : 0,
      };
    default:
      return state;
  }
};

export default { notifications };

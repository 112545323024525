import React from 'react';
import mayRollbar from 'helpers/mayRollbar';
import ErrorHandler from 'helpers/ErrorHandler';
import { createTranslator } from 'helpers/i18n';
import PropTypes from 'prop-types';
/* global Rollbar */
const tr = createTranslator({
  support: 'Aidin Support',
});
const argsToQueryString = (args) => new URLSearchParams(
  Object.fromEntries(
    Object
      .entries(args)
      .filter(([, v]) => v),
  ),
).toString();
const open = (uuid = '', returnTo, err) => {
  window.open(`/zendesk_session/${uuid}?${argsToQueryString({ returnTo, id: err })}`);
};
const action = (returnTo) => {
  if (mayRollbar.isAvailable()) {
    try {
      ErrorHandler.get().enable();
    } catch (e) {
      console.error(e); // eslint-disable-line
    }
    Rollbar.info(
      'Support Request',
      (err, data) => {
        if (err) {
          open('error_contacting_rollbar', returnTo, err);
          // TODO: POST telemetry somewhere...
        } else {
          open(data?.result?.uuid, returnTo);
        }
      },
    );
  } else {
    open();
  }
};
const Link = ({ children, onClick, onKeyDown }) => (
  <span
    css={{ textDecoration: 'underline', cursor: 'pointer' }}
    role="link"
    tabIndex="0"
    onClick={onClick}
    onKeyDown={onKeyDown}
  >
    { children }
  </span>
);
const ZendeskSessionLink = ({
  children,
  component: Component,
  onClick,
  returnTo,
}) => (
  <Component
    onClick={() => {
      onClick();
      action(returnTo);
    }}
    onKeyDown={() => {
      onClick();
      action(returnTo);
    }}
  >
    {children || tr('support')}
  </Component>
);
ZendeskSessionLink.propTypes = {
  /** Pass optional children if a translated 'Aidin Support' legend is not wanted */
  children: PropTypes.element,
  /** Override the default component style (which is a clickable span) */
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.node, PropTypes.func]),
  /** Supply additional onClick behaviors, such as to close a modal or menu */
  onClick: PropTypes.func,
  /** Optional URL to return to, if not the default ZenDesk new request URL */
  returnTo: PropTypes.string,
};
ZendeskSessionLink.defaultProps = {
  component: Link,
  onClick: () => {},
  children: undefined,
  returnTo: 'https://myaidin.zendesk.com/hc/en-us/requests/new',
};
export default ZendeskSessionLink;

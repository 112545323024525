/* eslint-disable no-nested-ternary */

import React from 'react';
import PropTypes from 'prop-types';
import AidinPropTypes from 'helpers/AidinPropTypes';
import momentPropTypes from 'react-moment-proptypes';
import { Date, DateTime } from 'helpers/dates';

import { defaultRefreshRate } from 'components/utils/RelativeTime';

const getDisplayName = WrappedComponent => WrappedComponent.displayName || WrappedComponent.name || 'Component';

/**
 * A HOC that can be used to render components based on a breakpoint
 * in time. The HOC will emit an "era" prop to the WrappedComponent
 * of type "before" or "after," which can then be used for any
 * conditioanl rendering.
 *
 * withTimeLimit will run checks at smart interval, or a given interval,
 * but will stop checking once the breakpoint has been reached.
 */
const withTimeLimit = WrappedComponent => (class extends React.Component {
  static displayName = `withTimeLimit(${getDisplayName(WrappedComponent)})`;

  static propTypes = {
    /**
     * The date or datetime value that serves as the time limit break point.
     * Can be either a moment object or a date/datetime-compatible string.
     */
    breakpoint: PropTypes.oneOfType([
      momentPropTypes.momentObj,
      AidinPropTypes.date,
      AidinPropTypes.dateTime,
    ]),
    /**
     * True to disable auto-refresh, such that the first era
     * calculated is the only value received by the WrappedComponent.
     * (default false)
     */
    fixed: PropTypes.bool, // eslint-disable-line react/no-unused-prop-types
    /**
     * Supply a custom refresh rate. This can either be a fixed number, or
     * a function. In the case of a function, the time difference in ms
     * between the breakpoint and the current time will be supplied as an
     * argument.
     *
     * By default, the refresh rate uses `defaultRefreshRate`, which
     * refreshes 10s/min, 30s/hour, 30m/day, and daily after that.
     */
    // eslint-disable-next-line react/no-unused-prop-types
    refreshRate: PropTypes.oneOfType([PropTypes.number, PropTypes.func]),
    /**
     * The current time relative to the breakpoint. Replaces `now()` functions.
     */
    since: PropTypes.oneOfType([AidinPropTypes.date, AidinPropTypes.dateTime]),
    /**
     * The unit of time to check.
     */
    unit: PropTypes.oneOf(['second', 'minute', 'hour', 'day', 'week', 'month', 'year']),
  };

  static defaultProps = {
    breakpoint: undefined,
    fixed: undefined,
    refreshRate: defaultRefreshRate,
    since: undefined,
    unit: 'second',
  };

  constructor(props) {
    super(props);
    this.state = { era: null };
    this.interval = null;
    this.intervalFrequency = null;
  }

  UNSAFE_componentWillMount() {
    this.refresh();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.breakpoint !== this.props.breakpoint) {
      // New value, reset interval
      this.resetInterval();
    }
    this.refresh(nextProps);
  }

  componentWillUnmount() {
    this.resetInterval();
  }

  /**
   * Use either the actual current time, or the provided time.
   */
  getCurrentTime = () => (
    this.props.since ? this.getDateParser().parse(this.props.since) : this.getDateParser().now()
  );

  getDateParser = () => (['day', 'days'].some(v => v === this.props.unit) ? Date : DateTime);

  /**
   * Calculate the interval delay in ms based on the
   * given refresh rate. If no refresh rate is given,
   * use the defaultRefreshRate instead.
   */
  getIntervalDelay = (refreshRate, diff) => {
    let result = null;
    if (refreshRate) {
      switch (typeof refreshRate) {
        case 'number':
          result = refreshRate;
          break;
        case 'function':
          result = refreshRate(diff);
          break;
        default:
          break;
      }
    }
    return result || defaultRefreshRate(diff);
  }

  /**
   * Re-format the time string, and update the state if necessary.
   * Also, set or reset the refresh interval if necessary.
   */
  refresh = (props) => {
    const {
      breakpoint, fixed, refreshRate, unit,
    } = props || this.props;
    if (!breakpoint) {
      if (this.state.era) { this.setState({ era: null }); }
      return;
    }
    const now = this.getCurrentTime();
    const since = typeof breakpoint === 'string' ? this.getDateParser().parse(breakpoint) : breakpoint;
    const diff = now.diff(since);

    const era = now.isBefore(since, unit) ? 'before' : 'after';

    if (era !== this.state.era) {
      this.setState({ era });
    }

    if (fixed || era === 'after') {
      // Stop checking, can't go back in time.
      this.resetInterval();
    } else {
      const intervalFrequency = this.getIntervalDelay(refreshRate, Math.abs(diff));
      const currentFrequency = this.intervalFrequency;
      if (!this.interval || intervalFrequency !== currentFrequency) {
        this.resetInterval();
        this.intervalFrequency = intervalFrequency;
        this.interval = setInterval(this.refresh.bind(this), intervalFrequency);
      }
    }
  }

  resetInterval = () => {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = undefined;
      this.intervalFrequency = undefined;
    }
  }

  render() {
    const { breakpoint, unit, ...passThrough } = this.props;
    return <WrappedComponent breakpoint={breakpoint} era={this.state.era} {...passThrough} />;
  }
});

export default withTimeLimit;

import * as actions from './actions';

export const addItemToLoadingQueue = (key, metadata = {}) => {
  const timeoutId = setTimeout(() => {
    // After a set time, pop item from queue
    actions.popFromLoadingQueue(key);
  }, 60 * 1000);
  return actions.pushToLoadingQueue(key, timeoutId, metadata);
};

export const removeItemFromLoadingQueue = key => (
  actions.popFromLoadingQueue(key)
);

import React, { useContext } from 'react';
import { Route } from 'react-router-dom';
import styled from 'react-emotion';
import qs from 'query-string';

import { createTranslator } from 'helpers/i18n';

import {
  Link,
  Panel,
  Icon,
} from 'components/utils';
import { mq } from 'components/utils/Responsive';
import { ThemeContext } from 'components/utils/ThemeContext';

import text from './translations';

const tr = createTranslator(text);

const BackArrowContainer = styled.div(({ theme: { colors } }) => mq({
  width: '40px',
  marginBottom: '30px',
  a: {
    textAlign: 'center',
    color: colors.BLACK,
  },
}));

export const signInPath = '/devise_users/sign_in';

const NavigateToSignInLink = () => {
  const {
    theme: {
      colors: {
        ERROR_DARKER_FILL: ButtonColor,
      },
      version,
    },
  } = useContext(ThemeContext);
  // This component only applies to v2.
  if (version !== 'v2') {
    return null;
  }

  return (
    <Route
      path="/devise_users"
      render={({ location: { pathname, search } }) => {
        if (pathname === signInPath) {
          const query = qs.parse(search);
          if (query.creds !== 'invitation') {
            return null;
          }
        }
        return (
          <BackArrowContainer>
            <Link to={signInPath} noUnderline>
              <Panel orientation="horizontal" align="center">
                <Icon size={50} name="arrow-left" color={ButtonColor} />
                {tr('back')}
              </Panel>
            </Link>
          </BackArrowContainer>
        );
      }}
    />
  );
};

export default NavigateToSignInLink;

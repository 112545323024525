import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'whatwg-fetch';

import React from 'react';
import ReactDOM from 'react-dom';
import { shouldTrack, trackPageView } from 'helpers/analytics';

import { AppContainer } from 'react-hot-loader';

import {
  createStore, applyMiddleware, combineReducers, compose,
} from 'redux';
import { responsiveStoreEnhancer } from 'redux-responsive';

import { Provider } from 'react-redux';
import { createLogger } from 'redux-logger';
import thunkMiddleware from 'redux-thunk';

import reducer from 'reducers';

import LoginContainer from 'containers/LoginContainer';
import initialState from 'store/initialState';

import { ThemeProvider } from 'components/utils/ThemeContext';

import { FEATURE_UPGRADE_LOGIN_UI } from 'ducks/session/types';

import 'styles';

const reduxStateKeys = [
  'browser',
  'session',
  'system',
  'ui',
];

const buildStore = () => createStore(
  combineReducers(reduxStateKeys.reduce((set, key) => ({
    ...set,
    [key]: reducer[key],
  }), {})),
  reduxStateKeys.reduce((set, key) => ({
    ...set,
    [key]: initialState[key],
  }), {}),
  compose(
    responsiveStoreEnhancer,
    applyMiddleware(
      thunkMiddleware,
      createLogger(),
    ),
  ),
);

// The login pack doesn't have a router; record a pageview on load
if (shouldTrack) trackPageView(window.location.pathname + window.location.search);

let store = null;

const render = () => {
  store = buildStore();
  const {
    session: {
      features: {
        [FEATURE_UPGRADE_LOGIN_UI]: upgradedLogin,
      } = {},
    } = {},
  } = store.getState();
  ReactDOM.render(
    <Provider store={store}>
      <ThemeProvider
        availableThemes={upgradedLogin ? [1, 2] : [1]}
      >
        <AppContainer>
          <LoginContainer />
        </AppContainer>
      </ThemeProvider>
    </Provider>,
    document.getElementById('login'),
  );
};

document.addEventListener('DOMContentLoaded', render);

if (process.env.NODE_ENV !== 'production') {
  if (module.hot) {
    module.hot.accept('containers/LoginContainer', render);
    module.hot.accept('reducers', () => {
      if (store) {
        store.replaceReducer(reducer);
      }
    });
  }
}

/* eslint-disable react/no-danger */

import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { css } from 'emotion';
import { createTranslator, pluralize } from 'helpers/i18n';
import System from 'models/System';
import Provider from 'models/Provider';

import * as actions from 'ducks/system';

import {
  LoadingPanel,
  SplitPanel,
  Panel,
  Icon,
  Button,
  ButtonBar,
  Spinner,
  Divider,
} from 'components/utils';

import ProviderForm from 'components/providers/ProviderForm';

import { ThemeContext } from 'components/utils/ThemeContext';
import ViewContext from 'components/utils/ViewContext';

const tr = createTranslator({
  instructions: count => `Let's get started. To claim your ${pluralize(count, 'organization')}, please review the below and confirm that this information is up to date. Aidin will then review the details and confirm your access.`,
  instructionsAlt: count => `To claim your ${pluralize(count, 'organization')}, please review the below and confirm that this information is up to date. Aidin will then review the details and confirm your access.`,
  activeReferral: (colors) => `<span style="color:${colors.RECEIVING_DARKEST_FILL}">This provider has an active patient referral ready for your review.</span> For immediate access, please refer to your referral notification for an <span style="color:${colors.RECEIVING_DARKEST_FILL}">invitation code</span> to access your organization.`,
  stepper: (page, count, name) => `${page + 1} of ${count}: ${name}`,
  button: {
    back: 'Back',
    next: name => `Next: ${name}`,
    submit: 'Submit Request',
  },
});

const instructionsPanelStyle = css`
  width: 460px;
  margin-bottom: 20px;
`;

const stepperStyle = css`
  margin: 0px 90px;
  width: 600px;
`;

const actionBarStyle = css`
  margin: 0px 90px;
`;

const containerStyle = css`
  margin-top: 30px;
`;

class ClaimOrganizationStepper extends React.Component {
  static propTypes = {
    /** Go to next provider */
    onNavigateNext: PropTypes.func.isRequired,
    /** Go to previous page or provider */
    onNavigatePrevious: PropTypes.func.isRequired,
    /** Save changes to server */
    onSave: PropTypes.func.isRequired,
    /** The current page to view */
    page: PropTypes.number.isRequired,
    /** Default data for a provider form */
    provider: Provider,
    /** The providers to iterate through */
    providers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    /** Load system data */
    requestSystem: PropTypes.func.isRequired,
    /** Changes are being saved */
    saving: PropTypes.bool,
    /** System data */
    system: System,
    /** hide back button for new provider settings workflow */
    hideBack: PropTypes.bool,
  }

  static defaultProps = {
    provider: undefined,
    saving: false,
    system: {},
    hideBack: false,
  }

  static contextType = ThemeContext;

  constructor(props) {
    super(props);
    this.hasActiveReferrals = {};
    this.state = {
      loading: true,
    };
  }

  UNSAFE_componentWillMount() {
    const {
      requestSystem,
      system,
      page,
      providers = [],
    } = this.props;

    const checkProvider = () => {
      const provider = providers[page];
      if (provider && this.hasActiveReferrals[provider.id] === undefined) {
        this.checkForActiveReferrals(provider);
      }
    };

    if (system.states) {
      checkProvider();
    } else {
      requestSystem().then(checkProvider);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { page } = this.props;
    const { page: nextPage } = nextProps;
    if (page !== nextPage) {
      const { providers = [] } = nextProps;
      const provider = providers[page];
      if (provider && this.hasActiveReferrals[provider.id] === undefined) {
        this.setState({ loading: true }, () => {
          this.checkForActiveReferrals(provider);
        });
      }
    }
  }

  checkForActiveReferrals = (provider) => {
    // TODO: actually check...

    this.hasActiveReferrals[provider.id] = false;
    this.setState({ loading: false });
  }

  renderForm = provider => (
    <ProviderForm
      ref={(ref) => { this.form = ref; }}
      provider={{
        ...this.props.provider,
        ...Object.entries(provider).filter(([, value]) => !!value).reduce((s, [key, value]) => ({
          ...s,
          [key]: value,
        }), {}),
      }}
    />
  )

  renderInstructions = (provider) => {
    let component = null;
    if (this.hasActiveReferrals[provider.id]) {
      component = (
        <Panel>
          <h4 dangerouslySetInnerHTML={{ __html: tr('activeReferral', this.context.theme.colors) }} />
          <h4>{tr('instructionsAlt', this.props.providers.length)}</h4>
        </Panel>
      );
    } else {
      component = (
        <h4>{tr('instructions', this.props.providers.length)}</h4>
      );
    }

    return (
      <Panel align="center">
        <Panel spacing={10} className={instructionsPanelStyle}>
          {component}
          <Divider margin={0} />
        </Panel>
      </Panel>
    );
  }

  renderStepper = () => {
    const { page, providers } = this.props;
    if (providers.length < 2) { return null; }

    return (
      <Panel spacing={0} className={stepperStyle}>
        <h4>{tr('stepper', page, providers.length, providers[page].name)}:</h4>
        <Divider margin={0} />
      </Panel>
    );
  }

  renderIcon = name => (
    <Icon name={name} size={30} color={this.context.theme.colors.WHITE} />
  )

  renderActionBar = () => {
    const { page, providers, saving } = this.props;

    if (saving) {
      return (
        <ButtonBar align="center">
          <Spinner color={this.context.theme.colors.RECEIVING_DARKER_FILL} />
        </ButtonBar>
      );
    }

    return (
      <SplitPanel className={actionBarStyle}>
        {this.props.hideBack ? <></> : (
          <Button
            leftIcon={this.renderIcon('chevron-left')}
            onClick={this.props.onNavigatePrevious}
          >
            {tr('button.back')}
          </Button>
        )}
        {page < providers.length - 1
          ? (
            <Button
              rightIcon={this.renderIcon('chevron-right')}
              onClick={this.props.onNavigateNext}
              color={this.context.theme.colors.RECEIVING_DARKER_FILL}
            >
              {tr('button.next', providers[page + 1].name)}
            </Button>
          )
          : (
            <Button
              rightIcon={this.renderIcon('chevron-right')}
              onClick={this.props.onSave}
              color={this.context.theme.colors.RECEIVING_DARKER_FILL}
            >
              {tr('button.submit', providers.length)}
            </Button>
          )}
      </SplitPanel>
    );
  }

  render() {
    if (this.state.loading) {
      return <LoadingPanel height="400px" gray />;
    }
    const { page, providers = [] } = this.props;

    const provider = providers[page];
    if (!provider) {
      return null;
    }

    return (
      <ViewContext.Provider value={{ readOnly: false }}>
        <Panel className={containerStyle} spacing={10}>
          {this.renderInstructions(provider)}
          {this.renderStepper()}
          {this.renderForm(provider)}
          {this.renderActionBar()}
        </Panel>
      </ViewContext.Provider>
    );
  }
}

export default connect(({ system }) => ({ system }), actions, null, { forwardRef: true })(
  ClaimOrganizationStepper,
);

import UA from 'ua-parser-js';

import { detectIE } from './IE';

const isBrowserName = (name) => {
  const ua = UA(window.navigator.userAgent);
  if (ua && ua.browser) {
    return ua.browser.name?.indexOf(name) !== -1;
  }
  return false;
};

const isBrowserOS = (name) => {
  const ua = UA(window.navigator.userAgent);
  if (ua && ua.os) {
    return ua.os.name?.indexOf(name) !== -1;
  }
  return false;
};

export const detectSafari = () => isBrowserName('Safari');

export const detectFF = () => isBrowserName('Firefox');

export const detectWin = () => isBrowserOS('Win');

export const detectAndroid = () => isBrowserOS('Android');

export const detectChrome = () => isBrowserName('Chrome');

export const attachmentPrintMode = () => (
  detectIE()
    || detectSafari() || detectAndroid()
    || (detectFF() && detectWin())
    ? 'download'
    : 'print'
);

const BrowserOS = () => null;

export default BrowserOS;

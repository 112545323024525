/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import PropTypes from 'prop-types';
import { css, cx } from 'emotion';
import styled from 'react-emotion';
import Textarea from 'react-textarea-autosize';
import InputMask from 'react-input-mask';
import { debounce } from 'lodash';

import { createTranslator, pluralize, numeric } from 'helpers/i18n';

import PrintContext from 'components/utils/PrintContext';
import ViewContext from 'components/utils/ViewContext';
import { Secondary } from 'components/utils/Styles';

import withThemedComponents, { ThemeConfig } from 'components/utils/withThemedComponents';
import { detectChrome, detectFF, detectSafari } from './BrowserOS';
import { detectIE } from './IE';

const tr = createTranslator({
  characterLimit: value => `${value} ${pluralize(value, 'character')} remaining`,
});

const getAutocompleteText = () => {
  if (detectChrome() || detectSafari() || detectFF() || detectIE()) return 'nope';
  return 'off';
};

const Field = styled.input`
  border-radius: 2px;
  background-color: #ffffff;
  box-shadow: inset 0 0.5px 3px 0 rgba(153, 153, 153, 0.57);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0 0 0 0.6rem;
  font-family: ${props => props.theme.fonts.BODY_TEXT};
  border: 1px solid ${props => (props.errors ? props.theme.colors.TEXT_ERROR : props.theme.colors.TEXTFIELD_BORDER_COLOR)};
  height: ${props => props.height || '34px'};
  line-height: 1.4;
  font-size: ${props => props.fontSize || '15px'};
  color: ${props => props.theme.colors.BLACK};
  ::-webkit-input-placeholder {
    color: #aaa;
  }
  ::-moz-placeholder {
    color: #aaa;
  }
  ::-ms-placeholder {
    color: #aaa;
  }
  ::placeholder {
    color: #aaa;
  }
  -webkit-appearance: none;
  ${props => props.width && `width: ${props.width};`}
  ${props => props.disabled && 'opacity: 0.5;'}
  &:focus {
    outline: none;
    border: 1px solid #61cee1;
  }
`;

const textErrorColor = ({
  theme: {
    colors: {
      TEXT_ERROR: textColor,
    },
  },
}) => textColor;

const TextAreaField = styled(Field.withComponent(Textarea))`
  padding: 0.6rem 0 0.6rem 0.6rem;
  resize: none;
  height: auto;
`;

const createComponentStyles = ({ version, theme: { colors } = {} }) => numeric(version, {
  other: {
    styles: {
      SecondaryWarning: styled(Secondary)`
        color: ${textErrorColor};
      `,
    },
  },
  v2: {
    styles: {
      SecondaryWarning: styled(Secondary)`
        color: ${textErrorColor};
      `,
      createTextFieldStyle: ({
        errors,
        fontSize,
        height,
        search,
      } = {}) => css`
        padding: ${search ? '18px 30px' : '18px 15px'};
        border: 2px solid ${errors ? textErrorColor({ theme: { colors } }) : colors.TEXTFIELD_BORDER_COLOR};
        border-radius: ${search ? '40px' : '2px'};
        height: ${height || '34px'};
        font-size: ${fontSize || '15px'};
        &:focus {
          outline: none;
          border: 2px solid ${search ? '#61cee1' : colors.SENDING_DARKER_FILL};
        }
        ::placeholder {
          text-align: ${search ? 'center' : 'unset'};
        }
      `,
      textAreaStyle: css({ padding: '18px 21px' }),
    },
  },
});

class TextFieldBase extends React.Component {
  static propTypes = {
    /** When lines specified, adds autosizing support for textarea. */
    autosize: PropTypes.bool,
    /** Classname for the field wrapper */
    className: PropTypes.string,
    /**
     * True to have the field span full width of container,
     * false otherwise (default true)
    */
    fullWidth: PropTypes.bool,
    /**
     * True to indent the field text by 20px (to make room for a
     * custom icon, perhaps), false otherwise (default false). Can
     * also supply a number which will be the pixel amount of the
     * indentation
     */
    indent: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
    /**
     * Label text or element
     */
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    /** Optional class name for the label */
    labelClassName: PropTypes.string,
    /** Number of lines to show in textarea */
    lines: PropTypes.number,
    /**
     * When lines is specified, this represents the max number of
     * characters for this field. It will be shown below the field.
     */
    maxChars: PropTypes.number,
    /**
     * When autosize enabled AND lines specified, the max
     * number of lines for which this field can grow
     */
    maxLines: PropTypes.number,
    /** Blur listener */
    onBlur: PropTypes.func,
    /** Change listener for text field input */
    onChange: PropTypes.func,
    /** Debounce changes for some time */
    onChangeDelay: PropTypes.number,
    /** Key listener */
    onKeyDown: PropTypes.func,
    /** Listen for the enter key being pressed. Not valid with multi-line text. */
    onSubmit: PropTypes.func,
    /** True to denote this field as required, false otherwise (default false) */
    required: PropTypes.bool,
    /** Component theme */
    theme: ThemeConfig.isRequired,
    /** Field type */
    type: PropTypes.oneOf(['text', 'email', 'tel', 'password', 'url']),
    /** Width, in pixels, of input */
    width: PropTypes.number,
    /** The initial value of the input */
    value: PropTypes.string,
    /** Formatting to apply to the input */
    format: PropTypes.oneOf(['phone', 'fax', 'sms']),
    /** a rounded type of an input */
    search: PropTypes.bool,
    /** Control the auto complete */
    noAutocomplete: PropTypes.bool,
  }

  static defaultProps = {
    autosize: undefined,
    className: undefined,
    fullWidth: true,
    indent: undefined,
    label: undefined,
    labelClassName: undefined,
    lines: undefined,
    maxChars: undefined,
    maxLines: undefined,
    onBlur: undefined,
    onChange: undefined,
    onChangeDelay: undefined,
    onKeyDown: undefined,
    onSubmit: undefined,
    required: false,
    type: 'text',
    width: undefined,
    value: '',
    format: undefined,
    search: undefined,
    noAutocomplete: undefined,
  }

  constructor(props) {
    super(props);
    this.state = { value: props.value };
    this.enqueueChangeNotification = debounce(this.notifyChangeListener, props.onChangeDelay || 0);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ value: nextProps.value });
  }

  /**
   * Manually focus the field
   * @public
   */
  focus = () => {
    if (this.input) { this.input.focus(); }
  }

  /**
   * Manually blur the AutocompleteField input
   *
   * @public
   */
  blur = () => {
    if (this.input) {
      try {
        this.input.blur();
      } catch (e) {
        // Ignore error
      }
    }
  }

  select = () => {
    if (this.input && this.input.select) {
      this.input.select();
    }
  }

  indentValue = indent => (typeof indent === 'number' ? `${indent}px` : '20px');

  indentedInputProps = indent => ({
    className: css`
      text-indent: ${this.indentValue(indent)}
    `,
  })

  notifyChangeListener = () => {
    if (this.props.onChange) {
      this.props.onChange(this.state.value);
    }
  }

  handleBlur = (...params) => {
    this.enqueueChangeNotification.flush();
    if (this.props.onBlur) {
      this.props.onBlur(...params);
    }
  }

  handleChange = (e) => {
    this.setState({ value: e.target.value }, () => {
      if (this.props.onChangeDelay) {
        this.enqueueChangeNotification();
      } else {
        this.notifyChangeListener();
      }
    });
  }

  handleEnterKeyPress = (e) => {
    if (e.keyCode === 13 || e.key === 'Enter') {
      this.props.onSubmit();
    } else if (this.props.onKeyDown) {
      this.props.onKeyDown(e);
    }
  }

  renderCharacterCounter = () => {
    const { styles: { SecondaryWarning } = {} } = this.props.theme;
    return (
      <SecondaryWarning>{tr('characterLimit', this.props.maxChars - this.state.value.length)}</SecondaryWarning>
    );
  }

  renderSingleLineTextInput = ({
    format,
    type,
    fullWidth,
    width,
    indent,
    required,
    onBlur,
    onKeyDown,
    onSubmit,
    labelClassName,
    label,
    noAutocomplete,
    onChangeDelay,
    ...other
  }) => (
    <ViewContext.Consumer>
      {({ readOnly }) => (
        <PrintContext.Consumer>
          {(value) => {
            if (value === 'pdf') {
              return (
                <>
                  <div className="Term">{label}</div>
                  <div>{this.state.value}</div>
                </>
              );
            }

            const renderInput = (fieldProps = {}) => {
              const {
                styles: {
                  createTextFieldStyle = () => undefined,
                } = {},
              } = this.props.theme;
              return (
                <Field
                  className={cx(createTextFieldStyle(fieldProps), this.props.className)}
                  autoComplete={noAutocomplete ? getAutocompleteText() : undefined}
                  {...fieldProps}
                  {...(indent && this.indentedInputProps(indent))}
                  innerRef={(ref) => { this.input = ref; }}
                  type={type || Text.defaultProps.type}
                  width={width ? `${width}px` : fullWidth ? '100%' : undefined} // eslint-disable-line no-nested-ternary
                  onKeyDown={onSubmit ? this.handleEnterKeyPress : onKeyDown}
                  required={readOnly ? false : required}
                />
              );
            };

            if (format === 'phone' || format === 'fax' || format === 'sms') {
              const chosenMask = '(999) 999-9999';
              return (
                <>
                  {readOnly
                    ? <div>{this.state.value}</div>
                    : (
                      <InputMask
                        {...other}
                        innerRef={(ref) => { this.input = ref; }}
                        value={this.state.value}
                        mask={chosenMask}
                        onChange={(e) => {
                          const { target: { value: newValue } = {} } = e;
                          const evt = { target: { value: newValue } };
                          if (newValue) {
                            if (newValue.replace(/\D/g, '').length > 0) {
                              this.handleChange(evt);
                            } else {
                              this.handleChange({ target: { value: '' } });
                            }
                          } else {
                            this.handleChange(evt);
                          }
                        }}
                      >
                        {renderInput}
                      </InputMask>
                    )}
                </>
              );
            }
            return (
              <>
                {readOnly
                  ? <div>{this.state.value}</div>
                  : renderInput({
                    ...other,
                    onBlur: this.handleBlur,
                    onChange: this.handleChange,
                    value: this.state.value,
                  })}
              </>
            );
          }}
        </PrintContext.Consumer>
      )}
    </ViewContext.Consumer>
  );

  renderMultiLineTextInput = ({
    type, autosize, lines, maxLines, fullWidth, width, maxChars,
    required, onSubmit, label, labelClassName,
    noAutocomplete, onChangeDelay,
    ...other
  }) => {
    const {
      styles: { textAreaStyle },
    } = this.props.theme;
    return (
      <ViewContext.Consumer>
        {({ readOnly }) => (
          <PrintContext.Consumer>
            {(value) => {
              if (value === 'pdf') {
                return (
                  <>
                    <div className="Term">{label}</div>
                    <div>{this.state.value}</div>
                  </>
                );
              }
              return (
                <>
                  {readOnly
                    ? <div>{this.state.value}</div>
                    : (
                      <>
                        <TextAreaField
                          {...other}
                          className={cx(textAreaStyle, other.className)}
                          inputRef={(ref) => { this.input = ref; }}
                          width={width ? `${width}px` : fullWidth ? '100%' : undefined} // eslint-disable-line no-nested-ternary
                          onBlur={this.handleBlur}
                          onChange={this.handleChange}
                          required={required}
                          minRows={lines}
                          maxRows={autosize ? maxLines : lines}
                          value={this.state.value}
                        />
                        {maxChars && this.renderCharacterCounter()}
                      </>
                    )}
                </>
              );
            }}
          </PrintContext.Consumer>
        )}
      </ViewContext.Consumer>
    );
  }

  render() {
    const { lines, required, ...other } = this.props;
    return !lines
      ? this.renderSingleLineTextInput({ lines, required, ...other })
      : this.renderMultiLineTextInput({ lines, required, ...other });
  }
}

export default withThemedComponents(createComponentStyles)(TextFieldBase);

import React from 'react';
import PropTypes from 'prop-types';
import { cx, css } from 'emotion';
import styled from 'react-emotion';

import Panel from 'components/utils/Panel';
import Spinner from 'components/utils/Spinner';
import { GRAY_FILL } from 'components/utils/Colors';

const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: ${props => props.height || '100%'};
`;

const fullPageStyle = css`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
`;

/**
 * Generic loading panel with spinner, text and optional child nodes.
 * Can be full page.
 */
const LoadingPanel = props => (
  <LoadingContainer
    data-testid="loading-container"
    className={cx(props.full ? fullPageStyle : undefined, props.className)}
    height={props.autoHeight ? 'auto' : props.height}
  >
    <Panel data-testid="loading-panel">
      <Spinner color={props.gray ? GRAY_FILL : props.color} />
      {props.text && <h3>{props.text}</h3>}
      {props.children && (
        <div>
          {props.children}
        </div>
      )}
    </Panel>
  </LoadingContainer>
);
LoadingPanel.propTypes = {
  /** True to set the height to auto, false otherwse */
  autoHeight: PropTypes.bool,
  /** Custom child nodes to display in the loading area */
  children: PropTypes.node,
  /** Custom class name for the container */
  className: PropTypes.string,
  /** Color of the spinner */
  color: PropTypes.string,
  /** True if this is a full-page loading screen, false otherwise */
  full: PropTypes.bool,
  /** Shortcut prop for color={GRAY_FILL}, since it's a very popular option */
  gray: PropTypes.bool,
  /** Set custom height of loading panel */
  height: PropTypes.string,
  /** The text to display, in bold, under the spinner */
  text: PropTypes.string,
};
LoadingPanel.defaultProps = {
  autoHeight: false,
  children: undefined,
  className: undefined,
  color: undefined,
  full: false,
  gray: false,
  height: undefined,
  text: undefined,
};

export default LoadingPanel;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion';
import { Link } from 'components/utils';
import ReferralInfo from 'components/messages/ReferralInfo';

const PatientName = styled.h3`
  margin-top: 0px;
  margin-bottom: 6px;
  text-align: center;
`;

const ConversationModalHeader = ({
  thread,
  close,
  thread: {
    referral,
    patient: { name },
  },
}) => (
  <Link to={`/referrals/${referral.id}`} onClick={close} style={{ textDecoration: 'none', display: 'block' }}>
    <PatientName>{name}</PatientName>
    <ReferralInfo referral={referral} thread={thread} close={close} />
  </Link>
);

ConversationModalHeader.propTypes = {
  thread: PropTypes.shape({
    referral: PropTypes.shape({
      id: PropTypes.number,
      level_of_care: PropTypes.array,
    }),
    patient: PropTypes.shape({
      name: PropTypes.string,
    }),
  }).isRequired,
  close: PropTypes.func.isRequired,
};

export default ConversationModalHeader;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion';

import apiDirector from 'helpers/ApiDirector';
import { createTranslator, pluralize } from 'helpers/i18n';

import LoadingPanel from 'components/utils/LoadingPanel';
import * as Colors from 'components/utils/Colors';
import Panel from 'components/utils/Panel';
import Divider from 'components/utils/Divider';
import Popup from 'components/utils/Popup';
import SplitPanel from 'components/utils/SplitPanel';
import * as Styles from 'components/utils/Styles';

const { PopupContent, LinkText, Secondary } = Styles;

const { GRAY_FILL } = Colors;

const LOAD_INCREMENT = 10;
const INITIAL_SIZE = 3;

const tr = createTranslator({
  loadMore: count => `Load ${count} more`,
  title: count => `${count} Aidin ${pluralize(count, 'user')}`,
});

const WrappedSecondary = styled(Secondary)`
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

/**
 * A scrollable list of users for a given organization. All
 * Popup props are supported.
 */
class OrganizationUserListPopup extends React.Component {
  static propTypes = {
    /** The organization to find */
    organization: PropTypes.shape({
      id: PropTypes.number.isRequired,
      type: PropTypes.string,
      organization_type: PropTypes.string,
    }).isRequired,
    /** Fetch organization users function */
    requestOrganizationUsers: PropTypes.func,
    /** Trigger element to show the popup */
    trigger: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.node]).isRequired,
  }

  static defaultProps = {
    requestOrganizationUsers: undefined,
  }

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      loading: true,
      users: [],
      cursor: INITIAL_SIZE,
    };
  }

  fetchUsers = id => apiDirector.validateFetch(`/api/providers/${id}/users`);

  loadUsers = () => {
    const {
      requestOrganizationUsers = this.fetchUsers,
      organization: {
        id, type, organization_type: oType,
      },
    } = this.props;

    requestOrganizationUsers(id, type || oType).then((users) => {
      this.setState({
        users,
        loading: false,
      });
    });
  }

  handleClickLoadMore = () => {
    this.setState(({ cursor }) => ({ cursor: cursor + LOAD_INCREMENT }));
  }

  handleOpen = () => {
    this.setState({ open: true, loading: true }, this.loadUsers);
  }

  handleClose = () => {
    this.setState({ open: false, users: [], cursor: LOAD_INCREMENT });
  }

  renderLoadMoreLink = () => {
    const { cursor, users } = this.state;
    if (cursor >= users.length) { return null; }
    const nextIndex = cursor + LOAD_INCREMENT;
    const nextPageCount = nextIndex > users.length ? users.slice(cursor).length : LOAD_INCREMENT;
    return (
      <LinkText onClick={this.handleClickLoadMore}>
        {tr('loadMore', nextPageCount)}
      </LinkText>
    );
  }

  renderResults = () => {
    const { cursor, users } = this.state;

    return (
      <Panel>
        <h4>{tr('title', users.length)}:</h4>
        <Panel spacing={10}>
          {users.slice(0, cursor).map(({ id, name, email }) => (
            <React.Fragment key={id}>
              <SplitPanel>
                {name}
                <WrappedSecondary title={email}>{email}</WrappedSecondary>
              </SplitPanel>
              <Divider margin={4} />
            </React.Fragment>
          ))}
        </Panel>
        {this.renderLoadMoreLink()}
      </Panel>
    );
  }

  render() {
    const { loading, open } = this.state;
    const {
      organization,
      requestOrganizationUsers,
      trigger,
      width,
      ...popupProps
    } = this.props;
    return (
      <Popup
        width={width || 450}
        open={open}
        onOpen={this.handleOpen}
        onClose={this.handleClose}
        trigger={trigger}
        {...popupProps}
      >
        <PopupContent>
          {!loading ? this.renderResults() : (
            <LoadingPanel color={GRAY_FILL} height="100px" />
          )}
        </PopupContent>
      </Popup>
    );
  }
}

export default OrganizationUserListPopup;

import * as types from './types';

export const clearPrintTray = () => dispatch => Promise.resolve(dispatch({
  type: types.CLEAR_PRINT_TRAY,
}));

export const replacePrintTray = document => dispatch => Promise.resolve(dispatch({
  type: types.REPLACE_PRINT_TRAY,
  document,
}));

export const markPrintPending = (id, inline = false) => dispatch => Promise.resolve(dispatch({
  type: types.PENDING_PRINT_TRAY,
  pending: id,
  inline,
}));

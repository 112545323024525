import { numeric } from 'helpers/i18n';

export const createComponentTheme = ({ version, theme: { colors } }) => ({
  version,
  colors,
  styles: {
    selectedBackgroundColor: '#DDF3F7',
    selectedBorderStyle: '2px solid #33B8CF',
    selectedCellColor: '#61CEE1',
  },
});

export const getAttribute = (attr, selected, whenSelected) => (
  selected ? whenSelected : attr
);

export const getPopupPosition = (day) => numeric(day.day(), {
  0: 'bottom left',
  6: 'bottom right',
  other: 'bottom center',
});

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createTranslator } from 'helpers/i18n';
import { isValidEmail } from 'helpers/validators';
import Referral from 'models/Referral';
import Session, { getOrganizationUsers } from 'models/Session';

import Popup from 'components/utils/Popup';
import Panel from 'components/utils/Panel';
import CheckboxField from 'components/utils/CheckboxField';
import SelectField from 'components/utils/SelectField';
import AutocompleteField, { PropertyFilter } from 'components/utils/AutocompleteField';
import ButtonBar from 'components/utils/ButtonBar';
import Button from 'components/utils/Button';
import { SENDING_DARKER_FILL } from 'components/utils/Colors';
import { PopupContent, LinkText } from 'components/utils/Styles';
import ProviderListItem from 'components/referrals/ProviderListItem';

const tr = createTranslator({
  patient: email => `Patient: ${email}`,
  input: {
    from: 'From',
    replyToLabel: 'No Reply',
    replyTo: 'select the reply-to address',
    label: format => `Send ${format ? format.toUpperCase() : 'PDF'} via email`,
    placeholder: 'Add email',
    empty: email => `Add ${email}`,
    errors: {
      invalid: 'Invalid email address',
    },
  },
  button: {
    cancel: 'Cancel',
    submit: 'Send Email',
  },
});

class ForwardAttachmentPopup extends React.Component {
  static propTypes = {
    format: PropTypes.string,
    onClose: PropTypes.func,
    onSubmit: PropTypes.func.isRequired,
    position: PropTypes.string,
    trigger: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.func]).isRequired,
    referral: Referral,
    session: Session.isRequired,
  }

  static defaultProps = {
    format: undefined,
    onClose: undefined,
    position: undefined,
    referral: undefined,
  }

  constructor(props) {
    super(props);

    this.state = {
      errors: null, input: '', items: this.getInitialItems(), from: '',
    };
  }

  getInitialItems = () => {
    const { referral: { patient = {} } = {} } = this.props;
    if (!patient.email || patient.email === '') {
      return [];
    }
    return [patient.email].map(email => ({
      value: email,
      text: `Patient: ${email}`,
      selected: false,
    }));
  }

  getInputOptions = () => {
    const { referral: { patient = {} } = {}, session: { organizations } } = this.props;
    return [
      {
        name: patient.name,
        levelOfCare: patient.email,
        value: patient.email,
      },
    ].concat(getOrganizationUsers(organizations).filter(u => !!u.email).map(u => ({
      name: u.name,
      levelOfCare: u.email,
      value: u.email,
    }))).map(item => ({
      ...item,
      label: item.name,
    })).filter(({ value }) => (
      !this.state.items.some(item => item.selected && item.value === value)
    ));
  }

  handleCheckChange = ({ value }, checked) => {
    this.setState(({ items }) => {
      const mItems = items.slice();
      const idx = mItems.findIndex(item => item.value === value);
      if (idx >= 0) {
        mItems[idx].selected = checked;
      }
      return { items: mItems };
    });
  }

  handleChangeInput = (input) => {
    this.setState({ input, errors: null });
    return input;
  }

  handleClose = () => {
    this.setState({ input: '', errors: null, items: this.getInitialItems() }, () => {
      if (this.props.onClose) {
        this.props.onClose();
      }
    });
  }

  handleSelectEmail = ({ value }) => {
    this.handleAppendEmail(value);
  }

  handleAddEmail = (e) => {
    if (e.keyCode === 13 || e.key === 'Enter') {
      const { input } = this.state;
      if (input) {
        this.handleAppendEmail(input, () => {
          if (this.control) {
            this.control.field.close();
          }
        });
      }
    }
  }

  handleAddViaClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.handleAddEmail({ keyCode: 13 });
  }

  handleAppendEmail = (input, andThen) => {
    const exists = this.state.items.some(item => item.value === input);
    if (!exists) {
      if (isValidEmail(input)) {
        this.setState(({ items }) => ({
          errors: null,
          input: '',
          items: items.concat([{ text: input, value: input, selected: true }]),
        }), andThen);
      } else {
        this.setState({ errors: [tr('input.errors.invalid')] }, andThen);
      }
    } else {
      this.setState(({ items }) => {
        const mItems = items.slice();
        const idx = mItems.findIndex(item => item.value === input);
        mItems[idx].selected = true;
        return { items: mItems };
      }, andThen);
    }
  }

  handleSubmit = () => {
    if (this.props.onSubmit) {
      this.props.onSubmit(
        this.state.items
          .filter(({ selected }) => !!selected)
          .map(({ value }) => value),
        this.state.from,
      );
    }
  }

  handleSelect = (from) => {
    this.setState({ from });
  }

  renderItemList = () => this.state.items.map(item => (
    <CheckboxField
      label={item.patient ? `${tr('patient', item.text)}` : item.text}
      key={item.value}
      value={item.selected}
      onChange={(checked) => { this.handleCheckChange(item, checked); }}
    />
  ));

  renderSelectField = (items) => (
    this.props.displayFromField
      ? (
        <SelectField
          label={tr('input.from')}
          items={items}
          placeholder={tr('input.replyTo')}
          onSelect={({ value } = {}) => this.handleSelect(value)}
          value={this.state.from}
        />
      ) : null
  )

  renderContent = (close, items) => (
    <Panel>
      { this.renderSelectField(items) }
      <AutocompleteField
        ref={(ref) => { this.control = ref; }}
        required
        errors={this.state.errors}
        label={tr('input.label', this.props.format)}
        itemRenderer={item => <ProviderListItem {...item} />}
        valueRenderer={() => ''}
        placeholder={tr('input.placeholder')}
        onInputChange={this.handleChangeInput}
        onSelect={this.handleSelectEmail}
        onInputKeyDown={this.handleAddEmail}
        items={this.getInputOptions()}
        filter={PropertyFilter('name', 'value')}
        defaultUnfiltered
        inputListener={i => this.setState({ input: i })}
        empty={(
          <LinkText onClick={this.handleAddViaClick}>
            {tr('input.empty', this.state.input)}
          </LinkText>
        )}
      />
      <Panel spacing={10}>
        {this.renderItemList()}
      </Panel>
      <ButtonBar align="right">
        <Button small link onClick={close}>{tr('button.cancel')}</Button>
        <Button
          small
          color={SENDING_DARKER_FILL}
          onClick={() => { close(); this.handleSubmit(); }}
          disabled={this.state.items.filter(({ selected }) => !!selected).length === 0}
        >
          {tr('button.submit')}
        </Button>
      </ButtonBar>
    </Panel>
  );

  render() {
    const { session: { user: currentUser } } = this.props;
    const items = [{ label: currentUser.email, value: currentUser.email }, { label: tr('input.replyToLabel'), value: null }];
    return (
      <Popup
        trigger={this.props.trigger}
        onClose={this.handleClose}
        position={this.props.position}
        disableScrolling
        triggerOn={this.props.triggerOn}
      >
        {({ close }) => (
          <PopupContent>{this.renderContent(close, items)}</PopupContent>
        )}
      </Popup>
    );
  }
}

export default connect(({ session }) => ({ session }))(ForwardAttachmentPopup);

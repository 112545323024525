import React from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { css } from 'emotion';
import { connect } from 'react-redux';
import qs from 'query-string';

import { createTranslator } from 'helpers/i18n';
import { applyTestIdentifier } from 'helpers/development';
import md from 'helpers/markdown';

import * as actions from 'ducks/session';

import Link from 'components/utils/Link';
import QueryContext from 'components/utils/QueryContext';

import {
  ContentPanel, Panel, ButtonBar, Button, TextField, Tip, Colors,
} from 'components/utils';

import { SUPPORT_KEY } from './RegisterAccountForm';

const { SENDING_DARKER_FILL, ERROR_LIGHTEST_FILL } = Colors;

const tr = createTranslator({
  title: {
    create: 'Create an Account',
    edit: 'Set password',
  },
  password: {
    label: 'New password',
    help: 'Use at least 8 characters, 1 number, and 1 symbol.',
  },
  password_confirmation: {
    label: 'Confirm new password',
  },
  submit: {
    create: 'Create my Account',
    edit: 'Set password',
  },
  back: 'Log in',
  error: {
    noToken: `A reset token is required to use this page. [Request another password reset email](/devise_users/sign_in?support=${SUPPORT_KEY}) to regain access.`,
    tokenInvalid: `Your password reset token is invalid or has expired. [Request another password reset email](/devise_users/sign_in?support=${SUPPORT_KEY}) to regain access. Your link will expire in 24 hours.`,
  },
});

const errorTextStyle = css`
  a > span {
    color: inherit !important;
  }
`;

class ResetPasswordForm extends React.Component {
  static propTypes = {
    changePasswordWithToken: PropTypes.func.isRequired,
    // eslint-disable-next-line react/no-typos
    location: ReactRouterPropTypes.location.isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {
      form: {
        reset_password_token: this.getResetToken(),
        password: '',
        password_confirmation: '',
      },
      errors: {},
    };
  }

  componentDidMount() {
    this.props.validateResetPasswordToken(this.state.form)
      .catch(({ response: { errors = {} } = {} }) => (
        errors.reset_password_token && window.location.assign(`/devise_users/sign_in?support=${SUPPORT_KEY}&token=invalid`)
      ));
  }

  getResetToken = () => {
    const { reset_password_token: token } = qs.parse(this.props.location.search);
    return token;
  }

  handleInput = (name, value) => {
    this.setState(({ form }) => ({
      form: {
        ...form,
        [name]: value,
      },
    }));
  }

  handleSubmit = () => {
    if (this.state.form.password && this.state.form.password_confirmation) {
      this.props.changePasswordWithToken(this.state.form)
        .then(() => window.location.assign('/api/session/start'))
        .catch(({ response: { errors = {} } = {} }) => {
          this.setState({ errors });
        });
    }
  }

  renderPasswordField = name => (
    <TextField
      required
      type="password"
      name={name}
      label={tr(`${name}.label`)}
      value={this.state.form[name]}
      errors={this.state.errors[name]}
      help={tr(`${name}.help`)}
      onChange={(value) => { this.handleInput(name, value); }}
    />
  )

  renderPage(context) {
    const { create } = context;

    return (
      <ContentPanel title={create ? tr('title.create') : tr('title.edit')}>
        <form
          {...applyTestIdentifier('reset-password-form')}
          onSubmit={(e) => { e.preventDefault(); this.handleSubmit(); }}
        >
          <Panel>
            {!this.state.form.reset_password_token && (
              <Tip color={ERROR_LIGHTEST_FILL} className={errorTextStyle}>
                {md(tr('error.noToken'))}
              </Tip>
            )}
            {this.state.form.reset_password_token && this.state.errors.reset_password_token && (
              <Tip color={ERROR_LIGHTEST_FILL} className={errorTextStyle}>
                {md(tr('error.tokenInvalid'))}
              </Tip>
            )}
            {this.renderPasswordField('password')}
            {this.renderPasswordField('password_confirmation')}
            <ButtonBar>
              <Button
                type="submit"
                color={SENDING_DARKER_FILL}
                disabled={!this.state.form.reset_password_token}
              >
                {create ? tr('submit.create') : tr('submit.edit')}
              </Button>
              <Link to="/devise_users/sign_in">
                <Button link>{tr('back')}</Button>
              </Link>
            </ButtonBar>
          </Panel>
        </form>
      </ContentPanel>
    );
  }

  render() {
    return (
      <QueryContext.Consumer>
        {context => this.renderPage(context)}
      </QueryContext.Consumer>
    );
  }
}

export default connect(state => state, actions)(ResetPasswordForm);

/* eslint-disable camelcase */

import PropTypes from 'prop-types';
import AidinPropTypes from 'helpers/AidinPropTypes';
import Attachment from 'models/Attachment';
import Provider from 'models/Provider';
import { LENGTH_OF_STAY_MODE_APPOINTMENT, isTransactional, usesTransactionalWorkflow } from 'models/ProviderType';
import { Date, DateTime } from 'helpers/dates';
import { careTypeCategories } from 'translations/care_types';
import { providerTypes } from 'translations/providers';
import { declineReason, removeReason, declineReasonGroup } from 'translations/referrals';
import { createTranslator, numeric } from 'helpers/i18n';
import {
  intakeOverdueAt,
  isReferralDoc,
  getTextForChecklist,
  getLandingPage,
  getPrecertChildReferral,
  getWebServices,
} from 'helpers/referrals';

export const BLINDABLE_PATIENT_FIELDS = [
  'address',
  'address2',
  'city',
  'date_of_birth',
  'emergency_address',
  'emergency_city',
  'emergency_first',
  'emergency_last',
  'emergency_phone',
  'emergency_phone_alt',
  'emergency_relation',
  'emergency_state_id',
  'emergency_zip',
  'first',
  'full_name',
  'last',
  'middle',
  'phone',
  'phone_alt',
  'pt_cell_phone',
  'ssn',
  'state_id',
  'suffix',
  'zip',
  'age',
  'emergency_contact',
  'formatted_ssn',
  'full_address',
  'full_name_with_suffix',
  'name',
  'next_of_kin',
];

const tr = createTranslator({
  or: 'or',
  unit_name: unit => `Unit ${unit}`,
  room_number: room => `Room ${room}`,
  location: (value, withPrefix) => `${withPrefix ? 'Patient Location: ' : ''}${value}`,
  hospital: ({ name }) => name,
  appointments: {
    recurring: {
      available_days: {
        available_sunday: 'Sundays',
        available_monday: 'Mondays',
        available_tuesday: 'Tuesdays',
        available_wednesday: 'Wednesdays',
        available_thursday: 'Thursdays',
        available_friday: 'Fridays',
        available_saturday: 'Saturdays',
      },
      available_times: (day, time) => `${day}${time ? ` at ${time}` : ''}`,
    },
  },
}, {
  es: {
    or: 'o',
    appointments: {
      recurring: {
        available_days: {
          available_sunday: 'Los Domingos',
          available_monday: 'Los Lunes',
          available_tuesday: 'Los Martes',
          available_wednesday: 'Los Miercoles',
          available_thursday: 'Los Jueves',
          available_friday: 'Los Viernes',
          available_saturday: 'Los Sabados',
        },
        available_times: (day, time) => `${day}${time ? ` a las ${time}` : ''}`,
      },
    },
  },
});

export {
  intakeOverdueAt,
  isReferralDoc,
  getTextForChecklist,
  getLandingPage,
  getPrecertChildReferral,
  getWebServices,
};

/**
 * Should be the admission date from patient visit. If admission is null,
 * the start of care date should just be null/unknown.
 */
export const getSendingStartOfCare = (referral) => {
  const {
    patient_visit: {
      admission,
    } = {},
  } = referral;
  return DateTime.parse(admission, null);
};

/**
 * Sending end of care should be the patient_visit.discharge date,
 * but if not, use the "estimated" discharge date value of
 * "discharge_date", or "provider_acutal_received_date". Could
 * be null.
 *
 */
const getSendingEndOfCare = (referral, confirmedDischarge = false) => {
  const {
    patient_visit: {
      discharge,
    } = {},
    discharge_date,
    start_of_care,
    provider_actual_received_date,
    provider_anticipated_received_date,
  } = referral;

  if (!confirmedDischarge) {
    return Date.parse(
      discharge
      || discharge_date
      || provider_actual_received_date
      || provider_anticipated_received_date
      || start_of_care,
      null,
    );
  }
  return Date.parse(discharge || discharge_date, null);
};
/**
 * Patient discharge date is solely determined by
 * patient_visit.discharge date. Could be null.
 *
 */
const getPatientLastDateInCare = (referral) => {
  const {
    patient_visit: {
      discharge,
    } = {},
  } = referral;
  const now = DateTime.now();
  let dischargeDate = DateTime.parse(discharge, null);
  if (!dischargeDate || dischargeDate.isAfter(now)) {
    dischargeDate = now;
  }
  return dischargeDate;
};

/**
 * Compute the actual length of stay by finding the number of
 * days between the start of care and discharge date. If the
 * actual discharge date is not available, then the LOS is the difference
 * between the start of care (admission) and today.
 */
export const getSendingActualLengthOfStay = (referral) => {
  const startOfCare = getSendingStartOfCare(referral);
  const dischargeDate = getPatientLastDateInCare(referral);
  if (!startOfCare) { return null; }

  const days = Math.abs(startOfCare.diff(dischargeDate, 'days'));
  return days === 0 ? 1 : days;
};

/**
 * Return the actual discharge date, or null if still in care.
 */
const getReceivingEndOfCare = (referral) => {
  const {
    level_of_care,
    end_of_care,
    discharge_date,
    provider_actual_received_date,
    provider_actual_discharge_date,
  } = referral;
  if (usesTransactionalWorkflow(level_of_care)) {
    return Date.parse(provider_actual_discharge_date
      || end_of_care
      || provider_actual_received_date
      || discharge_date);
  }
  return Date.parse(provider_actual_discharge_date);
};

/**
 * Starts on either the received date, the start of care, if given, or
 * the discharge date, in that order.
 */
export const getReceivingStartOfCare = (referral) => {
  const {
    discharge_date,
    provider_actual_received_date,
    provider_anticipated_received_date,
    start_of_care,
    patient_visit: {
      discharge,
    } = {},
    level_of_care = [],
  } = referral;
  if (usesTransactionalWorkflow(level_of_care)) {
    // For transactional, the start of care is the end of care.
    return getReceivingEndOfCare(referral);
  }
  return Date.parse(
    provider_actual_received_date
    || provider_anticipated_received_date
    || start_of_care
    || discharge
    || discharge_date,
  );
};

/**
 * Compute the actual length of stay by finding the number of
 * days between the start of care and end of care.
 *
 * If the patient has not yet been discharged by the provider, this
 * value is based on the current date. Otherwise, it is based on
 * the actual discharged date, and can be null if this value is not set.
 */
const getReceivingActualLengthOfStay = (referral) => {
  const startOfCare = getReceivingStartOfCare(referral);
  const endOfCare = getReceivingEndOfCare(referral);
  if (!startOfCare || !endOfCare) { return null; }
  const days = Math.abs(startOfCare.diff(endOfCare, 'days'));
  return days === 0 ? 1 : days;
};

const getReceivingEstimatedIntakeDate = (referral) => {
  const {
    provider_anticipated_received_date,
    start_date,
    discharge_date,
  } = referral;
  return Date.parse(provider_anticipated_received_date || start_date || discharge_date);
};

/**
 * Pass strict = true if you only want the anticipated dates; false to
 * allow for the actual date, if present (default false).
 */
const getReceivingEstimatedDischargeDate = (referral, strict = false) => {
  const {
    provider_actual_discharge_date,
    provider_anticipated_discharge_date,
    end_of_care,
  } = referral;
  return Date.parse((strict ? null : provider_actual_discharge_date)
    || provider_anticipated_discharge_date || end_of_care, null);
};

const getReceivingEstimatedLengthOfStay = (referral) => {
  const startOfCare = getReceivingStartOfCare(referral);
  const endOfCare = getReceivingEstimatedDischargeDate(referral);
  if (!startOfCare || !endOfCare) { return null; }
  const days = Math.abs(startOfCare.diff(endOfCare, 'days'));
  return days === 0 ? 1 : days;
};

export const isReferralAccessible = (referral) => (
  !['missed.closed', 'expired', 'removed', 'rejected.open', 'rejected.closed', 'cancelled', 'responded.declined.closed'].some(s => s === referral.reservationStatus)
);

export const isProviderDischarged = referral => (
  ['completed', 'discharged', 'discharged.other', 'discharged.transactional'].some(s => (
    s === referral.reservationStatus
  ))
);

export const isDischargingToday = (referral) => {
  if (referral.indefinite_service) { return false; }
  const providerDischargeDate = referral.end_of_care
    || referral.provider_anticipated_discharge_date;
  return providerDischargeDate ? Date.parse(providerDischargeDate).isSameOrBefore(Date.now(), 'day') : false;
};

export const isInpatient = referral => {
  if (!referral.patient_visit) { return false; }
  const {
    patient_visit: { patient_visit_type: type = 'inpatient' },
  } = referral;
  if (type && type !== 'inpatient') { return false; }
  return true;
};

export const isOutpatient = referral => {
  if (!referral.patient_visit) { return false; }
  const {
    patient_visit: { patient_visit_type: type = 'outpatient' },
  } = referral;
  if (type && type !== 'outpatient') { return false; }
  return true;
};

/**
 * Calculate the discharge disposition based on available data.
 * Return an object containing:
 * - `disposition`: the calculated dischare disposition
 * - `dischargedTo`: the organization discharged to, if any
 *   - `name`: name of the org
 *   - `id`: id of the org, if any
 *   - `type`: the type, Hospital or Provider
 *   - `level_of_care`: the single level of care.
 */
export const getDischargeDispositionDetails = (referral) => {
  const {
    organization_discharged_to: {
      id,
      name,
      type,
      provider_type,
    } = {},
    organization_discharged_to_id,
    organization_discharged_to_name,
    organization_discharged_to_type,
    discharge_disposition,
    discharged_other_reason,
    carePlan,
  } = referral;

  const dischargeToName = organization_discharged_to_name || name || '';
  const providerDischargeOptions = Object.keys(providerTypes).map(key => (
    `discharged_${key.toLowerCase()}`
  ));

  const details = {
    disposition: discharge_disposition,
    dischargedTo: !dischargeToName ? { description: discharged_other_reason } : {
      id: organization_discharged_to_id || id,
      name: dischargeToName,
      type: organization_discharged_to_type || type,
      level_of_care: provider_type ? [provider_type.toUpperCase()] : undefined,
      description: discharged_other_reason,
    },
    hasSavedDisposition: !!discharge_disposition,
  };
  if (providerDischargeOptions.some(item => item === details.disposition)) {
    if (dischargeToName) {
      details.disposition = 'discharged_to_provider';
    } else {
      details.disposition = 'discharged_to_unknown_provider';
    }
    details.dischargedTo = {
      ...details.dischargedTo,
      level_of_care: [discharge_disposition.split('_')[1].toUpperCase()],
    };
  }
  if (details.disposition === 'discharged_hospital' && !dischargeToName) {
    details.disposition = 'discharged_to_unknown_hospital';
  }
  if (!details.disposition) {
    if (organization_discharged_to_type === 'Hospital') {
      if (dischargeToName) {
        details.disposition = 'discharged_hospital';
      } else {
        details.disposition = 'discharged_to_unknown_hospital';
      }
    } else if (carePlan) {
      details.disposition = 'discharged_care_plan';
    } else {
      details.disposition = 'discharged_unknown';
    }
  }
  return details;
};

export const isLocked = referral => ['reserved.in_house', 'reserved.discharged', 'discharged', 'completed', 'cancelled'].some(s => s === referral.reservationStatus);

export const isClosed = referral => ['reserved.in_house', 'reserved.discharged', 'discharged', 'discharged.other', 'discharged.transactional', 'completed', 'cancelled'].some(s => s === referral.reservationStatus);

export const isDoc = referral => referral?.checklist_configuration?.name === 'doc';

export const isSign = referral => referral?.checklist_configuration?.name === 'sign';

/** Matched referrals can ignore having multiple roles in some contexts. */
export const isMatched = referral => [
  'reserved.in_house', 'reserved.discharged',
  'discharged', 'complete',
  'missed.open', 'accepted',
  'incoming.intake', 'incoming.transactional', 'in_care',
  'discharged.transactional', 'updating',
  'discharging', 'discharged.other',
].some(item => item === referral.reservationStatus);

export const isUnsent = referral => ['drafted', 'sending'].some(s => s === referral.reservationStatus);

/** Current determination: only sent to one manually-added provider */
export const isOneToOne = (referral, requireSent = false) => {
  const {
    provider_search: {
      provider_search_results: results = [],
    } = {},
  } = referral;

  const unsent = () => (requireSent ? false : isUnsent(referral));

  const oneChecked = results.some(r => r.manual);

  return results.length === 1 && (oneChecked || unsent()) && !isLocked(referral);
};

export const hasPatientCaregiverAccess = (referral) => {
  const {
    referralRole,
    referralResponse: {
      status,
    } = {},
  } = referral;
  switch (referralRole) {
    case 'sending':
      return true;
    case 'receiving':
      return status === 'chosen';
    default:
      return false;
  }
};

export const getPatientDOB = referral => () => {
  const {
    patient: {
      date_of_birth: DOB,
    },
  } = referral;
  if (DOB) {
    const formattedDOB = Date.format(DOB, 'MM-DD-YYYY');
    return formattedDOB;
  }
  return null;
};

export const getPatientLocation = referral => ({ withHospital = true, withPrefix = true } = {}) => {
  const {
    hospital = {},
    patient_visit: {
      location,
    } = {},
  } = referral;

  const data = { location, hospital: withHospital ? hospital : undefined };
  const result = Object.entries(data).filter(([, value]) => !!value)
    .map(([key, value]) => tr(key, value, withPrefix))
    .join(' - ');

  return result || referral.patient_location;
};

export const getProviderRoles = referral => (referral.referralRoles || [])
  .filter(rr => rr.referral && rr.referral.referralRole === 'receiving' && rr.referral.referralResponse && rr.referral.referralResponse.confirmed);

export const getInvitableProviders = (referral) => referral.invitableProviders || [];

export const hasSingleRespondant = (referral) => (
  getProviderRoles(referral).length === 1 && getInvitableProviders(referral).length === 0
);

export const hasMultipleRespondants = (referral) => (
  !hasSingleRespondant(referral)
);

export const getSortedTasks = referral => () => {
  if (!referral || !referral.currentRole) { return []; }
  const {
    currentRole: {
      referral: {
        tasks: roleTasks,
      } = {},
    } = {},
    tasks,
  } = referral;
  return (roleTasks || tasks || [])
    .filter(task => !!task.overdue_at)
    .sort(
      (a, b) => {
        const dateA = DateTime.parse(a.overdue_at);
        const dateB = DateTime.parse(b.overdue_at);
        return (
          dateB.toDate().getTime() - dateA.toDate().getTime());
      },
    );
};

const createTaskFilter = (taskType, completed) => ({
  task_type: type,
  completed_at,
}) => type === taskType && (!!completed_at === !!completed);

export const getTask = (referral, taskType, completed = false) => (
  getSortedTasks(referral)().find(createTaskFilter(taskType, completed))
);

export const getTasks = (referral, taskType, completed = false) => (
  getSortedTasks(referral)().filter(createTaskFilter(taskType, completed))
);

export const AvailableDayFields = [
  'available_sunday',
  'available_monday',
  'available_tuesday',
  'available_wednesday',
  'available_thursday',
  'available_friday',
  'available_saturday',
];

export const getAvailabilityForProvider = (referral, {
  provider,
  response,
  emptyText,
  locale,
} = {}) => {
  const { referralResponse, sent_referrals } = referral;
  const data = response || (provider
    ? sent_referrals.find(({ provider_id }) => provider_id === provider.id)
    : referralResponse);

  if (!data) { return null; }

  const { appointment_type, available_times, ...available_days } = data;

  if (appointment_type === LENGTH_OF_STAY_MODE_APPOINTMENT) {
    return available_times;
  }

  const ltr = (key, ...params) => tr({ key, locale }, ...params);

  const days = AvailableDayFields
    .filter(field => available_days[field])
    .map(field => ltr(`appointments.recurring.available_days.${field}`));

  if (days.length === 0) { return emptyText; }

  return numeric(days.length, {
    0: emptyText,
    1: ltr('appointments.recurring.available_times', days[0], available_times),
    other: ltr('appointments.recurring.available_times', [days.slice(0, days.length - 1).join(', '), ltr('or'), days.slice(-1)].join(' '), available_times),
  });
};

export const getDeclinedCareNeedsReasons = () => [
  'mental_health_concerns',
  'no_following_physician',
  'medically_complex',
  'incorrect_level_of_care',
  'insufficient_documentation',
  'unable_to_accommodate',
];

export const isDeclinedCareNeeds = ({ provider_type: type, declined_reason: declinedReason }) => type !== 'auth' && [
  ...getDeclinedCareNeedsReasons(),
  'other',
].some(reason => reason === declinedReason);

export const getDeclineReasonOptions = (referral) => numeric(referral.provider_type, {
  other: [
    ...getDeclinedCareNeedsReasons().map(reason => ({
      reason,
      type: 'clinically_not_appropriate',
    })),
    {
      reason: 'bad_debt',
      type: 'financial_mismatch',
    },
    {
      reason: 'declined_insurance',
      type: 'financial_mismatch',
    },
    {
      reason: 'no_payor_source',
      type: 'financial_mismatch',
    },
    {
      reason: 'payor_not_accepted',
      type: 'financial_mismatch',
    },
    {
      reason: 'not_covered_by_plan',
      type: 'financial_mismatch',
    },
    {
      reason: 'equipment_shortable',
      type: 'resources_mismatch',
    },
    {
      reason: 'staff_shortable',
      type: 'resources_mismatch',
    },
    {
      reason: 'no_bed',
      type: 'resources_mismatch',
    },
    {
      reason: 'service_area',
      type: 'resources_mismatch',
    },
    {
      reason: 'item_not_offered',
      type: 'resources_mismatch',
    },
    {
      reason: 'discharged_elsewhere',
      type: 'hospital_or_patient_choice',
    },
    {
      reason: 'request_cancelled',
      type: 'hospital_or_patient_choice',
    },
    {
      reason: 'known_elsewhere',
      type: 'hospital_or_patient_choice',
    },
    {
      reason: 'declined_care_with_this_facility',
      type: 'hospital_or_patient_choice',
    },
    {
      reason: 'transition_concerns',
      type: '',
    },
    {
      reason: 'policy_compliance',
      type: '',
    },
    {
      reason: 'other',
      type: '',
    },
    {
      reason: 'homeless',
      type: 'transition_concerns',
    },
    {
      reason: 'needs_long_term_bed',
      type: 'transition_concerns',
    },
    {
      reason: 'discharge_plan_safety_concern',
      type: 'transition_concerns',
    },
  ],
  auth: [
    {
      reason: 'delegate_carveout',
      type: '',
    },
    {
      reason: 'insufficient_clinical_documentation',
      type: '',
    },
    {
      reason: 'does_not_meet_criteria',
      type: '',
    },
    {
      reason: 'incorrect_dates',
      type: '',
    },
    {
      reason: 'incorrect_level_of_care',
      type: '',
    },
    {
      reason: 'not_medically_necessary',
      type: '',
    },
    {
      reason: 'patient_maximum_reached',
      type: '',
    },
    {
      reason: 'prior_auth_not_obtained',
      type: '',
    },
    {
      reason: 'out_of_network',
      type: '',
    },
    {
      reason: 'claim_form_errors',
      type: '',
    },
    {
      reason: 'not_covered_by_plan',
      type: '',
    },
    {
      reason: 'claim_deadline_passed',
      type: '',
    },
    {
      reason: 'other',
      type: '',
    },
  ],
}).map(object => ({
  label: declineReason[object.reason],
  value: object.reason,
  typeLabel: declineReasonGroup[object.type],
  typeValue: object.type,
}));

export const createReferralObject = referral => ({
  ...referral,
  getDischargeDispositionDetails: () => getDischargeDispositionDetails(referral),
  getProviderRoles: () => getProviderRoles(referral),
  getInvitableProviders: () => getInvitableProviders(referral),
  getProviderAvailability: options => getAvailabilityForProvider(referral, options),
  getSendingActualLengthOfStay: () => getSendingActualLengthOfStay(referral),
  getSendingStartOfCare: () => getSendingStartOfCare(referral),
  getSendingEndOfCare: () => getSendingEndOfCare(referral),
  getReceivingActualLengthOfStay: () => getReceivingActualLengthOfStay(referral),
  getReceivingEstimatedLengthOfStay: () => getReceivingEstimatedLengthOfStay(referral),
  getReceivingEstimatedIntakeDate: () => getReceivingEstimatedIntakeDate(referral),
  getReceivingEstimatedDischargeDate: () => getReceivingEstimatedDischargeDate(referral),
  getReceivingStartOfCare: () => getReceivingStartOfCare(referral),
  getReceivingEndOfCare: () => getReceivingEndOfCare(referral),
  getWebServices: () => getWebServices(referral),
  hasSingleRespondant: () => hasSingleRespondant(referral),
  hasMultipleRespondants: () => hasMultipleRespondants(referral),
  isAccessible: () => isReferralAccessible(referral),
  isLocked: () => isLocked(referral),
  isClosed: () => isClosed(referral),
  isDoc: () => isDoc(referral),
  isMatched: () => isMatched(referral),
  isProviderDischarged: () => isProviderDischarged(referral),
  isTransactional: () => isTransactional(referral.level_of_care),
  isSent: () => !isUnsent(referral),
  isSign: () => isSign(referral),
  isUnsent: () => isUnsent(referral),
  isOneToOne: (requireSent = false) => isOneToOne(referral, requireSent),
  isInpatient: () => isInpatient(referral),
  isOutpatient: () => isOutpatient(referral),
  isReferralDoc: (name) => isReferralDoc(referral, name),
  getTextForChecklist: (oracle = {}) => getTextForChecklist(referral, oracle),
  usesTransactionalWorkflow: () => usesTransactionalWorkflow(referral.level_of_care),
  hasPatientCaregiverAccess: () => hasPatientCaregiverAccess(referral),
  getPatientDOB: getPatientDOB(referral),
  getPatientLocation: getPatientLocation(referral),
  getSortedTasks: getSortedTasks(referral),
  getTask: (task, completed) => getTask(referral, task, completed),
  getDeclineReasonOptions: () => getDeclineReasonOptions(referral),
});

export const CommunicationRecord = PropTypes.shape({
  id: PropTypes.number,
  communication_sent_from: PropTypes.string,
  communication_sent_from_label: PropTypes.string,
  communication_sent_to: PropTypes.string,
  communication_sent_to_label: PropTypes.string,
  contact_type: PropTypes.string,
  contact_id: PropTypes.number,
  notes: PropTypes.string,
  record_id: PropTypes.number,
  record_type: PropTypes.string,
  user_id: PropTypes.number,
  organization_id: PropTypes.number,
  organization_type: PropTypes.string,
  status: PropTypes.oneOf(['pending', 'failed', 'succeeded', 'warning']),
  metadata: PropTypes.shape({}),
  service_record_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  service: PropTypes.string,
  sent_at: AidinPropTypes.dateTime,
  created_at: AidinPropTypes.dateTime,
  updated_at: AidinPropTypes.dateTime,
});

export const SentReferral = PropTypes.shape({
  id: PropTypes.number,
  appointment_type: PropTypes.oneOf(['recurring', 'individual']),
  awarded_badges: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    state: PropTypes.oneOf(['active', 'pending', 'failed']),
    details: PropTypes.string,
  })),
  last_touch: PropTypes.shape({
    created_at: AidinPropTypes.dateTime,
    event: PropTypes.string,
    via: PropTypes.string,
  }),
  communication_records: PropTypes.arrayOf(CommunicationRecord),
  confirmed: PropTypes.bool,
  declined_reason: PropTypes.oneOf(Object.keys(declineReason)),
  delivered: PropTypes.bool,
  distance: PropTypes.number,
  last_contacted_at: AidinPropTypes.dateTime,
  patient_choice: PropTypes.bool,
  provider: Provider.isRequired,
  recommended: PropTypes.bool,
  referral_id: PropTypes.number,
  remove_reason: PropTypes.oneOf(Object.keys(removeReason)),
  status: PropTypes.oneOf(['pending', 'viewed', 'available', 'unavailable', 'chosen', 'under_review', 'unsent', 'undelivered']),
  updated_at: AidinPropTypes.dateTime,
});

export const RelatedReferral = PropTypes.shape({
  id: PropTypes.number,
  parent_id: PropTypes.number,
  child_id: PropTypes.number,
  identifier: PropTypes.string,
});

const Referral = PropTypes.shape({
  id: PropTypes.number,
  /** Attachments */
  attachments: PropTypes.arrayOf(Attachment),
  cancel_reason: PropTypes.string,
  cancelled_at: AidinPropTypes.dateTime,
  cancelled_by: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string.isRequired,
  }),
  /** Care plan */
  carePlan: PropTypes.shape({
    name: PropTypes.string.isRequired,
    updates: PropTypes.arrayOf(PropTypes.shape({
      date: PropTypes.string,
    })),
    los: PropTypes.string,
  }),
  /** List of patient clinical needs */
  care_types: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    category: PropTypes.oneOf(Object.keys(careTypeCategories)),
    provider_type: PropTypes.oneOf(Object.keys(providerTypes).map(t => t.toLowerCase())),
    translations_name: PropTypes.string,
  })),
  /** Related referrals */
  child_referral_relationships: PropTypes.arrayOf(RelatedReferral),
  /** Date/time which patient much choose a provider */
  choiceDeadline: PropTypes.string,
  communication_records: PropTypes.arrayOf(CommunicationRecord),
  deadline: PropTypes.string,
  discharge_date: AidinPropTypes.date,
  end_of_care: AidinPropTypes.date,
  followers: PropTypes.arrayOf(PropTypes.shape({
    /** The user ID */
    id: PropTypes.number.isRequired,
    /** The follower display name */
    name: PropTypes.string.isRequired,
    /** Phone or other secondary information to show */
    phone: PropTypes.string,
  })),
  people: PropTypes.arrayOf(PropTypes.shape({
    /** The user ID */
    id: PropTypes.number.isRequired,
    /** The follower display name */
    name: PropTypes.string.isRequired,
    /** Phone or other secondary information to show */
    phone: PropTypes.string,
  })),
  length_of_stay: PropTypes.number,
  level_of_care: PropTypes.arrayOf(PropTypes.string),
  notes_consults: PropTypes.string,
  internal_notes: PropTypes.string,
  patient: PropTypes.shape({
    name: PropTypes.string,
    initials: PropTypes.string,
    deidentified: PropTypes.bool,
    address: PropTypes.string,
    first: PropTypes.string,
    last: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    date_of_birth: PropTypes.string,
    height: PropTypes.string,
    weight: PropTypes.string,
    sex: PropTypes.oneOf(['M', 'F']),
    emergency_first: PropTypes.string,
    emergency_last: PropTypes.string,
    emergency_address: PropTypes.string,
    emergency_phone: PropTypes.string,
    room_number: PropTypes.string,
    unit_name: PropTypes.string,
    location: PropTypes.string,
    hospital: PropTypes.shape({
      name: PropTypes.string,
      hospital_system: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        type: PropTypes.oneOf(['HospitalSystem', 'Provider']),
        settings: PropTypes.shape({}),
      }),
    }),
    allergies: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      allergy_type: PropTypes.string,
      description: PropTypes.string,
    })),
    /** Insurance information */
    insurances: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      insurance_company_name: PropTypes.string,
      primary: PropTypes.bool,
    })),
    previous_referrals: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      discharge_date: AidinPropTypes.dateTime,
      provider: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        provider_type: PropTypes.oneOf(Object.keys(providerTypes).map(t => t.toLowerCase())),
      }),
    })),
    current_providers: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      provider_id: PropTypes.number,
      status: PropTypes.oneOf(['available', 'chosen']),
      provider: Provider,
    })),
  }),
  patient_visit: PropTypes.shape({
    admission: PropTypes.string,
    discharge: PropTypes.string,
    mrn: PropTypes.string,
    admit_reason: PropTypes.string,
    location: PropTypes.string,
    diagnoses: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      code: PropTypes.string,
      description: PropTypes.string,
    })),
    attending_physicians: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      middle_name: PropTypes.string,
    })),
    hospital_service: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
    financial_class: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
    readmission_risk: PropTypes.string,
    test_patient: PropTypes.bool,
    placeholder: PropTypes.bool,
  }),
  practice: PropTypes.oneOf(['disabled', 'sending_alone', 'sending_with_providers', 'receiving']),
  provider: Provider,
  provider_anticipated_discharge_date: AidinPropTypes.date,
  provider_actual_discharge_date: AidinPropTypes.date,
  provider_search: PropTypes.shape({
    locked: PropTypes.bool,
    size: PropTypes.number,
    badge_tier_id: PropTypes.number,
    provider_network_id: PropTypes.number,
    provider_search_results: PropTypes.arrayOf(PropTypes.shape({
      awarded_badges: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.string.isRequired,
        state: PropTypes.oneOf(['active', 'pending', 'failed']),
        details: PropTypes.string,
      })),
      checked: PropTypes.bool,
      manual: PropTypes.bool,
      provider_id: PropTypes.number,
      provider: Provider,
      recommended: PropTypes.bool,
      score: PropTypes.number,
    })),
    available_networks: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })),
  }),
  /**
   * This is emitted for Dashboard referrals; they will have counts for
   * pertinent columns required to determine reservation status and for
   * dashboard display. These counts are for confirmed sent referrals only.
   */
  sent_referral_summary: PropTypes.shape({
    /** Count of referrals sent to providers */
    sent: PropTypes.number,
    /** Count of sent_referrals marked available or chosen */
    available: PropTypes.number,
    /** Count of sent_referrals marked unavailable */
    unavailable: PropTypes.number,
    /** Count of sent referral not yet opened */
    unopened: PropTypes.number,
  }),
  /**
   * A shallow list of sent referral data
   */
  sent_referral_list: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    confirmed: PropTypes.bool.isRequired,
    provider_id: PropTypes.number.isRequired,
  })),
  /**
   * Full sent referrals, only available for ones you own or
   * later fetch.
   */
  sent_referrals: PropTypes.arrayOf(SentReferral),
  referralRole: PropTypes.oneOf(['sending', 'receiving']),
  referralResponse: SentReferral,
  resuming_care: PropTypes.bool,
  /** Caretaker response ot referral */
  response: PropTypes.oneOf(['accepted', 'declined']),
  search_radius: PropTypes.number,
  /** Date/time of care start */
  start_of_care: AidinPropTypes.date,
  status: PropTypes.string,
  /** Updates to this referral */
  updates: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    date: PropTypes.string,
    complete: PropTypes.bool,
  })),
  coverage_category: PropTypes.string,
  /* Additional functions */
  getActualLengthOfStay: PropTypes.func,
  getStartOfCare: PropTypes.func,
  getEndOfCare: PropTypes.func,
  isAccessible: PropTypes.func,
  hasPatientCaregiverAccess: PropTypes.func,
});

export default Referral;

/* eslint-disable no-nested-ternary */
import { unionBy } from 'lodash';

import ProviderType, { SEARCH_AREA_HOSPITAL } from 'models/ProviderType';

import apiDirector from 'helpers/ApiDirector';
import KV from 'helpers/simpleKV';


const store = new KV({ timeout: 60 * 1000 });

export const AIDIN_DEFAULT_RADIUS = 10;
export const AIDIN_DEFAULT_ZIP = '10013';
export const AIDIN_DEFAULT_LEVEL_OF_CARE = 'SNF';

/**
 * Determine the default search radius for a given referral. Pass
 * an options hash to change the search mode:
 * - ignoreSavedRadius: will always use the default, useful when changing LOC
 */
export const getSearchRadius = (referral, { ignoreSavedRadius = false } = {}) => {
  const {
    hospital: {
      default_radii: radiiSettings = {},
    } = {},
    provider_type: defaultProviderType = AIDIN_DEFAULT_LEVEL_OF_CARE,
    search_radius: radius,
    provider_search: {
      provider_type: searchProviderType,
    } = {},
  } = referral;

  const providerType = searchProviderType || defaultProviderType
    || AIDIN_DEFAULT_LEVEL_OF_CARE.toLowerCase();

  const searchSettings = ProviderType[providerType.toUpperCase()] || ProviderType.defaults;
  if (!searchSettings.enableSearchRadius) {
    return null;
  }

  let defaultRadius = AIDIN_DEFAULT_RADIUS;
  try {
    defaultRadius = parseInt(radiiSettings[providerType], 10);
  } catch (e) {
    // Ignored, use AIDIN_DEFAULT_RADIUS
  }
  return (!ignoreSavedRadius && radius) || defaultRadius || AIDIN_DEFAULT_RADIUS;
};

export const getSearchSettings = (referral) => {
  const { hospital, patient } = referral;
  const levelOfCareData = referral.level_of_care
    ? referral.level_of_care
    : referral.provider_type
      ? [referral.provider_type]
      : [AIDIN_DEFAULT_LEVEL_OF_CARE];

  const searchSettings = levelOfCareData.length === 1 ? ProviderType[levelOfCareData[0]]
    : ProviderType.defaults;

  const data = {
    zip: null, radius: null, city: null, state: null,
  };
  if (searchSettings.searchByZip) {
    data.zip = (searchSettings.searchArea === SEARCH_AREA_HOSPITAL
      ? hospital.zip : patient.zip);
    data.zip = (data.zip || referral.zip || hospital.zip || AIDIN_DEFAULT_ZIP).substring(0, 5);
    if (searchSettings.enableSearchRadius) {
      data.radius = getSearchRadius(referral, { ignoreSavedRadius: false });
    }
  } else if (searchSettings.searchByLocation) {
    data.county = referral.city;
    data.state = referral.state;
  } else {
    data.zip = searchSettings.searchArea === SEARCH_AREA_HOSPITAL
      ? hospital.zip : patient.zip;
    data.zip = (data.zip || referral.zip || hospital.zip || AIDIN_DEFAULT_ZIP).substring(0, 5);
    data.radius = getSearchRadius(referral, { ignoreSavedRadius: false });
  }
  return data;
};

export const loadContent = (results, requestIsCurrent) => (
  new Promise((resolve, reject) => {
    const ids = [];
    results.map(patient => patient.referrals.map(rr => ids.push(rr.id)));
    const onContent = (content) => {
      if (!requestIsCurrent()) {
        return reject();
      }
      const getValidPRs = (patient) => patient.referrals
        .filter(pr => content.some(c => pr.id === c.id));
      const copy = results.map(patient => ({
        ...patient,
        referrals: content ? getValidPRs(patient)
          .map(r => content.find(rc => rc.id === r.id)) : [],
      }));
      return resolve(copy);
    };
    if (ids.length === 0) {
      return resolve(results);
    }
    // Idea if we need more speed, break out these ids requests into batches
    // of 10 to 20 referrals.
    return apiDirector.validateFetch('/api/search/content', {
      method: 'POST', body: JSON.stringify({ ids }),
    }).then(onContent, reject);
  }));

export const searchPatientsAndReferrals = (input, requestIsCurrent, deidentified, {
  format = 'referrals',
  scope = 'all',
  type: defaultType,
  organization_id: orgId,
  organization_type: orgType,
} = {}) => (
  new Promise((resolve, reject) => {
    // eslint-disable-next-line no-restricted-globals
    const isInt = !isNaN(input);
    const makeFetch = body => apiDirector.validateFetch('/api/search', {
      method: 'POST', body: JSON.stringify(body),
    });
    const onError = (error) => {
      if (!requestIsCurrent()) {
        return resolve();
      }
      return reject(error);
    };

    const onInitialResults = (rawResults, storeResults = true) => {
      if (!requestIsCurrent()) {
        return resolve();
      }
      const results = rawResults.map(patient => ({
        ...patient,
        ...(patient.deidentified ? deidentified : {}),
      }));
      if (storeResults) {
        store.set(input, results);
      }
      return resolve(results);
    };
    if (store.get(input)) {
      const results = store.get(input);
      onInitialResults(results, false);
      return;
    }
    const defaultQueryParams = {
      query: input,
      resultFormat: format,
      scope,
      organization_id: orgId,
      organization_type: orgType,
    };
    if (!isInt) {
      makeFetch({
        ...defaultQueryParams,
        type: defaultType,
      }).then(onInitialResults, onError);
    } else {
      const promises = ['referral', 'patient'].map(type => ({
        ...defaultQueryParams,
        type,
      })).map(p => makeFetch(p));
      Promise.all(promises).then((results) => {
        const referralResult = results[0];
        const patientResult = results[1];
        // Results are patient records.
        const combined = unionBy(referralResult, patientResult, patient => patient.id);
        const sorted = combined.sort((a, b) => {
          const nameA = a.name.toLowerCase();
          const nameB = b.name.toLowerCase();
          if (nameA === nameB) {
            return 0;
          }
          if (nameA > nameB) {
            return 1;
          }
          return -1;
        });
        onInitialResults(sorted);
      }, onError);
    }
  })
);

export const searchInsurances = (query, options = {}) => (
  new Promise((resolve, reject) => apiDirector.validateFetch('/api/search/insurance_companies', {
    method: 'POST',
    body: JSON.stringify({
      query,
      ...options,
    }),
  }).then(resolve, reject))
);

export const requestProviderSearchRequired = ({ changes = {}, forceSearch = false } = {}) => {
  const exemptChanges = ['discharge_date', 'start_of_care', 'time_window_closes_at'];
  return forceSearch || Object.keys(changes)
    .filter(change => !exemptChanges.some(key => key === change)).length > 0;
};

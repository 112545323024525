/* eslint-disable camelcase, no-nested-ternary */

import { Date, DateTime } from 'helpers/dates';
import qs from 'query-string';
import {
  getReceivingStartOfCare,
  getTextForChecklist,
  isReferralDoc,
  getTasks,
} from 'models/Referral';
import { usesTransactionalWorkflow, isAuthorization } from 'models/ProviderType';

/** Change a reservation status to a reservationStatus */
const parseReservationStatus = (referral) => {
  const { status } = referral;
  switch (status) {
    case 'new_list_created':
    case 'new_patient':
      return 'drafted';
    case 'in_process':
      return 'sending';
    case 'awaiting_provider_responses':
      return 'sent';
    // TODO: check providers to see which reviewing type it is!
    case 'provider_responses_closed':
      return 'reviewing';
    case 'awaiting_patient':
      return getTextForChecklist(referral, {
        sign: 'reserving.response',
        auth: 'reserved.authorized',
        other: 'reserving.pending',
      });
    case 'provider_chosen':
      return 'reserved';
    case 'handed_to_provider':
      return 'discharged';
    case 'canceled':
      return 'cancelled';
    default:
      return status;
  }
};

/**
 * Mapping of referral state to dashboard category.
 * Exposed for access but should probably use the
 * getReferralCategory method in most cases.
 */
export const referralStateMapping = {
  drafted: 'respond',
  sending: 'respond',
  sent: 'inProgress',
  'reviewing.response_review': 'inProgress',
  'reviewing.none': 'respond',
  'reviewing.all': 'respond',
  'reviewing.expired': 'respond',
  'reserving.pending': 'respond',
  'reserving.response': 'respond',
  'reserving.complete': 'respond',
  'reserved.authorized': 'completed',
  'reserved.discharged': 'completed',
  'reserved.in_house': 'completed',
  'reserved.response': 'respond', // Special case
  discharged: 'completed',
  completed: 'completed',
  responding: 'respond',
  'responding.response_review': 'respond',
  'responding.viewed': 'respond',
  'responding.under_review': 'respond',
  'responded.accepted.open': 'inProgress',
  'responded.accepted.closed': 'inProgress',
  'responded.accepted.authorized': 'completed',
  'responded.declined.open': 'completed',
  'responded.declined.closed': 'completed',
  'responded.declined.authorized': 'completed',
  'missed.open': 'respond',
  'missed.closed': 'completed',
  removed: 'completed',
  cancelled: 'completed',
  'rejected.open': 'completed',
  'rejected.closed': 'completed',
  accepted: 'inProgress',
  'incoming.intake': 'respond',
  'incoming.transactional': 'respond',
  in_care: 'completed',
  update: 'respond',
  discharging: 'respond',
  'discharged.transactional': 'completed',
  'discharged.other': 'completed',
};

export const patientDashboardReferralStateMapping = {
  drafted: 'respond',
  sending: 'respond',
  sent: 'respond',
  'reviewing.response_review': 'respond',
  'reviewing.none': 'respond',
  'reviewing.all': 'respond',
  'reviewing.expired': 'respond',
  'reserving.pending': 'respond',
  'reserving.response': 'respond',
  'reserving.complete': 'respond',
  'reserved.authorized': 'completed',
  'reserved.discharged': 'completed',
  'reserved.in_house': 'completed',
  'reserved.response': 'respond', // Special case
  discharged: 'completed',
  completed: 'completed',
  responding: 'respond',
  'responding.response_review': 'respond',
  'responding.viewed': 'respond',
  'responding.under_review': 'respond',
  'responded.accepted.open': 'respond',
  'responded.accepted.closed': 'respond',
  'responded.accepted.authorized': 'completed',
  'responded.declined.open': 'respond',
  'responded.declined.closed': 'completed',
  'responded.declined.authorized': 'completed',
  'missed.open': 'respond',
  'missed.closed': 'completed',
  removed: 'completed',
  cancelled: 'completed',
  'rejected.open': 'completed',
  'rejected.closed': 'completed',
  rejected: 'completed',
  accepted: 'respond',
  'incoming.intake': 'respond',
  'incoming.transactional': 'respond',
  in_care: 'inProgress',
  update: 'inProgress',
  discharging: 'inProgress',
  'discharged.transactional': 'completed',
  'discharged.other': 'completed',
};

/**
 * Determine the dashboard category of this referral based
 * on its referral state or reservation status.
 */
export const getReferralCategory = (referral, referralState) => referralStateMapping[referralState]
  || referralStateMapping[referral.reservationStatus]
  || referral.reservationStatus;

/**
 * Determine the unique referral state for this referral, based
 * on the mode.
 */
export const getReferralState = (mode, referral) => {
  if (mode === 'sending') {
    const parsedStatus = parseReservationStatus(referral);
    const hasResponseToReview = () => {
      const responseReviewTasks = getTasks(referral, 'response_review');
      return responseReviewTasks.length > 0;
    };
    switch (parsedStatus) {
      case 'sent': {
        if (hasResponseToReview()) {
          return 'reviewing.response_review';
        }
        return parsedStatus;
      }
      case 'reviewing': {
        const {
          provider_type: type,
          sent_referrals = [],
          sent_referral_summary: { available, unopened } = {},
        } = referral;
        if (hasResponseToReview()) {
          return 'reviewing.response_review';
        }
        const availableCount = available || sent_referrals.filter(p => p.status === 'available' || p.status === 'chosen').length;
        const unopenedCount = unopened || sent_referrals.filter(p => p.status === 'pending').length;
        if (availableCount === 0) {
          return 'reviewing.none';
        }
        if (type === 'auth') {
          return 'reserved.authorized';
        }
        if (unopenedCount === 0) {
          return 'reviewing.all';
        }
        return 'reviewing.expired';
      }
      case 'reserving': {
        const {
          checklist_configuration: { name: checklistName } = {},
          patient_visit: { discharge } = {},
          provider,
        } = referral;
        return provider
          ? discharge
            ? 'reserved.discharged'
            : 'reserved.in_house'
          : checklistName === 'sign'
            ? 'reserving.response'
            : 'reserving.pending';
      }
      case 'reserved': {
        const {
          level_of_care: loc,
          patient_visit: { discharge } = {},
        } = referral;
        if (isReferralDoc(referral)) { return 'reserved.response'; }
        if (isAuthorization(loc)) { return 'reserved.authorized'; }
        return !discharge ? 'reserved.in_house' : 'reserved.discharged';
      }
      case 'discharged': {
        const {
          checklist_configuration: { name: checklistName } = {},
          patient_visit: { discharge } = {},
        } = referral;
        if (!discharge) {
          return referral.provider
            ? 'reserved.in_house'
            : checklistName === 'sign'
              ? 'reserving.response'
              : 'reserving.pending';
        }
        if (usesTransactionalWorkflow(referral.level_of_care)) {
          if (referral.tasks.some(t => !t.completed_at)) {
            return 'discharged';
          }
          return 'discharged';
        }

        // The provider has not intaken patient yet, so can't be completed.
        if (!referral.provider_actual_received_date) {
          return 'discharged';
        }

        if (!referral.provider_actual_discharge_date) {
          if (referral.tasks.some(t => !t.completed_at)) {
            const providerDischargeDate = referral.end_of_care
              || referral.provider_anticipated_discharge_date;
            // TODO: Maybe we add a provider_discharging state?
            return providerDischargeDate && Date.parse(providerDischargeDate).isSameOrBefore(Date.now(), 'day')
              ? 'discharged' : 'discharged';
          }
        }

        if (referral.indefinite_service) {
          return 'discharged';
        }

        return 'completed';
      }
      case 'drafted':
      case 'sending': {
        const { provider_search = {} } = referral;
        const { provider_search_results = [] } = provider_search;
        if (provider_search_results.filter(p => p.checked).length === 0) {
          return 'drafted';
        }
        return 'sending';
      }
      default:
        return parsedStatus;
    }
  } else if (mode === 'receiving') {
    if (referral.status === 'canceled') {
      return 'cancelled';
    }
    if (!referral.referralResponse) {
      // Should never be the case, how did you get access??
      return 'cancelled';
    }
    if (!referral.referralResponse.confirmed && referral.referralResponse.removed_at) {
      return 'removed';
    }
    if (referral.checklist_configuration) {
      const {
        checklist_configuration: {
          name: configName,
        },
        referralResponse: {
          tasks = [],
        } = {},
      } = referral;
      if (configName === 'doc') {
        const viewedTask = tasks.find(t => t.task_type === 'viewed_documents');
        if (viewedTask && viewedTask.completed_at) {
          const responsesClosed = ['provider_responses_closed', 'awaiting_patient', 'provider_chosen', 'handed_to_provider'];
          const response = 'accepted';
          const closedStatus = 'closed';
          if (!responsesClosed.some(s => s === referral.status)
            && DateTime.now().isBefore(DateTime.parse(referral.time_window_closes_at))) {
            return `responded.${response}.open`;
          }
          return `responded.${response}.${closedStatus}`;
        }
        return 'responding.viewed';
      }
    }
    if (referral.status === 'handed_to_provider' && referral.provider_actual_received_date) {
      if (usesTransactionalWorkflow(referral.level_of_care)) {
        if (referral.referralResponse.tasks.some(t => !t.completed_at)) {
          return 'incoming.transactional';
        }
        return 'discharged.transactional';
      }

      if (referral.referralResponse.tasks.some(t => !t.completed_at)) {
        const providerDischargeDate = referral.end_of_care
          || referral.provider_anticipated_discharge_date;
        return providerDischargeDate && Date.parse(providerDischargeDate).isSameOrBefore(Date.now(), 'day')
          ? 'discharging' : 'in_care';
      }

      return 'discharged.other';
    }
    let referralStatus = referral.referralResponse.status;
    if (referralStatus === 'available' && referral.referralResponse.provider_id === referral.provider_id) {
      // Sometimes the chosen provider isn't marked chosen in the system.
      referralStatus = 'chosen';
    }
    if (referralStatus === 'chosen' && referral.referralResponse.provider_id !== referral.provider_id) {
      // Sometimes the "chosen" provider isn't actually chosen!
      referralStatus = 'available';
    }
    if (referralStatus === 'chosen' && isReferralDoc(referral)) {
      // For DOC referrals, chosen === available
      referralStatus = 'available';
    }
    switch (referralStatus) {
      case 'available':
      case 'unavailable': {
        const closedStatus = isAuthorization(referral.level_of_care) ? 'authorized' : 'closed';
        const responsesClosed = ['provider_responses_closed', 'awaiting_patient'];

        if (!isReferralDoc(referral)) {
          const rejected = ['provider_chosen', 'handed_to_provider'];
          if (rejected.some(s => s === referral.status)) {
            // If you're not chosen, you're rejected...
            return referral.status === 'handed_to_provider'
              ? 'rejected.closed'
              : 'rejected.open';
          }
        } else {
          responsesClosed.push('provider_chosen');
          responsesClosed.push('handed_to_provider');
        }

        const response = ['available', 'chosen'].some(i => i === referral.referralResponse.status) ? 'accepted' : 'declined';
        if (!responsesClosed.some(s => s === referral.status)
          && DateTime.now().isBefore(DateTime.parse(referral.time_window_closes_at))) {
          return `responded.${response}.open`;
        }
        return `responded.${response}.${closedStatus}`;
      }
      case 'chosen': {
        let start = getReceivingStartOfCare(referral);
        const state = usesTransactionalWorkflow(referral.level_of_care)
          ? 'incoming.transactional'
          : 'incoming.intake';
        if (start) {
          const now = Date.now();
          start = Date.parse(start);
          if (now.isBefore(start, 'day')) {
            return 'accepted';
          }
        }
        return state;
      }
      default: {
        const responding = ['pending', 'viewed'];
        const rejected = ['provider_chosen', 'handed_to_provider'];
        const missed = ['provider_responses_closed', 'awaiting_patient'];
        if (rejected.some(s => s === referral.status)) {
          return referral.status === 'handed_to_provider'
            ? 'rejected.closed'
            : 'rejected.open';
        }

        const responseReviewTasks = getTasks(referral, 'response_review');
        if (responseReviewTasks.length > 0) {
          return 'responding.response_review';
        }

        if (missed.some(s => s === referral.status)) {
          const {
            sent_referrals = [],
            sent_referral_summary: { sent } = {},
          } = referral;
          return `missed.${(sent || sent_referrals.length) === 1 ? 'open' : 'closed'}`;
        }

        if (referralStatus === 'under_review') {
          return 'responding.under_review';
        }

        if (responding.some(status => status === referralStatus)) {
          return 'responding.viewed';
        }
        return 'responding.viewed';
      }
    }
  }
  return null;
};

export const isViewingOpportunitiesSection = (location) => location && (qs.parse(location.search).section === 'opportunities');

export const getReferralOverdueStatus = (referral, location) => {
  const headers = [];
  if (referral.patient.test_patient || (referral.practice && referral.practice !== 'disabled')) {
    headers.push('practice');
  } else if (referral.referralRole === 'receiving') {
    headers.push('hospitalName');
  }
  if (isViewingOpportunitiesSection(location) && referral.referralRole === 'receiving') {
    if (referral.patient_visit.patient_visit_type === 'emergency') {
      headers.push('emergency');
    }
    if (referral.patient_visit.patient_visit_type === 'inpatient') {
      headers.push('inpatient');
    }
    if (referral.patient_visit.patient_visit_type === 'outpatient') {
      headers.push('outpatient');
    }
  }
  if (referral.highlighted_provider_network) {
    const { highlighted_type: highlightedType } = referral.highlighted_provider_network;
    const isSendingHospital = highlightedType === 'hospital_facility' && referral.referralRole === 'sending';
    const isReceivingFacility = highlightedType === 'receiving_facility' && referral.referralRole === 'receiving';
    const isBothFacilities = highlightedType === 'both_facilities';
    if (isSendingHospital || isReceivingFacility || isBothFacilities) {
      headers.push('highlightedProviderNetwork');
    }
  }

  const tasks = referral.getSortedTasks();
  const getDateForTask = (type, defaultValue) => {
    const task = tasks.find(({ task_type: t }) => t === type);
    const { overdue_at: date } = task || {};
    return date ? DateTime.parse(date) : defaultValue;
  };

  if (referral.referralRole === 'sending') {
    switch (referral.reservationStatus.split('.')[0]) {
      case 'drafted':
      case 'sending': {
        if (referral.reservationStatus === 'drafted') {
          headers.push('new');
        }
        const date = getDateForTask('submit', DateTime.parse(referral.discharge_date));
        if (date && DateTime.now().isAfter(date, 'minute')) {
          headers.unshift('overdue');
        }
        break;
      }
      case 'reviewing': {
        const taskType = referral.reservationStatus === 'reviewing.none' ? 'reopen' : 'share';
        const date = getDateForTask(taskType, DateTime.parse(referral.time_window_closes_at));
        if (DateTime.now().isAfter(date, 'minute')) {
          headers.unshift('overdue');
        }
        break;
      }
      case 'reserving': {
        const date = getDateForTask('reserve', Date.parse(referral.start_of_care || referral.discharge_date));
        if (DateTime.now().isAfter(DateTime.parse(date), 'minute')) {
          headers.unshift('overdue');
        }
        break;
      }
      default:
        break;
    }
  } else if (referral.referralRole === 'receiving') {
    switch (referral.reservationStatus) {
      case 'responding.viewed':
      case 'responding.under_review':
      case 'responding':
        if (DateTime.now().isAfter(getDateForTask('respond', DateTime.parse(referral.time_window_closes_at)), 'minute')) {
          headers.unshift('overdue');
        }
        break;
      case 'missed.open':
        headers.unshift('overdue');
        break;
      case 'incoming.intake':
      case 'incoming.transactional':
        if (DateTime.now().isAfter(getDateForTask('receive_patient', Date.parse(getReceivingStartOfCare(referral))), 'minute')) {
          headers.unshift('overdue');
        }
        break;
      case 'discharging':
        if (DateTime.now().isAfter(getDateForTask('update_patient_status', Date.parse(referral.end_of_care || referral.provider_anticipated_discharge_date)), 'minute')) {
          headers.unshift('overdue');
        }
        break;
      default:
        break;
    }
  }
  return headers;
};

export const handleReferralUpdate = (props, referral, refreshOptions) => {
  const { id, patient } = referral;
  const {
    requestDashboardPatient,
    requestDashboardReferral,
    removeItemFromLoadingQueue,
    session: {
      features: {
        'deprecated/dashboard/request-patient': shouldExplicitlyRequestPatient,
      } = {},
    } = {},
  } = props;

  if (requestDashboardReferral) {
    requestDashboardReferral(id, refreshOptions).then(() => {
      if (removeItemFromLoadingQueue) {
        removeItemFromLoadingQueue(`dashboardCard-${id}`);
      }
    });
  }
  if (shouldExplicitlyRequestPatient && requestDashboardPatient) {
    /**
     * FIXME: This will really only work for senders as it is currently
     * constructed. But with the push dashboard feature, we should not need
     * to call this anymore. Either remove, or fix.
     */
    requestDashboardPatient(patient);
  }
};

/* eslint-disable import/prefer-default-export */

import { requestSession } from 'ducks/session';
import * as types from './types';

export const openProviderSearchContactInfoModal = props => dispatch => (
  Promise.resolve(dispatch({
    type: types.TOGGLE_PROVIDER_SEARCH_CONTACT_INFO_MODAL,
    open: true,
    props,
  }))
);

export const closeProviderSearchContactInfoModal = () => dispatch => Promise.resolve(dispatch({
  type: types.TOGGLE_PROVIDER_SEARCH_CONTACT_INFO_MODAL,
  open: false,
}));

export const openProviderPreferencesModal = (id, preferences) => dispatch => (
  Promise.resolve(dispatch({
    type: types.TOGGLE_PROVIDER_PREFERENCES_MODAL,
    preferences,
    referralId: id,
    open: true,
  }))
);

export const closeProviderPreferencesModal = () => dispatch => Promise.resolve(dispatch({
  type: types.TOGGLE_PROVIDER_PREFERENCES_MODAL,
  preferences: null,
  open: false,
}));

export const openProviderMissingBadgeModal = (id, hospitalId, preferences) => dispatch => (
  Promise.resolve(dispatch({
    type: types.TOGGLE_PROVIDER_MISSING_BADGE_MODAL,
    preferences,
    referralId: id,
    hospitalId,
    open: true,
  }))
);

export const closeProviderMissingBadgeModal = () => dispatch => Promise.resolve(dispatch({
  type: types.TOGGLE_PROVIDER_MISSING_BADGE_MODAL,
  preferences: null,
  open: false,
}));

export const openNewProviderModal = ({
  organization,
  referral,
  name,
  onAddProviderToReferral,
}) => dispatch => (
  Promise.resolve(dispatch({
    type: types.TOGGLE_NEW_PROVIDER_MODAL,
    preferences: {
      organization,
      referral,
      onAddProviderToReferral,
      name,
    },
    open: true,
  }))
);

export const closeNewProviderModal = () => dispatch => Promise.resolve(dispatch({
  type: types.TOGGLE_NEW_PROVIDER_MODAL,
  preferences: null,
  open: false,
}));

export const openPasswordChangeModal = () => dispatch => Promise.resolve(dispatch({
  type: types.TOGGLE_PASSWORD_CHANGE_MODAL,
  open: true,
}));

export const closePasswordChangeModal = () => dispatch => Promise.resolve(dispatch({
  type: types.TOGGLE_PASSWORD_CHANGE_MODAL,
  open: false,
}));

export const openFedrampModal = () => dispatch => Promise.resolve(dispatch({
  type: types.TOGGLE_FEDRAMP_MODAL,
  open: true,
}));

export const closeFedrampModal = () => dispatch => Promise.resolve(dispatch({
  type: types.TOGGLE_FEDRAMP_MODAL,
  open: false,
}));

export const openBadgesCheckupModal = providers => dispatch => Promise.resolve(dispatch({
  type: types.TOGGLE_BADGES_CHECKUP_MODAL,
  open: true,
  providers,
}));

export const closeBadgesCheckupModal = () => dispatch => Promise.resolve(dispatch({
  type: types.TOGGLE_BADGES_CHECKUP_MODAL,
  open: false,
}));

export const openTurnOffFaxesModal = () => dispatch => Promise.resolve(dispatch({
  type: types.TOGGLE_GENERIC_MODAL,
  open: true,
  globalModalReduxKey: 'turnOffFaxesModal',
}));

export const openFollowingPreferencesMarketingModal = () => dispatch => Promise.resolve(dispatch({
  type: types.TOGGLE_GENERIC_MODAL,
  open: true,
  globalModalReduxKey: 'followingPreferencesMarketingModal',
}));

export const openIdentityVerificationModal = (refresh, options = {}) => dispatch => Promise.resolve(
  dispatch({
    type: types.TOGGLE_IDENTITY_VERIFICATION_MODAL,
    open: true,
    refresh: () => dispatch(requestSession()).then(refresh),
    options,
  }),
);

export const closeIdentityVerificationModal = () => dispatch => Promise.resolve(dispatch({
  type: types.TOGGLE_IDENTITY_VERIFICATION_MODAL,
  open: false,
}));

export const openYearEndReportModal = () => dispatch => Promise.resolve(dispatch({
  type: types.TOGGLE_YEAR_END_REPORT_MODAL,
  open: true,
}));

export const closeYearEndReportModal = () => dispatch => Promise.resolve(dispatch({
  type: types.TOGGLE_YEAR_END_REPORT_MODAL,
  open: false,
}));

export const setOrganizationForPurchaseModal = organization => dispatch => Promise.resolve(
  dispatch({
    type: types.UPDATE_PURCHASE_MODAL,
    organization,
  }),
);

export const openNewDocumentModal = (
  organization, document, recipient,
) => dispatch => {
  const defaultValues = { organization, document, recipient };
  if (document?.patient) {
    // patient already selected, just open the modal
    return Promise.resolve(dispatch({
      type: types.TOGGLE_NEW_DOCUMENT_MODAL,
      open: true,
      ...defaultValues,
    }));
  }
  return Promise.resolve(dispatch({
    type: types.TOGGLE_GENERIC_MODAL,
    open: true,
    action: 'new_document_for_patient',
    defaultValues,
    globalModalReduxKey: 'newReferralModal',
  }));
};

export const closeNewDocumentModal = () => dispatch => Promise.resolve(dispatch({
  type: types.TOGGLE_NEW_DOCUMENT_MODAL,
  open: false,
}));

export const openPDPMCalculatorModal = () => dispatch => Promise.resolve(dispatch({
  type: types.TOGGLE_PDPMCALCULATOR_MODAL,
  open: true,
}));

export const closePDPMCalculatorModal = () => dispatch => Promise.resolve(dispatch({
  type: types.TOGGLE_PDPMCALCULATOR_MODAL,
  open: false,
}));

export const openCreateReferralConflictModal = (
  patient, options, conflicts, andThen,
) => dispatch => (
  Promise.resolve(dispatch({
    type: types.TOGGLE_CREATE_REFERRAL_CONFLICT_MODAL,
    open: true,
    patient,
    options,
    patient_visits: conflicts,
    callback: andThen,
  }))
);

export const closeCreateReferralConflictModal = () => dispatch => Promise.resolve(dispatch({
  type: types.TOGGLE_CREATE_REFERRAL_CONFLICT_MODAL,
  open: false,
}));

// eslint-disable-next-line max-len
export const openUpdateReferralConflictModal = (conflicts, onConflictResolved) => dispatch => Promise.resolve(dispatch({
  type: types.TOGGLE_UPDATE_REFERRAL_CONFLICT_MODAL,
  open: true,
  conflicts,
  onConflictResolved,
}));

export const closeUpdateReferralConflictModal = () => dispatch => Promise.resolve(dispatch({
  type: types.TOGGLE_UPDATE_REFERRAL_CONFLICT_MODAL,
  open: false,
}));

export const openSendToProvidersModal = (referral, filters,
  totalCount, onConfirm, onClose, context = 'send') => dispatch => (
  Promise.resolve(dispatch({
    type: types.TOGGLE_SEND_TO_PROVIDERS_MODAL,
    open: true,
    referral,
    filters,
    totalCount,
    onConfirm,
    onClose,
    context,
  }))
);

export const closeSendToProvidersModal = () => dispatch => Promise.resolve(dispatch({
  type: types.TOGGLE_SEND_TO_PROVIDERS_MODAL,
  open: false,
}));

export const openSoftwareUpdateModal = (version) => (dispatch, getState) => {
  const { ui: { softwareUpdate } = {} } = getState();
  if (softwareUpdate.open || softwareUpdate.permanent) {
    return softwareUpdate;
  }
  return Promise.resolve(dispatch({
    type: types.TOGGLE_SOFTWARE_UPDATE_MODAL,
    open: true,
    version,
  }));
};

export const closeSoftwareUpdateModal = (permanent = false) => dispatch => (
  Promise.resolve(dispatch({
    type: types.TOGGLE_SOFTWARE_UPDATE_MODAL,
    open: false,
    permanent,
  }))
);

export const openReceiverPurchaseableModal = () => dispatch => Promise.resolve(dispatch({
  type: types.TOGGLE_RECEIVER_PURCHASEABLE_MODAL,
  open: true,
}));

export const closeReceiverPurchaseableModal = () => dispatch => Promise.resolve(dispatch({
  type: types.TOGGLE_RECEIVER_PURCHASEABLE_MODAL,
  open: false,
}));

export const openAdvancedMessageSearchModal = onSubmit => dispatch => Promise.resolve(dispatch({
  type: types.TOGGLE_ADVANCED_MESSAGE_SEARCH_MODAL,
  onSubmit,
  open: true,
}));

export const closeAdvancedMessageSearchModal = () => dispatch => Promise.resolve(dispatch({
  type: types.TOGGLE_ADVANCED_MESSAGE_SEARCH_MODAL,
  open: false,
}));

/**
 * Specify either attachment or url
 */
export const openAttachmentViewer = (data) => dispatch => Promise.resolve(dispatch({
  type: types.TOGGLE_ATTACHMENT_VIEWER,
  open: true,
  ...data,
}));

export const closeAttachmentViewer = () => dispatch => Promise.resolve(dispatch({
  type: types.TOGGLE_ATTACHMENT_VIEWER,
  open: false,
}));

export const openMessageMultipleProvidersModal = (
  referral,
  text,
  selection = [],
  onMessageSent,
) => dispatch => (
  Promise.resolve(dispatch({
    type: types.TOGGLE_MESSAGE_MULTIPLE_PROVIDERS_MODAL,
    open: true,
    topic: referral,
    text,
    values: selection,
    onMessageSent,
  }))
);

export const closeMessageMultipleProvidersModal = () => dispatch => (
  Promise.resolve(dispatch({
    type: types.TOGGLE_MESSAGE_MULTIPLE_PROVIDERS_MODAL,
    open: false,
  }))
);

export const toggleGenericModal = (request) => dispatch => Promise.resolve(dispatch({
  type: types.TOGGLE_GENERIC_MODAL,
  ...request,
}));

export const openAttachUnassignedDocumentsToPatientModal = (
  organization, source, loadDocuments,
) => dispatch => Promise.resolve(dispatch({
  type: types.TOGGLE_ATTACH_UNASSIGNED_DOCUMENTS_TO_PATIENT_MODAL,
  open: true,
  organization,
  source,
  loadDocuments,
}));

export const closeAttachUnassignedDocumentsToPatientModal = () => dispatch => Promise.resolve(dispatch({
  type: types.TOGGLE_ATTACH_UNASSIGNED_DOCUMENTS_TO_PATIENT_MODAL,
  open: false,
}));

/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import { css } from 'emotion';
import Attachment from 'models/Attachment';
import Referral from 'models/Referral';

import { numeric } from 'helpers/i18n';

import Help from 'components/utils/Help';
import { RECEIVING_LIGHT_FILL } from 'components/utils/Colors';

const receivingIconStyle = css`
  color: ${RECEIVING_LIGHT_FILL};
`;

const AttachmentRoleIcon = ({
  referral: {
    referralRole,
  } = {},
  attachment: {
    code,
    referralRole: referralAttachmentRole,
  } = {},
}) => {
  if (code === 'Pending') {
    return (
      <Help
        triggerOn="hover"
        value="referrals.attachmentRole.pendingAttachViaFax"
      />
    );
  }
  const icon = numeric(referralAttachmentRole, {
    receiving: { icon: 'mp-group', iconStyle: receivingIconStyle },
    other: null,
  });

  if (!icon) { return null; }
  if (referralRole === referralAttachmentRole) { return null; }

  return (
    <Help
      {...icon}
      triggerOn="hover"
      value={`referrals.attachmentRole.${referralAttachmentRole}`}
    />
  );
};

AttachmentRoleIcon.propTypes = {
  attachment: Attachment.isRequired,
  referral: Referral,
};
AttachmentRoleIcon.defaultProps = {
  referral: undefined,
};

export default AttachmentRoleIcon;

export const TEST_ATTRIBUTE = 'data-testid';

/**
 * Generate a test attribute. Overridden in Jest
 * to allow these to be added without breaking
 * snapshots.
 */
export const createTestAttribute = (id, key) => (id ? [id, key].filter(Boolean).join('-') : undefined);

/**
 * Find a test attribute in props and create an
 * object containing just that attribute. It will
 * be run through createTestAttribute so that it can
 * be applied cleanly in Jest testing scenarios.
 */
export const applyTestAttribute = (props, key) => ({
  [TEST_ATTRIBUTE]: createTestAttribute(props[TEST_ATTRIBUTE], key),
});

/**
 * Apply just the test id attribute.
 * @id the data test id
 * @key optional attribute to append to the id
 */
export const applyTestIdentifier = (id, key) => ({
  [TEST_ATTRIBUTE]: createTestAttribute(id, key),
});

import React, { useContext, forwardRef } from 'react';
import PropTypes from 'prop-types';
import memoizeOne from 'memoize-one';
import { merge } from 'lodash';

import { ThemeContext } from 'components/utils/ThemeContext';
import PageContext from 'components/utils/PageContext';

export const ThemeConfig = PropTypes.shape({
  styles: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.bool, PropTypes.object, PropTypes.node,
    PropTypes.string, PropTypes.number, PropTypes.func,
    PropTypes.oneOf([React.Fragment]),
  ])),
  colors: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string, PropTypes.number,
  ])),
  version: PropTypes.string,
});

const checkVersion = ([current], [last]) => current.version === last.version;

/**
 * Useful when you want to assign more than just basic CSS
 * to a theme version.
 *
 * For basic work like that, use withComponentTheme.
 *
 * The primary goal of this HOC is to allow for extracting styled components
 * based on version from the code, while eliminating problems such as extra
 * rendering that is introduced when using styled components with withComponentTheme.
 *
 * Note that these styles will NOT be made available to your styled
 * components. If you need that, use withComponentTheme
 *
 * `func` here should be a function that receives the theme from ThemeContext
 * and returns the appropriate ThemeConfig object for the given version of the
 * theme. A common pattern would be:
 *
 *     const func = ({ version }) => numeric(version, {
 *       v1: { styles: { important: { margin-top: '10px' } }, colors: { important: '#FF0000' } },
 *       v2: { styles: { important: { margin-top: '20px' } }, colors: { important: '#0000FF' } },
 *     });
 *     export default withThemedComponents(func)(MyComponent);
 *
 * You don't need to pass styles, colors, and version all the time; only pass
 * what's necessary for your component to render. Ideally, you'd never pass the
 * version, as to decouple your component from the details of the styles, but
 * you can do that if necessary.
 *
 * Then, in your component, you can access your theme information via the `theme`
 * prop.
 *
 *     const { styles, colors } = this.props.theme;
 *     return <div styles={{ ...styles.important, color: colors.important }}>Important text</div>
 *
 * Additinoally, if you'd prefer a prop other than theme, you can specify the name of the
 * prop you'd like. This is useful when you already have a `theme` prop from another component,
 * or when using multiple HOCs.
 *
 *     export default withThemedComponents(func, 'customTheme')(MyComponent);
 *     ...
 *     const { styles, colors } = this.props.theme;
 *
 * Finally, any themes specified together will be merged. So, if you are using multiple
 * withThemedComponents HOCs, the base component will get all styles merged into one
 * theme object:
 *
 *     export default compose(
 *       withThemedComponents(baseStyles),
 *       withThemedComponents(moreStyles),
 *     )(MyComponent);
 *
 * In this example, MyComponent's `theme` prop would received styles from both baseStyles
 * and moreStyles, merged, in that order or preference. While not a high-traffic use case,
 * this is useful when you want to share styles across multiple components.
 */
const withThemedComponents = (func, propName = 'theme') => WrappedComponent => forwardRef((props, ref) => {
  const getStyles = memoizeOne(func, checkVersion);
  const themeContext = useContext(ThemeContext);
  const pageContext = useContext(PageContext);

  const { [propName]: theme, ...passThrough } = props;

  const componentProps = {
    ...passThrough,
    [propName]: merge(theme, getStyles(themeContext, pageContext)),
  };

  return (
    <WrappedComponent
      {...componentProps /* eslint-disable-line react/jsx-props-no-spreading */}
      ref={ref}
    />
  );
});

export default withThemedComponents;

import rules from './mayRollbarRules';

/* global Rollbar */
/*
  Conditionally Rollbar something, based on a ruleset.
  This supports the various error logging functions (log, error, critical) but
  not the rest of the Rollbar API
*/

const received = {};
const sent = {};

/* eslint-disable no-console */
const log = (severity, message, error, data) => {
  const item = {
    message: message || '',
    error: error || {},
    data: data || {},
  };
  console.log(`logging with severity ${severity}`, item);
  for (let i = 0; i < rules.length; i += 1) {
    const rule = rules[i];
    let matched = false;
    // look in both the top-level message and nested error message
    if (rule.match.message) {
      if (item.message
        && item.message.match
        && item.message.match(new RegExp(rule.match.message))) {
        matched = true;
      }
      if (item.error
        && item.error.message
        && item.error.message.match
        && item.error.message.match(new RegExp(rule.match.message))) {
        matched = true;
      }
    }
    if (matched) {
      console.log(`matched rule ${rule.name}`);
      const count = (received[rule.id] || 0) + 1;
      received[rule.id] = count;
      if (rule.after && (count < rule.after)) {
        console.log(`count ${count} has not yet exceeded ${rule.after}, will not send to Rollbar`);
        return;
      }
      if (rule.every && (count % rule.every !== 0)) {
        console.log(`only attempting to send every ${rule.every} occurrences, will not send occurrence ${count} to Rollbar`);
        return;
      }
      const alreadySent = sent[rule.id] || 0;
      if (rule.limit && (alreadySent >= rule.limit)) {
        console.log(`already sent this ${alreadySent} times; limited to ${rule.limit} times; will not send to Rollbar`);
        return;
      }
      sent[rule.id] = alreadySent + 1;
    } else {
      console.log('does not match any rules, doing default action');
    }
  }
  if (Rollbar) {
    const args = [];
    if (message) {
      args.push(message);
    }
    if (error) {
      args.push(error);
    }
    if (data) {
      args.push(data);
    }
    switch (severity) {
      case 'debug':
        Rollbar.debug(...args);
        break;
      case 'info':
        Rollbar.info(...args);
        break;
      case 'warning':
        Rollbar.warning(...args);
        break;
      case 'error':
        Rollbar.error(...args);
        break;
      case 'critical':
        Rollbar.critical(...args);
        break;
      default:
        Rollbar.log(...args);
    }
  } else {
    console.log('Rollbar is not defined as expected; not forwarding anything');
  }
};

const mayRollbar = {
  log: (severity, message, error, data) => log(severity, message, error, data),
  debug: (message, error, data) => log('debug', message, error, data),
  info: (message, error, data) => log('info', message, error, data),
  warn: (message, error, data) => log('warning', message, error, data),
  warning: (message, error, data) => log('warning', message, error, data),
  error: (message, error, data) => log('error', message, error, data),
  critical: (message, error, data) => log('critical', message, error, data),
  isAvailable: () => {
    try {
      return !!Rollbar;
    } catch (e) {
      return false;
    }
  },
};

export default mayRollbar;

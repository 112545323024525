/* eslint-disable import/prefer-default-export, camelcase */
import {
  partition, flatten, uniq, uniqBy,
} from 'lodash';

import { reportError } from 'helpers/ErrorHandler';
import apiDirector from 'helpers/ApiDirector';
import { numeric } from 'helpers/i18n';
import * as DashboardUtils from 'components/home/DashboardUtils';
import * as referralActions from 'ducks/referrals';
import * as sessionActions from 'ducks/session/actions';
import {
  FEATURE_CONVERSATION_DASHBOARD,
} from 'ducks/session/types';
import { removeItemFromLoadingQueue } from 'ducks/loadingQueue/operations';
import * as actions from './actions';

const {
  referralStateMapping: defaultReferralStateMapping,
} = DashboardUtils;

const { shapeReferral: createShapedReferral } = referralActions;

const { replacePreferences } = sessionActions;

const shapeTaskToReferral = ({
  id, referral_id, provider_referral_id, sent_referral_id,
  referral_role, task_id, task_type, overdue_at, completed_at, expired, hidden,
}) => ({
  id: provider_referral_id || referral_id,
  sent_referral_id,
  expired,
  hidden,
  task_id: task_id || id,
  task: {
    id: task_id || id,
    task_type,
    overdue_at,
    completed_at,
    expired,
    hidden,
    task_role: {
      referralRole: referral_role,
      sent_referral_id,
    },
  },
});

const shapeReferral = (referral, getState, rethrow) => {
  try {
    return createShapedReferral(referral, getState);
  } catch (e) {
    reportError(e, 'A home dashboard referral was parsed without a reservationStatus', {
      id: referral.id,
      status: referral.status,
      discharge_date: referral.discharge_date,
      currentRole: referral.currentRole,
      referralRole: referral.referralRole,
      hospital: referral.hospital,
      provider: referral.provider,
    });
    if (rethrow) {
      throw e;
    }
    return null;
  }
};

const shapeDashboardIndex = (
  {
    active_referrals = [],
    in_progress_referrals = [],
    completed_referrals = [],
    in_house: {
      inhouse_hospital_referrals = [],
      inhouse_outbound_referrals = [],
      inhouse_signature_referrals = [],
      inhouse_authorization_referrals = [],
      discharged_outbound_referrals = [],
      discharged_signature_referrals = [],
    } = {},
    discharged: {
      discharged_hospital_referrals = [],
      discharged_authorization_referrals = [],
      canceled_outbound_referrals = [],
      canceled_signature_referrals = [],
    } = {},
    command_center_acute: {
      command_center_acute_referrals = [],
    } = {},
    command_center_trns: {
      command_center_trns_referrals = [],
    } = {},
    opportunities = [],
    incoming = [],
    closed = [],
    insights = {},
    ...other
  },
) => {
  const [expiredInProgress, currentInProgress] = partition(in_progress_referrals.map(shapeTaskToReferral), 'expired');
  const [expiredActive, currentActive] = partition(active_referrals.map(shapeTaskToReferral), 'expired');

  const [hiddenOpportunities, visibleOpportunities] = partition(opportunities.map(shapeTaskToReferral), 'hidden');
  const [expiredOpportunities, currentOpportunities] = partition(visibleOpportunities, 'expired');

  const [hiddenInCare, visibleInCare] = partition(incoming.map(shapeTaskToReferral), 'hidden');
  const [expiredInCare, currentInCare] = partition(visibleInCare, 'expired');

  const mapPatientReferrals = items => items.map(({ id, matching_referrals: referrals = [] }) => ({
    id, referrals,
  }));
  return {
    ...other,
    active_referrals: currentActive,
    expired_active_referrals: expiredActive,
    in_progress_referrals: currentInProgress,
    expired_in_progress_referrals: expiredInProgress,
    completed_referrals: completed_referrals.map(shapeTaskToReferral),
    in_house: [
      inhouse_hospital_referrals,
      inhouse_outbound_referrals,
      inhouse_signature_referrals,
      inhouse_authorization_referrals,
    ].reduce((set, item) => set.concat(mapPatientReferrals(item)), []),
    expired_in_house: [
      discharged_outbound_referrals,
      discharged_signature_referrals,
    ].reduce((set, item) => set.concat(mapPatientReferrals(item)), []),
    discharged: [
      discharged_hospital_referrals,
      discharged_authorization_referrals,
      canceled_outbound_referrals,
      canceled_signature_referrals,
    ].reduce((set, item) => set.concat(mapPatientReferrals(item)), []),
    opportunities: currentOpportunities,
    expired_opportunities: expiredOpportunities,
    hidden_opportunities: hiddenOpportunities,
    incoming: currentInCare,
    expired_incoming: expiredInCare,
    hidden_incoming: hiddenInCare,
    closed: closed.map(shapeTaskToReferral),
    command_center_acute: mapPatientReferrals(command_center_acute_referrals),
    command_center_trns: mapPatientReferrals(command_center_trns_referrals),
    insights,
  };
};

const shapeAndFilter = (list, getState) => list
  .map(referral => shapeReferral(referral, getState)).filter(r => !!r);

const shapeDashboardContent = (
  {
    active_referrals = [],
    in_progress_referrals = [],
    completed_referrals = [],
    in_house: {
      inhouse_hospital_referrals = [],
      inhouse_outbound_referrals = [],
      inhouse_signature_referrals = [],
      inhouse_authorization_referrals = [],
      discharged_outbound_referrals = [],
      discharged_signature_referrals = [],
    } = {},
    discharged: {
      discharged_hospital_referrals = [],
      discharged_authorization_referrals = [],
      canceled_outbound_referrals = [],
      canceled_signature_referrals = [],
    } = {},
    command_center_acute: {
      command_center_acute_referrals = [],
    } = {},
    command_center_trns: {
      command_center_trns_referrals = [],
    } = {},
    opportunities = [],
    incoming = [],
    closed = [],
    ...other
  } = {},
  getState,
) => ({
  ...other,
  active_referrals: shapeAndFilter(active_referrals, getState)
    .filter(({ reservationStatus }) => reservationStatus && reservationStatus !== 'missed.closed'),
  in_progress_referrals: shapeAndFilter(in_progress_referrals, getState),
  completed_referrals: shapeAndFilter(completed_referrals, getState),
  in_house: inhouse_hospital_referrals
    .concat(inhouse_outbound_referrals)
    .concat(inhouse_signature_referrals)
    .concat(inhouse_authorization_referrals)
    .concat(discharged_outbound_referrals)
    .concat(discharged_signature_referrals)
    .map(patient => ({
      ...patient,
      referrals: []
        .concat(patient.inhouse_hospital_referrals || [])
        .concat(patient.inhouse_outbound_referrals || [])
        .concat(patient.inhouse_signature_referrals || [])
        .concat(patient.inhouse_authorization_referrals || [])
        .concat(patient.discharged_outbound_referrals || [])
        .concat(patient.discharged_signature_referrals || [])
        .map(referral => shapeReferral(referral, getState))
        .filter(r => !!r),
    }))
    .reduce((set, patient) => {
      const pt = set.find(item => item.id === patient.id);
      if (pt) {
        pt.referrals = uniqBy(pt.referrals.concat(patient.referrals), 'id');
      } else {
        set.push(patient);
      }
      return set;
    }, []),
  discharged: discharged_hospital_referrals
    .concat(discharged_authorization_referrals)
    .concat(canceled_outbound_referrals)
    .concat(canceled_signature_referrals)
    .map(patient => ({
      ...patient,
      referrals: []
        .concat(patient.discharged_hospital_referrals || [])
        .concat(patient.discharged_authorization_referrals || [])
        .concat(patient.canceled_outbound_referrals || [])
        .concat(patient.canceled_signature_referrals || [])
        .map(referral => shapeReferral(referral, getState))
        .filter(r => !!r),
    }))
    .reduce((set, patient) => {
      const pt = set.find(item => item.id === patient.id);
      if (pt) {
        pt.referrals = uniqBy(pt.referrals.concat(patient.referrals), 'id');
      } else {
        set.push(patient);
      }
      return set;
    }, []),
  opportunities: shapeAndFilter(opportunities, getState)
    .filter(({ reservationStatus }) => reservationStatus && reservationStatus !== 'missed.closed'),
  incoming: shapeAndFilter(incoming, getState),
  closed: shapeAndFilter(closed, getState),
  command_center_acute: command_center_acute_referrals.map(patient => ({
    ...patient,
    referrals: (patient.command_center_acute_referrals || [])
      .map(referral => shapeReferral(referral, getState))
      .filter(r => !!r),
  })),
  command_center_trns: command_center_trns_referrals.map(patient => ({
    ...patient,
    referrals: (patient.command_center_trns_referrals || [])
      .map(referral => shapeReferral(referral, getState))
      .filter(r => !!r),
  })),
});

export const requestDashboardContent = (request, doReplace = false, prepend = false) => (dispatch, getState) => apiDirector.validateFetch('/api/dashboard/content', {
  method: 'POST',
  body: JSON.stringify({
    dashboard: {
      data: {
        ...request,
      },
      filters: request.filters,
    },
  }),
})
  .then(json => shapeDashboardContent(json, getState))
  .then(json => Object.keys(request).filter(key => key !== 'filters').reduce((sum, item) => ({
    ...sum,
    [item]: json[item],
  }), {}))
  .then(json => dispatch(doReplace
    ? actions.replaceDashboardData(json) : actions.addDashboardData(json, prepend)));

export const requestDashboardData = (key, options, filters = {}) => (dispatch, getState) => {
  const keys = typeof key === 'string' ? [key] : key;
  return apiDirector.validateFetch('/api/dashboard', {
    method: 'POST',
    body: JSON.stringify({
      dashboard: {
        data: keys,
        options: options
          ? keys.reduce((sum, item) => ({ ...sum, [item]: options[item] }), {})
          : undefined,
        filters,
      },
    }),
  })
    .then(json => shapeDashboardIndex(json, getState))
    .then(json => keys.reduce((sum, item) => ({ ...sum, [item]: json[item] }), {}))
    .then(json => dispatch(options && options.append
      ? actions.addDashboardIndex(json) : actions.replaceDashboardIndex(json)));
};

export const refreshHomeDashboard = () => (dispatch, getState) => apiDirector.validateFetch('/api/dashboard', {
  method: 'POST',
  body: JSON.stringify({
    dashboard: {
      filters: getState().dashboard.filters,
    },
  }),
})
  .then(json => shapeDashboardIndex(json, getState))
  .then(json => dispatch(actions.replaceDashboardIndex(json)));

const refreshDashboardIconSection = (referralId, organizationType, organizationId, iconSection, dispatch, getState) => {
  const {
    dashboard: {
      filters: { section } = {}, content,
    },
  } = getState();
  let hasReferralOnDashboard = false;
  if (section && content[section]) {
    hasReferralOnDashboard = content[section].some(item => {
      if (item.referrals) {
        return item.referrals.some(i => i.id === referralId);
      }
      return item.id === referralId;
    });
  }
  if (hasReferralOnDashboard) {
    // FIXME: Make more robust
    const role = section === 'in_house' || section === 'discharged'
      ? 'sending' : 'receiving';
    const source = iconSection;
    if (source === 'referral_events') {
      return dispatch(actions.mergeDashboardReferralEvents(referralId, organizationType, organizationId));
    }
    return apiDirector.validateFetch('/api/dashboard/content', {
      method: 'POST',
      body: JSON.stringify({
        dashboard: {
          data: {
            [source]: [
              { id: referralId, source, role },
            ],
          },
          filters: getState().dashboard.filters,
        },
      }),
    })
      .then(json => shapeDashboardContent(json, getState))
      .then(json => dispatch(actions.mergeDashboardMessages(referralId, json[source], source)));
  }
  return Promise.resolve([]);
};

export const refreshDashboardReferralEvents = (referralEvent) => (dispatch, getState) => {
  refreshDashboardIconSection(referralEvent.referral_id, referralEvent.organization_type, referralEvent.organization_id, 'referral_events', dispatch, getState);
};

export const refreshDashboardReferralMessages = (referralId) => (dispatch, getState) => {
  const {
    session: {
      features,
    },
  } = getState();
  const source = features[FEATURE_CONVERSATION_DASHBOARD] ? 'conversations' : 'messages';
  refreshDashboardIconSection(referralId, null, null, source, dispatch, getState);
};

export const refreshPatientDashboard = (fetchKeys) => (dispatch, getState) => apiDirector.validateFetch('/api/dashboard', {
  method: 'POST',
  body: JSON.stringify({
    dashboard: {
      data: [...fetchKeys, 'insights'],
      filters: getState().dashboard.filters,
    },
  }),
})
  .then(json => shapeDashboardIndex(json, getState))
  .then(json => dispatch(actions.replaceDashboardIndex(json)));

export const requestDashboardReferral = (
  referralId,
  {
    referralStateMapping = defaultReferralStateMapping,
    categoryMapping = {
      respond: ['active_referrals'],
      inProgress: ['in_progress_referrals'],
      completed: ['completed_referrals'],
    },
    source = 'home',
    fetchKeys,
  } = {},
) => (dispatch, getState) => {
  const getReferralCategory = (referral) => referralStateMapping[referral.reservationStatus]
    || referral.reservationStatus;
  return apiDirector.validateFetch(`/api/referrals/${referralId}`)
    .then(referral => shapeReferral(referral, getState, true))
    .then((referral) => {
      const [category] = categoryMapping[getReferralCategory(referral)] || [];
      if (!category) {
        // This referral has fallen off the dashboard, remove it.
        return dispatch(actions.removeDashboardReferral(referral));
      }
      if (referral.referralRoles.length > 1 && !referral.isMatched()) {
        /*
         * TODO: There are ways to make this more efficient, such as only refreshing
         * when each response yields a different role. But for today, just going to
         * refresh the dashboard, because the new referral could have moved anywhere.
         */
        const refresh = source === 'patients'
          ? refreshPatientDashboard : refreshHomeDashboard;
        return dispatch(refresh(fetchKeys))
          .then(() => dispatch(actions.addDashboardReferral(referral)));
      }

      // Just one meaningful role, we may be able to update in-place.
      if (source !== 'patients' && getState().dashboard.index[category].find(r => r.id === referral.id)) {
        // Either one role, or the current role should match the matched provider
        const {
          provider_id: matchedProviderId,
          currentRole: {
            referral: {
              referralResponseOrganization: {
                id: providerId,
              } = {},
            } = {},
          } = {},
        } = referral;

        if (referral.referralRoles.length === 1 || matchedProviderId === providerId) {
          // If it exists, replace content in-place
          return dispatch(actions.addDashboardReferral(referral, category));
        }
      }
      /*
       * Else, need to update the indices.
       * TODO: Determine the "old" category and we'll only need to update two instead
       * of all of them.
       */
      const allBuckets = flatten(Object.values(categoryMapping));
      return Promise.resolve(dispatch(requestDashboardData(allBuckets)))
        .then(() => dispatch(actions.addDashboardReferral(referral, category)));
    })
    .catch((e) => {
      if (e.status === 404 && e.message === 'missing_status') {
        reportError(e, 'A home dashboard referral was parsed without a reservationStatus', {
          id: referralId,
        });
      }
    });
};

const getDashboardKeys = (defaultKeys, getState) => {
  const {
    session: {
      classicMode,
    } = {},
  } = getState();

  const additionalKeys = [];

  const classicModeOnly = [];

  const keys = defaultKeys.filter(key => classicMode || !classicModeOnly.some(i => i === key));

  return uniq([...keys, ...additionalKeys]);
};

export const requestDashboardPatient = patientId => (dispatch, getState) => {
  const {
    dashboard: {
      filters: { line_of_service: lineOfService } = {},
    } = {},
  } = getState();
  const defaultPatientDashboardKeys = numeric(lineOfService, {
    sending: ['in_house', 'discharged'],
    receiving: ['opportunities', 'incoming', 'closed'],
    other: [],
  });
  if (defaultPatientDashboardKeys.length === 0) {
    return Promise.resolve({});
  }
  const patientDataKeys = getDashboardKeys(defaultPatientDashboardKeys, getState);
  return apiDirector.validateFetch('/api/dashboard/content', {
    method: 'POST',
    body: JSON.stringify({
      dashboard: {
        data: patientDataKeys.reduce((set, key) => ({
          ...set,
          [key]: [{ id: patientId }],
        }), {}),
      },
    }),
  })
    .then(json => shapeDashboardContent(json, getState))
    .then(json => patientDataKeys.reduce((set, key) => ({
      ...set,
      [key]: json[key],
    }), {}))
    .then(json => dispatch(actions.mergeDashboardData(json)));
};

export const replacePushedDashboard = ({
  patient_id,
  referral: pushedReferral,
  ...payload
}) => (dispatch, getState) => Promise.resolve(payload)
  .then(json => shapeDashboardIndex(json, getState))
  .then(json => dispatch(actions.replaceDashboardIndex(json)))
  .then(() => Promise.resolve(pushedReferral)
    .then(referral => shapeReferral(referral, getState, true))
    .then((referral) => {
      const referralCategory = defaultReferralStateMapping[referral.reservationStatus]
        || referral.reservationStatus;

      const [category] = {
        respond: ['active_referrals'],
        inProgress: ['in_progress_referrals'],
        completed: ['completed_referrals'],
      }[referralCategory] || [];

      if (!category) {
        return dispatch(actions.removeDashboardReferral(referral));
      }

      return dispatch(actions.addDashboardReferral(referral, category));
    })
    .catch((e) => {
      if (e.status === 404 && e.message === 'missing_status') {
        reportError(e, 'A home dashboard referral was parsed without a reservationStatus', {
          id: pushedReferral.id,
        });
      }
    }))
  .then(() => patient_id && dispatch(requestDashboardPatient(patient_id)))
  .then(() => pushedReferral && dispatch(removeItemFromLoadingQueue(`dashboardCard-${pushedReferral.id}`)));

const makeDashboardRequest = (dispatch, getState, fetchData, filters) => (
  new Promise((resolve, reject) => {
    dispatch(actions.incrementDashboardVersion()).then(() => {
      const requestVersion = getState().dashboardMetadata.version;
      fetchData().then((json) => {
        const currentVersion = getState().dashboardMetadata.version;
        if (requestVersion === currentVersion) {
          // FIXME: This should probably come from ActionCable
          const dashboardPreferences = { dashboard: filters };
          if (filters.line_of_service) {
            dashboardPreferences[`${filters.line_of_service}_dashboard`] = filters;
          }
          dispatch(replacePreferences(dashboardPreferences, true))
            .then(() => shapeDashboardIndex(json, getState))
            .then((shaped) => dispatch(actions.replaceDashboard(shaped, getState)))
            .then(() => resolve())
            .catch(reject);
        }
      });
    });
  })
);

export const requestPatientDashboard = (fetchKeys, filters = {}) => (dispatch, getState) => (
  makeDashboardRequest(
    dispatch,
    getState,
    () => apiDirector.validateFetch('/api/dashboard', {
      method: 'POST',
      body: JSON.stringify({
        dashboard: {
          data: getDashboardKeys([...fetchKeys, 'insights'], getState),
          filters,
        },
      }),
    }),
    filters,
  )
);

export const requestHomeDashboard = (filters = {}) => (dispatch, getState) => makeDashboardRequest(
  dispatch,
  getState,
  () => apiDirector.validateFetch('/api/dashboard', {
    method: 'POST',
    body: JSON.stringify({
      dashboard: {
        data: getDashboardKeys(['active_referrals', 'in_progress_referrals', 'completed_referrals'], getState),
        filters,
      },
    }),
  }),
  filters,
);

export const unfollowPatient = patientId => (dispatch, getState) => {
  const { session: { user: { id } = {} } = {} } = getState();
  return apiDirector.validateFetch(`/api/users/${id}/unassign_follower`, {
    method: 'POST',
    body: JSON.stringify({
      unfollow: {
        id: patientId,
        type: 'Patient',
      },
    }),
  })
    .then(() => dispatch(actions.removePatientFromDashboard(patientId)));
};

export const removeFollowerFromPatient = (
  id,
  userId,
  organizationId,
  organizationType,
) => (
  dispatch,
  getState,
) => {
  const { session: { user: { id: sessionUserId } = {} } = {} } = getState();

  return apiDirector.validateFetch(`/api/patients/${id}/followers/${userId}?organization_id=${organizationId}&organization_type=${organizationType}`, {
    method: 'DELETE',
  }).then(json => dispatch(userId === sessionUserId
    ? actions.removePatientFromDashboard(id)
    : referralActions.replaceReferralFollowers(json)));
};

export const requestFollowersForPatient = id => dispatch => (
  apiDirector.validateFetch(`/api/patients/${id}/followers`)
    .then(json => dispatch(referralActions.replaceReferralFollowers(json)))
);

export const createVisibilityRule = (rule, metadata = {}) => dispatch => apiDirector.validateFetch('/api/visibility_rules', {
  method: 'POST',
  body: JSON.stringify({
    ...metadata,
    visibility_rule: rule,
  }),
})
  .then(() => {
    switch (rule.record_type) {
      case 'Task':
        return dispatch(actions.removeDashboardTask(rule.record_id));
      case 'Referral':
        return dispatch(actions.removePatientReferral(rule.record_id));
      default:
        return rule;
    }
  });

/* eslint-disable react/prop-types */

import React from 'react';
import { css } from 'emotion';
import { baseModalStyleAttributes } from 'components/utils/Styles';

const triggerStyle = css`
  display: unset;
`;

/**
 * Create a referenceable trigger. Without this, Popup will fail.
 * If you override, make sure your popup's trigger is referenceable.
 *
 * For components, use React.forwardRef
 * For styled components, use innerRef
 * For connected components, use connect(a, b, c, { forwardRef: true })
 *
 */
export const createTrigger = ({
  trigger: defaultTrigger,
  triggerElement,
} = {}, customTrigger) => {
  const TriggerEl = typeof triggerElement === 'undefined'
    ? 'div'
    : triggerElement;
  const trigger = customTrigger || defaultTrigger;

  return TriggerEl ? <TriggerEl className={triggerStyle}>{trigger}</TriggerEl> : trigger;
};

export const modalOverrides = {
  width: undefined,
  background: undefined,
  border: undefined,
  boxShadow: undefined,
  padding: undefined,
  zIndex: undefined,
};

export { baseModalStyleAttributes };

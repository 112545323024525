import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import CheckboxField from 'components/utils/CheckboxField';
import Panel from 'components/utils/Panel';
import SelectField from 'components/utils/SelectField';
import ViewContext from 'components/utils/ViewContext';

/**
 * A select element that renders the selected option as a checkbox beneath it.
 * Multiple options may be selected one at a time to render multiple checkboxes.
 * Unchecking a checkbox is intended to remove that rendered option via callback.
 */
const MultiSelectFilter = ({
  empty,
  filter,
  itemClassName,
  items,
  label,
  onChange,
  onSelect,
  fullWidth,
  placeholder,
  itemRenderer,
  changeValueState,
  valueRenderer,
}) => {
  const { readOnly } = useContext(ViewContext);
  const selection = filter.value.sort((a, b) => (a.label || '').localeCompare(b.label || ''));
  return (
    <Panel spacing={10} itemClassName={itemClassName} fullWidth={fullWidth}>
      {!readOnly ? (
        <SelectField
          label={label}
          changeValueState={changeValueState}
          placeholder={placeholder}
          items={items.sort((a, b) => (a.label || '').localeCompare(b.label || ''))}
          onSelect={({ value } = {}) => onSelect(value, filter)}
          maxHeight="400px"
          itemRenderer={itemRenderer}
          valueRenderer={valueRenderer}
        />
      ) : <h4>{label}</h4>}
      <Panel spacing={4}>
        {(selection.length === 0 && empty) ? empty : selection.map(o => (
          <CheckboxField
            key={o.value}
            label={o.label}
            disabled={o.disableCheckbox}
            onChange={() => onChange(o.value, filter)}
            value
          />
        ))}
      </Panel>
    </Panel>
  );
};

const SelectOption = PropTypes.shape({
  /** Label attribute */
  label: PropTypes.string,
  /** Value attribute */
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /** True to disable checkbox, false otherwise */
  disableCheckbox: PropTypes.bool,
});

MultiSelectFilter.propTypes = {
  /** In read only mode, will show empty value if no values chosen */
  empty: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.func]),
  /** An object representing properties of the filterable collection */
  filter: PropTypes.shape({
    /** Selected filter options */
    value: PropTypes.arrayOf(SelectOption),
  }),
  /** Additional class name for the container panel */
  itemClassName: PropTypes.string,
  /** Collection of possible select element options */
  items: PropTypes.arrayOf(SelectOption).isRequired,
  /** Select element label */
  label: PropTypes.string,
  /**
   * Callback fired when checkbox is checked, typically when it's
   * unchecked, as it will default to a checked state in virtue of being
   * added via the onSelect callback. Remove the option
   * from the filter object's value in the callback.
   */
  onChange: PropTypes.func.isRequired,
  /**
   * Callback fired when select option is selected. Adds the option
   * to the filter object's value in the callback.
   */
  onSelect: PropTypes.func.isRequired,
  /** Set fullWidth property on container panel */
  fullWidth: PropTypes.bool,
  /** Set select element placeholder property */
  placeholder: PropTypes.string.isRequired,
  /** Set look and feel of item display */
  itemRenderer: PropTypes.func,
  /** Change if the underlying state is saved for SelectField */
  changeValueState: PropTypes.bool,
  /** Optional function for custom value rendering */
  valueRenderer: PropTypes.func,
};

MultiSelectFilter.defaultProps = {
  empty: undefined,
  filter: undefined,
  itemClassName: undefined,
  fullWidth: undefined,
  label: undefined,
  itemRenderer: undefined,
  changeValueState: undefined,
  valueRenderer: undefined,
};

export default MultiSelectFilter;

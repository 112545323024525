import React from 'react';
import PropTypes from 'prop-types';

import { createTranslator, numeric } from 'helpers/i18n';

import {
  Link,
  Button,
} from 'components/utils';
import withThemedComponents, { ThemeConfig } from 'components/utils/withThemedComponents';

import text from 'translations/login';

const tr = createTranslator(text);

const createComponentStyles = ({ version, theme: { colors } }) => numeric(version, {
  other: {
    styles: {},
  },
  v2: {
    styles: {
      buttonColor: colors.RECEIVING_DARKER_FILL,
    },
  },
});

const ReturnToLoginButton = ({
  onClick,
  theme: {
    styles: {
      buttonColor,
    },
  },
}) => (
  <Link to="/devise_users/sign_in">
    <Button small onClick={onClick} color={buttonColor}>
      {tr('links.backToLogin')}
    </Button>
  </Link>
);
ReturnToLoginButton.propTypes = {
  onClick: PropTypes.func,
  theme: ThemeConfig.isRequired,
};
ReturnToLoginButton.defaultProps = {
  onClick: undefined,
};

export default withThemedComponents(createComponentStyles)(ReturnToLoginButton);

import initialState from 'store/initialState';
import * as types from './types';

const loadingQueue = (state = initialState.loadingQueue, { type, key, ...entity } = {}) => {
  switch (type) {
    case types.LOADING_QUEUE_PUSH_KEY: {
      return {
        ...state,
        [key]: entity,
      };
    }
    case types.LOADING_QUEUE_POP_KEY: {
      const {
        [key]: {
          timeoutId: forget,
        } = {},
        ...rest
      } = state;
      clearTimeout(forget);
      return rest;
    }
    case types.LOADING_QUEUE_UPDATE_KEY: {
      const { metadata = {} } = entity;
      if (!state[key]) {
        return state;
      }
      return {
        ...state,
        [key]: {
          ...state[key],
          metadata: {
            ...state[key].metadata,
            ...metadata,
          },
        },
      };
    }
    default:
      return state;
  }
};

export default {
  loadingQueue,
};

/* eslint-disable camelcase */

import React from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { withRouter } from 'react-router-dom';

import AidinPropTypes from 'helpers/AidinPropTypes';
import Referral from 'models/Referral';
import { createTranslator } from 'helpers/i18n';
import { errorToast } from 'helpers/ui';
import { Date, DateTime } from 'helpers/dates';
import ReferralActionEngine from 'helpers/ReferralActionEngine';
import { openCreateReferralConflictModal } from 'ducks/ui';

import styled from 'react-emotion';
import { css, cx } from 'emotion';
import { connect } from 'react-redux';
import * as actions from 'ducks/referrals';
import Session from 'models/Session';

import { trCareDates, dashboardReservationStatus } from 'translations/referrals';

import ComingSoon from 'components/utils/ComingSoon';
import Panel from 'components/utils/Panel';
import Icon from 'components/utils/Icon';
import * as Styles from 'components/utils/Styles';
import * as Colors from 'components/utils/Colors';
import RelativeTime, { relativeDaysFormatter } from 'components/utils/RelativeTime';

import { getReferralCategory } from 'components/home/DashboardUtils';
import PatientNumber from 'components/home/PatientNumber';

import NewReferralFromPatientLink from './NewReferralFromPatientLink';

const { UPDATED, LIGHTEST_GRAY_FILL } = Colors;
const { Label, LinkText, Secondary } = Styles;

const tr = createTranslator({
  discharge_date: trCareDates('discharging'),
  start_of_care: trCareDates('acceptedCare'),
  end_of_care: trCareDates('leftCare'),
  all_filtered: 'No referrals for the current patient visit',
  show_older: 'Show older referrals',
  status: dashboardReservationStatus,
  new: 'Create new referral',
  loading: 'Loading...',
  loading_discharge: 'Loading discharge date...',
  dob: value => `DOB: ${Date.format(Date.parse(value))}`,
});

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  ${Label} {
    ${props => (props.category === 'completed' ? 'opacity: 0.5;' : '')}
    margin-bottom: 0px;
  }
`;

const Header = styled.h4`
  margin-top: 0px;
  margin-bottom: 0px;
  cursor: pointer;
  ${props => props.deidentified && 'opacity: 0.5;'}
`;

const Column = styled.div`
  width: ${props => props.width}px;
`;

const IconPlaceholder = styled.div`
  width: 12px;
`;

const iconStyle = css`
  display: block;
`;

const containerStyle = css`
  padding: 10px;
`;

const clickableCardStyle = css`
  cursor: pointer;
  &:hover {
    background-color: ${LIGHTEST_GRAY_FILL};
  };
`;

const queryInputMatchStyle = css`
  background-color: ${LIGHTEST_GRAY_FILL};
`;

const referralRowStyle = css`
  ${Row} {
    padding-top: 4px;
    min-height: 21px;
  };
`;

const loadingResultsStyle = css`
  opacity: 0.5;
`;

const comingSoonStyle = css`
  margin-top: 10px;
`;

const LoadingText = styled(Secondary)`
  flex-grow: 1;
  text-align: center;
`;

/* eslint-disable-next-line no-unused-vars */
const handleMenuItemClick = (key) => {
  // TODO: handle click
};

const FlexPanel = styled.div`
  display: flex;
`;

const createEngine = (history, onSelect, referral, referralActions) => (
  new ReferralActionEngine(referral, referralActions)
    .setExecuteCompleteHandler(onSelect)
    .setRedirectHandler((url) => { if (onSelect) { onSelect(); } history.push(url); })
    .setErrorHandler((e) => { errorToast(e.message); })
);

const getReferralRowStyle = (
  clickHandler,
  {
    hospital_id,
    hospital_type,
    queryInputMatch,
  },
  organizations,
) => {
  if (clickHandler || queryInputMatch) {
    const csx = [];
    if (clickHandler) {
      csx.push(clickableCardStyle);
    }
    if (queryInputMatch) {
      csx.push(queryInputMatchStyle);
    }
    if (!organizations.some(({ id, organization_type }) => (
      id === hospital_id && organization_type === hospital_type
    ))) {
      csx.push(loadingResultsStyle);
    }
    return cx(csx);
  }
  return undefined;
};

const getReferralType = referral => {
  const pt = referral.level_of_care.join(', ');
  if (!referral.checklist_configuration) {
    return referral.level_of_care.length > 0 ? pt : null;
  }
  if (referral.checklist_configuration.category === 'doc') {
    const name = referral.checklist_configuration.name || 'DOC';
    return name.toUpperCase();
  }
  return pt;
};

const renderReferralType = (loading, referral) => {
  if (referral.referralRole !== 'sending') { return null; }
  const label = getReferralType(referral);
  return label && (
    <Column width={40}>
      <Label className={loading ? loadingResultsStyle : undefined}>
        {label}
      </Label>
    </Column>
  );
};

const renderReferralRow = (
  referral, clickHandler, engineBuilder, loading, showUpdatedAtColumn, { organizations },
) => (
  <Row
    key={referral.id}
    category={getReferralCategory(referral, referral.reservationStatus)}
    className={getReferralRowStyle(clickHandler, referral, organizations)}
    onClick={clickHandler}
  >
    <FlexPanel>
      {renderReferralType(loading, referral)}
      <Column width={50}>
        <Label className={loading ? loadingResultsStyle : undefined}>
          #{referral.id}
        </Label>
      </Column>
    </FlexPanel>
    {loading && (
      <LoadingText>{tr('loading')}</LoadingText>
    )}
    <Panel orientation="horizontal" spacing={4}>
      <Label>{tr(`status.${referral.referralRole}.${referral.reservationStatus}`, referral)}</Label>
      {referral.reservationStatus === 'updated'
        ? (
          <Icon
            className={iconStyle}
            size={16}
            name="mp-warning"
            color={UPDATED}
          />
        )
        : <IconPlaceholder />}
    </Panel>
    {!loading && showUpdatedAtColumn && (
      <Panel>
        <Label style={{ textTransform: 'none' }}>{DateTime.dayFormat(referral.updated_at, { format: 'MMM Do' })}</Label>
      </Panel>
    )}
  </Row>
);

const getActiveReferrals = referrals => referrals
  .filter(r => r.reservationStatus !== 'cancelled');

const createDateFilter = unit => (referral) => {
  switch (unit) {
    case 'start_of_care':
      return referral.start_of_care || referral.discharge_date;
    case 'end_of_care':
      return referral.end_of_care || referral.provider_anticipated_discharge_date;
    default:
      return referral.discharge_date;
  }
};

const getMostRecentDate = (referrals, dateFilter) => referrals
  .filter(dateFilter)
  .map(r => Date.parse(dateFilter(r)))
  .reduce((recent, next) => {
    if (recent) {
      return next.isSameOrBefore(recent) ? recent : next;
    }
    return next;
  }, null);

const sortReferralsByUpdatedAt = ({ updated_at: ua1 }, { updated_at: ua2 }) => {
  if (ua1 > ua2) return -1;
  if (ua1 < ua2) return 1;
  return 0;
};

/** Display dashboard details for a patient and noted referrals */
const PatientPreviewCard = ({
  date,
  history,
  onClick,
  onSelect,
  patient,
  referrals = [],
  loading,
  session,
  showCreateNewReferralLink,
  showDateOfBirth,
  showPatientNumber,
  showUpdatedAtColumn,
  customHeader,
  isFiltered,
  ...referralActions
}) => {
  const engineBuilder = referral => createEngine(history, onSelect, referral, referralActions);

  const patientClickHandler = () => {
    if (onClick) {
      onClick(patient, 'Patient');
    } else {
      engineBuilder({ patient }).handle('see_all_for_patient');
    }
  };
  const clickHandler = index => () => {
    if (onClick) {
      onClick(referrals[index], 'Referral');
    } else {
      engineBuilder(referrals[index]).handle('open_referral');
    }
  };
  const activeReferrals = getActiveReferrals(referrals);
  const mostRecent = getMostRecentDate(activeReferrals, createDateFilter(date));
  const canCreateReferral = session.organizations.some(({ id, organization_type }) => {
    const locations = [].concat(patient.mrns || []);
    if (patient.hospital_id && patient.hospital_type) {
      locations.push({
        organization_id: patient.hospital_id,
        organization_type: patient.hospital_type,
      });
    } else if (patient.hospital) {
      locations.push({
        organization_id: patient.hospital.id,
        organization_type: patient.hospital.organization_type,
      });
    }

    return locations
      .filter(({ organization_type: type }) => type === 'Hospital')
      .some(({
        organization_id: patientHospitalId,
        organization_type: patientHospitalType,
      }) => (
        id === patientHospitalId && organization_type === patientHospitalType
      ));
  });
  return (
    <Panel
      data-testid="preview-patient-item"
      className={containerStyle}
      itemClassName={referralRowStyle}
      spacing={0}
    >
      <Header
        className={clickableCardStyle}
        deidentified={patient.deidentified}
        onClick={patientClickHandler}
      >
        {customHeader || patient.name}
      </Header>
      {showDateOfBirth && patient.date_of_birth && (
        <Label>{tr('dob', patient.date_of_birth)}</Label>
      )}
      {showPatientNumber && (
        <PatientNumber
          element={Label}
          patient={patient}
          prefix={showDateOfBirth}
        />
      )}
      {referrals
        .sort(sortReferralsByUpdatedAt)
        .map((referral, index) => renderReferralRow({
          patient,
          ...referral,
        },
        clickHandler(index), engineBuilder, loading, showUpdatedAtColumn, session))}
      {isFiltered && !referrals.length && (
        <Secondary>{tr('all_filtered')}</Secondary>
      )}
      {loading && activeReferrals.length > 0 && (
        <Secondary>{tr('loading_discharge')}</Secondary>
      )}
      {!loading && activeReferrals.length > 0 ? (
        <RelativeTime
          value={mostRecent}
          empty={tr(`${date}.empty`)}
          formatter={relativeDaysFormatter(tr(`${date}.formatter`))}
          element={Secondary}
        />
      ) : undefined }
      {canCreateReferral && showCreateNewReferralLink ? (
        <NewReferralFromPatientLink engine={engineBuilder({ patient })}>
          {tr('new')}
        </NewReferralFromPatientLink>
      ) : showCreateNewReferralLink && (
        <ComingSoon asLabel orientation="vertical" align="left" className={comingSoonStyle} spacing={2}>
          <Secondary>{tr('new')}</Secondary>
        </ComingSoon>
      )}
      { isFiltered && (
        <LinkText onClick={patientClickHandler}>{tr('show_older')}</LinkText>
      )}
    </Panel>
  );
};
PatientPreviewCard.propTypes = {
  date: PropTypes.oneOf(['discharge_date', 'start_of_care', 'end_of_care']).isRequired,
  patient: PropTypes.shape({
    name: PropTypes.string,
    date_of_birth: AidinPropTypes.date,
    deidentified: PropTypes.bool,
    hospital: PropTypes.shape({
      id: PropTypes.number,
      organization_type: PropTypes.oneOf(['Hospital', 'Provider']),
    }),
    hospital_id: PropTypes.number,
    hospital_type: PropTypes.oneOf(['Hospital', 'Provider']),
    patient_number: PropTypes.string,
    mrns: PropTypes.arrayOf(PropTypes.shape({
      organization_id: PropTypes.number,
      organization_type: PropTypes.oneOf(['Hospital', 'Provider']),
    })),
  }).isRequired,
  referrals: PropTypes.arrayOf(Referral).isRequired,
  // eslint-disable-next-line react/no-typos
  history: ReactRouterPropTypes.history.isRequired,
  /** Called when a click of some kind is done and passes the object, overrides engine action */
  onClick: PropTypes.func,
  /** Called after a click of some kind is done, good for cleanup */
  onSelect: PropTypes.func,
  /** Loading indicates that some props are in flux. Show loading panel for gear */
  loading: PropTypes.bool,
  /** The redux session */
  session: Session.isRequired,
  /** Override for the patient header */
  customHeader: PropTypes.string,
  /** Show create referral link */
  showCreateNewReferralLink: PropTypes.bool,
  /** Show the patient date of birth */
  showDateOfBirth: PropTypes.bool,
  /** Show the patient number */
  showPatientNumber: PropTypes.bool,
  /** Optionally show the updated_at column per referral */
  showUpdatedAtColumn: PropTypes.bool,
  /** Indicate that this view is filtered and give explicit link to see all referrals */
  isFiltered: PropTypes.bool,
};
PatientPreviewCard.defaultProps = {
  onClick: undefined,
  onSelect: undefined,
  loading: false,
  customHeader: undefined,
  showCreateNewReferralLink: true,
  showDateOfBirth: false,
  showPatientNumber: false,
  showUpdatedAtColumn: false,
  isFiltered: false,
};

const mapDispatchToProps = {
  ...actions,
  openCreateReferralConflictModal,
};

export default withRouter(
  connect(({ session }) => ({ session }), mapDispatchToProps)(PatientPreviewCard),
);

/*
 * Color palette, so we can map these to names for consistency.
 * Extracted from the style guide
 */

export const BODY_FILL = '#f9f9fa';
export const DARKER_GRAY_FILL = '#666666';
export const GRAY_FILL = '#aaaaaa';
export const SECONDARY_FILL = '#777777';
export const MEDIUM_DARK_GRAY_FILL = '#848484';
export const MEDIUM_LIGHT_GRAY_FILL = '#dddddd';
export const MEDIUM_GRAY_FILL = '#cccccc';
export const LIGHT_GRAY_FILL = '#e6e6e6';
export const LIGHTEST_GRAY_FILL = '#f9f9f9';
export const SENDING_DARKER_FILL = '#ebb300';
export const SENDING_PROGRESS_FILL = '#a07600';
export const RECEIVING_DARKEST_FILL = '#4A39CF';
export const RECEIVING_DARKER_FILL = '#8070ff';
export const RECEIVING_PROGRESS_FILL = '#4a39cf';
export const SENDING_LIGHTEST_FILL = '#fff5d7';
export const RECEIVING_LIGHTEST_FILL = '#e5e1ff';
export const COMPLETED_FILL = '#eeeeee';
export const CONFIRMATION_FILL = '#57b56c';
export const CONFIRMATION_TRANSPARENT_FILL = 'rgba(87, 181, 108, 0.1)';
export const NOTIFICATION_TRANSPARENT_FILL = 'rgba(67, 121, 255, 0.1)';
export const POOR_QUALITY_FILL = '#BED57B';
export const ERROR_DARKER_FILL = '#EE070D';
export const ERROR_LIGHT_FILL = '#ff7b7e';
export const ERROR_LIGHTEST_FILL = '#ffd6d6';
export const URGENT_OVERDUE = '#ff3e43';
export const WARNING_LIGHTEST_FILL = '#FFE0E1';
export const WARNING_PROGRESS_FILL = '#AE4F51';
export const UPDATED = '#fbd14b';
export const RECEIVING_LIGHT_FILL = '#bcb4fa';
export const RECEIVING_BADGE_FILL = RECEIVING_LIGHT_FILL;
export const SENDING_BADGE_FILL = '#FBD14B';
export const WHITE = '#ffffff';
export const BLACK = '#333333';
export const TRANSPARENT = 'transparent';
export const UNREAD = 'rgba(67,121,255,0.1)';
export const UNREAD_HOVER = 'rgba(67,121,255,0.16)';
export const ZIP_CODE_ELIGIBLE = '#8AACFE';

export const TEXT_ERROR = '#fe5054';

export const TIP_ERROR = '#ffd6d6';
export const TIP_WARNING = SENDING_LIGHTEST_FILL;
export const TIP_DEFAULT = '#f3f3f3';

export const TAG_DEFAULT = '#e9e9e9';

export const CONTENT_PANEL_TITLE = MEDIUM_LIGHT_GRAY_FILL;

export const NOTIFICATION_FILL = '#4379ff';
export const PRACTICE_FILL = '#95ADF5';

export const FORM_WARNING = '#EBB300';
export const FORM_WARNING_LIGHT = '#ffdf81';

const isValidEmail = value => value && value.match(/^[a-zA-Z0-9.!#$%&’*'+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/);
const isBlank = value => value === '';

const isValidZip = (zipcodes) => {
  if (!zipcodes) { return false; }
  const zipArray = Array.isArray(zipcodes) ? zipcodes : `${zipcodes}`.split(',');
  const pattern = /(^\d{5}$)|(^\d{5}-\d{4}$)/;

  return zipArray.every((zip) => {
    if (!zip) { return false; }
    return !!pattern.test(`${zip}`.trim());
  });
};

/**
   * Passing full will validate that a 10-digit fax number was given.
   * Without full, any input that is a string of numeric characters will pass.
   */
const isFaxNumber = (value, full) => {
  if (!value) { return false; }
  const input = value.replace(/[\D]/g, '');
  if (full) {
    return input.match(/^[0-9]{10}$/);
  }
  return input.match(/^[0-9]+/);
};

const MAX_CLINICAL_NEED_LENGTH = 80;

export {
  isValidEmail, isBlank, isValidZip, isFaxNumber, MAX_CLINICAL_NEED_LENGTH,
};

import React from 'react';
import PropTypes from 'prop-types';
import Message from 'models/Message';
import Session from 'models/Session';
import { createTranslator } from 'helpers/i18n';
import { trackEvent } from 'helpers/analytics';

import { LoadingPanel, Placeholder, Popup } from 'components/utils';
import { GRAY_FILL } from 'components/utils/Colors';
import { Secondary, PopupContent } from 'components/utils/Styles';

import MessageList from './MessageList';

const tr = createTranslator({
  error: 'Failed to load messages',
});

/**
 * Popup that displays in the MenuBar for opening
 * the MessageList
 */
class MessageListPopupPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = { open: false, loading: false, error: false };
  }

  handleClose = () => {
    this.setState({ open: false, loading: false, error: false });
  }

  handleOpen = () => {
    trackEvent('Site Header', 'Click', 'Messages Dropdown');
    this.setState({ open: true, loading: true, error: false }, () => {
      this.props.requestMessageList().then(() => {
        this.setState({ loading: false, error: false });
      }).catch(() => {
        this.setState({ loading: false, error: true });
      });
    });
  }

  renderMessageList = (close) => {
    const {
      messages,
      itemElement,
      openMessage,
      requestNewMessageThread,
      sendMessage,
      session,
    } = this.props;
    return (
      <MessageList
        close={close}
        onSendClick={requestNewMessageThread}
        messages={messages}
        sendMessage={sendMessage}
        onMessageClick={openMessage}
        session={session}
        itemElement={itemElement}
      />
    );
  };

  renderPopup = (close) => {
    if (this.state.loading) {
      return (
        <PopupContent>
          <LoadingPanel full color={GRAY_FILL} />
        </PopupContent>
      );
    } if (this.state.error) {
      return <Placeholder><Secondary>{tr('error')}</Secondary></Placeholder>;
    }
    return this.renderMessageList(close);
  }

  render() {
    return (
      <Popup
        trigger={this.props.trigger}
        open={this.state.open}
        onOpen={this.handleOpen}
        onClose={this.handleClose}
        offsetY={8}
        width={350}
        height={330}
        position="bottom right"
      >
        {({ close }) => this.renderPopup(close)}
      </Popup>
    );
  }
}

MessageListPopupPanel.propTypes = {
  /** List of messages to display */
  messages: PropTypes.arrayOf(Message).isRequired,
  /** Open conversation for a message */
  openMessage: PropTypes.func.isRequired,
  /** Request the message list */
  requestMessageList: PropTypes.func.isRequired,
  /** Request a new conversation thread */
  requestNewMessageThread: PropTypes.func.isRequired,
  /** Send a new message */
  sendMessage: PropTypes.func.isRequired,
  /** Session */
  session: Session.isRequired,
  /** Trigger component to open this popup */
  trigger: PropTypes.node.isRequired,
  /** How to render each item */
  itemElement: PropTypes.element,
};
MessageListPopupPanel.defaultProps = {
  itemElement: undefined,
};

export default MessageListPopupPanel;

/* eslint-disable react/no-unused-prop-types, react/jsx-props-no-spreading */

import React from 'react';
import PropTypes from 'prop-types';
import { css, cx } from 'emotion';
import styled from 'react-emotion';
import HOC from 'components/utils/Field';
import { ieOnly, ieEq } from 'components/utils/IE';
import ViewContext from 'components/utils/ViewContext';
import Panel from 'components/utils/Panel';
import Help from 'components/utils/Help';
import withNoClick from 'components/utils/withNoClick';
import withComponentTheme from 'components/utils/withComponentTheme';
import { ThemeContext } from 'components/utils/ThemeContext';

import { applyTestAttribute } from 'helpers/development';

const LabelInfo = withNoClick(Help);

const DEFAULT_CHECKBOX_SIZE = '18px';
const LARGE_CHECKBOX_SIZE = '22px';
const LARGE_SIZE = '16px';
const SMALL_SIZE = '13px';

const baseCheckmarkStyle = (colors) => (large) => (css`
        font-family: "Modern Pictograms";
        position: absolute;
        top: 2px;
        left: 0;
        height: ${large ? LARGE_CHECKBOX_SIZE : LARGE_SIZE};
        width: ${large ? LARGE_CHECKBOX_SIZE : LARGE_SIZE};
        background-color: ${colors.WHITE};
        border: solid 1px ${colors.CHECKBOX_UNCHECKED_BORDER};
        border-radius: 5px;
        transform: ${large ? 'translate(-2px, -4px)' : 'none'};
        &:after {
          line-height: 1.4;
          ${ieOnly('line-height: 0.8;')}
          font-size: ${large ? LARGE_SIZE : SMALL_SIZE};
          color: ${colors.WHITE};
          content: "%";
          position: absolute;
          display: none;
          font-style: italic;
          font-weight: normal;
          left: ${large ? '3px' : '1px'};
          top: ${large ? '2px' : '1px'};
        }
        &:before{
          line-height: 1.4;
          ${ieOnly('line-height: 0.8;')}
          font-size: ${large ? LARGE_SIZE : SMALL_SIZE};
          color: ${colors.CHECKBOX_UNCHECKED_BORDER};
          content: "%";
          position: absolute;
          font-style: italic;
          font-weight: normal;
          left: ${large ? '3px' : '1px'};
          top: ${large ? '2px' : '1px'};
        }`);

const indeterminateStyle = (colors, fonts) => (large) => (css`
        font-family: ${fonts.BODY_TEXT};
        display: block;
        overflow: hidden;
        background-color: ${colors.CHECKBOX_INDETERMINATE};
        &:before {
          display: none;
        }
        &:after {
          color: ${colors.WHITE};
          font-size: ${large ? '36px' : '26px'};
          content: "-";
          display: block;
          left: ${large ? '4px' : '3px'};
          top: ${large ? `-${LARGE_SIZE}` : '-12px'};
        }
      `);

const createComponentStyles = ({ theme: { colors, fonts } }) => ({
  v1: {
    styles: {
      baseCheckmarkStyle: css`
        font-family: "Modern Pictograms";
        position: absolute;
        top: 2px;
        left: 0;
        height: ${DEFAULT_CHECKBOX_SIZE};
        width: ${DEFAULT_CHECKBOX_SIZE};
        background-color: ${colors.WHITE};
        border-radius: 3px;
        box-shadow: inset 0 0.5px 3px 0 rgba(102, 102, 102, 0.57);
        &:after {
          line-height: 1.4;
          ${ieOnly('line-height: 0.8;')}
          font-size: ${LARGE_SIZE};
          color: ${colors.CHECKBOX_CHECKED};
          content: "%";
          position: absolute;
          display: none;
          left: 2px;
          top: 0px;
        }`,
      checkMarkCheckedStyle: css`
          font-family: "Modern Pictograms";
          display: block;
          &:after {
            display: block;
        }`,
      checkboxStyle: css`
        position: absolute;
        left: 0;
        top: 2px;
        `,
      indeterminateStyle: css`
        font-family: ${fonts.BODY_TEXT};
        display: block;
        overflow: hidden;
        &:after {
          font-size: 29px;
          content: "-";
          display: block;
          left: 3px;
          top: -13px;
        }
      `,
      disabledStyle: css`
        background-color: ${colors.CHECKBOX_DISABLED};
        cursor: not-allowed;
      `,
      LABEL_MARGIN_LEFT: '30px',
    },
  },
  v2: {
    styles: {
      baseCheckmarkStyle: baseCheckmarkStyle(colors),
      checkMarkCheckedStyle: css`
        font-family: "Modern Pictograms";
        display: block;
        background-color: ${colors.CHECKBOX_CHECKED};
        border: solid 1px ${colors.CHECKBOX_CHECKED};
        &:after {
          display: block;
        }`,
      checkboxStyle: css`
        position: absolute;
        left: 0;
        top: 2px;
        `,
      indeterminateStyle: indeterminateStyle(colors, fonts),
      LABEL_MARGIN_LEFT: '30px',
      disabledStyle: css`
        background-color: ${colors.CHECKBOX_DISABLED};
        cursor: not-allowed;
      `,
    },
  },
});

const CheckboxContainer = styled.label`
  display: inline-flex;
  cursor: pointer;
  align-items: flex-start;
  ${ieEq(11, 'align-items: stretch;')}
  line-height: 1.6;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  &:input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
`;

export const CheckboxLabel = styled.div`
  display: inline-block;
  margin-left: ${props => (props.readOnly ? '0px' : props.theme.styles.LABEL_MARGIN_LEFT)};
`;

/**
 * A checkbox field with styles and a change listener
 */
class CheckboxField extends React.Component {
  static contextType = ThemeContext;

  constructor(props) {
    super(props);
    this.state = { checked: !!props.value };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.state.checked) {
      this.setState({ checked: !!nextProps.value });
    }
  }

  handleChange = (e) => {
    this.setState({
      checked: e.target.checked,
    }, () => {
      if (this.props.onChange) {
        this.props.onChange(this.state.checked);
      }
    });
  }

  render() {
    const {
      theme: {
        styles: {
          baseCheckmarkStyle: checkmarkStyle,
          checkMarkCheckedStyle,
          checkboxStyle,
          indeterminateStyle: dashStyle,
          disabledStyle,
        },
      },
    } = this.context;
    const classes = (typeof checkmarkStyle === 'function') ? [checkmarkStyle(this.props.large)] : [checkmarkStyle];
    if (this.state.checked) {
      classes.push(checkMarkCheckedStyle);
    } else if (this.props.indeterminate) {
      classes.push(typeof dashStyle === 'function' ? [dashStyle(this.props.large)] : [dashStyle]);
    }
    if (this.props.disabled) {
      classes.push(disabledStyle);
    }

    /*
    *  TODO: this noClick behavior should be automatic or at least
    *  opt-out. The NoClick component can't be used on checkboxes, which
    *  are absolutely positioned.
    */
    const { noClick } = this.props;
    const noClickProps = noClick
      ? {
        onClick: (e) => {
          e.stopPropagation();
        },
      }
      : {};
    const LabelEl = this.props.labelElement || React.Fragment;

    return (
      <CheckboxContainer
        htmlFor={this.props.id || this.props.name || undefined}
        {...applyTestAttribute(this.props)}
        {...noClickProps}
      >
        <ViewContext.Consumer>
          {({ readOnly }) => (
            <>
              {!readOnly && (
                <>
                  <input
                    key={['key', this.props.id, this.props.name, Boolean(this.state.checked)].join('_')}
                    className={checkboxStyle}
                    disabled={this.props.disabled}
                    type="checkbox"
                    name={this.props.name}
                    id={this.props.id || this.props.name}
                    checked={this.state.checked}
                    onChange={this.props.disabled ? undefined : this.handleChange}
                  />
                  <span className={cx(...classes)} />
                </>
              )}
              {this.props.label
                && (
                  <CheckboxLabel
                    className={cx(this.props.labelClassName)}
                    readOnly={readOnly}
                  >
                    <Panel orientation="horizontal" spacing={8}>
                      <LabelEl>{this.props.label}</LabelEl>
                      {this.props.labelInfo && (
                        <LabelInfo value={!readOnly && this.props.labelInfo} />
                      )}
                    </Panel>
                  </CheckboxLabel>
                )}
            </>
          )}
        </ViewContext.Consumer>
      </CheckboxContainer>
    );
  }
}

CheckboxField.propTypes = {
  /** Optional classname for container */
  className: PropTypes.string, // eslint-disable-line react/no-unused-prop-types
  /** The HTML id for the input */
  id: PropTypes.string,
  /** True to disable, false otherwise (default false) */
  disabled: PropTypes.bool,
  /** The label text (or node) */
  label: PropTypes.node,
  /** The label class name, optional */
  labelClassName: PropTypes.string,
  /** The label element, optional (uses Fragment by default) */
  labelElement: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.func]),
  /** Show information icon with the given text on hover if labels are enabled */
  labelInfo: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  /** The HTML name for the input */
  name: PropTypes.string,
  /** Handle change events. Passes bool of checked state */
  onChange: PropTypes.func,
  /** Initial checked value */
  value: PropTypes.bool,
  /** Set third indeterminate state */
  indeterminate: PropTypes.bool,
  large: PropTypes.bool,
};

CheckboxField.defaultProps = {
  className: undefined,
  large: false,
  labelClassName: undefined,
  labelElement: undefined,
  id: undefined,
  disabled: false,
  label: null,
  labelInfo: undefined,
  name: undefined,
  onChange: undefined,
  value: false,
  indeterminate: undefined,
};

export default withComponentTheme(createComponentStyles)(HOC(CheckboxField, { noLabel: true }));

/* eslint-disable import/prefer-default-export */

import apiDirector from 'helpers/ApiDirector';
import * as actions from './actions';

const MAX_ATTEMPTS = 3;

const fetchSystem = (resolve, reject, attempt = 0) => apiDirector.validateFetch(`/api/system${attempt ? `?at=${new Date().getTime()}` : ''}`, {
  cache: attempt ? 'no-store' : undefined,
})
  .then((json) => {
    if (!json) throw new Error('Failed to load required system data; refresh and try again, or try another browser.');
    return json;
  })
  .then(resolve)
  .catch((e) => {
    if (attempt < MAX_ATTEMPTS) {
      setTimeout(() => {
        fetchSystem(resolve, reject, attempt + 1);
      }, 1000);
    } else {
      reject(e);
    }
  });

export const requestSystem = () => dispatch => new Promise(fetchSystem)
  .then(json => dispatch(actions.replaceSystem(json)))
  .catch((e) => {
    console.error('Fetching system data failed; not good'); // eslint-disable-line
    console.error(e); // eslint-disable-line
    throw e;
  });

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'react-emotion';

import * as actions from 'ducks/session';
import { createTranslator, numeric } from 'helpers/i18n';
import { isValidEmail } from 'helpers/validators';
import GlobalModal, { modals } from 'helpers/GlobalModal';

import {
  Button,
  ButtonBar,
  ContentPanel,
  Fragment,
  Link,
  Panel,
  TextField,
  Tip,
} from 'components/utils';
import { mq } from 'components/utils/Responsive';
import withThemedComponents, { ThemeConfig } from 'components/utils/withThemedComponents';

import {
  PrimaryColumn,
  ResponsiveGrid,
  ContentPanelPageTitle,
  primaryButtonStyle,
  textInputHeightV2,
} from 'components/login/LoginStyles';

const { TOGGLE_PASSWORD_CONFIRMATION_MODAL } = modals;

const tr = createTranslator({
  title: 'Forgot your password?',
  email: {
    label: 'Email',
  },
  submit: 'Send me reset password instructions',
  back: version => numeric(version, {
    v2: 'Return to Log In',
    other: 'Log in',
  }),
  success: 'You will receive an email with instructions on how to reset your password in a few minutes.',
  arrowBack: 'Back',
  errorMessage: 'Please enter a valid email address',
});

const ButtonContainer = styled.div(mq({
  display: 'flex',
  flexDirection: ['row', 'column-reverse', 'column-reverse'],
  width: ['auto', '100%', '100%'],
  marginTop: '10px',
  textAlign: 'center',
  alignItems: 'center',
  justifyContent: 'space-between',
  a: {
    marginTop: ['0px', '20px', '20px'],
  },
}));

const FormContainerV2 = ({ children }) => (
  <ResponsiveGrid
    spacing={10}
  >
    <PrimaryColumn>
      {children}
    </PrimaryColumn>
  </ResponsiveGrid>
);
FormContainerV2.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
};

const createComponentStyles = ({ version, theme: { colors } }) => numeric(version, {
  other: {
    version,
    styles: {
      FormContainer: Fragment,
      FormContent: ContentPanel,
      submitButtonColor: colors.SENDING_DARKER_FILL,
      SubmitButtonContainer: ButtonBar,
    },
    options: {
      submitButtonPosition: 'top',
      showInputLabelIndicator: true,
      showInputRequiredIndicator: true,
    },
  },
  v2: {
    version,
    styles: {
      forgotButtonStyle: primaryButtonStyle,
      FormContainer: FormContainerV2,
      FormContent: Panel,
      submitButtonColor: colors.SENDING_DARKER_FILL,
      SubmitButtonContainer: ButtonContainer,
      SecondaryPageTitle: ContentPanelPageTitle,
      textInputHeight: textInputHeightV2,
    },
    options: {
      submitButtonPosition: 'bottom',
      showInputLabelIndicator: false,
      showInputRequiredIndicator: true,
    },
  },
});

class ForgotPasswordForm extends React.Component {
  static propTypes = {
    resetPassword: PropTypes.func.isRequired,
    theme: ThemeConfig.isRequired,
  }

  constructor(props) {
    super(props);
    this.state = { email: '', message: null, errors: { email: null } };
  }

  componentDidMount() {
    this.input.getField().focus();
  }

  handleSubmit = () => {
    const emailValid = isValidEmail(this.state.email);
    if (!emailValid) {
      this.setState({ errors: { email: [tr('errorMessage')] } });
    } else {
      this.setState({ errors: { email: null } }, () => {
        this.props.resetPassword(this.state.email)
          .then(() => {
            this.setState({ message: tr('success') });
            GlobalModal.open(TOGGLE_PASSWORD_CONFIRMATION_MODAL);
          })
          .catch((e) => {
            if (e.response && e.response.errors) {
              this.setState({ errors: e.response.errors });
            }
          });
      });
    }
  }

  handleInput = (email) => {
    this.setState({ email });
  }

  renderButtonBar = () => {
    const {
      styles: {
        forgotButtonStyle,
        submitButtonColor,
        SubmitButtonContainer,
      },
      options: {
        submitButtonPosition,
      },
      version,
    } = this.props.theme;
    const renderSubmitButton = (position) => position === submitButtonPosition && (
      <Button
        type="submit"
        small
        color={submitButtonColor}
        className={forgotButtonStyle}
        disabled={!this.state.email}
      >
          {tr('submit')}
      </Button>
    );
    return (
      <SubmitButtonContainer>
        {renderSubmitButton('top')}
        <Link to="/devise_users/sign_in">
          <Button small link>{tr('back', version)}</Button>
        </Link>
        {renderSubmitButton('bottom')}
      </SubmitButtonContainer>
    );
  }

  renderContentForm = () => {
    const {
      styles: {
        FormContent,
        SecondaryPageTitle,
        textInputHeight,
      },
      options: {
        showInputLabelIndicator,
        showInputRequiredIndicator,
      },
      version,
    } = this.props.theme;
    return (
      <FormContent title={tr('title', version)}>
        {SecondaryPageTitle && <SecondaryPageTitle>{tr('title', version)}</SecondaryPageTitle>}
        <form onSubmit={(e) => { e.preventDefault(); this.handleSubmit(); }}>
          <Panel>
            {this.state.message && (
              <Tip>{this.state.message}</Tip>
            )}
            <TextField
              ref={(ref) => { this.input = ref; }}
              required={showInputRequiredIndicator}
              labelIndicator={showInputLabelIndicator ? undefined : ' '}
              height={textInputHeight}
              label={tr('email.label')}
              errors={this.state.errors.email}
              value={this.state.email}
              onChange={this.handleInput}
            />
            {this.renderButtonBar()}
          </Panel>
        </form>
      </FormContent>
    );
  }

  render() {
    const {
      styles: {
        FormContainer,
      },
    } = this.props.theme;

    return (
      <FormContainer>
        {this.renderContentForm()}
      </FormContainer>
    );
  }
}

export default connect(null, actions)(
  withThemedComponents(createComponentStyles)(ForgotPasswordForm),
);

import React from 'react';
import PropTypes from 'prop-types';
import { createTranslator } from 'helpers/i18n';

import Panel from 'components/utils/Panel';
import { LinkText } from 'components/utils/Styles';

const tr = createTranslator({
  expand: 'Show more',
});

/**
 * A generic list of items that can be displayed in a Panel and expanded
 * to show addiitonal items on button click.
 *
 * By default, it will show a list of text with "Show more" LinkText at
 * the bottom of the list.
 */
export default class ExpandablePanel extends React.Component {
  static propTypes = {
    /**
     * The trigger component for showing more items. Will be cloned and passed
     * onClick. Ignores expandContainer, expandElement and expandText.
     */
    expandComponent: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
    /**
     * The trigger component for showing more items. Will be cloned and passed
     * onClick and expandText as children. Ignores expandComponent and expandElement.
     */
    expandContainer: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
    /** The trigger element for showing more items. Must accept onClick. */
    expandElement: PropTypes.oneOfType([PropTypes.node, PropTypes.func, PropTypes.string]),
    /** The text within the trigger element. Could also be a component. */
    expandText: PropTypes.oneOfType([PropTypes.node, PropTypes.func, PropTypes.string]),
    /**
     * The list of items to display. Passing shapes with label/value will just
     * do the right thing. Or use an itemRenderer to display how you want.
     */
    items: PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.node, PropTypes.func, PropTypes.string, PropTypes.shape({})]),
    ),
    /**
     * The element to wrap each item in. Uses div by default. Ignore if an itemRenderer
     * is used.
     */
    itemElement: PropTypes.oneOfType([PropTypes.node, PropTypes.func, PropTypes.string]),
    /**
     * Custom item renderer for each item. Causes itemElement to be ignored.
     */
    itemRenderer: PropTypes.func,
    /**
     * The element wrapping the entire list. Default to Panel. Will be passed a `spacing`
     * option to denote the amount of space that should be between each item.
     */
    listElement: PropTypes.oneOfType([PropTypes.node, PropTypes.func, PropTypes.string]),
    /** The initial size of the list when not expanded. */
    size: PropTypes.number,
    /** The spacing between each list item */
    spacing: PropTypes.number,
  }

  static defaultProps = {
    expandComponent: undefined,
    expandContainer: undefined,
    expandElement: undefined,
    expandText: tr('expand'),
    items: [],
    itemElement: 'div',
    itemRenderer: undefined,
    listElement: undefined,
    size: 10,
    spacing: 20,
  }

  constructor(props) {
    super(props);
    this.state = { expanded: false };
  }

  handleExpand = () => {
    this.setState({ expanded: true });
  }

  renderExpandComponent = () => {
    const {
      expandComponent, expandContainer, expandElement, expandText,
    } = this.props;
    const content = expandText || tr('expand');
    if (expandComponent) {
      return React.cloneElement(expandComponent, { onClick: this.handleExpand });
    }
    if (expandContainer) {
      return React.cloneElement(expandContainer, { onClick: this.handleExpand, children: content });
    }
    const Element = expandElement || LinkText;
    return (
      <Element onClick={() => { this.handleExpand(); }}>
        {content}
      </Element>
    );
  }

  renderItem = (item, index) => {
    const Element = this.props.itemElement || ExpandablePanel.defaultProps.itemElement;
    return (
      <Element key={item.key || item.value || item.id || index}>
        {item.label || item}
      </Element>
    );
  }

  render() {
    const {
      items, itemRenderer = this.renderItem, listElement, size, spacing,
    } = this.props;
    const { expanded } = this.state;

    const list = expanded ? items : items.slice(0, size);
    const ListElement = listElement || Panel;

    return (
      <Panel spacing={spacing}>
        <ListElement spacing={spacing}>
          {list.map(itemRenderer)}
        </ListElement>
        {!expanded && items.length > size && this.renderExpandComponent()}
      </Panel>
    );
  }
}

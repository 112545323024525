import React from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { css } from 'emotion';

import {
  mq,
} from 'components/utils/Responsive';
import withThemedComponents, { ThemeConfig } from 'components/utils/withThemedComponents';
import {
  Panel,
  Divider,
  SplitPanel,
  Fragment,
  QueryContext,
} from 'components/utils';

import { numeric } from 'helpers/i18n';
import { isValidEmail } from 'helpers/validators';

import FriendlyLoginImage from './FriendlyLoginImage';
import LoginForm from './LoginForm';
import CreateAccountForm from './CreateAccountForm';
import RegisterAccountForm, { SUPPORT_KEY } from './RegisterAccountForm';
import ForgotPasswordForm from './ForgotPasswordForm';
import ResendConfirmationForm from './ResendConfirmationForm';
import ResetPasswordForm from './ResetPasswordForm';
import AidinLogoContainer from './AidinLogoContainer';
import AccountFAQPanel from './AccountFAQPanel';
import NewUserProviderLookupModal from './NewUserProviderLookupModal';
import { ResponsiveGrid } from './LoginStyles';
import NavigateToSignInLink from './NavigateToSignInLink';
import TimedOutModal from './TimedOutModal';

const createComponentStyles = ({ version }) => numeric(version, {
  other: {
    styles: {
      Container: styled.div(mq({
        width: '100%',
        margin: ['0px auto', '0px', '0px'],
      })),
      MainContainer: Panel,
      ContentContainer: Panel,
      TopContainer: Fragment,
      MainContent: Fragment,
      PageDivider: Divider,
      mainContainerSpacing: 40,
      contentContainerSpacing: 10,
      gridSpacing: 10,
    },
    options: {
      showLogo: true,
      showPageDivider: true,
    },
  },
  v2: {
    styles: {
      Container: styled.div({
        width: '100%',
      }),
      MainContainer: Fragment,
      MainContent: styled.div(({
        marginTop: '50px',
      })),
      ContentContainer: Fragment,
      TopContainer: SplitPanel,
      contentStyle: css(mq({
        width: '100%',
        margin: ['0px auto', '0px', '0px'],
        '> div': {
          position: 'relative',
        },
        '> .split-panel-item-0': {
          maxWidth: ['40%', '100%', '100%'],
          flexBasis: ['40%', '100%', '100%'],
        },
        '> .split-panel-item-1': {
          maxWidth: ['50%', '100%', '100%'],
          flexBasis: ['50%', '100%', '100%'],
        },
      })),
      contentContainerSpacing: 0,
      PageDivider: styled.div(mq({
        borderTop: '1px solid #DFDFE2',
        marginBottom: ['90px', '40px', '40px'],
        marginTop: ['-1px', '40px', '40px'],
      })),
    },
    options: {
      showLoginImages: true,
      showPageDivider: true,
    },
  },
});

const LoginFormContainer = () => (
  <QueryContext.Consumer>
    {({
      support, token, redirectUrl, user: { email: username } = {},
    }) => ((support === SUPPORT_KEY)
      ? (<Redirect to={`/devise_users/register${token ? `?token=${token}` : ''}`} />)
      : (<LoginForm username={isValidEmail(username) ? username : null} loginUrl={redirectUrl} />))}
  </QueryContext.Consumer>
);

const LoginPage = ({
  size,
  theme: {
    styles: {
      Container,
      TopContainer,
      MainContainer,
      MainContent,
      ContentContainer,
      PageDivider,
      mainContainerSpacing,
      contentContainerSpacing,
      gridSpacing,
      contentStyle,
    } = {},
    options: {
      showLogo,
      showLoginImages,
    } = {},
  } = {},
}) => (
  <Container>
    <MainContainer spacing={mainContainerSpacing}>
      {showLogo && <AidinLogoContainer />}
      <ContentContainer spacing={contentContainerSpacing}>
        <TopContainer className={contentStyle} orientation="horizontal">
          <MainContent>
            <NavigateToSignInLink />
            <Switch>
              <Route path="/devise_users/sign_in" component={LoginFormContainer} />
              <Route path="/devise_users/sign_up" component={CreateAccountForm} />
              <Route path="/devise_users/register" component={RegisterAccountForm} />
              <Route path="/devise_users/password/new" component={ForgotPasswordForm} />
              <Route path="/devise_users/confirmations/new" component={ResendConfirmationForm} />
              <Route path="/devise_users/password/edit" component={ResetPasswordForm} />
              <Redirect push from="/" to="/devise_users/sign_in" />
            </Switch>
          </MainContent>
          {showLoginImages && size === 'large' && <FriendlyLoginImage />}
        </TopContainer>
        <PageDivider />
        <ResponsiveGrid spacing={gridSpacing} className={contentStyle}>
          <AccountFAQPanel />
        </ResponsiveGrid>
      </ContentContainer>
    </MainContainer>
    <Route
      path="/devise_users/sign_in"
      render={() => (
        <>
          <TimedOutModal />
          <NewUserProviderLookupModal />
        </>
      )}
    />
  </Container>
);
LoginPage.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']).isRequired,
  theme: ThemeConfig.isRequired,
};

export default compose(
  withThemedComponents(createComponentStyles),
  connect(({ browser }) => ({ size: browser.mediaType })),
)(LoginPage);

/* eslint-disable import/prefer-default-export, camelcase */
import { flatten, partition } from 'lodash';
import apiDirector from 'helpers/ApiDirector';
import { providerTypes } from 'translations/providers';
import { DateTime } from 'helpers/dates';
import { getDefaultDeadlineValue, errorToast } from 'helpers/ui';
import { createTranslator } from 'helpers/i18n';
import { getSearchSettings } from 'helpers/search';
import { mergeReferral, replaceReferralProviderSearchResults } from 'ducks/referrals/actions';
import {
  refreshReferral, shapeReferral, sendReferralToProviders, updateReferral, updateReferralStatus,
} from 'ducks/referrals/operations';
import { openSendToProvidersModal } from 'ducks/ui';
import { sortAndMapProviders } from 'components/utils/AidinScore';
import { addItemToLoadingQueue, removeItemFromLoadingQueue } from 'ducks/loadingQueue/operations';
import * as actions from './actions';

const DEPRECATED_REQUIRE_ACTIVE_TO_RECOMMEND_ENABLED = false;

const tr = createTranslator({
  features: {
    high_medicare_score: value => `${value}-Star Medicare Rating`,
  },
});

const filteredSearchResults = (params, results, referralId, getState) => {
  const { referral, session, system } = getState();
  const {
    level_of_care,
    hospital: {
      id,
      organization_type,
    },
    provider_search: {
      sort_order: sortOrder,
    },
  } = referral;
  const organization = session.organizations
    .find(org => org.id === id && org.organization_type === organization_type);
  const supportedProviderTypes = organization ? organization.provider_types : null;
  let systemProviderTypes = Object.entries(providerTypes)
    .map(([value, label]) => ({ label, value }));
  if (supportedProviderTypes !== null) {
    systemProviderTypes = systemProviderTypes.filter(({ value }) => (
      supportedProviderTypes.some(pt => pt.toUpperCase() === value)
    ));
  }
  return {
    levelOfCare: systemProviderTypes,
    clinicalNeeds: system.care_types.filter(({ provider_type }) => (
      level_of_care && level_of_care.indexOf(provider_type.toUpperCase()) >= 0)),
    results,
    sortOrder,
  };
};

const filterParamsToSearchEntity = (params, getState, options = {}) => {
  const { referral } = getState();
  const provider_search = {};
  if (params) {
    if (params.levelOfCare) {
      provider_search.provider_type = params.levelOfCare[0].toLowerCase();
    } else {
      provider_search.provider_type = null;
    }

    // TODO: Support multiple levels of care
    const levelOfCareData = params.levelOfCare && params.levelOfCare.length > 0
      ? [params.levelOfCare[0]] : [];
    if (levelOfCareData.length === 0 && options.manual && options.provider_type) {
      levelOfCareData.push(options.provider_type.toUpperCase());
    }

    if (params.clinicalNeeds) {
      const needs = params.clinicalNeeds.needs ? params.clinicalNeeds.needs
        .filter(need => levelOfCareData.indexOf(need.provider_type.toUpperCase()) >= 0) : [];
      provider_search.care_types_provider_searches_attributes = needs.map(need => ({
        care_type_id: need.id,
      }));
    } else {
      provider_search.care_types_provider_searches_attributes = [];
    }

    if (params.searchArea) {
      if (params.searchArea.zip) {
        provider_search.radius = params.searchArea.radius;
        provider_search.zip = params.searchArea.zip;
        provider_search.city_id = null;
        provider_search.county_id = null;
        provider_search.state_id = null;
      } else {
        provider_search.zip = null;
        provider_search.radius = null;
        provider_search.city_id = params.searchArea.city;
        provider_search.county_id = params.searchArea.county;
        provider_search.state_id = params.searchArea.state;
      }
    } else {
      const { radius, zip } = getSearchSettings({
        ...referral,
        level_of_care: levelOfCareData,
      });
      provider_search.radius = radius;
      provider_search.zip = zip;
      provider_search.city_id = null;
      provider_search.county_id = null;
      provider_search.state_id = null;
    }

    if (params.insurance) {
      if (params.insurance.insurances) {
        provider_search.insurances_provider_searches_attributes = (
          params.insurance.insurances.map(i => ({
            insurance_id: i.id,
          }))
        );
      } else {
        provider_search.insurances_provider_searches_attributes = [];
      }
      if (params.insurance.network) {
        provider_search.provider_network_id = params.insurance.network.id;
      } else {
        provider_search.provider_network_id = null;
      }
      if (params.insurance.non_network !== undefined) {
        provider_search.non_network = params.insurance.non_network;
      }
    } else {
      provider_search.insurances_provider_searches_attributes = [];
      provider_search.provider_network_id = null;
      provider_search.non_network = false;
    }

    if (params.states) {
      provider_search.provider_search_states_attributes = params.states.map(({ id }) => ({
        state_id: id,
      }));
    }
  }

  return { provider_search };
};

const filterParamsToReferralEntity = (params, getState, options) => {
  const referral = {};
  if (params) {
    if (params.levelOfCare) {
      referral.provider_type = params.levelOfCare[0].toLowerCase();
    } else {
      referral.provider_type = null;
    }

    // TODO: Support multiple levels of care
    const levelOfCareData = params.levelOfCare && params.levelOfCare.length > 0
      ? [params.levelOfCare[0]] : [];
    if (levelOfCareData.length === 0 && options.manual && options.provider_type) {
      levelOfCareData.push(options.provider_type.toUpperCase());
    }

    if (params.clinicalNeeds) {
      const needs = params.clinicalNeeds.needs ? params.clinicalNeeds.needs
        .filter(need => levelOfCareData.indexOf(need.provider_type.toUpperCase()) >= 0) : [];
      referral.referral_care_types_attributes = needs.map(need => ({
        care_type_id: need.id,
      }));

      referral.other_care_type = params.clinicalNeeds.other;
    } else {
      referral.other_care_type = null;
    }

    if (params.dischargeDate) {
      referral.discharge_date = params.dischargeDate.dischargeDate;
      referral.start_of_care = params.dischargeDate.startOfCare;
    } else {
      referral.discharge_date = null;
      referral.start_of_care = null;
    }

    if (params.hospitalAnticipatedDischargeDate) {
      referral.hospital_anticipated_discharge_date = params
        .hospitalAnticipatedDischargeDate.dischargeDate;
    }

    if (params.deadline) {
      referral.time_window_closes_at = params.deadline;
    } else {
      referral.time_window_closes_at = (
        DateTime.utc(getDefaultDeadlineValue(
          null,
          levelOfCareData,
          [],
          { defaultDeadlineSource: referral },
        ).time)
      );
    }

    if (params.searchArea) {
      if (params.searchArea.zip) {
        referral.search_radius = params.searchArea.radius;
        referral.zip = params.searchArea.zip;
        referral.city = null;
        referral.state = null;
      } else {
        referral.zip = null;
        referral.search_radius = null;
        referral.city = params.searchArea.city;
        referral.state = params.searchArea.state;
      }
    } else {
      const { radius, zip } = getSearchSettings({
        ...getState().referral,
        ...referral,
        level_of_care: levelOfCareData,
      });
      referral.search_radius = radius;
      referral.zip = zip;
      referral.city = null;
      referral.state = null;
    }

    if (params.resumingCare !== undefined) {
      referral.resuming_care = params.resumingCare;
    }
  }

  return { referral };
};

export const scoreFeatures = (getState, filters, search, provider_search_results = []) => {
  const { badges } = getState().system;
  const superlatives = badges.filter(b => b.feature === 'superlative').map(b => b.key);
  const highlights = badges.filter(b => b.feature === 'highlighted').map(b => b.key);
  const superlativeAssigned = {};

  const {
    non_network,
    provider_network,
  } = search;

  const {
    referral: {
      sent_referral_index: sent = [],
    } = {},
  } = getState();

  const removed = sent.filter(({ confirmed, removed_at }) => !confirmed && removed_at);

  return provider_search_results.map((result) => {
    let features = [];
    let isRemoved = false;
    const awarded = result.awarded_badges.filter(({ state }) => state === 'active');
    // Superlatives
    features = features.concat(awarded.filter(({ key }) => {
      const use = !superlativeAssigned[key] && superlatives.some(b => b === key);
      if (use) { superlativeAssigned[key] = true; }
      return use;
    })).map(({ key }) => key);

    // Highlights
    features = features.concat(awarded
      .filter(({ key }) => highlights.some(b => b === key))
      .map(({ key, details }) => {
        if (key === 'network_member') {
          if (!non_network && provider_network) {
            const featureBadge = { key, label: provider_network.name };
            if (result.recommended) {
              return featureBadge;
            }
            return [featureBadge, 'non_matching_network_member'];
          }
          return [];
        }
        if (key === 'high_medicare_score' && details) {
          const [score] = details.split('/');
          return { key, label: tr(`features.${key}`, score.trim().split('.0')[0]) };
        }
        return key;
      }));

    // Manually added
    if (result.manual) {
      features = features.concat('manually_added');
    }

    if (result.excluded) {
      features = features.concat('excluded');
    }

    if (removed.some(({ provider_id }) => result.id === provider_id)) {
      features.push('removed');
      isRemoved = true;
    }

    return {
      ...result,
      removed: isRemoved,
      features: flatten(features),
    };
  });
};

const shapeSearchResults = ({
  active_providers = [],
  provider_search_results = [],
}) => provider_search_results.map(({
  awarded_badges = [],
  checked,
  excluded,
  manual,
  new_manual,
  provider,
  recommended,
  score,
}) => ({
  ...provider,
  awarded_badges,
  checked,
  excluded,
  manual,
  new_manual,
  recommended,
  score,
  provider_id: provider.id,
  active: DEPRECATED_REQUIRE_ACTIVE_TO_RECOMMEND_ENABLED
    ? active_providers.some(id => id === provider.id)
    : true,
}));

export const listProviderSearchResults = referralId => () => apiDirector.validateFetch(`/api/referrals/${referralId}/providers`)
  .then(({ results, sent_referrals }) => ({
    results: shapeSearchResults(results),
    search: results,
    sent_referrals,
  }));

export const handleSaveFaxNumber = (providerId, fax) => dispatch => apiDirector.fetch(`/api/providers/${providerId}/change_fax`, {
  method: 'PATCH',
  body: JSON.stringify({
    provider: { fax },
  }),
})
  .then(dispatch(actions.updateProviderFaxNumber(providerId, fax)));

export const requestSimulatedProviderSearch = (referralId, queries) => () => apiDirector.validateFetch(`/api/referrals/${referralId}/related-providers`, {
  method: 'POST',
  body: JSON.stringify({ queries }),
});

export const requestProviderSearch = (referralId, filters, options = {
  save: true,
  rescore: false,
  incompatible: false,
  resetSearch: false,
  autoSelect: undefined,
}) => (dispatch, getState) => Promise.resolve(options.save
  ? true
  : dispatch(actions.resetProviderSearch()))
  .then(() => apiDirector.validateFetch(`/api/referrals/${referralId}/providers`, {
    method: 'POST',
    body: JSON.stringify({
      rescore: options.rescore,
      incompatible: options.incompatible,
      autoSelect: options.autoSelect,
      resetSearch: options.resetSearch,
      keep_sent_referrals: options.keep_sent_referrals,
      ...filterParamsToReferralEntity(filters, getState, options),
      ...filterParamsToSearchEntity(filters, getState, options),
    }),
  }))
  .then(({ referral, results, warnings = {} }) => {
    dispatch(actions.replaceProviderSearchWarnings(warnings));
    dispatch(mergeReferral(shapeReferral(referral, getState)));
    return results;
  })
  .then(results => scoreFeatures(getState, filters, results, shapeSearchResults(results)))
  .then(results => filteredSearchResults(filters, results, referralId, getState))
  .then(results => dispatch(actions.replaceProviderSearchResults(results)))
  .then(() => dispatch(removeItemFromLoadingQueue(`provider-search-referral-${referralId}`)));

/**
 * Rows should be an array with object like sent_referrals:
 *
 * { provider_id: 123, referral_id: 123, checked: t/f }
 *
 * Options:
 * - resuming - set to true to clear other providers and mark this one as selected
 * - manual - set to true to mark given rows as manually added
 */
export const updateProviderSearchSelection = (id, rows, options = {}, data) => (dispatch, getState) => apiDirector.validateFetch(`/api/referrals/${id}/providers`, {
  method: 'PATCH',
  body: JSON.stringify({
    ...options,
    ...filterParamsToSearchEntity(data, getState, options),
    referral: {
      ...filterParamsToReferralEntity(data, getState, options).referral,
      provider_search_attributes: {
        provider_search_results_attributes: rows,
      },
    },
  }),
})
  .then((json) => { if (!json.results) { throw new Error('PerformClientUpdate'); } return json; })
  .then(({ results, filters }) => (
    scoreFeatures(getState, filters, results, shapeSearchResults(results))))
  .then(results => dispatch(actions.replaceProviderSearchResults({ results })))
  .then(({ results }) => dispatch(replaceReferralProviderSearchResults({ id, results })))
  .then(() => dispatch(refreshReferral(id, getState().referral.updated_at)))
  .catch((e) => {
    if (e.message === 'PerformClientUpdate') {
      dispatch(actions.syncProviderSearchSelection(rows))
        .then(results => dispatch(replaceReferralProviderSearchResults({
          id, results: results.rows,
        })))
        .then(() => dispatch(refreshReferral(id)));
    } else {
      throw e;
    }
  });

export const updateProviderSearch = (referralId, providerSearch) => (dispatch, getState) => apiDirector.validateFetch(`/api/referrals/${referralId}/provider_search`, {
  method: 'PATCH',
  body: JSON.stringify({
    provider_search: providerSearch,
  }),
}).then(({
  results,
  results: {
    sort_order: sortOrder,
  },
}) => ({
  results: scoreFeatures(getState, undefined, results, shapeSearchResults(results)),
  sortOrder,
}))
  .then(results => dispatch(actions.replaceProviderSearchResults(results)));

export const loadSearchResultDetails = (referralId, providerIds) => () => apiDirector.validateFetch(`/api/referrals/${referralId}/search_result_details`, {
  method: 'POST',
  body: JSON.stringify({ providers: providerIds }),
});

export const sendToProviders = (referral, providerIds, options, onSuccess, onError) => (dispatch) => {
  const { deadline, practice } = options || {};

  let suggestedDeadline = deadline || getDefaultDeadlineValue(
    null,
    referral.level_of_care,
    [],
    { defaultDeadlineSource: referral },
  ).time;
  if (suggestedDeadline && typeof suggestedDeadline !== 'string') {
    suggestedDeadline = DateTime.utc(suggestedDeadline);
  }

  const providerSearchButtonLoadingKey = `provider-search-referral-${referral.id}`;

  // TODO: Batching would be better
  const executeLoadingQueuePromiseChain = (action) => {
    const loadingKeys = [
      providerSearchButtonLoadingKey,
      `referral-steps-card-${referral.id}`,
    ];
    return loadingKeys.reduce((prom, key) => prom.then(() => (
      dispatch(action(key))
    )), Promise.resolve());
  };

  const startLoading = () => executeLoadingQueuePromiseChain(addItemToLoadingQueue);
  const stopLoading = () => executeLoadingQueuePromiseChain(removeItemFromLoadingQueue);

  const execute = () => startLoading()
    .then(() => dispatch(sendReferralToProviders(
      referral.id, providerIds, suggestedDeadline, practice,
      { onSuccess: () => stopLoading().then(onSuccess), onCancel: stopLoading },
    )).catch((e) => {
      if (e.message) {
        errorToast(e.message);
        stopLoading();
      }
      onError(e);
    }));

  if (referral.discharge_date && referral.time_window_closes_at
    && referral.reservationStatus === 'awaiting_provider_responses') {
    // If values set AND you are sending to additional providers, skip the modal
    execute();
  } else {
    // Otherwise, use the modal to verify or set values
    const onConfirm = (changes) => {
      dispatch(
        referral.isUnsent()
          ? updateReferral(referral.id, changes)
          : updateReferralStatus(referral.id, { ...changes, status: changes.status || 'awaiting_provider_responses' }),
      ).then(execute);
    };

    const onClose = () => stopLoading().then(() => onError());

    const filters = {
      levelOfCare: {
        data: referral.level_of_care,
        status: 'set',
      },
      dischargeDate: {
        data: referral.discharge_date ? {
          dischargeDate: referral.discharge_date,
          startOfCare: referral.start_of_care,
        } : {
          dischargeDate: null,
          startOfCare: null,
        },
        status: referral.discharge_date ? 'set' : 'unset',
      },
      deadline: {
        data: referral.status === 'awaiting_provider_responses' ? (referral.time_window_closes_at || suggestedDeadline) : suggestedDeadline,
        status: referral.time_window_closes_at ? 'set' : 'preset',
      },
    };
    // For inpatient visits, allow setting a hospital discharge date
    if (referral.isInpatient()) {
      filters.hospitalAnticipatedDischargeDate = {
        data: {
          dischargeDate: referral.hospital_anticipated_discharge_date,
        },
        status: referral.hospital_anticipated_discharge_date ? 'set' : 'unset',
      };
    }

    startLoading()
      .then(() => dispatch(openSendToProvidersModal(referral, filters,
        providerIds.length, onConfirm, onClose)))
      .catch(stopLoading);
  }
};

const INITIAL_PAGE_SIZE = 15;

export const getSearchedProvidersByType = (index = INITIAL_PAGE_SIZE, showNonMatchingNetwork) => (dispatch, getState) => {
  const { referral, providerSearch: { results = {}, sortOrder } } = getState();
  const isUnsent = () => referral.isUnsent();
  const sent_referrals = (referral.sent_referral_index || []);
  const confirmed = sent_referrals.filter(sr => sr.confirmed);
  const sentProviders = isUnsent() ? [] : results
    .filter(p => confirmed.some(sr => sr.provider_id === p.id))
    .map(p => ({
      ...p,
      ...confirmed.filter(sr => sr.provider_id === p.id)
        .map(sr => ({ sent_referral_id: sr.id }))[0],
    }));

  const sortedProviders = sortAndMapProviders(results || [], { presorted: true });

  const networkFilter = p => (
    p.features.some(({ key }) => key === 'network_member')
  );

  const [sent, providers] = partition(sortedProviders, p => (
    sentProviders.some(i => i.id === p.id)
  ));

  let [
    manualResults,
    found,
    networkResults,
    nonMatchingNetwork,
    organicResults,
  ] = Array(5).fill([]);

  if (sortOrder === 'recommended' || !sortOrder) {
    [manualResults, found] = partition(providers, 'manual');

    const [active, inactive] = partition(found, 'active');

    // Providers who are members of the selected network appear first
    const [network, organic] = partition(active, networkFilter);

    const [matchingNetwork, nonMatchingNetworkActive] = partition(network, p => (
      p.recommended
    ));

    const [networkInactive, organicInactive] = partition(inactive, networkFilter);

    networkResults = matchingNetwork;

    nonMatchingNetwork = nonMatchingNetworkActive.concat(networkInactive);

    const [
      recommendedOrganicResults,
      nonRecommendedOrganicResults,
    ] = partition(organic.concat(organicInactive), p => p.recommended);

    organicResults = recommendedOrganicResults.concat(nonRecommendedOrganicResults);
  } else {
    [manualResults, found] = partition(providers, 'new_manual');
    [networkResults, organicResults] = partition(found, networkFilter);

    if (!showNonMatchingNetwork) {
      [networkResults, nonMatchingNetwork] = partition(networkResults, p => (
        p.recommended
      ));
    }
  }
  /*
    * Use this to factor non-matching network results into the page
    * count, such that only 10 at a time are displayed. Ignoring this
    * means ALL non-matching networks get unfolded, no matter how many
    * there are, and shown on the first page. The 10 count only applies
    * to network + organic results. This also means no results get pushed
    * down (potentially out of view) by expanding non-network results.
    */
  /* if (this.state && this.state.showNonMatchingNetwork) {
    networkResults = matchingNetwork.concat(nonMatchingNetwork);
  } */

  const networkIndex = index;
  const matchingNetworkResults = networkResults.filter(({ features }) => !features.some(
    feature => feature === 'non_matching_network_member',
  ));
  const organicIndex = matchingNetworkResults.length < networkIndex
    ? networkIndex - matchingNetworkResults.length : 0;

  return {
    all: sortedProviders,
    sent,
    manualResults,
    nonMatchingNetwork,
    networkResults,
    organicResults,
    index,
    networkIndex,
    organicIndex,
  };
};

export const generateSearchedProvidersByType = (index = INITIAL_PAGE_SIZE) => (dispatch, getState) => ( // eslint-disable-line max-len
  dispatch(listProviderSearchResults(getState().referral.id))
    .then(({
      search,
      results: shapedResults,
    }) => scoreFeatures(getState, {}, search, shapedResults))
    .then(results => filteredSearchResults({}, results, getState().referral.id, getState))
    .then(results => dispatch(actions.replaceProviderSearchResults(results)))
    .then(() => dispatch(getSearchedProvidersByType(index)))
);

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion';
import Popup from 'reactjs-popup';
import { css } from 'emotion';
import { numeric } from 'helpers/i18n';
import { applyTestAttribute } from 'helpers/development';

import ContentPanel from 'components/utils/ContentPanel';
import ButtonBar from 'components/utils/ButtonBar';
import Fragment from 'components/utils/Fragment';
import Icon from 'components/utils/Icon';
import { ieLt } from 'components/utils/IE';
import withThemedComponents, { ThemeConfig } from 'components/utils/withThemedComponents';

import { createTrigger, baseModalStyleAttributes, modalOverrides } from 'components/utils/PopupUtils';

const DEFAULT_DIMENSIONS = { width: 800, height: 'auto', topMargin: 14 };

const createModalTheme = ({ version, theme: { colors, fonts } }) => numeric(version, {
  other: {
    version,
    styles: {
      MODAL_TITLE: css({
        marginTop: '17px',
        marginBottom: '17px',
      }),
      MODAL_BUTTON_FOOTER: css({
        marginTop: '20px',
      }),
      MODAL_RADIUS: undefined,
      MODAL_TITLE_BG_COLOR: colors.MEDIUM_LIGHT_GRAY_FILL,
      MODAL_BUTTON_ALIGNMENT: 'right',
      MODAL_CLOSE_BUTTON_COLOR: colors.WHITE,
      darkContentPadding: undefined,
      darkContentStyle: undefined,
    },
  },
  v2: {
    version,
    styles: {
      MODAL_TITLE: css({
        marginTop: '20px',
        marginBottom: '20px',
        fontSize: '16pt',
        fontFamily: fonts.BODY_TEXT,
      }),
      MODAL_BUTTON_FOOTER: css({
        margin: '20px 0 40px',
      }),
      MODAL_RADIUS: '10px',
      MODAL_TITLE_BG_COLOR: colors.TRANSPARENT,
      MODAL_BUTTON_ALIGNMENT: 'center',
      MODAL_CLOSE_BUTTON_COLOR: colors.SECONDARY_FILL,
      darkContentPadding: css`
        padding: 0px 40px 40px 40px;
      `,
      darkContentStyle: css`
        background-color: ${colors.MODAL_DARK_BACKGROUND};
        padding: 20px 40px 10px 40px;
        border-radius: 10px;
      `,
    },
  },
});

const customModalOverrides = () => ({
  ...modalOverrides,
  margin: undefined,
  ...(ieLt(12, {
    textAlign: 'left',
  })),
});

const ContentTitleContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ContentTitle = styled.h2`
  text-align: center;
  width: 100%;
`;

const IconContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  position: absolute;
  right: 0px;
  ${ieLt(12, 'top: -4px;')}
`;

const overlayStyle = () => ({
  alignItems: 'center',
  backgroundColor: 'rgba(255,255,255,0.8)',
  overflowY: 'scroll',
  ...(ieLt(12, {
    margin: '0px auto',
    display: 'block',
    textAlign: 'center',
  })),
});

/**
 * A modal popup window, pre-filled with a ContentPanel with close
 * button and optional footer with action buttons.
 */
class Modal extends React.Component {
  /**
   * Toggle the open/close state of the popup
   * @public
   */
  toggle = () => { if (this.instance) { this.instance.togglePopup({ persist: () => {} }); } }

  handleClose = () => { if (this.instance) { this.instance.closePopup(); } }

  renderCloseIcon = () => (
    <Icon name="mp-cancel" color={this.props.theme.styles.MODAL_CLOSE_BUTTON_COLOR} size={28} onClick={this.handleClose} />
  )

  renderTitleElement = (funcParams) => (
    <ContentTitleContainer>
      <ContentTitle
        className={this.props.theme.styles.MODAL_TITLE}
      >
        {this.props.title}
      </ContentTitle>
      {
        (this.props.closeable !== false || this.props.icons)
        && (
          <IconContainer>
            {this.props.icons ? (
              <ButtonBar>
                {typeof this.props.icons === 'function' ? this.props.icons(funcParams) : this.props.icons}
                {this.props.closeable !== false && this.renderCloseIcon()}
              </ButtonBar>
            ) : this.renderCloseIcon()}
          </IconContainer>
        )
      }
    </ContentTitleContainer>
  )

  render() {
    const funcParams = { close: this.handleClose };
    const { styles: modalStyles } = this.props.theme;
    const Element = (this.props.theme.version === 'v2' && this.props.dark) ? 'div' : Fragment;
    return (
      <Popup
        lockScroll={this.props.lockScroll}
        open={this.props.open}
        modal
        closeOnDocumentClick={!this.props.dialog}
        closeOnEscape={!this.props.dialog}
        ref={(r) => { this.instance = r; }}
        contentStyle={{
          ...customModalOverrides(),
          ...baseModalStyleAttributes,
          margin: `${this.props.topMargin || DEFAULT_DIMENSIONS.topMargin}vh auto auto`,
          height: `${(this.props.height ? `${this.props.height}px` : 'auto')}`,
          width: `${this.props.width || DEFAULT_DIMENSIONS.width}px`,
          overflowY: this.props.disableScrolling ? 'initial' : 'auto',
          borderRadius: modalStyles.MODAL_RADIUS,
        }}
        overlayStyle={overlayStyle()}
        arrow={false}
        on="click"
        onOpen={this.props.onOpen}
        onClose={this.props.onClose}
        trigger={createTrigger(this.props)}
      >
        <ContentPanel
          {...applyTestAttribute(this.props) /* eslint-disable-line react/jsx-props-no-spreading */}
          titleElement={this.renderTitleElement(funcParams, modalStyles)}
          titleBackgroundColor={this.props.color || modalStyles.MODAL_TITLE_BG_COLOR}
          divider={this.props.divider || !this.props.dark}
          className={this.props.dark ? modalStyles.darkContentPadding : undefined}
        >
          <Element className={this.props.dark ? modalStyles.darkContentStyle : undefined}>
            {typeof this.props.children === 'function' ? this.props.children(funcParams) : this.props.children}
            {this.props.actions && (
            <ButtonBar
              className={modalStyles.MODAL_BUTTON_FOOTER}
              align={modalStyles.MODAL_BUTTON_ALIGNMENT}
            >
              {typeof this.props.actions === 'function' ? this.props.actions(funcParams) : this.props.actions}
            </ButtonBar>
            )}
          </Element>
        </ContentPanel>
      </Popup>
    );
  }
}
Modal.propTypes = {
  /** Items to display in the footer button bar. Returns array of buttons when using func */
  actions: PropTypes.oneOfType([PropTypes.func, PropTypes.arrayOf(PropTypes.node)]),
  /** The modal content */
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired,
  /** True to add close button to the title, false otherwise (default true) */
  closeable: PropTypes.bool,
  /** The color of the modal */
  color: PropTypes.string,
  /** add dark background to modal, V2 use */
  dark: PropTypes.bool,
  /** A test identifier, for integration testing */
  'data-testid': PropTypes.string,
  /** True to keep modal open when clicking document, false otherwise */
  dialog: PropTypes.bool,
  /** Disable scrolling in popup */
  disableScrolling: PropTypes.bool,
  /** Control the divider for modal title */
  divider: PropTypes.bool,
  /** The height of the modal */
  height: PropTypes.number,
  /** Render custom icons in title container */
  icons: PropTypes.oneOfType([PropTypes.func, PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  /** Listener for when the modal is closed */
  onClose: PropTypes.func,
  /** Listener for when the modal is opened */
  onOpen: PropTypes.func,
  /** Control the popup open state */
  open: PropTypes.bool,
  /** Component theme */
  theme: ThemeConfig.isRequired,
  /** Title of the modal */
  title: PropTypes.oneOfType([PropTypes.func, PropTypes.node, PropTypes.string]).isRequired,
  /** Top margin above popup */
  topMargin: PropTypes.number,
  /** The node that will trigger the modal on click */
  trigger: PropTypes.node, // eslint-disable-line
  /** The width of the popup */
  width: PropTypes.number,
  /** Lock body scroll when modal is open */
  lockScroll: PropTypes.bool,
};

Modal.defaultProps = {
  actions: undefined,
  closeable: undefined,
  color: undefined,
  dark: undefined,
  'data-testid': undefined,
  dialog: false,
  divider: undefined,
  disableScrolling: undefined,
  height: null,
  icons: undefined,
  onClose: undefined,
  onOpen: undefined,
  open: undefined,
  topMargin: undefined,
  trigger: undefined,
  width: DEFAULT_DIMENSIONS.width,
  lockScroll: false,
};

export default withThemedComponents(createModalTheme)(Modal);

/* eslint-disable react/no-array-index-key, react/no-danger */

import React from 'react';
import PropTypes from 'prop-types';

import styled from 'react-emotion';
import Popup from 'reactjs-popup';

import * as styles from 'components/utils/Styles';
import { mq } from 'components/utils/Responsive';

import { createTrigger, modalOverrides, baseModalStyleAttributes } from 'components/utils/PopupUtils';

const { Secondary } = styles;

const Label = styled(Secondary)(
  mq({
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '100%',
  }),
);

const StyledPopup = styled(Popup)(
  mq({
    width: ['300px', '300px', '200px'],
  }),
);

const modalStyle = {
  ...baseModalStyleAttributes,
};

const ModalContent = styled(Secondary)`
  opacity: 0.5;
  padding: 20px;
  ul {
    margin-left: 16px;
  }
`;

/**
 * Display tooltip for provided trigger element,
 * revealing popup upon hover or any possible action provided via `on` property.
 * Popup text must be provided via the text property.
 */

const Tooltip = (props) => {
  const trigger = props.trigger ? props.trigger : <Label>{props.text}</Label>;
  return (
    <>
      <StyledPopup
        contentStyle={{
          ...modalOverrides,
          ...modalStyle,
        }}
        arrow={false}
        on={props.on}
        trigger={createTrigger({ ...props }, trigger)}
        position={`${props.position} center`}
      >
        <ModalContent>
          {props.text}
        </ModalContent>
      </StyledPopup>
    </>
  );
};

Tooltip.displayName = 'Tooltip';

Tooltip.propTypes = {
  /** Action on which popup triggered */
  on: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  /** Position of the tooltip */
  position: PropTypes.oneOf(['top', 'bottom', 'left', 'right']),
  /** Trigger element for tooltip */
  trigger: PropTypes.node,
  /** The text rendered inside popup, If trigger is not provided text will be used as trigger */
  text: PropTypes.string.isRequired,
};

Tooltip.defaultProps = {
  on: 'hover',
  position: 'bottom',
  trigger: null,
};

export default Tooltip;

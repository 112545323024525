import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { toggleGenericModal } from 'ducks/ui';
import PasswordConfirmationModal from 'components/login/PasswordConfirmationModal';

import GlobalModal from 'helpers/GlobalModal';

/**
 * This is a mini version of the global container for the login pack or other reduced feature set packs
 */
const MiniGlobalContainer = ({
  hasError = false,
  toggleGenericModal: toggleModal,
} = {}) => {
  useEffect(() => {
    GlobalModal.bindToStore(toggleModal);
  }, []);
  return !hasError && (
    <>
      <PasswordConfirmationModal />
    </>
  );
};
MiniGlobalContainer.propTypes = {
  /** True if there is an error in the parent that should stop this component from rendering */
  hasError: PropTypes.bool,
  /** Connect method to open these modals */
  toggleGenericModal: PropTypes.func.isRequired,
};
MiniGlobalContainer.defaultProps = {
  hasError: undefined,
};

export default connect(null, { toggleGenericModal })(MiniGlobalContainer);

/* eslint-disable import/prefer-default-export */
import GlobalModal, { modals } from 'helpers/GlobalModal';

import * as notificationOperations from './notifications';
import * as inboxOperations from './inbox';

export * from './threads';
export * from './notifications';
export * from './topics';
export * from './inbox';

// eslint-disable-next-line no-unused-vars
export const refreshMessages = ({ conversation, message } = {}, json) => (dispatch, getState) => (
  dispatch(notificationOperations.requestMessageUnreadCount())
);

export const updateConversationMembershipForThread = (payload) => (dispatch) => (
  GlobalModal.close(modals.TOGGLE_CONVERSATION_MODAL)
    .then(() => dispatch(inboxOperations.updateConversationMembership(payload)))
    .then(({ status, conversations }) => {
      const messages = Object.values(status);
      if (messages.length === 1) {
        const [message] = messages;
        if (message !== 'success') {
          throw new Error(message);
        }
      }
      return dispatch(inboxOperations.mergeInboxList(status, conversations));
    })
);

/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import PropTypes from 'prop-types';

/**
 * Renders a React Fragment, but throws away any passed in props other than
 * the children. Useful to escape warnings about invalid props.
 *
 * Note that this need to use old-school React.Component because, otherwise,
 * components that pass a ref to a Fragment will fail Jest testing, unless we
 * wrap that functional component with a forwardRef. However, using forwardRef
 * causes the underlying component to re-render excessively. This workaround
 * allows the ref (and other props) to be safely ignored without the rendering
 * issue.
 */
class Fragment extends React.Component {
  render() {
    return <>{this.props.children}</>;
  }
}
Fragment.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func, PropTypes.string]),
};
Fragment.defaultProps = {
  children: undefined,
};

export default Fragment;

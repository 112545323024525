import React from 'react';
import PropTypes from 'prop-types';

import Field from 'components/utils/Field';
import TextFieldBase from 'components/utils/TextFieldBase';

const IGNORED = [
  'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown',
  'Backspace', 'Home', 'End', 'Delete',
  'Control', 'Alt', 'Meta', 'Tab',
];

/**
 * A very simple number field that allows only
 * numeric input. No negative numbers or decimals.
 *
 * Any major needs beyond this will probably be grounds
 * for bringing in a third-party solution. But this should
 * do for most of our use cases.
 */
class NumberField extends React.Component {
  static propTypes = {
    /** Change handler */
    onChange: PropTypes.func,
    /** The numeric value (string also accepted) */
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  };

  static defaultProps = {
    onChange: undefined,
    value: undefined,
  };

  handleChange = (value) => {
    this.props.onChange(parseInt(value, 10));
  }

  handleKeyDown = (event) => {
    if (IGNORED.indexOf(event.key) < 0) {
      if (event.key.search(/\d/) === -1) {
        event.preventDefault();
      }
    }
  }

  render() {
    const { onChange, value, ...other } = this.props;
    return (
      <TextFieldBase
        type="tel"
        onKeyDown={this.handleKeyDown}
        onChange={onChange ? this.handleChange : undefined}
        value={value ? `${value}` : ''}
        {...other}
      />
    );
  }
}

export default Field(NumberField);

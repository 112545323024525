/* eslint-disable import/prefer-default-export, camelcase */
import { omit } from 'lodash';
import qs from 'query-string';

import apiDirector from 'helpers/ApiDirector';

import { DateTime } from 'helpers/dates';
import { createTranslator, numeric } from 'helpers/i18n';
import { removeSystemAlert } from 'ducks/session';
import { updateUserMetadata } from 'ducks/session/operations';

import { isProviderAdmin } from 'models/Session';
import { isReferralDoc, isDeclinedCareNeeds } from 'models/Referral';
import { isOutpatient, isTransportation, isAuthorization } from 'models/ProviderType';

import GlobalModal, { modals } from 'helpers/GlobalModal';

import * as actions from './actions';

const {
  TOGGLE_RECEIVING_RESPONSE_MODAL,
  TOGGLE_RECEIVING_AUTH_RESPONSE_MODAL,
  TOGGLE_RECEIVING_OUTPATIENT_RESPONSE_MODAL,
  TOGGLE_DENY_REFERRAL_MODAL,
  TOGGLE_DECLINE_REASON_MODAL,
} = modals;

/**
 * Not much going on in here. This UI-based duck is mainly for triggering
 * global UI pieces such as popups and modals. These already live in the DOM
 * and are generally self-contained in terms of data management. Thus, most
 * of the work is being done in actions. You should only be adding operations
 * here to chain methods or, if necessary, to fetch data from the server.
 */

export const requestProviderMissingBadgeModal = (
  referralId,
  hospitalId,
  sentReferrals,
) => (dispatch, getState) => apiDirector.validateFetch(`/api/referrals/${referralId}/provider-badges`, {
  method: 'POST',
  body: JSON.stringify({ sent_referral_ids: sentReferrals.map(({ id }) => id) }),
}).then(json => {
  const { collection = [] } = json;
  const { session: { user } } = getState();
  const isAdmin = sentReferrals.some(({ provider }) => isProviderAdmin(user, provider));

  if (collection.length > 0 && isAdmin) {
    return dispatch(actions.openProviderMissingBadgeModal(referralId, hospitalId, json));
  }

  return json;
});

export const requestProviderPreferencesModal = (referralId, sentReferrals, hospitalId) => (dispatch, getState) => apiDirector.validateFetch(`/api/referrals/${referralId}/provider-preferences`, {
  method: 'POST',
  body: JSON.stringify({
    sent_referral_ids: sentReferrals,
  }),
}).then((json) => {
  const {
    referral: {
      care_types = [],
      counties = [],
    } = {},
    patient: {
      insurances = [],
    } = {},
    sent_referrals = [],
  } = json;

  const { session: { user } } = getState();

  const preferencesMismatch = sent_referrals.some(({
    declined_reason,
    provider: {
      provider_type,
      care_types_offered = [],
      id: providerId,
      insurance_accepteds = [],
      provider_served_counties = [],
    } = {},
    status,
  }) => {
    if (!isProviderAdmin(user, { id: providerId })) return false;

    const isMissingCareType = () => care_types.some(({ id }) => (
      !care_types_offered.some(({ care_type_id }) => care_type_id === id)));

    const isMissingInsurance = () => insurances.some(({ adt_insurance_company_id }) => (
      !insurance_accepteds.some(({ adt_insurance_company_id: adtInsuranceCompanyId }) => (
        adtInsuranceCompanyId === adt_insurance_company_id))));

    const isMissingCounty = () => counties.some(({ id }) => (
      !provider_served_counties.some(({ county_id }) => county_id === id)));

    const hasCareType = () => care_types.some(({ id }) => (
      care_types_offered.some(({ care_type_id }) => care_type_id === id)));

    const hasInsurance = () => insurances.some(({ adt_insurance_company_id }) => (
      insurance_accepteds.some(({ adt_insurance_company_id: adtInsuranceCompanyId }) => (
        adtInsuranceCompanyId === adt_insurance_company_id))));

    const hasCounty = () => counties.some(({ id }) => (
      provider_served_counties.some(({ county_id }) => county_id === id)));

    return (
      (status === 'available' && (isMissingCareType() || isMissingInsurance() || isMissingCounty()))
        || (status === 'unavailable' && (
          (isDeclinedCareNeeds({ provider_type, declined_reason }) && hasCareType())
            || (declined_reason === 'declined_insurance' && hasInsurance())
            || (declined_reason === 'service_area' && hasCounty())
        ))
    );
  });

  if (preferencesMismatch) {
    return dispatch(actions.openProviderPreferencesModal(referralId, json));
  }

  if (hospitalId) {
    dispatch(requestProviderMissingBadgeModal(referralId, hospitalId, sent_referrals));
  }

  return json;
});

export const requestBadgesCheckupModal = (provider_ids) => dispatch => apiDirector.validateFetch('/api/admin/providers', {
  method: 'POST',
  body: JSON.stringify({
    id: provider_ids,
  }),
})
  .then(json => (Array.isArray(json) ? json : ([json] || [])))
  .then(json => dispatch(actions.openBadgesCheckupModal(json)))
  .then(() => dispatch(updateUserMetadata({
    badges_checkup_modal_last_viewed_at: DateTime.utc(DateTime.now()),
  })))
  .catch(() => {});

export const requestYearEndReportModal = (settingsKey) => dispatch => (
  dispatch(actions.openYearEndReportModal())
    .then(() => dispatch(updateUserMetadata({
      [settingsKey]: DateTime.utc(DateTime.now()),
    })))
);

export const requestReceiverPurchaseableModal = () => dispatch => dispatch(
  actions.openReceiverPurchaseableModal(),
).then(() => dispatch(updateUserMetadata({
  receiver_purchaseable_modal_last_viewed_at: DateTime.utc(DateTime.now()),
})));

export const openAttentionAlertModal = (key) => dispatch => dispatch(actions.toggleGenericModal({
  key,
  open: true,
  globalModalReduxKey: 'attentionAlert',
}));

export const openPatientVisitChooserModal = (patient, { query, ...options } = {}) => dispatch => {
  const path = `/api/patients/${patient.id}/patient_visits`;
  const params = query ? qs.stringify(query) : '';
  const url = [path, params].filter(Boolean).join('?');
  return apiDirector.validateFetch(url)
    .then((visits) => dispatch(actions.openCreateReferralConflictModal(
      patient,
      options,
      visits,
    )));
};

export const dismissSystemAlert = systemAlertReceipt => dispatch => (
  parseInt(systemAlertReceipt.system_alert_id, 10)
    ? apiDirector.validateFetch(
      '/api/system_alert_receipts',
      { body: JSON.stringify(systemAlertReceipt), method: 'POST' },
    )
    : Promise.resolve(systemAlertReceipt))
  .then(({ system_alert_id }) => dispatch(removeSystemAlert({ id: system_alert_id })));

export const prepareDocuments = (referral, attachmentOrAttachments) => (dispatch) => (
  new Promise((resolve, reject) => {
    if (!referral || !attachmentOrAttachments) {
      resolve();
    } else {
      const attachments = Array.isArray(attachmentOrAttachments)
        ? attachmentOrAttachments
        : [attachmentOrAttachments];
      if (!attachments.some(a => a.id === 'generated-overview')) {
        resolve();
      } else {
        dispatch(actions.toggleGenericModal({
          globalModalReduxKey: 'scrapeableDetails',
          open: false,
        })).then(() => {
          setTimeout(() => {
            dispatch(actions.toggleGenericModal({
              globalModalReduxKey: 'scrapeableDetails',
              open: true,
              referral,
              onRender: resolve,
            }));
          }, 500);
        }).catch(reject);
      }
    }
  })
);

const responseModalTranslator = createTranslator({
  title: (key, responseType) => numeric(responseType, {
    available: numeric(key, {
      [TOGGLE_RECEIVING_AUTH_RESPONSE_MODAL]: 'Respond: Authorize',
      [TOGGLE_RECEIVING_RESPONSE_MODAL]: 'Respond',
      other: 'Respond: Pre-approve',
    }),
    unavailable: numeric(key, {
      [TOGGLE_DENY_REFERRAL_MODAL]: 'Respond: Deny',
      other: 'Respond: Decline',
    }),
    under_review: 'Respond: Under Review',
    other: 'Respond',
  }),
});

export const openResponseModal = ({
  responseType,
  referral,
  referralResponse,
  onConfirm,
}) => () => {
  const createModal = (key, options = {}) => GlobalModal.open(key, {
    ...omit({
      referral,
      referralResponse,
      onClick: onConfirm,
      title: responseModalTranslator('title', key, responseType),
    }, Object.keys(options)),
    ...options,
  });

  const getLocations = () => {
    if (referralResponse) {
      return [referralResponse];
    }
    const roles = referral.getProviderRoles();
    return referral.sent_referrals
      .filter(({ confirmed }) => confirmed)
      .filter(({ provider_id: providerId }) => roles.some(({ organization }) => (
        organization.id === providerId && organization.organization_type === 'Provider'
      )));
  };

  const hasOnlyOneRespondant = getLocations().length === 1
    && referral.getInvitableProviders().length === 0;

  if (responseType === 'available') {
    const requiresDetails = isOutpatient(referral.level_of_care)
      || isTransportation(referral.level_of_care)
      || isAuthorization(referral.level_of_care);

    if ((referral.referralRole === 'receiving' || requiresDetails) && !isReferralDoc(referral)) {
      if (hasOnlyOneRespondant && isOutpatient(referral.level_of_care)) {
        return createModal(TOGGLE_RECEIVING_OUTPATIENT_RESPONSE_MODAL);
      }
      if (hasOnlyOneRespondant && isAuthorization(referral.level_of_care)) {
        return createModal(TOGGLE_RECEIVING_AUTH_RESPONSE_MODAL);
      }
      return createModal(TOGGLE_RECEIVING_RESPONSE_MODAL);
    }

    if (isReferralDoc(referral)) {
      return onConfirm([referralResponse]);
    }
    return onConfirm();
  }

  if (responseType === 'unavailable') {
    if (isAuthorization(referral.level_of_care)) {
      return createModal(TOGGLE_DENY_REFERRAL_MODAL);
    }

    return createModal(TOGGLE_DECLINE_REASON_MODAL, { onConfirm, onClick: undefined });
  }

  if (responseType === 'under_review' && isAuthorization(referral.level_of_care)) {
    if (hasOnlyOneRespondant) {
      return createModal(TOGGLE_RECEIVING_AUTH_RESPONSE_MODAL, { status: 'under_review' });
    }
    return createModal(TOGGLE_RECEIVING_RESPONSE_MODAL);
  }

  return createModal(TOGGLE_RECEIVING_RESPONSE_MODAL, { referralResponse: undefined });
};

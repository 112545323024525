import { isEmpty } from 'lodash';
import { isValidEmail } from 'helpers/validators';
import apiDirector from 'helpers/ApiDirector';
import { errorToast } from 'helpers/ui';

export const reducer = (state, { type, value }) => ({ ...state, [type]: value });

export const makeOnChangeHandler = ({ fieldKey, dispatch }) => (value) => dispatch({ type: fieldKey, value });

export const getErrors = ({ firstName, lastName, email }) => {
  const errors = {};
  if (!firstName) {
    errors.firstName = true;
  }
  if (!lastName) {
    errors.lastName = true;
  }
  if (!email || !isValidEmail(email)) {
    errors.email = true;
  }
  return errors;
};

export const handleSubmit = ({ state, dispatch }) => {
  dispatch({ type: 'loading', value: true });
  const { firstName, lastName, email } = state;
  const errors = getErrors({ firstName, lastName, email });

  if (isEmpty(errors)) {
    apiDirector.validateFetch('/api/users/lookup', {
      method: 'POST',
      body: JSON.stringify({
        user: { first: firstName, last: lastName, email },
      }),
    }).then(() => dispatch({ type: 'success', value: true }))
      .catch(error => { errorToast(error.message); })
      .finally(() => dispatch({ type: 'loading', value: false }));
  } else {
    dispatch({ type: 'errors', value: errors });
    dispatch({ type: 'loading', value: false });
  }
};

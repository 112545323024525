/* eslint-disable prefer-destructuring, camelcase, no-nested-ternary */
import apiDirector from 'helpers/ApiDirector';
import { DateTime } from 'helpers/dates';
import * as actions from './actions';

/**
 * Mark all notifications as read
 */
export const resetNotificationsUnreadCount = () => (dispatch, getState) => {
  const latest = getState().notifications.list
    .map(m => ({ id: m.id, date: DateTime.parse(m.created_at) }))
    .sort((a, b) => b.date.valueOf() - a.date.valueOf());

  if (latest.length > 0) {
    apiDirector.validateFetch('/api/notifications/read', {
      method: 'PATCH',
      body: JSON.stringify({
        read_id: latest[0].id,
      }),
    })
      .then(() => dispatch(actions.syncNotificationsUnreadCount()));
  }
};

/**
 * Mark one notification as read
 */
export const markNotificationAsRead = (id) => (dispatch) => {
  if (id) {
    apiDirector.validateFetch(`/api/notifications/${id}`, {
      method: 'POST',
      body: JSON.stringify({
        read_flag: true,
      }),
    })
      .then(() => dispatch(actions.decrementNotificationsUnreadCount()));
  }
};

/**
 * Download latest notifications and update the store
 */
export const requestNotifications = () => dispatch => apiDirector.validateFetch('/api/notifications')
  .then(json => dispatch(actions.replaceNotifications(json)));

// eslint-disable-next-line no-unused-vars
export const refreshNotifications = (referral, time) => (dispatch, getState) => (
  dispatch(requestNotifications())
);

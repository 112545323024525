/* eslint-disable react/no-unused-prop-types */

import React from 'react';
import PropTypes from 'prop-types';
import AidinPropTypes from 'helpers/AidinPropTypes';
import { css } from 'emotion';
import styled from 'react-emotion';
import { DateTime, Date } from 'helpers/dates';

import Field, { fieldPropTypes } from 'components/utils/Field';
import SelectField from 'components/utils/SelectField';
import Icon from 'components/utils/Icon';

const Container = styled.div`
  position: relative;
  .Select-control input {
    text-indent: 20px;
  }
  .Select-control .Select-placeholder {
    margin-left: 20px;
  }
  .Select-control .Select-value {
    margin-left: 20px;
  }
`;

const iconStyle = css`
  position: absolute;
  top: 5px;
  left: 4px;
`;

const cleanDate = date => date.clone().set({
  hours: 0, minutes: 0, seconds: 0, milliseconds: 0,
});

export const getDateValue = (dateValue, defaultValue) => {
  let baseTime = null;
  if (dateValue) {
    baseTime = DateTime.parseStrict(dateValue);
    if (!baseTime) {
      baseTime = Date.parse(dateValue, defaultValue);
    }
  } else {
    baseTime = defaultValue || DateTime.now();
  }
  return baseTime.clone();
};

export const formatTime = (value) => {
  if (value) {
    const date = DateTime.parse(value);
    return date.format('h:mma');
  }
  return '';
};

export const getTimeWindowOptions = ({
  startTime: iStartTime,
  endTime: iEndTime,
  endTimeInclusive,
  dateValue: iDateValue,
  step,
  valueRenderer,
} = {}) => {
  const dateValue = getDateValue(iDateValue);
  const [startTime, endTime] = DateTime.applyDateToDateTimeRange(
    dateValue,
    iStartTime || cleanDate(dateValue).set({ hour: 0 }),
    iEndTime || cleanDate(dateValue).set({ hour: 0, minute: 0 }).add(1, 'day'),
  );

  const options = [];
  const formatValue = (time) => (valueRenderer ? valueRenderer(time) : formatTime(time));
  let currentTime = startTime;
  const isEndTimeNotReached = () => (
    endTimeInclusive ? startTime.isSameOrBefore(endTime) : startTime.isBefore(endTime)
  );
  while (isEndTimeNotReached()) {
    options.push({
      label: formatValue(currentTime),
      value: DateTime.utc(currentTime),
    });
    currentTime = currentTime.add(step || 30, 'minutes');
  }

  return options;
};

/**
 * A select field allowing a choice of times
 */
class TimeSelectField extends React.Component {
  static propTypes = {
    ...fieldPropTypes,
    /** Hide the search icon */
    iconHidden: PropTypes.bool,
    /** The date portion of the DateTime. Defaults to today */
    dateValue: PropTypes.oneOfType([AidinPropTypes.date, AidinPropTypes.dateTime]),
    /** Receive a moment UTC object when the date is selected */
    onChange: PropTypes.func,
    /** Starting hour and minute. Date portion is ignored */
    startTime: PropTypes.oneOfType([AidinPropTypes.date, AidinPropTypes.dateTime]),
    /** End hour and minute. Date portion is ignored */
    endTime: PropTypes.oneOfType([AidinPropTypes.date, AidinPropTypes.dateTime]),
    /** True if end time should be inclusive, false otherwise */
    endTimeInclusive: PropTypes.bool,
    /** How many minutes between each option */
    step: PropTypes.number,
    /** The initial date, in UTC format */
    value: AidinPropTypes.dateTime,
    /** Custom rendering for the value */
    valueRenderer: PropTypes.func,
    /** Custom rendering for each item. Default to h:mma */
    itemRenderer: PropTypes.func,
  }

  static defaultProps = {
    onChange: undefined,
    startTime: undefined,
    endTime: undefined,
    endTimeInclusive: false,
    step: 60,
    iconHidden: undefined,
    dateValue: undefined,
    value: '',
    valueRenderer: undefined,
    itemRenderer: undefined,
  }

  constructor(props) {
    super(props);
    this.state = { value: props.value };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ value: nextProps.value });
  }

  formatTime = (value) => {
    if (value) {
      const date = DateTime.parse(value);
      return date.format('h:mma');
    }
    return '';
  }

  formatItem = (item) => {
    if (this.props.itemRenderer) {
      return this.props.itemRenderer(item);
    }
    return this.formatTime(item.value);
  }

  formatValue = () => (this.props.valueRenderer
    ? this.props.valueRenderer(this.state.value) : this.formatTime(this.state.value));

  getOptions = () => getTimeWindowOptions(this.props);

  handleSelect = (value) => {
    this.setState({
      value: value ? value.value : null,
    }, () => {
      if (this.props.onChange) {
        this.props.onChange(this.state.value);
      }
    });
  }

  render() {
    return (
      <Container>
        <SelectField
          placeholder={this.props.placeholder}
          items={this.getOptions()}
          itemRenderer={this.formatItem}
          valueRenderer={this.formatValue}
          onSelect={this.handleSelect}
          value={this.state.value}
        />
        {!this.props.iconHidden && <Icon className={iconStyle} name="mp-time" size={30} />}
      </Container>
    );
  }
}

export default Field(TimeSelectField);

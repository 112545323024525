/* eslint-disable camelcase, react/no-unused-prop-types */

import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withRouter } from 'react-router';
import styled from 'react-emotion';

import withConversationActions from 'components/messages/withConversationActions';

const Container = styled.div();

const handleClick = ({
  notifiable_id,
  notifiable_type,
  history,
  messageActions,
  conversationActions,
}) => {
  switch (notifiable_type) {
    case 'Referral':
      history.push(`/referrals/${notifiable_id}`);
      break;
    case 'Message':
      messageActions.requestMessageThreadById(notifiable_id);
      break;
    case 'Conversation':
      conversationActions.requestMessageThreadById(notifiable_id);
      break;
    default:
      break;
  }
};

const NotificationToast = props => (
  <Container onClick={() => handleClick(props)}>
    {props.text || `Notification: ${props.event}`}
  </Container>
);
NotificationToast.propTypes = {
  event: PropTypes.string,
  notifiable_id: PropTypes.number.isRequired,
  notifiable_type: PropTypes.string.isRequired,
  text: PropTypes.string,
};
NotificationToast.defaultProps = {
  event: undefined,
  text: undefined,
};

export default compose(
  withRouter,
  withConversationActions,
)(NotificationToast);

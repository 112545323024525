import PropTypes from 'prop-types';
import AidinPropTypes from 'helpers/AidinPropTypes';

/**
 * Supported organization types
 */
const OrganizationType = PropTypes.oneOf(['Hospital', 'Provider']);

/**
 * An organization here can be a Hospital or Provider,
 * which is specified by type.
 */
const Organization = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  type: OrganizationType,
});

const ConversationOrganization = PropTypes.shape({
  organization_id: PropTypes.number,
  organization_type: OrganizationType,
  organization: Organization,
  last_read_at: AidinPropTypes.dateTime,
});

export const ConversationItem = PropTypes.shape({
  /** Message id */
  id: PropTypes.number,
  /** The sender of this message */
  organization_id: PropTypes.number,
  /** The type of sender of this message */
  organization_type: OrganizationType,
  /** The id of the conversation */
  conversation_id: PropTypes.number,
  /** Message content */
  text: PropTypes.string,
  created_at: AidinPropTypes.dateTime,
  updated_at: AidinPropTypes.dateTime,
  /** User who sent this message */
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }),
});


/**
 * Details about a conversation about a Referral
 */
const ReferralTopicInfo = PropTypes.shape({
  id: PropTypes.number.isRequired,
  practice: PropTypes.string,
  discharge_date: AidinPropTypes.date,
  level_of_care: PropTypes.arrayOf(PropTypes.string),
  patient: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
});

const Conversation = PropTypes.shape({
  id: PropTypes.number.isRequired,
  topic_id: PropTypes.number,
  topic_type: PropTypes.string,
  topic_info: PropTypes.oneOfType([ReferralTopicInfo]),
  created_at: AidinPropTypes.dateTime,
  updated_at: AidinPropTypes.dateTime,
  conversation_organizations: PropTypes.arrayOf(ConversationOrganization),
  conversation_items: PropTypes.arrayOf(ConversationItem),
  last_conversation_item: ConversationItem,
});

export default Conversation;

import React from 'react';
import PropTypes from 'prop-types';
import { css, cx } from 'emotion';
import styled from 'react-emotion';

import moment from 'moment';
import momentPropTypes from 'react-moment-proptypes';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { DayPickerSingleDateController } from 'react-dates';

import Field, { fieldPropTypes } from 'components/utils/Field';
import Icon from 'components/utils/Icon';
import { detectIE } from 'components/utils/IE';
import SelectField from 'components/utils/SelectField';
import Placeholder from 'components/utils/Placeholder';
import CalendarDay from 'components/utils/CalendarDay';

const hideLinesStyles = css`
  .CalendarDay {
    border: none;
  }
`;

const containerStyle = css`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  .CalendarDay__outside {
    color: #AAAAAA;
    border: 1px solid #e4e7e7;
    background-color: #f5f5f5;
  }
  .CalendarDay__firstDayOfWeek {
    border-left: none;
  }
  .CalendarDay__lastDayOfWeek {
    border-right: none;
  }
  .CalendarMonth_caption {
    padding-bottom: 0px;
  }
  .CalendarDay__selected {
    background: none;
    border: 1px solid transparent;
  }
  .DayPickerNavigation_button {
    border: none;
  }
  .DayPicker_weekHeaders {
    display: none;
  }
`;

const Navigation = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0px 40px;
  text-align: left;
  color: #61CEE1 !important;
`;

const NavItem = styled.div`
  flex-basis: ${props => props.width}%;
`;

const WeekHeader = styled.div`
  margin-top: 8px;
  display: flex;
  align-items: center;
  justify-content: space-${detectIE() ? 'around' : 'evenly'};
  font-size: 12px;
  font-weight: 700;
`;

const DayOfWeek = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 39px;
  height: 39.5px;
  border-top: 0.5px solid #e4e7e7;
  border-left: 0.5px solid #e4e7e7;
  font-weight: bold;
  ${props => props.first && 'border-left: none;'}
  ${props => props.hideBorder && 'border: none;'}
`;

/**
 * Display a Calendar within a field input. It is opinionated, with
 * following stead-fast beliefs:
 *
 * - Dates are always chosen in the future
 * - Dates can be chosen no further than 10 years in the future
 *
 * These may be made into options as new features are built.
 */
class CalendarField extends React.Component {
  constructor(props) {
    super(props);
    this.state = { loading: false, focused: true };
    this.updating = false;
  }

  componentDidMount() {
    if (this.containerRef) {
      this.updateComponentHeight(this.containerRef.clientHeight);
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const current = this.props.value || moment();
    const next = nextProps.value || moment();
    if (!this.updating && !current.isSame(next, 'day')) {
      this.setState({ loading: true }, () => {
        this.setState({ loading: false });
      });
    }
  }

  componentDidUpdate() {
    if (!this.state.loading && this.containerRef) {
      this.updateComponentHeight(this.containerRef.clientHeight);
    }
  }

  /**
   * Set updating flag for internal state changes; these do not
   * need to have the full loading re-renders.
   */
  handleDateChange = (date) => {
    const that = this;
    that.updating = true;
    Promise.resolve(date)
      .then(that.props.onChange)
      .then(() => { that.updating = false; });
  }

  updateComponentHeight = (height) => {
    if (height > 0) {
      this.componentHeight = height;
    }
  }

  renderDayContents = day => (
    <CalendarDay
      key={day}
      date={day}
      options={typeof this.props.getDayOptions === 'function' ? this.props.getDayOptions(day) : undefined}
      selected={this.props.value && day.isSame(this.props.value, 'day')}
    />
  );

  renderNavigation = ({ month, onMonthSelect, onYearSelect }) => {
    const months = moment.months().map((label, value) => ({ label, value }));
    const { startYear, endYear } = this.props;

    let years = [];
    for (let i = startYear; i <= endYear; i++) { years.push(i); } // eslint-disable-line no-plusplus
    years = years.map(year => ({ label: `${year}`, value: year }));

    return (
      <div>
        <Navigation>
          <NavItem width={60}>
            <SelectField
              items={months}
              value={month.month()}
              onSelect={({ value } = {}) => Number.isInteger(value) && onMonthSelect(month, value)}
            />
          </NavItem>
          <NavItem width={35}>
            <SelectField
              items={years}
              value={month.year()}
              onSelect={({ value } = {}) => Number.isInteger(value) && onYearSelect(month, value)}
            />
          </NavItem>
        </Navigation>
        <WeekHeader>
          {moment.weekdays().map((name, idx) => (
            <DayOfWeek hideBorder={this.props.hideGridLines} key={name} first={idx === 0}>{name.charAt(0)}</DayOfWeek>
          ))}
        </WeekHeader>
      </div>
    );
  }

  render() {
    if (this.state.loading) {
      return (
        <Placeholder height={this.componentHeight}>...</Placeholder>
      );
    }
    const minHeightStyle = {};
    if (this.componentHeight > 0) {
      minHeightStyle.minHeight = this.componentHeight;
    }
    return (
      <div
        className={this.props.hideGridLines ? cx(containerStyle, hideLinesStyles) : containerStyle}
        style={minHeightStyle}
        ref={(ref) => { this.containerRef = ref; }}
      >
        <DayPickerSingleDateController
          noBorder
          daySize={40}
          keepOpenOnDateSelect
          hideKeyboardShortcutsPanel
          enableOutsideDays
          orientation="horizontal"
          date={this.props.value}
          numberOfMonths={this.props.numberOfMonths}
          onDateChange={this.handleDateChange}
          navPrev={<Icon size={30} name="mp-arrow-lg-left" color="#61CEE1" />}
          navNext={<Icon size={30} name="mp-arrow-lg-right" color="#61CEE1" />}
          focused={this.state.focused}
          renderDayContents={this.renderDayContents}
          renderCaption={this.renderNavigation}
          onFocusChange={({ focused }) => { this.setState({ focused }); }}
          isOutsideRange={this.props.isOutsideRange || (date => date.isBefore(moment(), 'day'))}
          isDayHighlighted={this.props.isDayHighlighted}
        />
      </div>
    );
  }
}

CalendarField.propTypes = {
  ...fieldPropTypes,
  /** Hides the gridlines on the calendar */
  hideGridLines: PropTypes.bool,
  /** Highlight the day if true is returned; gives date as a param */
  isDayHighlighted: PropTypes.func,
  /** Manage the available date range; gives date as a param */
  isOutsideRange: PropTypes.func,
  /** Number of months to be displayed */
  numberOfMonths: PropTypes.number,
  /** Receive a moment object when the date is selected */
  onChange: PropTypes.func, // eslint-disable-line react/no-unused-prop-types
  /** The first selectable year */
  startYear: PropTypes.number,
  /** The last selectable year */
  endYear: PropTypes.number,
  /** The initial date, a moment object */
  value: momentPropTypes.momentObj,
  /** Callback function that takes in a day (Moment obj) and returns a style obj for that day */
  getDayOptions: PropTypes.func,
};

CalendarField.defaultProps = {
  hideGridLines: false,
  isDayHighlighted: undefined,
  isOutsideRange: undefined,
  numberOfMonths: 1,
  onChange: undefined,
  startYear: moment().year(),
  endYear: moment().year() + 10,
  value: undefined,
  getDayOptions: undefined,
};

export default Field(CalendarField);

import PropTypes from 'prop-types';

import { createTranslator } from 'helpers/i18n';
import { providerTypes } from 'translations/providers';

const tr = createTranslator({
  displayName: {
    known: ({ name, type }) => `${name}${type ? ` (${type.toUpperCase()})` : ''}`,
    unknown: 'Unknown Location',
  },
});

export const getDisplayName = (provider, defaultValue = '') => {
  if (!provider || !provider.name) { return defaultValue || tr('displayName.unknown'); }
  return tr('displayName.known', provider);
};

export const getContactInformation = (provider) => {
  const { member_count: memberCount, fax, phone } = provider;
  let value = '';

  if (memberCount) {
    value = tr('count', memberCount);
  } else if (fax) {
    value = tr('fax', fax);
  } else if (phone) {
    value = tr('phone', phone);
  }

  return value;
};

const Provider = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  provider_type: PropTypes.oneOf(Object.keys(providerTypes).map(t => t.toLowerCase())),
  member_count: PropTypes.number,
  address: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.shape({
    name: PropTypes.string,
    abbreviation: PropTypes.string,
  }),
  state_id: PropTypes.number,
  zip: PropTypes.string,
  city_state_zip: PropTypes.string,
  phone: PropTypes.string,
  fax: PropTypes.string,
  suggested_deadline: PropTypes.number,
});

export default Provider;

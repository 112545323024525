import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { createTranslator } from 'helpers/i18n';
import {
  Panel,
} from 'components/utils';

import {
  partnerImages,
  styles,
} from './styles';
import text from './translations';

const tr = createTranslator(text);

const {
  imageTextContainerStyle,
  partnerImageIconStyle,
  loginImageContainerStyle,
  iconRowContainerStyle,
  ImageBottomText,
  ImageSubtext,
  ImageTitleText,
} = styles;

const FriendlyLoginImage = ({ size }) => {
  if (size !== 'large') return null;

  return (
    <div className={loginImageContainerStyle}>
      <Panel fullWidth align="center" className={imageTextContainerStyle} spacing={90}>
        <Panel spacing={0}>
          <ImageTitleText>
            {tr('imageTitleTop')}
            <br />
            {tr('imageTitleBottom')}
          </ImageTitleText>
          <ImageSubtext>
            {tr('imageSubtitleTop')}
            <br />
            {tr('imageSubtitleBottom')}
          </ImageSubtext>
        </Panel>
        <Panel spacing={10}>
          <ImageBottomText>
            {tr('imageTrusted')}
          </ImageBottomText>
          <Panel fullWidth orientation="horizontal" className={iconRowContainerStyle} spacing={0}>
            {partnerImages.map((icon, idx) => (
              <img
                key={['icon', idx].join('-')}
                src={icon}
                alt={tr('partnerAltText')}
                className={partnerImageIconStyle}
              />
            ))}
          </Panel>
        </Panel>
      </Panel>
    </div>
  );
};
FriendlyLoginImage.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']).isRequired,
};

export default connect(({
  browser: { mediaType: size } = {},
}) => ({ size }))(FriendlyLoginImage);

/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion';

import { applyTestAttribute } from 'helpers/development';
import { ContentTitle } from 'components/utils/ContentPanel';
import Divider from 'components/utils/Divider';

const Container = styled.div`
  border: 1px solid #EEEEEE;
  background-color: #F9F9F9;
  box-shadow: 0 1px 4px 0 rgba(153,153,153,0.3);
`;

const ContentPanelBody = styled.div`
  padding: 20px;
  height: 100%;
`;

const ContentPanelTitle = styled.div`
  background-color: ${props => props.backgroundColor};
  padding: 15px 20px 5px;
`;

/**
 * Based on ContentPanel, designed to be used within a
 * ContentPanel, particular on referral steps cards
 */
const InternalContentPanel = React.forwardRef((props, ref) => (
  <Container ref={ref} {...applyTestAttribute(props)}>
    <ContentPanelTitle>
      {props.titleElement || (
      <ContentTitle
        title={props.title}
        titleElement="h4"
        icon={props.icon}
      />
      )}
    </ContentPanelTitle>
    <Divider margin={0} />
    <ContentPanelBody>
      {props.children}
    </ContentPanelBody>
  </Container>
));
InternalContentPanel.propTypes = {
  /** The content of the content panel */
  children: PropTypes.node.isRequired,
  /** Optional icon for the panel, displayed to the right. TODO: How do we address icons? */
  icon: PropTypes.node,
  /** Text for panel title, displayed to the left. Ignored when using titleElement */
  title: PropTypes.string,
  /** Custom title component for this panel. Title and icon are ignored */
  titleElement: PropTypes.node,
  'data-testid': PropTypes.string,
};
InternalContentPanel.defaultProps = {
  icon: undefined,
  title: undefined,
  titleElement: undefined,
  'data-testid': undefined,
};

export default InternalContentPanel;

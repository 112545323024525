import React, { useState, useContext } from 'react';

import { createTranslator } from 'helpers/i18n';

import {
  Panel, Modal, Button, Icon,
} from 'components/utils';

import { ThemeContext } from 'components/utils/ThemeContext';
import text from './translations';
import { wasTimedOut } from './functions';
import { WrapContainer, CenteredText } from './styles';

const tr = createTranslator(text);

const TimedOutModal = () => {
  const [isOpen, setIsOpen] = useState(wasTimedOut());
  const {
    theme: {
      colors: {
        SENDING_DARKER_FILL, RECEIVING_DARKEST_FILL,
      },
    },
  } = useContext(ThemeContext);
  return (
    <Modal
      title={tr('title')}
      open={isOpen}
      width={500}
      onClose={() => setIsOpen(false)}
    >
      {({ close }) => (
        <Panel spacing={25} align="center">
          <Icon size={80} name="mp-time" color={SENDING_DARKER_FILL} />
          <Panel align="center">
            <WrapContainer>
              <CenteredText>{tr('subtitle')}</CenteredText>
            </WrapContainer>
          </Panel>
          <Button
            small
            color={RECEIVING_DARKEST_FILL}
            onClick={() => {
              setIsOpen(false);
              close();
            }}
          >
            {tr('button')}
          </Button>
        </Panel>
      )}
    </Modal>
  );
};

export default TimedOutModal;

import initialState from 'store/initialState';
import { getReferralState } from 'components/home/DashboardUtils';
import * as types from './types';

const replaceSentReferral = (referral, sentReferral) => {
  const updates = ['sent_referrals', 'provider_sent_referrals'].reduce((sum, key) => {
    const data = referral[key] || [];
    if (referral[key] === undefined) { return sum; }
    const existing = data.findIndex(sr => sr.id === sentReferral.id);
    if (existing >= 0) {
      const array = data.slice();
      array.splice(existing, 1, sentReferral);
      return {
        ...sum,
        [key]: array,
      };
    }
    return {
      ...sum,
      [key]: data.concat([sentReferral]),
    };
  }, {});

  if (referral.referralResponse && referral.referralResponse.id === sentReferral.id) {
    updates.referralResponse = sentReferral;
    updates.reservationStatus = getReferralState(referral.referralRole, {
      ...referral,
      ...updates,
    });
  }
  return { ...referral, ...updates };
};

const referrals = (state = initialState.referral, action) => {
  switch (action.type) {
    case types.REPLACE_REFERRAL: {
      const { merge = false } = action;
      let { referral } = action;
      if (state && state.id === referral.id) {
        referral = {
          ...(merge ? state : {}),
          ...referral,
          sent_referrals: merge ? state.sent_referrals : referral.sent_referrals,
        };
      }
      return (merge && state.id !== referral.id) ? state : referral;
    }
    case types.UPDATE_ATTACHMENT_METADATA: {
      const updated = state.attachments.slice();
      const index = updated.findIndex(({ id }) => id === action.attachmentId);

      if (index >= 0) {
        updated[index] = {
          ...updated[index],
          ...action.changes,
        };
      }

      return {
        ...state,
        attachments: updated,
      };
    }
    case types.REPLACE_REFERRAL_ROLE: {
      const {
        referralRole: currentRole,
        referralRole: {
          referral,
        } = {},
      } = action;
      return {
        ...state,
        ...referral,
        currentRole,
      };
    }
    case types.REPLACE_SENT_REFERRALS: {
      if (state) {
        return action.sent_referrals.reduce((result, sr) => replaceSentReferral(result, sr), state);
      }
      return state;
    }
    case types.REPLACE_SENT_REFERRAL: {
      if (state) {
        return replaceSentReferral(state, action.sent_referral);
      }
      return state;
    }
    case types.REPLACE_REFERRAL_PROVIDER_SEARCH_RESULTS: {
      const { results = [] } = action;

      const referral = state;
      const { provider_search: { provider_search_results: psr = [] } = {} } = referral;
      const array = results.reduce((set, result) => {
        const matchIndex = set.findIndex(r => r.provider_id === result.provider_id);
        if (matchIndex >= 0) {
          set.splice(matchIndex, 1, result);
          return set;
        }
        return set.concat(result);
      }, psr.slice());
      const referralCopy = {
        ...referral,
        provider_search: {
          ...referral.provider_search,
          provider_search_results: array,
          isSaved: true,
        },
      };
      referralCopy.reservationStatus = getReferralState(referralCopy.referralRole, referralCopy);

      return referralCopy;
    }
    default:
      return state;
  }
};

const patientSearch = (state = initialState.patientSearch, action) => {
  const { type, ...data } = action;
  switch (type) {
    case types.REPLACE_PATIENTS:
      return {
        ...state,
        ...data,
      };
    default:
      return state;
  }
};

const conversations = (state = initialState.conversations, action) => {
  const { type, ...data } = action;
  switch (type) {
    case types.REPLACE_CONVERSATIONS:
      return data.conversations;
    default:
      return state;
  }
};

const activity = (state = initialState.activity, action) => {
  const { type, ...data } = action;
  switch (type) {
    case types.REPLACE_REFERRAL_ACTIVITY:
      return data.activity;
    default:
      return state;
  }
};

const followers = (state = initialState.followers, action) => {
  const { type, ...data } = action;
  switch (type) {
    case types.REPLACE_REFERRAL_FOLLOWERS:
      return {
        abilities: {
          ...state.abilities,
          ...data.followers.abilities,
        },
        all: data.followers.all,
      };
    default:
      return state;
  }
};

const watchers = (state = initialState.followers, action) => {
  const { type, ...data } = action;
  switch (type) {
    case types.REPLACE_REFERRAL_WATCHERS:
      return data.watchers;
    default:
      return state;
  }
};

const purchases = (state = initialState.purchases, action) => {
  const { type, ...data } = action;
  switch (type) {
    case types.REPLACE_REFERRAL_PURCHASES:
      return data;
    default:
      return state;
  }
};

export default {
  activity,
  conversations,
  followers,
  purchases,
  patientSearch,
  referral: referrals,
  watchers,
};

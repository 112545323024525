import { flatten, merge, uniq } from 'lodash';

import Config from 'config';
import initialState from 'store/initialState';
import { hasSendingOrganization, getDashboardSectionTypes } from 'models/Session';

import * as types from './types';

export const mapAbilities = (user, organizations) => ({
  ...user,
  roles: uniq(
    (user.roles || []).concat(flatten(organizations.map(({ roles: orgRoles }) => orgRoles))),
  ),
  user_organizations: organizations,
  hospitals: organizations.filter(({ organization_type: type }) => type === 'Hospital').map(({ organization }) => organization),
  providers: organizations.filter(({ organization_type: type }) => type === 'Provider').map(({ organization }) => organization),
});

export const mapOrganizations = organizations => organizations
  .sort((a, b) => {
    if (a.default !== b.default) {
      return a.default ? -1 : 1;
    }
    return (a.organization.name || '').localeCompare(b.organization.name || '');
  })
  .map(({ organization }) => organization);

export const mapUserOrganizations = organizations => organizations
  .map(({ organization = {}, ...uo } = {}) => {
    const users = (organization.users || [])
      .filter(({ email, roles = [] } = {}) => email && !roles.some(r => r === 'patient'));

    return {
      ...uo,
      organization: {
        ...organization,
        team: users.filter(({ archived_at: archivedAt, email }) => (
          !archivedAt && !/^support\+(preview|impersonate).*@myaidin\.com$/i.test(email)
        )),
        users,
      },
    };
  });

const isClassicMode = organizations => Config.supportClassicMode
  && hasSendingOrganization(organizations)
  && !getDashboardSectionTypes(organizations).some(section => section === 'patient_dashboard');

export const backfillFlags = ({ nextFeatures }) => {
  const upgrades = [
    types.FEATURE_UPGRADE_RELEASE_4,
    types.FEATURE_UPGRADE_RELEASE_3,
    types.FEATURE_UPGRADE_RELEASE_2,
    types.FEATURE_UPGRADE_RELEASE_1,
  ];

  return upgrades.reduce((acc, upgrade, index) => {
    if (nextFeatures[upgrade]) {
      const newFeatures = { ...acc };
      for (let i = index; i < upgrades.length; i += 1) {
        newFeatures[upgrades[i]] = true;
      }
      return newFeatures;
    }
    return acc;
  }, { ...nextFeatures });
};

const session = (state = initialState.session, action) => {
  switch (action.type) {
    case types.LOGIN_USER: {
      const rawOrganizations = mapUserOrganizations(action.current_user.organizations);
      const organizations = mapOrganizations(rawOrganizations);
      return {
        ...state,
        user: mapAbilities(action.current_user, rawOrganizations),
        organizations,
      };
    }
    case types.LOGOUT_USER:
      return {
        ...state,
        user: null,
      };
    case types.SET_INVITED_USER:
      return {
        ...state,
        pending_user: {
          code: action.code,
          email: action.email,
          provider: action.provider,
        },
      };
    case types.REPLACE_SESSION_DATA: {
      const rawOrganizations = mapUserOrganizations(action.session.current_user.organizations);
      const organizations = mapOrganizations(rawOrganizations);
      const { session: { system_alerts: systemAlerts = [] } } = action;
      const nextFeatures = {
        ...state.features,
        ...action.session.features,
      };
      return {
        ...state,
        user: mapAbilities(action.session.current_user, rawOrganizations),
        organizations,
        classicMode: isClassicMode(organizations),
        features: backfillFlags({ nextFeatures }),
        system_alerts: [
          ...state.system_alerts.filter(({ id: systemAlertId }) => (
            !systemAlerts.some(({ id }) => systemAlertId === id)
          )),
          ...systemAlerts,
        ],
      };
    }
    case types.UPDATE_FEATURE_FLAGS: {
      return {
        ...state,
        features: {
          ...state.features,
          ...action.featureFlags,
        },
      };
    }
    case types.UPDATE_SYSTEM_ALERTS: {
      const { systemAlert } = action;
      return {
        ...state,
        system_alerts: [
          ...state.system_alerts.filter(({ id }) => systemAlert.id !== id),
          systemAlert,
        ],
      };
    }
    case types.REMOVE_SYSTEM_ALERT: {
      return {
        ...state,
        system_alerts: state.system_alerts.filter(({ id }) => action.systemAlert.id !== id),
      };
    }
    case types.UPDATE_USER: {
      const updatedUser = {
        ...action.user,
        organizations: state.user.organizations,
      };
      return {
        ...state,
        user: mapAbilities(updatedUser, updatedUser.organizations),
        classicMode: isClassicMode(mapOrganizations(updatedUser.organizations)),
      };
    }
    case types.UPDATE_METADATA:
      return {
        ...state,
        user: {
          ...state.user,
          metadata: {
            ...(action.merge ? state.user.metadata : {}),
            ...action.metadata,
          },
        },
      };
    case types.UPDATE_PREFERENCES:
      return {
        ...state,
        user: {
          ...state.user,
          prefs: action.merge ? merge({}, state.user.prefs, action.prefs) : action.prefs,
        },
      };
    default:
      return state;
  }
};

export default {
  session,
};

import React from 'react';
import { ThemeProvider } from 'components/utils/ThemeContext';

const withThemeProvider = WrappedComponent => React.forwardRef((props, ref) => {
  const { availableThemes, ...rest } = props;
  return (
    <ThemeProvider availableThemes={availableThemes}>
      <WrappedComponent ref={ref} {...rest} />
    </ThemeProvider>
  );
});

export default withThemeProvider;

import * as types from './types';

export const replaceProviderSearchResults = results => dispatch => Promise.resolve(dispatch({
  type: types.REPLACE_PROVIDER_SEARCH_RESULTS,
  ...results,
}));

export const resetProviderSearch = () => dispatch => Promise.resolve(dispatch({
  type: types.RESET_PROVIDER_SEARCH,
}));

export const syncProviderSearchSelection = rows => dispatch => Promise.resolve(dispatch({
  type: types.SYNC_PROVIDER_SEARCH_SELECTION,
  rows,
}));

export const updateProviderFaxNumber = (providerId, fax) => dispatch => Promise.resolve(dispatch({
  type: types.REPLACE_PROVIDER_FAX,
  providerId,
  fax,
}));

export const replaceProviderSearchWarnings = warnings => dispatch => Promise.resolve(dispatch({
  type: types.REPLACE_PROVIDER_SEARCH_WARNINGS,
  warnings,
}));

/* eslint-disable camelcase, no-nested-ternary */

import React from 'react';
import PropTypes from 'prop-types';

import { createTranslator, numeric } from 'helpers/i18n';
import { Date } from 'helpers/dates';

import Referral from 'models/Referral';

const tr = createTranslator({
  empty: 'No Date Set',
  discharge_initials: {
    dc: 'DC',
    edd: 'EDD',
  },
  startOfService: (date, format) => numeric(format, {
    short: `Start: ${date || tr('empty')}`,
    other: `Requested Start of Service: ${date || tr('empty')}`,
  }),
  dc: date => `${tr('discharge_initials.dc')}: ${date || tr('empty')}`,
  edd: date => `${tr('discharge_initials.edd')}: ${date || tr('empty')}`,
  none: '',
  label: {
    discharge: data => numeric(data.type, {
      dc: tr('dc', data.dischargeDate),
      edd: tr('edd', data.dischargeDate),
      other: '',
    }),
    short: data => [
      tr('startOfService', data.startOfService, 'short'),
      tr('label.discharge', data),
    ].filter(i => !!i).join(' | '),
    full: data => [
      tr('startOfService', data.startOfService, 'full'),
      tr('label.discharge', data),
    ].filter(i => !!i).join(' | '),
    other: 'Dates of Care',
  },
});

export const getHospitalDischargeDate = (referral, format = 'full') => {
  const {
    patient_visit = {},
    discharge_date: serviceDate,
    hospital_anticipated_discharge_date: edd,
    checklist_configuration: { category } = {},
  } = referral;
  let discharge;
  let initials;
  if (patient_visit && patient_visit.discharge) { discharge = patient_visit.discharge; }
  let type = discharge ? 'dc' : 'edd';
  if (type) {
    initials = tr(`discharge_initials.${type}`);
  }
  const date = discharge || edd;
  if (!date) { type = 'none'; }
  const dischargeDate = Date.dayFormat(date, { format: 'M/D' });
  const startOfService = serviceDate ? Date.dayFormat(serviceDate, { format: 'M/D' }) : null;
  const data = {
    type,
    value: date || null,
    initials: initials || '',
    startOfService,
    dischargeDate,
  };

  if (category === 'doc') {
    type = 'none';
  }

  return {
    ...data,
    label: tr(`label.${format}`, data),
    labels: Object.entries({ startOfService, [type]: dischargeDate })
      .map(([key, value]) => tr(key, value, format))
      .filter(i => !!i),
  };
};

const HospitalDischargeDate = ({ referral, format }) => (
  <>
    {getHospitalDischargeDate(referral, format).label}
  </>
);
HospitalDischargeDate.propTypes = {
  referral: Referral.isRequired,
  format: PropTypes.oneOf(['full', 'short', 'discharge', 'other']),
};
HospitalDischargeDate.defaultProps = {
  format: 'full',
};

export default HospitalDischargeDate;

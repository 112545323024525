export default {
  referrals: {
    overview: {
      checkInsuranceEligibility: "Aidin can now check online whether your patient's plan will be covered by the care you're seeking.<br/><br/>Checking is instantaneous, and gives you more security that their care is covered.",
      authorizeInsurance: "Aidin can now authorize your patient's insurance by sending this referral info directly to the patient's payors. Aidin will start a process similar to sending to providers, but will wait until your provider is reserved to ask them to approve or decline their coverage.",
      allowFeedback: 'Aidin follows up with patients 30 days after their treatment ends to learn about their satisfaction with their stay. Aidin uses those reviews to create the Patient Satisfaction badge, improving your provider search quality for future referrals.',
      providerStatus: "Aidin can use the providers' activity to determine their status. The possible options are: <br/><br/>- Unopened<br/>- Undelivered: Aidin had an issue with delivering it to their current contact info<br/>- Reviewing: They've opened the referral, but haven't responded yet<br/>- Available<br/>- Unavailable<br/>- Removed: They've asked to be removed from the referral and all future updates",
    },
    providerSearch: {
      aidinScore: 'Aidin calculates the best provider for your patient based on qualitative categories.<br/><br/>Click a score to compare providers.',
      lastActive: "This is the last time anyone from this provider logged in to Aidin. If they've never logged in, you can invite them to join.",
      removed: 'This provider has been removed from this referral and will no longer receive updates about it.',
      idealProviderCount: "Aidin recommends this number of providers based on what we've observed on successful referrals in the past for the level of care you've chosen. <br/><br/>Generally, too few providers limits the patient's choice, and too many providers makes the choice overwhelming for the patient.",
      preSelectedFeaturedBadge: 'Aidin has pre-selected this provider because it fits your search parameters and is one of the best results.',
      referralBadges: 'These badges are earned based on how the provider compares to other available options on this specific referral.',
      providerBadges: 'These badges are the same for a provider on every referral, and are based on how they are doing as a provider regardless of referral.',
      providerNetworks: 'Your leadership team may have set up provider networks that will override the Aidin filters (area, care types, insurances) and recommend the providers in the network.<br /><br />A "hard network" will default to in-network only providers, where a regular network will always include in-network providers as well as other eligible providers.',
    },
    referralDetails: {
      checkForPatientAuthorization: "Aidin can now authorize your patient's insurance by sending this referral info directly to the patient's payors. Aidin will start a process similar to sending to providers, but will wait until your provider is reserved to ask them to approve or decline their coverage.",
      addPayorsToThisReferral: 'If you have individual insurance authorizors that you would like to add to this referral, you can add them here.',
    },
    receivingReferralsOverview: {
      takeInCare: "Aidin knows that start of service dates tend to change during the patient's stay, but use this date to give your best assessment of whether you could provide the patient's care.",
      clinicallyComplexPatients: 'These patients have been marked clinically complex by their care team, perhaps because their clinical needs require care beyond the standard level—[contact the care team](action:send_message) for more details. <br/><br/> Aidin rewards your team for providing care for these patients by awarding you the Accepts Clinically Complex Cases badge and boosting you in the provider search results.',
      financiallyComplexPatients: 'These patients have been marked financially complex by their care team, perhaps because their insurance is one less often supported—[contact the care team](action:send_message) for more details. <br/><br/> Aidin rewards your team for providing care for these patients by awarding you the Accepts Clinically Complex Cases badge and boosting you in the provider search results.',
      intakeHomeServiceStartOfCare: "This is set based on the date the care team suggested when sending you the referral. Update according to your team's assessment of when the patient will begin their care. <br/><br/>If you don't know the answer, Aidin recommends waiting to complete intake until you can confirm when the patient will begin care.",
      intakeHomeServiceLOS: "Expected Length of Stay is how long (in days) your organization expects to care for this patient. <br/><br/>This is a new requirement for following patients' continuing care. <br/><br/>Take your best guess and Aidin will check in with you when this date arrives. If you don't know the answer, Aidin recommends setting a short LOS that will bring it back to your dashboard when you have more information.",
      aidinScore: 'Aidin uses the Provider Badges to compare possible provider options for a patient to each other.<br/><br/>The higher your Aidin Score the better match this patient is for your services and the better ranking you will have when compared against your peers.',
    },
    intake: {
      startOfCareInfo: 'This is set based on the date the care team suggested when sending you the referral. Update according to your team\'s assessment of when the patient will begin their care.<br/><br/>If you don\'t know the answer, Aidin recommends waiting to complete intake until you can confirm when the patient will begin care.',
      lengthOfStayInfo: 'Expected Length of Stay is how long (in days) your organization expects to care for this patient. <br/><br/>This is a new requirement for following patients\' continuing care.<br/><br/>Take your best guess and Aidin will check in when this date arrives. If you don\'t know the answer, Aidin recommends setting a short LOS that will bring it back to your dashboard when you have more information.',
      intakeDateInfo: 'Aidin is committed to tracking population health, so we ask you to provide which date the patient started care.',
    },
  },
  users: {
    badges: {
      earnSendingBadges: 'You can earn these badges by following best practices when creating and sending referrals.',
      earnReceivingBadges: 'You can earn these badges by following best practices when responding to referrals.',
      suggestCustomBadges: 'Give a provider a high-five! Have an idea for a great badge you\'d like to reward? Let us know!',
    },
    locations: {
      admin: `
The Aidin Admin role allows you to do a few additional tasks:
<br/><br/>
* Manage your provider networks (for sending referrals)
* Manage your team and team memberships
* View your organization's reporting suite
      `,
    },
  },
  providers: {
    badges: {
      earnProviderBadges: 'Estas insignias se obtienen cuando el equipo del Proveedor sigue las mejores prácticas al responder a las referencias.',
      suggestCustomBadges: '¿Tienes una idea para una gran insignia que te gustaría recompensar? ¡Haznos saber!',
    },
    quality: {
      medicare_score: 'Medicare asigna una calificación de estrellas basada en el desempeño de cada proveedor a través de una serie de factores: calidad, inspecciones de salud, personal y más. La calificación de estrellas proporciona un indicador del rendimiento de un proveedor en comparación con otros proveedores. Más estrellas indican un mejor rendimiento.',
      readmission_rate: 'Este es el porcentaje de pacientes que recibieron atención de este proveedor y tuvieron que regresar a un hospital dentro de los 30 días de su hospitalización previa.<br/><br/>Aidin también rastrea los reingresos en tiempo real para pacientes similares a usted.',
      medicare_outcome_score: 'Este es el porcentaje de pacientes que recibieron atención de este proveedor y tuvieron que regresar a un hospital dentro de los 30 días de su hospitalización previa.<br/><br/>La tasa de reingreso de Medicare se calcula anualmente en función del desempeño de otros Pacientes de Medicare.',
      patient_rating: 'Este es el porcentaje de pacientes que recomendaría este proveedor a amigos o familiares.<br/><br/>Aidin realiza encuestas independientes de todos los pacientes en tiempo real. Esperamos que considere brindar su voz al proceso de encuesta de Aidin.',
      medicare_satisfaction_score: 'Este es el porcentaje de pacientes que recomendaría este proveedor a amigos o familiares.<br/><br/>La satisfacción del paciente de Medicare se calcula anualmente en base a encuestas realizadas por otros pacientes de Medicare.',
      expected_los_met_rate: 'Esto transmite cuán estrechamente la duración de la estadía en este proveedor coincidió con la mejor estimación del proveedor sobre cuánto tiempo esperan que permanezca el paciente.',
      response_rate_total: 'Esto incluye referencias que nunca llegaron a completarse. Aidin quiere darle crédito por tantas respuestas como sea posible, incluso si el paciente no terminó necesitando atención.',
      response_rate: messageVariables => `
      Este es el porcentaje de la frecuencia con la que este proveedor ha respondido a las referencias antes de la fecha límite establecida por el remitente en los últimos 90 días. Responder a las referencias a tiempo garantiza que los pacientes puedan ser dados de alta a tiempo.

      * Referencias recibidas en los últimos 90 días: **${messageVariables.total}** [?](#help:providers.quality.response_rate_total)
      * Las referencias ${messageVariables.subject} respondieron antes de la fecha límite: **${messageVariables.on_time}**
      * Las referencias ${messageVariables.subject} sin respuestas: **${messageVariables.unresponded}**
      * Las referencias ${messageVariables.subject} respondió tarde: **${messageVariables.overdue}**
      <br/><br/>
      Porcentaje: **${messageVariables.on_time}/${messageVariables.total} = ${messageVariables.response_rate}%** Tasa de responder a tiempo
      `,
    },
    qualitySummary: {
      medicare_score: 'Medicare asigna una calificación de estrellas basada en el desempeño de cada proveedor a través de una serie de factores: calidad, inspecciones de salud, personal y más. La calificación de estrellas proporciona un indicador del rendimiento de un proveedor en comparación con otros proveedores. Más estrellas indican un mejor rendimiento.',
      readmission_rate: 'Este es el porcentaje de pacientes que recibieron atención de este proveedor y tuvieron que regresar a un hospital dentro de los 30 días de su hospitalización previa.<br/><br/>La tasa de reingreso de Medicare se calcula anualmente en función del desempeño de otros Pacientes de Medicare. Aidin, el servicio que lo ayuda a encontrar proveedores, también rastrea los reingresos en tiempo real para pacientes similares a usted.',
      patient_rating: 'Este es el porcentaje de pacientes que recomendaría este proveedor a amigos o familiares.<br/><br/>La satisfacción del paciente de Medicare se calcula anualmente en base a encuestas realizadas por otros pacientes de Medicare. Aidin, el servicio que lo ayuda a encontrar proveedores, realiza encuestas independientes de todos los pacientes en tiempo real. Esperamos que considere brindar su voz al proceso de encuesta de Aidin.',
      discharge_to_community: 'Este es el porcentaje de pacientes que recibieron atención de este proveedor y fueron dados de alta de manera segura después de que se completara su atención. Esta tasa de Medicare se calcula anualmente y es una medida de la utilización de los recursos.',
    },
  },
  features: {
    verified_admin: {
      aboutJumio: 'Your identification details are transferred directly and security to Jumio Go and only used for the purposes of verification. Aidin never handles your identity information, and neither Aidin nor Jumio store any of your identity information.',
    },
  },
  documents: {
    send: 'TODO: Help text',
  },
};

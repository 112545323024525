/* eslint-disable react/no-danger */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion';
import { createTranslator } from 'helpers/i18n';
import loginTranslations from 'translations/login';

import {
  Button, CheckboxField, Modal, Panel, Colors,
} from 'components/utils';

const { GRAY_FILL, RECEIVING_DARKER_FILL } = Colors;

const tr = createTranslator({
  ...loginTranslations,
  title: 'HIPAA Disclosure Agreement',
  fraudTitle: 'Certification Statement of Fraud and Abuse',
  // eslint-disable-next-line quotes
  agree: `I agree to and understand the above and Aidin's <a target="_blank" href="http://www.myaidin.com/terms-of-service/">terms and conditions</a>`,
  cancel: 'Cancel',
  submit: 'Continue',
});

const Container = styled.div`
  margin: 23px 70px 40px;
`;

/** HIPAA modal for new users */
export default class HIPAAModal extends React.Component {
  static propTypes = {
    /** Triggers the popup of the modal */
    open: PropTypes.bool.isRequired,
    /** Callback when modal is closed */
    onClose: PropTypes.func.isRequired,
    /** Called when submit is clicked (req's agreement) */
    onSubmit: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);
    this.state = { agree: false };
  }

  handleCheckChange = (agree) => {
    this.setState({ agree });
  }

  renderActions = ({ close }) => [
    <Button key="cancel" small link onClick={close}>{tr('cancel')}</Button>,
    <Button
      small
      key="submit"
      disabled={!this.state.agree}
      color={this.state.agree ? RECEIVING_DARKER_FILL : GRAY_FILL}
      onClick={() => { close(); this.props.onSubmit(); }}
    >
      {tr('submit')}
    </Button>,
  ]

  render() {
    return (
      <Modal
        title={tr('title')}
        open={this.props.open}
        onClose={this.props.onClose}
        actions={this.renderActions}
      >
        <Container>
          <Panel spacing={30}>
            <div>{tr('privacy')}</div>
            <Panel spacing={6}>
              <h4>{tr('fraudTitle')}</h4>
              <div>{tr('fraud')}</div>
            </Panel>
            <CheckboxField
              label={<span dangerouslySetInnerHTML={{ __html: tr('agree') }} />}
              value={this.state.agree}
              onChange={this.handleCheckChange}
            />
          </Panel>
        </Container>
      </Modal>
    );
  }
}

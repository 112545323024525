import { Colors } from 'components/utils';

const { LIGHTEST_GRAY_FILL } = Colors;

export const formatSender = (user, organization) => {
  let sender = '';

  if (!user && organization) {
    sender = organization.name;
  } else if (user && organization) {
    if (user.name.includes('@')) {
      sender = `**${organization.name}** (${user.name})`;
    } else {
      sender = `**${user.name}** @ ${organization.name}`;
    }
  } else if (user) {
    if (user.name.includes('@')) {
      sender = `**${user.name}**`;
    } else if (user.email) {
      sender = `**${user.name}** (${user.email})`;
    } else {
      sender = `**${user.name}**`;
    }
  }

  return sender;
};

export const testWhoSentMessage = (mine, ours) => {
  if (mine) {
    return 'mine';
  }
  if (ours) {
    return 'ours';
  }
  return '';
};

export const setBackgroundColor = (props) => {
  if (props === 'mine') {
    return '#C5D6FF';
  }
  if (props === 'ours') {
    return '#ECF1FF';
  }
  return LIGHTEST_GRAY_FILL;
};

export const LOGIN_USER = 'session/user/LOGIN_USER';
export const LOGOUT_USER = 'session/user/LOGOUT_USER';
export const SET_INVITED_USER = 'session/user/SET_INVITED_USER';
export const SIGN_UP_USER = 'session/user/SIGN_UP_USER';
export const REPLACE_SESSION_DATA = 'session/user/REPLACE_SESSION_DATA';
export const UPDATE_FEATURE_FLAGS = 'session/user/UPDATE_FEATURE_FLAGS';
export const UPDATE_USER = 'session/user/UPDATE_USER';
export const UPDATE_ORGANIZATION = 'session/user/UPDATE_ORGANIZATION';
export const UPDATE_PREFERENCES = 'session/user/UPDATE_PREFERENCES';
export const UPDATE_METADATA = 'session/user/UPDATE_METADATA';
export const UPDATE_SYSTEM_ALERTS = 'session/user/UPDATE_SYSTEM_ALERTS';
export const REMOVE_SYSTEM_ALERT = 'session/user/REMOVE_SYSTEM_ALERT';

export const FEATURE_DOCUMENT_LIBRARY = 'feature/inbox/library';
export const FEATURE_AIDIN_PRINTER = 'feature/printer';
export const FEATURE_INBOUND_FAX = 'feature/inbound_fax';
export const FEATURE_AUTHORIZATIONS_PRECERT = 'feature/authorizations/precert';
export const FEATURE_UPGRADE_UI = 'feature/upgrade_ui';
export const FEATURE_ACTION_CABLE_PUSH_UNREAD_MESSAGES = 'feature/action_cable/push_unread_messages';
export const FEATURE_DIGITAL_SIGNATURES = 'feature/digital_signatures';
export const FEATURE_UPGRADE_LOGIN_UI = 'feature/upgrade_ui/login';
export const FEATURE_UPGRADE_RELEASE_1 = 'feature/upgrade_ui/release-1';
export const FEATURE_UPGRADE_RELEASE_2 = 'feature/upgrade_ui/release-2';
export const FEATURE_UPGRADE_RELEASE_3 = 'feature/upgrade_ui/release-3';
export const FEATURE_UPGRADE_RELEASE_4 = 'feature/upgrade_ui/release-4';

export const FEATURE_REFERRAL_FOLLOWING_PREFERENCES = 'feature/organization_following_exclusions';
export const FEATURE_REFERRAL_FOLLOWING_PREFERENCES_MARKETING_MODAL = 'feature/organization_following_exclusions/modal';

export const FEATURE_PROVIDER_PROFILE_MVP = 'feature/provider-profile/mvp';
export const FEATURE_PROVIDER_PROFILE_USE = 'feature/provider-profile/use';
export const FEATURE_PROVIDER_PROFILE_PREVIEW = 'feature/provider-profile/preview';
export const FEATURE_PROVIDER_PROFILE_CONTACTS = 'feature/provider-profile/provider_contact';
export const FEATURE_PROVIDER_PROFILE_AVAILABILITY_VISITOR = 'feature/provider-profile/visitor_policy';
export const FEATURE_PROVIDER_PROFILE_AVAILABILITY_ADMISSION = 'feature/provider-profile/admission_hours';
export const FEATURE_PROVIDER_PROFILE_ORGANIZATION_TAGS = 'feature/provider-profile/provider_licensure';

export const FEATURE_CONVERSATION_THREADS = 'feature/conversations/threads';
export const FEATURE_CONVERSATION_TOPICS = 'feature/conversations/topics';
export const FEATURE_CONVERSATION_INBOX = 'feature/conversations/inbox';
export const FEATURE_CONVERSATION_DASHBOARD = 'feature/conversations/dashboard';

export const FEATURE_PATIENT_RESPONSE_SEND_BUTTONS = 'feature/patient-response/send-to-provider';
export const FEATURE_PATIENT_RESPONSE_CHOICE_DOCS = 'feature/patient-response/choice-documents';
export const FEATURE_PATIENT_RESPONSE_DISSEMINATION = 'feature/patient-response/dissemination-document';

export const FEATURE_EPIC_OUTBOUND = 'feature/epic/outbound';
export const FEATURE_EPIC_OUTBOUND_AUTH_NOTE = 'feature/epic/outbound/authorizationNote';
export const FEATURE_EPIC_OUTBOUND_DOCUMENTS = 'feature/epic/outbound/addWatermarkToPDF';
export const FEATURE_EPIC_OUTBOUND_BED_DAY_TABLE = 'feature/epic/outbound/bedDayTableCSV';
export const FEATURE_EPIC_OUTBOUND_DISCHARGE_DESTINATION = 'feature/epic/outbound/dischargeDestination';
export const FEATURE_EPIC_OUTBOUND_HAR_NOTE_CSV = 'feature/epic/outbound/harNoteCSV';
export const FEATURE_EPIC_OUTBOUND_ONBASE_PDF = 'feature/epic/outbound/onbasePdf';
export const FEATURE_DOCUMENT_RESPONSES = 'feature/inbox/outbound/responses';
export const FEATURE_PATIENT_FILTERED_DASHBOARD = 'feature/dashboard-patient-filter';
export const FEATURE_DASHBOARD_INSIGHTS = 'feature/dashboard/insights';
export const FEATURE_EVENT_SUBSCRIPTIONS = 'feature/event_subscription/publish';

export const FEATURE_YEAR_END_REPORT = 'feature/reports/year_end_report';

export const FEATURE_DOCUMENT_READER = 'feature/document_reader';

export const FEATURE_COLLECT_AUTH_CARE_TYPES = 'feature/authorizations/collect-care-types';
export const FEATURE_REQUIRED_FIELDS_TOGGLE = 'feature/authorizations/availity/required-fields-toggle';
export const FEATURE_HIDE_COVERAGE_CATEGORY_INPUT = 'feature/auth/hide-coverage-category-input';

export const FEATURE_EXPANDED_FEATURE_COLUMN = 'feature/provider-search/expanded-feature-column';
export const FEATURE_PROVIDER_SEARCH_SORT_ORDER = 'feature/provider_search/sort_order';
export const FEATURE_PROVIDER_SEARCH_SORT_ORDER_NAME = 'feature/provider_search/sort_order/name';
export const FEATURE_PROVIDER_SEARCH_SORT_ORDER_DISTANCE = 'feature/provider_search/sort_order/distance';
export const FEATURE_PROVIDER_SEARCH_SORT_ORDER_AIDIN_SCORE = 'feature/provider_search/sort_order/aidin_score';

export const FEATURE_HIDE_POPUP_CONTENT = 'feature/provider-search/hide-popup-content';

export const FEATURE_PROVIDER_VERIFICATION_WARNING = 'feature/provider-verification-warning';
export const FEATURE_PROVIDER_EXPERIENCE_DEFAULT_SELECT_ALL_INSURANCES = 'feature/provider-experience-default-select-all-insurances';

export const FEATURE_DASHBOARD_ACTIVITY_NOTIFICATION = 'feature/dashboard/activity-notification';
export const FEATURE_DASHBOARD_PRIORITY_SORT = 'feature/dashboard/priority-sort';
export const FEATURE_DASHBOARD_PRIORITY_SORT_REFERRAL_STATUS = 'feature/dashboard/priority-sort/referral_status';
export const FEATURE_DASHBOARD_PRIORITY_SORT_LAST_NAME = 'feature/dashboard/priority-sort/last_name';
export const FEATURE_DASHBOARD_PRIORITY_SORT_DEADLINE_TO_RESPOND = 'feature/dashboard/priority-sort/deadline_to_respond';
export const FEATURE_DASHBOARD_PRIORITY_SORT_SCORE = 'feature/dashboard/priority-sort/score';

export const FEATURE_FEDRAMP_DISCLOSURE = 'feature/fedramp-disclosure';
export const FEATURE_INSURANCE_INBOUND_PROCESSING = 'feature/insurance-inbound-processing';
export const FEATURE_AUTHORIZATIONS_AVAILITY_STOP_AUTO_UPDATES = 'feature/authorizations/availity/stop-auto-updates';
export const FEATURE_INBOX_INBOUND_ASSIGN_USER = 'feature/inbox/inbound/assign-user';
export const FEATURE_ASSIGN_PATIENT_VISIT_DOCUMENT_VIEW = 'feature/inbound/assign_patient_visit_document_view';
export const FEATURE_INBOX_PROCESS_MULTIPLE_RESPONSES = 'feature/inbox/process_multiple_responses';
export const FEATURE_INBOX_INBOUND_DOCUMENT_DUPLICATE_DETECTED_ENABLED = 'feature/inbox/inbound/document_duplicate_detected_enabled';
export const FEATURE_INBOX_INBOUND_CALENDAR = 'feature/inbox/inbound/response_history_calendar';
export const FEATURE_PATIENT_VISIT_PARTITION_BY_BED = 'feature/patient-visits/prioritize-by-bed';
export const FEATURE_INBOX_INBOUND_MULTIPLE_ANSWERS = 'feature/inbox/inbound/multiple_aidin_auth_approval';
export const FEATURE_INBOX_OUTBOUND_HIDE_EMAIL = 'feature/inbox/outbound/hide-email';
export const FEATURE_LAZY_LOAD_UPDATES = 'feature/dashboard/lazy-load-updates';

/* eslint-disable camelcase */

import * as types from './types';

export const replaceReferral = referral => dispatch => Promise.resolve(dispatch({
  type: types.REPLACE_REFERRAL,
  referral,
}));

export const replaceReferralRole = referralRole => dispatch => Promise.resolve(dispatch({
  type: types.REPLACE_REFERRAL_ROLE,
  referralRole,
}));

export const mergeReferral = referral => dispatch => Promise.resolve(dispatch({
  type: types.REPLACE_REFERRAL,
  referral,
  merge: true,
}));

export const replacePatients = search => dispatch => Promise.resolve(dispatch({
  type: types.REPLACE_PATIENTS,
  ...search,
}));

export const replaceSentReferral = sent_referral => dispatch => Promise.resolve(dispatch({
  type: types.REPLACE_SENT_REFERRAL,
  sent_referral,
}));

export const replaceSentReferrals = sent_referrals => dispatch => Promise.resolve(dispatch({
  type: types.REPLACE_SENT_REFERRALS,
  sent_referrals,
}));

export const replaceConversations = conversations => dispatch => Promise.resolve(dispatch({
  type: types.REPLACE_CONVERSATIONS,
  conversations,
}));

export const clearConversations = () => dispatch => Promise.resolve(dispatch({
  type: types.REPLACE_CONVERSATIONS,
  conversations: [],
}));

export const replaceReferralActivity = activity => dispatch => Promise.resolve(dispatch({
  type: types.REPLACE_REFERRAL_ACTIVITY,
  activity,
}));

export const clearReferralActivity = () => dispatch => Promise.resolve(dispatch({
  type: types.REPLACE_REFERRAL_ACTIVITY,
  activity: [],
}));

export const replaceReferralFollowers = res => dispatch => Promise.resolve(dispatch({
  type: types.REPLACE_REFERRAL_FOLLOWERS,
  followers: {
    abilities: res.abilities || {},
    all: res.followers || [],
  },
}));

export const clearReferralFollowers = () => dispatch => Promise.resolve(dispatch({
  type: types.REPLACE_REFERRAL_FOLLOWERS,
  followers: {
    abilities: {},
    all: [],
  },
}));

export const replaceReferralWatchers = watchers => dispatch => Promise.resolve(dispatch({
  type: types.REPLACE_REFERRAL_WATCHERS,
  watchers,
}));

export const clearReferralWatchers = () => dispatch => Promise.resolve(dispatch({
  type: types.REPLACE_REFERRAL_WATCHERS,
  watchers: [],
}));

export const replaceReferralPurchases = data => dispatch => Promise.resolve(dispatch({
  type: types.REPLACE_REFERRAL_PURCHASES,
  ...data,
}));

export const clearReferralPurchases = () => dispatch => Promise.resolve(dispatch({
  type: types.REPLACE_REFERRAL_PURCHASES,
}));

export const replaceReferralProviderSearchResults = ({ id, results }) => dispatch => (
  Promise.resolve(dispatch({
    type: types.REPLACE_REFERRAL_PROVIDER_SEARCH_RESULTS,
    referralId: id,
    results,
  })));

export const updateReferralAttachmentDate = (referralId, attachmentId, dateAdded) => dispatch => (
  Promise.resolve(dispatch({
    type: types.UPDATE_ATTACHMENT_METADATA,
    referralId,
    attachmentId,
    changes: {
      dateAdded,
    },
  }))
);

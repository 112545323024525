import initialState from 'store/initialState';
import * as types from './types';

const referralsDashboard = (state = initialState.referralsDashboard, action) => {
  switch (action.type) {
    case types.REPLACE_REFERRALS_SEARCH_INDEX: {
      return {
        ...state,
        ...action.results,
      };
    }
    case types.APPEND_REFERRALS_SEARCH_CONTENT: {
      return {
        ...state,
        content: {
          ...state.content,
          ...action.referrals,
        },
      };
    }
    case types.REPLACE_REFERRALS_SEARCH_FILTERS: {
      return {
        ...state,
        filters: action.filters,
      };
    }
    default:
      return state;
  }
};

export default {
  referralsDashboard,
};

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion';

import { createTranslator, pluralize } from 'helpers/i18n';
import { errorToast } from 'helpers/ui';
import User from 'models/User';
import { Organization } from 'models/Session';

import {
  Modal,
  Panel,
  Button,
  ButtonBar,
} from 'components/utils';

import CreateOrganizationStepper from 'components/login/CreateOrganizationStepper';
import ClaimOrganizationStepper from 'components/login/ClaimOrganizationStepper';
import ProviderLookupPanel from 'components/providers/ProviderLookupPanel';
import { ThemeContext } from 'components/utils/ThemeContext';

const tr = createTranslator({
  title: 'Find Your Organization',
  claims: {
    title: count => `Claim Your ${pluralize(count, 'Organization')}`,
    confirmation: count => `Thanks! Aidin is reviewing your ${pluralize(count, 'organization')} and will get back to you in 48 hours.`,
  },
  create: {
    title: 'Create Your Organization',
    confirmation: 'Thanks! Aidin is reviewing your organization and will get back to you in 48 hours.',
  },
  actions: {
    add: 'Add Another Organization',
    search: 'Search for More Organizations',
  },
  error: {
    create: 'An unexpected error occurred trying to create this provider.',
    claim: 'An unexpected error occurred trying to claim this provider',
  },
});

const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const Content = styled.div`
  width: 460px;
  margin-bottom: 20px;
  ul {
    margin-left: 1rem;
  };
  li > div {
    margin-left: 10px;
  }
`;

const ConfirmationContainer = styled.div`
  margin: 30px 0px 58px;
`;

/**
 * Shared functionality for managing provider claim, create, and join
 * requests for new and existing users.
 */
class ProviderLookupContainer extends React.Component {
  static propTypes = {
    /** True if the modal should be open, false otherwise. Omit for uncontrolled modal */
    open: PropTypes.bool,
    /** Optional Callback for when the modal closes */
    onClose: PropTypes.func,
    /** Return a Promise when a provider claim is requested */
    onClaimProviders: PropTypes.func.isRequired,
    /** Return a Promise when a provider creation is requested */
    onCreateProvider: PropTypes.func.isRequired,
    /** A trigger for the modal. Omit for controlled modal */
    trigger: PropTypes.oneOfType([PropTypes.node, PropTypes.func, PropTypes.string]),
    /** The user, or at least first, last, and email */
    user: User,
    /** The list of providers to use by default */
    defaultSelection: PropTypes.arrayOf(Organization),
    /** The view to use by default */
    defaultView: PropTypes.oneOf(['claim', 'lookup']),
  }

  static defaultProps = {
    open: undefined,
    onClose: undefined,
    trigger: undefined,
    user: undefined,
    defaultSelection: undefined,
    defaultView: undefined,
  }

  static contextType = ThemeContext;

  constructor(props) {
    super(props);
    this.state = {
      view: 'lookup',
      claims: {
        providers: [],
        page: 0,
      },
    };
  }

  getTitleForView = () => {
    const { view } = this.state;

    if (view === 'confirmation') {
      const { confirmation: { title } = {} } = this.state;
      return title;
    }

    if (view === 'claims') {
      const { claims: { providers = [] } = {} } = this.state;
      return tr('claims.title', providers.length);
    }

    if (view === 'create') {
      return tr('create.title');
    }

    return tr('title');
  }

  validateAndSyncData = (skipValidation = false) => {
    const { view } = this.state;
    const { stepper: { form } = {} } = this;
    if (form) {
      return form.validate()
        .then((result) => {
          if (result || skipValidation) {
            if (view === 'claims') {
              const { claims: { page, providers = [] } = {} } = this.state;
              const updated = providers.slice();
              updated[page] = {
                ...providers[page],
                ...form.getValues(),
              };
              return {
                result,
                providers: updated,
              };
            }
            if (view === 'create') {
              return {
                result,
                provider: form.getValues(),
              };
            }
            return result;
          }
          return { result };
        });
    }
    return Promise.resolve({ result: false });
  }

  reset = () => {
    if (this.panel) { this.panel.reset(); }
  }

  focus = () => {
    if (this.panel) { this.panel.focus(); }
  }

  handleOpen = () => {
    const { defaultView, defaultSelection } = this.props;
    const view = defaultView === 'claims' && defaultSelection?.length ? defaultView : 'lookup';
    this.setState((oldState) => ({
      view,
      claims: {
        ...oldState.claims,
        hideBack: defaultView === 'claims' || oldState.hideBack,
        providers: defaultSelection || [],
      },
    }), () => {
      if (this.panel) {
        this.panel.reset();
        this.panel.focus();
      }
    });
  }

  handleRequestCreateProvider = () => {
    this.setState(({ create }) => ({
      view: 'create',
      create: {
        ...create,
        saving: false,
      },
    }));
  }

  handleSubmitClaims = () => {
    this.setState(({ claims }) => ({
      view: 'claims',
      claims: {
        ...claims,
        page: 0,
        saving: false,
      },
    }));
  }

  handleGoToPreviousClaim = () => {
    const { claims: { page } = {} } = this.state;

    if (page > 0) {
      const next = (providers) => {
        this.setState(({ claims }) => ({
          claims: {
            ...claims,
            providers,
            page: page - 1,
          },
        }));
      };

      this.validateAndSyncData(true).then(({ providers }) => {
        next(providers);
      });
    } else {
      this.setState({ view: 'lookup' });
    }
  }

  handleGoToNextClaim = () => {
    const next = (providers) => {
      this.setState(({ claims }) => ({
        claims: {
          ...claims,
          providers,
          page: claims.page + 1,
        },
      }));
    };
    this.validateAndSyncData().then(({ result, providers }) => {
      if (result) { next(providers); }
    });
  }

  handleSaveClaims = user => () => {
    const next = (updatedProviders) => {
      this.setState(({ claims }) => ({
        claims: {
          ...claims,
          providers: updatedProviders,
          saving: true,
        },
      }), () => {
        const { claims: { providers } } = this.state;
        this.props.onClaimProviders(user, providers).then(() => {
          this.setState({
            view: 'confirmation',
            claims: {
              providers: [],
            },
            confirmation: {
              title: tr('claims.title', providers.length),
              message: tr('claims.confirmation', providers.length),
            },
          });
        }).catch(() => {
          this.setState(({ claims }) => ({
            claims: {
              ...claims,
              saving: false,
            },
          }), () => {
            errorToast(tr('error.claims'));
          });
        });
      });
    };
    this.validateAndSyncData().then(({ result, providers }) => {
      if (result) { next(providers); }
    });
  }

  handleAddOrganizationToClaim = provider => Promise.resolve(provider)
    .then(() => {
      this.setState(({ claims }) => ({
        claims: {
          ...claims,
          providers: claims.providers.slice().concat(provider),
        },
      }));
    })

  handleRemoveOrganizationFromClaim = provider => Promise.resolve(provider)
    .then(() => {
      const { claims: { providers = [] } = {} } = this.state;
      const idx = providers.findIndex(({ id }) => provider.id === id);
      if (idx >= 0) {
        this.setState(({ claims }) => {
          const updated = claims.providers.slice();
          updated.splice(idx, 1);

          return {
            claims: {
              ...claims,
              providers: updated,
            },
          };
        });
      }

      return { update: false };
    });

  handleCreateProvider = user => () => {
    const next = (provider) => {
      this.setState(({ create }) => ({
        create: {
          ...create,
          provider,
          saving: true,
        },
      }), () => {
        this.props.onCreateProvider(user, provider).then(() => {
          this.setState({
            view: 'confirmation',
            create: {},
            confirmation: {
              title: tr('create.title'),
              message: tr('create.confirmation'),
              actions: ['add', 'search'],
            },
          });
        }).catch(() => {
          this.setState(({ create }) => ({
            create: {
              ...create,
              saving: false,
            },
          }), () => {
            errorToast(tr('error.create'));
          });
        });
      });
    };

    this.validateAndSyncData().then(({ result, provider }) => {
      if (result) { next(provider); }
    });
  }

  renderView = (close, user) => {
    const { view } = this.state;
    if (view === 'claims') {
      const { provider: iProvider, ...stepperProps } = this.state.claims;
      const providerDefaults = { ...iProvider };
      if (user) {
        providerDefaults.medical_director_first = user.first;
        providerDefaults.medical_director_last = user.last;
        providerDefaults.email = user.email;
      }
      return (
        <ClaimOrganizationStepper
          ref={(ref) => { this.stepper = ref; }}
          provider={providerDefaults}
          onNavigatePrevious={this.handleGoToPreviousClaim}
          onNavigateNext={this.handleGoToNextClaim}
          onSave={this.handleSaveClaims(user)}
          {...stepperProps}
        />
      );
    }

    if (view === 'create') {
      const { provider: iProvider, ...stepperProps } = this.state.create;
      const providerDefaults = { ...iProvider };
      if (user) {
        providerDefaults.medical_director_first = user.first;
        providerDefaults.medical_director_last = user.last;
        providerDefaults.email = user.email;
      }
      return (
        <CreateOrganizationStepper
          ref={(ref) => { this.stepper = ref; }}
          provider={providerDefaults}
          onNavigatePrevious={() => {
            this.validateAndSyncData(true).then(({ provider }) => {
              this.setState(({ create }) => ({
                view: 'lookup',
                create: {
                  ...create,
                  provider,
                },
              }));
            });
          }}
          onSave={this.handleCreateProvider(user)}
          {...stepperProps}
        />
      );
    }

    if (view === 'confirmation') {
      const { confirmation: { message, actions = [] } = {} } = this.state;
      return (
        <>
          <ConfirmationContainer>
            <Panel>
              <Content>
                <h4>{message}</h4>
              </Content>
              {actions && actions.length > 0 && (
                <ButtonBar align="center">
                  {actions.map(action => this.renderConfirmationAction(action))}
                </ButtonBar>
              )}
            </Panel>
          </ConfirmationContainer>
        </>
      );
    }

    const { claims: { providers = [] } = {} } = this.state;

    return (
      <Content>
        <ProviderLookupPanel
          ref={(ref) => { this.panel = ref; }}
          claims={providers}
          user={user}
          onAddOrganizationToClaim={this.handleAddOrganizationToClaim}
          onRemoveOrganizationFromClaim={this.handleRemoveOrganizationFromClaim}
          onSubmit={close}
          onSubmitClaims={this.handleSubmitClaims}
          onRequestCreateProvider={this.handleRequestCreateProvider}
        />
      </Content>
    );
  }

  renderConfirmationAction = (action) => {
    if (action === 'add') {
      return (
        <Button
          small
          color={this.context.theme.colors.RECEIVING_DARKER_FILL}
          onClick={this.handleRequestCreateProvider}
        >
          {tr('actions.add')}
        </Button>
      );
    }
    if (action === 'search') {
      return (
        <Button
          small
          onClick={() => {
            this.setState({
              view: 'lookup',
            });
          }}
        >
          {tr('actions.search')}
        </Button>
      );
    }
    return null;
  }

  render() {
    const {
      open,
      user,
      trigger,
    } = this.props;
    return (
      <Modal
        title={this.getTitleForView()}
        open={open}
        trigger={trigger}
        onOpen={this.handleOpen}
        onClose={this.props.onClose}
      >
        {({ close }) => (
          <Container>
            {this.renderView(close, user)}
          </Container>
        )}
      </Modal>
    );
  }
}

export default ProviderLookupContainer;

import React from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import styled from 'react-emotion';
import { withRouter } from 'react-router-dom';
import qs from 'query-string';

import { createTranslator } from 'helpers/i18n';
import apiDirector from 'helpers/ApiDirector';
import Notification from 'models/Notification';

import {
  Panel, Divider, Styles, Placeholder, Spinner,
} from 'components/utils';

import NotificationListItem from './NotificationListItem';

const { LinkText, Secondary } = Styles;

const PopupContent = styled.div`
  padding: 10px;
  position: relative;
`;

const PopupPanel = styled(Panel)`
  overflow-x: hidden;
  max-height: 280px;
`;

const SplitContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  bottom: -20px;
  width: 330px;
`;

const tr = createTranslator({
  links: {
    mark_read: 'Mark All as Read',
    load_more: 'Load More',
  },
  notifications: {
    empty: 'No new notifications. You\'re all caught up!',
  },
});

class NotificationList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      notifications: props.notifications,
      showLoadMore: props.notifications.length > 7,
    };
  }

  /* eslint-disable-next-line camelcase */
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.notifications !== this.props.notifications) {
      this.setState({ notifications: nextProps.notifications });
    }
  }

  handleOpenNotification = (notification) => {
    this.props.markNotificationAsRead(notification.id);
    switch (notification.notifiable_type) {
      case 'Referral':
        this.props.history.push(`/referrals/${notification.notifiable_id}`);
        break;
      case 'Message':
        this.props.requestMessageThreadById(notification.notifiable_id);
        break;
      case 'Conversation':
        this.props.requestConversationById(notification.notifiable_id);
        break;
      default:
        break;
    }
  }

  scrollToItem = (notification) => {
    if (notification && notification.id) {
      document.querySelector(`#notification-list-item-${notification.id}`).scrollIntoView();
      window.scrollTo(0, 0);
    }
  }

  loadMoreNotifications = () => {
    const start = this.state.notifications[this.state.notifications.length - 1].id;
    const query = qs.stringify({ start, count: 10 });
    this.setState({ loading: true }, () => {
      apiDirector.validateFetch(`/api/notifications?${query}`)
        .then(({ list = [] } = {}) => {
          this.setState(({ notifications }) => ({
            notifications: notifications.concat(list),
            loading: false,
            showLoadMore: list.length > 0,
          }), () => { this.scrollToItem(list[0]); });
        });
    });
  }

  render() {
    const { notifications = [] } = this.state;
    return (
      <PopupContent>
        <PopupPanel id="notification_list" spacing={14}>
          <div>
            {notifications.map(notification => (
              <div key={notification.id} id={`notification-list-item-${notification.id}`}>
                <NotificationListItem
                  notification={notification}
                  onClick={() => {
                    this.props.close();
                    this.handleOpenNotification(notification);
                  }}
                />
                <Divider margin={0} />
              </div>
            ))}
            {!notifications.length > 0 && (
              <Placeholder fullwidth height={280}>
                <Panel>
                  <Secondary>
                    {tr('notifications.empty')}
                  </Secondary>
                </Panel>
              </Placeholder>
            )}
          </div>
        </PopupPanel>
        {notifications && notifications.length > 0 && (
          <SplitContainer>
            <LinkText onClick={() => { this.props.resetUnreadCount(); this.props.close(); }}>
              {tr('links.mark_read')}
            </LinkText>
            <>
              {this.state.loading && <Spinner small width={25} />}
              {this.state.showLoadMore && (
                <LinkText onClick={() => { this.loadMoreNotifications(); }}>
                  {tr('links.load_more')}
                </LinkText>
              )}
            </>
          </SplitContainer>
        )}
      </PopupContent>
    );
  }
}
NotificationList.propTypes = {
  /** Close handler for the wrapping popup */
  close: PropTypes.func.isRequired,
  /** Marks individual notification as read */
  markNotificationAsRead: PropTypes.func.isRequired,
  /** List of notifications to display */
  notifications: PropTypes.arrayOf(Notification).isRequired,
  /** From withRouter, to push routes to the stack */
  history: ReactRouterPropTypes.history.isRequired,
  /** Open a message */
  requestMessageThreadById: PropTypes.func.isRequired,
  /** Open a conversation */
  requestConversationById: PropTypes.func.isRequired,
  /** Resets the Unread Notification Count to 0 */
  resetUnreadCount: PropTypes.func.isRequired,
};

export default withRouter(NotificationList);

export const TOGGLE_GENERIC_MODAL = 'ui/TOGGLE_GENERIC_MODAL';

export const TOGGLE_PROVIDER_SEARCH_CONTACT_INFO_MODAL = 'ui/toggle/providerSearchContactInfoModal';
export const TOGGLE_PROVIDER_PREFERENCES_MODAL = 'ui/toggle/providerPreferencesModal';
export const TOGGLE_PROVIDER_MISSING_BADGE_MODAL = 'ui/toggle/providerMissingBadgeModal';
export const TOGGLE_NEW_PROVIDER_MODAL = 'ui/toggle/newProviderModal';
export const TOGGLE_PASSWORD_CHANGE_MODAL = 'ui/toggle/passwordChangeModal';
export const TOGGLE_FEDRAMP_MODAL = 'ui/toggle/fedrampModal';
export const TOGGLE_BADGES_CHECKUP_MODAL = 'ui/toggle/badgesCheckupModal';
export const TOGGLE_IDENTITY_VERIFICATION_MODAL = 'ui/toggle/identityVerificationModal';
export const TOGGLE_YEAR_END_REPORT_MODAL = 'ui/toggle/yearEndReportModal';
export const TOGGLE_NEW_DOCUMENT_MODAL = 'ui/toggle/newOutboundDocumentModal';
export const TOGGLE_PDPMCALCULATOR_MODAL = 'ui/TOGGLE_PDPMCALCULATOR_MODAL';
export const TOGGLE_CREATE_REFERRAL_CONFLICT_MODAL = 'ui/toggle/createReferralConflictModal';
export const TOGGLE_UPDATE_REFERRAL_CONFLICT_MODAL = 'ui/toggle/updateReferralConflictModal';
export const TOGGLE_CONFIRM_SIGNATURE_MODAL = 'ui/toggle/confirmSignatureModal';
export const UPDATE_PURCHASE_MODAL = 'ui/UPDATE_PURCHASE_MODAL';
export const TOGGLE_SOFTWARE_UPDATE_MODAL = 'ui/toggle/softwareUpdate';
export const TOGGLE_SEND_TO_PROVIDERS_MODAL = 'ui/toggle/sendToProviders';
export const TOGGLE_RECEIVER_PURCHASEABLE_MODAL = 'ui/toggle/receiverPurchaseableModal';
export const TOGGLE_ADVANCED_MESSAGE_SEARCH_MODAL = 'ui/toggle/advancedMessageSearchModal';
export const TOGGLE_ATTACHMENT_VIEWER = 'ui/toggle/attachmentViewer';
export const TOGGLE_NEW_HOSPITAL_MODAL = 'ui/toggle/newHospitalModal';
export const TOGGLE_NEW_REFERRAL_MODAL = 'ui/toggle/newReferralModal';
export const TOGGLE_NEW_PATIENT_MODAL = 'ui/toggle/newPatientModal';
export const TOGGLE_MESSAGE_MULTIPLE_PROVIDERS_MODAL = 'ui/toggle/messageMultipleProvidersModal';
export const TOGGLE_EDIT_ATTACHMENT_MODAL = 'ui/toggle/editAttachmentModal';
export const TOGGLE_ATTENTION_ALERT_MODAL = 'ui/toggle/attentionAlert';
export const TOGGLE_DOCUMENT_WARNING_MODAL = 'ui/toggle/documentWarningModal';
export const TOGGLE_PASSWORD_CONFIRMATION_MODAL = 'ui/toggle/passwordConfirmationModal';
export const TOGGLE_DOCUMENT_RESPONSE_MODAL = 'ui/toggle/documentResponseModal';
export const TOGGLE_CONVERSATION_MODAL = 'ui/toggle/conversationModal';
export const TOGGLE_EDIT_PROVIDER_SETTINGS = 'ui/toggle/editProviderSettings';
export const TOGGLE_SYSTEM_ALERT = 'ui/toggle/systemAlert';
export const TOGGLE_REQUEST_ALREADY_EXISTS = 'ui/toggle/requestAlreadyExists';
export const TOGGLE_ENTER_PIN_MODAL = 'ui/toggle/enterPinModal';
export const TOGGLE_SCRAPEABLE_DETAILS = 'ui/toggle/scrapeableDetails';
export const TOGGLE_FULL_FAX_DISABLED_CONFLICT_MODAL = 'ui/toggle/fullFaxDisabledConflictModal';
export const TOGGLE_JOIN_ORGANIZATION_MODAL = 'ui/toggle/joinOrganizationModal';
export const TOGGLE_RECEIVING_RESPONSE_MODAL = 'ui/toggle/receivingResponseModal';
export const TOGGLE_DENY_REFERRAL_MODAL = 'ui/toggle/denyReferralModal';
export const TOGGLE_DECLINE_REASON_MODAL = 'ui/toggle/declineReasonModal';
export const TOGGLE_RECEIVING_AUTH_RESPONSE_MODAL = 'ui/toggle/receivingAuthResponseModal';
export const TOGGLE_RECEIVING_OUTPATIENT_RESPONSE_MODAL = 'ui/toggle/receivingOutpatientResponseModal';
export const TOGGLE_SEND_TO_EPIC_MODAL = 'ui/toggle/sendToEpicModal';
export const TOGGLE_GENERATE_FORM_INVITE_MODAL = 'ui/toggle/generateFormInviteModal';
export const TOGGLE_PAYOR_CONFLICT_MODAL = 'ui/toggle/payorConflictModal';
export const TOGGLE_INBOUND_RESPONSE_REVIEW_PROCESSING_MODAL = 'ui/toggle/responseReviewProcessingModal';
export const TOGGLE_INBOUND_RESPONSE_REVIEW_LIST_MODAL = 'ui/toggle/responseReviewListModal';
export const TOGGLE_ATTACH_UNASSIGNED_DOCUMENTS_TO_PATIENT_MODAL = 'ui/toggle/attachUnassignedDocumentsToPatientModal';
export const TOGGLE_COMPLETE_NON_EMERGENCY_MEDICAL_TRANSPORT_MODAL = 'ui/toggle/completeNonEmergencyMedicalTransportModal';
export const TOGGLE_CURRENT_VIEWER_CONFIRM_MODAL = 'ui/toggle/currentViewerConfirmModal';
export const TOGGLE_TURN_OFF_FAXES_MODAL = 'ui/toggle/turnOffFaxesModal';
export const TOGGLE_FOLLOWING_PREFERENCES_MARKETING_MODAL = 'ui/toggle/followingPreferencesMarketingModal';
export const TOGGLE_REQUEST_DUPLICATION_WARNING_MODAL = 'ui/toggle/requestDuplicationWarningModal';
export const ATTACHMENTS_DOWNLOAD_MODAL = 'ui/toggle/attachmentsDownloadModal';

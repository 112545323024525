/* eslint-disable import/prefer-default-export, camelcase */

import apiDirector from 'helpers/ApiDirector';
import * as actions from 'ducks/conversations/actions';

export const advancedMessageSearch = query => dispatch => apiDirector.validateFetch('/api/conversations/inbox/search', {
  body: JSON.stringify(query),
  method: 'POST',
}).then(json => dispatch(actions[query.offset ? 'appendToInbox' : 'replaceInbox'](json)));

export const refreshInboxList = (count, filter = 'all') => dispatch => apiDirector.validateFetch(`/api/conversations/inbox?page=0&limit=${count}&stats=true&filter=${filter}`)
  .then(json => dispatch(actions.replaceInbox(json)));

export const requestInboxList = (count, filter = 'all', offset, withStats) => dispatch => apiDirector.validateFetch(`/api/conversations/inbox?page=${offset}&limit=${count}&filter=${filter}${withStats ? '&stats=true' : ''}`)
  .then(json => dispatch(actions.appendToInbox(json)));

export const mergeInboxList = (status, conversations) => dispatch => (
  Promise.resolve(dispatch(actions.mergeInbox(status, conversations)))
);

export const updateConversationMembership = (payload) => () => (
  apiDirector.validateFetch('/api/conversations/inbox/membership', {
    method: 'POST',
    body: JSON.stringify({
      membership: {
        membership_attributes: payload,
      },
    }),
  })
);

export const batchUpdateMessages = (messages, operation, options = {}) => () => apiDirector.validateFetch('/api/conversations/inbox/batch', {
  method: 'POST',
  body: JSON.stringify({
    batch_update_conversations_request: {
      ...options,
      messages,
      operation,
    },
  }),
});

const icons = {};
try {
  const context = require.context('./', true, /\.svg$/);
  context.keys().forEach((file) => {
    const Icon = context(file).default;
    const label = file.slice(2, -4); // strip './provider-' and '.js'
    icons[label] = Icon;
    return icons;
  }, {});
} catch (e) {
  // TODO: Add mocks to support this in a testing environment
}

export default icons;

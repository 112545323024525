/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion';
import Link from 'components/utils/Link';
import withAppcues from 'components/utils/Appcues';
import { mq } from 'components/utils/Responsive';
import { applyTestIdentifier } from 'helpers/development';
import { numeric } from 'helpers/i18n';
import { ThemeContext } from 'components/utils/ThemeContext';

const baseStyles = {
  Tab: styled.div(props => mq({
    marginRight: props.align ? ['10px', '10px', '5px'] : ['15px', '15px', '5px'],
    height: ['27px', '25px', 'calc(100% - 6px)'],
    backgroundColor: '#e9e9e9',
    boxShadow: '0 0 3px 0 rgba(51, 51, 51, 0.24)',
    paddingLeft: ['10px', '7px', '7px'],
    paddingRight: ['10px', '7px', '7px'],
    paddingTop: ['5px', '6px', '6px'],
    '-webkit-font-smoothing': 'antialiased',
    color: '#333333',
    display: props.align && 'flex',
    alignItems: props.align,
  })),
};

const Icon = styled.div(mq({
  marginLeft: ['5px', '5px', 'auto'],
  marginRight: ['', '', 'auto'],
  display: ['inline-block', 'inline-block', 'block'],
  verticalAlign: 'bottom',
  width: ['21px', '14px', '16px'],
  height: '21px',
}));

const createComponentStyles = ({ version, theme: { colors } }) => numeric(version, {
  other: {
    styles: {
      ...baseStyles,
      TabsContainer: styled.div(mq({
        display: 'flex',
        fontFamily: 'futura-pt',
        fontWeight: 'bold',
        marginTop: '20px',
        fontSize: ['17px', '17px', '15px'],
        marginLeft: ['0px', '0px', '-11px'],
        'a:link': {
          textDecoration: 'none',
        },
        'a:last-child div': {
          marginRight: 0,
        },
      })),
      ActiveTab: styled(baseStyles.Tab)`
        background-color: ${colors.DARKER_GRAY_FILL};
        color: ${colors.WHITE};
        cursor: default;
      `,
    },
  },
  v2: {
    styles: {
      TabsContainer: styled.div(mq({
        display: 'flex',
        fontWeight: 'bold',
        marginTop: '20px',
        fontSize: ['14px', '14px', '13px'],
        marginLeft: ['0px', '0px', '-11px'],
        'a:link': {
          textDecoration: 'none',
        },
      })),
      Tab: styled.div(mq({
        display: 'flex',
        alignItems: 'flex-start',
        marginRight: ['0px', '0px', '0px'],
        height: ['32px', '32px', 'calc(100% - 6px)'],
        backgroundColor: colors.WHITE,
        boxShadow: 'unset',
        paddingLeft: ['20px', '20px', '7px'],
        paddingRight: ['20px', '20px', '7px'],
        paddingTop: ['12px', '12px', '6px'],
        '-webkit-font-smoothing': 'antialiased',
        color: colors.DARKER_GRAY_FILL,
        fontWeight: 'normal',
      })),
      ActiveTab: styled(baseStyles.Tab)(mq({
        display: 'flex',
        alignItems: 'center',
        backgroundColor: colors.MEDIUM_DARK_GRAY_FILL,
        color: colors.BLACK,
        cursor: 'default',
        boxShadow: 'unset',
        marginRight: ['0px', '0px', '0px'],
        height: ['32px', '32px', 'calc(100% - 6px)'],
        paddingLeft: ['20px', '20px', '7px'],
        paddingRight: ['20px', '20px', '7px'],
        paddingBottom: ['12px', '12px', '6px'],
        fontWeight: 'bold',
      })),
    },
  },
});

const getTabType = (item, active, ActiveTab, Tab) => (item.path === active ? ActiveTab : Tab);

/**
 * Create a horizontal bar of linked tabs. Each tab should provide
 * a path to link to and text to display in the tab. While a string
 * is expected, any node can be used here. Tabs are set as active
 * when the path of the tab matches the active property, which is
 * expected to be the current location, but can be anything.
 */
const Tabs = props => {
  const themeInfo = useContext(ThemeContext);
  const { version: v } = themeInfo;
  const {
    styles: {
      TabsContainer,
      Tab,
      ActiveTab,
    },
  } = useMemo(() => createComponentStyles(themeInfo), [v]);
  return (
    <TabsContainer>
      {props.links.map((link) => {
        const Tag = getTabType(link, props.active, ActiveTab, Tab);
        const AppcuesLink = withAppcues(Link, {
          id: link.id,
        });
        return (
          <AppcuesLink key={link.path} to={link.path} {...applyTestIdentifier(link.path.split('/').filter(Boolean).join('-'))}>
            <Tag align={props.align}>
              {link.leftIcon ? link.leftIcon : ''}
              {link.text}
              {link.icon ? <Icon>{link.icon}</Icon> : ''}
            </Tag>
          </AppcuesLink>
        );
      })}
    </TabsContainer>
  );
};

Tabs.propTypes = {
  /** The path of the active tab */
  active: PropTypes.string.isRequired,
  /** Links to display in the tab listing */
  links: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    icon: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
    id: PropTypes.string,
    leftIcon: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  })).isRequired,
  align: PropTypes.oneOf(['top', 'left', 'center', 'right', 'bottom', 'baseline']),
};

Tabs.defaultProps = {
  align: undefined,
};

export default Tabs;

/* eslint-disable no-plusplus */
import { createTranslator, numeric } from 'helpers/i18n';
import { confirmMessages } from 'translations/actions';
import { Date } from 'helpers/dates';

export const text = createTranslator({
  attachments: {
    list: {
      name: ({ printLocale } = {}) => numeric(printLocale, {
        es: 'Patient Choice PDF (Spanish)',
        other: 'Patient Choice PDF',
      }),
    },
    contacted: {
      name: 'Contacted Providers PDF',
    },
    patientChoice: {
      name: 'Patient Choice Confirmation Form',
    },
    patientDisseminationChoice: {
      name: 'Patient Dissemination Choice Confirmation Form',
    },
    dissemination: {
      name: 'Patient Dissemination PDF',
    },
  },
});

export const getWebServices = (referral) => {
  const {
    hospital: {
      hospital_system: {
        web_services: webServices = [],
      } = {},
    } = {},
  } = referral;
  return webServices;
};

export const findWebService = (referralOrWebServices, service) => {
  const webServices = Array.isArray(referralOrWebServices)
    ? referralOrWebServices
    : getWebServices(referralOrWebServices);
  return webServices.find(({
    metadata: {
      supportedDocumentTypes = [],
    } = {},
  }) => supportedDocumentTypes.includes(service));
};

export const getActionConfirmationText = (referral, action, completedActionDetails) => {
  const tr = createTranslator(confirmMessages);
  return tr(`${referral.referralRole}.${action}`, referral, completedActionDetails);
};

export const intakeOverdueAt = (referral) => {
  if (referral.referralResponse && referral.referralResponse.tasks) {
    const receivePatientTask = referral.referralResponse.tasks.find(t => t.task_type === 'receive_patient' && !t.completed_at);
    if (receivePatientTask && receivePatientTask.overdue_at) {
      return receivePatientTask.overdue_at;
    }
  }
  if (referral.start_of_care) {
    return referral.start_of_care;
  }
  if (referral.discharge_date) {
    return referral.discharge_date;
  }
  return null;
};

export const isReferralDoc = (referral, name) => {
  const { checklist_configuration: { category, name: configName } = {} } = referral;
  return category === 'doc' && (!name || name === configName);
};

/** Will Probably need modification in future cases */
export const getTextForChecklist = (referral, oracle) => {
  const {
    checklist_configuration: config,
    provider_type: type,
  } = referral || {};
  const {
    category,
    name,
  } = config || {};
  return numeric(category, {
    doc: numeric(name, {
      sign: numeric('sign', oracle),
      doc: numeric('doc', oracle),
    }),
    other: numeric(type, {
      auth: numeric('auth', oracle),
      other: numeric('provider', oracle),
    }),
  });
};

/** Renders the correct landing page when a user opens a referral or goes to /referrals/{id} */
export const getLandingPage = (referral, options = {}) => {
  const { isNew, path } = options;
  if (path) {
    return `/referrals/${referral.id}/${path}`;
  }
  if (isReferralDoc(referral)) {
    return `/referrals/${referral.id}/actions`;
  }
  if (isNew || referral.referralRole === 'receiving' || isReferralDoc(referral)) {
    return `/referrals/${referral.id}/details`;
  }
  return `/referrals/${referral.id}/actions`;
};

export const getPrecertChildReferral = (referral) => {
  const { id, child_referral_relationships: childReferralRelationships = [] } = referral;
  return childReferralRelationships.find(c => c.identifier === 'auth-pre-cert' && c.parent_id === id);
};

export const filterExpiredInsurances = (insurances = [], patientVisit = {}) => {
  const { admission } = patientVisit || {};
  if (!admission) {
    return insurances;
  }
  const admissionDate = Date.parse(admission).format('YYYY-MM-DD');
  return insurances.filter(insurance => (
    !insurance.expirationDate || insurance.expirationDate >= admissionDate
  ));
};

const isProviderNetworkHighlighted = (network, role) => {
  // check for network presence + highlighting rules based on role & return boolean
  if (!network) { return false; }
  const { highlighted_type: highlightedType } = network;
  if (role === 'sending') {
    return highlightedType === 'hospital_facility' || highlightedType === 'both_facilities';
  }
  if (role === 'receiving') {
    return highlightedType === 'receiving_facility' || highlightedType === 'both_facilities';
  }
  return false;
};

export const getProviderNetworkIndicatorForRole = (network, supportedRoles = ['sending', 'receiving'], component) => {
  if (!supportedRoles.some(r => isProviderNetworkHighlighted(network, r))) { return null; }
  return component;
};

export const responseChecks = {
  notes: item => !!item.response_notes,
  document: item => !!item.response_attachment,
  signature: item => !!item.response_attachment,
  patient_response: item => !!item.response_attachment,
  form_completion: item => !!item.response_attachment,
};

export const getDocumentStats = (tasks = []) => {
  const responses = tasks.map(task => task.document_responses).flat();
  let count = 0;
  let total = 0;
  responses.forEach(item => {
    total++;
    if (!responseChecks[item.request_type] || responseChecks[item.request_type](item)) {
      count++;
    }
  });
  return { count, total };
};

/*
  This function dertemines which providers have not been sent a referral
  based on the results of a provider search.
  It returns an array of provider ids that have not been sent a referral.
*/
export const getUnsentProvidersIds = (referral, results, mapping) => {
  let unsent = [];
  if (results.length > 0) {
    const confirmed = referral.sent_referral_index.filter(sr => sr.confirmed);
    unsent = results
      .filter(psr => psr.checked && !confirmed.find(sr => sr.provider_id === psr.provider_id))
      .map(mapping);
  } else {
    // Aidin 2, search never generated.
    unsent = referral.sent_referral_index
      .filter(sr => !sr.confirmed && !sr.removed_at)
      .map(p => p.provider.id);
  }
  return unsent;
};

const createProtectedDocument = ({ id, url, name }, options) => ({
  id,
  url,
  name,
  protected: true,
  generated: true,
  ...options,
});

export const getAttachmentConfiguration = (key, referral, options = {}) => numeric(key, {
  contactedProvidersPDF: createProtectedDocument({
    id: 'generated-contacted',
    url: `/referrals/${referral.id}/pdfs/contacted`,
    name: text('attachments.contacted.name'),
  }, options),

  patientChoicePDF: createProtectedDocument({
    id: 'generated-list',
    url: `/referrals/${referral.id}/pdfs/list`,
    name: text('attachments.list.name'),
  }, options),

  patientChoicePDFSpanish: createProtectedDocument({
    id: 'generated-list?printLocale=es',
    url: `/referrals/${referral.id}/pdfs/list?printLocale=es`,
    name: text('attachments.list.name', { printLocale: 'es' }),
  }, options),

  patientDisseminationPDF: createProtectedDocument({
    id: 'generated-dissemination',
    url: `/referrals/${referral.id}/pdfs/dissemination`,
    name: text('attachments.dissemination.name'),
  }, options),

  other: undefined,
});

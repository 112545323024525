/* eslint-disable import/prefer-default-export */

export const providerTypes = {
  ALF: 'Assisted Living',
  DAR: 'Drug Rehab',
  DME: 'Durable Medical Equipment',
  HHA: 'Home Health Agency',
  HOSP: 'Hospice',
  INF: 'Infusion',
  PSYC: 'Inpatient Psych',
  IRF: 'Inpatient Rehab Facility / ARU',
  LTAC: 'Long Term Acute Care Hospital',
  DIAL: 'Outpatient Dialysis',
  OUTPSYC: 'Outpatient Psych',
  PCP: 'Primary Care Physician',
  PAL: 'Palliative',
  PD: 'Private Duty Agency',
  SNF: 'Skilled Nursing Facility / Sub-acute',
  TRNS: 'Transportation',
  ACUTE: 'Hospital / Acute Care',
  RECUPE: 'Recuperative Care',
  INHOSP: 'Inpatient Hospice',
  AUTH: 'Authorization',
  PT: 'Physical Therapy / Day Rehab',
  GENERAL: 'General',
  NEMT: 'Non-Emergency Medical Transport',
  PTSERV: 'Patient Services',
};

/* eslint-disable react/no-array-index-key */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion';

const justify = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end',
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${props => justify[props.align] || 'flex-start'};
  ${props => props.wrap === 'wrap' && 'flex-wrap: wrap;'}
`;

// IE10, IE11: max-width: 100% is a workaround for Flexbug #2
// (https://github.com/philipwalton/flexbugs)
const Item = styled.div`
  max-width: 100%;
  margin-right: ${props => props.margin || 0}px;
  ${props => props.wrap === 'wrap' && 'margin-bottom: 20px;'}
  > h1, > h2, > h3, > h4 {
    margin-top: 0px;
    margin-bottom: 0px;
  };
`;

/**
 * Display buttons in a horizontal row, 20px apart
 */
const ButtonBar = props => (
  <Container align={props.align} className={props.className} wrap={props.wrap ? 'wrap' : null}>
    {React.Children.map(props.children, item => item).filter(item => !!item)
      .map((item, index, list) => (
        <Item
          key={index}
          margin={list.length > 0 && index < list.length - 1 ? props.spacing : 0}
          wrap={props.wrap ? 'wrap' : null}
        >
          {item}
        </Item>
      ))}
  </Container>
);
ButtonBar.propTypes = {
  /** Align items left, center, or right */
  align: PropTypes.oneOf(['left', 'center', 'right']),
  /** Optional class name */
  className: PropTypes.string,
  /** The button items to display (really can be any item you want) */
  children: PropTypes.node.isRequired,
  /** The spacing between items. Default 20 */
  spacing: PropTypes.number,
  /** True to wrap buttons, false otherwiese. Will add 10px vertical padding to each line. */
  wrap: PropTypes.bool,
};
ButtonBar.defaultProps = {
  align: 'left',
  className: undefined,
  spacing: 20,
  wrap: undefined,
};

export default ButtonBar;

/* eslint-disable jsx-a11y/interactive-supports-focus,jsx-a11y/click-events-have-key-events */

import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import * as actions from 'ducks/session';

import withFeatureFlagRequired from 'components/utils/withFeatureFlagRequired';

// this SVG is copied from Zendesk Messenger product
const ZendeskChatIcon = ({ activateZendeskModal }) => (
  <div
    role="button"
    css="
      tabindex: 0;
      cursor: pointer;
      fill: white;
      position: fixed;
      bottom: 12px;
      right: 90px;
      height: 24px;
      width: 24px;
      border-radius: 50%;
      z-index: 1;
      padding: 10px;
      background: #4379ff;
    "
    onClick={(e) => activateZendeskModal(e, 'bottom')}
  >
    <svg
      position="left"
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
    >
      <path
        d="M10,18 L6,22 L6,18 L10,18 Z M17,6 C19.7614237,6 22,8.23857625 22,11 C22,13.7614237 19.7614237,16 17,16 L17,16 L7,16 C4.23857625,16 2,13.7614237 2,11 C2,8.23857625 4.23857625,6 7,6 L7,6 Z"
        transform="translate(12.000000, 14.000000) scale(-1, 1) translate(-12.000000, -14.000000) "
      />
    </svg>
  </div>
);
ZendeskChatIcon.propTypes = {
  activateZendeskModal: PropTypes.func.isRequired,
};

export default compose(
  withFeatureFlagRequired('feature/zendesk-chatbot', React.Fragment),
  connect(null, actions),
)(ZendeskChatIcon);

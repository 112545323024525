import initialState from 'store/initialState';
import * as types from './types';

const shapeCareTypes = items => {
  const allExceptAuth = items.filter(({ provider_type: pt }) => pt !== 'auth');
  const generatedAuth = allExceptAuth.reduce((sum, part) => sum.concat({
    ...part,
    provider_type: 'auth',
    category: part.provider_type.toUpperCase(),
    priority: 0,
  }), []);
  return allExceptAuth.concat(generatedAuth);
};

const system = (state = initialState.system, action = {}) => {
  switch (action.type) {
    case types.REPLACE_SYSTEM: {
      const { care_types: careTypes, ...data } = action.system;
      return {
        ...data,
        care_types: shapeCareTypes(careTypes),
      };
    }
    default:
      return state;
  }
};

export default {
  system,
};

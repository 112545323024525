/* eslint-disable react/no-unused-prop-types, no-nested-ternary */

import React from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import Select from 'react-select';
import { css, cx } from 'emotion';
import styled from 'react-emotion';

import {
  SelectFieldContainer, selectStyle, optionStyle, getCommonStyles,
} from 'components/utils/SelectField';
import SplitPanel from 'components/utils/SplitPanel';
import Icon from 'components/utils/Icon';
import HOC from 'components/utils/Field';
import ViewContext from 'components/utils/ViewContext';
import { createRegex } from 'helpers/formatters';

import * as colors from 'components/utils/Colors';

import withComponentTheme from 'components/utils/withComponentTheme';
import { ThemeContext } from 'components/utils/ThemeContext';

const { BODY_FILL, TEXT_ERROR } = colors;

const createComponentStyles = () => {
  const commonStyles = getCommonStyles();
  return {
    ...commonStyles,
    v1: {
      ...commonStyles.v1,
      styles: {
        ...commonStyles.v1.styles,
        Sizes: {
          medium: {
            height: 34,
            lineHeight: '30px',
            fontSize: 15,
          },
          large: {
            height: 44,
            lineHeight: '44px',
            fontSize: 20,
          },
        },
      },
    },
    v2: {
      ...commonStyles.v2,
      styles: {
        ...commonStyles.v2.styles,
        Sizes: {
          medium: {
            height: 36,
            lineHeight: '36px',
            fontSize: 15,
          },
          large: {
            height: 44,
            lineHeight: '44px',
            fontSize: 20,
          },
        },
      },
    },
  };
};

const DisplayValueStyle = styled.div`
  margin-left: 10px;
  margin-top: 10px;
`;

/**
 * Default item list filter
 */
export const SimpleFilter = (item, value) => {
  if (!value) { return false; }
  try {
    const check = new RegExp(`.*${value}.*`, 'i');
    const text = typeof item === 'string' ? item : item.label || item.text || item.valueText;
    return check.test(text);
  } catch (e) {
    return false;
  }
};

/**
 * Pass a list of property names to check; if the supplied property
 * matches the input, then the item is displayed.
 */
export const PropertyFilter = (...props) => (item, value) => {
  if (!value) { return false; }
  try {
    const check = createRegex(value);
    return props.reduce((result, prop) => result || check.test(item[prop]), false);
  } catch (e) {
    return false;
  }
};

/**
 * Do not to any additional filtering; assume all filtering is handled
 * elsewhere, such as with a server-side search.
 */
export const NoFilter = (item, value) => !!value;

class AutocompleteField extends React.Component {
  static contextType = ThemeContext;

  constructor(props) {
    super(props);
    this.state = { value: props.value };
    this.input = '';
    this.inputListener = debounce(this.handleNotifyInput, 500);
    this.control = null;
    this.open = false;
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.value !== nextProps.value) {
      this.setState({ value: nextProps.value });
    }
  }

  handleFilter = (options, filter, currentValues) => {
    if (filter) {
      return options.filter(item => this.props.filter(item, filter, currentValues));
    }
    return this.props.defaultUnfiltered ? options : [];
  }

  handleChange = (option) => {
    const value = option && !this.props.resetValueOnChange ? option[this.props.valueKey] : null;
    this.setState({ value }, () => {
      if (this.props.onSelect) {
        this.props.onSelect(option);
      }
    });
  }

  handleInput = (value) => {
    this.input = value;
    this.inputListener();
    return value;
  }

  handleNotifyInput = () => {
    if (typeof this.input === 'string') {
      this.props.onInputChange(this.input);
    }
  }

  handleEnterKeyPress = (e) => {
    if (this.open) {
      if (this.props.onInputKeyDown) {
        this.props.onInputKeyDown(e);
      }
    } else if (e.keyCode === 13 || e.key === 'Enter') {
      e.preventDefault();
      this.props.onSubmit();
    } else if (this.props.onInputKeyDown) {
      this.props.onInputKeyDown(e);
    }
  }

  handleOpen = () => {
    this.open = true;
    if (this.props.onOpen) { this.props.onOpen(); }
  }

  handleClose = () => {
    this.open = false;
    if (!this.props.autoBlur) {
      setTimeout(() => this.focus(), 100);
    }
    if (this.props.onClose) { this.props.onClose(); }
  }

  /**
   * Close the input
   *
   * @public
   */
  close = () => {
    if (this.control) {
      this.control.closeMenu();
    }
  }

  /**
   * Manually focus the AutocompleteField input
   *
   * @public
   */
  focus = () => {
    if (this.control) {
      this.control.focus();
    }
  }

  /**
   * Manually blur the AutocompleteField input
   *
   * @public
   */
  blur = () => {
    if (this.control) {
      this.control.blurInput();
    }
  }

  indentValue = indent => (typeof indent === 'number' ? `${indent}px` : '20px');

  indentedInputProps = indent => ({
    className: css`
      input {
        text-indent: ${this.indentValue(indent)}
      }
    `,
  })

  indentedSelectStyle = indent => css`
    .Select-value, .Select-placeholder {
      margin-left: ${this.indentValue(indent)}
    }
  `;

  render() {
    const {
      autoBlur, autoOpen, defaultBorderColor, disabled, displayValue, empty, items, itemRenderer,
      highlight, onInputChange, onInputKeyDown, onSubmit, maxHeight,
      indent, placeholder, required, size, valueKey, valueRenderer,
      creatable, clearable,
    } = this.props;
    const { theme: { styles, styles: { versionBaseStyle } } } = this.context;
    const SelectTag = creatable ? Select.Creatable : Select;
    let creatableProps = {};
    if (creatable) {
      creatableProps = {
        promptTextCreator: filterText => (typeof empty === 'function' ? empty(filterText) : empty),
        showNewOptionAtTop: false,
        shouldKeyDownEventCreateNewOption: ({ keyCode }) => keyCode === 13,
        newOptionCreator: ({ label, labelKey, valueKey: newValueKey }) => ({
          [labelKey]: label,
          [newValueKey]: 0,
        }),
      };
    }
    return (
      <ViewContext.Consumer>
        {({ readOnly }) => !readOnly && (
          <>
            <SelectTag
              ref={(ref) => { this.control = ref ? creatable ? ref.select : ref : null; }}
              autoBlur={autoBlur}
              arrowRenderer={null}
              className={cx(css`
                ${selectStyle(styles.Sizes[size] || styles.Sizes.medium, highlight && this.state.value, styles)}
                ${indent ? this.indentedSelectStyle(indent) : ''}
                .Select-control {
                  border: 1px solid ${this.props.errors ? TEXT_ERROR : defaultBorderColor}
                }
                .Select-menu, .Select-menu-outer {
                  max-height: ${maxHeight};
                };
              `, versionBaseStyle)}
              clearable={false}
              disabled={disabled}
              filterOptions={this.handleFilter}
              inputProps={indent ? this.indentedInputProps(indent) : undefined}
              noResultsText={empty}
              onChange={this.handleChange}
              onNewOptionClick={this.handleChange}
              onInputChange={onInputChange ? this.handleInput : undefined}
              onInputKeyDown={onSubmit ? this.handleEnterKeyPress : onInputKeyDown}
              onOpen={this.handleOpen}
              onClose={this.handleClose}
              openOnClick={autoOpen}
              optionClassName={optionStyle}
              optionRenderer={itemRenderer}
              options={items}
              placeholder={placeholder}
              required={required}
              value={displayValue ? null : this.state.value}
              valueKey={valueKey}
              valueRenderer={valueRenderer}
              {...creatableProps}
            />
            {displayValue && (
              <DisplayValueStyle>
                {clearable ? (
                  <SplitPanel>
                    {displayValue}
                    {<Icon name="cancel-alt" onClick={() => { this.handleChange(null); }} />}
                  </SplitPanel>
                ) : displayValue}
              </DisplayValueStyle>
            )}
          </>
        )}
      </ViewContext.Consumer>
    );
  }
}

AutocompleteField.propTypes = {
  /** Blur after a selection has been made. */
  autoBlur: PropTypes.bool,
  /** Automatically open the options list when field is clicked (default false) */
  autoOpen: PropTypes.bool,
  /** Optional class name for the field wrapper */
  className: PropTypes.string,
  /** Allows for clearable icon to be displayed next to underlying message */
  clearable: PropTypes.bool,
  /** Optional: allow for creation of items within this field. New options have an id of 0 */
  creatable: PropTypes.bool,
  /** Default border color; defaults to transparent */
  defaultBorderColor: PropTypes.oneOf([BODY_FILL, 'transparent']),
  /** True to default the items list as unfiltered when input is blank. (default false) */
  defaultUnfiltered: PropTypes.bool,
  /** True to disable this field, false otherwise */
  disabled: PropTypes.bool,
  /** If active, the value will display below the input field */
  displayValue: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.func]),
  /** Render empty display message when there is input but no items. Default shows nothing */
  empty: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.func]),
  /** Optional array of error messages */
  errors: PropTypes.arrayOf(PropTypes.node),
  /**
    * Filter the input. Supply your own or use a pre-built one, i.e.
    * AutocompleteField.SimpleFilter or AutocompleteField.PropertyFilter.
    */
  filter: PropTypes.func,
  /** True to highlight the control with a green background, false otherwise */
  highlight: PropTypes.bool,
  /** Indent the input and placeholder. Either true or a number of px */
  indent: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  /** Optional function for custom item rendering */
  itemRenderer: PropTypes.func,
  /** Array of items to display in the drop down */
  items: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    })),
  ]).isRequired,
  /** Optional label to display over the field. Can be a string or node */
  label: PropTypes.node,
  /** Optional class name to add to the label text */
  labelClassName: PropTypes.string,
  /** Callback when menu is opened */
  maxHeight: PropTypes.string,
  /** maxHeight to be passed into Select outer container css */
  onOpen: PropTypes.func,
  /** Callback when menu is closed */
  onClose: PropTypes.func,
  /** Listen for key down events */
  onInputKeyDown: PropTypes.func,
  /** Handle input changes, for search functionality */
  onInputChange: PropTypes.func,
  /** Callback when an item is selected */
  onSelect: PropTypes.func,
  /** Listen for the enter key being pressed. Not valid with multi-line text. */
  onSubmit: PropTypes.func,
  /** Placeholder text to display */
  placeholder: PropTypes.string,
  /** True if required field, false otherwise */
  required: PropTypes.bool,
  /** Set value to null to clear after select */
  resetValueOnChange: PropTypes.bool,
  /** Input size */
  size: PropTypes.oneOf(['medium', 'large']),
  /** The pre-selected value */
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /** The key to use for the value */
  valueKey: PropTypes.string,
  /** Render the selected value */
  valueRenderer: PropTypes.func,
};

AutocompleteField.defaultProps = {
  autoBlur: true,
  autoOpen: undefined,
  className: undefined,
  clearable: false,
  creatable: undefined,
  defaultBorderColor: 'transparent',
  defaultUnfiltered: false,
  disabled: false,
  displayValue: undefined,
  empty: undefined,
  errors: undefined,
  filter: SimpleFilter,
  highlight: false,
  indent: undefined,
  itemRenderer: item => (typeof item === 'string' ? item : item.label || item.text),
  label: undefined,
  labelClassName: undefined,
  maxHeight: '200px',
  onClose: undefined,
  onInputChange: undefined,
  onInputKeyDown: undefined,
  onOpen: undefined,
  onSelect: undefined,
  onSubmit: undefined,
  placeholder: undefined,
  required: false,
  resetValueOnChange: false,
  size: 'medium',
  value: null,
  valueKey: 'value',
  valueRenderer: item => (typeof item === 'string' ? item : item.label || item.text || item.valueText),
};

export default withComponentTheme(createComponentStyles)(HOC(AutocompleteField, { Container: SelectFieldContainer }));

/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import styled from 'react-emotion';
import { css } from 'emotion';
import { withRouter } from 'react-router-dom';
import qs from 'query-string';

import { createTranslator, numeric } from 'helpers/i18n';
import md from 'helpers/markdown';
import { isValidEmail } from 'helpers/validators';
import { trackEvent } from 'helpers/analytics';
import { applyTestIdentifier } from 'helpers/development';

import * as actions from 'ducks/session';

import {
  Button, ButtonBar, Panel, ContentPanel, Link,
  SplitPanel, Tip, TextField, Divider,
  Colors, Styles, Fragment, QueryContext,
} from 'components/utils';
import withThemedComponents, { ThemeConfig } from 'components/utils/withThemedComponents';
import { Sizes } from 'components/utils/SelectField';
import { mq } from 'components/utils/Responsive';

import {
  PrimaryColumn,
  SecondaryColumn,
  ResponsiveGrid,
  ContentPanelPageTitle,
  pageContainerStyleV2,
  primaryButtonStyle,
  textInputHeightV2,
} from 'components/login/LoginStyles';
import PreviewModal from 'components/home/PreviewModal';

import { SUPPORT_KEY } from './RegisterAccountForm';
import AidinLogoContainer from './AidinLogoContainer';
import HIPAAModal from './HIPAAModal';

const { Secondary } = Styles;
const {
  SENDING_LIGHTEST_FILL,
  ERROR_DARKER_FILL,
  CONFIRMATION_FILL,
} = Colors;

const LoginSecondaryColumn = styled(SecondaryColumn)(mq({
  padding: ['0px', '0px', '0px'],
  width: ['300px', '100%', '100%'],
}));

const LoginSecondaryColumnContainer = styled(LoginSecondaryColumn)({
  boxShadow: 'none',
  [LoginSecondaryColumn]: {
    margin: '0px',
  },
});

const LoginInnerContainer = styled.div(props => mq({
  padding: `${props.padding || 20}px`,
}));

const loginFormTitleStyle = css`
  margin-top: 0;
`;

const createV1LoginLayout = (
  loginContent,
  secondaryContent,
  {
    colors,
  },
) => (
  <LoginSecondaryColumnContainer>
    <Panel spacing={10}>
      <LoginSecondaryColumn backgroundColor={colors.WHITE}>
        <LoginInnerContainer>
          {loginContent}
        </LoginInnerContainer>
      </LoginSecondaryColumn>
      <LoginSecondaryColumn backgroundColor={colors.WHITE}>
        <LoginInnerContainer padding={4}>
          {secondaryContent}
        </LoginInnerContainer>
      </LoginSecondaryColumn>
    </Panel>
  </LoginSecondaryColumnContainer>
);

const createV2LoginLayout = (
  loginContent,
  secondaryContent,
  {
    styles: {
      contentPanelStyle,
    },
  },
) => (
  <Panel
    className={contentPanelStyle}
  >
    <Panel spacing={10}>
      {loginContent}
    </Panel>
    {secondaryContent}
  </Panel>
);

const createComponentStyles = ({ version, theme: { colors } }) => {
  const sharedLoginStyles = mq({
    margin: ['20px 150px', '20px 0px', '20px 0px'],
  });
  return numeric(version, {
    other: {
      version,
      colors,
      styles: {
        loginButtonStyle: css`
          margin-top: 8px;
        `,
        referralButtonStyle: undefined,
        referralButtonPanelStyle: undefined,
        emailPasswordContainerStyle: undefined,
        buttonDarkerColor: colors.RECEIVING_DARKER_FILL,
        loginPanelAligment: 'top',
        loginPanelOrientation: 'horizontal',
        LoginContainer: styled.div(sharedLoginStyles),
        LoginFormContainer: ContentPanel,
        AidinLogoStyled: Fragment,
        JoinAidinPrompt: 'h3',
      },
      options: {
        loginLayoutBuilder: createV1LoginLayout,
        loginSubmitButtonPosition: 'top',
        showLoginPanelDivider: true,
        showSignUpPrompt: true,
        showInputLabelIndicator: true,
        showInputRequiredIndicator: true,
        showInputPlaceholder: true,
        useSmallInvitationButton: false,
      },
      layout: {
        all: {
          showLogo: false,
        },
      },
    },
    v2: {
      version,
      colors,
      styles: {
        clickableTextButtonStyle: css`
          transform: translateX(-14.4px);
        `,
        invitationButtonBarStyle: css(mq({
          '> div': {
            width: [undefined, '100%', '100%'],
          },
        })),
        referralButtonStyle: primaryButtonStyle,
        contentPanelStyle: pageContainerStyleV2,
        loginContainerStyle: css(mq({
          width: '100%',
          display: 'flex',
          flexDirection: ['row', 'column-reverse', 'column-reverse'],
          justifyContent: 'space-between',
          alignItems: ['baseline', 'flex-end', 'flex-end'],
        })),
        loginPanelOrientation: 'horizontal',
        loginPanelSpacing: 0,
        loginButtonStyle: css(mq({
          width: ['145px', '100%', '100%'],
          display: 'flex',
          justifyContent: 'center',
          backgroundColor: colors.RECEIVING_DARKEST_FILL,
        })),
        loginButtonAlignment: (idx) => {
          if (idx <= 1) {
            const large = idx === 0 ? 0 : '10px';
            const smallMed = idx === 0 ? '17px' : '10px';

            return css(mq({
              width: ['auto', '100%', '100%'],
              marginTop: [large, smallMed, smallMed],
            }));
          }

          return css`
            position: absolute;
          `;
        },
        buttonDarkerColor: colors.SENDING_DARKER_FILL,
        LoginContainer: styled.div(sharedLoginStyles),
        LoginFormContainer: Panel,
        SecondaryPageTitle: ContentPanelPageTitle,
        JoinAidinPrompt: styled.div(),
        AidinLogoStyled: styled.div(mq({
          display: 'flex',
          justifyContent: ['flex-start', 'center', 'center'],
          marginLeft: ['-20px', '0px', '0px'],
          marginBottom: ['40px', '20px', '20px'],
        })),
        getInvitationButtonBarAlignment: (size) => numeric(size, {
          large: 'right',
          other: 'center',
        }),
        textInputHeight: textInputHeightV2,
        textInputWidth: size => (size === 'large' ? 507 : undefined),
      },
      options: {
        loginLayoutBuilder: createV2LoginLayout,
        loginSubmitButtonPosition: 'bottom',
        showLoginPanelDivider: false,
        showSignUpPrompt: false,
        showInputLabelIndicator: false,
        showInputRequiredIndicator: true,
        showInputPlaceholder: false,
        showInputAsFullWidth: true,
        showInvitationInlineAccessLinks: true,
        showJoinButtonAsLink: true,
        useSmallInvitationButton: false,
        hideLogoTagline: true,
      },
      layout: {
        invitation: {
          showLogo: false,
        },
        user: {
          showLogo: true,
        },
      },
    },
  });
};

const tr = createTranslator({
  confirmed: {
    title: 'Log In to Continue',
    titleColor: null,
    prompt: {
      new: 'Thank you for confirming your account!',
    },
    promptElement: 'h3',
    promptColor: CONFIRMATION_FILL,
  },
  unconfirmed: {
    title: 'Log In to Continue',
    titleColor: null,
    prompt: {
      new: 'Your account has been created, but you\'ll need to confirm your account before logging in. Check your email for instructions.',
    },
    promptElement: 'h3',
    promptColor: CONFIRMATION_FILL,
  },
  missingInvite: {
    title: 'Log In to Continue',
    titleColor: null,
    prompt: {
      new: 'Your invitation link is incorrect or has expired.',
    },
    promptElement: 'h3',
    promptColor: ERROR_DARKER_FILL,
  },
  confirmationTokenInvalid: {
    title: 'Log In to Continue',
    titleColor: null,
    prompt: {
      new: md('It looks like you are trying to confirm your email address, but your link is invalid. Please contact [support@myaidin.com](mailto:support@myaidin.com) for assistance.'),
    },
    promptElement: 'h3',
    promptColor: ERROR_DARKER_FILL,
  },
  login: {
    title: themeVersion => numeric(themeVersion, {
      v2: 'Log in to continue',
      other: 'Returning User Login:',
    }),
    back: 'Back',
    titleColor: SENDING_LIGHTEST_FILL,
    prompt: {
      existing: 'We recognize the email you used to find your referral. Please log in with your email and password to use Aidin.',
      invalid_existing: "We couldn't find the referral you're looking for, but go ahead and log in and you'll find it on the dashboard.",
    },
    username: {
      label: 'Email',
      error: {
        empty: 'Please enter your email address',
        invalid: 'Please enter a valid email address',
      },
    },
    password: {
      label: 'Password',
      error: 'Please enter your password',
    },
    access: 'Access your account:',
    submit: 'Log In',
    forgot: 'Forgot Password?',
    join: themeVersion => numeric(themeVersion, {
      v2: 'Create an Account',
      other: 'Join Aidin',
    }),
    reactivate: 'Reactivate Account',
    tip: {
      error: 'Error',
      warning: 'Update',
      empty_message: 'An unknown error occurred when trying to login, please try again later.',
    },
  },
  signup: {
    title: themeVersion => numeric(themeVersion, {
      v2: 'Find a Referral',
      other: 'Look Up a Referral',
    }),
    prompt: 'Never used Aidin before? Check your email, texts, or fax for an invitation code (with dashes) inviting you to a new referral.',
    code: {
      label: themeVersion => numeric(themeVersion, {
        v2: 'Invitation code (words & dashes)',
        other: 'Enter your Invitation Code (words and dashes)',
      }),
      placeholder: 'e.g. liberal-green-paisley-ladybug-918508',
      error: {
        empty: 'Please enter your invitation code',
        invalid: 'This invitation code is invalid or has expired.',
      },
      referralButton: 'I have a referral invitation code',
    },
    email: {
      label: 'Email',
      placeholder: 'e.g. your work email',
      error: {
        empty: 'Please enter your email address',
        invalid: 'Please enter a valid email address',
      },
    },
    submit: 'Look up Referral',
    error: {
      validate_token: {
        invalid: 'Sorry, that invitation code is invalid.',
        invalid_existing: 'Sorry, that invitation code is invalid.',
        unconfirmed: 'Sorry, that invitation code does not currently provide access to the referral.',
        expired: 'Sorry, that invitation code has expired.',
        closed: 'Sorry, the referral corresponding to that invitation code has closed.',
      },
    },
  },
  join: {
    title: 'New to Aidin?',
  },
});

const confirmed = () => !!document.getElementById('confirmed');
const unconfirmed = () => !!document.getElementById('unconfirmed');
const confirmationTokenInvalid = () => !!document.getElementById('confirmation_token_invalid');
const missingInvite = () => !!document.getElementById('invitation_token_not_found');

class LoginForm extends React.Component {
  static contextType = QueryContext;

  static propTypes = {
    /** True to only show the login form, false otherwise (show validation token input) */
    loginOnly: PropTypes.bool,
    /** Specify a URL to go to after logging in */
    loginUrl: PropTypes.string,
    /** Pre-set username for the login form */
    username: PropTypes.string,
    /** Conditionally render PreviewModal content in different referral contexts */
    hasReferral: PropTypes.bool,
    /** Routing */
    history: ReactRouterPropTypes.history.isRequired,
    location: ReactRouterPropTypes.location.isRequired,
    inviteUser: PropTypes.func.isRequired,
    loginUser: PropTypes.func.isRequired,
    logoutUser: PropTypes.func.isRequired,
    validateInvitationCode: PropTypes.func.isRequired,
    /** used to override the responsive layout justify content */
    justifyContent: PropTypes.string,
    /** Browser Size */
    size: PropTypes.string.isRequired,
    theme: ThemeConfig.isRequired,
  }

  static defaultProps = {
    hasReferral: true,
    username: '',
    loginOnly: undefined,
    loginUrl: undefined,
    justifyContent: undefined,
  }

  constructor(props) {
    super(props);
    this.state = {
      username: props.username || '',
      password: '',
      code: '',
      email: '',
      previewModalOpen: false,
      hipaaOpen: false,
      tokenValidation: null,
      error: null,
      errors: {
        username: null,
        password: null,
        code: null,
      },
    };
  }

  componentDidMount() {
    this.focusField();
  }

  createSupportLink = key => {
    const query = { ...this.context, support: key };
    return `/devise_users/sign_in?${qs.stringify(query)}`;
  }

  focusField = () => {
    if (this.focusInput) {
      this.focusInput.getField().focus();
    }
  }

  handleInput = (key, value) => {
    this.setState({ [key]: value });
  }

  handleForgotPassword = () => {
    if (this.props.loginOnly) {
      this.props.logoutUser(() => {
        window.location.assign('/devise_users/password/new');
      });
    }
  }

  handlePostLogin = () => {
    window.location.assign(this.props.loginUrl || '/api/session/start');
  }

  handleLogin = () => {
    if (this.state.username && this.state.password) {
      this.setState(({ username, errors }) => ({
        errors: {
          ...errors,
          username: isValidEmail(username) ? null : [tr('login.username.error.invalid')],
          password: null,
        },
      }), () => {
        if (!this.state.errors.username) {
          this.props.loginUser(this.state.username, this.state.password)
            .then(this.handlePostLogin)
            .catch((e) => {
              if (!e.message) {
                e.message = tr('login.tip.empty_message');
              }
              this.setState(({ errors }) => ({
                errors: { ...errors, username: true, password: true },
                error: e.message.indexOf('90') < 0 ? md(e.message) : undefined,
                warning: e.message.indexOf('90') >= 0 ? e.message : undefined,
              }), e.message.indexOf('90') < 0 ? this.focusField : undefined);
            });
        }
      });
    } else {
      this.setState(({ username, password, errors }) => ({
        errors: {
          ...errors,
          // eslint-disable-next-line no-nested-ternary
          username: username
            ? isValidEmail(username) ? null : [tr('login.username.error.invalid')]
            : [tr('login.username.error.empty')],
          password: !password && [tr('login.password.error')],
        },
      }));
    }
  }

  handleValidateCode = () => {
    const { code, email } = this.state;
    if (code && email) {
      this.setState(({ errors }) => ({
        errors: {
          ...errors,
          code: null,
          email: isValidEmail(email) ? null : [tr('signup.email.error.invalid')],
        },
      }), () => {
        if (!this.state.errors.email) {
          this.props.validateInvitationCode(code, email)
            .then((tokenValidation) => {
              if (tokenValidation.valid) {
                if (tokenValidation.login) {
                  // Login as previewer
                  window.location.assign('/api/session/start');
                } else {
                  // Unrecognized user, create a new account
                  this.setState({ tokenValidation, hipaaOpen: true });
                }
              } else {
                this.setState(({ errors }) => ({
                  errors: {
                    ...errors,
                    code: [tr('signup.code.error.invalid')],
                  },
                }));
              }
            })
            .catch(({ message }) => {
              this.setState(({ errors }) => ({
                errors: {
                  ...errors,
                  code: [tr(`signup.${message}`)],
                },
              }), () => {
                if (message === 'error.validate_token.invalid_existing') {
                  trackEvent('Login', 'Error', 'Invalid Invitation Code with Existing User');
                  this.setState({ previewModalOpen: true });
                }
              });
            });
        }
      });
    } else {
      this.setState(({ errors }) => ({
        errors: {
          ...errors,
          code: !code && [tr('signup.code.error.empty')],
          // eslint-disable-next-line no-nested-ternary
          email: email
            ? isValidEmail(email) ? null : [tr('signup.email.error.invalid')]
            : [tr('signup.email.error.empty')],
        },
      }));
    }
  }

  handleSignup = () => {
    const { code, email, tokenValidation } = this.state;
    this.props.inviteUser(code, email, tokenValidation.provider)
      .then(() => {
        if (tokenValidation.existingUser) {
          this.setState({ username: email }, () => {
            this.props.history.push('/devise_users/sign_in');
          });
        } else {
          this.props.history.push('/devise_users/sign_up');
        }
      });
  }

  renderTextField = (scope, name, props) => {
    const {
      theme: {
        version,
        styles: {
          textInputHeight,
        },
        options: {
          showInputLabelIndicator,
          showInputRequiredIndicator,
          showInputPlaceholder,
          showInputAsFullWidth,
        },
      },
    } = this.props;

    return (
      <TextField
        required={showInputRequiredIndicator}
        labelIndicator={showInputLabelIndicator ? undefined : ' '}
        height={textInputHeight}
        fullWidth={showInputAsFullWidth}
        name={name}
        label={tr(`${scope}.${name}.label`, version)}
        placeholder={showInputPlaceholder ? tr(`${scope}.${name}.placeholder`) : undefined}
        errors={this.state.errors[name]}
        value={this.state[name]}
        onChange={(value) => { this.handleInput(name, value); }}
        {...props}
      />
    );
  }

  renderSubmitButton = ({ style, text }) => (
    <Button
      key="login"
      small
      className={style}
      type="submit"
      onClick={() => trackEvent('Login', 'Button', 'Log In')}
    >
      {text}
    </Button>
  )

  renderEmailLookupLink = () => {
    const {
      version,
      options: { showJoinButtonAsLink },
    } = this.props.theme;
    return (
      <Link to={this.createSupportLink(SUPPORT_KEY)}>
        <Button
          small
          inline
          link={showJoinButtonAsLink}
          onClick={() => trackEvent('Login', 'Get Started', 'Join')}
        >
          {tr('login.join', version)}
        </Button>
      </Link>
    );
  }

  renderSecondaryTitle = () => (<Secondary>{tr('join.title')}</Secondary>);

  renderCreateAccAndLoginPanel = () => (
    <Panel spacing={0}>
      <Panel orientation="horizontal" align="center" spacing={0}>
        <Secondary>{tr('login.access')}</Secondary>
        <Link to="/devise_users/sign_in?creds=account">
          <Button
            small
            inline
            link
            onClick={() => {
              trackEvent('Login', 'Get Started', 'Forgot Password');
              this.handleForgotPassword();
            }}
          >
            {tr('login.submit')}
          </Button>
        </Link>
      </Panel>
      <Panel orientation="horizontal" align="center">
        {this.renderSecondaryTitle()}
        {this.renderEmailLookupLink()}
      </Panel>
    </Panel>
  )

  renderInvitationButton = (joinAidinLink) => {
    const {
      colors,
    } = this.props.theme;

    const linkAlignmentStyle = (index) => {
      if (index !== 0) { return undefined; }
      return css({ alignSelf: 'flex-start' });
    };

    return (
      <Panel fullWidth itemClassName={linkAlignmentStyle}>
        {joinAidinLink}
        <Link inline noUnderline to="/devise_users/sign_in?creds=invitation">
          <Button
            small
            fullWidth
            color={colors.WHITE}
            justifyContent="center"
          >
            {tr('signup.code.referralButton')}
          </Button>
        </Link>
      </Panel>
    );
  }

  renderLinks = (loginTr) => {
    const {
      styles: {
        clickableTextButtonStyle,
        loginButtonStyle,
      },
      options: {
        loginSubmitButtonPosition,
      },
    } = this.props.theme;
    const renderSubmitForPosition = (position) => position === loginSubmitButtonPosition && (
      this.renderSubmitButton({ style: loginButtonStyle, text: loginTr('submit') })
    );
    return [
      renderSubmitForPosition('top'),
      <Panel key="links" spacing={0}>
        <Link to="/devise_users/password/new">
          <Button
            small
            link
            className={clickableTextButtonStyle}
            onClick={() => {
              trackEvent('Login', 'Get Started', 'Forgot Password');
              this.handleForgotPassword();
            }}
          >
            {loginTr('forgot')}
          </Button>
        </Link>
        <Link to={this.createSupportLink(SUPPORT_KEY)}>
          <Button
            small
            link
            className={clickableTextButtonStyle}
            onClick={() => trackEvent('Login', 'Get Started', 'Reactivate')}
          >
            {loginTr('reactivate')}
          </Button>
        </Link>
      </Panel>,
      renderSubmitForPosition('bottom'),
    ];
  }

  renderLoginPanel = () => {
    const {
      styles: {
        loginContainerStyle,
        loginButtonAlignment,
        loginPanelAligment,
        loginPanelOrientation,
        loginPanelSpacing,
        LoginContainer,
        JoinAidinPrompt,
      },
      options: {
        loginLayoutBuilder,
        showLoginPanelDivider,
      },
      colors,
      version,
    } = this.props.theme;

    let loginTr = tr('login');
    if (confirmed()) {
      loginTr = { ...loginTr, ...tr('confirmed') };
    }
    if (unconfirmed()) {
      loginTr = { ...loginTr, ...tr('unconfirmed') };
    }
    if (confirmationTokenInvalid()) {
      loginTr = { ...loginTr, ...tr('confirmationTokenInvalid') };
    }
    if (missingInvite()) {
      loginTr = { ...loginTr, ...tr('missingInvite') };
    }
    loginTr = createTranslator(loginTr);

    const hasEmail = this.state.tokenValidation || this.props.username;
    const NewPromptElement = loginTr('promptElement') || Secondary;
    const hasEmailPrompt = this.props.hasReferral ? loginTr('prompt.existing') : loginTr('prompt.invalid_existing');

    const withLoginDisableAutocomplete = (proposedSettings = {}) => (
      process.env.DISABLE_LOGIN_AUTOCOMPLETE === 'true' ? { autoComplete: 'off' } : proposedSettings
    );

    const loginContent = (
      <>
        {!this.props.loginOnly && (
          <>
            {/* eslint-disable-next-line */}
            <h3 className={loginFormTitleStyle} dangerouslySetInnerHTML={{ __html: loginTr('title', version) }} />
            {showLoginPanelDivider && <Divider margin={0} />}
          </>
        )}
        <form onSubmit={(e) => { e.preventDefault(); this.handleLogin(); }} {...applyTestIdentifier('emailPasswordLoginForm')} {...withLoginDisableAutocomplete()}>
          <Panel>
            <Panel>
              {this.state.error && (
                <Tip title={loginTr('tip.error')} color={colors.ERROR_LIGHTEST_FILL} orientation="horizontal">
                  {this.state.error}
                </Tip>
              )}
              {this.state.warning && (
                <Tip title={loginTr('tip.warning')} orientation="horizontal">
                  {this.state.warning}
                </Tip>
              )}
              {hasEmail ? <h3>{hasEmailPrompt}</h3> : (
                <NewPromptElement style={{ margin: '0px 0px', color: loginTr('promptColor') }}>
                  {loginTr('prompt.new')}
                </NewPromptElement>
              )}
              {hasEmail && <Divider margin={0} />}
              {!this.state.tokenValidation ? this.renderTextField('login', 'username', {
                ref: (ref) => { this.focusInput = ref; },
                name: 'email',
                type: 'email',
                ...withLoginDisableAutocomplete({ autoComplete: 'email' }),
              }) : (
                <div>
                  <h4>{loginTr('username.label')}</h4>
                  <div>{this.state.tokenValidation.email}</div>
                </div>
              )}
              {this.renderTextField('login', 'password', {
                ref: (ref) => {
                  if (this.state.tokenValidation || this.props.username) {
                    this.focusInput = ref;
                  }
                },
                type: 'password',
              })}
            </Panel>
            <Panel
              align={loginPanelAligment}
              orientation={loginPanelOrientation}
              className={loginContainerStyle}
              itemClassName={loginButtonAlignment}
              spacing={loginPanelSpacing}
            >
              {this.renderLinks(loginTr)}
            </Panel>
          </Panel>
        </form>
      </>
    );

    if (this.props.loginOnly) {
      return (
        <LoginContainer>
          {loginContent}
        </LoginContainer>
      );
    }

    const joinAidinLink = (
      <SplitPanel align="center" spacing="evenly">
        <JoinAidinPrompt>{tr('join.title')}</JoinAidinPrompt>
        {this.renderEmailLookupLink()}
      </SplitPanel>
    );

    const secondaryContent = numeric(version, {
      v2: this.renderInvitationButton(joinAidinLink),
      other: joinAidinLink,
    });

    return loginLayoutBuilder(
      loginContent,
      secondaryContent,
      this.props.theme,
    );
  }

  renderValidateCodePanel = () => {
    const {
      size,
      theme: {
        styles: {
          contentPanelStyle,
          invitationButtonBarStyle,
          referralButtonStyle,
          buttonDarkerColor,
          getInvitationButtonBarAlignment = () => undefined,
          SecondaryPageTitle,
          LoginFormContainer,
        },
        options: {
          showInvitationInlineAccessLinks,
          showSignUpPrompt,
          useSmallInvitationButton,
        },
        colors,
        version,
      },
    } = this.props;

    return (
      <LoginFormContainer
        className={contentPanelStyle}
        title={!SecondaryPageTitle ? tr('signup.title', version) : undefined}
        titleBackgroundColor={colors.RECEIVING_LIGHTEST_FILL}
        {...applyTestIdentifier('look-up-referral')}
      >
        {SecondaryPageTitle && <SecondaryPageTitle>{tr('signup.title', version)}</SecondaryPageTitle>}
        <Panel spacing={30}>
          <Panel>
            {showSignUpPrompt && <Secondary>{tr('signup.prompt')}</Secondary>}
            {this.renderTextField('signup', 'code', {
              onSubmit: this.handleValidateCode,
              height: Sizes.large.lineHeight,
              fontSize: `${Sizes.large.fontSize}px`,
            })}
            {this.renderTextField('signup', 'email', {
              onSubmit: this.handleValidateCode,
            })}
          </Panel>
          <ButtonBar
            className={invitationButtonBarStyle}
            align={getInvitationButtonBarAlignment(size)}
          >
            <Button
              type="submit"
              color={buttonDarkerColor}
              small={useSmallInvitationButton}
              className={referralButtonStyle}
              onClick={() => {
                trackEvent('Login', 'Button', 'Invitation Code Lookup');
                this.handleValidateCode();
              }}
            >
              {tr('signup.submit')}
            </Button>
          </ButtonBar>
        </Panel>
        {showInvitationInlineAccessLinks && this.renderCreateAccAndLoginPanel()}
        <HIPAAModal
          open={this.state.hipaaOpen}
          onClose={() => { this.setState({ hipaaOpen: false }); }}
          onSubmit={this.handleSignup}
        />
        <PreviewModal
          hasReferral={false}
          onClose={() => { this.setState({ previewModalOpen: false }); }}
          open={this.state.previewModalOpen}
          username={this.state.email}
        />
      </LoginFormContainer>
    );
  };

  render() {
    const {
      styles: {
        AidinLogoStyled,
      },
      options: {
        hideLogoTagline,
      },
      layout: layoutOptions,
    } = this.props.theme;
    const {
      creds = '',
    } = this.context;
    const AidinLogo = () => (
      <AidinLogoStyled>
        <AidinLogoContainer hideTagline={hideLogoTagline} />
      </AidinLogoStyled>
    );

    const currentPage = creds;
    // eslint-disable-next-line no-nested-ternary
    const layoutKey = currentPage && layoutOptions[currentPage]
      ? currentPage
      : layoutOptions.user
        ? 'user'
        : 'all';
    const layout = layoutOptions[layoutKey];

    return (
      <ResponsiveGrid
        spacing={10}
        justifyContent={this.props.justifyContent}
      >
        {!this.props.loginOnly && (
          <PrimaryColumn>
            {layout.showLogo && <AidinLogo />}
            {['all', 'invitation'].includes(layoutKey) && this.renderValidateCodePanel()}
            {layoutKey === 'user' && this.renderLoginPanel()}
          </PrimaryColumn>
        )}
        {(layoutKey === 'all' || this.props.loginOnly) && this.renderLoginPanel()}
      </ResponsiveGrid>
    );
  }
}

export default compose(
  withRouter,
  connect(({
    state,
    browser: { mediaType: size },
  }) => ({ state, size }), actions),
  withThemedComponents(createComponentStyles),
)(LoginForm);

import { createTranslator, numeric } from 'helpers/i18n';

const tr = createTranslator({
  noData: (isMine) => numeric(isMine, {
    true: 'No tiene suficientes datos para ganar esta insignia.',
    false: 'Este proveedor no tiene suficientes datos para ganar esta insignia.',
  }),
  noDataUser: (isMine) => numeric(isMine, {
    true: 'No tiene suficientes datos para ganar esta insignia.',
    false: 'Este usuario de Aidin no tiene suficientes datos para ganar esta insignia.',
  }),
  metricsTitle: 'Aidin usa las siguientes tareas para calcular esta insignia',
  subject: {
    personal: {
      user: 'Usted',
      provider: 'Usted',
    },
    default: {
      user: 'Este usuario do Aidin',
      provider: 'Este proveedor',
    },
  },
  terms: {
    personal: {
      is: 'de la',
      has: 'tienes',
      meets: 'meet',
      responds: 'respond',
      does: 'do',
      accepts: 'accept',
      receives: 'receive',
      completes: 'complete',
      chooses: 'choose',
      sends: 'send',
    },
    default: {
      is: 'es',
      has: 'tiene',
      meets: 'meets',
      responds: 'responds',
      does: 'does',
      accepts: 'accepts',
      receives: 'receives',
      completes: 'completes',
      chooses: 'chooses',
      sends: 'sends',
    },
  },
});

const getSubject = (type, isMine) => (isMine ? tr(`subject.personal.${type}`) : tr(`subject.default.${type}`));

const getTerm = (term, isMine) => (isMine ? tr(`terms.personal.${term}`) : tr(`terms.default.${term}`));

/**
 * Include in your code via:
 *
 *     import badges from 'translations/badges';
 *     const tr = createTranslator({
 *       badges,
 *       ...,
 *     });
 *
 * Then get the name or description with a given AwardedBadge via:
 *
 *     const badge = ...;
 *     const name = tr(`${badge.key}.name`, badge);
 *     const desc = tr(`${badge.key}.description`, badge);
 *
 * The `badge` object must contain two keys:
 * - key: the key for the badge
 * - state, one of:
 *   - active: Badge has been earned.
 *   - pending: Badge has no data
 *   - failed: Badge failed criteria
 *
 * You can also import the badge name and description helpers:
 *
 *     import { trBadgeName, trBadgeDescription } from 'translations/badges';
 *
 *     const name = trBadgeName(badge);
 *
 * Which do the above internally.
 */
const badges = {
  network_member: {
    name: 'Miembro de la red',
    description: ({ state }, isMine) => numeric(state, {
      active: `${getSubject('provider', isMine, 'es')} ${getTerm('is', isMine, 'es')}} parte de la red para referencias como esta.`,
      other: 'Este proveedor no forma parte de la red para referencias como esta.',
    }),
  },
  authorized_for_care: {
    name: 'Autorizado para el cuidado del paciente',
    description: ({ state }, isMine) => numeric(state, {
      active: numeric(isMine, {
        true: 'Usted es parte de la red para referencias como esta.',
        false: 'Este proveedor es parte de la red para referencias como esta.',
      }),
      other: 'Este proveedor no está autorizado por su seguro para cubrir las necesidades de esta referencia.',
    }),
  },
  accepts_similar_insurance: {
    name: ({ state }) => numeric(state, {
      active: 'Seguros similares',
      other: 'No seguros similares',
    }),
    description: ({ state }, isMine) => numeric(state, {
      active: numeric(isMine, {
        true: 'Ha aceptado un seguro similar en los últimos 90 días.',
        false: 'Este proveedor ha aceptado un seguro similar en los últimos 90 días.',
      }),
      pending: tr('noData', isMine),
      failed: numeric(isMine, {
        true: 'No ha aceptado un seguro similar en los últimos 90 días.',
        false: 'Este proveedor no ha aceptado un seguro similar en los últimos 90 días.',
      }),
    }),
  },
  accepts_similar_care_types: {
    name: ({ state }) => numeric(state, {
      active: 'Necesidades clínicas similares',
      other: 'No necesidades clínicas similares ',
    }),
    description: ({ state }, isMine) => numeric(state, {
      active: numeric(isMine, {
        true: 'Ha aceptado necesidades clínicas similares en los últimos 90 días.',
        false: 'Este proveedor ha aceptado necesidades clínicas similares en los últimos 90 días.',
      }),
      pending: tr('noData', isMine),
      failed: numeric(isMine, {
        true: 'No ha aceptado necesidades clínicas similares en los últimos 90 días.',
        false: 'Este proveedor no ha aceptado necesidades clínicas similares en los últimos 90 días.',
      }),
    }),
  },
  accepts_similar_geography: {
    name: ({ state }) => numeric(state, {
      active: 'Geografía similar',
      other: 'No geografía similar',
    }),
    description: ({ state }, isMine) => numeric(state, {
      active: numeric(isMine, {
        true: 'Ha aceptado necesidades geográficas similares en los últimos 90 días.',
        false: 'Este proveedor ha aceptado necesidades geográficas similares en los últimos 90 días.',
      }),
      pending: tr('noData', isMine),
      failed: numeric(isMine, {
        true: 'No ha aceptado necesidades geográficas similares en los últimos 90 días.',
        false: 'Este proveedor ha aceptado necesidades geográficas similares en los últimos 90 días.',
      }),
    }),
  },
  lowest_readmission_rate: {
    name: 'La tasa de readmisión en la 20% más baja',
    description: ({ state }, isMine) => numeric(state, {
      active: numeric(isMine, {
        true: 'Ha aceptado una tasa de readmisión de Medicare en el mejor 20% de los proveedores filtrados.',
        false: 'Este proveedor tiene una tasa de readmisión de Medicare en el mejor 20% de los proveedores filtrados.',
      }),
      pending: tr('noData', isMine),
      failed: numeric(isMine, {
        true: 'Tiene una tasa de readmisión de Medicare más alta que el 20% de los mejores proveedores filtrados.',
        false: 'Este proveedor tiene una tasa de readmisión de Medicare en el mejor 20% de los proveedores filtrados.',
      }),
    }),
  },
  highest_patient_satisfaction: {
    name: 'Top 20% de satisfacción del paciente',
    description: ({ state }, isMine) => numeric(state, {
      active: numeric(isMine, {
        true: 'Tiene una satisfacción de Medicare en el 20% superior de los proveedores filtrados.',
        false: 'Este proveedor tiene una satisfacción de Medicare en el 20% superior de los proveedores filtrados.',
      }),
      pending: tr('noData', isMine),
      failed: numeric(isMine, {
        true: 'Tiene una satisfacción de Medicare inferior al 20% superior de los proveedores filtrados.',
        false: 'Este proveedor tiene una satisfacción de Medicare inferior al 20% superior de los proveedores filtrados.',
      }),
    }),
  },
  meets_goal_los: {
    name: 'Cumple con las metas de estadía (LOS)',
    description: ({ state }, isMine) => numeric(state, {
      active: numeric(isMine, {
        true: 'Cumple con los períodos de estadía esperados para referencias como esta.',
        false: 'Este proveedor cumple con los períodos de estadía esperados para referencias como esta.',
      }),
      pending: tr('noData', isMine),
      failed: numeric(isMine, {
        true: 'No ha cumplido los períodos de estadía esperados para referencias como esta.',
        false: 'Este proveedor no ha cumplido los períodos de estadía esperados para referencias como esta.',
      }),
    }),
  },
  responds_similar_care_plans: {
    name: 'Responde a planes de atención similares',
    description: ({ state }, isMine) => numeric(state, {
      active: numeric(isMine, {
        true: 'Responde a planes de atención similares y responde a tiempo.',
        false: 'Este proveedor responde a planes de atención similares y responde a tiempo.',
      }),
      pending: tr('noData', isMine),
      failed: numeric(isMine, {
        true: 'No ha respondido a planes de atención similares.',
        false: 'Este proveedor no ha respondido a planes de atención similares.',
      }),
    }),
  },
  active_account: {
    name: ({ state }) => numeric(state, {
      failed: 'Cuenta de Aidin inactiva',
      other: 'Cuenta de Aidin activa',
    }),
    description: ({ state }, isMine) => numeric(state, {
      active: numeric(isMine, {
        true: 'Ha respondido a una referencia en los últimos 90 días y tiene una biografía y una foto.',
        false: 'Este proveedor ha respondido a una referencia en los últimos 90 días y tiene una biografía y una foto.',
      }),
      pending: tr('noData', isMine),
      failed: numeric(isMine, {
        true: 'No ha respondido a una referencia en los últimos 90 días.',
        false: 'Este proveedor no ha respondido a una referencia en los últimos 90 días.',
      }),
    }),
  },
  responds_on_time: {
    name: 'Responde a las referencias a tiempo',
    description: ({ state }, isMine) => numeric(state, {
      active: numeric(isMine, {
        true: 'Responde a tiempo para al menos el 80% de las referencias.',
        false: 'Este proveedor responde a tiempo para al menos el 80% de las referencias.',
      }),
      pending: tr('noData', isMine),
      failed: numeric(isMine, {
        true: 'Puede responder tarde a las referencias.',
        false: 'Este proveedor puede responder tarde a las referencias.',
      }),
    }),
  },
  accurate_insurance_filters: {
    name: 'Filtros de seguro precisos',
    description: ({ state }, isMine) => numeric(state, {
      active: numeric(isMine, {
        true: 'Ha completado sus filtros de seguro.',
        false: 'Este proveedor ha completado sus filtros de seguro.',
      }),
      pending: tr('noData', isMine), // TODO: Can this really be no data? Either you did or didn't??
      failed: numeric(isMine, {
        true: 'No ha completado sus filtros de seguro.',
        false: 'Este proveedor no ha completado sus filtros de seguro.',
      }),
    }),
  },
  accurate_care_types_filters: {
    name: 'Filtros precisos de necesidad clínica',
    description: ({ state }, isMine) => numeric(state, {
      active: numeric(isMine, {
        true: 'Ha completado sus filtros de necesidades clínicas.',
        false: 'Este proveedor ha completado sus filtros de necesidades clínicas.',
      }),
      pending: tr('noData', isMine), // TODO: Can this really be no data? Either you did or didn't??
      failed: numeric(isMine, {
        true: 'No ha completado sus filtros de necesidades clínicas.',
        false: 'Este proveedor no ha completado sus filtros de necesidades clínicas.',
      }),
    }),
  },
  accurate_geography_filters: {
    name: 'Filtros de geografía precisos',
    description: ({ state }, isMine) => numeric(state, {
      active: numeric(isMine, {
        true: 'Has completado tus filtros de ubicación.',
        false: 'Este proveedor ha completado sus filtros de ubicación.',
      }),
      pending: tr('noData', isMine), // TODO: Can this really be no data? Either you did or didn't??
      failed: numeric(isMine, {
        true: 'No ha completado sus filtros de ubicación.',
        false: 'Este proveedor no ha completado sus filtros de ubicación.',
      }),
    }),
  },
  high_medicare_score: {
    name: '4+ Calificación de puntaje de Medicare',
    description: ({ state }, isMine) => numeric(state, {
      active: numeric(isMine, {
        true: 'Tiene una calificación de Medicare de 4 o 5 estrellas.',
        false: 'Este proveedor tiene una calificación de Medicare de 4 o 5 estrellas.',
      }),
      pending: numeric(isMine, {
        true: 'No tiene una calificación de Medicare.',
        false: 'Este proveedor no tiene una calificación de Medicare.',
      }),
      failed: numeric(isMine, {
        true: 'Tiene una calificación de Medicare de menos de 4.',
        false: 'Este proveedor tiene una calificación de Medicare de menos de 4.',
      }),
    }),
  },
  accepts_financially_complex_cases: {
    name: 'Acepta casos financieramente complejos',
    description: ({ state }, isMine) => numeric(state, {
      active: numeric(isMine, {
        true: 'Acepta casos financieramente complejos.',
        false: 'Este proveedor acepta casos financieramente complejos.',
      }),
      pending: tr('noData', isMine), // TODO: Can this really be no data? Either you do or you don't??
      failed: numeric(isMine, {
        true: 'No acepta casos financieramente complejos.',
        false: 'Este proveedor no acepta casos financieramente complejos.',
      }),
    }),
  },
  accepts_clinically_complex_cases: {
    name: 'Acepta casos clínicamente complejos.',
    description: ({ state }, isMine) => numeric(state, {
      active: numeric(isMine, {
        true: 'Acepta casos clínicamente complejos, como pacientes difíciles.',
        false: 'Este proveedor acepta casos clínicamente complejos, como pacientes difíciles.',
      }),
      pending: tr('noData', isMine), // TODO: Can this really be no data? Either you do or you don't??
      failed: numeric(isMine, {
        true: 'No acepta casos clínicamente complejos, como pacientes difíciles.',
        false: 'Este proveedor no acepta casos clínicamente complejos, como pacientes difíciles.',
      }),
    }),
  },
  electronic_notifications: {
    name: 'Utiliza correos electrónicos',
    description: ({ state }, isMine) => numeric(state, {
      active: numeric(isMine, {
        true: 'Recibes correos electrónicos, que tienden a ser más rápidos que los de fax.',
        false: 'Este proveedor recibe correos electrónicos, que tienden a ser más rápidos que los de fax.',
      }),
      pending: tr('noDataUser', isMine), // TODO: Can this really be no data? Either you do or you don't??
      failed: numeric(isMine, {
        true: 'No recibes correos electrónicos.',
        false: 'Este proveedor no recibe correos electrónicos.',
      }),
    }),
  },
  open_competition: {
    name: 'Competencia abierta',
    description: ({ state }, isMine) => numeric(state, {
      active: numeric(isMine, {
        true: 'Envías a múltiples proveedores y envías a los proveedores de más alta calidad disponibles más del 90% del tiempo.',
        false: 'Este proveedor envía a múltiples proveedores y envía a los proveedores de más alta calidad disponibles más del 90% del tiempo.',
      }),
      pending: tr('noDataUser', isMine),
      failed: numeric(isMine, {
        true: 'No siempre envía a múltiples proveedores o envía a los proveedores de más alta calidad disponibles.',
        false: 'Este proveedor no siempre envía a múltiples proveedores o envía a los proveedores de más alta calidad disponibles.',
      }),
    }),
    metrics: {
      title: tr('metricsTitle'),
      items: {
        competition: 'Enviar a 3 o más proveedores en referencias que no se reanuden',
        deadline: 'Permitir que se cierre el plazo de una referencia antes de reservar',
        patientChoice: 'Mostrarle al paciente su Lista de elección de pacientes y no saltar para reservar',
      },
    },
  },
  quality_first: {
    name: 'Calidad primero',
    description: ({ state }, isMine) => numeric(state, {
      active: numeric(isMine, {
        true: 'Usted elige el proveedor disponible de la más alta calidad más del 90% del tiempo.',
        false: 'Este proveedor elige el proveedor disponible de más alta calidad más del 90% del tiempo.',
      }),
      pending: tr('noDataUser', isMine),
      failed: numeric(isMine, {
        true: 'No siempre elige el proveedor disponible de más alta calidad.',
        false: 'Este proveedor no siempre elige el proveedor de mayor calidad disponible.',
      }),
    }),
    metrics: {
      title: tr('metricsTitle'),
      items: {
        quality: 'Reservar el proveedor disponible de la más alta calidad en referencias',
      },
    },
  },
  accountability_transparency: {
    name: 'Responsabilidad y Transparencia',
    description: ({ state }, isMine) => numeric(state, {
      active: numeric(isMine, {
        true: 'Responde y completa referencias a tiempo más del 90% del tiempo.',
        false: 'Este proveedor responde y completa las tareas a tiempo más del 90% del tiempo.',
      }),
      pending: tr('noDataUser', isMine),
      failed: numeric(isMine, {
        true: 'Responde y completa las tareas a tiempo menos del 90% del tiempo.',
        false: 'Este proveedor responde y completa las tareas a tiempo menos del 90% del tiempo.',
      }),
    }),
    metrics: {
      title: 'Aidin utiliza las siguientes tareas a tiempo para completar esta insignia',
      items: {
        submit: 'Crear y enviar referencias',
        reopen: 'Reapertura de referencias',
        share: 'Compartir la lista de elección del paciente',
        reserve: 'Reservando el proveedor',
      },
    },
  },
  practices_receiving_referrals: {
    name: ({ state }) => numeric(state, {
      active: 'Prácticas para recibir referencias',
      other: 'No ha practicado recibir referencias',
    }),
    description: ({ state }, isMine) => numeric(state, {
      active: numeric(isMine, {
        true: 'Ha recibido y completado 10 referencias de práctica en el último año.',
        false: 'Este proveedor recibió y completó 10 referencias de práctica en el último año.',
      }),
      other: 'Este proveedor no ha recibido ni completado 10 referencias de práctica en el último año.',
    }),
  },
  practices_sending_referrals: {
    name: ({ state }) => numeric(state, {
      active: 'Prácticas de envío de referencias',
      other: 'No ha practicado el envío de referencias',
    }),
    description: ({ state }, isMine) => numeric(state, {
      active: numeric(isMine, {
        true: 'Has enviado y completado 10 referencias de práctica en el último año.',
        false: 'Este proveedor ha enviado y completado 10 referencias de práctica en el último año.',
      }),
      other: 'Este proveedor no ha enviado ni completado 10 referencias de práctica en el último año.',
    }),
  },
};

export default badges;

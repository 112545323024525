/* eslint-disable import/prefer-default-export */

import apiDirector from 'helpers/ApiDirector';
import * as actions from 'ducks/conversations/actions';

export const requestConversations = (topicId, topicType) => (dispatch, getState) => {
  const createTemplate = () => {
    if (topicType === 'Referral') {
      const { referral } = getState();
      if (referral && referral.referralRole === 'receiving') {
        return {
          topic_id: referral.id,
          topic_type: 'Referral',
          conversation_organizations_attributes: [
            referral.hospital,
          ].filter(i => i).map(org => ({
            organization_id: org.id,
            organization_type: org.type || org.organization_type,
          })),
        };
      }
    }
    return undefined;
  };

  return apiDirector.validateFetch('/api/conversations/topics', {
    method: 'POST',
    body: JSON.stringify({
      conversation: {
        topic_id: topicId,
        topic_type: topicType,
      },
      placeholder: createTemplate(),
    }),
  })
    .then(json => dispatch(actions.replaceConversations(json)));
};

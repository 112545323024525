import { Userpilot } from 'userpilot';

const { userpilotSettings: { token } = {} } = window.AidinNext || {};
const shouldTrack = (process.env.NODE_ENV !== 'development');

if (shouldTrack) {
  Userpilot.initialize(token);
}

const trackEvent = (category, action, label) => {
  let event = { category, label };
  if (typeof category === 'object') {
    event = category;
  }
  return Userpilot.track(action, event);
};

const trackPageView = url => Userpilot.reload({ url });

export default Userpilot;

export {
  shouldTrack,
  trackEvent,
  trackPageView,
};

import PropTypes from 'prop-types';

export const CareTypeModel = PropTypes.shape({
  id: PropTypes.number.isRequired,
  category: PropTypes.string,
  provider_type: PropTypes.string,
  translations_name: PropTypes.string,
});

export const LanguageModel = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string,
  code: PropTypes.string,
});

export const CountyModel = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  state_id: PropTypes.number,
});

export const StateModel = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string,
  abbreviation: PropTypes.string,
});

export const BadgeModel = PropTypes.shape({
  key: PropTypes.string.isRequired,
  weight: PropTypes.number,
  category: PropTypes.oneOf(['search', 'user', 'provider']),
  feature: PropTypes.oneOf(['unfeatured', 'highlighted', 'superlative']),
  inactive: PropTypes.bool,
  badge_tier_id: PropTypes.number,
});

const System = PropTypes.shape({
  care_types: PropTypes.arrayOf(CareTypeModel),
  languages: PropTypes.arrayOf(LanguageModel),
  counties: PropTypes.arrayOf(CountyModel),
  states: PropTypes.arrayOf(StateModel),
  badges: PropTypes.arrayOf(BadgeModel),
  provider_types: PropTypes.arrayOf(PropTypes.string),
});

export default System;

import styled from 'react-emotion';
import { mq } from 'components/utils/Responsive';
import { css } from 'emotion';
import {
  Label, NumbersSmall, Secondary,
} from 'components/utils/Styles';

import { Colors } from 'components/utils';

const { LIGHT_GRAY_FILL, SECONDARY_FILL } = Colors;

export const columns = [25, 30, 17, 16, 12];

export const Column = styled.div`
  flex-basis: ${props => props.width}%;
  text-align: ${props => props.align || 'left'};
  ${props => ((props.status === 'unavailable' || props.disabled) && `color: ${LIGHT_GRAY_FILL};`)}
  ${props => props.stretch && 'align-self: stretch;'};
  ${Secondary} {
    ${props => ((props.status === 'unavailable' || props.disabled) && `color: ${SECONDARY_FILL};`)}
  }
`;

export const WordBreakColumn = styled(Column)`
  word-break: break-all;
`;

export const TableTitle = styled.h3`
  margin-top: 0em;
  margin-bottom: 0.25em;
`;

export const NumbersSmallCenter = styled(NumbersSmall)`
  text-align: center;
`;

export const centerPanelStyle = css`
  justify-content: center;
`;

export const ExplainerText = styled.div(({ marginBottom = 0, theme }) => ({
  fontFamily: theme.fonts.BODY_TEXT,
  marginBottom,
}));

export const MemberRow = styled.div`
  display: flex;
  align-items: center;
  padding: 6px;
`;

export const SettingsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const SettingsColumn = styled.div(props => mq({
  flexBasis: props.width,
  display: props.flex ? 'flex' : 'block',
  flexWrap: props.wrapItems ? 'wrap' : undefined,
  '> div': {
    margin: ['0px', props.wrapItems ? '10px 0px' : '0px', props.wrapItems ? '10px 0px' : '0px'],
  },
}));

export const PrimarySettingsColumn = styled(SettingsColumn)(props => mq({
  flexBasis: [props.width || '520px', '100%', '100%'],
}));

export const SecondarySettingsColumn = styled(SettingsColumn)(props => mq({
  flexBasis: [props.width || '200px', '100%', '100%'],
  marginTop: ['0px', '20px', '20px'],
}));

export const splitColumnWidth = ['50%', '100%', '100%'];

export const fieldWidth = ['210px', '100%', '100%'];

export const Header = styled(Column.withComponent(Label))``;

export const HeaderRow = styled(MemberRow)`
  margin: 9px 0px 12px;
`;

export const LargeHeaderContainerBase = styled.div(mq({
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'space-between',
  marginLeft: [undefined, undefined, '11px'],
  a: {
    fontSize: [undefined, undefined, '14px'],
  },
}));

/* eslint-disable import/prefer-default-export */

/**
 * Do some crazy math to make the price pretty.
 */
export const formatPrice = price => `$${(price / 100).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,').split('.')[0]}`;

export const formatPriceWithCents = (amount) => {
  if (amount === null || amount === undefined) {
    return '';
  }
  return `$${(amount / 100).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`;
};

export const isCurrency = (value) => {
  const cleanedInput = value.replace(/[$,]/g, '');
  const decimalCount = (cleanedInput.split('.')[1] || '').length;
  const decimalCountIsMoreThanTwo = decimalCount > 2;

  const fieldValue = Number(cleanedInput);

  return (
    !Number.isNaN(fieldValue)
    && fieldValue >= 0
    && fieldValue < 1000000
    && cleanedInput !== ''
    && !decimalCountIsMoreThanTwo
  );
};

export const formatNumber = (value) => {
  if (!value) { return value; }
  const match = value.replace(/[\D]/g, '').match(/(\d{3})(\d{3})(\d{4})/);
  if (!match) { return value; }
  const [, area, start, end] = match;
  return `(${area}) ${start}-${end}`;
};

export const formatPhoneNumber = (user) => {
  if (!user) { return null; }
  if (!user.phone) return user.phone;
  const phoneExtension = user.phone_extension;
  return `${formatNumber(user.phone)}${phoneExtension ? ` x ${phoneExtension}` : ''}`;
};

export const formatZip = (zipcodes) => {
  if (!zipcodes) { return ''; }
  const zipArray = Array.isArray(zipcodes) ? zipcodes : `${zipcodes}`.split(',');
  return zipArray.map(zip => `${zip}`.trim()).filter(i => !!i).join(', ');
};

export const formatZipCodes = (zipCodes) => {
  if (!zipCodes) { return ''; }
  return zipCodes.split(/[ ]+/).join(',').replace(/,+/g, ',');
};

export const formatLocationInfo = (provider) => {
  const ordered = Object.keys(provider).sort().reduce(
    (obj, key) => {
      const data = obj;
      data[key] = provider[key];
      return data;
    },
    {},
  );

  const otherData = [];
  Object.keys(ordered).forEach(key => {
    if (!!ordered[key] && key !== 'address') {
      otherData.push(ordered[key]);
    }
  });

  if (!!provider.address && otherData.length > 0) {
    return [
      provider.address,
      otherData.join(', '),
    ];
  }
  if (provider.address) return provider.address;
  if (otherData.length > 0) return otherData.join(', ');
  return null;
};

export const formatName = ({ email, name }) => {
  if (name === email) {
    return name;
  }
  return `${name} ${email}`;
};

export const createRegex = (inputString) => {
  const sterilizedString = inputString.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  return new RegExp(`.*${sterilizedString}.*`, 'i');
};

export const convertQuoteToCents = (quote) => {
  if (quote === null || quote === undefined || quote === '') return null;
  const cleanedQuote = quote.replace(/[$,]/g, '');
  const quotedPriceInDollars = Number(cleanedQuote);
  if (Number.isNaN(quotedPriceInDollars) || quotedPriceInDollars < 0) {
    return null;
  }
  return Math.round(quotedPriceInDollars * 100);
};

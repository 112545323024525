import React from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion';
import { css } from 'emotion';

import md from 'helpers/markdown';
import { createTranslator, messageDateFormat, numeric } from 'helpers/i18n';
import withTimeStampPopup from 'components/messages/MessageUtils';
import withThemedComponents, { ThemeConfig } from 'components/utils/withThemedComponents';
import { applyTestAttribute, applyTestIdentifier } from 'helpers/development';
import {
  Panel, Styles, Tag,
} from 'components/utils';
import avatar from 'components/branding/avatar@2x.png';
import { testWhoSentMessage, setBackgroundColor, formatSender } from './functions';
import messageText from './translations';

const { Secondary, TimeAgo } = Styles;

const tr = createTranslator(messageText);

const BubbleContainer = styled.div`
  align-self: ${props => props.align};
  max-width: 450px;
  margin: 8px 0;
`;

const Bubble = styled.div`
  border-radius: 11px;
  background-color: ${props => setBackgroundColor(props.message)};
  padding: 10px 15px;
  > div {
    text-align:  justify;
  }
`;

const BubbleFooter = styled.div`
  margin: 0px 10px;
  float: ${props => props.align};
  color: #666666;
  font-size: 12px;
  line-height: 15px;
`;

const BubbleText = styled.div`
  white-space: pre-wrap;
  padding-right: 10px;
  margin-bottom: 10px;
`;

const imageStyles = css`
  width: 40px;
  height: 40px;
  border-radius: 50%;
`;

const createComponentStyles = ({ version, theme: { colors } }) => numeric(version, {
  other: {
    styles: {
      ReadContainerStyle: 'strong',
    },
  },
  v2: {
    styles: {
      helpTextStyle: css`
          border-bottom: 1px dotted ${colors.TIP_DEFAULT};
        `,
      ReadContainerStyle: styled(Tag)`
        border-radius: 20px;
        padding: 5px 10px;
      `,
    },
  },
});

const MessageBubble = ({
  message,
  message: {
    read_flag: readFlag,
    updated_at: updatedAt,
    message_content: {
      text,
      user,
      organization,
    },
  },
  mine,
  ours,
  theme: {
    styles: {
      ReadContainerStyle,
      helpTextStyle,
    },
  },
  ...props
}) => {
  const Component = () => <TimeAgo className={helpTextStyle}>{messageDateFormat(updatedAt)}</TimeAgo>;
  const EnhancedTimeAgo = withTimeStampPopup(Component);
  const alignment = ours ? 'right' : 'left';

  return (
    <BubbleContainer align={ours ? 'flex-end' : 'flex-start'} {...applyTestAttribute(props)}> {/* eslint-disable-line react/jsx-props-no-spreading */}
      <Panel spacing={5}>
        <Bubble
          align={alignment}
          message={testWhoSentMessage(mine, ours)}
        >
          <Panel orientation="horizontal" align="top">
            <img src={user?.avatar_url || avatar} alt="avatar" className={imageStyles} />
            <Panel orientation="vertical" align="left" spacing={0}>
              <Secondary>
                {mine ? md(tr('message.you')) : md(formatSender(user, (ours ? null : organization)))}
              </Secondary>
              {/* eslint-disable-next-line react/jsx-props-no-spreading */}
              <BubbleText {...applyTestIdentifier('bubble-content')}>{text}</BubbleText>
            </Panel>
          </Panel>
        </Bubble>
        <BubbleFooter align={alignment}>
          <Panel orientation="horizontal" align="center">
            {readFlag && <ReadContainerStyle>{tr('message.read')}</ReadContainerStyle>}
            <EnhancedTimeAgo item={message} position={`bottom ${alignment}`} />
          </Panel>
        </BubbleFooter>
      </Panel>
    </BubbleContainer>
  );
};
MessageBubble.propTypes = {
  message: PropTypes.shape({
    read_flag: PropTypes.bool,
    updated_at: PropTypes.string,
    message_content: PropTypes.shape({
      text: PropTypes.string,
      user: PropTypes.shape({
        avatar_url: PropTypes.string,
      }),
      organization: PropTypes.shape({
        name: PropTypes.string,
      }),
    }),
  }).isRequired,
  mine: PropTypes.bool.isRequired,
  ours: PropTypes.bool.isRequired,
  'data-testid': PropTypes.string,
  theme: ThemeConfig.isRequired,
  read_flag: PropTypes.bool,
};

MessageBubble.defaultProps = {
  'data-testid': 'messages-item',
  read_flag: false,
};

export default withThemedComponents(createComponentStyles)(MessageBubble);

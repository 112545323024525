/**
 * Note: These are all English-based. Later, for other languages, would
 * be good for the 'translations/utils' import to automatically select
 * the right utils for the current language.
 */

import { numeric, pluralize } from 'helpers/i18n';
import { DateTime } from 'helpers/dates';

/**
 * Number of days to show for relativeDays before rounding to
 * weeks and months.
 */
const DAYS_BREAK_POINT = 14;

/** Uppercase first letter in the term when uppercase is true */
const checkCase = (term, uppercase) => {
  if (term && uppercase) {
    return `${term[0].toUpperCase()}${term.slice(1)}`;
  }
  return term;
};

/** Numeric translator for ordinal counts */
export const ordinal = count => numeric(count, {
  1: '1st',
  2: '2nd',
  3: '3rd',
  other: `${count}th`,
});

/** Possessive terms, with proper apostrophe placement */
export const possessive = term => (term[term.length - 1] === 's' ? `${term}'` : `${term}'s`);

/** Gender pronouns */
export const genderPronoun = (gender, uppercase = false) => checkCase(numeric(gender, {
  F: 'she',
  M: 'he',
  other: 'they',
}), uppercase);

/** Gender possessive term */
export const genderPossessive = (gender, uppercase = false) => checkCase(numeric(gender, {
  F: 'her',
  M: 'his',
  other: 'their',
}), uppercase);

/**
 * The number of days between now and the given time, followed by
 * the formatted time. Today and tomorrow are returned as special
 * cases when the days diff is 0 and 1, respectively.
 */
export const dayAndTime = (time, uppercase = false, now = DateTime.now()) => {
  const diffDays = time.diff(now, 'days');
  const day = checkCase(numeric(diffDays, {
    '-1': 'yesterday',
    0: 'today',
    1: 'tomorrow',
    other: DateTime.dayFormat(time),
  }), uppercase);
  return `${day} at ${time.format('h:mma')}`;
};

export const timeOfDay = (time, now = DateTime.now()) => {
  const start = time;
  const fmt = start.minute() === 0 ? 'ha' : 'h[:]mma';
  let label = start.format(fmt);
  if (!now.isSame(start, 'day')) {
    label = DateTime.dayFormat(start, {
      format: `${fmt} M/D`,
      otherFormat: `${fmt} M/D/YY`,
    });
  }
  return label;
};

/**
 * Number of days between now and the given time. Today and
 * tomorrow are returned as special cases when the days diff
 * is 0 and 1, respectively. The given date should be in the future.
 */
export const relativeDaysUntil = (
  date,
  { prefix = false, uppercase = false, round = true } = {},
  now = DateTime.now(),
) => {
  const diffDays = Math.ceil(Math.abs(date.diff(now, 'days', true)));
  if (diffDays <= DAYS_BREAK_POINT || !round) {
    return checkCase(numeric(diffDays, {
      0: 'today',
      1: 'tomorrow',
      other: `${prefix ? 'in ' : ''}${diffDays} days`,
    }), uppercase);
  }
  return checkCase(now.to(date, !prefix), uppercase);
};

/**
 * Number of days since now and the given time. Today and
 * yesterday are returned as special cases when the days diff
 * is 0 and 1, respectively. The given date should be in the past.
 */
export const relativeDaysAgo = (
  date,
  { suffix = false, uppercase = false, round = true } = {},
  now = DateTime.now(),
) => {
  const diffDays = Math.floor(Math.abs(date.diff(now, 'days', true)));
  if (diffDays <= DAYS_BREAK_POINT || !round) {
    return checkCase(numeric(diffDays, {
      0: 'today',
      1: 'yesterday',
      other: `${diffDays} days${suffix ? ' ago' : ''}`,
    }), uppercase);
  }
  return checkCase(date.from(now, !suffix), uppercase);
};

/**
 * The number of hours and minutes between now and the given time.
 * If less than an hour, use "X minutes".
 * If less than a day, use "X hours and X minutes"
 * If more than a day, use relative time display
 *
 * Unlike other relative time functions, the default text is uppercased.
 * This is because this is most likely to be used standalone or at the
 * start of a sentence
 */
export const hoursAndMinutes = (time, { uppercase = true } = {}, now = DateTime.now()) => {
  if (!time) { return 'Unknown time'; }
  const diffMins = time.diff(now, 'minutes');
  const diff = Math.abs(diffMins);
  if (diff < 60) {
    return `${diff} ${pluralize(diff, 'minute')}`;
  }
  if (diff < 60 * 24) {
    const hours = parseInt(diff / 60, 10);
    const mins = diff % 60;
    return `${hours} ${pluralize(hours, 'hour')}${(mins > 0 ? ` and ${mins} ${pluralize(mins, 'minute')}` : '')}`;
  }
  const options = { uppercase };
  return diffMins > 0 ? relativeDaysUntil(time, options) : relativeDaysAgo(time, options);
};

import React from 'react';
import PropTypes from 'prop-types';
import { throttle } from 'lodash';
import styled from 'react-emotion';

import Spinner from 'components/utils/Spinner';
import { LinkText } from 'components/utils/Styles';

const LoadingContainer = styled.div`
  margin-top: 4px;
`;

/**
 * A multi-click-resisitant LinkText component. Uses ReferralActionEngine
 * to disable and re-enable the link if necessary.
 */
class NewReferralFromPatientLink extends React.Component {
  static propTypes = {
    /** The link text */
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.func]).isRequired,
    /** The engine */
    engine: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    /** Action handler */
    onReferralAction: PropTypes.func,
  }

  static defaultProps = {
    engine: undefined,
    onReferralAction: undefined,
  }

  constructor(props) {
    super(props);
    this.state = { saving: false };
    this.clickHandler = throttle(this.handleClick, 1000);
  }

  createReferral = () => {
    const { engine, onReferralAction } = this.props;
    const onBegin = (_, cb) => {
      this.setState({ saving: false }, cb);
    };
    const onComplete = () => {
      this.setState({ saving: false });
    };
    if (onReferralAction) {
      onReferralAction({
        key: 'new_for_patient',
        executeBeginHandler: onBegin,
        executeCompleteHandler: onComplete,
        errorHandler: onComplete,
      });
    } else if (engine) {
      engine.setExecuteBeginHandler(onBegin);
      engine.setExecuteCompleteHandler(onComplete);
      engine.setErrorHandler(onComplete);
      engine.handle('new_for_patient');
    }
  }

  handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!this.state.saving) {
      this.setState(({ saving: true }), () => {
        this.createReferral();
      });
    }
  }

  render() {
    if (this.state.saving) {
      return <LoadingContainer><Spinner small /></LoadingContainer>;
    }
    return (
      <LinkText data-testid="new-referral-for-patient" onClick={this.clickHandler}>
        {this.props.children}
      </LinkText>
    );
  }
}

export default NewReferralFromPatientLink;

import PropTypes from 'prop-types';
import AidinPropTypes from 'helpers/AidinPropTypes';

const Notification = PropTypes.shape({
  id: PropTypes.number.isRequired,
  /** When the notification was sent */
  created_at: AidinPropTypes.dateTime.isRequired,
  /** The thing this notification is about */
  notifiable_id: PropTypes.number,
  /** The class of the thing this notification is about */
  notifiable_type: PropTypes.oneOf(['Referral', 'Message', 'Review', 'Conversation']),
  /** True if read, false otherwise */
  read_flag: PropTypes.bool,
  /** The referral corresponding to this notification */
  referral_id: PropTypes.number,
  /** The text for the notification */
  text: PropTypes.string,
});

export default Notification;

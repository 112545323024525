import React from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion';
import Popup from 'reactjs-popup';
import { createTranslator } from 'helpers/i18n';

import { baseModalStyleAttributes } from 'components/utils/PopupUtils';
import Icon from 'components/utils/Icon';

const tr = createTranslator({
  message: 'Loading, please wait...',
});

const LoadingContainer = styled.div`
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LoadingMessage = styled.div`
  margin-top: 10px;
  font-size: 32px;
`;

const DEFAULT_DIMENSIONS = { width: 800, height: 'auto' };

const modalOverrides = {
  width: undefined,
  background: undefined,
  border: undefined,
  boxShadow: undefined,
  padding: undefined,
  zIndex: undefined,
  margin: undefined,
};

const overlayStyle = {
  backgroundColor: 'rgba(255,255,255,0.8)',
  overflowY: 'scroll',
};

/**
 * A modal popup window, pre-filled with a ContentPanel with close
 * button and optional footer with action buttons.
 */
export default class Loading extends React.Component {
  static propTypes = {
    /** The modal content */
    children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
    /** The height of the modal */
    height: PropTypes.number,
    /** Icon name, default to mp-signal */
    icon: PropTypes.string,
    /** Use a custom icon */
    iconElement: PropTypes.node,
    /** Listener for when the modal is closed */
    onClose: PropTypes.func,
    /** Listener for when the modal is opened */
    onOpen: PropTypes.func,
    /** The width of the popup */
    width: PropTypes.number,
  }

  static defaultProps = {
    children: undefined,
    height: null,
    icon: undefined,
    iconElement: undefined,
    onClose: undefined,
    onOpen: undefined,
    width: DEFAULT_DIMENSIONS.width,
  }

  /**
   * Toggle the open/close state of the popup
   * @public
   */
  toggle = () => { this.instance.togglePopup(); }

  handleClose = () => { this.instance.closePopup(); }

  render() {
    return (
      <Popup
        defaultOpen
        modal
        closeOnDocumentClick={false}
        ref={(r) => { this.instance = r; }}
        contentStyle={{
          ...modalOverrides,
          ...baseModalStyleAttributes,
          margin: '32vh auto auto',
          width: `${this.props.width || DEFAULT_DIMENSIONS.width}px`,
          height: `${(this.props.height ? `${this.props.height}px` : 'auto')}`,
        }}
        overlayStyle={overlayStyle}
        arrow={false}
        on="click"
        onOpen={this.props.onOpen}
        onClose={this.props.onClose}
        trigger={<span />}
      >
        <LoadingContainer>
          {this.props.iconElement || <Icon name={this.props.icon || 'mp-signal'} size={40} color="#333333" />}
          <LoadingMessage>
            {this.props.children || tr('message')}
          </LoadingMessage>
        </LoadingContainer>
      </Popup>
    );
  }
}

/* eslint-disable import/prefer-default-export */
import { providerTypes } from 'translations/providers';

export const careTypeCategories = {
  ...providerTypes,
  CONDITION: 'Condition',
  TREATMENT: 'Treatment',
  THERAPY: 'Therapy',
  ADL: 'ADL',
  BEHAVIOR: 'Behavior',
  NURSING: 'Nursing',
  ANCILLARY: 'Ancillary',
  HOME_SAFETY_EVAL: 'Home Safety Eval',
  PRIVATE_DUTY: 'Private Duty',
  HOSPITAL: 'Hospital',
  SURVEY: 'Survey',
  VENT_WEANING: 'Vent Weaning',
  RESPIRATORY: 'Respiratory',
  WOUND_CARE: 'Wound Care',
  LT_ID: 'LT Id',
  MEDICALLY_COMPLEX: 'Medically Complex',
  PHYSICAL_THERAPY: 'Physical Therapy',
  CARDIOVASCULAR: 'Cardiovascular',
  PRICE_RANGE: 'Price Range',
  ACTIVITIES: 'Activities',
  SERVICE_NEEDS: 'Service Needs',
  VEHICLE_TYPE: 'Vehicle Type',
  COVID_19: 'COVID-19',
  INTEGRATIVE_THERAPY: 'Integrative Therapy',
  END_OF_LIFE: 'End of Life',
  AGENCY_TYPE: 'Agency Type',
};

/* eslint-disable camelcase */

import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'emotion';
import styled from 'react-emotion';
import Message from 'models/Message';
import { Organization } from 'models/Session';
import { messageDateFormat, createTranslator, numeric } from 'helpers/i18n';
import withTimeStampPopup from 'components/messages/MessageUtils';
import withThemedComponents from 'components/utils/withThemedComponents';

import {
  Panel, Styles, Icon, CheckboxField,
} from 'components/utils';

const { Secondary } = Styles;

const tr = createTranslator({
  label: (message_content) => (message_content.user ? `${message_content.user.name} at ${message_content.organization.name}` : `${message_content.organization.name}`),
});

const ContainerBase = styled.div`
  padding: 10px;
  cursor: pointer;
  ${props => (props.unread && `background-color: ${props.theme.colors.MESSAGE_FILL};`)}
  &:hover {
    background-color: ${props => (props.unread ? 'rgba(67,121,255,0.16)' : '#f9f9f9')};
  };
`;

const PreviewAndTime = styled.div`
  display: flex;
`;

const TextPreview = styled.div`
  color: #000;
  opacity: 0.3;
  font-size: 15px;
  line-height: 21px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-grow: 1;
  margin-left: ${({ checkbox }) => checkbox && '30px'};
`;

const Sender = styled(Secondary)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: ${({ checkbox }) => checkbox && '30px'};
`;

const selfSentIconStyle = css`
  position: relative;
  top: 1px;
  margin-right: 4px;
`;

const TimeSent = styled.div`
  color: #666666;
  font-size: 12px;
  line-height: 15px;
  text-align: right;
  white-space: nowrap;
  padding-top: 2px;
  min-width: 50px;
  float: left;
  margin-left: ${({ checkbox }) => (checkbox && '30px')};
`;

const ReferralContent = styled.div`
  opacity: 0.8;
  color: #222;
  font-family: Cabin;
  font-size: 14px;
  line-height: 19px;
  white-space: nowrap;
  max-width: 160px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const createComponentStyles = ({ version, theme: { colors } }) => numeric(version, {
  other: {
    styles: {
      Container: ContainerBase,
      panelSpacing: 3,
    },
  },
  v2: {
    styles: {
      Container: styled(ContainerBase)`
        border-radius: 4px;
        margin: 5px;
        ${props => (props.isChecked ? `border: 1px solid ${colors.MESSAGE_BORDER_SELECTED};` : `border: 1px solid ${colors.MESSAGE_BORDER_UNSELECTED};`)}
      `,
      checkboxSpacingStyle: css`
        margin-right: -20px;
      `,
      panelSpacing: 5,
    },
  },
});

const ReferralDetailRowPanel = styled(Panel)`
  padding-left: ${({ checkbox }) => checkbox && '30px'};
`;

const ReferralDetailRow = ({
  checkbox, patient, referral,
}) => (
  <ReferralDetailRowPanel checkbox={checkbox} orientation="horizontal" spacing={10}>
    <ReferralContent>{patient.name}</ReferralContent>
    <ReferralContent>|</ReferralContent>
    <ReferralContent>{referral.level_of_care.join(', ')}</ReferralContent>
    <ReferralContent>|</ReferralContent>
    <ReferralContent>#{referral.id}</ReferralContent>
  </ReferralDetailRowPanel>
);
ReferralDetailRow.propTypes = {
  patient: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  referral: PropTypes.shape({
    id: PropTypes.number,
    level_of_care: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  checkbox: PropTypes.bool,
};

ReferralDetailRow.defaultProps = {
  checkbox: false,
};

const messageLabel = (message) => {
  const label = tr('label', message.message_content);
  return label;
};

const MessageListItem = ({
  checkbox, isChecked, handleCheck, onClick, message, organizations, lastMessageReceived,
  theme: {
    styles: {
      Container,
      panelSpacing,
      checkboxSpacingStyle,
    },
  },
}) => {
  const Component = () => (
    <TimeSent checkbox={checkbox}>
      {messageDateFormat(message.created_at)}
    </TimeSent>
  );
  const EnhancedTimeAgo = withTimeStampPopup(Component);

  return (
    <Container
      onClick={onClick}
      isChecked={isChecked}
      unread={(
        lastMessageReceived
          ? !lastMessageReceived.read_flag
          : !message.read_flag && organizations.some(({ id, organization_type }) => (
            id === message.organization.id && organization_type === message.organization_type
          ))
      )}
    >
      <Panel spacing={panelSpacing}>
        <Panel spacing={0} orientation="horizontal">
          {(checkbox && lastMessageReceived) && (
            <CheckboxField
              large // V2+ only prop
              onChange={checked => handleCheck(checked, lastMessageReceived.id)}
              value={isChecked}
              noClick
              className={checkboxSpacingStyle}
            />
          )}
          <EnhancedTimeAgo onClick={onClick} item={message} position="bottom left" />
        </Panel>
        <Sender onClick={onClick} checkbox={checkbox}>
          {organizations.some(({ id, organization_type }) => (
            id !== message.organization.id && organization_type !== message.organization_type
          ))
            && <Icon className={selfSentIconStyle} color="#000" size={20} name="mp-arrow-left" />}
          {messageLabel(message)}
        </Sender>
        <PreviewAndTime>
          <TextPreview checkbox={checkbox} width="80%">{message.message_content.text}</TextPreview>
        </PreviewAndTime>
        <ReferralDetailRow
          onClick={onClick}
          patient={message.message_content.patient}
          referral={message.message_content.referral}
          checkbox={checkbox}
        />
      </Panel>
    </Container>
  );
};
MessageListItem.propTypes = {
  onClick: PropTypes.func.isRequired,
  organizations: PropTypes.arrayOf(Organization).isRequired,
  message: Message.isRequired,
  checkbox: PropTypes.bool,
  isChecked: PropTypes.bool,
  handleCheck: PropTypes.func,
  lastMessageReceived: PropTypes.shape({
    id: PropTypes.number.isRequired,
    read_flag: PropTypes.bool.isRequired,
    referral_id: PropTypes.number.isRequired,
  }),
};

MessageListItem.defaultProps = {
  checkbox: false,
  isChecked: undefined,
  handleCheck: undefined,
  lastMessageReceived: undefined,
};

export default withThemedComponents(createComponentStyles)(MessageListItem);

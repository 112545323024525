/* eslint-disable camelcase */

import { replacePreferences } from 'ducks/session/actions';
import apiDirector from 'helpers/ApiDirector';
import GlobalModal, { modals } from 'helpers/GlobalModal';
import { DateTime } from 'helpers/dates';
import { openPatientVisitChooserModal } from 'ducks/ui';
import { FEATURE_ASSIGN_PATIENT_VISIT_DOCUMENT_VIEW } from 'ducks/session/types';
import { shouldDisableFeatureWithoutFlagEnabled } from 'models/Session';
import {
  parseDocumentStatus,
  reshapeAuthResponse,
  reshapePatient,
} from './functions';
import * as actions from '../actions';

const delay = time => new Promise(resolve => setTimeout(resolve, time));

export const shapeDocument = (document, {
  answers: defaultAnswers = {},
  suggestions: defaultSuggestions = [],
  version = 0,
} = {}) => ({
  ...document,
  version,
  suggested_answers: {
    ...document.suggested_answers,
    ...defaultAnswers,
  },
  name: document.name || document.full_name || document.file_file_name,
  archived: !!(document.meta || {}).archived_at,
  status: parseDocumentStatus(document),
  document_scans: [
    {
      document_scan_results: [
        ...(defaultSuggestions.concat(document.inbound_scan_results || []).map(
          (doc = {}) => {
            if (doc.name === 'AIDIN_PATIENT' && doc.match) {
              return {
                ...doc,
                match: reshapePatient(doc.match, document),
              };
            }
            if (doc.name === 'AIDIN_AUTH_RESPONSE' && doc.value) {
              return reshapeAuthResponse(doc);
            }
            return doc.match === null ? { ...doc, match: undefined } : doc;
          },
        ) || []).filter(Boolean),
      ],
    },
  ],
});

const shapeDocuments = documents => documents.map(shapeDocument);

export const loadInboundRequest = (id, defaultValues, options = {}) => dispatch => {
  let url = `/api/inbox/inbound/${id}`;

  if (options.confirm_viewer_change) {
    url += '?confirm_viewer_change=true';
  }

  return apiDirector.validateFetch(url)
    .then((json) => shapeDocument(json, defaultValues))
    .then((json) => {
      if (typeof options.onLoad === 'function') {
        options.onLoad(json);
      }
      return json;
    })
    .catch((e) => {
      const { code } = e.response;
      if (code === 409) {
        GlobalModal.open(modals.TOGGLE_CURRENT_VIEWER_CONFIRM_MODAL, {
          onSubmit: () => {
            dispatch(loadInboundRequest(id, defaultValues, {
              ...options,
              confirm_viewer_change: true,
            }));
          },
          onCancel: options.onCancel,
          onClose: () => {
            GlobalModal.close(modals.TOGGLE_CURRENT_VIEWER_CONFIRM_MODAL);
          },
        });
      } else {
        throw e;
      }
    });
};


export const splitInboundDocument = (id, archive_document, sections) => () => apiDirector.validateFetch(`/api/inbound_requests/${id}`, {
  method: 'PATCH',
  body: JSON.stringify({
    id,
    archive_document,
    sections,
  }),
});

export const manualUploadForInboundDocument = (from, to, files) => () => {
  if (!files || files.length === 0) { return Promise.resolve(true); }

  const meta = { source: 'inbound_fax' };
  const form = new FormData();
  files.forEach((file) => { form.append('files[]', file, file.name); });
  form.append('attachable_id', to.id);
  form.append('attachable_type', to.organization_type || to.type);
  form.append('organization_id', to.id);
  form.append('organization_type', to.organization_type || to.type);
  if (from) {
    if (from.id) {
      form.append('from_id', from.id);
    }
    if (from.fax) {
      form.append('from_number', from.fax);
    }
    meta.from_id = from.id;
    meta.from_name = from.name;
    meta.from_number = from.fax;
  }
  form.append('attachment[meta]', JSON.stringify(meta));

  return apiDirector.validateFetch('/api/inbound_requests', {
    method: 'POST',
    body: form,
  }, {
    Accept: 'application/json',
  });
};

export const replaceInboundDocument = document => dispatch => dispatch(actions.replaceDocument(
  shapeDocument(document, { version: 1 }),
  'inbound',
));

export const requestInboundDocuments = params => dispatch => apiDirector.validateFetch(`/api/inbox/inbound${params ? `?${params}` : ''}`)
  .then(documentsInfo => ({
    ...documentsInfo,
    content: shapeDocuments(documentsInfo.content),
    stage: [],
  }))
  .then(documentsInfo => dispatch(actions.replaceDocuments(documentsInfo, 'inbound')))
  .then(({ documentsInfo: { aidin_inbox, values } }) => {
    dispatch(replacePreferences({ aidin_inbox, aidin_inbox_values: values }, true));

    return aidin_inbox;
  });

export const getRefreshDuplicateDetectedIds = (library, document) => {
  const start = DateTime.parse(document.created_at);
  const finish = DateTime.parse(document.created_at).add(1, 'hours');
  const ids = library.filter(libraryDocument => {
    const between = DateTime.parse(libraryDocument.created_at);
    return (start < between && between < finish) ? libraryDocument.id : null;
  }).map(d => d.id);
  return ids;
};

export const stageInboundDocument = (document, visible, immediate) => (dispatch, getState) => dispatch(actions.stageDocument(
  shapeDocument(document, { version: 1 }),
  'inbound',
  visible,
  immediate,
))
  .then(() => {
    if (!visible) {
      delay(2000).then(() => dispatch(actions.removeDocument(
        shapeDocument(document),
        'inbound',
      )));
    }
    const {
      documents: {
        inbound: {
          content,
          stage,
        },
      },
    } = getState();
    const contentIds = getRefreshDuplicateDetectedIds(content, document);
    const stageIds = getRefreshDuplicateDetectedIds(stage, document);
    const ids = contentIds.concat(stageIds);
    if (ids.length > 0) {
      apiDirector.validateFetch('/api/inbox/inbound/refresh_duplicate_detected', {
        method: 'POST',
        body: JSON.stringify({ ids }),
      }).then(refreshData => dispatch(actions.refreshDuplicateDetected(
        refreshData,
        'inbound',
      )));
    }
  });
export const loadStagedInboundDocuments = () => dispatch => dispatch(actions.loadStagedDocuments(
  'inbound',
));

export const archiveInboundDocument = (documentId, archived_at) => dispatch => apiDirector.validateFetch(`/api/inbox/inbound/${documentId}`, {
  method: 'PATCH',
  body: JSON.stringify({
    document: {
      meta: {
        archived_at,
      },
    },
  }),
})
  .then(shapeDocument)
  .then(document => dispatch(actions.removeDocument(document, 'inbound')));

export const renameInboundDocument = (documentId, name) => dispatch => apiDirector.validateFetch(`/api/inbox/inbound/${documentId}`, {
  method: 'PATCH',
  body: JSON.stringify({
    document: {
      name,
    },
  }),
})
  .then(shapeDocument)
  .then(document => dispatch(actions.replaceDocument(document, 'inbound')));

export const createAttachableForInboundDocument = (documentId, attachable, source = 'inbound', event_type = '') => dispatch => apiDirector.validateFetch(`/api/inbox/inbound/${documentId}/document_scan_results${event_type ? `?event_type=${event_type}` : ''}`, {
  method: 'POST',
  body: JSON.stringify({
    ...attachable,
  }),
})
  .then(shapeDocument)
  .then(document => dispatch(actions.replaceDocument(document, source)));

export const assignAttachableToInboundDocument = (attchmentId, documentId, attachable, source = 'inbound') => dispatch => apiDirector.validateFetch(`/api/inbox/inbound/${attchmentId}/document_scan_results/${documentId}`, {
  method: 'PATCH',
  body: JSON.stringify({
    ...attachable,
  }),
})
  .then(shapeDocument)
  .then(document => dispatch(actions.replaceDocument(document, source)));

export const assignPatientToDocument = (documentId, patient) => (dispatch, getState) => {
  const { session } = getState();
  const hasPatientVisitFeatureFlag = !shouldDisableFeatureWithoutFlagEnabled(
    session,
    FEATURE_ASSIGN_PATIENT_VISIT_DOCUMENT_VIEW,
  );
  const patientAttachDetails = {
    match_id: patient.id,
    match_type: 'Patient',
    name: 'AIDIN_PATIENT',
    value: patient.name,
    score: 100,
  };

  if (!hasPatientVisitFeatureFlag) {
    return dispatch(createAttachableForInboundDocument(documentId, {
      document_scan_results: [{ ...patientAttachDetails }],
    }));
  }

  return dispatch(openPatientVisitChooserModal(patient, {
    documentId,
    query: { scope: 'inbound' },
    callback: (visit) => {
      const matches = [];
      const visitMatch = {
        match_type: 'PatientVisit',
        match_id: visit.id,
        score: 100,
      };
      if (visit.patient_visit_id) {
        visitMatch.name = 'AIDIN_PATIENT_VISIT_CSN';
        visitMatch.value = visit.patient_visit_id;
      } else if (visit.mrn) {
        visitMatch.name = 'AIDIN_PATIENT_VISIT_MRN';
        visitMatch.value = visit.mrn;
      } else {
        visitMatch.name = 'AIDIN_PATIENT_VISIT';
        visitMatch.value = patient.name;
      }
      matches.push(visitMatch);
      matches.push(patientAttachDetails);
      dispatch(createAttachableForInboundDocument(documentId,
        { document_scan_results: matches }));
      GlobalModal.close(modals.TOGGLE_CREATE_REFERRAL_CONFLICT_MODAL);
    },
  }));
};

/* eslint-disable import/prefer-default-export, camelcase */
import { flatten } from 'lodash';
import { replacePreferences } from 'ducks/session/actions';
import GlobalModal, { modals } from 'helpers/GlobalModal';
import { Date, DateTime } from 'helpers/dates';
import apiDirector from 'helpers/ApiDirector';
import { numeric } from 'helpers/i18n';
import { replaceReferralIfNecessary } from 'ducks/referrals/operations';
import * as actions from '../actions';

const parseOutboundDocumentStatus = (document) => {
  switch (document.status) {
    case 'new_patient':
    case 'new_list_created':
    case 'in_process':
      return 'unsent';
    case 'provider_responses_closed':
      return 'error';
    default:
      return 'sent';
  }
};

const shapeOutboundDocument = document => ({
  ...document,
  attachments: document.attachments.map(a => ({
    ...a,
    name: a.name || a.full_name || a.file_file_name,
    response_requested: {
      request_types: [],
    },
  })),
  communication_records: flatten((document.sent_referrals || [])
    .filter(({ confirmed }) => confirmed)
    .map(({ communication_records: cr }) => cr)),
  archived: (document.tasks || []).some(t => t.task_type === 'discharge' && t.completed_at),
  status: parseOutboundDocumentStatus(document),
  referralRole: 'sending',
});

const shapeOutboundDocuments = documents => documents.map(shapeOutboundDocument);

export const replaceOutboundDocument = document => dispatch => dispatch(actions.replaceDocument(
  shapeOutboundDocument(document),
  'outbound',
));

export const requestOutboundDocuments = params => dispatch => apiDirector.validateFetch(`/api/inbox/outbound${params ? `?${params}` : ''}`)
  .then(documentsInfo => ({
    ...documentsInfo,
    content: shapeOutboundDocuments(documentsInfo.content),
  }))
  .then(documentsInfo => dispatch(actions.replaceDocuments(documentsInfo, 'outbound')))
  .then(({ documentsInfo: { aidin_inbox } }) => {
    dispatch(replacePreferences({ aidin_inbox }, true));

    return aidin_inbox;
  });

export const updateOutboundDocumentReferral = (id, tasks) => dispatch => apiDirector.validateFetch(`/api/checklists/${id}`, {
  method: 'PATCH',
  body: JSON.stringify({
    transaction: {
      tasks: !tasks || Array.isArray(tasks) ? tasks : [tasks],
    },
  }),
})
  .then(shapeOutboundDocument)
  .then(document => dispatch(actions.replaceDocument(document, 'outbound')));

export const assignAttachableToOutboundReferral = (documentId, attachable) => dispatch => (
  dispatch(updateOutboundDocumentReferral(documentId, {
    task_type: 'assign_documents',
    complete: true,
    payload: attachable,
  }))
);

export const archiveOutboundDocument = (documentId, archived_at) => dispatch => (
  dispatch(updateOutboundDocumentReferral(documentId, {
    task_type: 'archive_status',
    payload: {
      archived_at,
    },
  })).then(({ document }) => dispatch(actions.removeDocument(document, 'outbound')))
);

/**
 * Sends a document referral to providers.
 */
export const sendOutboundDocumentReferral = (id, {
  options: {
    saveProvider = false,
  } = {},
  provider,
}, saveOnly = false) => dispatch => {
  const tasks = [];
  tasks.push({
    task_type: 'new_list_created',
    complete: true,
    payload: {
      saveProvider,
      providers: [provider],
    },
  });
  if (!saveOnly) {
    tasks.push({
      task_type: 'submit',
      complete: true,
      payload: {
        referral: {
          discharge_date: Date.utc(Date.now()),
          time_window_closes_at: DateTime.utc(DateTime.now().add(10, 'minutes')),
        },
      },
    });
  }

  if (tasks.length === 0) { return Promise.resolve({}); }

  return dispatch(updateOutboundDocumentReferral(id, tasks));
};

/**
 * Can be used to simply upload a document OR upload and send.
 *
 * To immediately send an uploaded file via fax to someone,
 * the meta prop is expected to contain (optionally):
 *  - to_name
 *  - to_number
 *  - from_name
 *  - from_number
 *  - notes
 *
 * The options can include:
 *  - attachable (id/type), such as a patient or referral. Default to organization
 *  - saveProvider - true if updating the provider fax number, or adding a new provider
 *
 * Note that if a to_number is specified in meta, this will trigger the send fax behavior.
 *
 * This will always create a new referral unless opts[referral] is given.
 */
export const createOutboundDocumentReferral = (
  organization, files, meta, opts = {},
) => (dispatch, getState) => {
  if (!files || files.length === 0) {
    return Promise.resolve(true);
  }

  const {
    session: {
      user: {
        name,
      },
    },
  } = getState();

  const options = {
    attachable_id: organization.id,
    attachable_type: organization.organization_type,
    ...opts,
  };

  const transaction = {
    tasks: [],
  };
  let checklistConfigurationType = 'doc';

  if (!options.referral) {
    transaction.referral = {
      hospital_id: organization.id,
      hospital_type: organization.organization_type,
      patient_visit_id: options?.patient_visit_id,
    };
  }

  const newProviderPayload = {};
  if (options.fileNames) {
    newProviderPayload.fileNames = JSON.stringify(options.fileNames);
  }
  if (options.form_ids) {
    newProviderPayload.form_ids = JSON.stringify(options.form_ids);
  }
  if (options.responseRequested) {
    newProviderPayload.response_requested = JSON.stringify(options.responseRequested);
  }
  newProviderPayload.saveMode = 'chunked';
  newProviderPayload.attachable_id = options.attachable_id;
  newProviderPayload.attachable_type = options.attachable_type;
  newProviderPayload.organization_id = organization.id;
  newProviderPayload.organization_type = organization.organization_type;
  newProviderPayload.attachment = {
    meta: JSON.stringify({
      source: 'aidin_inbox',
      from_name: name,
      ...meta,
    }),
  };

  transaction.tasks.push({
    task_type: 'new_patient',
    complete: true,
    payload: newProviderPayload,
  });

  if (options.provider || (meta && meta.to_number)) {
    const newListPayload = {};
    if (options.provider) {
      newListPayload.saveProvider = options.saveProvider;
      newListPayload.providers = [options.provider];
    } else {
      newListPayload.providers = [{
        id: meta.to_contact_id,
        name: meta.to_name,
        [meta.communication_type]: meta.to_number,
        communication_type: meta.communication_type,
        provider_type: meta.to_level_of_care,
      }];
    }

    transaction.tasks.push({
      task_type: 'new_list_created',
      complete: true,
      payload: newListPayload,
    });
  }

  const allowedAssignees = ['Patient', 'Referral'];
  if (allowedAssignees.some(i => i === options.attachable_type)) {
    transaction.tasks.push({
      task_type: 'assign_documents',
      complete: true,
      payload: {
        attachable_id: options.attachable_id,
        attachable_type: options.attachable_type,
      },
    });
  }

  if (transaction.tasks.some(t => t.task_type === 'new_list_created' && t.complete)) {
    transaction.tasks.push({
      task_type: 'submit',
      complete: true,
      payload: {
        referral: {
          discharge_date: Date.utc(Date.now()),
          time_window_closes_at: meta?.deadline,
          notes: meta?.notes,
        },
        from_number: meta?.from_number,
      },
    });
  }

  if (options.connect_provider_referral) {
    transaction.tasks.push({
      task_type: 'connect_provider_referral',
      complete: true,
      payload: {
        name: options.attachment.name,
        identifier: options.attachment.identifier,
        referral_id: options.connect_provider_referral,
        to_number: options.provider.fax,
        on_conflict: options.onConflict,
      },
    });
  }

  if (options.connectedInboundDocumentId) {
    transaction.tasks.push({
      task_type: 'connect_inbound_document',
      complete: true,
      payload: {
        attachment_id: options.connectedInboundDocumentId,
      },
    });
  }

  if (options.responseRequested) {
    const actualResponses = options.responseRequested
      .filter(i => i && i.request_types && i.request_types.length > 0);
    if (actualResponses.length > 0) {
      checklistConfigurationType = 'sign';
      transaction.tasks.push({
        task_type: 'respond',
        complete: true,
        payload: {
          not_empty: true,
        },
      });
    }
  }

  const form = new FormData();
  files.forEach((file) => {
    form.append('files[]', file);
    form.append('form_ids[]', file.form_id || '');
  });
  form.append('checklist_configuration', checklistConfigurationType);
  form.append('transaction', JSON.stringify(transaction));

  if (options.referral) {
    return apiDirector.validateFetch(`/api/checklists/${options.referral.id}`, {
      method: 'PATCH',
      body: form,
    }, {
      Accept: 'application/json',
    }).then((result) => {
      dispatch(requestOutboundDocuments());
      return result;
    });
  }

  return apiDirector.validateFetch('/api/checklists', {
    method: 'POST',
    body: form,
  }, {
    Accept: 'application/json',
  }).then((result) => {
    if (!options.connect_provider_referral) {
      dispatch(requestOutboundDocuments());
    }
    return result;
  });
};

export const onDocumentResponseUpdate = ({
  files, transactions, id,
}) => (dispatch) => {
  const transaction = {
    tasks: [],
  };

  transaction.tasks.push(...transactions);

  const form = new FormData();
  files.forEach((file) => { form.append('files[]', file); });
  form.append('transaction', JSON.stringify(transaction));

  return apiDirector.validateFetch(`/api/checklists/${id}`, {
    method: 'PATCH',
    body: form,
  }, {
    Accept: 'application/json',
  }).then(json => dispatch(replaceReferralIfNecessary(id, null, json)));
};

export const markResponseRequestAsComplete = (id, reservedProviderId) => dispatch => {
  const transaction = {
    tasks: [
      {
        task_type: 'mark_as_complete',
        complete: true,
        payload: {
          provider_id: reservedProviderId || null,
        },
      },
    ],
  };

  return apiDirector.validateFetch(`/api/checklists/${id}`, {
    method: 'PATCH',
    body: JSON.stringify({ transaction }),
  }).then(json => dispatch(replaceReferralIfNecessary(id, null, json)));
};

export const handleSendToProvider = (
  { patient_visit, patient, organization },
  attachments,
  options = {},
) => () => {
  const {
    recipient,
  } = options;
  const getPatientResponseDefaults = (type) => ({
    request_types: ['patient_response'],
    patient_response_type: type,
  });
  const defaultPayload = { request_types: [] };
  const getResponseRequested = (item) => {
    const givenTypes = item?.response_requested?.request_types;
    if (!givenTypes) {
      return defaultPayload;
    }
    return item.response_requested;
  };
  const getResponseDefaults = (item) => numeric(recipient, {
    patient: numeric((item.id || '').toString().split('?')[0], {
      'generated-dissemination': getPatientResponseDefaults('dissemination'),
      'generated-list': getPatientResponseDefaults('reservation'),
      other: getResponseRequested(item),
    }),
    other: getResponseRequested(item),
  });
  const selection = attachments.map((item) => ({
    ...item,
    response_requested: getResponseDefaults(item),
  }));
  return GlobalModal.open(modals.TOGGLE_NEW_DOCUMENT_MODAL, {
    ...options,
    organization,
    document: {
      patient,
      attachments: selection,
      patient_visit,
    },
  });
};

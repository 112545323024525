import Config from 'config';
import { DateTime } from 'helpers/dates';
import ResponseError from 'helpers/ResponseError';
import mayRollbar from 'helpers/mayRollbar';
import { disableErrorHandling } from 'helpers/ErrorHandler';
import { viewOnlyToast } from 'helpers/ui';

const store = require('store');

class ApiDirector {
  constructor(base = '') {
    this.base = base;
    this.authToken = null;
    this.forceHttpMethodOverride = false;
  }

  fetch = (uri, init = {}, headers = { Accept: 'application/json', 'Content-Type': 'application/json' }) => {
    const authorizedHeaders = {
      ...headers,
      'X-CSRF-Token': this.csrf(),
    };
    if (this.authToken && this.authToken !== 'managed') {
      authorizedHeaders.Authorization = this.authToken;
    }
    if (Config.version) {
      const { version: localVersion } = Config.version || {};
      authorizedHeaders['AIDIN-REQUEST-VERSION-CHECK'] = localVersion;
    }
    const directedInit = {
      credentials: 'include',
      ...init,
      headers: authorizedHeaders,
    };
    const standardHeaders = ['GET', 'POST'];
    if (this.forceHttpMethodOverride
        && directedInit.method
        && !standardHeaders.some(h => h === directedInit.method.toUpperCase())) {
      directedInit.headers['X-HTTP-Method-Override'] = directedInit.method;
      directedInit.method = 'POST';
    }
    return fetch(`${this.base}${uri}`, directedInit);
  }

  csrf = () => {
    const elem = document.querySelector('meta[name="csrf-token"]');
    return !elem ? null : elem.getAttribute('content');
  }

  setAuthToken = (authToken) => {
    this.authToken = authToken;
  }

  setForceHttpMethodOverride = (value) => {
    this.forceHttpMethodOverride = value;
  }

  setSoftwareVersionConflictHandler = (func) => {
    this.softwareVersionConflictHandler = func;
  }

  handleFetchErrors = (entity) => {
    const { response, entity: json, fatal } = entity;
    if (!response.ok || fatal) {
      const err = fatal || new ResponseError(response, json);
      const { status } = response;
      if (mayRollbar.isAvailable()) {
        // if unauthorized (401) or not allowed (403), no need to Rollbar
        if (status === 401 || status === 403) {
          // Maybe warning? Most of these are "invalid email or password" so probably not.
          // mayRollbar.warning(err.message, err);
        } else if (status === 423) {
          // Error due to view-only
          viewOnlyToast(err.message);
        } else if (status === 404) {
          // Do we care if something is missing? At most, this is a warning.
          // mayRollbar.warning(err.message, err);
        } else if (status === 409) {
          // We never care about conflict errors, these will be resolved by users; at most, debug
          // mayRollbar.debug(err.message, err);
        } else if (status === 400) {
          if (err.message === 'form_errors') {
            // We KNOW these are validation errors, probably don't need to report.
            // mayRollbar.warning(err.message, err);
          } else {
            // We still probably don't need to be reporting 400, those tend to mean user error.
            // Going to report for now, though.
            mayRollbar.error(err.message, err, json);
          }
        } else {
          // 500s, anything not handle-able. Unexpected and should go to Rollbar
          // otherwise, always Rollbar this error, even if the throw is handled
          mayRollbar.error(err.message, err, json);
        }
      }
      throw err;
    } else {
      // Mark the session as active
      if (store && store.enabled) {
        store.set('idleTimerLastActivity', DateTime.now().toDate().getTime());
        store.set('idleTimerLoggedOut', false);
        store.set('idleSessionActive', true);
      }
      return Promise.resolve(json);
    }
  }

  handleTimeoutError = (error) => {
    if (error.status === 401) {
      if (store && store.enabled) {
        store.set('idleTimerLoggedOut', true);
      }
      fetch('/api/session/timeout').then(() => {
        window.location.href = '/devise_users/sign_in';
      }).catch(() => {
        window.location.href = '/devise_users/sign_in';
      });
    }
    disableErrorHandling();
    throw error;
  }

  parseJSON = (response) => {
    if (response.status === 204) {
      return Promise.resolve({ entity: {} });
    }
    const remoteVersion = response.headers.get('aidin-response-version-check');
    const { version: localVersion } = Config.version || {};
    if (localVersion && remoteVersion
      && remoteVersion !== localVersion && this.softwareVersionConflictHandler) {
      this.softwareVersionConflictHandler(remoteVersion, localVersion);
    }
    const contentType = response.headers.get('content-type');
    if (!contentType || !contentType.includes('application/json')) {
      // eslint-disable-next-line no-console
      console.log('Headers for failed request', Array.from(response.headers.entries()).reduce((set, [k, v]) => ({
        ...set,
        [k]: v,
      }), {}));
      return response.text().then(entity => ({
        entity,
        fatal: new Error('A fatal error has occurred, service unavailable'),
      }));
    }
    return response.json().then(entity => ({ entity })).catch(fatal => ({ fatal }));
  };

  validateFetch = (uri, init, headers) => this.fetch(uri, init, headers)
    .then(response => this.parseJSON(response)
      .then(({ fatal, entity }) => ({
        success: !!response.ok,
        response,
        entity,
        fatal,
      }))
      .then(this.handleFetchErrors))
    .catch(this.handleTimeoutError);

  validateFetchWithoutCatch = (uri, init, headers) => this.fetch(uri, init, headers)
    .then(response => this.parseJSON(response)
      .then(({ fatal, entity }) => ({
        success: !!response.ok,
        response,
        entity,
        fatal,
      }))
      .then(this.handleFetchErrors));
}

const apiDirector = new ApiDirector();
export default apiDirector;

/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { StateModel } from 'models/System';
import SelectField from 'components/utils/SelectField';

/**
 * A connected SelectField that allows for choosing a
 * state. If you want access to the ref, you must use
 * ref.getWrappedComponent(). Takes the same arguments
 * as a SelectField.
 */

const StateSelectField = React.forwardRef(({ states = [], ...passThrough }, ref) => (
  <SelectField
    ref={ref}
    items={states.map(({ id, abbreviation }) => ({
      label: abbreviation,
      value: id,
    }))}
    {...passThrough}
  />
));
StateSelectField.propTypes = {
  states: PropTypes.arrayOf(StateModel),
};
StateSelectField.defaultProps = {
  states: [],
};

export default connect(({ system }) => ({ states: system.states }), null, null,
  { forwardRef: true })(StateSelectField);

/* eslint-disable react/no-danger */
import React from 'react';
import marker from 'helpers/markdown';

const parse = (value, htmlOverride = false, onAction) => {
  if (htmlOverride) {
    return <span dangerouslySetInnerHTML={{ __html: value }} />;
  }
  return marker(value, onAction);
};

export default parse;

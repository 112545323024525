/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'emotion';
import styled from 'react-emotion';
import { mq } from 'components/utils/Responsive';
import { Label } from 'components/utils/Styles';
import Panel from 'components/utils/Panel';

import { applyTestAttribute, applyTestIdentifier } from 'helpers/development';

const COLOR_COMPLETE = '#a07600';
const COLOR_CURRENT = '#222222';
const COLOR_INCOMPLETE = '#333333';

const highlightedStates = [
  // Sending
  'sent',
  'reserved',
  // Receiving
  'responded',
  'updated',
  'discharged',
  'in_care',
];

const Sizes = {
  small: {
    elements: ['20px', '20px', '14px'],
    icons: {
      size: ['8px', '8px', '6px'],
      opacity: '0.3',
    },
    incomplete: ['6px', '6px', '4px'],
    text: ['12px', '12px', '9px'],
    divider: {
      width: '52%',
      top: '4px',
      left: '75%',
    },
  },
  large: {
    elements: ['80px', '80px', '55px'],
    icons: {
      size: ['12px', '12px', '9px'],
    },
    incomplete: ['10px', '10px', '7px'],
    text: ['12px', '12px', '9px'],
    divider: {
      width: '82%',
      top: '5px',
      left: '59%',
    },
  },
};

const Container = styled.div`
  position: relative;
  display: flex;
  margin-top: 5px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

const LargeElementContainer = styled.div(mq({
  textAlign: 'center',
  position: 'relative',
  width: Sizes.large.elements,
}));

const SmallElementContainer = styled.div(mq({
  textAlign: 'center',
  position: 'relative',
  width: Sizes.small.elements,
}));

const SmallLabel = styled(Label)`
  display: inline-block;
  margin-top: 8px;
`;

const baseIconStyle = style => css(mq({
  position: 'relative',
  transform: 'rotate(45deg)',
  textAlign: 'center',
  margin: '0px auto',
  width: style.size,
  height: style.size,
  opacity: style.opacity,
  top: ['0px', '0px', '1px'],
}));

const iconStyles = (primaryColor, { incomplete }) => ({
  complete: css`
    background-color: ${primaryColor};
  `,
  current: css`
    background-color: ${COLOR_CURRENT};
  `,
  incomplete: css(mq({
    width: incomplete,
    height: incomplete,
    opacity: '0.3',
    border: `1px solid ${COLOR_INCOMPLETE}`,
  })),
  stopped: css`
    opacity: 0.3;
    background-color: ${COLOR_INCOMPLETE};
  `,
});

const baseTextStyle = textSize => css(mq({
  height: '12px',
  opacity: '0.8',
  fontFamily: 'futura-pt',
  fontSize: textSize,
  fontWeight: '500',
  lineHeight: '1.33',
  letterSpacing: '0.2px',
  textAlign: 'center',
  textTransform: 'uppercase',
  marginTop: '8px',
}));

const textStyles = primaryColor => ({
  complete: css`
    color: ${primaryColor};
  `,
  current: css`
    color: ${COLOR_CURRENT};
  `,
  incomplete: css`
    opacity: 0.3;
    color: ${COLOR_INCOMPLETE};
  `,
  stopped: css`
    opacity: 0.3;
    color: ${COLOR_INCOMPLETE};
  `,
});

const Divider = styled.div`
  width: ${props => props.size.width};
  height: 1px;
  position: absolute;
  top: ${props => props.size.top};
  left: ${props => props.size.left};
  background-color: ${props => props.color};
  opacity: ${props => (props.small || props.color === COLOR_INCOMPLETE ? '0.3' : '1')};
`;

const getLabel = (labels, value) => {
  if (!labels) { return value; }
  if (typeof labels === 'function') {
    return labels(value);
  }
  return labels[value] || value;
};

const DiamondProgressBar = (props) => {
  let found = false;
  const elements = [];

  const isHighlighted = typeof props.highlightSelected === 'undefined'
    ? highlightedStates.some(value => value === props.value)
    : props.highlightSelected;

  props.options.forEach((text, idx) => {
    // eslint-disable-next-line no-nested-ternary
    const progressState = props.stopped ? 'stopped' : found ? 'incomplete' : props.value === text ? 'current' : 'complete';
    const primaryColor = props.stopped ? COLOR_INCOMPLETE : props.color || COLOR_COMPLETE;
    found = found || progressState === 'current';
    const ElementContainer = props.small ? SmallElementContainer : LargeElementContainer;
    const size = props.small ? Sizes.small : Sizes.large;
    const element = (
      <ElementContainer key={text} {...applyTestIdentifier(`stepsCard-${text}`)}>
        <div className={`${baseIconStyle(size.icons)} ${iconStyles(primaryColor, size)[progressState === 'current' && isHighlighted ? 'complete' : progressState]}`} />
        {!props.small && !props.hideLabels && (
          <div className={`${baseTextStyle(size.text)} ${textStyles(primaryColor)[progressState === 'current' && isHighlighted ? 'complete' : progressState]}`}>
            {getLabel(props.labels, text)}
          </div>
        )}
        {idx + 1 < props.options.length
          && (
          <Divider
            key={`div-${text}`}
            small={props.small}
            size={size.divider}
            color={found ? COLOR_INCOMPLETE : primaryColor}
          />
          )}
      </ElementContainer>
    );
    elements.push(element);
  });

  return (
    <Panel
      {...applyTestAttribute(props) /* eslint-disable-line react/jsx-props-no-spreading */}
      spacing={8}
      align="center"
      orientation="horizontal"
    >
      {props.small && !props.hideLabels && (
        <SmallLabel>
          {getLabel(props.labels, props.value)}
        </SmallLabel>
      )}
      <Container>
        {elements}
      </Container>
    </Panel>
  );
};
DiamondProgressBar.propTypes = {
  /** Complete style color */
  color: PropTypes.string,
  /** True to hide label, false otherwise */
  hideLabels: PropTypes.bool,
  /** True to color the selected option, false for black indicator */
  highlightSelected: PropTypes.bool,
  /** The options to display */
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  /**
   * Can either be a function that receives the value and returns
   * its label, or a hash of labels, where the keys match up with
   * options, and the values are the display text
   */
  labels: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({})]),
  /** True to display smaller version, false otherwise */
  small: PropTypes.bool,
  /** True when progress has been halted */
  stopped: PropTypes.bool,
  /** The currently selected progress indicator */
  value: PropTypes.string.isRequired,
};
DiamondProgressBar.defaultProps = {
  color: COLOR_COMPLETE,
  highlightSelected: undefined,
  hideLabels: false,
  labels: {},
  small: false,
  stopped: false,
};

export default DiamondProgressBar;

/* eslint-disable react/no-array-index-key */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion';
import { cx } from 'emotion';

import { applyTestAttribute } from 'helpers/development';

const flexTranslation = {
  top: 'flex-start',
  left: 'flex-start',
  center: 'center',
  right: 'flex-end',
  bottom: 'flex-end',
};

const Container = styled.div`
  width: ${props => (props.fullWidth ? '100%' : 'auto')};
  display: flex;
  flex-direction: ${props => (props.orientation === 'horizontal' ? 'row' : 'column')};
  ${props => props.align && `align-items: ${flexTranslation[props.align] || props.align};`}
`;

const Item = styled.div`
  margin-left: ${props => (props.orientation === 'horizontal' ? `${props.margin}px` : '0px')};
  margin-top: ${props => (props.orientation === 'vertical' ? `${props.margin}px` : '0px')};
`;

/**
 * Render children in a panel with equivalent spacing.
 *
 * Defaults to being a vertical panel with 20px between each item, but
 * can change with the orientation flag. Also, see ButtonBar for similar
 * behavior specific to horizontal layouts.
 */
const Panel = React.forwardRef((props, ref) => (!props.children ? null : (
  <Container
    {...applyTestAttribute(props) /* eslint-disable-line react/jsx-props-no-spreading */}
    id={props.id}
    align={props.align}
    innerRef={ref}
    className={props.className}
    onClick={props.onClick}
    orientation={props.orientation}
    fullWidth={props.fullWidth}
  >
    {React.Children
      .map(props.children, child => child)
      .filter(child => !!child)
      .map((child, index) => (
        <Item
          key={index}
          className={cx(
            typeof props.itemClassName === 'function' ? props.itemClassName(index) : props.itemClassName,
            `panel-item-${index}`,
          )}
          orientation={props.orientation}
          margin={index === 0 ? 0 : props.spacing}
        >
          {child}
        </Item>
      ))}
  </Container>
)));
Panel.propTypes = {
  align: PropTypes.oneOf(['top', 'left', 'center', 'right', 'bottom', 'baseline']),
  /** Panels in the container */
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.element, PropTypes.node]),
  /** Optional class name for container */
  className: PropTypes.string,
  /** True to stretch container full width, false otherwise (default false) */
  fullWidth: PropTypes.bool,
  /**
   * Optional class name for each item in container.
   * If given a function, index is passed, and a string is expected
   */
  itemClassName: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  /** Click handler for entire panel */
  onClick: PropTypes.func,
  /** Layout direction for the panel */
  orientation: PropTypes.oneOf(['vertical', 'horizontal']),
  /** Margin between each panel */
  spacing: PropTypes.number,
  /** HTML id */
  id: PropTypes.string,
};
Panel.defaultProps = {
  align: undefined,
  children: undefined,
  className: undefined,
  fullWidth: undefined,
  itemClassName: undefined,
  onClick: undefined,
  orientation: 'vertical',
  spacing: 20,
  id: undefined,
};

export default Panel;

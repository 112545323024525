/* eslint-disable no-nested-ternary */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: #ffffff;
  width: ${props => (props.width ? `${props.width}px` : props.fullWidth ? '100%' : 'auto')};
  height: ${props => (props.height ? `${props.height}px` : props.fullHeight ? '100%' : 'auto')};
`;

const Content = styled.div`
  padding: 20px;
`;

/**
 * A placeholder component for items that have not yet been
 * developed. By default, they span the full width and height
 * of their container, but custom width and height dimensions
 * can be set.
 */
const Placeholder = props => (
  <Container {...props}><Content>{props.children}</Content></Container>
);
Placeholder.propTypes = {
  /** The child component. Just a text string is fine. */
  children: PropTypes.node.isRequired,
  /** Optional numeric width. Defaults to 100% */
  width: PropTypes.number,
  /** Optional numeric height. Defaults to 100% */
  height: PropTypes.number,
  /** Sets width to 100%. Ignored if a width is given. Default true */
  fullWidth: PropTypes.bool,
  /** Sets height to 100%. Ignored if a height is given. Default true */
  fullHeight: PropTypes.bool,
};
Placeholder.defaultProps = {
  width: undefined,
  height: undefined,
  fullWidth: true,
  fullHeight: true,
};

export default Placeholder;

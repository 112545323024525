import { Date } from 'helpers/dates';
import { reportError } from 'helpers/ErrorHandler';

/**
 * Placeholder for if we need to massage the JSON into shape.
 */
export const parseDocumentStatus = () => 'unsent';

/**
 * Return any MRNs that are associated with
 * any hospitals attached to this document.
 */
export const getMRNs = (patient, document) => {
  if (!patient.mrns) { return []; }

  return patient.mrns.filter(({
    organization_id: id, organization_type: type,
  }) => (
    document.attachable_attachments.some(item => (
      item.attachable_id === id && item.attachable_type === type
    ))
  ));
};

/**
 * Ensure consistent full name display is available.
 */
const reshapePatientName = (patient) => {
  const { first, middle, last } = patient;
  return [first, middle, last].filter(Boolean).join(' ');
};

/**
 * Put inbound patient information in the right shape.
 */
export const reshapePatient = (patient, document) => ({
  ...patient,
  name: patient.name || reshapePatientName(patient) || patient.full_name,
  mrns: getMRNs(patient, document),
});

/**
 * Parse given dates and format in a standard way.
 */
export const reshapeAuthResponse = (result) => {
  try {
    const {
      dates_of_approved_service: dates = '[]',
      ...rest
    } = JSON.parse(result.value);
    const values = dates.map((dateString) => {
      const parsed = Date.parse(dateString);
      return parsed?.isValid() ? Date.format(parsed) : null;
    });
    return {
      ...result,
      value: JSON.stringify({
        ...rest,
        dates_of_approved_service: values,
      }),
    };
  } catch (e) {
    reportError(e, 'Inbound fax failed to parse a date', result);
    return null;
  }
};

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion';
import Session from 'models/Session';
import Referral from 'models/Referral';
import SendMessageForm from './SendMessageForm';
import ConversationModalHeader from './ConversationModalHeader';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #333333;
  max-height: 600px;
  margin-left: -10px;
  width: calc(100% + 20px);
  position: relative;
`;

const HeaderRow = styled.div`
  flex-basis: 100px;
`;

const ContentRow = styled.div`
  overflow-y: auto;
`;

const RowContent = styled.div`
  padding: 0px 20px;
`;

const FormContainer = styled.div(({ theme: { colors } }) => ({
  color: colors.WHITE,
  '> div': {
    width: '92%',
    margin: '0 4%',
  },
}));

const Divider = styled.div`
  border-bottom: 1px solid #e6e6e6;
  margin-top: 20px;
`;

const Organization = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string,
});

/**
 * Basic scrolling, set-height container for conversations,
 * both new and existing.
 */
class ConversationContainer extends React.Component {
  static propTypes = {
    /** The existing conversation thread */
    thread: PropTypes.shape({
      id: PropTypes.number,
      organizations: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      })),
    }).isRequired,
    /** Callback to close the modal */
    close: PropTypes.func.isRequired,
    /** The scrolling body portion */
    body: PropTypes.node,
    /** Callback when a message is sent. */
    onMessageSent: PropTypes.func,
    /** The organization that will receive new messages in (to) */
    recipient: Organization,
    /** The referral */
    referral: Referral.isRequired,
    /** The organization that will send new message out (from) */
    sender: Organization,
    /** Function to send the message */
    sendMessage: PropTypes.func.isRequired,
    /** The current user session */
    session: Session.isRequired,
  }

  static defaultProps = {
    body: undefined,
    onMessageSent: undefined,
    recipient: undefined,
    sender: undefined,
  }

  /**
   * Scroll to the end of the container
   * @public
   */
  scrollToBottom = () => {
    this.scrollContainer.scrollTop = this.scrollContainer.scrollHeight;
  }

  render() {
    const { referral: { hospital = {} } = {}, sender } = this.props;
    const isSending = hospital.id === sender.id
      && hospital.organization_type === sender.organization_type;
    return (
      <Container>
        <HeaderRow>
          <RowContent>
            <ConversationModalHeader thread={this.props.thread} close={this.props.close} />
          </RowContent>
          <Divider />
        </HeaderRow>
        <ContentRow innerRef={(ref) => { this.scrollContainer = ref; }}>
          <RowContent>
            {this.props.body}
          </RowContent>
        </ContentRow>
        <FormContainer>
          <SendMessageForm
            onMessageSent={this.props.onMessageSent}
            session={this.props.session}
            sendMessage={this.props.sendMessage}
            sender={this.props.sender}
            recipient={this.props.recipient}
            referral={{
              referralRole: isSending ? 'sending' : 'receiving',
              ...this.props.referral,
            }}
          />
        </FormContainer>
      </Container>
    );
  }
}

export default ConversationContainer;

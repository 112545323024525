import React from 'react';
import { connect } from 'react-redux';
import { css } from 'emotion';
import styled from 'react-emotion';

import { createTranslator } from 'helpers/i18n';
import { isValidEmail } from 'helpers/validators';
import { providerTypes } from 'translations/providers';
import Provider from 'models/Provider';

import {
  Divider,
  TextField,
  SelectField,
  AutocompleteField,
  Styles,
} from 'components/utils';
import { PropertyFilter } from 'components/utils/AutocompleteField';

const { Secondary } = Styles;

const tr = createTranslator({
  heading: {
    providerInfo: 'Provider Info',
    admissionsInfo: 'Intake Contact Info',
  },
  placeholder: {
    name: 'e.g. All About Home Care',
    provider_type: 'e.g. home health, HHA, etc.',
  },
  label: {
    name: 'Name',
    provider_type: 'Level of Care',
    address: 'Address',
    city: 'City',
    state_id: 'State',
    zip: 'Zip',
    npi: 'Provider NPI',
    fax: 'Fax',
    phone: 'Phone',
    email: 'Email',
    medical_director_first: 'First Name',
    medical_director_last: 'Last Name',
    provider_number: 'Medicare ID Number (CCN)',
    description: 'Additional Notes',
  },
  error: {
    required: 'This field is required',
    emailFormat: 'Please enter a valid email address',
  },
  warning: {
    provider_number: 'CCN must be six-digits long',
  },
  labelInfo: {
    provider_number: 'providers.provider_number.info',
  },
});

const Container = styled.div`
  margin: 20px 90px;
`;

const SplitForm = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

const SplitFormPanel = styled.div`
  flex-basis: 48%;
`;

const FormFields = styled.div`
  margin-top: 20px;
`;

const FieldRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const FieldColumn = styled.div`
  flex-basis: ${props => props.width};
`;

const FieldSetTitle = styled.h4`
  margin: 0px;
`;

const fieldStyle = css`
  margin-top: 24px;
`;

const REQUIRED_FIELDS = [
  'name',
  'provider_type',
  'fax',
  'address',
  'city',
  'state_id',
  'zip',
];

/**
 * Barebones provider information form.
 */
class ProviderForm extends React.Component {
  static propTypes = {
    /*
     * Initial data to include in the form.
     */
    provider: Provider,
  }

  static defaultProps = {
    provider: null,
  }

  constructor(props) {
    super(props);
    this.required = REQUIRED_FIELDS;
    this.state = {
      form: this.getInitialForm(this.props.provider),
      errors: {},
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { provider } = this.props;
    const { provider: nextProvider } = nextProps;
    if ((!provider && nextProvider) || (provider && !nextProvider)
      || (provider && nextProvider && provider.id !== nextProvider.id)) {
      this.setState({
        form: this.getInitialForm(nextProvider),
        errors: {},
      });
    }
  }

  getEmptyForm = () => ({
    name: '',
    provider_type: null,
    address: '',
    city: '',
    state_id: null,
    zip: '',
    npi: '',
    fax: '',
    phone: '',
    email: '',
    medical_director_first: '',
    medical_director_last: '',
    provider_number: '',
    description: '',
  });

  getLevelOfCareItems = () => (
    Object.entries(providerTypes).map(entry => ({
      label: entry[1], value: `${entry[0]}`.toLowerCase(),
    }))
  );

  getStateItems = () => this.props.system.states.map(({ id, abbreviation }) => ({
    label: abbreviation,
    value: id,
  })).sort((a, b) => (a.label || '').localeCompare(b.label));

  getInitialForm = provider => Object.entries(this.getEmptyForm())
    .reduce((form, [key, value]) => ({
      ...form,
      [key]: provider ? (provider[key] || value) : value,
    }), {});

  /**
   * Get the data values from the form.
   *
   * @public
   */
  getValues = () => ({
    ...this.state.form,
  });

  /**
   * Validates the form and displays error messages if necessary.
   * Returns a Promise with a true if valid, false otherwise.
   *
   * @public
   */
  validate = () => {
    const errors = {};
    const { form } = this.state;
    this.required.forEach((key) => {
      if (!form[key]) {
        errors[key] = [tr('error.required')];
      }
    });

    if (form.email && !isValidEmail(form.email)) {
      errors.email = [tr('error.emailFormat')];
    }

    ['phone', 'fax'].forEach((key) => {
      if (form[key]) {
        // TODO: validate; otherwise, this will fail on the server.
      }
    });

    if (Object.keys(errors).length > 0) {
      this.setState({ errors });
      return Promise.resolve(false);
    }
    return Promise.resolve(true);
  }

  handleChange = (field, value, andThen) => {
    if (value) {
      this.setState(({ form }) => ({
        form: {
          ...form,
          [field]: value,
        },
      }), andThen);
    }
  }

  renderWarning = (key) => {
    if ((key !== 'provider_number') || (!this.state.form[key])) return null;
    if (this.state.form[key].length !== 6 || !(/^\d+$/.test(this.state.form[key]))) {
      return [tr(`warning.${key}`)];
    }
    return null;
  }

  renderTextField = (key, props) => (
    <TextField
      className={fieldStyle}
      name={key}
      placeholder={tr(`placeholder.${key}`)}
      label={tr(`label.${key}`)}
      format={key === 'phone' || key === 'fax' ? key : undefined}
      value={this.state.form[key]}
      onChange={(value) => { this.handleChange(key, value); }}
      required={this.required.some(i => i === key)}
      errors={this.state.errors[key]}
      labelInfo={tr(`labelInfo.${key}`)}
      labelInfoPosition="left"
      labelInfoTrigger={['hover', 'click']}
      warnings={this.renderWarning(key)}
      {...props}
    />
  )

  renderForm = () => (
    <Container>
      <SplitForm>
        <SplitFormPanel>
          <FieldSetTitle>{tr('heading.providerInfo')}</FieldSetTitle>
          <Divider margin={5} />
          <FormFields>
            {this.renderTextField('name')}
            <div>
              <AutocompleteField
                required
                className={fieldStyle}
                errors={this.state.errors.provider_type}
                label={tr('label.provider_type')}
                placeholder={tr('placeholder.provider_type')}
                items={this.getLevelOfCareItems()}
                filter={PropertyFilter('value', 'label')}
                itemRenderer={item => (
                  <div style={{ display: 'flex', minWidth: '150px', justifyContent: 'space-between' }}>
                    <div>{item.label}</div>
                    <Secondary>{item.value}</Secondary>
                  </div>
                )}
                onSelect={(item) => item && this.handleChange('provider_type', item.value)}
                value={this.state.form.provider_type}
              />
            </div>
            {this.renderTextField('address')}
            <FieldRow>
              <FieldColumn width="120px">{this.renderTextField('city')}</FieldColumn>
              <FieldColumn width="60px">
                <SelectField
                  required
                  name="state"
                  className={fieldStyle}
                  items={this.getStateItems()}
                  label={tr('label.state_id')}
                  onSelect={item => (item && this.handleChange('state_id', item.value))}
                  value={this.state.form.state_id}
                  errors={this.state.errors.state_id}
                  placeholder=""
                />
              </FieldColumn>
              <FieldColumn width="70px">
                {this.renderTextField('zip')}
              </FieldColumn>
            </FieldRow>
            {this.renderTextField('npi')}
          </FormFields>
        </SplitFormPanel>
        <SplitFormPanel>
          <FieldSetTitle>{tr('heading.admissionsInfo')}</FieldSetTitle>
          <Divider margin={5} />
          <FormFields>
            {this.renderTextField('fax')}
            {this.renderTextField('phone')}
            {this.renderTextField('email')}
            <FieldRow>
              <FieldColumn width="120px">{this.renderTextField('medical_director_first')}</FieldColumn>
              <FieldColumn width="120px">{this.renderTextField('medical_director_last')}</FieldColumn>
            </FieldRow>
            {this.renderTextField('provider_number')}
          </FormFields>
        </SplitFormPanel>
      </SplitForm>
      {this.renderTextField('description', { lines: 2 })}
    </Container>
  )

  render() {
    return this.renderForm();
  }
}

export default connect(({ system }) => ({ system }), null, null, { forwardRef: true })(ProviderForm);

/* eslint-disable react/no-array-index-key */

import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion';
import { createTranslator, numeric } from 'helpers/i18n';
import { ThemeContext } from 'components/utils/ThemeContext';
import Panel from 'components/utils/Panel';

/**
 * Given an object, use a string to navigate the
 * keys and find the values for the terms you want
 * to display. Uses `createTranslator` from i18n.
 *
 * Note: terms must be a list of strings.
 */
export const objectRenderer = obj => createTranslator(obj);

const createComponentStyles = ({ version }) => numeric(version, {
  other: {
    styles: {
      Row: styled.div`
        display: flex;
        justify-content: space-between;
      `,
      Term: styled.div`
        margin-right: 14px;
        flex-basis: ${props => props.width || 30}%;
      `,
      Definition: styled.div`
        width: ${props => props.width || 30}px;
        flex-basis: ${props => 100 - (props.width || 30) - 10}%;
      `,
    },
  },
  v2: {
    styles: {
      Row: styled.div`
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
      `,
      Term: styled.div`
        margin-right: 14px;
        flex-basis: ${props => props.width || 30}%;
      `,
      Definition: styled.div`
        width: ${props => props.width || 30}px;
        flex-basis: ${props => 100 - (props.width || 30) - 10}%;
        font-weight: 600;
      `,
    },
  },
});

/**
 * A list of terms and values. This might move to utils if it proves useful.
 *
 * The terms are typically an array of strings, but can be any object really.
 * The terms list is iterated and a label and value renderer are passed the
 * term for formatting. The formatted result is displayed.
 */
const DefinitionList = ({
  terms,
  fullWidth,
  hideEmpty,
  labelClassName,
  labelRenderer,
  spacing,
  valueClassName,
  valueRenderer,
  width,
}) => {
  const themeInfo = useContext(ThemeContext);
  const v = themeInfo.version;

  const {
    styles: {
      Row,
      Term,
      Definition,
    },
  } = useMemo(() => createComponentStyles(themeInfo), [v]);

  let rows = terms
    .map((term, index) => ({
      term,
      label: labelRenderer(term, index),
      value: valueRenderer(term, index),
    }));
  if (hideEmpty) {
    if (typeof hideEmpty === 'function') {
      rows = rows.filter(({ term, value }) => !hideEmpty(term, value));
    } else if (typeof hideEmpty === 'object') {
      rows = rows.filter(({ term, value }) => !hideEmpty.some(item => item === term) || !!value);
    } else {
      rows = rows.filter(({ value }) => !!value);
    }
  }
  return (
    <Panel fullWidth={fullWidth} spacing={spacing}>
      {
        rows.map(({ term, label, value }, idx) => (
          <Row key={typeof term === 'string' || typeof term === 'number' ? `${idx}-${term}` : idx}>
            <Term className={labelClassName} width={width}>{label}</Term>
            <Definition className={valueClassName} width={width}>{value}</Definition>
          </Row>
        ))
}
    </Panel>
  );
};

DefinitionList.propTypes = {
  /**
   * Enable to skip terms that resolve to empty. Can be one of the following types:
   * - a boolean value (true to hide all terms that are empty, false otherwise)
   * - an array of terms that should be hidden if the values are empty
   * - a function that takes a term and value and returns true if it should
   *   be hidden, false otherwise.
   */
  hideEmpty: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.func,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  /** True to show in full width, false otherwise */
  fullWidth: PropTypes.bool,
  /** Class name for terms */
  labelClassName: PropTypes.string,
  /** Given the term, return the label */
  labelRenderer: PropTypes.func.isRequired,
  /** Spacing between each row, in pixels */
  spacing: PropTypes.number,
  /** A list of terms to display. Generally a string, but any key will do */
  terms: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string, PropTypes.number, PropTypes.object,
  ])).isRequired,
  /** Class name for definitions */
  valueClassName: PropTypes.string,
  /** Given the term, return the rendered result */
  valueRenderer: PropTypes.func.isRequired,
  /** Width of the label */
  width: PropTypes.number,
};
DefinitionList.defaultProps = {
  hideEmpty: undefined,
  fullWidth: undefined,
  labelClassName: undefined,
  spacing: 3,
  valueClassName: undefined,
  width: undefined,
};

export default DefinitionList;

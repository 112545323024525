import React from 'react';
import { reportError } from 'helpers/ErrorHandler';

const ids = {
  'dashboard-left-card': 'dashboard-left-card',
  'dashboard-main-panel': 'dashboard-main-panel',
  'dashboard-left-card-activity-and-patients': 'dashboard-left-card-activity-and-patients',
  'referral-details-quickview': 'referral-details-quickview',
  'referral-details-attachments': 'referral-details-attachments',
  'referral-details-face-sheet': 'referral-details-face-sheet',
  'referral-details-insurance': 'referral-details-insurance',
  'referral-details-followers': 'referral-details-followers',
  'referral-details-care-plans': 'referral-details-care-plans',
  'referral-details-all': 'referral-details-all',
  'referral-provider-search-result-1': 'referral-provider-search-result-1',
  'referral-provider-search-filter-container': 'referral-provider-search-filter-container',
  'referral-provider-search-filter-insurance': 'referral-provider-search-filter-insurance',
  'sending-referral-steps-card-title-element': 'sending-referral-steps-card-title-element',
  'sending-referral-steps-card': 'sending-referral-steps-card',
  'sending-referral-provider-card': 'sending-referral-provider-card',
  'sending-referral-sidebar-container': 'sending-referral-sidebar-container',
  'referrals-page-header': 'referrals-page-header',
  'receiving-referral-steps-card': 'receiving-referral-steps-card',
  'receiving-referral-main-container': 'receiving-referral-main-container',
  'receiving-referral-sidebar-container': 'receiving-referral-sidebar-container',
  'referral-sending-tabs-details': 'referral-sending-tabs-details',
  'referral-sending-tabs-providers': 'referral-sending-tabs-providers',
  'referral-sending-tabs-overview': 'referral-sending-tabs-overview',
  'referral-sending-tabs-activity': 'referral-sending-tabs-activity',
  'referral-receiving-tabs-details': 'referral-receiving-tabs-details',
  'referral-receiving-tabs-overview': 'referral-receiving-tabs-overview',
  'referral-receiving-tabs-activity': 'referral-receiving-tabs-activity',
};

const appcuesId = (identifier) => {
  if (ids[identifier]) {
    return ids[identifier];
  }
  if (identifier !== undefined) {
    const error = new Error(`${identifier} is not a supported appcues identifier.`);
    reportError(error, error.message);
  }

  return undefined;
};

const withAppcues = (WrappedComponent, { id }) => React.forwardRef(({ ...passThrough }, ref) => (
  <WrappedComponent id={appcuesId(id)} ref={ref} {...passThrough} />
));

export default withAppcues;

import React from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import { withRouter } from 'react-router-dom';
import styled from 'react-emotion';
import { createTranslator } from 'helpers/i18n';

import { mq } from 'components/utils/Responsive';
import Button from 'components/utils/Button';
import ButtonBar from 'components/utils/ButtonBar';
import Panel from 'components/utils/Panel';
import { PageContent } from 'components/utils/Styles';

const tr = createTranslator({
  title: 'Sorry, we couldn\'t find that page!',
  description: 'It might be an incorrect or out-of-date link, or it may have moved.',
  suggestion: 'If you’re having trouble accessing a referral, you can search by patient name, MRN, or referral number above.',
  button: 'Back to Dashboard',
});

const Container = styled.div(mq({
  width: ['450px', 'calc(100% - 32px)', 'calc(100% - 32px)'],
  margin: '0px auto',
}));

/**
 * Not found page. Requires history prop to send user back to dashboard.
 */
const NotFound = ({ history }) => (
  <PageContent>
    <Container>
      <Panel spacing={40}>
        <Panel spacing={0}>
          <h2>{tr('title')}</h2>
          <Panel>
            <div>{tr('description')}</div>
            <div>{tr('suggestion')}</div>
          </Panel>
        </Panel>
        <ButtonBar align="center">
          <Button onClick={() => { history.push('/'); }}>
            {tr('button')}
          </Button>
        </ButtonBar>
      </Panel>
    </Container>
  </PageContent>
);
NotFound.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
};

export default withRouter(NotFound);

import * as types from './types';

export const replaceNotifications = notifications => dispatch => Promise.resolve(dispatch({
  type: types.REPLACE_NOTIFICATION_LIST,
  ...notifications,
}));

/**
 * Resets the notifications count to zero
 */
export const syncNotificationsUnreadCount = () => dispatch => Promise.resolve(dispatch({
  type: types.RESET_UNREAD_COUNT,
}));

/**
 * Update the notification unread count by one when ActionCable hits
 * the events endpoint.
 */
export const incrementNotificationsUnreadCount = () => dispatch => Promise.resolve(dispatch({
  type: types.INCREMENT_UNREAD_COUNT,
}));

/**
 * Update the notification unread count minus one when User clicks on notification
 */
export const decrementNotificationsUnreadCount = () => dispatch => Promise.resolve(dispatch({
  type: types.DECREMENT_UNREAD_COUNT,
}));

/* eslint-disable import/prefer-default-export */
export const INITIALIZE_DASHBOARD = 'dashboard/INITIALIZE_DASHBOARD';
export const REPLACE_DASHBOARD_DATA = 'dashboard/REPLACE_DASHBOARD_DATA';
export const REPLACE_DASHBOARD_REFERRAL = 'dashboard/REPLACE_DASHBOARD_REFERRAL';
export const REPLACE_DASHBOARD_INDEX = 'dashboard/REPLACE_DASHBOARD_INDEX';
export const REMOVE_DASHBOARD_REFERRAL = 'dashboard/REMOVE_DASHBOARD_REFERRAL';
export const MERGE_DASHBOARD_MESSAGES = 'dashboard/MERGE_DASHBOARD_MESSAGES';
export const REMOVE_DASHBOARD_TASK = 'dashboard/REMOVE_DASHBOARD_TASK';
export const REMOVE_PATIENT_REFERRAL = 'dashboard/REMOVE_PATIENT_REFERRAL';
export const REMOVE_PATIENT_FROM_DASHBOARD = 'dashboard/REMOVE_PATIENT_FROM_DASHBOARD';
export const INCREMENT_DASHBOARD_VERSION = 'dashboard/INCREMENT_DASHBOARD_VERSION';
export const MERGE_DASHBOARD_REFERRAL_EVENTS = 'dashboard/MERGE_DASHBOARD_REFERRAL_EVENTS';

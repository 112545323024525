const text = {
  suggestions: {
    searchSupport: 'Search for an email from **support@myaidin.com**',
    searchWelcome: 'Search for an email with the subject line **"Aidin"**',
    checkSpam: 'Look in your email\'s **Spam or Trash folder** (sometimes they go there!)',
    contactSupport: 'If 5 minutes pass and you still haven\'t received an email, please [contact Aidin Support](#action:activateZendeskModal).',
  },
};

export default text;

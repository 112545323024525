import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { createTranslator } from 'helpers/i18n';
import { errorToast } from 'helpers/ui';
import Button from 'components/utils/Button';

const MAX_FILE_SIZE_BYTES = undefined; // TODO: Define...

const tr = createTranslator({
  error: {
    invalid: 'This file type or size is not allowed to be uploaded.',
  },
});

export default class FileUploadButton extends React.Component {
  static propTypes = {
    /** What file type to accept? All by defualt. Use mime-type strings */
    accept: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    /** Text for the button */
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
    /** CSS class name */
    className: PropTypes.string,
    /** The button element. Defaults to a Button, but use whatever you want. */
    element: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
    /** True to allow for multiple files to be uploaded at once. */
    multiple: PropTypes.bool,
    /** Name of file input field */
    name: PropTypes.string,
    /** The max file size (bytes) */
    maxSize: PropTypes.number,
    /** Callback for when a file has been uploaded */
    onFileUploaded: PropTypes.func,
  }

  static defaultProps = {
    accept: undefined,
    className: undefined,
    element: undefined,
    maxSize: undefined,
    multiple: undefined,
    name: undefined,
    onFileUploaded: undefined,
  }

  open = () => {
    if (this.input) {
      this.input.open();
    }
  }

  handleFileUpload = (files) => {
    if (this.props.onFileUploaded) {
      this.props.onFileUploaded(files);
    }
  }

  handleFileRejected = () => {
    errorToast(tr('error.invalid'));
  }

  render() {
    const {
      accept, element, multiple, name, maxSize, className, ...buttonProps
    } = this.props;
    const ButtonElement = element || Button;
    return (
      <>
        <Dropzone
          ref={(ref) => { this.input = ref; }}
          style={{ display: 'none' }}
          accept={accept}
          data-testid="file-upload"
          disablePreview
          multiple={multiple}
          name={name || `file-${new Date().getTime()}`}
          maxSize={maxSize || MAX_FILE_SIZE_BYTES}
          className={className}
          onDropAccepted={this.handleFileUpload}
          onDropRejected={this.handleFileRejected}
        />
        <ButtonElement
          {...buttonProps}
          onClick={this.open}
        >
          {this.props.children}
        </ButtonElement>
      </>
    );
  }
}

import initialState from 'store/initialState';
import * as types from './types';

const conversationsInbox = (state = initialState.conversationsInbox, action) => {
  const { type, ...entity } = action;
  switch (type) {
    case types.APPEND_INBOX_LIST: {
      return {
        ...state,
        ...entity,
        list: state.list.concat(entity.list),
      };
    }
    case types.REPLACE_INBOX_LIST: {
      return {
        ...state,
        ...entity,
      };
    }
    case types.MERGE_INBOX_LIST: {
      const { status, conversations: changes } = entity;
      return {
        ...state,
        list: state.list.map((item) => {
          const { id } = item;
          if (status[id] === 'success') {
            return changes.find(i => i.id === id);
          }
          return item;
        }),
      };
    }
    default:
      return state;
  }
};

const conversationsNotification = (state = initialState.conversationsNotification, action) => {
  const { type, ...entity } = action;
  switch (type) {
    case types.REPLACE_MESSAGE_LIST:
      return {
        ...state,
        ...entity,
        list: entity.list ? entity.list : state.list,
      };
    default:
      return state;
  }
};

const conversationThread = (state = initialState.conversationThread, action) => {
  const { type, ...entity } = action;
  switch (type) {
    case types.REPLACE_CURRENT_THREAD:
      return {
        ...state,
        current: {
          id: entity.id,
          ...entity.thread,
        },
      };
    case types.CLOSE_CURRENT_THREAD:
      return {
        ...state,
        current: null,
      };
    case types.TOGGLE_NEW_MESSAGE_THREAD:
      return {
        ...state,
        composer: {
          open: entity.open === undefined ? !state.composer.open : entity.open,
        },
      };
    default:
      return state;
  }
};

const conversations = (state = initialState.conversations, action) => {
  const { type, ...data } = action;
  switch (type) {
    case types.REPLACE_CONVERSATIONS:
      return data.conversations;
    default:
      return state;
  }
};

export default {
  conversations,
  conversationThread,
  conversationsInbox,
  conversationsNotification,
};

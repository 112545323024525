import React from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion';
import { createTranslator } from 'helpers/i18n';
import Session from 'models/Session';

import { Modal, AutocompleteField } from 'components/utils';

import ConversationContainer from './ConversationContainer';

const tr = createTranslator({
  title: 'New Conversation',
  field: {
    label: 'Start a conversation',
    placeholder: 'Search by Aidin member name or provider name',
  },
});

const HeaderContainer = styled.div`
  width: 50%;
`;

const fakeItems = [
  {
    id: 2,
    name: 'Revival Home Health',
  },
];

export default class NewMessagePopupPanel extends React.Component {
  static propTypes = {
    composer: PropTypes.shape({
      open: PropTypes.bool,
    }).isRequired,
    onOpen: PropTypes.func,
    onClose: PropTypes.func.isRequired,
    session: Session.isRequired,
    sendMessage: PropTypes.func.isRequired,
  };

  static defaultProps = {
    onOpen: undefined,
  };

  constructor(props) {
    super(props);
    this.state = { recipient: null };
  }

  getOrganizations = () => (
    this.props.session.organizations || []
  );

  renderHeader = () => (
    <HeaderContainer>
      <AutocompleteField
        label={tr('field.label')}
        placeholder={tr('field.placeholder')}
        items={fakeItems.map(item => ({ ...item, label: item.name, value: item.id }))}
        onSelect={(recipient) => { this.setState({ recipient }); }}
      />
    </HeaderContainer>
  );

  render() {
    return (
      <Modal
        open={this.props.composer.open}
        onOpen={this.props.onOpen}
        onClose={() => { this.props.onClose(false); }}
        title={tr('title')}
      >
        {({ close }) => (
          <ConversationContainer
            header={this.renderHeader()}
            onMessageSent={close}
            sender={this.getOrganizations()[0]}
            recipient={this.state.recipient}
            sendMessage={this.props.sendMessage}
            session={this.props.session}
          />
        )}
      </Modal>
    );
  }
}

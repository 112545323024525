/* eslint-disable no-nested-ternary */

import React from 'react';
import PropTypes from 'prop-types';
import { css, cx } from 'emotion';

import Icon from 'components/utils/Icon';
import SplitPanel from 'components/utils/SplitPanel';
import { GRAY_FILL, WHITE } from 'components/utils/Colors';
import { Label } from 'components/utils/Styles';

const Sizes = {
  small: '3px 10px 2px',
  large: '6px 10px 4px',
};

const containerStyle = (color = GRAY_FILL, size = Sizes.small) => css`
  background-color: ${color};
  padding: ${size};
  .split-panel-item-0 {
    flex: 1;
    min-width: 0;
  }
`;

const itemStyle = (element, textColor = WHITE) => css`
  > ${element} {
    color: ${textColor};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

/**
 * A thin title bar with label text.
 */
const TitleBar = ({
  children,
  className,
  closeable,
  element,
  color,
  icon,
  large,
  onIconClick,
  textColor,
}) => {
  const createIcon = name => (
    <Icon name={name} size={20} color={textColor} onClick={onIconClick} />
  );
  const TitleElement = element || Label;
  const iconComponent = icon
    ? typeof icon === 'string'
      ? createIcon(icon)
      : icon
    : closeable
      ? createIcon('cancel')
      : null;
  return (
    <SplitPanel
      align="center"
      className={cx(containerStyle(color, large ? Sizes.large : Sizes.small), className)}
      itemClassName={itemStyle(element, textColor)}
    >
      <TitleElement>
        {children}
      </TitleElement>
      {iconComponent && (<div>{iconComponent}</div>)}
    </SplitPanel>
  );
};
TitleBar.propTypes = {
  /** The text/element to display in the bar */
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func, PropTypes.string]).isRequired,
  /** Optional class name, applied to the container */
  className: PropTypes.string,
  /** Adds a close icon */
  closeable: PropTypes.bool,
  /** The background color of the TitleBar */
  color: PropTypes.string,
  /** The element tag, or Label by default. */
  element: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  /** An optional icon to display to the right of the title text. Overrides closeable */
  icon: PropTypes.oneOfType([PropTypes.node, PropTypes.func, PropTypes.string]),
  /** A larger variant with double the top/bottom padding */
  large: PropTypes.bool,
  /** Click handler for an icon, provided by either `icon` or `closeable` */
  onIconClick: PropTypes.func,
  /** The text color */
  textColor: PropTypes.string,
};
TitleBar.defaultProps = {
  className: undefined,
  closeable: false,
  color: GRAY_FILL,
  element: Label,
  icon: undefined,
  large: false,
  onIconClick: undefined,
  textColor: WHITE,
};

export default TitleBar;

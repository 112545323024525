import PropTypes from 'prop-types';

import { Date, DateTime } from 'helpers/dates';

const date = (props, propName, componentName) => {
  if (!props[propName]) { return null; }
  const result = Date.parse(props[propName]);
  if (!result || !result.isValid()) {
    return new Error(`Invalid prop ${propName} supplied to ${componentName}: Invalid date format: ${props[propName]}`);
  }
  return null;
};

const dateTime = (props, propName, componentName) => {
  if (!props[propName]) { return null; }
  const result = DateTime.parse(props[propName]);
  if (!result || !result.isValid()) {
    return new Error(`Invalid prop ${propName} supplied to ${componentName}: Invalid date format: ${props[propName]}`);
  }
  return null;
};

const mediaType = PropTypes.oneOf(['small', 'medium', 'large']);

export default { date, dateTime, mediaType };

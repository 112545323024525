export default {
  whatIsAidin: {
    title: 'What is Aidin?',
    description: 'Aidin helps all patient care managers collaborate online so every patient transition is efficient, informed, and aligned with patient care goals.',
    icon: 'mp-info',
  },
  haveToPay: {
    title: 'Do I have to pay to use this site?',
    description: 'There is no charge to view and receive referrals on Aidin.',
    icon: 'mp-money',
  },
  inviteNotWorking: {
    title: 'My Invitation Code isn’t working',
    description: 'The Invitation Code is no longer active if the time frame to respond is no longer open.<br /><br />The goal of your hospital partners is to open competition among providers to find the best option for their patients. In order to ensure that all providers have time to respond, the sender of your referral has the ability to set a time frame for your response. Once the time frame has ended, you will no longer have access to the referral until the patient makes a final choice. However, if you want to explore Aidin, register for a new account at no cost!',
    icon: 'mp-padlock',
  },
  currentReferralSystem: {
    title: 'Why do I need to change my current referral system?',
    description: 'Aidin is completely different than existing referral systems. Aidin does not function as a marketing tool for providers. Aidin is the only solution that makes your providers compete for your patients based on your quality and satisfaction goals.',
    icon: 'mp-papers',
  },
};

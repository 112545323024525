import React from 'react';
import PropTypes from 'prop-types';

const ThemeElement = ({
  children,
  version,
}) => {
  const Container = React.useMemo(() => (
    version
      ? `aidin-theme-${version}`
      : 'div'
  ), [version]);
  return <Container>{children}</Container>;
};
ThemeElement.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.func]).isRequired,
  version: PropTypes.string,
};
ThemeElement.defaultProps = {
  version: undefined,
};

export default ThemeElement;

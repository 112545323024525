import React from 'react';
import PropTypes from 'prop-types';
import { createTranslator, numeric, pluralize } from 'helpers/i18n';

import CheckboxField from 'components/utils/CheckboxField';
import Icon from 'components/utils/Icon';
import Panel from 'components/utils/Panel';
import Popup from 'components/utils/Popup';

import { MEDIUM_DARK_GRAY_FILL } from 'components/utils/Colors';
import { Secondary, PopupContent } from 'components/utils/Styles';
import Attachment from 'models/Attachment';

const tr = createTranslator({
  attachmentDropDown: count => numeric(count, {
    0: 'No Response Requested',
    other: `${count} ${pluralize(count, 'Response')}`,
  }),
  patientResponse: 'Patient Response',
  responseLabel: {
    formCompletion: 'Request Form Completion',
    signature: 'Request Signature',
    notes: 'Request Notes',
    document: 'Request Another Document',
  },
});

const dropDownItems = [
  {
    key: 0, label: tr('responseLabel.formCompletion'), value: false, type: 'form_completion',
  },
  {
    key: 1, label: tr('responseLabel.signature'), value: false, type: 'signature',
  },
  {
    key: 2, label: tr('responseLabel.notes'), value: false, type: 'notes',
  },
  {
    key: 3, label: tr('responseLabel.document'), value: false, type: 'document',
  },
];

const ResponseRequestedMenu = ({
  selectedItems, attachment, onChange, iconClassName,
}) => {
  const checkIfTypeExists = (type) => {
    if (selectedItems.includes(type)) {
      return true;
    }
    return false;
  };

  const handleChange = (type) => {
    onChange(attachment, type, checkIfTypeExists(type));
  };

  const renderResponseType = responseType => (
    <Secondary style={{ width: 'auto', whiteSpace: 'nowrap' }}>
      {responseType}
    </Secondary>
  );

  const getCorrectResponseCount = () => {
    if (selectedItems.includes('form_completion') && selectedItems.includes('signature')) {
      return selectedItems.length - 1;
    }
    return selectedItems.length;
  };

  return (
    selectedItems.includes('patient_response') ? renderResponseType(tr('patientResponse')) : (
      <Popup
        trigger={(
          <Panel orientation="horizontal" spacing={2}>
            {renderResponseType(tr('attachmentDropDown', getCorrectResponseCount()))}
            <Icon
              className={iconClassName}
              name="chevron-down"
              color={MEDIUM_DARK_GRAY_FILL}
            />
          </Panel>
        )}
        triggerOn="click"
        width={250}
      >
        <PopupContent>
          {dropDownItems.map((item) => (
            <div key={item.key} style={item.type === 'signature' ? { marginLeft: '30px', marginBottom: '10px' } : { marginBottom: '10px' }}>
              <CheckboxField
                label={item.label}
                value={selectedItems.includes(item.type)}
                onChange={() => handleChange(item.type)}
              />
            </div>
          ))}
        </PopupContent>
      </Popup>
    )
  );
};

ResponseRequestedMenu.propTypes = {
  selectedItems: PropTypes.arrayOf(PropTypes.string).isRequired,
  attachment: Attachment.isRequired,
  onChange: PropTypes.func.isRequired,
  iconClassName: PropTypes.string.isRequired,
};

export default ResponseRequestedMenu;

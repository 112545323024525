import initialState from 'store/initialState';
import * as types from './types';

const providers = (state = initialState.admin.providers, action) => {
  const { type, ...entity } = action;
  switch (type) {
    case types.REPLACE_PROVIDER:
      return {
        ...state,
        current: entity.provider,
      };
    case types.UPDATE_PROVIDER:
      return {
        ...state,
        current: {
          ...state.current,
          ...entity.provider,
        },
      };
    case types.RESET_PROVIDER:
      return {
        ...state,
        current: null,
      };
    case types.SET_ALL_PROVIDER_ADMINS:
      return {
        ...state,
        administrators: entity.administrators,
      };
    default:
      return state;
  }
};

export default { providers };

export const REPLACE_REFERRAL = 'referral/REPLACE_REFERRAL';
export const REQUEST_PATIENTS = 'referral/patientSearch/REQUEST_PATIENTS';
export const REPLACE_PATIENTS = 'referral/patientSearch/REPLACE_PATIENTS';
export const REPLACE_SENT_REFERRAL = 'referral/sent/REPLACE_SENT_REFERRAL';
export const REPLACE_SENT_REFERRALS = 'referral/sent/REPLACE_SENT_REFERRALS';
export const REPLACE_CONVERSATIONS = 'referral/REPLACE_CONVERSATIONS';
export const REPLACE_REFERRAL_ACTIVITY = 'referral/REPLACE_REFERRAL_ACTIVITY';
export const REPLACE_REFERRAL_FOLLOWERS = 'referral/REPLACE_REFERRAL_FOLLOWERS';
export const REPLACE_REFERRAL_PROVIDER_SEARCH_RESULTS = 'referral/REPLACE_REFERRAL_PROVIDER_SEARCH_RESULTS';
export const REPLACE_REFERRAL_PURCHASES = 'referral/purchases/REPLACE_REFERRAL_PURCHASES';
export const REPLACE_REFERRAL_ROLE = 'referral/REPLACE_REFERRAL_ROLE';
export const REPLACE_REFERRAL_WATCHERS = 'referral/REPLACE_REFERRAL_WATCHERS';
export const UPDATE_ATTACHMENT_METADATA = 'referral/UPDATE_ATTACHMENT_METADATA';

/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import { ThemeProvider } from 'components/utils/ThemeContext';

/**
 * This provides customizations to the current component theme, and can
 * only effectively handle basic strings and objects. If you need to pass
 * more advanced things like functions or styled components, consider using
 * withThemedComponents.
 *
 * Note that this HOC will make your theme available to your styled components
 * as well as your theme context; withThemedComponents will NOT be made
 * available to your styled components.
 */
const withComponentTheme = theme => WrappedComponent => React.forwardRef((props, ref) => (
  <ThemeProvider componentTheme={theme}>
    <WrappedComponent ref={ref} {...props} />
  </ThemeProvider>
));

export default withComponentTheme;

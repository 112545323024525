import React, { useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { css } from 'emotion';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

import { numeric } from 'helpers/i18n';
import Session from 'models/Session';
import {
  FEATURE_UPGRADE_UI,
  FEATURE_PROVIDER_PROFILE_USE,
  FEATURE_PROVIDER_PROFILE_MVP,
  FEATURE_UPGRADE_RELEASE_1,
  FEATURE_UPGRADE_RELEASE_2,
  FEATURE_UPGRADE_RELEASE_3,
} from 'ducks/session/types';

import {
  PageContent as FixedContainer,
  FullPageContent as FullContainer,
  GrayFullContainer,
  WhiteFullContainer,
} from 'components/utils/Styles';
import PageContext, {
  PAGE_CONTENT_WIDTH_V1_PX,
  PAGE_CONTENT_WIDTH_V2_PX,
} from 'components/utils/PageContext';
import { CURRENT_UI_VERSION } from 'components/utils/ThemeContext';
import { mq } from 'components/utils/Responsive';

const dashboardRoutes = ['', '/', '/incoming', '/tasks', '/command-center'];

const releaseFlagRoutes = [
  { feature: FEATURE_UPGRADE_RELEASE_2, route: '/messages' },
  { feature: FEATURE_UPGRADE_RELEASE_3, route: '/admin' },
];

const createContainerStyle = (version, width) => css(mq({
  width: [typeof width === 'string' ? width : `${width}px`, 'calc(100% - 32px)', 'calc(100% - 32px)'],
  marginLeft: 'auto',
  marginRight: 'auto',
}));

const createFullContainerStyle = (version, width) => css(mq({
  width: [typeof width === 'string' ? width : `${width}px`, 'auto', 'auto'],
  marginLeft: 'auto',
  marginRight: 'auto',
  paddingLeft: ['inherit', '32px', '32px'],
  paddingRight: ['inherit', '32px', '32px'],
}));

const defaultContentWidth = (version) => numeric(version, {
  2: PAGE_CONTENT_WIDTH_V2_PX,
  other: PAGE_CONTENT_WIDTH_V1_PX,
});

const providerProfileRouteCustomizations = {
  containerStyleCreator: (version, width) => numeric(version, {
    2: createFullContainerStyle(version, width),
    other: createContainerStyle(version, width),
  }),
  contentWidth: defaultContentWidth,
  container: (version) => numeric(version, {
    other: FixedContainer,
    2: WhiteFullContainer,
  }),
  containerWidth: () => '100%',
};

const matchableRoutes = [
  {
    ...providerProfileRouteCustomizations,
    route: '/preview/providers/.*',
  },
  {
    route: (features) => (features[FEATURE_UPGRADE_RELEASE_1] ? '/referrals/.*' : '/preview-referrals/.*'),
    containerStyleCreator: (version, width) => numeric(version, {
      2: createContainerStyle(version, width),
      other: createContainerStyle(version, width),
    }),
    contentWidth: defaultContentWidth,
    containerWidth: (version, features) => (features[FEATURE_UPGRADE_UI] ? numeric(version, {
      2: '100%',
      other: PAGE_CONTENT_WIDTH_V1_PX,
    }) : PAGE_CONTENT_WIDTH_V1_PX),
    container: (version) => numeric(version, {
      2: GrayFullContainer,
      other: FixedContainer,
    }),
  },
];

const getDashboardContainerWidth = (version, features) => numeric(version, {
  2: features[FEATURE_UPGRADE_RELEASE_1] ? PAGE_CONTENT_WIDTH_V2_PX : PAGE_CONTENT_WIDTH_V2_PX,
  other: PAGE_CONTENT_WIDTH_V2_PX,
});

const getDashboardRoutes = features => (features[FEATURE_UPGRADE_RELEASE_1] ? dashboardRoutes : []);

export const getPageConfiguration = (path, version, features) => {
  const v2Routes = []
    .concat(getDashboardRoutes(features).reduce((set, route) => set.concat({
      match: () => path === route,
      route,
      containerWidth: getDashboardContainerWidth,
      availableThemes: [1, 2],
    }), []))
    .concat(matchableRoutes.reduce((set, {
      route, containerWidth, container, contentWidth, containerStyleCreator,
    }) => {
      const resolvedRoute = typeof route === 'function' ? route(features) : route;
      return set.concat({
        match: () => path.match(resolvedRoute),
        route: resolvedRoute,
        container,
        containerStyleCreator,
        containerWidth,
        contentWidth,
        availableThemes: [1, 2],
      });
    }, []));

  if (features[FEATURE_PROVIDER_PROFILE_USE] || features[FEATURE_PROVIDER_PROFILE_MVP]) {
    v2Routes.push({
      ...providerProfileRouteCustomizations,
      match: () => path.match('^/providers/.*'),
      route: '/providers/.*',
      availableThemes: [1, 2],
    });
  } else {
    v2Routes.push({
      match: () => path.match('/providers/.*'),
      route: '/providers/.*',
      containerWidth: () => PAGE_CONTENT_WIDTH_V1_PX,
      availableThemes: [1, 2],
    });
  }

  releaseFlagRoutes.forEach(({ feature, route }) => {
    if (features[feature]) {
      v2Routes.push({
        match: () => path.match(route),
        route,
        containerWidth: () => '100%',
        contentWidth: defaultContentWidth,
        container: () => GrayFullContainer,
        containerStyleCreator: (themeVersion, width) => numeric(themeVersion, {
          2: createContainerStyle(themeVersion, width),
          other: createContainerStyle(themeVersion, width),
        }),
      });
    }
  });

  v2Routes.push({
    match: () => path.match('/inbox/inbound.*'),
    route: '/inbox/inbound.*',
    availableThemes: [1],
    container: () => FullContainer,
    containerWidth: () => PAGE_CONTENT_WIDTH_V2_PX,
    contentWidth: () => PAGE_CONTENT_WIDTH_V2_PX,
  });

  const route = v2Routes.find(({ match }) => match(path)) || {};
  const result = route.containerWidth && route.containerWidth(version, features);
  const pageWidth = result || PAGE_CONTENT_WIDTH_V1_PX;
  return {
    container: route.container || (() => FixedContainer),
    containerWidth: pageWidth,
    containerStyleCreator: route.containerStyleCreator || createContainerStyle,
    contentWidth: route.contentWidth,
    route: route.route,
    availableThemes: route.availableThemes,
  };
};

const PageContent = ({
  children,
  element,
  hasBanner,
  hasWarning,
  location: { pathname } = {},
  session: {
    features,
    features: {
      [FEATURE_UPGRADE_UI]: isFeatureEnabled,
    } = {},
  } = {},
}) => {
  const {
    contentWidth: parentContentWidth,
  } = useContext(PageContext) || {};
  const version = isFeatureEnabled ? CURRENT_UI_VERSION + 1 : CURRENT_UI_VERSION;
  const {
    route,
    container: createContainer,
    containerWidth,
    containerStyleCreator,
    contentWidth: getContentWidth,
  } = useMemo(() => getPageConfiguration(pathname, version, features), [pathname, version]);
  const containerStyle = useMemo(() => (
    containerStyleCreator(version, parentContentWidth || containerWidth)
  ), [version, containerWidth, parentContentWidth]);
  const Element = useMemo(() => element || createContainer(version, features), [
    version, features, route,
  ]);
  const contentWidth = getContentWidth
    ? getContentWidth(version, features)
    : null;
  return (
    <PageContext.Provider
      value={{
        contentWidth: parentContentWidth || contentWidth,
      }}
    >
      <Element
        className={containerStyle}
        hasBanner={hasBanner}
        hasWarning={hasWarning}
        width={containerWidth}
      >
        {children}
      </Element>
    </PageContext.Provider>
  );
};
PageContent.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired,
  element: PropTypes.oneOfType([PropTypes.func, PropTypes.element, PropTypes.string]),
  hasBanner: PropTypes.bool,
  hasWarning: PropTypes.bool,
  location: ReactRouterPropTypes.location.isRequired,
  session: Session.isRequired,
};
PageContent.defaultProps = {
  element: undefined,
  hasBanner: undefined,
  hasWarning: undefined,
};

export default connect(({ session }) => ({ session }))(withRouter(PageContent));

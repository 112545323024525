import React from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion';
import * as colors from 'components/utils/Colors';
import * as styles from 'components/utils/Styles';

import Icon from 'components/utils/Icon';
import Help from 'components/utils/Help';
import Panel from 'components/utils/Panel';

import ViewContext from 'components/utils/ViewContext';

const { TEXT_ERROR, FORM_WARNING } = colors;

const { Secondary } = styles;

const ErrorContainer = styled(Panel)`
  margin-left: 2px;
  margin-top: 5px;
`;

const Error = styled.div`
  color: ${TEXT_ERROR};
`;

const Warning = styled.div`
  color: ${FORM_WARNING};
`;

const HelpText = styled(Secondary)`
  margin-top: ${props => (props.noHelpMargin ? '0px' : '10px')};
`;

const LabelInfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const fieldPropTypes = {
  /** Optional class name for the field wrapper */
  className: PropTypes.string,
  /** Optional array of error messages */
  errors: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.bool, PropTypes.string, PropTypes.node,
  ])),
  /** Help text to show below the field */
  help: PropTypes.node,
  /** Optional minimum spacing for the help text */
  noHelpMargin: PropTypes.bool,
  /** Optional label to display over the field. Can be a string or node */
  label: PropTypes.node,
  /** Optional class name to add to the label text */
  labelClassName: PropTypes.string,
  /** Label element, h4 by default. Can be a string or object */
  labelElement: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.func]),
  /** Indicator symbol for a label. Defaults to ':' */
  labelIndicator: PropTypes.string,
  /** Show information icon with the given text on hover if labels are enabled */
  labelInfo: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  /** Show information icon with the given component on hover if labels are enabled */
  labelInfoComponent: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  /** Top for label to go above field, left to go to the left (default top) */
  labelPosition: PropTypes.oneOf(['left', 'top']),
  /** Position of the information icon text (default bottom) */
  labelInfoPosition: PropTypes.oneOf(['left', 'top', 'right', 'bottom']),
  /** Information icon trigger options (default click) */
  labelInfoTrigger: PropTypes.oneOf(['hover', 'click', 'focus', 'none']),
  /** True if required field, false otherwise */
  required: PropTypes.bool,
  /** Optional array of warning messages */
  warnings: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.bool, PropTypes.string, PropTypes.node,
  ])),
  /**
   * For validation, a function expected to return a Promise with a boolean value,
   * true if valid, false otherwise. Will be passed in the Field instance.
   */
  validator: PropTypes.func,
};

const getDisplayName = WrappedComponent => WrappedComponent.displayName || WrappedComponent.name || 'Component';

// eslint-disable-next-line react/prefer-stateless-function
const Field = (WrappedComponent, features = {}) => class extends React.Component {
  static displayName = `Field(${getDisplayName(WrappedComponent)})`;

  static propTypes = fieldPropTypes;

  static defaultProps = {
    className: undefined,
    noHelpMargin: false,
    errors: undefined,
    help: undefined,
    label: undefined,
    labelClassName: undefined,
    labelElement: undefined,
    labelIndicator: ':',
    labelInfo: undefined,
    labelInfoComponent: undefined,
    labelPosition: undefined,
    labelInfoPosition: undefined,
    labelInfoTrigger: undefined,
    required: undefined,
    validator: undefined,
    warnings: undefined,
  };

  getField = () => this.field;

  validate = () => {
    if (this.field && this.field.validate) {
      return this.field.validate(this);
    }
    if (this.props.validator) {
      return this.props.validator(this);
    }
    return Promise.resolve(true);
  }

  renderLabel = (labelElement, labelClassName, label, labelInfo, labelInfoPosition, labelInfoTrigger, labelIndicator, labelInfoComponent) => {
    const LabelTag = labelElement || 'h4';
    const labelComponent = (
      <ViewContext.Consumer>
        {({ readOnly }) => (
          <LabelTag className={labelClassName}>
            {label}{labelIndicator} {!readOnly && this.props.required ? ' *' : ''}
          </LabelTag>
        )}
      </ViewContext.Consumer>
    );
    if (labelInfo || labelInfoComponent) {
      return (
        <ViewContext.Consumer>
          {({ readOnly }) => (
            <LabelInfoContainer>
              {labelComponent}
              {labelInfoComponent || <Help triggerOn={labelInfoTrigger} position={labelInfoPosition} value={!readOnly && labelInfo} />}
            </LabelInfoContainer>
          )}
        </ViewContext.Consumer>
      );
    }
    return labelComponent;
  }

  render() {
    const {
      className, help, noHelpMargin, label, labelClassName, labelElement,
      labelIndicator, labelInfo, labelPosition, labelInfoPosition,
      labelInfoComponent, labelInfoTrigger, ...other
    } = this.props;
    const Container = features.Container || 'div';
    const passThrough = features.noLabel ? this.props : other;
    return (
      <Container className={className} style={{ position: 'relative' }}>
        <Panel
          orientation={labelPosition === 'left' ? 'horizontal' : 'vertical'}
          spacing={labelPosition === 'left' ? 20 : 0}
          align={labelPosition === 'left' ? 'center' : undefined}
        >
          {!features.noLabel && label
            && this.renderLabel(labelElement, labelClassName, label, labelInfo, labelInfoPosition, labelInfoTrigger, labelIndicator, labelInfoComponent)}
          <WrappedComponent
            ref={(ref) => { this.field = ref; }}
            {...passThrough /* eslint-disable-line react/jsx-props-no-spreading */}
          />
        </Panel>
        {!features.noErrors && this.props.errors
          && this.props.errors.map && this.props.errors.map(error => (
            <ErrorContainer key={error} orientation="horizontal" align="center" spacing={5}>
              <Icon color={TEXT_ERROR} name="mp-warning" />
              <Error>{error}</Error>
            </ErrorContainer>
        ))}
        <ViewContext.Consumer>
          {({ readOnly }) => (
            help && !readOnly && <HelpText noHelpMargin={noHelpMargin}>{help}</HelpText>
          )}
        </ViewContext.Consumer>
        {this.props.warnings && this.props.warnings.map && this.props.warnings.map(warning => (
          <ErrorContainer key={warning} orientation="horizontal" spacing={5}>
            <Icon color={FORM_WARNING} name="mp-warning" />
            <Warning> {warning}</Warning>
          </ErrorContainer>
        ))}
      </Container>
    );
  }
};

export default Field;

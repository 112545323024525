/* eslint-disable react/no-typos */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion';
import { cx } from 'emotion';
import { clickableStyle } from 'components/utils/Styles';
import PopupMenu, { popupMenuProps } from 'components/utils/PopupMenu';
import ContextMenuIcon from 'components/utils/ContextMenuIcon';

const Container = styled.div`
  display: ${props => props.display};
  ${props => (props.display.indexOf('flex') >= 0 ? 'align-items: center;' : '')}
`;

const getDisplayStyle = (inline) => {
  if (inline && typeof inline === 'string') { return inline; }
  return `${inline ? 'inline-' : ''}flex`;
};

/**
 * A gear icon that, when clicked, displays a popup menu. The
 * items can be simple strings, formatted text, or custom nodes.
 *
 * See PopupMenu for complete list of props
 */
const GearMenu = ({
  iconClassName, inline, trigger, ...popupProps
}) => (popupProps.items?.length ? (
  <Container
    display={getDisplayStyle(inline)}
    role="button"
    onClick={(e) => {
      e.preventDefault();
      e.stopPropagation();
    }}
  >
    <PopupMenu
      {...popupProps /* eslint-disable-line react/jsx-props-no-spreading */}
      trigger={trigger || <ContextMenuIcon className={cx(clickableStyle, iconClassName)} />}
    />
  </Container>
) : null);
GearMenu.propTypes = {
  ...popupMenuProps,
  iconClassName: PropTypes.string,
  inline: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};
GearMenu.defaultProps = {
  ...PopupMenu.defaultProps,
  iconClassName: undefined,
  inline: true,
};

export default GearMenu;

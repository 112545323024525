import React from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { withRouter } from 'react-router';
import qs from 'query-string';

import QueryContext from 'components/utils/QueryContext';

/**
 * Wraps the entire site to make the QueryContext consumer available everywhere.
 */
class QueryAware extends React.Component {
  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.func]).isRequired,
    location: ReactRouterPropTypes.location.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = { query: this.getValueForSearch(props.location.search) };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.location.pathname !== nextProps.location.pathname) {
      this.setState({ query: this.getValueForSearch(nextProps.location.search) });
    } else if (this.props.location.search !== nextProps.location.search) {
      this.setState({ query: this.getValueForSearch(nextProps.location.search) });
    }
  }

  getValueForSearch = search => Object.entries(qs.parse(search || '')).reduce(this.setObjectValue, {});

  setObjectValue = (set, [key, value]) => {
    const keys = [];
    let nested = null;
    let current = key;
    do {
      nested = current.match(/(.*)(\[(.*)\])+/);
      if (nested) {
        const [, next, , item] = nested;
        current = next;
        keys.push(item);
      } else {
        keys.push(current);
      }
    } while (nested);

    let target = set;
    while (keys.length > 1) {
      current = keys.pop();
      if (!target[current]) { target[current] = {}; }
      target = target[current];
    }

    target[keys.pop()] = value;

    return set;
  }

  render() {
    return (
      <QueryContext.Provider value={this.state.query}>
        {this.props.children}
      </QueryContext.Provider>
    );
  }
}

export default withRouter(QueryAware);

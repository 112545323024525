/* eslint-disable import/prefer-default-export */
import { uniqBy } from 'lodash';
import * as types from './types';

export const replaceDashboard = data => dispatch => Promise.resolve(dispatch({
  type: types.INITIALIZE_DASHBOARD,
  ...data,
}));

export const replaceDashboardData = data => dispatch => Promise.resolve(dispatch({
  type: types.REPLACE_DASHBOARD_DATA,
  ...data,
}));

const mergeArray = (key, value, getState, prepend = false) => {
  const iteratee = key === 'zip_code_eligible' ? undefined : 'id';

  return prepend
    ? uniqBy(value.concat(getState().dashboard.content[key]), iteratee)
    : uniqBy((getState().dashboard.content[key] || []).concat(value), iteratee);
};

const mergeObject = (key, value, getState) => ({
  ...getState().dashboard.content[key],
  ...value,
});

export const addDashboardData = (data, prepend = false) => (dispatch, getState) => {
  Promise.resolve(dispatch({
    type: types.REPLACE_DASHBOARD_DATA,
    ...Object.entries(data).reduce((sum, [key, value]) => ({
      ...sum,
      [key]: Array.isArray(value)
        ? mergeArray(key, value, getState, prepend)
        : mergeObject(key, value, getState),
    }), {}),
  }));
};

export const replaceDashboardIndex = data => dispatch => Promise.resolve(dispatch({
  type: types.REPLACE_DASHBOARD_INDEX,
  ...data,
}));

export const addDashboardIndex = (data, prepend = false) => (dispatch, getState) => {
  Promise.resolve(dispatch({
    type: types.REPLACE_DASHBOARD_INDEX,
    ...Object.entries(data).reduce((sum, [key, value]) => ({
      ...sum,
      [key]: prepend
        ? value.concat(getState().dashboard.index[key])
        : (getState().dashboard.index[key] || []).concat(value),
    }), {}),
  }));
};

export const mergeDashboardData = data => (dispatch, getState) => Promise.resolve(dispatch({
  type: types.REPLACE_DASHBOARD_DATA,
  ...Object.entries(data).reduce((dashboard, [key, value]) => ({
    ...dashboard,
    [key]: value.reduce((m, row) => {
      const rowIdx = m.findIndex(r => r.id === row.id);
      if (rowIdx < 0) {
        return m.concat(row);
      }
      m.splice(rowIdx, 1, row);
      return m;
    }, getState().dashboard.content[key].slice()),
  }), {}),
}));

export const mergeDashboardReferralEvents = (referralId, organizationType, organizationId) => (dispatch) => (
  Promise.resolve(dispatch({
    type: types.MERGE_DASHBOARD_REFERRAL_EVENTS,
    content: { [`${referralId}/${organizationType}/${organizationId}`]: { referralId, organizationType, organizationId } },
  }))
);

export const mergeDashboardMessages = (referralId, data, section) => (dispatch, getState) => (
  Promise.resolve(dispatch({
    type: types.MERGE_DASHBOARD_MESSAGES,
    section,
    [section]: {
      ...(Object.keys(getState().dashboard.content[section] || {}))
        .filter(key => key.startsWith(`${referralId}`))
        .reduce((sum, key) => ({
          ...sum,
          [key]: null,
        }), {}),
      ...data,
    },
  }))
);

export const prependDashboardData = data => addDashboardData(data, true);

export const addDashboardReferral = (referral, category) => dispatch => Promise.resolve(dispatch({
  type: types.REPLACE_DASHBOARD_REFERRAL,
  referral,
  category,
}));

export const removeDashboardReferral = referral => dispatch => Promise.resolve(dispatch({
  type: types.REMOVE_DASHBOARD_REFERRAL,
  referral,
}));

export const removeDashboardTask = taskId => dispatch => Promise.resolve(dispatch({
  type: types.REMOVE_DASHBOARD_TASK,
  task: taskId,
}));

export const removePatientReferral = referralId => dispatch => Promise.resolve(dispatch({
  type: types.REMOVE_PATIENT_REFERRAL,
  referral: referralId,
}));

export const removePatientFromDashboard = patientId => dispatch => Promise.resolve(dispatch({
  type: types.REMOVE_PATIENT_FROM_DASHBOARD,
  patient: patientId,
}));

export const incrementDashboardVersion = () => dispatch => Promise.resolve(dispatch({
  type: types.INCREMENT_DASHBOARD_VERSION,
}));

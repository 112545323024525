import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'react-emotion';

import { openMessageMultipleProvidersModal } from 'ducks/ui';

import { createTranslator } from 'helpers/i18n';
import Referral from 'models/Referral';

import {
  ConfirmationPopup,
  TextField,
  Panel,
  Styles,
} from 'components/utils';

const { LinkText, Secondary } = Styles;

const tr = createTranslator({
  text: 'Message multiple providers',
  label: 'Enter message',
  button: {
    confirm: 'Select Providers',
  },
  form: {
    text: {
      placeholder: 'Your message here...',
      help: 'You can also press Control+Enter to send your message',
    },
  },
});

const Container = styled.span();

const SendToManyProvidersLink = ({
  children,
  disabled,
  label,
  openMessageMultipleProvidersModal: openModal,
  referral,
  referral: {
    referralRole: role,
    sent_referral_index: sent = [],
  },
  text,
  selection,
  onMessageSent,
}) => {
  const renderComponent = (onClick) => {
    const TextStyle = disabled ? Secondary : LinkText;
    return (
      <Container onClick={disabled ? undefined : onClick}>
        {children || (
          <TextStyle>{label || tr('text')}</TextStyle>
        )}
      </Container>
    );
  };
  const [state, dispatch] = useReducer((s, p) => ({ ...s, ...p }), {
    open: false, value: '',
  });
  const eligible = role === 'sending'
    && sent.filter(({ status }) => status !== 'unavailable').length > 1;
  if (!eligible) {
    return null;
  }
  if (text) {
    return renderComponent(() => {
      openModal(referral, text, selection, onMessageSent);
    });
  }

  const handleSubmit = () => {
    openModal(referral, state.value, selection);
  };

  const handleCheckForSubmit = (e) => {
    if (e.ctrlKey && e.keyCode === 13) {
      handleSubmit();
    }
  };

  if (disabled) {
    return renderComponent();
  }

  return (
    <ConfirmationPopup
      confirmText={tr('button.confirm')}
      open={state.open}
      onOpen={() => { dispatch({ value: '' }); }}
      onClose={() => { dispatch({ open: false }); }}
      onConfirm={handleSubmit}
      disabled={!state.value}
      offsetY={8}
      position="bottom right"
      trigger={renderComponent()}
    >
      <Panel spacing={4}>
        <TextField
          lines={4}
          label={tr('label')}
          value={state.value}
          onChange={(value) => { dispatch({ value }); }}
          onKeyDown={handleCheckForSubmit}
        />
        <Secondary>
          {tr('form.text.help')}
        </Secondary>
      </Panel>
    </ConfirmationPopup>
  );
};
SendToManyProvidersLink.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.func]),
  disabled: PropTypes.bool,
  label: PropTypes.string,
  onMessageSent: PropTypes.func,
  openMessageMultipleProvidersModal: PropTypes.func.isRequired,
  referral: Referral.isRequired,
  selection: PropTypes.arrayOf(PropTypes.number),
  text: PropTypes.string,
};
SendToManyProvidersLink.defaultProps = {
  children: undefined,
  disabled: undefined,
  label: undefined,
  onMessageSent: undefined,
  text: undefined,
  selection: undefined,
};

export default connect(null, { openMessageMultipleProvidersModal })(SendToManyProvidersLink);

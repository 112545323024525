/* eslint-disable import/prefer-default-export */

import * as types from './types';

/**
 * `documentsInfo` contains the `size` and `content` for the listing. Used for pagination.
 */
export const replaceDocuments = (documentsInfo, source) => dispatch => Promise.resolve(dispatch({
  type: types.REPLACE_DOCUMENTS,
  source,
  documentsInfo,
}));

export const replaceDocument = (document, source) => dispatch => Promise.resolve(dispatch({
  type: types.REPLACE_DOCUMENT,
  document,
  source,
}));

export const removeDocument = (document, source) => dispatch => Promise.resolve(dispatch({
  type: types.REMOVE_DOCUMENT,
  document,
  source,
}));

export const stageDocument = (document, source, visible, immediate) => dispatch => (
  Promise.resolve(dispatch({
    type: types.STAGE_DOCUMENT,
    document,
    source,
    visible,
    immediate,
  }))
);

export const refreshDuplicateDetected = (refreshData, source) => dispatch => Promise.resolve(dispatch({
  type: types.REFRESH_DUPLICATE_DETECTED,
  refreshData,
  source,
}));

export const loadStagedDocuments = source => dispatch => Promise.resolve(dispatch({
  type: types.LOAD_STAGED_DOCUMENTS,
  source,
}));

export const populateSignatures = signatures => dispatch => Promise.resolve(dispatch({
  type: types.POPULATE_SIGNATURES,
  signatures,
}));

export const addSignature = signature => dispatch => Promise.resolve(dispatch({
  type: types.ADD_SIGNATURE,
  signature,
}));

export const removeSignature = index => dispatch => Promise.resolve(dispatch({
  type: types.DELETE_SIGNATURE,
  index,
}));

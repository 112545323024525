import PropTypes from 'prop-types';

const Message = PropTypes.shape({
  id: PropTypes.number.isRequired,
  created_at: PropTypes.string,
  /** True if read, false otherwise */
  read_flag: PropTypes.bool,
  /** Recipient organization */
  organization: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  /** Represents the sender of the message */
  message_content: PropTypes.shape({
    text: PropTypes.string,
    patient: PropTypes.shape({
      name: PropTypes.string,
      hospital: PropTypes.shape({
        name: PropTypes.string,
      }),
    }),
    referral: PropTypes.shape({
      id: PropTypes.number.isRequired,
      level_of_care: PropTypes.arrayOf(PropTypes.string),
    }).isRequired,
    /** User sending the message */
    user: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string.isRequired,
    }).isRequired,
    /** Organization representing the user */
    organization: PropTypes.shape({
      name: PropTypes.string,
    }).isRequired,
  }).isRequired,
});

export default Message;

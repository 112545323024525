/* eslint-disable react/no-danger */

import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { css } from 'emotion';
import styled from 'react-emotion';
import Popup from 'reactjs-popup';

import { SECONDARY_FILL } from 'components/utils/Colors';
import { Secondary } from 'components/utils/Styles';
import { createTrigger, modalOverrides, baseModalStyleAttributes } from 'components/utils/PopupUtils';
import Panel from 'components/utils/Panel';
import Icon from 'components/utils/Icon';
import { PopupPosition } from 'components/utils/Popup';

import { createTranslator } from 'helpers/i18n';
import translations from 'translations/help';
import helpTextParser from 'helpers/helpTextParser';
import { ThemeContext } from 'components/utils/ThemeContext';

const tr = createTranslator(translations);

const iconContainerStyle = css`
  cursor: pointer;
`;

const ModalContent = styled(Secondary)`
  opacity: 1;
  color: ${SECONDARY_FILL};
  padding: 20px;
  ul {
    margin-left: 16px;
  }
`;

const helpMark = (colors) => css`
  cursor: pointer;
  padding: 3px;
  border-radius: 50%;
  width: 25px;
  background-color: ${colors.WHITE};
  border: 1px solid ${colors.PRACTICE_FILL};
  color: ${colors.BLACK};
  font-size: 16px;
  text-align: center;
`;

/**
 * Renders a question mark icon that, when clicked, renders scrollable popup div
 */
const Help = (props) => {
  const themeInfo = useContext(ThemeContext);
  let trigger;
  switch (themeInfo.version) {
    case 'v2':
      trigger = (
        <Panel className={helpMark(themeInfo.theme.colors)}>?</Panel>
      );
      break;
    default:
      trigger = (
        <Icon
          key="btn"
          name={`${props.icon || Help.defaultProps.icon}`}
          className={`${iconContainerStyle} ${props.iconStyle || ''}`}
        />
      );
  }

  const value = props.value ? (tr(props.value, props.messageVariables)
  || props.value) : (props.value || props.children);
  const component = props.children && !props.html
    ? value : helpTextParser(value, props.html, props.onAction);
  const triggerOn = props.triggerOn || props.on;
  const contentStyle = {
    ...modalOverrides,
    ...baseModalStyleAttributes,
    width: `${props.width}px`,
    height: `${props.height ? `${props.height}px` : 'auto'}`,
    overflowY: 'visible',
  };
  return (
    <Popup
      arrow={false}
      closeOnDocumentClick
      contentStyle={contentStyle}
      on={triggerOn === 'none' ? [] : triggerOn}
      trigger={createTrigger(props, props.trigger || trigger)}
      position={props.position.indexOf(' ') > 0 ? props.position : `${props.position} center`}
      offsetX={props.offsetX}
      offsetY={props.offsetY}
    >
      <ModalContent>
        {component}
      </ModalContent>
    </Popup>
  );
};
Help.propTypes = {
  /** Custom help content. For simple strings, use value instead. */
  children: PropTypes.node,
  /** Set the height of the popup */
  height: PropTypes.number,
  /** True if this should be rendered as HTML, false otherwise */
  html: PropTypes.bool,
  /** Set the icon class; mp-question by default */
  icon: PropTypes.string,
  /** Set the icon style, including text color. */
  iconStyle: PropTypes.string,
  /** Offset for X axis */
  offsetX: PropTypes.number,
  /** Offset for Y axis */
  offsetY: PropTypes.number,
  /** Position of the tooltip */
  position: PopupPosition,
  /** Perhaps the ID or lookup key of help text, to lookup dynamically. */
  value: PropTypes.string,
  /** The width, in pixels, of the popup */
  width: PropTypes.number,
  /** A custom trigger icon element instead of the built in question mark */
  trigger: PropTypes.instanceOf(Icon),
  /** A custom action to show the help popup */
  triggerOn: PropTypes.oneOf(['hover', 'click', 'focus', 'none']),
  /** Deprecated: a custom action to show the help popup */
  on: PropTypes.oneOf(['hover', 'click', 'focus', 'none']),
  /** For action links in value, onAction function call */
  onAction: PropTypes.func,
  /** Custom object data passed into translations for the help message */
  messageVariables: PropTypes.shape({}),
};
Help.defaultProps = {
  children: null,
  height: null,
  html: undefined,
  icon: 'mp-question',
  iconStyle: null,
  value: null,
  width: 300,
  offsetX: undefined,
  offsetY: undefined,
  position: 'bottom',
  trigger: null,
  triggerOn: 'click',
  on: 'click',
  onAction: () => {},
  messageVariables: undefined,
};

export default Help;

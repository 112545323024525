import * as types from 'ducks/ui/types';

/**
 * Mapping of action name to a registration key. This key must
 * match the pattern `ui/toggle/[key]`, where [key] is the
 * name of the key used in the store for the given modal.
 */
const registry = Object.entries(types)
  .filter(([, action]) => action.match(/^ui\/toggle\/.*/))
  .reduce((sum, [, action]) => ({
    ...sum,
    [action]: action.split('/')[2],
  }), {});

/**
 * Toggle Global Modals without having to connect to the Redux
 * store directly or define an explicit set of functions in
 * ducks/ui/actions. As long as your modal toggles by taking an
 * open param to trigger open/close state, and some additional
 * params alongside it for other state, this utility will work
 * for any general use case of a modal.
 */
class GlobalModal {
  bindToStore = (toggleModalFunc) => {
    this.toggleModal = toggleModalFunc;
  }

  find = (key) => ({
    open: (params) => new Promise((resolve, reject) => {
      if (registry[key] && this.toggleModal) {
        this.toggleModal({
          open: true,
          ...params,
          globalModalReduxKey: registry[key],
        }).then(resolve).catch(reject);
      }
    }),
    close: (params) => new Promise((resolve, reject) => {
      if (registry[key] && this.toggleModal) {
        this.toggleModal({
          open: false,
          ...params,
          globalModalReduxKey: registry[key],
        }).then(resolve).catch(reject);
      }
    }),
  })

  open = (key, params) => this.find(key).open(params);

  close = (key, params) => this.find(key).close(params);
}

const instance = new GlobalModal();

export { instance as default, types as modals };

import PropTypes from 'prop-types';
import AidinPropTypes from 'helpers/AidinPropTypes';
import { flatten, uniq, uniqBy } from 'lodash';
import {
  FEATURE_INBOUND_FAX, FEATURE_AIDIN_PRINTER,
  FEATURE_PROVIDER_VERIFICATION_WARNING,
  FEATURE_PATIENT_RESPONSE_SEND_BUTTONS,
} from 'ducks/session/types';
import { Date, DateTime } from 'helpers/dates';
import ProviderType from 'models/ProviderType';


export const hasSendingOrganization = organizations => organizations
  .some(({ organization_type: type }) => type === 'Hospital');

export const hasReceivingOrganization = organizations => organizations
  .some(({ organization_type: type }) => type === 'Provider');

export const getDashboardSectionTypes = organizations => uniq(flatten(
  organizations.map(({ dashboard_sections: sections = [] } = {}) => flatten(
    sections.map(({ dashboard_section_type: section }) => section),
  )),
));

export const getAvailableCommandCenterSections = organizations => uniq(flatten(
  organizations.map(({ dashboard_sections: dashboardSections = [] } = {}) => flatten(
    dashboardSections
      .filter(({ dashboard_section_type: type }) => type === 'command_center')
      .map(({ settings: { available_sections: sections = [] } = {} }) => sections),
  )),
)).filter(i => ['command_center_trns', 'command_center_acute'].some(k => i === k));

export const getFirstIntegrationPath = ({ features }) => {
  const integrations = {
    [FEATURE_AIDIN_PRINTER]: '/integrations/printers',
    [FEATURE_INBOUND_FAX]: '/integrations/fax-lines',
  };
  const [firstMatch] = Object.entries(integrations).filter(([feature]) => features[feature]);
  if (firstMatch) {
    const [, path] = firstMatch;
    return path;
  }

  return '/integrations/printers'; // Will 404, but should never get here.
};

export const getOrganizationUsers = organizations => uniqBy(
  flatten(organizations.map(({ team = [] }) => team)),
  'id',
);

export const isHospitalAdmin = ({
  roles,
} = {}) => roles.some(r => r === 'hospital_admin') || (roles.some(r => r === 'hospital') && roles.some(r => r === 'admin'));

export const isProviderAdmin = ({
  roles,
  organizations,
} = {}, { id } = {}, strict = false) => organizations
  .filter(({ organization_id: providerId }) => !id || id === providerId)
  .some(({
    organization_type: type,
    organization: { management_mode: mode } = {},
    roles: roleList,
  }) => (type === 'Provider' && ((mode === 'closed' || strict)
    ? roleList.some(r => r === 'provider_admin')
    : roles.some(r => r === 'provider_admin')
    || roles.some(r => r === 'provider')
    || (roles.some(r => r === 'provider') && roles.some(r => r === 'admin')))));

/**
 * Return a list of all organizations this user has the right to administer.
 * * All hospitals for the hospital_admin role
 * * All open providers
 * * All closed providers with the provider_admin role
 */
export const getAdministrableOrganizations = (user) => {
  const { organizations = [] } = (user || {});
  const administrable = organizations
    .filter(({
      organization_type: type,
      organization: { management_mode: mode } = {},
      roles = [],
    }) => (
      (type === 'Hospital' && roles.some(r => r === 'hospital_admin'))
      || (type === 'Provider' && (mode === 'open' || roles.some(r => r === 'provider_admin')))
    ))
    .map(({ organization }) => organization)
    .sort((a, b) => (a.name || '').localeCompare(b.name));
  return uniqBy(administrable, 'id');
};

export const isOrganizationAdmin = user => isHospitalAdmin(user) || isProviderAdmin(user);

export const hasOpenProvider = ({
  organizations = [],
} = {}) => organizations.some(({ organization: { management_mode: mode } = {} }) => mode === 'open');

export const hasClosedProvider = ({
  organizations = [],
} = {}) => organizations.some(({ organization: { management_mode: mode } = {} }) => mode === 'closed');

export const hasNoVerifiedIdentity = ({ identity_verifications: verifications = [] } = {}) => (
  verifications.filter(({ status }) => status !== 'expired').length === 0
);

export const hasVerifiedIdentity = ({ identity_verifications: verifications = [] } = {}) => (
  verifications.filter(({ status }) => status !== 'expired').some(({ status }) => status === 'succeeded')
);

export const hasPendingVerifiedIdentity = ({ identity_verifications: verifications = [] } = {}) => (
  verifications.filter(({ status }) => status !== 'expired').some(({ status }) => status && status !== 'succeeded')
);

export const getVerifiedOrganizations = (organizations = []) => organizations
  .filter(({ organization_purchases: purchases = [] }) => (
    purchases.some(({ feature: f, expires_at: expires, details }) => {
      if (f === 'verified_admin' && details.status !== 'cancelled') {
        return expires ? DateTime.parse(expires).isAfter(DateTime.now()) : true;
      }
      return false;
    })
  ));

const today = Date.now();

const verifiedAdminComplete = (provider) => provider.management_mode === 'closed';

const isProviderCareTypesVerified = (provider) => {
  if (provider.care_types_verified_at) {
    return today.diff(Date.parse(provider.care_types_verified_at), 'days') < 90;
  }
  return false;
};

const isProviderInsurancesVerified = (provider) => {
  if (provider.insurances_verified_at) {
    return today.diff(Date.parse(provider.insurances_verified_at), 'days') < 90;
  }
  return false;
};

const isProviderGeographyVerified = (provider) => {
  if (provider.geography_verified_at) {
    return today.diff(Date.parse(provider.geography_verified_at), 'days') < 90;
  }
  return false;
};

export const isProviderVerified = (provider) => isProviderCareTypesVerified(provider)
  && isProviderInsurancesVerified(provider);

export const isGeographyProviderVerified = (provider) => isProviderVerified(provider)
  && isProviderGeographyVerified(provider);

export const hasSearchByLocation = (provider) => {
  if (provider.provider_type) {
    const providerType = ProviderType[provider.provider_type.toUpperCase()];
    if (providerType) {
      return providerType.searchByLocation;
    }
  }
  return ProviderType.defaults.searchByLocation;
};

const hasProviders = (providers = []) => providers.some((provider) => provider.organization_type === 'Provider');

export const testProvider = (provider) => (hasSearchByLocation(provider)
  ? isGeographyProviderVerified(provider)
  : isProviderVerified(provider));

export const isProvidersVerified = (providers = []) => !hasProviders(providers)
  || providers.every((provider) => {
    if (provider.organization_type === 'Provider') {
      return testProvider(provider);
    }
    return true;
  });


export const getProviderAccessDetails = (user) => {
  const items = user.user_organizations.filter(({
    organization_type: type,
  }) => type === 'Provider').map(({
    organization: {
      management_mode: mode,
      organization_purchases: purchases = [],
    } = {},
    organization,
    roles = [],
  }) => ({
    organization,
    isAdmin: mode === 'open' || roles.some(r => r === 'provider_admin'),
    isRestricted: mode === 'closed'
      && purchases.some(({ feature: f, expires_at: expires, details }) => {
        if (f === 'verified_admin' && details.status !== 'cancelled') {
          return expires ? DateTime.parse(expires).isAfter(DateTime.now()) : true;
        }
        return false;
      }),
  }));

  return {
    hasOpenProviders: items.length > 0 && items.some(i => !i.isRestricted),
    hasClosedProviders: items.some(i => i.isRestricted),
    isAdmin: items.some(i => i.isAdmin),
    isVerifiedAdmin: hasVerifiedIdentity(user),
  };
};

export const getUnclaimedLocations = (organizations = []) => organizations
  .filter(({ organization_purchases: purchases = [] }) => (
    purchases.some(({ feature: f }) => f !== 'verified_admin')
  ));

export const getOrganizationOptions = ({ user, organizations }, currentFeature) => organizations
  .filter(({ organization_type: type, organization_purchases: purchases }) => (
    type === 'Provider' && !purchases.some(({ feature, details: { status } = {} }) => feature === currentFeature && status !== 'cancelled')
  )).filter(({ id, management_mode: mode }) => (
    currentFeature === 'verified_admin' ? mode === 'open' : isProviderAdmin(user, { id })
  ));

/**
 * Return a list of all user organizations that has made a purchase of the given feature
 * @param {string} purchase - the feature to filter by
 * @param {array} defaultOrganizations - the list of all user organizations to filter
 * @param {array} organizations - the list of organizations attached to a a specific location
 * @returns {array} - the list of organizations that has made a purchase of the given feature
 */
export const getSubscribedOrganizations = (
  purchase,
  defaultOrganizations = [],
  organizations,
) => defaultOrganizations
  .filter(({ id, organization_type: type }) => !organizations || (
    organizations.some(org => org.id === id && org.organization_type === type)
  ))
  .filter(({ organization_purchases: purchases = [] }) => (
    purchases.some(({
      feature,
      expires_at: expiration,
    }) => (
      feature === purchase
        && (!expiration || DateTime.parse(expiration).isAfter(DateTime.now()))
    ))
  ));

export const HospitalSystem = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  type: PropTypes.string,
  settings: PropTypes.shape({}),
});

export const Organization = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  organization_type: PropTypes.string.isRequired,
  url: PropTypes.string,
  settings: PropTypes.shape({}),
  units: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  })),
  users: PropTypes.arrayOf(PropTypes.shape({
    email: PropTypes.string,
    id: PropTypes.number,
    name: PropTypes.string,
    previewer: PropTypes.bool,
  })),
  hospital_system: HospitalSystem,
});

export const SystemAlert = PropTypes.shape({
  active: PropTypes.bool.isRequired,
  description: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  link: PropTypes.string,
  severity: PropTypes.string.isRequired,
  sticky: PropTypes.bool.isRequired,
  title: PropTypes.string,
});

export const User = PropTypes.shape({
  email: PropTypes.string,
  first: PropTypes.string,
  id: PropTypes.number,
  last: PropTypes.string,
  name: PropTypes.string,
  fax: PropTypes.string,
  phone: PropTypes.string,
  sms: PropTypes.string,
  organization_role: PropTypes.oneOf(['none', 'admin_director', 'intake', 'nursing', 'corporate', 'it_staff', 'medical_director']),
  organizations: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    organization_id: PropTypes.number,
    organization_type: PropTypes.string,
    authorized: PropTypes.bool,
    default: PropTypes.bool,
    roles: PropTypes.arrayOf(PropTypes.string),
    organization: Organization.isRequired,
  })),
  prefs: PropTypes.shape({
    notify: PropTypes.shape({}),
  }),
  preview_email: PropTypes.string,
  previewer: PropTypes.bool,
  roles: PropTypes.arrayOf(PropTypes.string),
  updated_at: AidinPropTypes.dateTime,
  password_last_update_at: AidinPropTypes.dateTime,
  days_before_password_expiration: PropTypes.number,
});

export const shouldDisableFeatureWithoutPurchase = (session, featureName, organization) => {
  const {
    organizations = [],
  } = session;
  const newOrganization = organizations.filter(org => org.id === organization?.id
    && org.organization_type === organization?.organization_type);
  const eligible = newOrganization.length ? newOrganization : organizations;
  const subscribed = eligible.some(({ organization_purchases: purchases = [] }) => (
    purchases.some(({
      feature,
      expires_at: expiration,
    }) => (
      feature === featureName
      && (!expiration || DateTime.parse(expiration).isAfter(DateTime.now()))
    ))
  ));
  return subscribed ? false : null; // Null prevents this from appearance in ComingSoon section
};

export const shouldDisableFeatureWithoutFlagEnabled = (session, ...flags) => flags.map(flag => {
  const {
    features: {
      [flag]: isRequiredFeatureEnabled,
    } = {},
  } = session;
  return isRequiredFeatureEnabled ? false : null;
}).every(shouldDisable => shouldDisable === null);

export const shouldDisableInboxSubmenu = (session) => (
  shouldDisableFeatureWithoutPurchase(session, 'aidin_inbox') || shouldDisableFeatureWithoutFlagEnabled(session, FEATURE_INBOUND_FAX)
);

export const isInboxInboundEnabled = (user, session) => !shouldDisableInboxSubmenu(session);

export const getYearlyReportYear = () => {
  const month = DateTime.now().get('month');
  const year = DateTime.now().get('year');
  return month > 10 ? year : year - 1;
};

const Session = PropTypes.shape({
  /** The current organization */
  organizations: PropTypes.arrayOf(Organization),
  user: User,
  features: PropTypes.objectOf(PropTypes.bool),
  system_alerts: PropTypes.arrayOf(SystemAlert),
});

export const testSingleProviderCriteriaVerification = (provider, criteria) => {
  switch (criteria) {
    case 'verifiedAdmin': {
      return verifiedAdminComplete(provider);
    }
    case 'needs': {
      return isProviderCareTypesVerified(provider);
    }
    case 'insurances': {
      return isProviderInsurancesVerified(provider);
    }
    case 'counties': {
      return isProviderGeographyVerified(provider);
    }
    default:
      return true;
  }
};

export const featureVerificationWarningEnabled = (session) => !shouldDisableFeatureWithoutFlagEnabled(session,
  FEATURE_PROVIDER_VERIFICATION_WARNING);

export const hasSingleProviderCriteriaVerificationWarning = (session, provider, criteria) => {
  const hasWarning = featureVerificationWarningEnabled(session)
    && !testSingleProviderCriteriaVerification(provider, criteria);
  return hasWarning;
};

export const hasSingleProviderVerificationWarning = (session, provider) => {
  const hasWarning = featureVerificationWarningEnabled(session)
    && !testProvider(provider);
  return hasWarning;
};

export const hasProviderVerificationWarning = (session) => {
  const hasWarning = featureVerificationWarningEnabled(session)
    && !isProvidersVerified(session.user.providers);
  return hasWarning;
};

export const disableInboxFeaturesHandler = (referral, session) => shouldDisableFeatureWithoutFlagEnabled(
  session, FEATURE_PATIENT_RESPONSE_SEND_BUTTONS, referral.hospital,
) || shouldDisableFeatureWithoutPurchase(session, 'aidin_inbox', referral.hospital) !== false;

export default Session;

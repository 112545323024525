/*
 * Only needs to be required once at app launch for initialization
 */

import moment from 'moment';
import { pluralize } from 'helpers/i18n';

/**
 * Performs any custom moment-based locale loading tasks.
 */
const initialize = () => {
  moment.relativeTimeThreshold('d', 7 * 4);
  moment.updateLocale('en', {
    relativeTime: {
      future: 'in %s',
      past: '%s ago',
      s: 'a few seconds',
      ss: '%d seconds',
      m: 'a minute',
      mm: '%d minutes',
      h: 'an hour',
      hh: '%d hours',
      d: 'a day',
      dd: (number) => {
        if (number < 14) {
          return `${number} days`; // Moment uses "d" when it's just 1 day.
        }

        const weeks = Math.round(number / 7);
        return `${weeks} ${pluralize(weeks, 'week')}`;
      },
      M: 'a month',
      MM: '%d months',
      y: 'a year',
      yy: '%d years',
    },
  });
};

export default initialize;

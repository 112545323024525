import { combineReducers } from 'redux';
import providers from './providers/reducers';
import team from './team/reducers';
import providerNetworks from './providerNetworks/reducers';
import providerExclusions from './providerExclusions/reducers';
import providerDisclosures from './providerDisclosures/reducers';

const admin = combineReducers({
  ...providers,
  ...team,
  ...providerNetworks,
  ...providerExclusions,
  ...providerDisclosures,
});

export default { admin };

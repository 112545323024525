import React from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion';

const DEFAULT_VERTICAL_MARGIN = 20;

const DividerDiv = styled.div`
  border-bottom: 1px solid #e6e6e6;
  margin: ${props => props.margin}px 0px;
  width: 100%;
`;

/**
 * A gray, full-width horizontal-rule-like divider with vertical margin.
 */
const Divider = props => (
  <DividerDiv margin={props.margin} />
);
Divider.propTypes = {
  /** The vertical margin in pixels for this divider. Default 20. */
  margin: PropTypes.number,
};
Divider.defaultProps = {
  margin: DEFAULT_VERTICAL_MARGIN,
};

export default Divider;

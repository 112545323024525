/* global Rollbar */
import mayRollbar from 'helpers/mayRollbar';

let instance = null;
let errorCallbackValue;

/**
 * Fatal errors will wait this many ms to fire. This is so that
 * Error Boundaries can be used in the system and not shut down the
 * entire app. Without this, the fatal error handler would fire and
 * always present a full-screen error message.
 */
const ERROR_BOUNDARY_DELAY = 200;

/**
 * Harmless errors that we do NOT want to cause a full-screen error,
 * and do NOT want to send to Rollbar.
 */
const ignoredMessages = [
  'InvalidValueError',
  'ResizeObserver loop limit exceeded',
  /.*Script error.*/i,
  /^ReferenceError:.*is not defined$/,
];

const locallyHandleArgs = (...args) => {
  console.log(args); // eslint-disable-line no-console
  args.forEach((arg) => {
    if (typeof arg === 'function') {
      // simulate a Rollbar callback
      arg(errorCallbackValue, errorCallbackValue ? undefined : {
        result: {
          uuid: 'xdevelop-0000-0000-0000-notarealuuid',
        },
      });
    }
  });
};

const initializeRollbar = () => {
  // setup dynamic host mapping for source maps
  if (window) {
    if (typeof Rollbar === 'undefined') {
      console.log('Rollbar global is undefined'); // eslint-disable-line no-console
      window.Rollbar = {
        debug: locallyHandleArgs,
        info: locallyHandleArgs,
        warning: locallyHandleArgs,
        error: locallyHandleArgs,
        critical: locallyHandleArgs,
        configure: () => { },
      };
    } else {
      Rollbar.configure({
        // Ignore random errors that happen from non-users
        ignoredMessages,
        // this transform handles multi-hostnames for source maps
        transform(payload) {
          const { trace } = payload.body;
          // Change 'yourdomainhere' to your domain.
          const locRegex = /^(https?):\/\/[a-zA-Z0-9._-]+\.myaidin\.(?:com|net|us|test)(.*)/;
          if (trace && trace.frames) {
            for (let i = 0; i < trace.frames.length; i += 1) {
              const { filename } = trace.frames[i];
              if (filename) {
                const m = filename.match(locRegex);
                // Be sure that the minified_url when uploading includes 'dynamichost'
                if (m && m.length > 1) {
                  trace.frames[i].filename = `${m[1]}://dynamichost${m[2]}`;
                }
              }
            }
          }
        },
      });
    }
  }
};

/**
 * Error handler mechanism that can handle and report
 * caught and uncaught exceptions.
 *
 * Handle caught errors via:
 *
 *  ErrorHandler.get().handle(e);
 *
 * Errors are still reported to the console.
 */
export default class ErrorHandler {
  constructor({
    onFatalError = null,
  } = {}) {
    if (!instance) {
      initializeRollbar();

      instance = this;

      this.originalErrorHandler = window.onerror;
      this.onFatalError = onFatalError;
      this.handled = {};

      this.ignored = ignoredMessages
        .map(item => (typeof item === 'string' ? new RegExp(`^${item}$`, 'i') : item));

      this.enable();

      window.ErrorHandler = instance;
    }
    return instance;
  }

  static get = () => instance;

  disable = () => {
    if (Rollbar) {
      Rollbar.configure({ enabled: false });
    }
    window.onerror = undefined; // this.originalErrorHandler;
  }

  enable = () => {
    if (Rollbar) {
      Rollbar.configure({ enabled: true });
    }
    window.onerror = this.handleFatalError;
  }

  /**
   * TODO: Perhaps there may be a difference in how errors are handled based on
   * some property? For now, treat them all as fatal.
   */
  handle = this.handleFatal;

  /**
   * Report the error, but don't report it back to the application, as it
   * was sourced from the app
   */
  handleReactError = (error, info) => { // eslint-disable-line
    mayRollbar.critical(error.message, error, info);
  }

  /**
   * Disconnected error, need to report to the app so it can attempt to
   * render something useful
   */
  handleFatalError = (...args) => {
    setTimeout(() => {
      const [msg, url, line, col, error] = args;
      let details = error;
      if (!error) {
        details = `${msg} (${url}:${line}`;
        if (col) {
          details += `:${col}`;
        }
        details += ')';
      }
      const shouldIgnore = this.checkIgnored(error) || this.checkIgnored(msg);
      if (shouldIgnore) {
        // Won't go to Rollbar, but should be around on help button click
        mayRollbar.warn(msg, error, args);
      } else if (!(this.checkHandled(error) || this.checkHandled(msg))) {
        if (this.onFatalError) {
          this.onFatalError(details);
        }
      }
      if (this.originalErrorHandler && !shouldIgnore) {
        this.originalErrorHandler(...args);
      }
    }, ERROR_BOUNDARY_DELAY);
  }

  /**
   * A one-time denotation that an error with a particular message has
   * been handled. Should be called from withErrorBoundary, and should
   * be cleared in short order.
   */
  markHandled = (error) => {
    if (error) {
      this.handled[error.message] = error;
    }
  }

  /**
   * Check to see if an error that was just caught has already been handled,
   * most likely by withErrorBoundary. If so, then return true so that the
   * default error handler does not fire, and clear the marking so that the
   * next error with the same message will fire, unless it is marked.
   */
  checkHandled = (error) => {
    if (error) {
      const errorKey = error.message || error;
      if (errorKey && this.handled[errorKey]) {
        delete this.handled[errorKey];
        return true;
      }
    }
    return false;
  }

  /**
   * Check to see if an error should be ignored. If so, report, but do not
   * show a full-scren error.
   */
  checkIgnored = (error) => {
    if (error) {
      const errorKey = error.message || error;
      if (errorKey && this.ignored.some(m => errorKey.match && errorKey.match(m))) {
        return true;
      }
    }
    return false;
  }

  reportError = ({ error, message, data }) => {
    mayRollbar.error(message, error, data);
  }
}

/**
 * Use the builder function to initialize the error
 * handler. From there, use ErrorHandler to handle
 * any caught exceptions.
 */
export const errorHandlerBuilder = {
  build: args => new ErrorHandler(args),
};

/**
 * Report an error to the error handling tool. The param is an object
 * containing the following:
 */
export const reportError = (error, message, data) => {
  if (ErrorHandler.get()) {
    ErrorHandler.get().reportError({
      error,
      message,
      data,
    });
  }
};

/**
 * Mark a particular error as handled, so that a fatal error will not
 * be thrown. It will still be reported.
 */
export const markErrorHandled = (error) => {
  if (ErrorHandler.get()) {
    ErrorHandler.get().markHandled(error);
  }
};

/**
 * Turn off Rollbar. This is mainly for the case where we are timing out.
 */
export const disableErrorHandling = () => {
  if (ErrorHandler.get()) {
    ErrorHandler.get().disable();
  }
};

/**
 * Reset the error handler instance. Useful for testing
 */
export const resetErrorHandlerInstance = () => {
  instance = undefined;
};

/**
 * Set the error callback value that the Rollbar stub will return. For testing
 */
export const setErrorCallbackValue = (e) => {
  errorCallbackValue = e;
};
